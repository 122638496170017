import { createContext, useState } from "react";

export const TheLayoutContext = createContext();

export function TheLayoutProvider({ children }) {
  const [footerName, setFooterName] = useState("inicio");
  const [childFooter, setChildFooter] = useState(null);
  const [childFooterLeft, setChildFooterLeft] = useState(null);
  const [expand, setExpand] = useState(false);
  const [idsContenidos, setIdContenidos] = useState([]);
  const [newCheck, setNewCheck] = useState([]);
  const [newCheckandSelected, setNewCheckandSelected] = useState([]);
  const [clienteId, setClienteId] = useState(0);
  const [s3Values, setS3Values] = useState([]);
  const [s3Json, setS3Json] = useState({});
  const [recordsDesplegados, setRecordsDesplegados] = useState([]);
  const [procesoSeleccionado, setProcesoSeleccionado] = useState(window.procesoSeleccionado || { id: 1, label: 'Consulta General', value: 1, category: 'Consultas' });
  //Variables usadas para conocer la cantidad de cajas o contenidos Marcados/Tildados
  const [cantidadTildadas, setCantidadTildadas] = useState(0);
  const [cantidadMarcadas, setCantidadMarcadas] = useState(0);
  const [cantidadTotal, setCantidadTotal] = useState(0);
  const [changeProceso, setChangeProceso] = useState(false);
  const [buttonMarcaDisabled, setButtonMarcaDisabled] = useState(true);
  const [buttonMarcaTodoDisabled, setButtonMarcarTodoDisabled] = useState(true);
  const [buttonDesmarcaDisabled, setButtonDesmarcaDisabled] = useState(true);
  const [subProcesoChange, setSubProcesoChange] = useState(false);
  const [selection, setSelection] = useState([]); // Setea los valores que se van seleccionando con el checkbox listable
  const [subProceso, setSubProceso] = useState(window.subProceso || '');
  const [valueSubproceso, setValueSubproceso] = useState(0);
  const [useAwsBucket, setUseAwsBucket] = useState(JSON.parse(localStorage.getItem("siam-params-images"))?.USE_AWS_BUCKET?.value ? JSON.parse(localStorage.getItem("siam-params-images"))?.USE_AWS_BUCKET?.value : '');
  const [subProcesoLocal, setValueSubprocesoLocal] = useState({});
  const [clienteIdLocal, setClienteIdLocal] = useState(null);
  const [procesandoMarcas, setProcesandoMarcas] = useState(false);
  const [clienteFichaSelected, setClienteFichaSelected] = useState(false);

  return (
    <TheLayoutContext.Provider
      value={{
        footerName, setFooterName,
        childFooter, setChildFooter,
        expand, setExpand, childFooterLeft, setChildFooterLeft,
        idsContenidos, setIdContenidos, newCheck, setNewCheck, s3Values,
        setS3Values, s3Json, setS3Json, procesoSeleccionado, setProcesoSeleccionado,
        setRecordsDesplegados, recordsDesplegados,
        cantidadTildadas, setCantidadTildadas,
        cantidadMarcadas, setCantidadMarcadas,
        cantidadTotal, setCantidadTotal, changeProceso, setChangeProceso, buttonMarcaDisabled,
        setButtonMarcaDisabled,
        buttonDesmarcaDisabled, setButtonDesmarcaDisabled, clienteId, setClienteId, selection,
        setSelection, newCheckandSelected,
        setNewCheckandSelected, subProceso, setSubProceso, subProcesoChange, setSubProcesoChange,
        valueSubproceso, setValueSubproceso, useAwsBucket, setUseAwsBucket, subProcesoLocal,
        setValueSubprocesoLocal, clienteIdLocal, setClienteIdLocal, buttonMarcaTodoDisabled,
        setButtonMarcarTodoDisabled, procesandoMarcas, setProcesandoMarcas, clienteFichaSelected, setClienteFichaSelected
      }}
    >
      {children}
    </TheLayoutContext.Provider>
  );
}

// export default CajasContext;

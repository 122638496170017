import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Grid, ImageList } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import AlertDialog from "../projects/gestion_documental/components/Digitalizaciones/AlertDialogDelete";
import "react-medium-image-zoom/dist/styles.css";
import "../projects/gestion_documental/components/styles/Digitalizaciones.css";
import { SnackbarMessenger } from "./SnackbarMessenger";
import Box from "@mui/material/Box";
import { postDigitalizacionesNotas } from "src/projects/gestion_documental/helpers/NotasData";
import {
  getImages,
  deleteImages,
} from "../projects/gestion_documental/helpers/ImagesData";
import esArray from "./functionEsArray";

export const ImagesList = (props) => {
  const {
    guardado,
  } = props;
  let idNotaEdit = props.params?.id || "";

  // eslint-disable-next-line no-unused-vars
  const [base64String, setBase64String] = useState("");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);


  //NOTE ESTADO PARA SABER SI HAY IMAGENES NUEVAS PARA ALMACENAR


  //NOTE STATE PARA DELETE
  const [alertDelete, setAlertDelete] = useState(false);
  const [idImagen, setIdImagen] = useState(false);
  const [deleteTrue, setDeleteTrue] = useState(false);


  //NOTE STATE PARA LAS ALERTAS
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const [listadeImagenes, setListadeImagenes] = useState([]);

  useEffect(() => {
    const getImagenes = async () => {
      let paramsImages = [
        { modelo_padre: "notas_internas", id_modelo_padre: idNotaEdit },
      ];
      let response = await getImages(paramsImages);
      if (response.status === 'true') {
        let imagenes = esArray(response.wsResponse.imagenes);
        setListadeImagenes(imagenes);
      } else {
        setListadeImagenes([]);
      }
      setRefresh(!refresh)
      setLoading(false);
    };
    refresh && getImagenes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    const deleteImage = async () => {
      if (deleteTrue) {
        let data = {
          id: idImagen,
        };
        const respuesta = await deleteImages(data);
        if (respuesta.status === "true") {
          setSnackbarVisible(true);
          setSeverity("success");
          setMessage("Se elimino la Imagen seleccionada");
          setRefresh(true);

        } else {
          setSnackbarVisible(true);
          setSeverity("warning");
          setMessage("Hubo un error al eliminar la Imagen seleccionada");
        }
        setIdImagen("");
        setDeleteTrue(!deleteTrue);
      }
    }
    deleteImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTrue]);


  //NOTE CUANDO SE SELECCIONA UNA IMAGEN SE PASA DIRECTAMENTE A BASE 64
  const uploadImages = async (event) => {
    let fileObj = [];
    fileObj.push(event.target.files);

    for (let i = 0; i < fileObj[0].length; i++) {
      setLoading(true);
      let imageBase64 = await convertImageToBase64(
        URL.createObjectURL(fileObj[0][i]),
        event.target.files[0].name
      );
      setBase64String(imageBase64);
      saveImage(imageBase64);
    }
  };

  //NOTE FUNCION PARA PASAR LAS IMAGENES A BASE 64, PARA PODER ALMACENARLAS EN LA BASE DE DATOS
  const convertImageToBase64 = async (url, name) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };



  const saveImage = async (imageBase64) => {
    if (imageBase64 !== "") {
      let paramsCreateImages = [
        {
          modelo_padre: "notas_internas",
          id_modelo_padre: idNotaEdit,
          image: imageBase64,
        },
      ];
      let responseImages = await postDigitalizacionesNotas(paramsCreateImages);
      if (responseImages.status !== "true") {
        setSnackbarVisible(true);
        setSeverity("warning");
        setMessage("Ocurrió un problema al insertar la imagen");
        return;
      }
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage("Imagen insertada con exito!!!");
      setRefresh(true);
    }
  };

  return (
    <>
      {alertDelete && (
        <AlertDialog
          alertDelete={alertDelete}
          setDeleteTrue={setDeleteTrue}
          setAlertDelete={setAlertDelete}
        />
      )}
      <Grid
        style={{
          paddingLeft: "100px",
          paddingRight: "100px",
        }}
      >
        {/*NOTE BOTTON PARA SELECCIONAR LAS IMAGENES */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          style={{
            textAlign: "center",
            display: "block",
          }}
        >
          <Button variant="contained" component="label">
            Agregar Imagen
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={uploadImages}
            />
          </Button>

          <PhotoCamera style={{ color: "#1565C0" }} />
        </Stack>
        {/*NOTE LISTADO DE IMAGENES */}
        {loading || guardado ? (
          <Grid style={{ textAlign: "center" }}>
            <CircularProgress style={{ width: "50px", height: "50px" }} />
          </Grid>
        ) : listadeImagenes && (
          <ImageList sx={{ width: 1000, height: 450 }} cols={6} rowHeight={164}>
            {listadeImagenes.map((img, key) => (
              <Box key={key}>
                <Zoom isZoomed={true}>
                  <img src={img.link} alt={img.id} className="image" />

                  {/*NOTE BOTON PARA ELIMINAR LAS IMAGENES */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <IconButton
                        className="iconButton"
                        aria-label="delete"
                        onClick={() => {
                          setIdImagen(img.id);
                          setAlertDelete(true);
                        }}
                      >
                        <DeleteIcon className="icon" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Zoom>
              </Box>
            ))}
          </ImageList>
        )}
      </Grid>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </>
  );
};


export const schemaServiciosInternetForm  = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "id",
      description: "Id",
    },
    selectedTipoServicio: {
      type: "string",
      customRender: "SelectorServiciosInternet",
    },
    valor: {
      type: "string",
      title: "Valor",
      description: "Ingresar Valor",
      minLength: 5,
    },
  },
  required: [ "selectedTipoServicio","valor"],
};
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useCuentasContext } from "src/context/CuentasContext";
import { SelectorTipoDocumentosComponent } from "src/forms/customRenders/selectorTipoDocumento";
import { SelectorCategoriaCuentasComponent } from "src/forms/customRenders/selectorCategoriaCuentas";
import { SelectorEstadoCivilComponent } from "src/forms/customRenders/selectorEstadoCivil";
import { SelectorGeneroComponent } from "src/forms/customRenders/selectorGenero";
import { DateFormComponent } from "src/forms/customRenders/dateForm";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { Spinner } from "src/general_components/Spinner";
// import { SelectorCategoriaCuentasComponent } from "./forms/customRenders/selectorCategoriaCuentas";
import { SelectorPerfilFiscalComponent } from "src/forms/customRenders/selectorPerfilFiscal";
import { schemaCuentasJuridicasForm, schemaCuentasFisicasForm } from "./forms/schemaCuentasJuridicasForm";
import { uiSchemaCuentasJuridicasForm, uiSchemaCuentasFisicasForm } from "./forms/uiSchemaCuentasJuridicasForm";
import { Buttons } from "src/general_components/Buttons";

/* Formulario contenedor de los datos de las CUENTAS JURIDICAS */
const localize = require("ajv-i18n");

export const CuentasJuridicasForm = (props) => {
  /* estados propios del context */
  const {
    cuenta,
    saveData,
    cuentaJuridica,
    categoriaCuentas,
    documentos,
    cuentaJuridicaEdit,
    setCuentaJuridicaEdit,
    categoriaCuentasEdit,
    setCategoriaCuentasEdit,
    documentosEdit,
    setDocumentosEdit,
    setImpuestoSituacionEdit,
    impuestoSituacionEdit,
    impuestoSituacion,
    isLoadingData,
    message,
    setMessage,
    severity,
    setSeverity,
    snackbarVisible,
    setSnackbarVisible,
    setCuentaFisicaEdit,
    cuentaFisicaEdit,
    cuentaFisica,
    esJuridica
  } = useCuentasContext();


  /* definicion de constantes de estado para utiliar con sus respectivos formularios */
  const [dataForm, setDataForm] = useState({
    id: "",
    id_cuentas: "",
    razon_social: "",
    nombre_fantasia: "",
    apellido: "",
    nombre: "",
    id_genero: "",
    fecha_nacimiento: "",
    id_estado_civil: "",
    id_tipo_documentos: "",
    id_categoria_cuenta: "",
    numero: "",
    selectedTipoDocumento: "",
    selectedCategoriaCuentas: "",
    selectedPerfilFiscal: ""
  });

  useEffect(() => {
    if (cuenta.id_cuentas > 0) {
      
      setDataForm({
        ...dataForm,
        id: cuentaJuridica?.id,
        id_cuentas: cuentaJuridica?.id_cuentas,
        razon_social: cuentaJuridica?.razon_social,
        nombre_fantasia: cuentaJuridica?.nombre_fantasia,
        apellido: cuentaFisica?.apellido,
        nombre: cuentaFisica?.nombre,
        id_genero: cuentaFisica?.id_genero,
        fecha_nacimiento: cuentaFisica?.fecha_nacimiento,
        id_estado_civil: cuentaFisica?.id_estado_civil,
      });
      setCuentaJuridicaEdit({ ...cuentaJuridica });
      setCuentaFisicaEdit({ ...cuentaFisica });
      setDocumentosEdit({ ...documentos });
      setCategoriaCuentasEdit({ ...categoriaCuentas });
      setImpuestoSituacionEdit({ ...impuestoSituacion })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuentaJuridica, cuentaFisica,documentos, categoriaCuentas, impuestoSituacion]);

  useEffect(() => {
    if (cuenta.id_cuentas > 0)
      setDataForm({
        ...dataForm,
        id_tipo_documentos: documentos.id_documento_tipo,
        numero: parseInt(documentos.numero),
        selectedTipoDocumento: documentos.id_documento_tipo,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentos]);

  useEffect(() => {
    if (cuenta.id_cuentas > 0)

      setDataForm({
        ...dataForm,
        selectedPerfilFiscal: impuestoSituacion?.id_impuestos_situaciones,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impuestoSituacion]);

  useEffect(() => {
    setDocumentosEdit({
      ...documentosEdit,
      numero: dataForm.numero,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.numero]);

  useEffect(() => {

    if (cuenta.id_cuentas > 0)
      setDataForm({
        ...dataForm,
        id_categoria_cuenta: categoriaCuentas.id_categoria_cuentas,
        selectedCategoriaCuentas: categoriaCuentas.id_categoria_cuentas,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriaCuentas]);

  useEffect(() => {
    setCuentaJuridicaEdit({
      ...cuentaJuridicaEdit,
      razon_social: dataForm.razon_social,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.razon_social]);

  useEffect(() => {
    setCuentaJuridicaEdit({
      ...cuentaJuridicaEdit,
      nombre_fantasia: dataForm.nombre_fantasia,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.nombre_fantasia]);

  useEffect(() => {
    setDocumentosEdit({
      ...documentosEdit,
      id_documento_tipo: dataForm?.selectedTipoDocumento,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.selectedTipoDocumento]);

  useEffect(() => {
    setCategoriaCuentasEdit({
      ...categoriaCuentasEdit,
      id_categoria_cuentas: dataForm?.selectedCategoriaCuentas,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.selectedCategoriaCuentas]);

  useEffect(() => {
    setImpuestoSituacionEdit({
      ...impuestoSituacionEdit,
      id_impuestos_situaciones: dataForm?.selectedPerfilFiscal
    }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.selectedPerfilFiscal]);

  useEffect(() => {
    setCuentaFisicaEdit({
      ...cuentaFisicaEdit,
      nombre: dataForm.nombre,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.nombre]);

  useEffect(() => {
    setCuentaFisicaEdit({
      ...cuentaFisicaEdit,
      id_genero: dataForm.id_genero,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.id_genero]);

  useEffect(() => {
    setCuentaFisicaEdit({
      ...cuentaFisicaEdit,
      id_estado_civil: dataForm.id_estado_civil,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.id_estado_civil]);

  useEffect(() => {
    setCuentaFisicaEdit({
      ...cuentaFisicaEdit,
      fecha_nacimiento: dataForm.fecha_nacimiento,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.fecha_nacimiento]);

  useEffect(() => {
    setCuentaFisicaEdit({
      ...cuentaFisicaEdit,
      apellido: dataForm.apellido,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm.apellido]);
  /* Se actualiza el context con los datos del formulario */

  const [withErrors, setWithErrors] = useState(false);

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setDataForm(data);

    return { errors, data };
  };

  const sendInfo = async (send, event) => {
    if (withErrors) {
      setMessage("Error en el formulario. Revise los Campos");
      setSeverity("error");
      setSnackbarVisible(true);
    } else if (send) {
      let result = await saveData();
      if (result) {
        if (cuenta.id_cuentas > 0){
          let message = "Cuenta Fisica editada exitosamente";
          if(esJuridica){
            message = "Cuenta Juridica editada exitosamente";
          }
          setMessage(message);
        }
        else{
          let message = "Cuenta Fisica agregada exitosamente";
          if(esJuridica){
            message = "Cuenta Juridica agregada exitosamente";
          }
          setMessage(message);
        } 
        setSeverity("success");
        clearInputs();
        props.handleClose()
      } else {
        setMessage("Hubo un error");
        setSeverity("error");
      }
      setSnackbarVisible(true);
      setWithErrors(true);
    }
  };


  const clearInputs = () => {
    setDataForm({
      id: "",
      id_cuentas: "",
      razon_social: "",
      nombre_fantasia: "",
      id_tipo_documentos: "",
      id_categoria_cuenta: "",
      numero: "",
      selectedTipoDocumento: "",
      selectedCategoriaCuentas: "",
      apellido: "",
      nombre: "",
      id_genero: "",
      fecha_nacimiento: "",
      id_estado_civil: "",
    });
  };

  const handleClose = () => {
    props.handleClose(false);
  };

  if (cuenta.id_cuentas > 0 && isLoadingData) {
    return <Spinner />;
  } else
    return (
      <>
        <Container maxWidth="lg">
          <Grid item xs={12}>

            <Box
              sx={{
                mx: "auto",
                width: "100%",
                p: 1,
                m: 1,
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              <JsonForms
                onChange={({ errors, data }) =>
                  onChange && onChange(errors, data)
                }
                schema={esJuridica ? schemaCuentasJuridicasForm : schemaCuentasFisicasForm}
                uischema={esJuridica ? uiSchemaCuentasJuridicasForm : uiSchemaCuentasFisicasForm}
                renderers={[
                  ...materialRenderers,
                  SelectorTipoDocumentosComponent,
                  SelectorCategoriaCuentasComponent,
                  SelectorPerfilFiscalComponent,
                  SelectorEstadoCivilComponent,
                  SelectorGeneroComponent,
                  DateFormComponent
                ]}
                data={dataForm}
                cells={materialCells}
              />
            </Box>
          </Grid>
          <Grid >
            <Buttons
              onClickSubmit={(event) => {
                sendInfo(true, event);
              }}
              labelButtonSave={"Guardar"}
              buttonCerrar={handleClose}
            />
            {/* <Button
              variant="contained"
              size="small"
              onClick={(event) => {
                sendInfo(true, event);
              }}
            >
              {" "}
              Guardar{" "}
            </Button> */}
          </Grid>
        </Container>
        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          hide={4000}
          close={() => {
            setSnackbarVisible(false);
          }}
        />
      </>
    );
};

import { useState, useEffect } from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { getPais, getPaisPaginated,deletePais } from "src/general_helpers/PaisData";
import { FormularioDinamicoModelos } from "src/general_components/FormularioDinamicoModelos";
import { getTablaEstructura,getDataSelect } from "src/hooks/structureTable";

export const Pais = () => {
  const [createButton, setCreateButton] = useState(false);
  const [dataEdit, setDataEdit] = useState([]);
  const listName = "Paises";
  const modelo = "pais"; 
  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "nombre", headerName: "Nombre", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
  ];

  const [formData, setFormData] = useState({});

  const [options, setOptions] = useState({
    cols: columns,
    get: getPais,
    getPaginated: getPaisPaginated,
    pageSize: [5, 10, 25],
    edit: true,
    delete: deletePais,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setDataEdit,
  });



  useEffect(() => {
    const valuesDefaultForm = {};
    const filtrarCampos = async (formDataResponse) => {
      const camposFiltrados = {};

      for (const fieldName in formDataResponse) {
        if (fieldName.includes('id_')) {
          const palabraExtraida = fieldName.replace('id_', ''); // Cortar "id_"
          const valorLocalStorage = JSON.parse(localStorage.getItem("siam-" + palabraExtraida))?.[palabraExtraida];
          console.log("valorLocalStorage",valorLocalStorage)

          if (valorLocalStorage) {
            // Mapear cada objeto del valorLocalStorage para tener solo "id", "nombre" y manejar casos
            const opcionesFiltradas = valorLocalStorage.map(({ id, nombre, descripcion, label,detalle }) => ({
              id,
              nombre: nombre || descripcion || detalle  || label || 'Sin Nombre', // Prioridad: nombre > descripcion > label
            }));

            camposFiltrados[fieldName] = {
              ...formDataResponse[fieldName],
              options: opcionesFiltradas,
            };
          }else{
            console.log("palabraExtraida",palabraExtraida)
            const datosSelect = await getDataSelect(palabraExtraida);
            
            // / Mapear cada objeto del valorLocalStorage para tener solo "id", "nombre" y manejar casos
            const opcionesFiltradas = datosSelect.map(({ id, nombre, descripcion, label,detalle }) => ({
              id,
              nombre: nombre || descripcion || detalle ||  label || 'Sin Nombre', // Prioridad: nombre > descripcion > label
            }));

            camposFiltrados[fieldName] = {
              ...formDataResponse[fieldName],
              options: opcionesFiltradas,
            };
          }
        }
      }

      // Combina formData con camposFiltrados
      let camposFinal = { ...formDataResponse, ...camposFiltrados };


      if (camposFinal.id) {
        delete camposFinal.id;
      }


      // Eliminar propiedades de camposFinal si coinciden con valuesDefaultForm
      Object.keys(valuesDefaultForm).forEach((key) => {
        delete camposFinal[key];
      });

      return camposFinal;
    };
    const getEstructuraTabla = async () => {
      // setIsLoadingData(true);
      let response = await getTablaEstructura(modelo);

      if (response.status === "true") {
        const formDataResponse = response.wsResponse;
        const camposFiltrados = await filtrarCampos(formDataResponse);
        setFormData(camposFiltrados);
      }

    };
    getEstructuraTabla();
  }, []);

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  //EL BUTTONMETADATOS SOLO ES PARA ESTE CASO, VERIFICAR CADA OCASION.
  if (
    createButton === false &&
    dataEdit.id !== undefined
  ) {
    setDataEdit([]);
  }

  return (


    <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          {listName}
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <CajasProvider>
        <>

          <ListTablePaginated options={options} />
          <FormularioDinamicoModelos
            data={dataEdit}
            createButton={createButton}
            listName={listName}
            setCreateButton={setCreateButton}
            setOptions={setOptions}
            options={options}
            formData={formData}
            modelo = {modelo}
          />
        </>
      </CajasProvider>
    </Grid>
  );
};


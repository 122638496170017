export const uiSchemaTipoContenidosForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/selectedTipoContenidos",
        },
      ],
    },
  ],
};
import { useState } from "react";
import {
  deleteTipoServiciosInternet,
  getTipoServiciosInternet,
  getTipoServiciosInternetPaginated,
  postTipoServiciosInternet,
} from "../general_helpers/TipoServiciosInternetData";
import { BasicFormIdDetalle } from "../general_components/BasicFormIdDetalle";
import { ListTablePaginated } from "../general_components/ListTablePaginated";

export const TipoServicioInternetList = () => {
  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "nombre", headerName: "Nombre", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
  ];
  const listName = "Tipo de servicio de internet";

  const [tipoInternet, setTipoInternet] = useState([]);
  const [createButton, setCreateButton] = useState(false);

  const options = {
    cols: columns,
    get: getTipoServiciosInternet,
    getPaginated: getTipoServiciosInternetPaginated,
    pageSize: [5, 10, 25],
    edit: true,
    listName,
    delete: deleteTipoServiciosInternet,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setTipoInternet,
  };

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  if (createButton === false && tipoInternet.id !== undefined) {
    setTipoInternet([]);
  }

  return (
    <>
      <BasicFormIdDetalle
        data={tipoInternet}
        createButton={createButton}
        listName={listName}
        setCreateButton={setCreateButton}
        componentList={"/generales/tipo_internet/admin"}
        postData={postTipoServiciosInternet}
      />
      <ListTablePaginated options={options} />
    </>
  );
};

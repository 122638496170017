import {
    materialCells,
    materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useCuentasContext } from "src/context/CuentasContext";
// import { SelectorServiciosInternetComponent } from "./forms/customRenders/selectorServiciosInternet";
import { SelectorServiciosInternetComponent } from "src/forms/customRenders/selectorServiciosInternet";
import { schemaServiciosInternetForm } from "./forms/schemaCuentasServiciosInternetForm";
import { uiSchemaServiciosInternetForm } from "./forms/uiSchemaCuentasServiciosInternetForm";
import Swal from 'sweetalert2'
import "src/services/styles/sweetAlert.css";
import { Buttons } from "src/general_components/Buttons";

const localize = require("ajv-i18n");

/* Formulario de Cuentas Servicios de Internet */

export const CuentasServiciosInternetForm = (props) => {
  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [seleccion_cuenta, setSeleccion_cuenta] = useState();

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [sendDisabledButton, setSendDisabledButton] = useState(true);


  const { loadServiciosInternet, cuenta, serviciosInternetEdit,setMessage,setSeverity,setSnackbarVisible,
    setServiciosInternetEdit,setUpdateServiciosInternet } =
    useCuentasContext();

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [selectedTipoServicio, setSelectedTipoServicio] = useState([]);

  const [updater, setUpdater] = useState(Date.now());
  useEffect(() => {
    clearInputs();
  }, []);
  useEffect(() => {
    setUpdater(Date.now());
  }, [serviciosInternetEdit]);

  const [dataForm, setDataForm] = useState({
    id: props.data?.id || "",
    id_cuentas: props.data?.id_cuentas || "",
    id_tipo_servicios_internet: props.data?.id_tipo_servicios_internet || "",
    valor: props.data?.valor || "",
    selectedTipoServicio: "",
  });

  useEffect(() => {
    if (serviciosInternetEdit)
      setDataForm({
        id: serviciosInternetEdit?.id || "",
        id_cuentas: serviciosInternetEdit?.id_cuentas || "",
        id_tipo_servicios_internet:
          serviciosInternetEdit?.id_tipo_servicios_internet || "",
        valor: serviciosInternetEdit?.valor || "",
        selectedTipoServicio: 
          serviciosInternetEdit.id_tipo_servicios_internet,
        
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updater]);

  const [withErrors, setWithErrors] = useState(false);

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setDataForm(data);
    if (err) {
      setSendDisabledButton(true);
    } else setSendDisabledButton(false);

    return { errors, data };
  };



  const eventEdit = (data,event) => {

    let valido =false;
    let mensaje = '';
    const tipoServiciosInterneLocalStorage=JSON.parse(localStorage.getItem("siam-tipo_servicios_internet"));
    const tipoServicioSelected= tipoServiciosInterneLocalStorage.tipo_servicios_internet.find((servicio)=>servicio.id===data.selectedTipoServicio)
    
    switch (tipoServicioSelected.nombre){
      case 'Mail':{
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test( data.valor)) {
          mensaje="El campo 'valor' debe ser un correo electrónico válido. Ejemplo: ejemplo@mail.com";
        }else{
          valido=true
        }
      break;}
      case 'Web':{
        const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-zA-Z]{2,6})([/\w.-]*)*\/?$/;
         if (!urlRegex.test( data.valor)) {
           mensaje="El campo 'valor' debe ser una web válida. Ejemplo: www.ejemplo.com";
         }else{
           valido=true
         }
       break;}
       default:
          valido=true
        break;
    }

    if(valido){
      sendInfo(true, event);
    }else{
      setMessage(mensaje);
      setSeverity('warning')
      
      setSnackbarVisible(true);
    }
  };


  /* funcion para validar el formulario y  guardar los datos en el context */

  const sendInfo = (send) => {
    if (withErrors || dataForm.selectedTipoServicio === "") {
      setMessage("Error en el formulario. Revise los Campos. Algunos son Obligatorios");
      setSeverity("error");
      setSnackbarVisible(true);
    } else if (send) {
      const cuentasToSend = { ...dataForm };
      cuenta?.id_cuentas && (cuentasToSend.id_cuentas = cuenta.id_cuentas);
      cuentasToSend.id_tipo_servicios_internet =
        cuentasToSend.selectedTipoServicio;
      loadServiciosInternet({ ...cuentasToSend });

      // if (!props.data) {
      if (serviciosInternetEdit) {
        setMessage("Servicio editado exitosamente");}
      else{ 
        setMessage("Servicio agregado exitosamente");
      }
      Swal.fire({
        title: 'Atencion!!!',
        text: "Los servicios de internet que se agreguen o editen no impactara en la base de datos hasta que no confirme los mismos mediante"
        +" el boton Guardar que se encuentra debajo de la grilla de Servicios de Internet",
        icon: 'warning',
        customClass: {
          container: 'my-swal-delete'
        },
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar'
      })
      setSeverity("success");
      setServiciosInternetEdit(undefined)
      setSnackbarVisible(true);
      setUpdateServiciosInternet(true);
      props.callback && props.callback(false);
      //}
    }
  };

  const clearInputs = () => {
    setDataForm({
      id: "",
      id_cuentas: "",
      id_tipo_servicios_internet: "",
      valor: "",
      selectedTipoServicio: "",
    });
    setSelectedTipoServicio();
  };

  const handleClose = () => {
    props.callback(false);
  };

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          mx: "auto",
          width: "100%",
          p: 1,
          m: 1,
          textAlign: "center",
          flexGrow: 1,
        }}
      >
        <JsonForms
          onChange={({ errors, data }) => onChange && onChange(errors, data)}
          schema={schemaServiciosInternetForm}
          uischema={uiSchemaServiciosInternetForm}
          renderers={[...materialRenderers, SelectorServiciosInternetComponent]}
          data={dataForm}
          cells={materialCells}
        />

        <Grid container justifyContent="flex-end">
          <Buttons
                onClickSubmit={(event) => {
                  eventEdit(dataForm,event)
                 
                }}
                labelButtonSave={"Guardar"}
                buttonCerrar={handleClose}
              />
        </Grid>
      </Box>
    </Container>
  );
};

import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css"

//Para el selector de tipo cuentas
export const getTipoCuentas = async (auth) => {
  //Esta funcion se encarga de obtener los tipos de cuentas disponibles
  const tipo_cuentas = new datos("tipo_cuentas");
  tipo_cuentas.addCondition("tipo_cuentas", "id", 0, ">");
  const response = await tipo_cuentas.get();
  const res = response.wsResponse.tipo_cuentas;

  // Si es un array se mantiene como estaba
  if (Array.isArray(res)) {
    var arr = res;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (typeof res === "undefined") {
    // se pasa un array vacio
    arr = [];
  } else {
    // si trae un solo elemento , es un objeto
    arr = [res]; // se lo convierte a array
  }
  return arr;
};

// Esta función se utiliza para obtener los precios por tipo de cuenta
export const getTipoCuentasPrecio = async (auth) => {
  //Realizar una consulta a la tabla "tipo_cuentas_articulos" y realiza uniones internas con las tablas "articulos"
  //y "tipo_cuentas" para obtener los datos relacionados
  const cuentasPrecio = new datos("tipo_cuentas_articulos");
  cuentasPrecio.innerJoinTables(
    "articulos",
    "tipo_cuentas_articulos",
    "id",
    "id_articulos",
    "="
  );
  cuentasPrecio.innerJoinTables(
    "tipo_cuentas",
    "tipo_cuentas_articulos",
    "id",
    "id_tipo_cuentas",
    "="
  );
  cuentasPrecio.addCondition("tipo_cuentas_articulos", "id", 0, ">");
  cuentasPrecio.addCondition("tipo_cuentas_articulos", "id", 0, ">");

  const response = await cuentasPrecio.get();

  const tipoCuentasArticulosData = esArray(
    response.wsResponse.tipo_cuentas_articulos
  );
  const cuentasTipoData = esArray(response.wsResponse.relations.tipo_cuentas);
  const articulosData = esArray(response.wsResponse.relations.articulos);

  return {
    tipoCuentasArticulos: tipoCuentasArticulosData,
    cuentasTipo: cuentasTipoData,
    articulos: articulosData,
  };
};

//Esta función se utiliza para obtener los precios por tipo de cuenta de forma paginada
export const getTipoCuentasPrecioPaginated = async (limit, offset) => {
  // realizar una consulta a la tabla "tipo_cuentas_articulos" con una limitación y 
  //un desplazamiento dados. Realiza las mismas uniones internas que la función anterior.
  const cuentasPrecio = new datos("tipo_cuentas_articulos");
  cuentasPrecio.innerJoinTables(
    "articulos",
    "tipo_cuentas_articulos",
    "id",
    "id_articulos",
    "="
  );
  cuentasPrecio.innerJoinTables(
    "tipo_cuentas",
    "tipo_cuentas_articulos",
    "id",
    "id_tipo_cuentas",
    "="
  );
  cuentasPrecio.addCondition("tipo_cuentas_articulos", "id", 0, ">");
  cuentasPrecio.addLimitCondition(limit, offset);

  const response = await cuentasPrecio.get();

  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/
  let result = {
    data: [],
    total_records: 0
  };
  if (!response.wsResponse.empty) {
    // Combinar los datos de las tres tablas en un solo array
    const data = response.wsResponse.tipo_cuentas_articulos.map((cuenta) => {
      const tipoCuentasArticulosData = esArray(cuenta);
      tipoCuentasArticulosData[0].nombre =
        cuenta.relations?.articulos?.nombre ?? "";
      tipoCuentasArticulosData[0].detalle =
        cuenta.relations?.tipo_cuentas?.detalle ?? "";
      return {
        ...cuenta,
        tipoCuentasArticulos: tipoCuentasArticulosData,
      };
    });

    result = {
      data: data,
      total_records: response.wsResponse.total_records,
    };
  }

  return result;
};

// Esta función se utiliza para insertar un nuevo precio por tipo de cuenta
export const postTipoCuentasPrecio = async (estado) => {
  const cuentasPrecio = new datos("tipo_cuentas_articulos");
  const response = cuentasPrecio.insert(estado);
  return response;
};

//Esta función se utiliza para eliminar un precio por tipo de cuenta.
export const deleteTipoCuentasPrecio = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
    customClass: {
      container: 'my-swal-delete'
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos("tipo_cuentas_articulos");
      let data = {
        id: id,
      };
      const response = await userData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El precio por tipo de cuenta ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Buttons } from 'src/general_components/Buttons';

const BuscarFormTipoContenidos = ({setModalState,setFiltros}) => {
    const [formData, setFormData] = useState({
        id: '',
        detalle: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleClose = () => {
        setModalState(false);
    };

    const handleSubmit = () => {
        setFiltros(formData);
        setModalState(false);
    };

    return (<>
        <div>
            <TextField
                label="ID"
                name="id"
                type="number"
                value={formData.id}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
            />
        </div>
        <div>
            <TextField
                label="Detalle"
                name="detalle"
                value={formData.detalle}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
            />
        </div>
        <div style={{marginTop: 20}}>
            <Buttons
                onClickSubmit={handleSubmit}
                labelButtonSave={"Buscar"}
                buttonCerrar={handleClose}
            />
        </div>
    </>
    );
};

export default BuscarFormTipoContenidos;
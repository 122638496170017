import { useState } from "react";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";

export const ItemComprobantesList = (data) => {

  // let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    // { field: "articulo", headerName: parametros_proyecto.id === 30 ? "Item" : "Art/Serv", flex: 0.5 },
    { field: "descripcion", headerName: "Descripcion", flex: 0.3 },
    { field: "total", headerName: "Total", flex: 0.3 },
  ];

  const getItemPaginated = () => {
    if (data.contenidoItem) {
      let resultado = {
        data: data.contenidoItem,
        total_records: data.contenidoItem.length,
      };
      console.log("resultado",resultado)
      return resultado;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState({
    cols: columns,
    get: getItemPaginated,
    getPaginated: getItemPaginated,
    pageSize: [5, 10, 25],
    edit: false,
    limit: 50,
    filter: true,
    exportData: true,
    server: false,
    sinFilas: false,
  });

  return <ListTablePaginated options={options} />;
};

// TODO: useEffect no se esta usando, revisar la necesidad de importarlo
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import { Autocomplete, TextField } from "@mui/material";
import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import {esArray} from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";
import { getPropositoCalculoPrecio } from "src/projects/articulos/helpers/PropositoCalculoPrecio";

const SelectorPropositoCalculoPrecio = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.selectedpropositoCalculoPrecio;

  const proposito_calculo_precios = getFromLS("siam-proposito_calculo_precios").proposito_calculo_precios;
  const [propositoCalculoPreciosSeleccionado, setPropositoCalculoPreciosSeleccionado] = useState('');

  useState(() => {
    const propositoCalculoPrecio = async () => {
      if (proposito_calculo_precios) {
        setDataDefaults(esArray(proposito_calculo_precios));
      }else {
        const response = await getPropositoCalculoPrecio()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    propositoCalculoPrecio();
  }, []);

  useEffect(() => {
    setPropositoCalculoPreciosSeleccionado(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setPropositoCalculoPreciosSeleccionado(newValue);
    props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
    }else{
      props.handleChange(props.path, "");
    }
  };
  
  
  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
        value={propositoCalculoPreciosSeleccionado ? propositoCalculoPreciosSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id + ' - ' + option.nombre || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Proposito calculo precios"
          disabled={disabled} />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorPropositoCalculoPrecioTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("object"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorPropositoCalculoPrecio";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorPropositoCalculoPrecioComponent = {
  renderer: withJsonFormsControlProps(SelectorPropositoCalculoPrecio),
  tester: SelectorPropositoCalculoPrecioTester,
};

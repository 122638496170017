import React from "react";

export const Spinner = () => {
  return (
    <div
      className="spinner-border m-5"
      style={{ width: "3rem", height: "3rem" }}
      role="status"
    >
      <span className="visually-hidden"></span>
    </div>
  );
};

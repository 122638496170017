import menu from "../src/configs/menu";
import { Ubicaciones } from "./projects/gestion_documental/components/Ubicaciones";
import { TipoCajasList } from "./projects/gestion_documental/components/TipoCajasList.js";
import { TipoCambioList } from "src/general_components/TipoCambioList";
import { EstadosCajasList } from "src/projects/gestion_documental/components/EstadosCajasList";
import { UsersList } from "./general_components/UsersListMUI";
import { GenerosList } from "./general_components/GenerosList";
import { EstadoCivilList } from "./general_components/EstadoCivilList";
import { AlicuotasIVAList } from "./projects/gestion_comercial/components/AlicuotasIVAList";
import { EstadosUbicacionesList } from "./projects/gestion_documental/components/EstadosUbicacionesList";
import { ReservasList } from "./projects/venta_entradas/components/ReservasList";
import { CajasUbicacion } from "./projects/gestion_documental/pages/CajasUbicacion";
import { CajasEtiquetas } from "./projects/gestion_documental/pages/CajasEtiquetas";
import { Dashboard } from "./pages/Dashboard";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { Help } from "./pages/Help";
import { TipoContenidosList } from "./projects/gestion_documental/pages/TipoContenidosList";
import { TipoDocumentosList } from "./pages/TipoDocumentosList";
import { TipoServicioInternetList } from "./pages/TipoServicioInternetList";
import { TipoTelefonosList } from "./pages/TipoTelefonosList";
import { Digitalizaciones } from "./projects/gestion_documental/pages/Digitalizaciones";
import { ContenidoRetiro } from "./projects/gestion_documental/pages/ContenidoRetiro";
import { AltaCajas } from "./projects/gestion_documental/pages/AltaCajas";
import { Articulos } from "./projects/articulos/pages/Articulos";
import { AdministracionContenidos } from "./projects/gestion_documental/pages/AdministracionContenidos";
import { CuentasServiciosList } from "./projects/gestion_comercial/pages/CuentasServiciosList";
import { CategoriasList } from "./projects/gestion_comercial/pages/CategoriasList";
import { TipoCuentasList } from "./projects/gestion_comercial/pages/TipoCuentasList";
import { CajasGenericasAsignacion } from "./projects/gestion_documental/pages/CajasGenericasAsignacion";
import { AlojamientoCajasGenericas } from "./projects/gestion_documental/pages/AlojamientoCajasGenericas";
import { InformeMovimientos } from "./projects/gestion_documental/pages/InformeMovimientos";
import { ConsultaComprobantes } from "./projects/gestion_documental/pages/ConsultaComprobantes";
import { TipoUbicaciones } from "./projects/gestion_documental/pages/TipoUbicaciones";
import { ImportacionContenidos } from "./projects/gestion_documental/pages/ImportacionContenidos";
import { CuentasServiciosPrecioList } from "./projects/gestion_comercial/pages/CuentasServiciosPrecioList";
import { CuentasFichasList } from "./projects/gestion_comercial/pages/CuentasFichasList";
import { CajasProcesoAdmin } from "./projects/gestion_documental/pages/CajasProcesoAdmin";
import { ContenidosProcesoAdmin } from "./projects/gestion_documental/pages/ContenidosProcesoAdmin";
import { ComprobantesCodigos } from "./pages/ComprobantesCodigos";
import { ArticulosTipo } from "./pages/ArticulosTipo";
import { Categorias } from "./pages/Categorias";
import { ComprobantesEstados } from "./pages/ComprobantesEstados";
import { Departamentos } from "./pages/Departamentos";
import { DocumentoTipo } from "./pages/DocumentoTipo";
import { EstadoCivil } from "./pages/EstadoCivil";
import { Genero } from "./pages/Genero";
import { Localidades } from "./pages/Localidades";
import { MaestrosBancos } from "./pages/MaestrosBancos";
import { Pais } from "./pages/Pais";
import { Provincias } from "./pages/Provincias";
import { TipoLocalizacion } from "./pages/TipoLocalizacion";
import { TipoServiciosInternet } from "./pages/TipoServiciosInternet";
import { TipoTelefonos } from "./pages/TipoTelefonos";
import { CuentasFisicas } from "./projects/gestion_comercial/pages/CuentasFisicas";
import { CuentasJuridicas } from "./projects/gestion_comercial/pages/CuentasJuridicas";
import { ComprobantesCarga } from "./pages/ComprobantesCarga";
import { Informes } from "./pages/Informes";
import { Maquinas } from "./projects/articulos/pages/Maquinas";
import { Cajas } from "./projects/articulos/pages/Cajas";
import { Frutas } from "./projects/articulos/pages/Frutas";
import { TipoArmado } from "./projects/articulos/pages/TipoArmado";
import { TiposDetenciones } from "./projects/produccion/pages/TiposDetenciones";
import { TiposMermas } from "./projects/produccion/pages/TiposMermas";
//let user_data = JSON.parse(localStorage.getItem("siam-user-data"));
//console.log(user_data, 'lllllllllll')
const routes = [];
menu.forEach((m, k) => {
  if (m.component !== false) {
    switch (m.component) {
      case "Dashboard":
        menu[k].component = Dashboard;
        break;
      case "Login":
        menu[k].component = Login;
        break;
      case "Logout":
        menu[k].component = Logout;
        break;
      case "Help":
        menu[k].component = Help;
        break;
      case "CajasUbicacion":
        menu[k].component = CajasUbicacion;
        break;
      case "ReservasList":
        menu[k].component = ReservasList;
        break;
      case "EstadosUbicacionesList":
        menu[k].component = EstadosUbicacionesList;
        break;
      case "AlicuotasIVAList":
        menu[k].component = AlicuotasIVAList;
        break;
      case "CuentasFisicas":
        menu[k].component = CuentasFisicas;
        break;
      case "CuentasJuridicas":
        menu[k].component = CuentasJuridicas;
        break;
      case "Ubicaciones":
        menu[k].component = Ubicaciones;
        break;
      case "TipoCajasList":
        menu[k].component = TipoCajasList;
        break;
      case "TipoCambioList":
        menu[k].component = TipoCambioList;
        break;
      case "EstadosCajasList":
        menu[k].component = EstadosCajasList;
        break;
      case "UsersList":
        //if (user_data?.id_perfiles !== '2') {
        //  menu[k].component = Dashboard;
        //} else {

        menu[k].component = UsersList;
        //   }
        break;
      case "GenerosList":
        menu[k].component = GenerosList;
        break;
      case "EstadoCivilList":
        menu[k].component = EstadoCivilList;
        break;
      case "AltaCajas":
        menu[k].component = AltaCajas;
        break;
      case "TipoContenidosList":
        menu[k].component = TipoContenidosList;
        break;
      case "TipoDocumentosList":
        menu[k].component = TipoDocumentosList;
        break;
      case "TipoServicioInternetList":
        menu[k].component = TipoServicioInternetList;
        break;
      case "TipoTelefonosList":
        menu[k].component = TipoTelefonosList;
        break;
      case "Digitalizaciones":
        menu[k].component = Digitalizaciones;
        break;
      case "ContenidoRetiro":
        menu[k].component = ContenidoRetiro;
        break;
      case "Articulos":
        menu[k].component = Articulos;
        break;
      case "CajasEtiquetas":
        menu[k].component = CajasEtiquetas;
        break;
      case "AdministracionContenidos":
        menu[k].component = AdministracionContenidos;
        break;
      case "CuentasServiciosList":
        menu[k].component = CuentasServiciosList;
        break;
      case "CategoriasList":
        menu[k].component = CategoriasList;
        break;
      case "TipoCuentasList":
        menu[k].component = TipoCuentasList;
        break;
      case "CajasGenericasAsignacion":
        menu[k].component = CajasGenericasAsignacion;
        break;
      case "AlojamientoCajasGenericas":
        menu[k].component = AlojamientoCajasGenericas;
        break;
      case "InformeMovimientos":
        menu[k].component = InformeMovimientos;
        break;
      case "ConsultaComprobantes":
        menu[k].component = ConsultaComprobantes;
        break;
      case "TipoUbicaciones":
        menu[k].component = TipoUbicaciones;
        break;
      case "ImportacionContenidos":
        menu[k].component = ImportacionContenidos;
        break;
      case "CuentasServiciosPrecioList":
        menu[k].component = CuentasServiciosPrecioList;
        break;
      case "CajasProcesoAdmin":
        menu[k].component = CajasProcesoAdmin;
        break;
      case "ContenidosProcesoAdmin":
        menu[k].component = ContenidosProcesoAdmin;
        break;
      case "ComprobantesCodigos":
        menu[k].component = ComprobantesCodigos;
        break;
      case "ArticulosTipo":
        menu[k].component = ArticulosTipo;
        break;
      case "Categorias":
        menu[k].component = Categorias;
        break;
      case "ComprobantesEstados":
        menu[k].component = ComprobantesEstados;
        break;
      case "Departamentos":
        menu[k].component = Departamentos;
        break;
      case "DocumentoTipo":
        menu[k].component = DocumentoTipo;
        break;
      case "EstadoCivil":
        menu[k].component = EstadoCivil;
        break;
      case "Genero":
        menu[k].component = Genero;
        break;
      case "Localidades":
        menu[k].component = Localidades;
        break;
      case "MaestrosBancos":
        menu[k].component = MaestrosBancos;
        break;
      case "Pais":
        menu[k].component = Pais;
        break;
      case "Provincias":
        menu[k].component = Provincias;
        break;
      case "TipoLocalizacion":
        menu[k].component = TipoLocalizacion;
        break;
      case "TipoServiciosInternet":
        menu[k].component = TipoServiciosInternet;
        break;
      case "TipoTelefonos":
        menu[k].component = TipoTelefonos;
        break;
      case "ComprobantesCarga":
        menu[k].component = ComprobantesCarga;
        break;
      case "CuentasFichas":
        menu[k].component = CuentasFichasList;
        break;
      case "Informes":
        menu[k].component = Informes;
        break;
      case "Cajas":
        menu[k].component = Cajas;
        break;
      case "Maquinas":
        menu[k].component = Maquinas;
        break;
      case "Frutas":
        menu[k].component = Frutas;
        break;
      case "TipoArmado":
        menu[k].component = TipoArmado;
        break;
      case "TiposMermas":
        menu[k].component = TiposMermas;
        break; 
      case "TiposDetenciones":
        menu[k].component = TiposDetenciones;
        break; 
      default:
        menu[k].component = false;
    }
  }
  routes.push(m);
});

export default routes;

import { Grid } from "@mui/material";
import { useState } from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import CuentasServiciosPrecioForm from "../cuentas/components/CuentasServiciosPrecioForm";
import {
  deleteTipoCuentasPrecio,
  getTipoCuentasPrecio,
  getTipoCuentasPrecioPaginated,
  postTipoCuentasPrecio,
} from "../cuentas/helpers/CuentasServiciosPrecioData";
import SwitchCell from "../cuentas/components/SwitchCell";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";

export const CuentasServiciosPrecioList = (props) => {
  //Estados para controlar estados de el formulario y datos de la tabla
  const [createButton, setCreateButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [buttonMetadatos, setButtonMetadatos] = useState(false);
  const [tipoCuentaPrecio, setTipoCuentaPrecio] = useState([]);
  const listName = "Precio por tipo de cuenta";

  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "detalle", headerName: "Tipo cuenta", flex: 0.5 },
    { field: "nombre", headerName: "Servicio", flex: 0.7 },
    {
      //Se defina una funcion de renderizado personalizada utilizando el componente SwitchCell
      field: "activo",
      headerName: "Activo",
      type: "actions",
      flex: 0.7,
      renderCell: (params) => (
        <SwitchCell
          row={params.row}
          checked={params.row.activo === "1" ? true : false}
        />
      ),
    },
    { field: "importe", headerName: "Importe", flex: 0.2 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
  ];

  const [options, setOptions] = useState({
    cols: columns,
    get: getTipoCuentasPrecio,
    getPaginated: getTipoCuentasPrecioPaginated,
    pageSize: [5, 10, 25],
    edit: true,
    delete: deleteTipoCuentasPrecio,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,
    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setTipoCuentaPrecio,
  });

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  //EL BUTTONMETADATOS SOLO ES PARA ESTE CASO, VERIFICAR CADA OCASION.
  if (
    createButton === false &&
    tipoCuentaPrecio.id !== undefined &&
    buttonMetadatos === false
  ) {
    setTipoCuentaPrecio([]);
  }

  return (
    <>
       <Grid style={{ marginBottom: "50px", background: "#FFFFFFF", paddingTop: 10 }}>
        <>
        <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          {listName}
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
          <ListTablePaginated options={options} />
          <CuentasServiciosPrecioForm
            data={tipoCuentaPrecio} //Datos para el formulario
            createButton={createButton} //Datos de los botones
            setCreateButton={setCreateButton} //Datos de los botones
            componentList={"/tipo_cuentas_servicios/admin"}
            postData={postTipoCuentasPrecio} //Funcion para realizar el insert
            setOptions={setOptions}
            options={options}
          />
        </>
      </Grid>
    </>
  );
};

import FormControl from "@mui/material/FormControl";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { getTipoContenidosYmetadatos } from "../helpers/TipoContenidosData";
import { getMetadatosWhereIdTipoContenidos } from "../helpers/MetadatoData";
import { ContenidosFormDinamico } from "./ContenidosFormDinamico";
import { postContenidos, updateContenidos } from "../helpers/ContenidosData";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Autocomplete, TextField } from "@mui/material";

export const TipoContenidosSelectProcesosVarios = (data) => {
    const {
        setTipoContenido, setShowForm,
        tipoContenidoSeleccionado, setTipoContenidoSeleccionado,
        retiroContenido,
        disabled, setModalStateCont, setMessage,
        modalStateCont, setSnackbarVisible, setSeverity

    } = useContext(CajasContext);

    //Variables para el formulario ya sea edicion o creacion
    const {
        modalStateContList,
        setModalStateContList,
        cajaId,
        setActualizarListadoContenidos,
        digitalizado,
        sectorId
    } = data;


    const [tiposContenidos, setTiposContenidos] = useState([]);
    const [metadatosList, setMetadatosList] = useState([]);
    const [inputsValues, setInputsValues] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [contenidosInfo, setContenidosInfo] = useState({
        id: "",
        id_cajas: cajaId,
        id_tipo_contenidos: tipoContenidoSeleccionado,
        meta_datos: [
            { id: "", id_metadatos: "1", valor: "" },
            { id: "", id_metadatos: "2", valor: "" },
            { id: "", id_metadatos: "3", valor: "" },
            { id: "", id_metadatos: "4", valor: "" },
            { id: "", id_metadatos: "5", valor: "" },
        ],
    });
    const [tipoContenidoSeleccionadoObject, setTipoContenidoSeleccionadoObject] = useState("");

    useEffect(() => {
        const getTipoContenido = async () => {
            setIsLoadingData(true);

            try {
                let idTipoContenido = data?.data?.id_tipo_contenidos;
                let objTipoContenido = data?.data?.relations?.tipo_contenidos;

                // Se obtienen todos los tipos de contenidos    
                let todosTipoContenidos = JSON.parse(localStorage.getItem("siam-tipo_contenidos"))?.tipo_contenidos || [];
                let cuentas_localizaciones_tipo_contenidos = JSON.parse(localStorage.getItem("siam-cuentas_localizaciones_tipo_contenidos"))?.cuentas_localizaciones_tipo_contenidos || [];

                cuentas_localizaciones_tipo_contenidos = cuentas_localizaciones_tipo_contenidos.filter(objeto => objeto.id_cuentas_localizaciones === sectorId);
                console.log("cuentas_localizaciones_tipo_contenidos", cuentas_localizaciones_tipo_contenidos)
                console.log("id_sector", sectorId)
                // Se valida si tipoContenidoListCuentaSector tiene valores
                if (cuentas_localizaciones_tipo_contenidos) {
                    // Si no hay datos en localStorage, se consumen desde el servicio
                    if (!todosTipoContenidos.length) {
                        todosTipoContenidos = await getTipoContenidosYmetadatos(digitalizado);
                    }

                    // Se filtran los tipos de contenidos vinculados a la cuenta
                    const tipoContenidosFiltrados = todosTipoContenidos.filter(tipoContenido =>
                        cuentas_localizaciones_tipo_contenidos.some(obj => obj.id_tipo_contenidos === tipoContenido.id)
                    );

                    setTiposContenidos(FiltrarPorDigitalizado(tipoContenidosFiltrados));
                }

                setIsLoadingData(false);

                if (!cuentas_localizaciones_tipo_contenidos) {
                    setTipoContenidoSeleccionado(idTipoContenido || "");
                    setTipoContenidoSeleccionadoObject(objTipoContenido || '');
                }
            } catch (error) {
                // Manejar el error según sea necesario
                console.error("Error al obtener tipo de contenido:", error);
            }
        };

        getTipoContenido();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function FiltrarPorDigitalizado(arrayDeObjetos) {
        return arrayDeObjetos;
        /*
        let objetosFiltrados;
        // Utilizamos el método filter para obtener un nuevo array con solo los objetos que cumplen la condición
        if (digitalizado) {
            objetosFiltrados = arrayDeObjetos.filter(objeto => objeto.digitalizado === '1');
        } else {
            objetosFiltrados = arrayDeObjetos.filter(objeto => objeto.digitalizado === '0');
        }

        // Devolvemos el nuevo array (puedes ajustar esto según tus necesidades)
        return objetosFiltrados;
        */
    }

    useEffect(() => {
        const getMetadatos = async () => {
            setIsLoadingData(true);

            try {
                const response = await getMetadatosWhereIdTipoContenidos(tipoContenidoSeleccionado);
                const valuesMetadatosEdit = data.data?.contenidos_metadatos || [];

                const responseMetadatos = response.map(metadato => {
                    const metadatoEdit = valuesMetadatosEdit.find(item => item.id_metadatos === metadato.id) || {};
                    return { ...metadato, valor: metadatoEdit.valor || "", id_metadato: metadatoEdit.id || "" };
                });

                setMetadatosList(responseMetadatos);
                data.setMetadatos?.(responseMetadatos);
                setInputsValues(responseMetadatos);
            } catch (error) {
                // Manejar el error según sea necesario
                console.error("Error al obtener metadatos:", error);
            } finally {
                setIsLoadingData(false);
            }
        };

        if (tipoContenidoSeleccionado !== "" && !Array.isArray(tipoContenidoSeleccionado)) {
            getMetadatos();
            setContenidosInfo(prevInfo => ({ ...prevInfo, id_tipo_contenidos: tipoContenidoSeleccionado }));
        } else {
            setMetadatosList([]);
            data.setMetadatos?.([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoContenidoSeleccionado]);


    useEffect(() => {
        const metadatosValues = inputsValues
            .filter(input => input.valor !== "" || input.id_metadato !== "")
            .map(input => ({
                id: input.id_metadato || input.id,
                id_metadatos: input.id,
                valor: input.valor,
            }));

        setContenidosInfo({ ...contenidosInfo, meta_datos: metadatosValues });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputsValues]);

    const toggleModal = () => {
        setModalStateCont(!modalStateCont);
        if (setModalStateContList) {
            setModalStateContList(!modalStateContList)
        }
        setTipoContenidoSeleccionado("");
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const id = data?.data?.id;
        const datos = { ...contenidosInfo, id_cajas: cajaId, id };

        let responseServicio;

        if (id) {
            responseServicio = await updateContenidos(datos);
            handleResponse(responseServicio, "Se ha modificado exitosamente el contenido");
        } else {
            responseServicio = await postContenidos(datos);
            handleResponse(responseServicio, "Se ha añadido exitosamente el contenido");
        }
    };

    const handleResponse = (response, successMessage) => {
        if (response.status === "false") {
            setMessage(response.message);
            setSeverity("error");
        } else {
            setMessage(successMessage);
            setSeverity("success");
            setActualizarListadoContenidos(true);
            setTipoContenidoSeleccionado("");
            toggleModal();

        }
        setSnackbarVisible(true);
    };

    const handleChange = (event, newValue) => {
        setTipoContenido(newValue.id);
        setShowForm(true);
        setTipoContenidoSeleccionado(newValue.id);
        setTipoContenidoSeleccionadoObject(newValue)
    };

    const estilosSelect = {
        fontSize: "1em", color: "#646464", fontFamily: 'Roboto, sans-serif', backgroundColor: 'white',
        borderRadius: 10,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    };

    if (isLoadingData && !data.ImportFile) {
        return (
            <Box sx={{ width: "100%" }}>
                <LinearProgress />
            </Box>
        );
    } else {
        return (
            <div>
                <FormControl sx={{ mt: data.ImportFile ? 2 : 1, minWidth: "100%" }} size="small">
                    <Autocomplete
                        size="small"
                        value={tipoContenidoSeleccionadoObject || null}
                        onChange={handleChange}
                        options={tiposContenidos}
                        style={data.ImportFile ? estilosSelect : null}
                        getOptionLabel={(option) => option.id + ' - ' + option.detalle || ""}
                        renderInput={(params) => (
                            <TextField {...params}
                                label="Tipos de Contenido"
                                disabled={disabled} />
                        )}
                    />
                </FormControl>
                {!data.ImportFile && (metadatosList.length > 0 &&
                    tipoContenidoSeleccionado !== "" &&
                    retiroContenido === false) ? (
                    <ContenidosFormDinamico
                        data={data}
                        inputsListValues={metadatosList}
                        toggleModal={toggleModal}
                        setInputsValues={setInputsValues}
                        handleSubmit={handleSubmit}
                        contenidoSeleccionado={data.contenidoSeleccionado}
                        selectorCajas={data.selectorCajas}
                    />
                ) : undefined}
            </div>
        );
    }
};

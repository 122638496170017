import { config } from "src/configs/config";
import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";

var myHeaders = new Headers();
myHeaders.append("Authorization", config.authToken);
myHeaders.append("Content-Type", "application/json");

export const getUbicaciones = async (auth) => {
    const ubicaciones = new datos('ubicaciones')
    const response = await ubicaciones.get()
    return response.wsResponse.ubicaciones
};

export const getDepositos = async (data) => {
    const deposito = new datos('owner_localizaciones')
    deposito.addCondition('owner_localizaciones','id',data.idDep,' = ');
    const response = await deposito.get()
    
    const res = response.wsResponse.owner_localizaciones;
    // Si es un array se mantiene como estaba
    if(Array.isArray(res)){
        var arr = res; 
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
    }else if(typeof res === 'undefined'){ // se pasa un array vacio
        arr = [];
    }else { // si trae un solo elemento , es un objeto
        arr = [res]; // se lo convierte a array
    }
   

    return arr
};

export const getUbicacionesFiltrado = async (data) => {
    const Objubicaciones = new datos('ubicaciones')
    Objubicaciones.addCondition('ubicaciones','id',0,'>');
    // Se añaden condiciones dependiendo de las coordenadas que se ingresen
    if(typeof data.idDep !== 'undefined'){
        Objubicaciones.addCondition('ubicaciones','id_owner_localizaciones',data.idDep,' = ');
    }
    if(typeof data.idSector !== 'undefined'){
        Objubicaciones.addCondition('ubicaciones','sector',data.idSector,' = ');
    }
    if(typeof data.idModulo !== 'undefined'){
        Objubicaciones.addCondition('ubicaciones','modulo',data.idModulo,' = ');
    }
    if(typeof data.idEstante !== 'undefined'){
        Objubicaciones.addCondition('ubicaciones','estante',data.idEstante,' = ');
    }
    if(typeof data.idPosicion !== 'undefined'){
        Objubicaciones.addCondition('ubicaciones','posicion',data.idPosicion,' = ');
    }
    if(typeof data.idEstiba !== 'undefined'){
        Objubicaciones.addCondition('ubicaciones','estibe',data.idEstiba,' = ');
    }
    if(typeof data.idProfundidad !== 'undefined'){
        Objubicaciones.addCondition('ubicaciones','profundidad',data.idProfundidad,' = ');
    }
    Objubicaciones.join("estados_ubicaciones", "id", "id_estados_ubicaciones", "=");
    
    const response = await Objubicaciones.get();
    const res = response.wsResponse.ubicaciones;


    // Si es un array se mantiene como estaba
    if(Array.isArray(res)){
        var arr = res; 
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
    }else if(typeof res === 'undefined'){ // se pasa un array vacio
        arr = [];
    }else { // si trae un solo elemento , es un objeto
        arr = [res]; // se lo convierte a array
    }
   
    return arr;
};

export const getUbicacionesPaginated = async (limit, offset,data) => {
    const ubicaciones = new datos('ubicaciones')
    ubicaciones.addCondition('ubicaciones','id',0,'>')
    // Se añaden condiciones dependiendo de las coordenadas que se ingresen
    if(typeof data.idDep !== 'undefined'){
        ubicaciones.addCondition('ubicaciones','id_owner_localizaciones',data.idDep,' = ');
    }
    if(typeof data.idSector !== 'undefined'){
        ubicaciones.addCondition('ubicaciones','sector',data.idSector,' = ');
    }
    if(typeof data.idModulo !== 'undefined'){
        ubicaciones.addCondition('ubicaciones','modulo',data.idModulo,' = ');
    }
    if(typeof data.idEstante !== 'undefined'){
        ubicaciones.addCondition('ubicaciones','estante',data.idEstante,' = ');
    }
    if(typeof data.idPosicion !== 'undefined'){
        ubicaciones.addCondition('ubicaciones','posicion',data.idPosicion,' = ');
    }
    if(typeof data.idEstiba !== 'undefined'){
        ubicaciones.addCondition('ubicaciones','estibe',data.idEstiba,' = ');
    }
    if(typeof data.idProfundidad !== 'undefined'){
        ubicaciones.addCondition('ubicaciones','profundidad',data.idProfundidad,' = ');
    }
    if(typeof data.id_tipo_cajas !== 'undefined'){
        ubicaciones.addCondition('ubicaciones','id_tipo_cajas',data.id_tipo_cajas,' = ');
    } 
    
    if(Boolean(data.id_estado_ubicaciones)){
        ubicaciones.addCondition('estados_ubicaciones','id',data.id_estado_ubicaciones,' = ');
    }
    ubicaciones.join("estados_ubicaciones", "id", "id_estados_ubicaciones", "=");
    ubicaciones.join("tipo_ubicaciones", "id", "id_tipo_ubicaciones", "=");
    ubicaciones.addLimitCondition(limit,offset)
    const response = await ubicaciones.get()
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/
    
    let result = {}
    let ubicacionesList = [];
    if (!response.wsResponse.empty) {
        if(response.wsResponse.ubicaciones){
            ubicacionesList= esArray(response.wsResponse.ubicaciones)
            for (let i = 0; i < ubicacionesList.length; i++) {
                ubicacionesList[i].estado = ubicacionesList[i]?.relations?.estados_ubicaciones?.detalle;
                ubicacionesList[i].tipo_ubicacion = ubicacionesList[i]?.relations?.tipo_ubicaciones?.detalle;
            }
        }
        
        result = {
            data: ubicacionesList,
            total_records: response.wsResponse.total_records? response.wsResponse.total_records : 0
        }
        return result

    }
};


export const postUbicaciones = async(ubicacion) => {

    const ubicaciones = new datos('ubicaciones')
    const response = ubicaciones.createUbicaciones(ubicacion)
    response.then(value => {
        if(value.status === 'false'){
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: value.message
            });
        }else{
            Swal.fire({
                icon: "success",
                title: "Genial!!!",
                text: "Se crearon "+value.wsResponse.cantidad_ubicaciones+" ubicaciones nuevas"
            });
        }
      })
    
    return response;
};
import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css";
const categorias = new datos("categorias");

export const getCategorias= async (auth) => {
  categorias.addCondition("categorias", "id", 0, ">");
  const response = await categorias.get();
  return esArray(response.wsResponse.categorias);
};

export const getCategoriasPaginated = async (limit, offset) => {
  categorias.addCondition("categorias", "id", 0, ">");
  categorias.addLimitCondition(limit, offset);
  const response = await categorias.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: esArray(response.wsResponse.categorias),
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const deleteCategoria = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar esta Categoria?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
    customClass: {
      container: 'my-swal-delete'
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      const categorias = new datos("categorias");
      let data = {
        id: id,
      };
      const response = await categorias.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "La categoria ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};
/* eslint-disable no-unused-vars */
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import { schemaTipoUbicacionesForm } from "src/forms/schemaTipoUbicacionesForm";
import { uiSchemaTipoUbicacionesForm } from "src/forms/uiSchemaTipoUbicacionesForm";
import { ComponentDialog } from "src/general_components/ComponentDialog";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";

const localize = require("ajv-i18n");
export const TipoUbicacionesForm = (props) => {
  const auth = useContext(AuthContext);
  const {
    data,
    listName,
    componentList,
    createButton,
    setCreateButton,
    postData,
  } = props;
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');

  const [dataInfo, setDataInfo] = useState({
    id: data.id || "",
    detalle: data.detalle || "",
    alto: parseInt(data.alto) || undefined,
    ancho: parseInt(data.ancho) || undefined,
    profundidad: parseInt(data.profundidad) || undefined,
  });

  //CONTROLA CUANDO SE SALE DE UN EDIT Y QUEDA CON BASURA LOS SELECTORES, COMPROBAR CUALQUIERA DE LOS DOS SELECTORES SI ES DISTINTO DE VACIO
  if (data.length === 0 && dataInfo.id !== "") {
    setDataInfo({
      id: "",
      detalle: "",
      alto: undefined,
      ancho: undefined,
      profundidad: undefined,
    });
  }

  //PARA QUE SE ACTUALICE EL SELECTOR CUANDO SE EDITA
  if (data.id && dataInfo.id === "") {
    setDataInfo({
      id: data.id || "",
      detalle: data.detalle || "",
      alto: parseInt(data.alto) || undefined,
      ancho: parseInt(data.ancho) || undefined,
      profundidad: parseInt(data.profundidad) || undefined,
    });
  }
  const [withErrors, setWithErrors] = useState(false);
  const [pushData, setPushData] = useState(false);
  const [redirect, setRedirect] = useState(false);


  if (redirect) {
    return <Redirect to={componentList} />; //Redirecciona a la pagina del listado dependiend del componente que se este usando
  }

  const post = async (dataPost) => {
    let response = await postData(dataPost, auth);
    if (response.status === 'true') {
      setRedirect(true);
    } else {
      setSnackbarVisible(true);
      setMessage('Revise los campos.');
      setSeverity('warning')
    }


    return response;
  };

  if (pushData) {
    let dataPost = {
      id: dataInfo.id || "",
      detalle: dataInfo.detalle || "",
      alto: dataInfo.alto ? `${dataInfo.alto}` : "",
      ancho: dataInfo.ancho ? `${dataInfo.ancho}` : "",
      profundidad: dataInfo.profundidad ? `${dataInfo.profundidad}` : "",
    }
    if (dataInfo.detalle !== "" && dataInfo.alto !== "" && dataInfo.ancho !== "" && dataInfo.profundidad !== "") {
      if (Number.isInteger(dataInfo.alto) && Number.isInteger(dataInfo.ancho) && Number.isInteger(dataInfo.profundidad)) {
        let response = post(dataPost, auth);

      } else {
        setSnackbarVisible(true);
        setMessage('Los campos alto, ancho y profundidad solo admiten numeros enteros');
        setSeverity('warning');
      }
      setPushData(false);
    } else {
      setPushData(false);
      setSnackbarVisible(true);
      setMessage('EL campo detalle es obligatorio');
      setSeverity('warning');
    }
  }

  // eslint-disable-next-line
  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);

    setDataInfo(data);

    return { errors, data };
  };


  return (
    <>
      <ComponentDialog
        listName={listName}
        buttonClicked={createButton}
        setButtonClicked={setCreateButton}
        withErrors={withErrors} //Sirve para controlar el boton de crear y los errorres del formulario
        setPushData={setPushData} //Sirve para enviar los datos al servidor
        child={
          <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>
            <JsonForms
              schema={schemaTipoUbicacionesForm}
              uischema={uiSchemaTipoUbicacionesForm}
              renderers={materialRenderers}
              data={dataInfo}
              cells={materialCells}
              onChange={({ errors, data }) => onChange && onChange(errors, data)}
            />

          </Grid>
        }
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => { setSnackbarVisible(false) }}
        hide={8000}
      />
    </>
  );
};


import React, { useContext, useEffect } from "react";
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import { CajasContext } from "src/context/CajasContext/CajasContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    display: "flex",
  };
}

export const FormEstadosCajas = (props = null) => {
  const { listDatosEstadosCajas, setListDatosEstadosCajas, setDataSearchFormEstadosCajas,
    formEstadosCajasDisabled, setFormEstadosCajasDisabled,
    setGrupoBusquedaEstadosCajas } = useContext(CajasContext);
  const theme = useTheme();
  const estadosList = props.estadosLista;
  //dataDefaultsAssigned y dataDefaultsOptions tienen diferentes referencias de objetos incluso si tienen el mismo valor, por lo que debe usar Array.filter para tener el valor inicial 
  let data = [];
  if (estadosList) {
    data = estadosList.filter((estado) =>
      listDatosEstadosCajas.some((obj) => obj.id === estado.id)
    )
  }

  const [estadosCajas, setEstadosCajas] = React.useState(data || []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setEstadosCajas(value);
    console.log(value, 'calue')
    value.length > 0 ?
      setGrupoBusquedaEstadosCajas(true) :
      setGrupoBusquedaEstadosCajas(false)
  };

  useEffect(() => {
    let data = "";
    setListDatosEstadosCajas(estadosCajas);
    for (var i = 0; i < estadosCajas.length; i++) {
      if (i === 0) {
        data += estadosCajas[i].id
      } else {
        data += "," + estadosCajas[i].id
      }
    }

    if (data !== "") {
      let datos = { id_estados_cajas: data }
      setDataSearchFormEstadosCajas(datos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadosCajas]);

  const limpiar = (e) => {
    setEstadosCajas([]);
    setFormEstadosCajasDisabled(false);
    setGrupoBusquedaEstadosCajas(false)
  };

  return (
    <div>
      <Grid container justifyContent="end" style={{ marginBottom: '3px' }}>

        <Button
          variant="contained"
          onClick={limpiar}
          color="secondary"

          sx={{
            padding: '5px',
            minWidth: 'auto',
          }}
        >
          <ReplayIcon style={{
            padding: '0px',      // Ajusta el relleno (padding) para hacer el botón más pequeño
            position: 'center',

          }} />
        </Button>

      </Grid>
      <FormControl sx={{ m: 1 }} fullWidth>
        <InputLabel id="demo-multiple-chip-label">Estados de Cajas</InputLabel>
        <Select
          {...formEstadosCajasDisabled ? { disabled: true } : {}}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={estadosCajas}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((item) => (
                <Chip key={item.id} label={item.detalle} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {estadosList.map((estado) => (
            <MenuItem
              key={estado.id}
              value={estado}
              style={getStyles(estado.detalle, estadosCajas, theme)}
            >
              {estado.detalle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>);
};

import CuentasProvider from "src/context/CuentasContext";
import CuentasContent from "../cuentas/components/CuentasContent";


export const CuentasJuridicas = () => {
  return (
    <CuentasProvider>
      <CuentasContent esJuridica={true}/>
    </CuentasProvider>
  );
}

export default CuentasJuridicas;

import Swal from "sweetalert2";
import datos from "../services/datos";
import Getters from "./getters";
import { decode } from "base-64";
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import { parametros_proyecto, id_proyecto } from "src/configs/config";
// import { ExitToApp } from "@mui/icons-material";

const userData = new datos('usuarios');

// Función para buscar los parametros del proyecto
function buscarPorIdProyecto(id) {
    return parametros_proyecto?.find(objeto => objeto.id === id);
}
const proyecto_encontrado = buscarPorIdProyecto(id_proyecto);

const aws_id_cliente_aplicacion = proyecto_encontrado?.aws_id_cliente_aplicacion;
const aws_id_grupo_user = proyecto_encontrado?.aws_id_grupo_user;
const aws_iam_accessKeyId = proyecto_encontrado?.aws_iam_accessKeyId;
const aws_iam_secretAccessKey = proyecto_encontrado?.aws_iam_secretAccessKey;
const aws_url_clave_firma = proyecto_encontrado?.aws_url_clave_firma;
const useCognito = localStorage.getItem("cognitoUse");

export const getUsers = async (auth) => {
    userData.join('usuarios_cuentas', 'id_usuarios', 'id');
    userData.join('cuentas_personas_juridicas', 'id_cuentas', 'usuarios_cuentas.id_cuentas');

    // Filtro por cuentas vinculadas al usuario
    const getter = new Getters()
    const user_id_cuentas = getter.getLocalUserCuentas()
    user_id_cuentas ?
        userData.addCondition('usuarios_cuentas', 'id_cuentas', user_id_cuentas, 'in') :
        userData.addCondition('usuarios', 'id', 0, '>');

    const response = await userData.get();

    let users = ''
    if (response.wsResponse.usuarios) {
        users = typeof response.wsResponse.usuarios.user_id === 'undefined' ?
            response.wsResponse.usuarios :
            [response.wsResponse.usuarios]

    }

    return users;
};

export const getUsers2 = async () => {
    userData.join('usuarios_cuentas', 'id_usuarios', 'id');
    userData.join('cuentas_personas_juridicas', 'id_cuentas', 'usuarios_cuentas.id_cuentas');

    // Filtro por cuentas vinculadas al usuario
    const getter = new Getters()
    const user_id_cuentas = getter.getLocalUserCuentas()
    user_id_cuentas ?
        userData.addCondition('usuarios_cuentas', 'id_cuentas', user_id_cuentas, 'in') :
        userData.addCondition('usuarios', 'id', 0, '>');

    const response = await userData.get();
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/
    if (response) {
        return [...response.wsResponse.usuarios]
    }
    return response
};

export const getUsersPaginated = async (limit, offset) => {
    const userData = new datos('usuarios');
    userData.join('usuarios_cuentas', 'id_usuarios', 'id');
    userData.join('cuentas_personas_juridicas', 'id_cuentas', 'usuarios_cuentas.id_cuentas');

    // Filtro por cuentas vinculadas al usuario
    const getter = new Getters()
    const user_id_cuentas = getter.getLocalUserCuentas()
    user_id_cuentas ?
        userData.addCondition('usuarios_cuentas', 'id_cuentas', user_id_cuentas, 'in') :
        userData.addCondition('usuarios', 'id', 0, '>');
    userData.addLimitCondition(limit, offset);
    const response = await userData.get();
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

    let result = {};
    if (response.wsResponse.usuarios) {
        result = {
            data: [...response.wsResponse.usuarios],
            total_records: response.wsResponse.total_records,
        };
        return result;
    }

};

export const getCuentaBusqueda = async (nombre_fantasia, id_cuentas = false) => {

    const cuentasData = new datos('cuentas_personas_juridicas');
    id_cuentas ?
        cuentasData.addCondition('cuentas_personas_juridicas', 'id_cuentas', id_cuentas, 'in') :
        cuentasData.addCondition('cuentas_personas_juridicas', 'nombre_fantasia', nombre_fantasia, 'like')

    const response = await cuentasData.get();

    let cuentas_personas_juridicas = ''
    if (response.wsResponse.cuentas_personas_juridicas) {
        cuentas_personas_juridicas = typeof response.wsResponse.cuentas_personas_juridicas.id_cuentas === 'undefined' ?
            response.wsResponse.cuentas_personas_juridicas :
            [response.wsResponse.cuentas_personas_juridicas]
    }

    return cuentas_personas_juridicas;
}

const poolData = {
    // UserPoolId: 'us-east-1_IicsmhSD0',
    // ClientId: '58lb2ugcj8ukmk2hl7q9sh4rbq',
    UserPoolId: aws_id_grupo_user,
    ClientId: aws_id_cliente_aplicacion
};

const adminToken = localStorage["cognitoAccessToken"] ? decode(localStorage["cognitoAccessToken"]) : ''


AWS.config.update({
    // accessKeyId: 'AKIASIOYXQSFJLSQ2RG2',  // Reemplaza con tu Access Key ID
    // secretAccessKey: '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',  // Reemplaza con tu Secret Access Key

    accessKeyId: aws_iam_accessKeyId,  // Reemplaza con tu Access Key ID
    secretAccessKey: aws_iam_secretAccessKey,  // Reemplaza con tu Secret Access Key
    region: 'us-east-1'
});

const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
    region: 'us-east-1'
});

const deleteUserCognito = async (username) => {
    const params = {
        UserPoolId: poolData.UserPoolId,
        Username: username,
    };

    return cognitoIdentityServiceProvider.adminDeleteUser(params).promise();
};

// eslint-disable-next-line
const migrateUser = async (user) => {

    try {

        let cognitoUser = '';

        const attributeList = [
            // new CognitoUserAttribute({ Name: 'username', Value: user.username }),
            new CognitoUserAttribute({ Name: 'email', Value: user.email }),
            new CognitoUserAttribute({ Name: 'profile', Value: user.id_perfiles }),
            new CognitoUserAttribute({ Name: 'name', Value: user.apellido + ' ' + user.nombre }),

        ];

        console.log('DELETE USER')
        await deleteUserCognito(user.userNameOrigi);


        const userPool = new CognitoUserPool(poolData);

        userPool.signUp(user.username, user.password, attributeList, null, (err, result) => {
            if (err) {
                console.error('Error al registrar usuario en Cognito', err);
                return;
            }
            cognitoUser = result.user;

            console.log('Usuario registrado en Cognito:', cognitoUser);
        });

    } catch (error) {
        console.error('Error al migrar usuario:', error);
    }
};

// eslint-disable-next-line
const updateUserAttributes = async (user) => {

    console.log('UPDATE DATA USER')
    const attributeList = [
        new CognitoUserAttribute({ Name: 'preferred_username', Value: user.username }),
        new CognitoUserAttribute({ Name: 'email', Value: user.email }),
        new CognitoUserAttribute({ Name: 'profile', Value: user.id_perfiles }),
        new CognitoUserAttribute({ Name: 'name', Value: `${user.apellido} ${user.nombre}` })
    ];

    try {
        const params = {
            UserPoolId: poolData.UserPoolId,
            Username: user.userNameOrigi ? user.userNameOrigi : user.username, // Original username
            UserAttributes: attributeList
        };
        // TODO: SE DEBE COMPROBAR SI FUNCIONA EL PARAMETRO aws_url_clave_firma CONTENIDO EN EL ARCHIVO CONFIG

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: poolData.UserPoolId,
            Logins: {
                [aws_url_clave_firma]: adminToken
            }
        });

        await cognitoIdentityServiceProvider.adminUpdateUserAttributes(params).promise();
        console.log('Atributos de usuario actualizados correctamente por el administrador');
    } catch (error) {
        console.error('Error al actualizar los atributos del usuario:', error);
    }
};

export const postUser = async (user) => {



    if (user.id) {
        if (user.id) {
            if (!user.nombre || !user.apellido || !user.username || !user.email) {
                let respuesta = { status: 'false' }
                return respuesta;
            }
        } else {
            if (!user.nombre || !user.apellido || !user.username || !user.password || !user.email) {
                let respuesta = { status: 'false' }
                return respuesta;
            }
        }
    }

    const userData = new datos('usuarios');
    let data = {
        "nombre": user.nombre,
        "apellido": user.apellido,
        "username": user.username,
        "password": user.password,
        "email": user.email,
        "id_perfiles": user.id_perfiles,
        "id_owner": user.id_owner,
    };
    if (user.id) {
        if (user.password !== "" && user.password !== undefined) {
            data = {
                "id": user.id,
                "nombre": user.nombre,
                "apellido": user.apellido,
                "username": user.username,
                "password": user.password,
                "email": user.email,
                "id_perfiles": user.id_perfiles,
                "id_owner": user.id_owner,
            };
        } else {
            data = {
                "id": user.id,
                "nombre": user.nombre,
                "apellido": user.apellido,
                "username": user.username,
                "email": user.email,
                "id_perfiles": user.id_perfiles,
                "id_owner": user.id_owner,
            };
        }

    }
    const response = await userData.insert(data);
    const tmp_user_data = response.wsResponse

    if (response.status === "true" && !user.id) {
        if (user.id_cuentas.length > 0) {
            user.id_cuentas.forEach((id) =>
                postCuentasUsuario(id, tmp_user_data.usuarios)
            );

        }
        // TODO:COMENTADO PARA IMPLEMENTACION DE COGNITO
        if (response.status === "true" && useCognito) {


            if (!user.nombre || !user.apellido || !user.username || !user.password || !user.email) {
                let respuesta = { status: 'false' }
                return respuesta;
            }

            let cognitoUser = '';

            const attributeList = [
                // new CognitoUserAttribute({ Name: 'preferred_username', Value: user.username }),
                new CognitoUserAttribute({ Name: 'email', Value: user.email }),
                new CognitoUserAttribute({ Name: 'profile', Value: user.id_perfiles }),
                new CognitoUserAttribute({ Name: 'name', Value: user.apellido + ' ' + user.nombre }),

            ];

            const userPool = new CognitoUserPool(poolData);

            userPool.signUp(user.username, user.password, attributeList, null, (err, result) => {
                if (err) {
                    console.error('Error al registrar usuario en Cognito', err);
                    return;
                }
                cognitoUser = result.user;

                console.log('Usuario registrado en Cognito:', cognitoUser);
            });

            console.log(cognitoUser, 'cognitoUser')

        }
    }
    // TODO:COMENTADO PARA IMPLEMENTACION DE COGNITO
    else if (response.status === "true" && user.id && useCognito) {

        // if (user.id) {
        console.log('UPDATE USER')
        if (!user.nombre || !user.apellido || !user.username || !user.email) {
            let respuesta = { status: 'false' }
            return respuesta;
        }

        // Si el usuario es un administrador y desea cambiar la contraseña de otro usuario
        if (user.password && user.password !== '') {

            console.log('UPDATE PASSWORD')
            try {
                const params = {
                    UserPoolId: poolData.UserPoolId,
                    Username: user.userNameOrigi,
                    Password: user.password,
                    Permanent: true // La contraseña se establece como permanente
                };
                console.log(params, 'params')
                let res = await cognitoIdentityServiceProvider.adminSetUserPassword(params).promise();
                console.log('Contraseña cambiada correctamente por el administrador', res);
            } catch (error) {
                console.error('Error al cambiar la contraseña por el administrador:', error);
                return { status: 'false', message: error.message };
            }
        }
        // console.log(user.userNameOrigi, '!== user.username')
        // console.log(user.username, 'user.userNameOrigi ')
        // console.log(user, 'user ')
        // Migrate the user if the username is being changed
        if ((user.userNameOrigi !== undefined && user.userNameOrigi !== user.username) || (user.userMailOrig !== user.email)) {
            await migrateUser(user);
        } else {
            await updateUserAttributes(user);
        }


    }

    return response;
};

export const postUserMiPerfil = async (user) => {
    if (user.id) {
        if (!user.nombre || !user.apellido || !user.username) {
            let respuesta = { status: 'false' }
            return respuesta;
        }
    } else {
        if (!user.nombre || !user.apellido || !user.username || !user.password || !user.email) {
            let respuesta = { status: 'false' }
            return respuesta;
        }
    }
    const userData = new datos('usuarios');
    let data = {
        "nombre": user.nombre,
        "apellido": user.apellido,
        "username": user.username,
        "password": user.password,
        "email": user.email,
    };
    if (user.id) {
        if (user.password !== "" && user.password !== undefined) {
            data = {
                "id": user.id,
                "nombre": user.nombre,
                "apellido": user.apellido,
                "username": user.username,
                "password": user.password,
                "email": user.email,
            };
        } else {
            data = {
                "id": user.id,
                "nombre": user.nombre,
                "apellido": user.apellido,
                "username": user.username,
                "email": user.email,
            };
        }

    }
    const response = await userData.insert(data);
    const tmp_user_data = response.wsResponse
    if (response.status === "true" && !user.id) {
        if (user.id_cuentas.length > 0) {
            user.id_cuentas.forEach((id) =>
                postCuentasUsuario(id, tmp_user_data.usuarios)
            );

        }
        // TODO:COMENTADO PARA IMPLEMENTACION DE COGNITO
        if (response.status === "true" && useCognito) {


            if (!user.nombre || !user.apellido || !user.username || !user.password || !user.email) {
                let respuesta = { status: 'false' }
                return respuesta;
            }

            let cognitoUser = '';

            const attributeList = [
                // new CognitoUserAttribute({ Name: 'preferred_username', Value: user.username }),
                new CognitoUserAttribute({ Name: 'email', Value: user.email }),
                new CognitoUserAttribute({ Name: 'profile', Value: user.id_perfiles }),
                new CognitoUserAttribute({ Name: 'name', Value: user.apellido + ' ' + user.nombre }),

            ];

            const userPool = new CognitoUserPool(poolData);

            userPool.signUp(user.username, user.password, attributeList, null, (err, result) => {
                if (err) {
                    console.error('Error al registrar usuario en Cognito', err);
                    return;
                }
                cognitoUser = result.user;

                console.log('Usuario registrado en Cognito:', cognitoUser);
            });

            console.log(cognitoUser, 'cognitoUser')

        }
    }
    // TODO:COMENTADO PARA IMPLEMENTACION DE COGNITO
    else if (response.status === "true" && user.id && useCognito) {

        // if (user.id) {
        console.log('UPDATE USER')
        if (!user.nombre || !user.apellido || !user.username || !user.email) {
            let respuesta = { status: 'false' }
            return respuesta;
        }

        // Si el usuario es un administrador y desea cambiar la contraseña de otro usuario
        if (user.password && user.password !== '') {

            console.log(user, 'UPDATE PASSWORD')
            try {
                const params = {
                    UserPoolId: poolData.UserPoolId,
                    Username: user.userNameOrigi,
                    Password: user.password,
                    Permanent: true // La contraseña se establece como permanente
                };
                console.log(params, 'params')
                let res = await cognitoIdentityServiceProvider.adminSetUserPassword(params).promise();
                console.log('Contraseña cambiada correctamente por el administrador', res);
            } catch (error) {
                console.error('Error al cambiar la contraseña por el administrador:', error);
                return { status: 'false', message: error.message };
            }
        }
        // console.log(user.userNameOrigi, '!== user.username')
        // console.log(user.username, 'user.userNameOrigi ')
        // console.log(user, 'user ')
        // Migrate the user if the username is being changed
        if ((user.userNameOrigi !== undefined && user.userNameOrigi !== user.username) || (user.userMailOrig !== user.email)) {
            await migrateUser(user);
        } else {
            await updateUserAttributes(user);
        }


    }
    return response;
};

export const deleteUser = async (id, params) => {
    let usuario = params.row.username ? params.row.username : "";
    let usuarioLogeado = JSON.parse(localStorage.getItem("user")).username;

    let status = false;

    if (usuario === usuarioLogeado) {
        await Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No se puede eliminar el usuario con el cual se encuentra logueado."
        });
    } else {
        await Swal.fire({
            title: "Esta seguro de que desea eliminar este usuario?",
            text: "Si sigue adelante, no se podran revertir los cambios!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, quiero eliminarlo!",
            cancelButtonText: "No, no estoy seguro!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const userData = new datos('usuarios');
                let data = {
                    id: id
                }
                await deleteCuentasUsuario(id)
                const response = await userData.delete(data);
                if (useCognito) {
                    let s = await deleteUserCognito(usuario);
                    console.log(s, 'ss')
                }

                if (response.status === "true") {
                    status = true;
                    await Swal.fire(
                        "Eliminado!",
                        "El usuario ha sido eliminado exitosamente",
                        "success"
                    );
                } else {
                    await Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response.message
                    });
                }
            }
        });
    }

    return status
};


/////post cuentas

export const postCuentasUsuario = async (id_cuenta, id_usuarios) => {
    const userData = new datos('usuarios_cuentas');
    let data = {
        "id_usuarios": id_usuarios,
        "id_cuentas": id_cuenta,
    }
    const response = await userData.insert(data);

    return response;
};

export const deleteCuentasUsuario = async (id) => {
    let status = false;
    const userData = new datos('usuarios_cuentas');
    let data = { "id_usuarios": id }
    const response = await userData.delete(data);
    if (response.status === 'true') {
        status = response.status
    }

    return status
};
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import Grid from "@mui/material/Grid";
import { useContext, useState } from "react";
import { Buttons } from "src/general_components/Buttons";
import { CajasContext } from "../../../context/CajasContext/CajasContext";
import { DateFormComponent } from "../../../forms/customRenders/dateForm";
import { SelectorClienteComponent } from "../../../forms/customRenders/selectorClientes";
import { SelectorCodigoCajasComponent } from "../../../forms/customRenders/selectorCodigoCajas";
import { SelectorComprobanteEstadoComponent } from "../../../forms/customRenders/selectorComprobanteEstado";
import { SelectorComprobantesCodigoComponent } from "../../../forms/customRenders/selectorComprobantesCodigo";
import { SelectorPuntoVentaComponent } from "../../../forms/customRenders/selectorPuntoVenta";
import { SelectorTerminalComponent } from "../../../forms/customRenders/selectorTerminal";
import { SelectorTipoComprobantesComponent } from "../../../forms/customRenders/selectorTipoComprobantes";
import { SelectorTipoFechaComponent } from "../../../forms/customRenders/selectorTipoFecha";
import { schemaComprobantesForm } from "../../../forms/schemaComprobantesForm";
import { uiSchemaComprobantesForm } from "../../../forms/uiSchemaComprobantesForm";

export const FormConsultaComprobantes = (props = null) => {
  const {
    setcriteriaComprobantes,
    setSnackbarVisible,
    setSeverity,
    setMessage,
  } = useContext(CajasContext);

  /// Contiene los datos del Jsonform
  const [dataForm, setDataForm] = useState({});

  // setDataForm({
  //   comprobante_codigo: [],
  // });

  const handleSubmit = async () => {
    //Creamos variables de las fechas, solo se usan para las condiciones
    let fecha_desde = dataForm.fecha_desde;
    let fecha_hasta = dataForm.fecha_hasta;
    if (
      fecha_hasta &&
      fecha_desde &&
      (fecha_desde > fecha_hasta || fecha_desde === fecha_hasta)
    ) {
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage("Fecha incorrecta");
      return;
    }

    if (fecha_desde?.length === 0) {
      delete dataForm["fecha_desde"];
    }

    if (fecha_hasta?.length === 0) {
      delete dataForm["fecha_hasta"];
    }
    //Eliminamos las fechas del dataForm porque no la vamos a usar más
    // delete dataForm.fecha_desde;
    // delete dataForm.fecha_hasta;
    if (!fecha_hasta && !fecha_desde && Object.entries(dataForm).length === 0) {
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage("Debe completar al menos un campo");
      return;
    }
    //Convertimos el objeto dataForm en un array
    var array = Object.entries(dataForm);
    //Creamos el JSON de la pegada al servicio 
    let criteriaComprobantes = array.map(([key, value]) => {
      let objeto = {};
      let propiedaModel = "";
      let propiedadColum = "";
      let propiedadFilter = "";
      let propiedadValue = value;
      switch (key) {
        case "cliente":
          propiedaModel = "comprobantes_cuentas";
          propiedadColum = "id_cuentas";
          propiedadFilter = "equal";
          propiedadValue = value;
          break;
        case "comprobante_codigo":
          propiedaModel = "comprobantes_codigos";
          propiedadColum = "id";
          propiedadFilter = "equal";
          propiedadValue = value;
          break;
        case "comprobante_estado":
          propiedaModel = "comprobantes_estados";
          propiedadColum = "id";
          propiedadFilter = "equal";
          propiedadValue = value;
          break;
        case "tipo":
          propiedaModel = "comprobantes_tipo";
          propiedadColum = "id";
          propiedadFilter = "equal";
          propiedadValue = value;
          break;
        case "codigo_cajas":
          propiedaModel = "codigo_caja";
          propiedadColum = "id";
          propiedadFilter = "equal";
          propiedadValue = value;
          break;
        case "terminal":
          propiedaModel = "terminal";
          propiedadColum = "id";
          propiedadFilter = "equal";
          propiedadValue = value;
          break;
        case "punto_venta":
          propiedaModel = "puntos_venta";
          propiedadColum = "id";
          propiedadFilter = "equal";
          propiedadValue = value;
          break;
        case "fecha_desde":
          propiedaModel = "comprobantes";
          propiedadColum = "fecha_comprobante";
          propiedadFilter = ">";
          propiedadValue = value;
          break;
        case "fecha_hasta":
          propiedaModel = "comprobantes";
          propiedadColum = "fecha_comprobante";
          propiedadFilter = "<";
          propiedadValue = value;
          break;
        case "tipo_fecha":
          propiedaModel = "comprobantes";
          propiedadColum = value === 1 ? "fecha_comprobante" : "fecha_trabajo";
          if (fecha_desde && fecha_hasta) {
            propiedadFilter = "between";
            propiedadValue = fecha_desde + "," + fecha_hasta;
          } else if (fecha_desde) {
            propiedadFilter = "greater";
            propiedadValue = fecha_desde;
          } else {
            propiedadFilter = "smaller";
            propiedadValue = fecha_hasta;
          }
          break;
        default:
          break;
      }
      objeto["model"] = propiedaModel;
      objeto["column"] = propiedadColum;
      objeto["value"] = propiedadValue;
      objeto["filter"] = propiedadFilter;
      return objeto;
    });
    setcriteriaComprobantes(criteriaComprobantes);
    handleClose();
  };
  const handleClose = () => {
    props.cerrarModal(false);
  };

  return (
    <div>
      <JsonForms
        schema={schemaComprobantesForm}
        uischema={uiSchemaComprobantesForm}
        data={dataForm}
        renderers={[
          ...materialRenderers,
          DateFormComponent,
          SelectorClienteComponent,
          SelectorTipoComprobantesComponent,
          SelectorComprobantesCodigoComponent,
          SelectorCodigoCajasComponent,
          SelectorComprobanteEstadoComponent,
          SelectorPuntoVentaComponent,
          SelectorTerminalComponent,
          SelectorTipoFechaComponent,
        ]}
        cells={materialCells}
        onChange={({ data, _errors }) => setDataForm(data)}
      />
      <Grid container style={{ paddingTop: "15px", justifyContent: "center" }}>
        <Buttons
          variant="contained"
          color="success"
          onClickSubmit={handleSubmit}
          labelButtonSave={"Buscar"}
          buttonCerrar={handleClose}
        />
      </Grid>
    </div>
  );
};

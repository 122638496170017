import datos from "src/services/datos";
import { esArray } from "src/general_components/functionEsArray";

export const getCuentasLocalizaciones = async (clienteId) => {
  const cuentaLocalizaciones = new datos("cuentas_localizaciones");
  cuentaLocalizaciones.addCondition("cuentas_localizaciones", "id_cuentas", parseInt(clienteId), "=")
  cuentaLocalizaciones.join('cuentas_localizaciones_tipo_contenidos',"id_cuentas_localizaciones","id", "=")

  const response = await cuentaLocalizaciones.get();
  
  const arrResponse = response ? esArray(response.wsResponse.cuentas_localizaciones) : response;
  return arrResponse;

};

export const getCuentasLocalizacionesAll = async () => {
  const cuentaLocalizaciones = new datos("cuentas_localizaciones");
  cuentaLocalizaciones.addCondition("cuentas_localizaciones", "id",0, ">")

  const response = await cuentaLocalizaciones.get();
  
  const arrResponse = response ? esArray(response.wsResponse.cuentas_localizaciones) : response;
  return arrResponse;

};
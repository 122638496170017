import { Switch } from "@mui/material";
import { postTipoCuentasPrecio } from "../helpers/CuentasServiciosPrecioData";
import { useState } from "react";

const SwitchCell = ({ row, checked }) => {
  //Row datos de la fila de la tabla y checked el estado inicial
  const [isChecked, setIsChecked] = useState(checked);

  const handleSwitchChange = (event) => {
    //Toma el evento del switch asignándolo a activo
    const activo = event.target.checked;
    //Crea un nuevo estado con los datos para enviar al POST
    const estado = {
      id: row.id,
      activo: activo ? "1" : "0",
    };
    //Función que envía el estado actualizado al servidor
    postTipoCuentasPrecio(estado);
    //Cambia el estado del el checked
    setIsChecked(activo);
  };

  return <Switch checked={isChecked} onChange={handleSwitchChange} />;
};

export default SwitchCell;

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import DoneIcon from '@mui/icons-material/Done';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import ClearIcon from '@mui/icons-material/Clear';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ArchiveIcon from '@mui/icons-material/Archive';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import WidgetsIcon from '@mui/icons-material/Widgets';
import TimelineIcon from '@mui/icons-material/Timeline';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';

export const estadoColores = {
    "Creada": "#25B03E",
    "Sin Ubicación": "#EAAA0C",
    "Alojada": "#2E6CEA",
    "Alojada Temporalmente": "pink",
    "Creada Genérica": "purple",
    "Alojada Genérica": "gray",
    "En Cliente": "teal",
    "Alojada - Esperando devolución": "brown",
    "Baja": "red",
    "No aplica": "black",
};

export const estadoIconos = {
    "Creada": <CheckCircleOutlineIcon />,
    "Sin Ubicación": <LocationOffIcon />,
    "Alojada": <WhereToVoteIcon />,
    "Alojada Temporalmente": <AccessTimeIcon />,
    "Creada Genérica": <PlaylistAddIcon />,
    "Alojada Genérica": <PlaylistAddCheckIcon />,
    "En Cliente": <PersonPinCircleIcon />,
    "Alojada - Esperando devolución": <AlarmOnIcon />,
    "Baja": <RemoveCircleOutlineIcon />,
    "No aplica": <CloseIcon />,
};


export const estadoColoresContenidos = {
    "Activo": "#25B03E",
    "Activo Retirado Parcialmente": "#EAAA0C",
    "Activo Retirado Totalmente": "#2E6CEA",
    "Inactivo - Quitado Totalmente": "pink",
};

export const estadoIconosContenidos = {
    "Activo": <DoneIcon />,
    "Activo Retirado Parcialmente": <DownloadDoneIcon />,
    "Activo Retirado Totalmente": <RemoveDoneIcon />,
    "Inactivo - Quitado Totalmente": <ClearIcon />,
};

export const iconosMarcadoCajasList = {
    "retiro_cajas": UnarchiveIcon,
    "retiro_definitivo": UnarchiveIcon,
    "baja_expurgue": DisabledByDefaultIcon,
    "devolucion_cajas": ArchiveIcon,
    "ubicacion_cajas": WidgetsIcon,
    "ubicacion_temporal": MoveUpIcon,
    "mudanza_cajas": TimelineIcon,
};

export const iconosMarcadoCajas = {
    "retiro_cajas": <UnarchiveIcon />,
    "retiro_definitivo": <UnarchiveIcon />,
    "baja_expurgue": <DisabledByDefaultIcon />,
    "devolucion_cajas": <ArchiveIcon />,
    "ubicacion_cajas": <WidgetsIcon />,
    "ubicacion_temporal": <MoveUpIcon />,
    "mudanza_cajas": <TimelineIcon />,
};

export const colorMarcadoCajas = {
    "retiro_cajas": '#003e90',
    "retiro_definitivo": '#2e58af',
    "baja_expurgue": '#4c73ce',
    "devolucion_cajas": '#ca7821',
    "ubicacion_cajas": '#097835',
    "ubicacion_temporal": '#149650',
    "mudanza_cajas": '#1cb66c',
};

export const iconosMarcadoContenidos = {
    "retiro_contenido_parcial": <UnarchiveIcon />,
    "retiro_contenido_total": <UnarchiveIcon />,
    "quita_total_contenidos": <OpenInNewOffIcon />,
    "devolucion_contenido_total": <ArchiveIcon />,
    "devolucion_contenidos_parcial": <ArchiveIcon />
};

export const colorMarcadoContenidos = {
    "retiro_contenido_parcial": '#5e492d',
    "retiro_contenido_total": '#7b6447',
    "quita_total_contenidos": '#998062',
    "devolucion_contenido_total": '#a832e7',
    "devolucion_contenidos_parcial": '#c44ff9'
};

export const iconosMarcadoContenidosList = {
    "retiro_contenido_parcial": UnarchiveIcon ,
    "retiro_contenido_total": UnarchiveIcon ,
    "quita_total_contenidos": OpenInNewOffIcon ,
    "devolucion_contenido_total": ArchiveIcon ,
    "devolucion_contenidos_parcial": ArchiveIcon
};



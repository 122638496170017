import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { CajasProvider } from "src/context/CajasContext/CajasContext"; // Se necesita para el listablePaginated ubicacion
import Swal from "sweetalert2";
import { SelectorDepositoComponent } from "../../../forms/customRenders/selectorDeposito";
import { schema } from "../../../forms/schemaUbicaciones";
import { uischema } from "../../../forms/uiSchemaUbicaciones";
import {
  getDepositos,
  getUbicacionesFiltrado,
  getUbicacionesPaginated,
  postUbicaciones,
} from "../helpers/UbicacionesData";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";
import { SelectorTipoUbicacionesComponent } from "../../../forms/customRenders/selectorTipoUbicaciones";
import { SelectorEstadoUbicacionesComponent } from "../../../forms/customRenders/selectorEstadoUbicaciones";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";


export const Ubicaciones = () => {
  const [data, setData] = useState({
    idDep: "",
    id_tipo_ubicaciones: "",
    id_estado_ubicaciones: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [ubicacionesObtenidas, setUbicacionesObtenidas] = useState([]);


  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [refresh, setRefresh] = useState(false);
  const [formReadOnly, setFormReadOnly] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validacionCompleta, setValidacionCompleta] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [ubicaciones, setUbicaciones] = useState({});

  let propDeposito = "id_owner_localizaciones";
  let propSector = "";
  let propModulo = "";
  let propEstante = "";
  let propPosicion = "";
  let propEstibe = "";
  let propProfundidad = "";
  let propTipo = "";
  let propEstado = "";

  let valorDeposito = data.idDep;
  let valorSector = "";
  let valorModulo = "";
  let valorEstante = "";
  let valorPosicion = "";
  let valorEstibe = "";
  let valorProfundidad = "";
  let valorTipo = data.id_tipo_ubicaciones;
  let valorEstado = data.id_estado_ubicaciones;

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "estado", headerName: "Estado", align: "center", flex: 0.3 },
    { field: "tipo_ubicacion", headerName: "Tipo Ubicacion", align: "center", flex: 0.3 },
    { field: "id_owner_localizaciones", headerName: "Dep", flex: 0.3 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    { field: "sector", headerName: "Sector", flex: 0.3 },
    { field: "modulo", headerName: "Modulo", align: "center", flex: 0.3 },
    { field: "estante", headerName: "Estante", align: "center", flex: 0.3 },
    { field: "posicion", headerName: "Posicion", align: "center", flex: 0.3 },
    { field: "estibe", headerName: "Estibe", align: "center", flex: 0.3 },
    { field: "profundidad", headerName: "Profundidad", align: "center", flex: 0.3, },
  ];

  const [options, setOptions] = useState({
    cols: columns,
    get: getUbicacionesFiltrado,
    getPaginated: getUbicacionesPaginated,
    idElementData: data,
    pageSize: [5, 10, 25],
    edit: false,
    limit: 50,
    sinFilas: true,
    filter: true,
    exportData: true,
    server: true,

  });


  const limpiarFormulario = async () => {
    setData({ idDep: "", id_tipo_ubicaciones: "" });
    setFormReadOnly(false);
    setValidacionCompleta(true);
    setOptions({
      ...options,
      sinFilas: true,
    });
    setUbicacionesObtenidas([]);
  };

  const validarIngresoIntercalado = () => {
    var validarCantSector = data.cantSector
      ? data.idModulo ||
        data.idEstante ||
        data.idPosicion ||
        data.idEstiba ||
        data.idProfundidad
        ? false
        : true
      : true;
    var validarCantModulo = data.cantModulo
      ? data.idEstante || data.idPosicion || data.idEstiba || data.idProfundidad
        ? false
        : true
      : true;
    var validarCantEstante = data.cantEstante
      ? data.idPosicion || data.idEstiba || data.idProfundidad
        ? false
        : true
      : true;
    var validarCantPosicion = data.cantPosicion
      ? data.idEstiba || data.idProfundidad
        ? false
        : true
      : true;
    var validarCantEstibe = data.cantEstiba
      ? data.idProfundidad
        ? false
        : true
      : true;

    var validacionFinal =
      validarCantSector &&
        validarCantModulo &&
        validarCantEstante &&
        validarCantPosicion &&
        validarCantEstibe
        ? true
        : false;

    return validacionFinal;
  };

  const asignarValores = async (consulta) => {
    // asigna valores
    propSector = !data.idSector ? "cant_sector" : "id_sector";
    valorSector = !data.idSector ? data.cantSector : data.idSector;
    propModulo = !data.idModulo ? "cant_modulo" : "id_modulo";
    valorModulo = !data.idModulo ? data.cantModulo : data.idModulo;
    propEstante = !data.idEstante ? "cant_estante" : "id_estante";
    valorEstante = !data.idEstante ? data.cantEstante : data.idEstante;
    propPosicion = !data.idPosicion ? "cant_posicion" : "id_posicion";
    valorPosicion = !data.idPosicion ? data.cantPosicion : data.idPosicion;
    propEstibe = !data.idEstiba ? "cant_estibe" : "id_estibe";
    valorEstibe = !data.idEstiba ? data.cantEstiba : data.idEstiba;
    propProfundidad = !data.idProfundidad ? "cant_profundidad" : "id_profundidad";
    valorProfundidad = !data.idProfundidad ? data.cantProfundidad : data.idProfundidad;
    propTipo = "id_tipo_ubicaciones";
    valorTipo = data.id_tipo_ubicaciones;
    propEstado = "id_estado_ubicaciones";
    valorEstado = data.id_estado_ubicaciones;

    return {
      [propDeposito]: String(valorDeposito),
      [propSector]: String(valorSector),
      [propModulo]: String(valorModulo),
      [propEstante]: String(valorEstante),
      [propPosicion]: String(valorPosicion),
      [propEstibe]: String(valorEstibe),
      [propProfundidad]: String(valorProfundidad),
      [propTipo]: String(valorTipo),
      [propEstado]: String(valorEstado),
    };
  };

  const validarForm = (consulta) => {
    var esEntero = true
    if (!consulta) {
      esEntero =
        !Number.isInteger(valorSector) ||
          !Number.isInteger(valorModulo) ||
          !Number.isInteger(valorEstante) ||
          !Number.isInteger(valorPosicion) ||
          !Number.isInteger(valorEstibe) ||
          !Number.isInteger(valorProfundidad)
          ? false
          : true;
    }

    var esPositivo =
      valorSector <= 0 ||
        valorModulo <= 0 ||
        valorEstante <= 0 ||
        valorPosicion <= 0 ||
        valorEstibe <= 0 ||
        valorProfundidad <= 0
        ? false
        : true;

    var validadoCompleto = esEntero && esPositivo ? true : false;
    return validadoCompleto;
  };



  const validaciones = async (consulta) => {
    let mensaje = false;

    // valida que no se intercalen el ingreso de coordenadas y cantidades
    const validacionIngresoIntercalado = validarIngresoIntercalado();

    if (validacionIngresoIntercalado === false) {

      mensaje = "No se puede ingresar coordenadas intercaladas con cantidades";
    }

    //let ubicacionesFiltrado = await getUbicacionesFiltrado(data);

    let depositoFiltrado = await getDepositos(data);

    if (depositoFiltrado.length === 0) {
      console.log(
        "validacion3",
        "El valor de alguna(s) de las coordenadas, no existe en la base de datos"
      );
      mensaje =
        "El valor de la coordenada deposito, no existe en la base de datos";

    }

    const esNumeroEnteroPositivo = validarForm(consulta);
    if (esNumeroEnteroPositivo === false) {
      mensaje =
        "El valor de las coordenadas y las cantidades tiene que ser un numero entero positivo";

    }

    // valida que se ingresen 7 parametros
    if (!consulta && (!data.id_tipo_ubicaciones || data.id_tipo_ubicaciones === "")) {
      mensaje = "Se debe seleccionar un tipo de ubicación";

    }

    if (!consulta && Boolean(data.idProfundidad)) {

      mensaje = "Se debe ingresar una Cantidad en el campo Profundidad";

    }
    // valida que se ingresen 7 parametros
    if (!consulta && (
      (!data.idSector && !data.cantSector) ||
      (!data.idModulo && !data.cantModulo) ||
      (!data.idEstante && !data.cantEstante) ||
      (!data.idPosicion && !data.cantPosicion) ||
      (!data.idEstiba && !data.cantEstiba) ||
      (!data.idProfundidad && !data.cantProfundidad))
    ) {
      mensaje = "Se debe ingresar 7 valores entre coordenadas y cantidades";

    }


    return mensaje
  }

  const obtenerUbicaciones = async () => {

    // asigna valores
    await asignarValores(true);
    let validacion = await validaciones(true);

    if (validacion) {
      return Swal.fire({
        icon: "warning",
        title: "¡Atención!",
        text: validacion,
      });
    } else {
      setOptions({
        ...options,
        idElementData: data,
        sinFilas: false,
      });
      // setUbicacionesObtenidas([...ubicacionesFiltrado]);

      // //setFormReadOnly(true);
      setValidacionCompleta(false);

    }
  };

  const handleSubmit = async (formObj) => {

    const ubicaciones = await asignarValores(false);

    let validacion = await validaciones(false);

    if (validacion) {
      return Swal.fire({
        icon: "warning",
        title: "¡Atención!",
        text: validacion,
      });
    }
    setUbicaciones({
      [ubicaciones.propDeposito]: `${ubicaciones.valorDeposito}`,
      [ubicaciones.propSector]: `${ubicaciones.valorSector}`,
      [ubicaciones.propModulo]: `${ubicaciones.valorModulo}`,
      [ubicaciones.propEstante]: `${ubicaciones.valorEstante}`,
      [ubicaciones.propPosicion]: `${ubicaciones.valorPosicion}`,
      [ubicaciones.propEstibe]: `${ubicaciones.valorEstibe}`,
      [ubicaciones.propProfundidad]: `${ubicaciones.valorProfundidad}`,
      [ubicaciones.propTipo]: `${ubicaciones.valorTipo}`,
    });

    setData({ ...data });
    const response = await postUbicaciones(ubicaciones);

    if (response.status === 'true') {
      setRefresh(true);
      setFormReadOnly(false);
      setValidacionCompleta(true);
      limpiarFormulario();
    }

  };

  return (


    <Grid style={{ marginBottom: "50px", paddingTop: 10 }}>
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          Alta de Ubicaciones
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <CajasProvider>

        <Grid container style={{
          background: "white",
          padding: 20,
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
        }}>

          <Grid item xs={1}>
            <Typography
              style={{ marginTop: "20px", width: "80px", height: "30px", fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
              align="left"
              variant="h7"
              gutterBottom
              component="div"
            >
              Coordenadas
            </Typography>
            <Typography
              style={{ marginTop: "20px", width: "80px", height: "30px", fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
              align="left"
              variant="h7"
              gutterBottom
              component="div"
            >
              Cantidad
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={data}
              renderers={[...materialRenderers,
                SelectorTipoUbicacionesComponent,
                SelectorDepositoComponent,
                SelectorEstadoUbicacionesComponent]}
              cells={materialCells}
              onChange={({ data, _errors }) => setData(data)}
              {...(formReadOnly ? { readonly: true } : {})}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Button
                  onClick={limpiarFormulario}
                  variant="contained"
                  endIcon={<RefreshIcon />}
                  color="secondary"
                  //style={{ marginLeft: "10%", width: "60%", height: "30px" }}
                  style={{
                    marginTop: "20px",
                    marginLeft: "10%",
                    width: "60%",
                    height: "30px",
                  }}
                >
                  Limpiar
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  onClick={obtenerUbicaciones}
                  endIcon={<SearchIcon />}
                  variant="contained"
                  style={{
                    marginTop: "20px",
                    marginLeft: "10%",
                    width: "60%",
                    height: "30px",
                  }}
                >
                  Recupera
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  onClick={handleSubmit}
                  //  disabled={validacionCompleta}
                  endIcon={<AddIcon />}
                  variant="contained"
                  color="success"
                  style={{
                    marginTop: "20px",
                    marginLeft: "10%",
                    width: "60%",
                    height: "30px",
                  }}
                >
                  Procesa
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <ListTablePaginated options={options} />

      </CajasProvider>
    </Grid>
  );
};

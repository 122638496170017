import ArticulosProvider from "src/context/ArticulosContext";
import ArticulosContent from "../components/ArticulosContent";

export function Articulos() {
  return (
    <ArticulosProvider>
      <ArticulosContent />
    </ArticulosProvider>
  );
}


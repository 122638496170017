import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { esArray } from "src/general_components/functionEsArray";
import { Autocomplete, TextField } from "@mui/material";
import { getFromLS } from "src/general_helpers/getFromLS";
import { getComprobantesTipos } from "src/projects/gestion_documental/helpers/ComprobantesTipoData";
  
  const SelectorTipoComprobantes = (props) => {

    const jsonForm = useJsonForms();
    const [dataDefaults, setDataDefaults] = useState([]);
    const disabled = jsonForm.core.data.disabled;
    const tipoComprobantes = jsonForm.core.data.tipo;
    
    const [selectedTipoComprobantes, setSelectedTipoComprobantes] = useState("");
    const comprobantes_tipo = getFromLS("siam-comprobantes_tipo").comprobantes_tipo;

    useState(() => {
      const getPuntoVenta = async () => {
        if (comprobantes_tipo) {
          setDataDefaults(esArray(comprobantes_tipo));
        }else {
          const response = await getComprobantesTipos()
          if (response) {
            setDataDefaults(response);
          }
        }
      }
      getPuntoVenta()
    
    }, []);
  
    useEffect(() => {
      setSelectedTipoComprobantes(dataDefaults.find((item) => item.id === tipoComprobantes));
    }, [dataDefaults, tipoComprobantes]);
  

    const handleChange = (event, newValue) => {
      if (newValue && newValue !== "") {
        setSelectedTipoComprobantes(newValue);
        props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
      }else{
        props.handleChange(props.path, "");
      }
    };
  
    return (
      <div>
        <FormControl
          variant="standard"
          sx={{ width: "100%", paddingBottom: "10px"}}
        >
           <Autocomplete
        value={selectedTipoComprobantes  ? selectedTipoComprobantes : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.detalle || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Tipo"
          disabled={disabled}
           />
        )}
        />
        </FormControl>
      </div>
    );
  };
  
  /*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
  const SelectorTipoComprobantesTester = rankWith(
    100,
    and(
      uiTypeIs("Control"),
      schemaTypeIs("number"),
      schemaMatches((schema) => {
        if (schema.hasOwnProperty("customRender")) {
          let cellschema = schema;
          return cellschema["customRender"] === "SelectorTipoComprobantes";
        }
        return false;
      })
    )
  );
  
  // se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
  export const SelectorTipoComprobantesComponent = {
    renderer: withJsonFormsControlProps(SelectorTipoComprobantes),
    tester: SelectorTipoComprobantesTester,
  };
  
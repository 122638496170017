import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { getCuentasJuridicas } from "src/projects/gestion_comercial/cuentas/helpers/CuentasJuridicasData";


const SelectorCuentasJuridicas = (props) => {
  const jsonForm = useJsonForms();
  const dataDefaultsAssigned = jsonForm.core.data.selectedCuentaJuridica;
  const [cuentas, setCuentas] = useState([]);
  const [selectedCuenta, setSelectedCuenta] = useState(
    dataDefaultsAssigned || []
  );

  const getCuentas = async () => {
    let aux_storage = JSON.parse(localStorage.getItem("siam-cuentasjuridicas"));
    if (aux_storage) { setCuentas(aux_storage) }
    else {
      const response = await getCuentasJuridicas();
      if (response) {
        setCuentas(response)
        localStorage.setItem('siam-cuentasjuridicas', JSON.stringify(response))
      }
    }
  }

    useEffect(() => {
      getCuentas()
    }, [])

    const handleChangeCuentas = (selectedOption) => {
      setSelectedCuenta({
        ...selectedOption
      })
      handleChanges(selectedOption)
    }

    const handleChanges = (selectedOption) => {
      props.handleChange(props.path, selectedOption);
    };

    
    return (
      <>
        <FormControl
          sx={{ width: "100%", paddingBottom: "10px" }}
        >
          <Autocomplete
            options={cuentas && cuentas}
            value={(selectedCuenta?.id_cuentas && cuentas.length > 0) && cuentas.find(x => x.id_cuentas === selectedCuenta.id_cuentas)}
            getOptionLabel={(e) => e.id_cuentas + " - " + e.razon_social}
            getOptionValue={(e) => e.id_cuentas}
            renderInput={(params) => cuentas.length > 0 ? <TextField {...params} label="Cuenta" size="medium" /> : <CircularProgress {...params} size={30} />}
            onChange={((e, newValue) => handleChangeCuentas(newValue))}
            fullWidth
            size="medium"
          />
        
        </FormControl>
      </>
    );
  };

  const SelectorCuentasJuridicasTester = rankWith(
    100,
    and(
      uiTypeIs("Control"),
      schemaTypeIs("object"),
      schemaMatches((schema) => {
        if (schema.hasOwnProperty("customRender")) {
          let cellschema = schema;
          return cellschema["customRender"] === "SelectorCuentasJuridicas";
        }
        return false;
      })
    )
  );

  // hand this over together with the provided `renderers` to the `JsonForms` component
  export const SelectorCuentasJuridicasComponent = {
    renderer: withJsonFormsControlProps(SelectorCuentasJuridicas),
    tester: SelectorCuentasJuridicasTester,
  };
import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import { types } from "src/context/types";

export const Logout = () => {
    const { dispatch } = useContext(AuthContext);
    const endSession = () => {
        Swal.fire({
            title: "Desea cerrar sesion?",
            showDenyButton: true,
            confirmButtonText: `Si`,
            denyButtonText: `No`,

        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({
                    type: types.logout,
                    payload: {}
                });
                
                localStorage.clear();
                return true;
                
            } else {
                return false;
            }
        })
    }

    return (

        <div>
            Cerrando sesion
            {endSession() ? <Redirect to="/login"/> : <Redirect to="/"/>}
        </div>
    );
};

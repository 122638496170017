import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "../../../context/CajasContext/CajasContext";
import { esArray } from "../../../general_components/functionEsArray";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { SnackbarMessenger } from "../../../general_components/SnackbarMessenger";
import { getInformeMovimientosSearchPaginated } from "../helpers/ContenidosData";
import { MovimientosTimelineMUI } from "./MovimientosTimelineMUI";
import { SearchFormGeneral } from "./SearchFormGeneral";

// INFORME SOBRE CAJAS
export const InformeMovimientosList = () => {
  const {
    dataSearchFormGeneral,
    snackbarVisible,
    message,
    severity,
    setOmitirQuerySearch,
    setListRecords,
    setSnackbarVisible,
    setShowFormMovimientos,
    modalState,
    setModalState,
    setTipoHistorico,
    setValorContenido,
    setRequiredFormMovimientos, actualizarListado, setActualizarListado
  } = useContext(CajasContext);

  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const [pushData, setPushData] = useState(false);
  const [dataBusqueda, setDataBusqueda] = useState(false);
  const [contenidoEdit, setContenidoEdit] = useState(false);

  const [modalListCont, setModalListCont] = useState(false);
  const [labelModal, setLabelModal] = useState("Alta de Cajas");

  //DATOS DEL JSONFORM DE BUSQUEDA
  // eslint-disable-next-line no-unused-vars
  const [dataInfoBusqueda, setDataInfoBusqueda] = useState({
    grupo: "",
    contrato: "",
    titular: "",
  });

  let proceso = "InformeGeneralCajas";

  var columns = [];
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);

  if (isMobileDevice) {
    columns = [
      {
        field: "cliente",
        headerName: "Cliente",
        flex: 150,
        minWidth: 150,
        maxWidth: 175,
      },
      {
        field: "sector",
        headerName: "Sector",
        flex: 150,
        minWidth: 150,
        maxWidth: 175,
      },
      {
        field: "cajas",
        headerName: "Cajas",
        flex: 150,
        minWidth: 150,
        maxWidth: 175,
      },
      {
        field: "contenidos",
        headerName: "Contenidos",
        flex: 150,
        minWidth: 150,
        maxWidth: 175,
      },
      {
        //Boton Imprimir
        field: "actions",
        headerName: "Acciones",
        type: "actions",
        width: 100,
        minWidth: 100,
        maxWidth: 125,
        getActions: (params) => [
          <GridActionsCellItem
            hidden={
              !params.row.historico && !params.row.HistoricoContenidos
                ? true
                : false
            }
            icon={
              <Tooltip title="Historico">
                <span>
                  <HistoryIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={() => {
              toggleModalEdit();
              if (params.row.historico) {
                const newId = params.id.slice(4);
                setTipoHistorico(true);
                setLabelModal(" Caja " + newId);
                setContenidoEdit(esArray(params.row.historico));
              } else if (params.row.HistoricoContenidos) {
                setTipoHistorico(false);
                const newId = params.id.slice(10);
                setLabelModal(" Contenido " + newId);
                setValorContenido(params.row.contenidos);
                setContenidoEdit(esArray(params.row.HistoricoContenidos));
              }
              setModalListCont(true);
            }}
            label="Cajas"
          />,
        ],
      },
    ];
  } else {
    columns = [
      { field: "cliente", headerName: "Cliente", flex: 0.3 },
      { field: "sector", headerName: "Sector", flex: 0.3 },
      { field: "cajas", headerName: "Cajas", flex: 0.3 },
      { field: "contenidos", headerName: "Contenidos", flex: 2},
      {
        field: "ubicacion",
        headerName: "Ubicacion",
        headerAlign: "left",
        flex: 0.5,
        
      },
      {
        field: "tipo_cajas",
        headerName: "Tipo de Caja",
        headerAlign: "left",
        flex: 0.5,
        
      },
      {
        //Boton Imprimir
        field: "actions",
        headerName: "Acciones",
        type: "actions",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            hidden={
              !params.row.historico && !params.row.HistoricoContenidos
                ? true
                : false
            }
            icon={
              <Tooltip title={params.row.historico? "Historico de Caja" : "Historico de Contenido"}>
                <span>
                  <HistoryIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={() => {
              toggleModalEdit();
              if (params.row.historico) {
                const newId = params.id.slice(4);
                setTipoHistorico(true);
                setLabelModal(" Caja " + newId);
                setContenidoEdit(esArray(params.row.historico));
              } else if (params.row.HistoricoContenidos) {
                setTipoHistorico(false);
                const newId = params.id.slice(10);
                setLabelModal(" Contenido " + newId);
                setValorContenido(params.row.contenidos);
                setContenidoEdit(esArray(params.row.HistoricoContenidos));
              }
              setModalListCont(true);
            }}
            label="Historico"
          />,
        ],
      },
    ];
  }

  const toggleModalEdit = () => {
    setModalState(!modalState);
    setModalListCont(false);
  };
  const toggleModalMovimientos = () => {
    setModalState(!modalState);
    setModalListCont(false);
  };

  const buttonBuscar = () => (
    <Button
      endIcon={<SearchIcon />}
      onClick={() => {
        setShowFormMovimientos(true);
        setRequiredFormMovimientos(true);
        setAbrirBusqueda(!abrirBusqueda);
        setOmitirQuerySearch(true);
      }}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
      }}
    >
      Buscar
    </Button>
  );

  const [options, setOptions] = useState({
    cols: columns,
    getPaginated: getInformeMovimientosSearchPaginated,
    setListRecords: setListRecords,
    pageSize: [5, 10, 25],
    limit: 50, //cantidad de registros
    filter: true,
    notas: true,
    exportData: true,
    server: true,
    sinFilas: true,
    idElementData: dataSearchFormGeneral,
    metadatoValor: setContenidoEdit,
    contenidoEdit: setContenidoEdit,
    hierarchyOn: true,
    buttonExtraOn: true,
    ButtonExtra: buttonBuscar,
  });

  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
    //SETEO EL ESTADO PARA QUE SE ACTUALICE EL LISTADO
    setActualizarListado(true);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setShowFormMovimientos(true);
        setRequiredFormMovimientos(true);
        setAbrirBusqueda(!abrirBusqueda);
        setOmitirQuerySearch(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!Array.isArray(dataSearchFormGeneral) && actualizarListado) {
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: false,
      });
      setActualizarListado(false);
    }
    setActualizarListado(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBusqueda, actualizarListado]);

  if (pushData) {
    if (abrirBusqueda) {
      //CIERRO EL DIALOGO DE BUSQUEDA
      setAbrirBusqueda(!abrirBusqueda);
      //SETEO LOS DATOS PARA QUE EL OTRO COMPONENTE PUEDA LEERLOS
      setDataBusqueda(dataInfoBusqueda);
      //SETEO EL ESTADO PARA QUE SE ACTUALICE EL LISTADO
      setActualizarListado(true);
    }
    setPushData(!pushData);
  }
  console.log("contenidoEdit",contenidoEdit)

  return (
    <>
      {abrirBusqueda && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirBusqueda}
          modalName={"Busqueda de contenidos"}
          child={
            <SearchFormGeneral toggleModal={toggleModal} proceso={proceso} />
          }
          setPushData={setPushData}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}
      <Grid>
        <ListTablePaginated options={options} />
      </Grid>
      <Grid>
        {modalListCont === false && (
          <SimpleDialogComponent
            open={modalState}
            modalName={labelModal}
            child={""}
            setPushData={toggleModalMovimientos}
            handleClose={toggleModalMovimientos}
            buttonEnviar={false}
            buttonCancelar={true}
          />
        )}
        {modalListCont === true && (
          <SimpleDialogComponent
            open={modalState}
            modalName={labelModal}
            child={<MovimientosTimelineMUI data={contenidoEdit} />}
            setPushData={toggleModalMovimientos}
            handleClose={toggleModalMovimientos}
            buttonEnviar={false}
            buttonCancelar={true}
          />
        )}
      </Grid>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </>
  );
};

export const schemaTelefonosForm  = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "id",
      description: "Id",
    },
    id_cuentas_localizaciones:{
      type: "string",
      hidden: true,
    },
    selectedTipoTelefono: {
      type: "string",
      customRender: "SelectorTipoTelefono",
    },
    numero: {
      type: "integer",
      title: "Numero",
      description: "Ingresar Numero",
      "minimum": 0,
      "minLength": 7
    },
  },
  required: [ "selectedTipoTelefono"],
};
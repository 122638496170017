import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css"


export const getCuentasTelefonos = async (id_cuentas_localizaciones) => {
  const cuentas_telefonos = new datos("cuentas_telefonos");
  cuentas_telefonos.addCondition("cuentas_telefonos", "id", 0, ">");
  cuentas_telefonos.addCondition("cuentas_telefonos", "id_cuentas_localizaciones", parseInt(id_cuentas_localizaciones), "=")
  const response = await cuentas_telefonos.get();
  return esArray(response.wsResponse.cuentas_telefonos);
};

export const getCuentasTelefonosPaginated = async (limit, offset, id_cuentas_localizaciones) => {
  const cuentas_telefonos = new datos("cuentas_telefonos");
  cuentas_telefonos.addCondition("cuentas_telefonos", "id", 0, ">");
  cuentas_telefonos.addCondition("cuentas_telefonos", "id_cuentas_localizaciones", parseInt(id_cuentas_localizaciones), "=")
  cuentas_telefonos.addLimitCondition(limit, offset);
  const response = await cuentas_telefonos.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: esArray(response.wsResponse.cuentas_telefonos),
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const deleteCuentasTelefonos = async (id) => {
  let status = false;
  await Swal.fire({
      title: "¿Está seguro de que desea eliminar este registro?",
      text: "Si sigue adelante, no se podrán revertir los cambios.",
      icon: "warning",
      showCancelButton: true,
      customClass: {
          container: 'my-swal-delete'
      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, quiero eliminarlo",
      cancelButtonText: "No, no estoy seguro",
  }).then(async (result) => {
      if (result.isConfirmed) {
          const cuentas_telefonos = new datos("cuentas_telefonos");
          let data = {
              id: id,
          };
          const response = await cuentas_telefonos.delete(data);

          if (response.status === "true") {
              status = true;
              await Swal.fire({
                  customClass: {
                      container: 'my-swal-delete'
                  },
                  title: "¡Eliminado!",
                  text: "El registro ha sido eliminado exitosamente",
                  icon: "success"
              });
          } else {
              await Swal.fire({
                  customClass: {
                      container: 'my-swal-delete'
                  },
                  icon: "error",
                  title: "¡Oops!",
                  text: response.message,
              });
          }
      }
  });
  return status;
};

export const getCuentasTelefonosByLocalizacion = async (idCuentaLocalizacion) => {
  const objectCuentasTelefonos = new datos("cuentas_localizaciones")

  objectCuentasTelefonos.addCondition("cuentas_telefonos", "id_cuentas_localizaciones", parseInt(idCuentaLocalizacion), "=")

  const response = await objectCuentasTelefonos.get()
  if (response) {
    return response.wsResponse.cuentas_telefonos
  }
  return response
}

export const postCuentasTelefonos = async (data) => {
  const objectCuentasTelefonos = new datos("cuentas_localizaciones")

  const response = objectCuentasTelefonos.insert(data)

  if (response) {
    return response.wsResponse.cuentas_telefonos
  }
  return response

}
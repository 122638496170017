/* eslint-disable no-unused-vars */
import { Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import * as React from "react";
import Divider from "@mui/material/Divider";
import { Buttons } from "./Buttons";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

export const SimpleDialogComponent = (props) => {
  const {
    modalName,
    open,
    // eslint-disable-next-line no-unused-vars
    isMobileDevice,
    child,
    setPushData,
    handleClose,
    buttonEnviar = true,
    fullWidth = true,
    maxWidth = "md",
  } = props;

  let isMobile = props.isMobileDevice ? false : true;

  const handleOnClose = (event, reason) => {
    console.log("handleOnClose", reason);
    if (reason === "backdropClick") {
      handleClose()
    } else if (reason === "escapeKeyDown") {
      handleClose();
    }
  };

  const Title = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF', // Color blanco para el título
    fontWeight: 'bold', // Puedes ajustar el peso de la fuente según tus preferencias
    fontSize: '1rem', // Tamaño de la fuente del título
    marginBottom: theme.spacing(0), // Espacio inferior para separar del contenido
  }));

  const CloseButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
  }));

  const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0),

  }));

  const customDialogStyle = {
    borderRadius: '12px', // Ajusta el valor de acuerdo a tus preferencias
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      fullWidth={isMobile}
      maxWidth={maxWidth}
      PaperProps={{ style: customDialogStyle }}
    >
      <DialogTitle>
        <Header>
          <div>
            <Title>{modalName}</Title>
          </div>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <>{child}</>
      </DialogContent>
      {
        buttonEnviar && (
          <Divider />
        )}
      <DialogActions>
        {
          buttonEnviar && (
            <Buttons
              onClickSubmit={() => {
                setPushData();
                handleClose();
              }}
              labelButtonSave={"Guardar"}
              buttonCerrar={handleClose}
            />
          )
        }
      </DialogActions>
    </Dialog>
  );
};

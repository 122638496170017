import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "src/context/AuthContext";
import { ComponentDialog } from "../../../general_components/ComponentDialog";
import { postAlicuotaIVA } from "../helpers/AlicuotasIVAData";
import { schemaAlicuotasIVAForm } from "./forms/schemaAlicuotasIVAForm";
import { uiSchemaAlicuotasIVAForm } from "./forms/uiSchemaAlicuotasIVAForm";
const localize = require("ajv-i18n");

export const AlicuotasIVAForm = (props) => {
  const auth = useContext(AuthContext);

  const { data, listName, createButton, setCreateButton } = props;

  const [pushData, setPushData] = useState(false);
  const [dataForm, setDataForm] = useState({
    id: "",
    nombre: "",
    valor: "",
    codigo_afip: "",
  });

  //PASA LA PRIMERA VEZ SIRVE SOLO PARA CARGAR LOS DATOS EN EL FORM (SOLO PARA EDITAR)
  if (data.id && dataForm.id === "") {
    setDataForm({
      id: data.id || "",
      nombre: data.nombre || "",
      valor: parseFloat(data.valor) || "",
      codigo_afip: parseInt(data.codigo_afip) || "",
    });
  }

  if (createButton === false) {
    //SIRVE PARA RESTABLECER LOS VALORES DE LOS SELECTORES COMO VACIO
    setDataForm([]);
  }

  const [withErrors, setWithErrors] = useState(false);

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setDataForm(data);

    return { errors, data };
  };

  //CONTROLA CUANDO SE SALE DE UN EDIT Y QUEDA CON BASURA LOS SELECTORES, COMPROBAR CUALQUIERA DE LOS DOS SELECTORES SI ES DISTINTO DE VACIO
  if (data.length === 0 && dataForm.id !== "") {
    setDataForm({
      id: "",
      nombre: "",
      valor: "",
      codigo_afip: "",
    });
  }

  if (pushData) {
    const newMeta = {
      id: dataForm.id || "",
      nombre: dataForm.nombre,
      valor: dataForm.valor,
      codigo_afip: dataForm.codigo_afip,
    };
    postAlicuotaIVA(newMeta, auth);
    setCreateButton(false);
  }

  return (
    <div>
      <ComponentDialog
        listName={listName}
        buttonClicked={createButton}
        setButtonClicked={setCreateButton}
        setPushData={setPushData}
        withErrors={withErrors}
        child={
          <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>
            <JsonForms
              schema={schemaAlicuotasIVAForm}
              uischema={uiSchemaAlicuotasIVAForm}
              renderers={materialRenderers}
              data={dataForm}
              cells={materialCells}
              //validationMode={"ValidateAndHide"}
              onChange={({ errors, data }) =>
                onChange && onChange(errors, data)
              }
            />
          </Grid>
        }
      />
    </div>
  );
};

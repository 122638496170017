export const uiSchemaTipoCuentasForm = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
          scope: "#/properties/detalle",
        },
        {
          type: "Control",
          scope: "#/properties/selectedCategoriaCuentas",
        },
    
],}
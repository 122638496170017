import { endpoints } from "src/configs/config_endpoints";

export const imprimirEtiquetas = (idsParaImprimir) => { 
  
  if (idsParaImprimir === "") return false
  
  let encoded = btoa(idsParaImprimir);
  let dominio = JSON.parse(localStorage.getItem("clientData")).dominio
  let URI = endpoints.urls.impresionEtiquetas + "owner_code=" + JSON.parse(localStorage.getItem("clientData")).owner_code + "&boxes=" + encoded 
  let URL_Etiquetas= dominio + URI;
  console.log("URL",URI)
  return URL_Etiquetas
}
export const uiSchemaFiltroCuentasServiciosPreciosForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/selectedTipoCuenta",
    },
    {
      type: "Control",
      scope: "#/properties/selectedModeloArticulo",
    },
    {
      type: "Control",
      scope: "#/properties/activo",
      options: {
        toggle: true,
        align: "start",
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/cantidad",
        },
        {
          type: "Control",
          scope: "#/properties/importe",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/activoFechaInicio",
          options: {
            toggle: true,
            align: "center",
          },
        },
        {
          type: "Control",
          scope: "#/properties/fechaActivoInicio",
          rule: {
            effect: "ENABLE",
            condition: {
              scope: "#/properties/activoFechaInicio",
            },
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/activoFechaFin",
          options: {
            toggle: true,
            align: "start",
          },
        },
        {
          type: "Control",
          scope: "#/properties/fechaActivoFin",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/activoFechaFin",
            },
          },
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/descripcion",
    },
  ],
};

import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { CajasGenericasAsignacionList } from "../components/CajasGenericasAsignacionList";
import CustomBreadcrumbs from 'src/general_components/BreadCrumbsCustom';


export function CajasGenericasAsignacion() {


  return (


    <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          Cajas Genericas Asignacion
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <CajasProvider>
        <Grid>
          <CajasGenericasAsignacionList />
        </Grid>
      </CajasProvider>
    </Grid>
  );
}

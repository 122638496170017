export const schemaCuentasContactosForm  = {
  type: "object",
  properties: {
    descripcion: {
      type: "string",
      title: "Descripcion",
      description: "Ingresar Descripcion",
      minLenght: 5
    },
  },
  required: ["descripcion"],
};
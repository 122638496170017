import { useState, useEffect } from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";
import { FormularioDinamicoModelos } from "src/general_components/FormularioDinamicoModelos";
import { getTablaEstructura, getDataSelect, getPaginatedData, getDataByCondition, deleteDataById } from "src/hooks/structureTable";

export const ABMGenerico = ({ modelo, titulo = false, condiciones, columnasAexcluir, valuesDefaultForm = false }) => {
    const [createButton, setCreateButton] = useState(false);
    const [dataEdit, setDataEdit] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [columns, setColumns] = useState([]);
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState({});
    const [columnsLoaded, setColumnsLoaded] = useState(false);

    const filtros = {
        modelo: modelo,
        condiciones: condiciones
    }

    const toPascalCase = (str) => {
        return str
            .replace(/_/g, ' ')  // Reemplaza guiones bajos con espacios
            .replace(/\b\w+/g, (word) => word[0].toUpperCase() + word.slice(1).toLowerCase());  // Convierte la primera letra de cada palabra a mayúscula
    };

    // Ejemplo de uso:
    const listName = titulo || toPascalCase(modelo);

    useEffect(() => {
        const filtrarCampos = async (formDataResponse) => {
            const camposFiltrados = {};

            for (const fieldName in formDataResponse) {
                if (fieldName.includes('id_')) {
                    const palabraExtraida = fieldName.replace('id_', '');
                    const valorLocalStorage = JSON.parse(localStorage.getItem("siam-" + palabraExtraida))?.[palabraExtraida];

                    if (valorLocalStorage) {
                        const opcionesFiltradas = valorLocalStorage.map(({ id, nombre, descripcion, label, detalle }) => ({
                            id,
                            nombre: nombre || descripcion || detalle || label || 'Sin Nombre',
                        }));

                        camposFiltrados[fieldName] = {
                            ...formDataResponse[fieldName],
                            options: opcionesFiltradas,
                        };
                    } else {
                        const datosSelect = await getDataSelect(palabraExtraida);
                        const opcionesFiltradas = datosSelect.map(({ id, nombre, descripcion, label, detalle }) => ({
                            id,
                            nombre: nombre || descripcion || detalle || label || 'Sin Nombre',
                        }));

                        camposFiltrados[fieldName] = {
                            ...formDataResponse[fieldName],
                            options: opcionesFiltradas,
                        };
                    }
                }
            }

            let camposFinal = { ...formDataResponse, ...camposFiltrados };

            if (camposFinal.id) {
                delete camposFinal.id;
            }

            return camposFinal;
        };

        const getEstructuraTabla = async () => {
            let response = await getTablaEstructura(modelo);
            if (response.status === "true") {
                const formDataResponse = response.wsResponse;
                const campos = await filtrarCampos(formDataResponse);
                let camposFiltrados = campos;
                if (columnasAexcluir) {
                    camposFiltrados = Object.fromEntries(
                        Object.entries(campos).filter(([key]) => !columnasAexcluir.includes(key))
                    );
                }

                const generatedColumns = Object.keys(camposFiltrados).slice(0, 5).map((field, index) => {
                    const label = camposFiltrados[field].label;
                    const headerName = typeof label === 'object' && Object.keys(label).length === 0
                        ? field.charAt(0).toUpperCase() + field.slice(1)
                        : label || field.charAt(0).toUpperCase() + field.slice(1);

                    const column = {
                        field: field,
                        headerName: headerName,
                        flex: 1,
                        align: 'center',
                        headerAlign: "center",
                    };

                    // Check if the field is "activo" to apply custom rendering
                    if (field === 'activo') {
                        column.flex = 0.2;
                        column.renderCell = (params) => (params.value === "1" ? 'Sí' : 'No');
                    }

                    return column;
                });

                const columnasGeneradas = [{
                    field: "id",
                    headerName: "ID",
                    flex: 0.1, // Divide equitativamente entre el número de columnas
                }, ...generatedColumns];
                setColumns(columnasGeneradas);
                if (valuesDefaultForm) {
                    // Eliminar propiedades de camposFinal si coinciden con valuesDefaultForm
                    Object.keys(valuesDefaultForm).forEach((key) => {
                        delete campos[key];
                    });
                }
                setFormData(campos);
                setOptions({
                    cols: columnasGeneradas,
                    get: getDataByCondition,
                    getPaginated: getPaginatedData,
                    pageSize: [5, 10, 25],
                    edit: true,
                    delete: deleteDataById,
                    limit: 50,
                    filter: true,
                    exportData: true,
                    server: true,
                    createButton: createButton,
                    setCreateButton: setCreateButton,
                    setDataEdit: setDataEdit,
                    idElementData: filtros
                });
                setColumnsLoaded(true); // Indicar que las columnas se han cargado
            }
        };

        getEstructuraTabla();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelo]);

    if (createButton === false && dataEdit.id !== undefined) {
        setDataEdit([]);
    }

    return (
        <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
            <Grid
                style={{
                    background: "white",
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    fontFamily: 'Roboto, sans-serif',
                    borderRadius: 10,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    marginBottom: 20,
                    height: 50
                }}
            >
                <Typography
                    align="left"
                    variant="h2"
                    component="div"
                    sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F" }}
                >
                    {listName}
                </Typography>
                <CustomBreadcrumbs />
            </Grid>

            {columnsLoaded && (
                <>
                    <ListTablePaginated options={options} />
                    <FormularioDinamicoModelos
                        data={dataEdit}
                        createButton={createButton}
                        listName={listName}
                        setCreateButton={setCreateButton}
                        setOptions={setOptions}
                        options={options}
                        formData={formData}
                        modelo={modelo}
                        dataDefault={valuesDefaultForm}
                    />
                </>
            )}
        </Grid>
    );
};

import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";
import Swal from "sweetalert2";

export const getTipoCambio = async (id_tipo_moneda) => {
  const tipo_cambio = new datos("tipo_cambio");
  tipo_cambio.addCondition("tipo_cambio", "id", 0, ">");
  tipo_cambio.addCondition("tipo_cambio", "id_tipo_moneda", id_tipo_moneda, "=");
  const response = await tipo_cambio.get();
  return esArray(response.wsResponse.tipo_cambio);
};


export const getTipoCambioList = async (limit, offset) => {
  const tipo_cambio = new datos("tipo_cambio");
  tipo_cambio.join('tipo_moneda', "id", "id_tipo_moneda", "=");
  tipo_cambio.addCondition("tipo_cambio", "id", 0, ">");
  tipo_cambio.addLimitCondition(limit, offset);
  const response = await tipo_cambio.get();

  const tipos_cambio = esArray(response.wsResponse.tipo_cambio)
  const respuesta = tipos_cambio.map(tipoCambio => {
    let unidad_monetaria = ''
    // if (tipoCambio.id_tipo_moneda) {
    unidad_monetaria = tipoCambio.relations.tipo_moneda.unidad_monetaria
    //  }


    return {
      ...tipoCambio,
      ...(unidad_monetaria !== '' && { unidad_monetaria: unidad_monetaria }),
    };
  });

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: [...esArray(respuesta)],
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const deleteTipoCambio = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este tipo de cambio?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos("tipo_cambio");
      let data = {
        id: id,
      };
      const response = await userData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El tipo de caja ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};


/* eslint-disable no-unused-vars */
import { Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import * as React from "react";
import Divider from "@mui/material/Divider";
import { Buttons } from "./Buttons";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LoadingOverlay from "./LoadingOverlay";

export const ConfimDialogComponent = (props) => {
  const {
    modalName,
    open,
    mensaje,
    handleConfirm,
    handleClose,
    maxWidth = "md",
    loading = false
  } = props;

  let isMobile = props.isMobileDevice ? false : true;

  const handleOnClose = (event, reason) => {
    console.log("handleOnClose", reason);
    if (reason === "backdropClick") {
    } else if (reason === "escapeKeyDown") handleClose();
    else handleClose();
  };

  const Title = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF', // Color blanco para el título
    fontWeight: 'bold', // Puedes ajustar el peso de la fuente según tus preferencias
    fontSize: '1rem', // Tamaño de la fuente del título
    marginBottom: theme.spacing(0), // Espacio inferior para separar del contenido
  }));

  const CloseButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
  }));

  const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0),

  }));

  const estilosChild = {
    justifyContent: 'center', textAlign: 'center', marginBottom: 30, fontSize: 16,
    fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', color: "#0A395F"
  }

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      fullWidth={isMobile}
      maxWidth={maxWidth}
    >
      <DialogTitle >
        <Header>
          <div>
            <Title>{modalName}</Title>
          </div>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
      </DialogTitle>
      <Divider />
      <DialogContent style={estilosChild}>
        <>{mensaje}</>
        <LoadingOverlay open={loading} />
      </DialogContent>

      <Divider />

      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          startIcon={<CloseIcon />}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => handleConfirm()}
          variant="contained"
          color="success"
          startIcon={<CheckIcon />}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

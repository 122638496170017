import { DeleteForever, Edit } from "@mui/icons-material";
import { Container, Grid, Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  esES,
} from "@mui/x-data-grid";
import {
  DataGridPro
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { DeleteModal } from "src/general_components/DeleteModal";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { CustomNoRowsOverlay } from "src/general_components/Overlay";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,

} from "@mui/x-data-grid";

/* Componente de Lista, similar a <ListTable>, pero que se usa anidado dentro  del boton show del componente
llamada <CompositeListTable>

Este componente fue diseñado casi especificamente para el proceso de CUENTAS pero podria usarse como modelo para 
otros componentes similares

Como solo difiere un par de lineas con respecto al <ListTable>, solo se documentan los cambios
*/

export const ListTableCuentasServicios = (data) => {
  const {

    buttonExtraOn,
    ButtonExtra
  } = data.options;
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [records, setRecords] = useState([...data.options.res]);
  const [columns, setColumns] = useState(data.options.cols); // columnas que se muestran
  const viewCustomToolbar = data.options.viewCustomToolbar || true;
  // eslint-disable-next-line no-unused-vars
  const [hiddenButtonAgregar, setHiddenButtonAgregar] = useState(
    data.options.hiddenButtonAgregar || false
  ); // Se agrega este parametro para desabilitar el boton agregar


  /* campos para controlar que se muestre la ventana modal */
  const [pageSize, setPageSize] = useState(data.options.limit); // TAMAÑO DE LA PAGINA A MOSTRAR (CANTIDAD DE RECORDS). SI NO SE SETEA EN LOS PROPS, USA POR DEFECTO 10

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false); // ESTADO DE LA TABLA

  const [idTodelete, setIdToDelete] = useState(0);

  const [confirmationModalState, setConfirmationModalState] = useState(false);

  const toggleconfirmationModal = () => {
    setConfirmationModalState(!confirmationModalState);
  };

  const actionColumn = {
    field: "actions",
    headerName: "Acciones",
    type: "actions",

    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Tooltip title="Editar">
            <span>
              <Edit color="info" />
            </span>
          </Tooltip>
        }
        onClick={() => {
          data.options.toEdit(params.id);
        }}
        label="Editar"
      />,

      <GridActionsCellItem
        icon={
          <Tooltip title="Eliminar">
            <span>
              <DeleteForever color="warning" />
            </span>
          </Tooltip>
        }
        onClick={() => {
          setIdToDelete(params.id);
          toggleconfirmationModal();
        }}
        label="Delete"
      />,
    ],
  };

  useEffect(() => {
    setRecords([...data.options.res]);
    let cols_aux = [...data.options.cols];
    // data.options.telefonos && cols_aux.push(actionTelefonos)
    cols_aux.push(actionColumn);
    setColumns(cols_aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //Maneja el delete del item seleccionado--
  const handleDelete = async () => {
    if (idTodelete !== 0) {
      data.options.delete(idTodelete).then((response) => {
        if (response.status === "true") {
          setMessage("Servicio borrado exitosamente");
          setSeverity("success");
          setSnackbarVisible(true);
          data.options.callback();
        } else {
          setMessage("Hubo un error al procesar los datos");
          setSeverity("error");
          setSnackbarVisible(true);
        }
      });
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>

        <Grid>
          {viewCustomToolbar !== "false" && (<><GridToolbarColumnsButton
            style={{
              color: '#FFFFFF',
              fontFamily: 'Roboto, sans-serif',
              background: "#0A395F",
              margin: '4px',
              minWidth: '120px',
              width: '120px',
              fontSize: '11px',
              height: '30px',
            }}
          />
            <GridToolbarFilterButton
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                background: "#0A395F",
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            />
            <GridToolbarDensitySelector
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                background: "#0A395F",
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            />
          </>)}

        </Grid>
        <Grid>

          {buttonExtraOn && (<ButtonExtra></ButtonExtra>)}

        </Grid>

      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Container maxWidth="xxl" style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <h4>{data.options.listName}</h4>
          </Grid>
          <Grid item xs={12}>
            <DataGridPro
              autoHeight
              localeText={esES.components.MuiDataGrid.defaultProps.localeText} // hace que la cabecera de los filtros este español
              components={{
                // las cabeceras de filtrado que van a aparecer
                NoRowsOverlay: CustomNoRowsOverlay,
                // las cabeceras de filtrado que van a aparecer
                Toolbar: CustomToolbar,
              }}
              density="compact"
              sx={{
                '--DataGrid-overlayHeight': '50vh',
                backgroundColor: 'white',
                fontFamily: 'Roboto, sans-serif', // Cambia el tipo de letra
                borderRadius: 3, // Elimina los bordes redondeados del DataGridPro
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", /* Agrega sombra */
                height: '50vh',
                "& .MuiDataGrid-cell": {
                  fontSize: 13,
                  fontFamily: 'Roboto, sans-serif',
                }, '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                }

                , '& .MuiDataGrid-overlay': {
                  height: '800px !important',
                },
              }}
              rows={records}
              columns={columns}
              loading={loading}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[50, 100, 150, 200]}
              pagination
              {...data.options.props}
            />
          </Grid>
        </Grid>

        <DeleteModal
          open={confirmationModalState}
          close={toggleconfirmationModal}
          handleDelete={handleDelete}
        />
        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          close={() => {
            setSnackbarVisible(false);
          }}
        />
      </Container>
    </>
  );
};

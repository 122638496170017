import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

export const CardsDashboard = (data) => {
    const sumaTotal = Array.isArray(data.data) ? data.data.reduce((total, objeto) => total + parseFloat(objeto.cant.replace(',', '')), 0) : "0";

    // Define el número de tarjetas por fila que se mostraran.
    const tarjetasPorFila = data.data.length;

    // Calcula la cantidad de tarjetas y ajusta md dinámicamente.
    const md = Math.min(12 / tarjetasPorFila, 12);

    return (
        <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', textAlign: 'left' }}>
                    Cajas por estado
                </Typography>
            </Grid>
            {Array.isArray(data.data) ? (
                data.data.map((objeto) => {
                    const porcentaje = ((parseFloat(objeto.cant.replace(',', ''))) / sumaTotal) * 100;
                    return (
                        <Grid item key={objeto.id} xs={12} sm={6} md={md}>
                            <Card key={objeto.id} sx={{ border: "1px solid #e0e0e0", height: '100%' }}>
                                <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', textAlign: 'left', color: "grey" }}>
                                        {objeto.descripcion}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', textAlign: 'left', color: "#0A395F" }}>
                                            {objeto.cant}
                                        </Typography>
                                        <Chip
                                            icon={porcentaje >= 50 ? <TrendingUpIcon /> : <TrendingDownIcon />}
                                            style={{ borderRadius: '5px' }}
                                            color={porcentaje >= 50 ? "info" : "warning"}
                                            variant="outlined"
                                            label={`${porcentaje.toFixed(2)}%`}
                                        />
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })
            ) : (
                <Typography variant="body1">
                    No hay datos disponibles.
                </Typography>
            )}
        </Grid>
    );
};
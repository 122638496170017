import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";

export const getCajasExistence = async ( cajasId) => {
  const objectCajas = new datos("cajas");
  objectCajas.addCondition("cajas", "id", cajasId, "in");  

  
  const response = await objectCajas.get();

  if(response.wsResponse.cajas){
    const arrResponse = esArray(response.wsResponse.cajas);
    return arrResponse;
  }else{
    return [];
  }
  
};

export const postContenidos = async (contenidos) => {
  const { id, id_cajas, id_tipo_contenidos, meta_datos } = contenidos;
  var metaDatosFiltrado = meta_datos.filter(
    (item) =>
      item.valor !== undefined && item.valor !== "" && item.valor !== null
  );

  const data = {
    id: id,
    id_cajas: id_cajas,
    id_tipo_contenidos: id_tipo_contenidos,
    meta_datos: metaDatosFiltrado,
  };

  const contenidosInsert = new datos("contenidos");
  const response = await contenidosInsert.contenidosCreate(data);

  return response;
};

export default getCajasExistence;
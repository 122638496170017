export const uiSchemaUbicacionCaja =
{
  "type": "VerticalLayout",
  "elements": [
    {
      "type": "HorizontalLayout",
      "elements": [
        {
          "type": "Control",
          "label": "Deposito",
          "scope": "#/properties/idDep",
        }

        ,

        {
          "type": "Control",
          "label": "Sector",
          "scope": "#/properties/idSector",

        }

        ,

        {
          "type": "Control",
          "label": "Modulo",
          "scope": "#/properties/idModulo",
        },

        {
          "type": "Control",
          "label": "Estante",
          "scope": "#/properties/idEstante",

        }
        ,
      ],
    }
    ,
    {
      "type": "HorizontalLayout",
      "elements": [


        {
          "type": "Control",
          "label": "Posicion",
          "scope": "#/properties/idPosicion",

        }
        ,

        {
          "type": "Control",
          "label": "Profundidad",
          "scope": "#/properties/idProfundidad",

        }

        ,

        {
          "type": "Control",
          "label": "Estibe",
          "scope": "#/properties/idEstiba",

        }


        ,
        {
          type: "Control",
          scope: "#/properties/id_tipo_ubicaciones",
        },

      ],

    },

  ]
}

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import menu from "src/configs/menu";
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import React, { useState } from 'react';
import {
    List,
    ListItem,
    Grid
} from '@mui/material';

export function CustomBreadcrumbs() {

    const levelActual = localStorage.getItem("levelMenuActual");

    const StyledBreadcrumb = styled(Chip)(({ theme }) => {
        const backgroundColor =
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[800];
        return {
            backgroundColor,
            height: theme.spacing(3),
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover, &:focus': {
                backgroundColor: emphasize(backgroundColor, 0.06),
            },
            '&:active': {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(backgroundColor, 0.12),
            },
            '& .MuiChip-icon': {
                color: theme.palette.mode === 'light' ? '#0A395F' : '#FFFFFF', // Color del icono
            },
            '& .MuiChip-label': {
                color: theme.palette.mode === 'light' ? '#0A395F' : '#FFFFFF', // Color del texto
            },
        };
    });
    

    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

    const handleClick = (event) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setPopoverAnchorEl(null);
    };

    function getItemsByLevel(menuItems, targetLevel) {
        const resultItems = [];
        for (const item of menuItems) {
            const itemLevelParts = item.level.split('.').slice(0, 2).join('.');;
            const targetLevelParts = targetLevel.split('.').slice(0, 2).join('.');;
            if (itemLevelParts.length >= targetLevelParts.length) {
                let match = true;
                for (let i = 0; i < targetLevelParts.length; i++) {
                    if (itemLevelParts[i] !== targetLevelParts[i]) {
                        match = false;
                        break;
                    }
                }
                if (match) {
                    resultItems.push(item);
                }
            }
        }
        return resultItems;
    }

    function getItemByLevel(menuItems, targetLevel) {
        for (const item of menuItems) {
            const itemLevelParts = item.level.split('.');
            const targetLevelParts = targetLevel.split('.');
            if (itemLevelParts.length >= targetLevelParts.length) {
                let match = true;
                for (let i = 0; i < targetLevelParts.length; i++) {
                    if (i < 2 && itemLevelParts[i] !== targetLevelParts[i]) {
                        match = false;
                        break;
                    } else if (i >= 2 && itemLevelParts[i] !== targetLevelParts[i]) {
                        match = false;
                        break;
                    }
                }
                if (match) {
                    return item;
                }
            }
        }
        return null;
    }

    const targetLevel = levelActual;
    const itemsByLevel = targetLevel && targetLevel !== null && getItemsByLevel(menu, targetLevel);
    const itemActual = targetLevel && targetLevel !== null && getItemByLevel(menu, targetLevel);

    return (
        <Grid sx={{ paddingRight: 1, fontSize: '1vw' }}>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="/dashboard"
                    label="Inicio"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '0.7vw',
                        fontFamily: 'Public Sans, sans-serif',
                        fontWeight: 400,
                        
                    }}
                    onClick={() => { }}
                    icon={<HomeIcon fontSize="small"/>}
                />
                {itemActual && itemActual !== null && (
                    <div role="presentation">
                        <StyledBreadcrumb
                            label={itemActual.module}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '0.7vw',
                                fontFamily: 'Public Sans, sans-serif',
                                fontWeight: 400,
                                color: "#0A395F",
                            }}
                        />
                    </div>)}
                {itemActual && itemActual !== null && (
                    <div role="presentation" onClick={handleClick}>
                        <StyledBreadcrumb
                            label={itemsByLevel[0].name}
                            icon={<ExpandMoreIcon />}
                            onClick={handleClick}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '0.7vw',
                                fontFamily: 'Public Sans, sans-serif',
                                fontWeight: 400,
                                color: "#0A395F",
                            }}
                        />
                    </div>)}
                {itemActual && itemActual !== null && (
                    <StyledBreadcrumb component="a" href={itemActual.path}
                        label={itemActual.name}
                        onClick={() => { }} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '0.7vw',
                            fontFamily: 'Public Sans, sans-serif',
                            fontWeight: 400,
                            color: "#0A395F",
                        }} />)}
            </Breadcrumbs>
            <div>
                <Popover
                    open={Boolean(popoverAnchorEl)}
                    anchorEl={popoverAnchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <List sx={{
                        fontFamily: 'Public Sans, sans-serif',
                        color: "#0A395F",
                    }}>
                        {itemsByLevel?.map((breadcrumb, index) => (
                            (breadcrumb.path && breadcrumb.component) ? (
                                <ListItem key={breadcrumb.name}>
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center', fontSize: '0.7vw', fontFamily: 'Public Sans, sans-serif', fontWeight: 400 }}
                                        color="inherit"
                                        href={breadcrumb.path}
                                    >
                                        {breadcrumb.name}
                                    </Link>
                                </ListItem>
                            ) : null
                        ))}
                    </List>
                </Popover>
            </div>
        </Grid>
    );
}

export default CustomBreadcrumbs;

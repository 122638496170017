import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useCuentasContext } from "src/context/CuentasContext";
import { Spinner } from "src/general_components/Spinner";
import Grid from '@mui/material/Grid';
import { Buttons } from "src/general_components/Buttons";
import { postDinamico } from "src/general_helpers/AgegarDinamicoData";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/* Formulario contenedor de los datos de las CUENTAS JURIDICAS */

export const CuentasInfoAdicionalForm = (props) => {
  /* estados propios del context */
  const { cuenta } = useCuentasContext();
  const { setSnackbarVisible, setMessage, setSeverity, infoAdicional } = useCuentasContext();

  const { isLoadingData } = useCuentasContext();


  const [editorData, setEditorData] = useState('');

  /* definicion de constantes de estado para utiliar con sus respectivos formularios */
  const [dataForm, setDataForm] = useState({
    id: props.data?.id || "",
    id_cuentas: props.params.id_cuentas || "",
    descripcion: "",
  });


  useEffect(() => {
    if (infoAdicional.descripcion?.length > 0) {
      try {
        // Decodificar el contenido base64 usando la función base64ToUnicode
        const decodedEditorData = base64ToUnicode(infoAdicional.descripcion);
        // Establecer el contenido desformateado en el editor
        setEditorData(decodedEditorData);
      } catch (error) {
        setEditorData('<p>' + infoAdicional.descripcion + '</p>');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoAdicional]);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike']
    ],
  };

  // Función para verificar si una cadena contiene caracteres no ASCII
  const containsNonASCII = (str) => {
    return /[^\u0020-\u007E]/.test(str);
  }

  // Función para codificar una cadena Unicode a base64
  const unicodeToBase64 = (str) => {
    // Codifica la cadena Unicode a una secuencia de bytes
    const bytes = new TextEncoder().encode(str);
    // Crea una cadena binaria a partir de la secuencia de bytes
    const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
    // Codifica la cadena binaria a base64
    return btoa(binary);
  }

  // Función para codificar a base64, manejando caracteres Unicode si es necesario
  const safeBase64Encode = (str) => {
    if (containsNonASCII(str)) {
      return unicodeToBase64(str);
    } else {
      return btoa(str);
    }
  }

  // Función para decodificar base64 a una cadena Unicode
  const base64ToUnicode = (str) => {
    const binary = atob(str);
    const bytes = Uint8Array.from(binary, char => char.charCodeAt(0));
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
  }

  useEffect(() => {

    if (editorData) {
      // const editorDataBase64 = btoa(editorData);

      const editorDataBase64 = safeBase64Encode(editorData);

      setDataForm({
        id: infoAdicional.id,
        id_cuentas: props.params.id_cuentas,
        descripcion: editorDataBase64,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorData]);

  useEffect(() => {
    if (infoAdicional)
      setDataForm({
        ...dataForm,
        id: infoAdicional.id,
        id_cuentas: props.params.id_cuentas,
        descripcion: infoAdicional.descripcion,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoAdicional]);

  const toggleModal = () => {
    props.handleClose();
  };

  const handleSubmit = async () => {
    const data = dataForm;
    const response = await postDinamico("cuentas_info_adicional", data);
    if (response.status === "true") {
      if (data.id) {
        setMessage("Se modifico exitosamente!");
      } else {
        setMessage("Se agrego exitosamente!");
      }

      setSeverity("success");
      setSnackbarVisible(true);
      toggleModal();

    } else {
      setMessage("Hubo un problema al agregar la informacion adicional");
      setSeverity("warning");
      setSnackbarVisible(true);
    }
  };

  if (cuenta.id_cuentas > 0 && isLoadingData) {
    return <Spinner />;
  } else
    return (
      <>
        <Container maxWidth="lg">

          <Box
            sx={{
              mx: "auto",
              width: "100%",
              p: 1,
              m: 1,
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            <Grid>
              <ReactQuill
                theme="snow" // Selecciona el tema del editor (puedes cambiarlo según tus preferencias)
                value={editorData}
                modules={modules}
                onChange={(value) => setEditorData(value)}
              />
            </Grid>
            <Grid sx={{ mt: 1, marginTop: 5 }}>
              <Buttons
                disableButton={false}
                onClickSubmit={handleSubmit}
                labelButtonSave={"Confirmar"}
                buttonCerrar={toggleModal}
              />
            </Grid>
          </Box>
        </Container>
      </>
    );
};

import datos from "src/services/datos";

export const postDigitalizacion = async (dataImagen, updateImageSave) => {
  let filesImages = [];

  // eslint-disable-next-line array-callback-return
  dataImagen.map((item) => {
    if (item.save === false) {
      //pasar folio a string
      // let folio = item.model_data.folio.toString();
      // item.model_data.folio = folio;

      // item.model_data.id_tipo_contenidos =
      //   item.model_data?.id_tipo_contenidos?.id;

      //QUITAR data:image/png;base64, PARA QUE FUNCIONE EL POST
      item.image = item.image.replace(/^data:image\/[a-z]+;base64,/, "");
      filesImages.push(item);
    }
  });
  let data = { images: filesImages };
  const objetoImages = new datos("images");
  const response = await objetoImages.createImagesMultiple(data);

  return response;
};

export const actualizarDigitalizaciones = async (updateImageSave) => {
  const items = Object.values(updateImageSave);
  const objectCategoriaCuentas = new datos("digitalizaciones");
  let response = [];
  response = objectCategoriaCuentas.insert(items);

  return response;
};

export const postDigitalizacionesCaratulas = async (dataImagen) => {
  let filesImages = [];
  console.log(dataImagen, 'll')
  // eslint-disable-next-line array-callback-return
  dataImagen.map((item) => {
    if (item.save === false) {
      //QUITAR data:image/png;base64, PARA QUE FUNCIONE EL POST
      item.image = item.image.replace(/^data:image\/[a-z]+;base64,/, "");
      filesImages.push(item);
    }
  });

  const objetoImages = new datos("images");
  const response = await objetoImages.createImages(filesImages);

  return response;
};

export const deleteImagesDigitalizaciones = async (id) => {
  const userData = new datos("digitalizaciones");
  let data = {
    id: id,
  };
  const response = await userData.delete(data);

  return response.status;
};

export const deleteImagesPrincipales = async (id) => {
  const userData = new datos("imagenes");
  let data = {
    id: id,
  };
  const response = await userData.delete(data);

  return response.status;
};

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export default function AlertDialog(props) {
  const { alertDelete, setDeleteTrue, setAlertDelete } = props;
 
  const handleClose = () => {
    setAlertDelete(!alertDelete);
  };
  const onClickSubmit = () => {
    setDeleteTrue(true);
    setAlertDelete(!alertDelete);
  };

  const Title = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF', // Color blanco para el título
    fontWeight: 'bold', // Puedes ajustar el peso de la fuente según tus preferencias
    fontSize: '1rem', // Tamaño de la fuente del título
    marginBottom: theme.spacing(0), // Espacio inferior para separar del contenido
  }));

  return (
    <div>
      <Dialog
        open={alertDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Title>{"Confirmación de eliminación"}</Title>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{paddingTop: 20,textAlign: 'center'}}>
            ¿Realmente desea eliminar la imagen seleccionada?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">Cancelar</Button>
          <Button onClick={onClickSubmit} autoFocus variant="contained"color="success">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

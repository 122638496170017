import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import esArray from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";
import { getTipoContenidos } from "src/projects/gestion_documental/helpers/TipoContenidosData";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

const SelectorTipoContenido = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = esArray(jsonForm.core.data.selectedTipoContenidos);

  const tipo_contenidos = getFromLS("siam-tipo_contenidos").tipo_contenidos;

  const [tipoContenidoSeleccionado, setTipoContenidoSeleccionado] =
    useState([]);

  useState(() => {
    const obtenerTipoContenidos = async () => {
      if (tipo_contenidos) {
        setDataDefaults(esArray(tipo_contenidos));
      } else {
        const response = await getTipoContenidos();
        if (response) {
          setDataDefaults(response);
        }
      }
    };
    obtenerTipoContenidos();
  }, []);

  useEffect(() => {
    if (tipo.length > 0) {
      const tiposs = dataDefaults.filter((dataDefault) =>
        tipo.some((obj) => dataDefault.id === obj.id_tipo_contenidos || dataDefault.id === obj.id)
      );
      setTipoContenidoSeleccionado(tiposs);
    }
  }, [tipo, dataDefaults]);

  const handleChange = (event, newValue) => {

    setTipoContenidoSeleccionado(newValue);

    // const nuevo = [...tipo]


    // const arr1 = tipo;
    // const arr2 = newValue;
    // const idsArr1 = new Set(arr1.map((item) => item.id_tipo_contenidos));
    // const idsArr2 = new Set(arr2.map((item) => item.id));

    // // Crear una copia de arr1 para modificarla

    // // Eliminar elementos en nuevoArray1 que no existan en arr2
    // arr1.forEach((item, index) => {

    //   if (!idsArr2.has(item.id_tipo_contenidos)) {
    //     nuevo.splice(index, 1);
    //   }
    // });
    // // Agregar elementos de arr2 a nuevo que no existan en arr1

    // arr2.forEach((item) => {
    //   if (!idsArr1.has(item.id)) {
    //     var objteoAdd = { id: '', id_tipo_contenidos: item.id, detalle: item.detalle }
    //     nuevo.push(objteoAdd);
    //   }

    // });

    props.handleChange(props.path, newValue); // Obtén el ID del objeto seleccionado

  };

  const handleClear = () => {
    setTipoContenidoSeleccionado([]);
    props.handleChange(props.path, []); // Actualizar el estado en el componente padre si es necesario
  };

  return (

    <div>
      <FormControl variant="standard" sx={{ width: "100%", paddingBottom: "10px" }}>
        <Autocomplete
          value={tipoContenidoSeleccionado}
          onChange={handleChange}
          options={dataDefaults || []}
          multiple
          getOptionLabel={(option) => option.id + " - " + option.detalle || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tipos de Contenidos"
              disabled={disabled}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  tipoContenidoSeleccionado && (
                    <IconButton
                      size="small"
                      onClick={handleClear}
                      style={{
                        marginLeft: '-25px', // Ajusta este valor según sea necesario
                        color: 'rgba(0, 0, 0, 0.54)',
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )
                ),
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
const SelectorTipoContenidoTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("object"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorTipoContenidos";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorTipoContenidosComponent = {
  renderer: withJsonFormsControlProps(SelectorTipoContenido),
  tester: SelectorTipoContenidoTester,
};

import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import StyleIcon from "@mui/icons-material/Style";
import { Button, MenuItem, Tooltip, FormControl,InputLabel,Select } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Snackbar from "@mui/material/Snackbar";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { TheLayoutContext } from "src/context/TheLayoutContext";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import "src/services/styles/sweetAlert.css";
import Swal from "sweetalert2";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { SwitchAjuste } from "../components/SwitchAjuste";
import { SwitchBaja } from "../components/SwitchBaja";
import { SwitchDefinitivo } from "../components/SwitchDefinitivo";
import {
  getContenidos, getContenidosSearchPaginated
} from "../helpers/ContenidosData";
import { imprimirEtiquetas } from "../helpers/EtiquetasData";
import {
  // getCajas,
  // getCajasDisponiblesPaginated,
  postCajasUbicaciones
} from "../helpers/UbicacionCajasData";
import { CajasFormUbicacionTemporal } from "./CajasFormUbicacionTemporal";
import { SearchFormGeneral } from "./SearchFormGeneral";
import { SelectRetiroPrioridad } from "./SelectRetiroPrioridad";
import HistoryIcon from "@mui/icons-material/History";
import { getContenidosSearchHistorico } from "../helpers/HistoricoCajasData";
import { MovimientosTimelineMUI } from "./MovimientosTimelineMUI";
import esArray from "src/general_components/functionEsArray";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { FormUbicaciones } from "./FormUbicacionesCajas";
import { ComponentDialog } from "../../../general_components/ComponentDialog";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const CajasUbicacionList = (datosUrl) => {
  const {
    //sectorIdSearch,
    selectionModel,
    setSelectionModel,
    //clienteIdSearch,
    setSelected,
    setClienteIdSearch,
    setSectorIdSearch,
    setDisabled,
    cajasUbicadas,
    modalState,
    setModalState,
    valueDetalleUbicacionTemporal,
    // clienteName,
    snackbarVisible,
    setSnackbarVisible,
    message,
    setMessage,
    setSeverity,
    severity,
    setRequiredFormCuentaSector,
    setRequiredFormEstadosCajas,
    setOmitirQuerySearch,
    dataSearchFormGeneral,
    setDataSearchFormGeneral,
    busquedaSearchOn,
    setBusquedaSearchOn,
    setTipoRetiroMovimientoSeleccionado,
    tipoRetiroMovimientoSeleccionado,
    setShowFormEstadosCajas,
    setShowFormFechaAltaCajas,
    setShowFormMetadatos,
    setShowFormIdCajas,
    setRequiredFormUbicaciones,
    setRequiredSector,
    setListDatosFormUbicaciones,
    setShowFormUbicaciones,
    setSoloCajas, actualizarListado, setActualizarListado,
     datosFormUbicaciones, setDatosFormUbicaciones,aProcesar,setAProcesar
  } = useContext(CajasContext);

  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const toggleModalSearch = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };

  const {setChildFooter, setExpand,s3Values,cantidadTildadas,cantidadMarcadas,cantidadTotal,procesoSeleccionado  } = useContext(TheLayoutContext)
  // eslint-disable-next-line no-unused-vars
  const [formReadOnly, setFormReadOnly] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dataList, setDataList] = useState([]);
  const [actualizarSelected, setActualizarSelected] = useState(false);

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [ubicacionesDisponibles, setUbicacionesDisponibles] = useState();
  // eslint-disable-next-line no-unused-vars
  const [cajasYubicacionesEncontradas, setCajasYubicacionesEncontradas] =
    useState(false);

  //FIXME VER QUE HACER CON ESTA VARIABLE
  const [params, setParams] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cajasEncontradas, setCajasEncontradas] = useState();
  const [mensajeSnackbar, setMensajeSnackbar] = useState();
  const [responseService, setResponseService] = useState(false);
  const [mostrarUbicacionesDisponibles, setMostrarUbicacionesDisponibles] =
    useState(false);
  const [ajuste, setAjuste] = useState(false);
  const [baja, setBaja] = useState(false);
  const [definitivo, setDefinitivo] = useState(false);
  const [modalStateHistorico, setMOdalStateHistorico] = useState(false);
  const [modalStateUbicaciones, setModalStateUbicaciones] = useState(false);
  const [historicoCaja, setHistoricoCaja] = useState(false);

  const [abrirDetalle, setAbrirDetalle] = useState(false);

  /// snackbar
  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({
    vertical: "bottom",
    horizontal: "center",
  });

  const { vertical, horizontal } = state;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  ///////////////////

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  const handleAProcesarChange = (event) => {
    setAProcesar(event.target.value);
  };

  const copiarTodos = async () => {
    let registrosSeleccionados = [];

    if (aProcesar === 'soloTildadas' || aProcesar === 'soloMarcadas') {
      registrosSeleccionados = selectionModel.filter(item => {
        const condition = (aProcesar === 'soloTildadas') ? !s3Values.includes(item) : s3Values.includes(item);
        return condition;
      }).concat(s3Values.filter(item => !selectionModel.includes(item)));
  
    } else {
      registrosSeleccionados = selectionModel
    }
  
    const idsSeparadosPorComa = registrosSeleccionados.join(',');
    copiarAlPortapapeles(idsSeparadosPorComa);
  };

  const verComprobante= async () => {
    console.log('Redirigir a la visualizacion del comprobante.')
   };

   const copiarAlPortapapeles = (texto) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(texto).then(
        () => {
          setSnackbarVisible(true);
          setSeverity("success");
          setMessage('Texto copiado al portapapeles');
        },
        (err) => {
          setSnackbarVisible(true);
          setSeverity("error");
          setMessage('Error al copiar al portapapeles');
        }
      );
    }
  };


  /// Despliega el buscador cuando se presiona F2
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
       
        setOmitirQuerySearch(true);
        setAbrirBusqueda(!abrirBusqueda);
        setRequiredFormCuentaSector(true);
        setActualizarListado(true);
        setSoloCajas(true);
        if (datosUrl.datos.valorRetiroCaja !== "1") {
          setShowFormEstadosCajas(true);
          setShowFormFechaAltaCajas(true);
          setShowFormMetadatos(true);
          setShowFormIdCajas(true);
          if (datosUrl.datos.valorDevolucionCaja === "1") {
            setShowFormUbicaciones(true)
            setRequiredFormUbicaciones(false);
            setShowFormIdCajas(true);
            setShowFormEstadosCajas(true);
          } else {
            setRequiredSector(true);
          }
        } else {
          setRequiredFormEstadosCajas(true);
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  const isMobileDevice = regexp.test(details);

  const estadoColores = {
    "Creada": "#25B03E",
    "Sin Ubicación": "#EAAA0C",
    "Alojada": "#2E6CEA",
    "Alojada Temporalmente": "pink",
    "Creada Genérica": "purple",
    "Alojada Genérica": "gray",
    "En Cliente": "teal",
    "Alojada - Esperando devolución": "brown",
    "Baja": "red",
    "No aplica": "black",
  };

  const estadoIconos = {
    "Creada": <CheckCircleOutlineIcon />,
    "Sin Ubicación": <LocationOffIcon />,
    "Alojada": <WhereToVoteIcon />,
    "Alojada Temporalmente": <AccessTimeIcon />,
    "Creada Genérica": <PlaylistAddIcon />,
    "Alojada Genérica": <PlaylistAddCheckIcon />,
    "En Cliente": <PersonPinCircleIcon />,
    "Alojada - Esperando devolución": <AlarmOnIcon />,
    "Baja": <RemoveCircleOutlineIcon />,
    "No aplica": <CloseIcon />,
    // ... otros iconos para diferentes estados
  };

  const flex = 100;
  const minWidth = 100;
  const maxWidth = 200;

  const columns = [
    {
      field: "clienteName",
      headerName: "Cliente",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 100,
      maxWidth: isMobileDevice ? 200 : 200,
      minWidth: isMobileDevice ? minWidth : 50,
    },
    {
      field: "sectorName",
      headerName: "Sector",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 2,
      maxWidth: isMobileDevice ? maxWidth : 200,
      minWidth: isMobileDevice ? minWidth : 50,
    },
    {
      field: "concatCaja",
      headerName: "Caja",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 1,
      maxWidth: isMobileDevice ? maxWidth : 350,
      minWidth: isMobileDevice ? minWidth : 175,
    },
    {
      field: "ubicacion",
      headerName: "Ubicacion",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 1,
      maxWidth: isMobileDevice ? maxWidth : 350,
      minWidth: isMobileDevice ? minWidth : 175,
    },
    {
      field: "tipo_cajas",
      headerName: "Tipo de Caja",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 1,
      maxWidth: isMobileDevice ? maxWidth : 350,
      minWidth: isMobileDevice ? minWidth : 175,
    },
    {
      field: "estados_cajas",
      headerName: "Estado",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 2,
      maxWidth: isMobileDevice ? maxWidth : 150,
      minWidth: isMobileDevice ? minWidth : 100,
      renderCell: (params) => {
        if (params.value) {
          const color = estadoColores[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
          const icono = estadoIconos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
          return (
            <Grid>
              <Button
                size="small"  // Tamaño más pequeño
                variant="text"
                style={{

                  color: color,

                }}
                disabled  // Desactiva la interacción del botón
                startIcon={icono}  // Icono en el inicio del botón
              >
                {params.value}
              </Button>
            </Grid>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      width: 100,
      minWidth: 100,
      maxWidth: 125,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Acciones">
              <span>
                <MoreVertIcon color="info" />
              </span>
            </Tooltip>
          }
          onClick={(e) => {
            handleOpenMenu(e);
            setParams(params);
          }}
          label="Acciones"
          {...(params.id.includes("cliente") || params.id.includes("sector")
            ? { hidden: true }
            : {})}
        />,
        <GridActionsCellItem
          {...(params.id.includes("cliente") || params.id.includes("sector")
            ? { hidden: true }
            : {})}
          icon={
            <Tooltip title="Historico">
              <span>
                <HistoryIcon color="info" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            toggleModalHistorico(params.id);
          }}
          label="Cajas"
        />
      ],
    },
  ];
  //Funcion para el boton imprimir
  const onClickEtiqueta = () => {
    const URI_aux = imprimirEtiquetas(params.id);
    if (URI_aux) window.open(URI_aux, "_blank");
    handleCloseMenu();
  };

  /// Contiene los datos del Jsonform


  // Se obtienen los id de los registros que se vayan seleccionando en la grilla
  // const registrosSeleccionados = selectionModel?.map((data) => {
  //   return { id: data };
  // });
 // console.log(selectionModel,'selection123333')
  // const registrosSeleccionados = Array.isArray(selectionModel)
  // ? selectionModel.map((data) => ({ id: data }))
  // : [{ id: selectionModel }];

  // function registrosSeleccionados() {
  //   console.log(selectionModel,'selectionsss')
  //   selectionModel?.map((data) => {
  //     return { id: data };
  //   });
  // }

  // Valida el formulario para que no se ingresen numeros negativo o con decimales.
  const validarForm = () => {
    const campos = [
      "idSector",
      "idModulo",
      "idEstante",
      "idPosicion",
      "idEstiba",
      "idProfundidad",
    ];

    for (const campo of campos) {
      if (datosFormUbicaciones[campo] !== undefined) {
        if (!Number.isInteger(datosFormUbicaciones[campo]) || datosFormUbicaciones[campo] < 0) {
          return false;
        }
      }
    }

    return true;
  };

  // Consume el servicio que obtiene las cajas y cantidad de ubicaciones Disponibles
  // Tambien realiza algunas validaciones y muestra sus mensajes correspondientes.
  const recuperarCajasYcalcularUbicaciones = async () => {

    let icono = "";
    let titulo = "";
    let textMensaje = "";
    let validado = true;


    //  if (datosUrl.datos.valorDevolucionCaja !== "1") {
    //   if (!dataSearchFormGeneral.search_params.ubicaciones) {
    //     icono = "error";
    //     titulo = "Oops...";
    //     textMensaje = "Debe seleccionar un deposito";
    //     validado = false;
    //   }
    // }

    // let sectorIdNumber = sectorIdSearch.map((sector) => sector.id).join(",");
    if (validado) {
      const validacionForm = validarForm();
      if (validacionForm === true) {
        let dataSearch = dataSearchFormGeneral;
        setOptions({
          ...options,
          getPaginated: getContenidosSearchPaginated,
          get: getContenidos,
          idElementData: dataSearch,
          sinFilas: false,
          totales: false
        });
        setActualizarListado(false);
        setBusquedaSearchOn(false);
      } else {
        icono = "error";
        titulo = "Oops...";
        textMensaje = "El valor de las coordenadas deben ser números mayores a 0 y no pueden contener decimales";
      }
    }

    if (icono !== "" && titulo !== "" && textMensaje !== "") {
      return Swal.fire({
        icon: icono,
        title: titulo,
        text: textMensaje,
      }).then((result) => {
        if (result.isConfirmed) {
          setActualizarListado(false);
          setBusquedaSearchOn(false);
        }
      });
    }
  };

//Funcion encargada de recargar la pagina con los ids procesados
  const recargarPaginaProcesados = (registrosFiltrados) =>{

    const arrayIndexado = registrosFiltrados.map(item => item.id);
    const cadenaFormateada = arrayIndexado.join(',');
    const data = {
      coincidencias: '1',
      eliminados: '0',
      search_params: {
        cajas: {
          fisica: '1',
          idCajasRango: cadenaFormateada,
          id_cajas: cadenaFormateada
        }
      },
      soloCajas: '1'
    };

    setDataSearchFormGeneral(data);
    setBusquedaSearchOn(true);
    setActualizarListado(true)
  }

  const procesar = async () => {
  
// Establecer parámetros de prioridad
    datosUrl.datos.ret_preferencial = "0";
    datosUrl.datos.ret_urgente = "0";

    if (datosUrl.datos.valorRetiroCaja === "1") {
      switch (tipoRetiroMovimientoSeleccionado) {
        case "2":
          datosUrl.datos.ret_preferencial = "1";
          break;
        case "3":
          datosUrl.datos.ret_urgente = "1";
          break;
        default:
          console.log("NO hay coincidencia");
      }
    }
    if (datosUrl.datos.ubicacion_caja) {
      if (!datosFormUbicaciones.idDep || datosFormUbicaciones.idDep === "" || datosFormUbicaciones.idDep === null) {
        setMessage("Debe seleccionar un deposito");
        setSeverity("warning");
        setSnackbarVisible(true);
        return;
      }
    }


    setSnackbarVisible(false);
    let registrosFiltrados = []
    let registrosSeleccionados = [];

    if (aProcesar === 'soloTildadas' || aProcesar === 'soloMarcadas') {
      registrosSeleccionados = selectionModel.filter(item => {
        const condition = (aProcesar === 'soloTildadas') ? !s3Values.includes(item) : s3Values.includes(item);
        return condition;
      }).concat(s3Values.filter(item => !selectionModel.includes(item)));
  
      registrosFiltrados = registrosSeleccionados.map((data) => ({ id: data }));
    } else {
      registrosSeleccionados = selectionModel.map((value) => ({ id: value }));
      registrosFiltrados = registrosSeleccionados;
    } 

    const resultado = await postCajasUbicaciones(
      datosFormUbicaciones,
      registrosFiltrados,
      datosUrl,
      cajasUbicadas,
      valueDetalleUbicacionTemporal,
      ajuste,
      baja,
      definitivo,
      setModalStateUbicaciones
    );
    setAbrirDetalle(!abrirDetalle);
    if (resultado.status === "true") {
      console.log(registrosFiltrados,'registrosFiltrados')
      if (datosUrl.datos.valorUbicacionTemporal === "1") {
        setModalState(!modalState);
      }

      setMostrarUbicacionesDisponibles(false);
      limpiar();
      setMessage("¡Proceso realizado con éxito!");
      setSeverity("success");
      setSnackbarVisible(true);

      Swal.fire({
        icon: "question",
        title: "Etiquetas",
        customClass: {
          container: "my-swal-delete",
        },
        text:
          "Desea imprimir las etiquetas para las cajas. \n " +
          "Al Confirmar, será redirigido a una pestaña nueva con las etiquetas. \n" +
          "También podrá imprimir las etiquetas luego, en la sección correspondiente",
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          let idCajasParaEtiquetas = registrosFiltrados
            .map((registro) => registro.id)
            .join(",");
          let URI_aux = imprimirEtiquetas(idCajasParaEtiquetas);
          if (URI_aux) window.open(URI_aux, "_blank");
          setActualizarSelected(true);
       
        } else {
          setActualizarSelected(true);
        }

        recargarPaginaProcesados(registrosFiltrados)
        
      });

    } else {
      if (datosUrl.datos.valorUbicacionTemporal === "1") {
        setModalState(!modalState);
      }
    }
 

  }


  // Limpiar todos los select,el jsonform y la grilla para empezar desde 0.
  const limpiar = async () => {
    setModalStateUbicaciones(false);
    setMostrarUbicacionesDisponibles(false);
    setDatosFormUbicaciones({ idDep: "", disabled: false });
    setFormReadOnly(false);
    setSelected(true);
    setDisabled(false);
    setSectorIdSearch([]);
    setClienteIdSearch([]);
    setDataList([]);
    setCajasYubicacionesEncontradas(false);
    setChildFooter(null);
    setExpand(false);
    setSelectionModel([]);
    setOptions({ ...options, sinFilas: true });
    setDataSearchFormGeneral([]);
    setSelectionModel([]);
    setActualizarSelected(true);
    setListDatosFormUbicaciones({ idDep: '' });
    setDefinitivo(false);
    setAjuste(false);
    setBaja(false);
  };

  const setearAjuste = async (data) => {
    setAjuste(data);
  };

  const setearBaja = async (data) => {
    setBaja(data);
  };

  const setearDefinitivo = async (data) => {
    setDefinitivo(data);
  };

  // Se utiliza para actualizar el array de id seleccionados en el listtable ya que el selectionModel
  // al limpiar sigue trayendo los elementos seleccionados anteriores
  useEffect(() => {
    if (actualizarSelected) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: true,
        actualizarSelected: actualizarSelected,
        setActualizarSelected: setActualizarSelected,
      });
    } else {
      setOptions({
        ...options,
        sinFilas: true,
        actualizarSelected: actualizarSelected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualizarSelected]);

  // Se utiliza para mostrar los mensajes en base a la respuesta que retornan los servicios en el listtablePaginated
  useEffect(() => {
    if (responseService) {
      if (responseService.status === "true") {
        let textSnackbar =
          "Se ha encontrado " +
          responseService.wsResponse.cajas_cantidad +
          " caja(s)" +
          "y " +
          responseService.wsResponse.ubicaciones_cantidad +
          " ubicaciones disponibles";
        setMessage(textSnackbar);
        setSeverity("success");
        setSnackbarVisible(true);
        if (
          datosUrl.datos.valorUbicacionTemporal === "1" ||
          datosUrl.datos.valorRetiroCaja === "1"
        ) {
          setMensajeSnackbar(
            responseService.wsResponse.cajas_cantidad + " Cajas"
          );
        } else {
          setMensajeSnackbar(
            responseService.wsResponse.cajas_cantidad +
            " Cajas y " +
            responseService.wsResponse.ubicaciones_cantidad +
            " Ubicaciones Disponibles"
          );
          setMostrarUbicacionesDisponibles(true);
        }
      } else if (responseService.status === "false") {
        setMostrarUbicacionesDisponibles(false);
        setMessage(responseService.message);
        setSeverity("warning");
        setSnackbarVisible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseService]);

  //NOTE REDECLARO LAS COLUMNAS DE ACCIONES DEL LISTTABLE
  const actionColumn = {
    field: "actions",
    headerName: "Acciones",
    type: "actions",
    flex: 0,

    getActions: (params) => [
      //Funcion para poder abrir el popup o para que te lleve al formulario de edicion o creacion

      //Boton Imprimir
      <GridActionsCellItem
        hidden={params.id.includes("CAJA") ? false : true}
        icon={
          <Tooltip title="Imprimir">
            <span>
              <StyleIcon color="info" />
            </span>
          </Tooltip>
        }
        onClick={() => {
          onClickEtiqueta(params);
        }}
        label="Imprimir"
      />,
    ],
  };

  const buttonBuscar = () => (
    <Button
      endIcon={<SearchIcon />}
      onClick={() => {
        setOmitirQuerySearch(true);
        setAbrirBusqueda(!abrirBusqueda);
        setRequiredFormCuentaSector(true);
        setActualizarListado(true);
        setSoloCajas(true);
        if (datosUrl.datos.valorRetiroCaja !== "1") {
          setShowFormEstadosCajas(true);
          setShowFormFechaAltaCajas(true);
          setShowFormMetadatos(true);
          setShowFormIdCajas(true);
          if (datosUrl.datos.valorDevolucionCaja === "1") {
            setShowFormUbicaciones(true)
            setRequiredFormUbicaciones(false);
            setShowFormIdCajas(true);
            setShowFormEstadosCajas(true);
          } else {
            setRequiredSector(true);
          }
        } else {
          setRequiredFormEstadosCajas(true);
        }
      }}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
      }}
    >
      Buscar
    </Button>
  );

  const [options, setOptions] = useState({
    cols: columns,
    getPaginated: getContenidosSearchPaginated,
    get: getContenidos,
    pageSize: [50, 100, 150, 200],
    limit: 50,
    filter: true,
    refresh: false,
    exportData: true,
    server: true,
    sinFilas: true,
    notas: true,
    idElementData: dataSearchFormGeneral,
    actionColumnCustom: actionColumn,
    hierarchyOn: true,
    detailsOn: true,
    selectCheckbox: true,
    valuesSelected: setSelectionModel,
    checkboxContenidos: "false",
    setResponseService: setResponseService,
    abmContenidos: "false",
    desabilitarSeleccionContenidos: true,
    totales: true,
    buttonExtraOn: true,
    ButtonExtra: buttonBuscar,
    ButtonExtraMarcar: true,
    ButtonExtraDesmarcar:true

  });

  useEffect(() => {
   // console.log("busquedaSearchOn",busquedaSearchOn);
  //  console.log("actualizarListado",actualizarListado);
    if (
      !Array.isArray(dataSearchFormGeneral) &&
      actualizarListado &&
      busquedaSearchOn
    ) {

      if (datosUrl.datos.valorRetiroCaja !== "1" && datosUrl.datos.valorDevolucionCaja !== "1") {
        recuperarCajasYcalcularUbicaciones()

      } else {
        //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
        setOptions({
          ...options,
          idElementData: dataSearchFormGeneral,
          sinFilas: false,
        });
        setActualizarListado(false);
        setBusquedaSearchOn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busquedaSearchOn, actualizarListado]);

  const toggleModal = () => {
    // setMostrarUbicacionesDisponibles(!mostrarUbicacionesDisponibles);
    setModalState(!modalState);
  };

 // console.log("datosUrl.datos.ubicacion_caja",datosUrl.datos.ubicacion_caja)

//  console.log(setSelectionModel,'procesoSeleccionado222222')
  // eslint-disable-next-line no-undef
  useEffect(() => {
    if (selectionModel?.length > 0) {
      setExpand(true);
    }
    setChildFooter(

      <>
        <Grid container spacing={0} alignItems="center" style={{ display: 'flex' }}>
          <Grid item xs={!isMobileDevice ? 6 : 6} justifyContent="flex-start" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid container>
              <Grid item>
                <SwitchAjuste
                  setAjuste={setearAjuste}
                  disabledCheckAjuste={selectionModel?.length > 0 ? false : true}
                />

              </Grid>
              {datosUrl.datos.valorRetiroCaja === "1" && (
                <Grid item>
                  <SwitchBaja
                    setBaja={setearBaja}
                    disabledCheckBaja={selectionModel?.length > 0 ? false : true}
                  />
                </Grid>)}
              {datosUrl.datos.valorRetiroCaja === "1" && (
                <Grid item>
                  <SwitchDefinitivo
                    setDefinitivo={setearDefinitivo}
                    disabledCheckDefinitivo={selectionModel?.length > 0 ? false : true}
                  />
                </Grid>)}

            </Grid>
          </Grid>
          {datosUrl.datos.valorRetiroCaja === "1" && (

            <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>
              <SelectRetiroPrioridad
                disabledSelector={ajuste || baja || definitivo ? true : false}
                setTipoRetiroMovimientoSeleccionado={
                  setTipoRetiroMovimientoSeleccionado
                }
                tipoRetiroMovimientoSeleccionado={
                  tipoRetiroMovimientoSeleccionado
                }
              />
            </Grid>
          )}
          <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>
            <Button
              disabled={selectionModel?.length > 0 ? false : true}
              onClick={
                datosUrl.datos.valorUbicacionTemporal === "1"
                  ? toggleModal
                  : datosUrl.datos.ubicacion_caja ? toggleModalUbicaciones : toggleModalDetalle
              }
              endIcon={<AddIcon />}
              variant="contained"
              color="success"
              style={{
                bottom: "7px",
                marginTop: '10px'
              }}
            >
              Procesa
            </Button>
          </Grid>
          {isMobileDevice ? (
            <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}></Grid>
          ) : null}

        </Grid>
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionModel, ajuste, tipoRetiroMovimientoSeleccionado, baja, definitivo]);

  // Parametros Popover
  const [open, setOpen] = useState(null); // variable popover
  // eslint-disable-next-line no-unused-vars
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const toggleModalHistorico = async (id) => {
    const response = await getContenidosSearchHistorico(id, "caja")

    if (response) {
      setHistoricoCaja(esArray(response.wsResponse.movimientos?.historico));
      toggleModalMovimientos();
    } else {

    }
   // console.log("Response", response);
  };

  const toggleModalMovimientos = async () => {
    setMOdalStateHistorico(!modalStateHistorico);

  };

  const toggleModalUbicaciones = async () => {
    setModalStateUbicaciones(!modalStateUbicaciones);

  };

  const toggleModalDetalle = async () => {
    
    setAbrirDetalle(!abrirDetalle);

  };

  return (
    <Grid>

      {modalStateHistorico && (
        <SimpleDialogComponent
          open={modalStateHistorico}
          modalName={"Historico"}
          child={<MovimientosTimelineMUI data={historicoCaja} />}
          setPushData={toggleModalMovimientos}
          handleClose={toggleModalMovimientos}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}

      {modalStateUbicaciones && (
        <SimpleDialogComponent
          open={modalStateUbicaciones}
          modalName={"Campos de ubicaciones"}
          child={<FormUbicaciones buttonEnviar={true} setPushData={procesar} toggleModal={toggleModalUbicaciones} data={datosFormUbicaciones} setData={setDatosFormUbicaciones} valorUbicacionTemporal={false} />}
          setPushData={procesar}
          handleClose={toggleModalUbicaciones}
          buttonEnviar={false}
          buttonCancelar={false}
        />
      )}

      {abrirBusqueda && (
        <SimpleDialogComponent
          open={abrirBusqueda}
          modalName={"Busqueda de cajas y contenidos"}
          child={
            <SearchFormGeneral
              procesoSeleccionado={procesoSeleccionado}
              toggleModal={toggleModalSearch}
              proceso={datosUrl.datos.proceso}
              valorUbicacionTemporal={datosUrl.datos.valorUbicacionTemporal}
              procesoGenerica={datosUrl.datos.procesoGenerica}
            />
          }
          //setPushData={ObtenerDatos}
          handleClose={toggleModalSearch}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}

      <Grid item xs={12} md={12}>
        {" "}
        <ListTablePaginated options={options} />
        {/* <ListTablePaginatedUbicacion options={options} /> */}
      </Grid>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={onClickEtiqueta}>
          <StyleIcon color="info" sx={{ mr: 2 }} />
          Imprimir
        </MenuItem>
      </Popover>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={mostrarUbicacionesDisponibles}
        //message={ubicacionesDisponibles+" Ubicaciones Disponibles"}
        key={vertical + horizontal}
        ContentProps={{
          sx: {
            background: "green",
            display: "block",
            textAlign: "center",
          },
        }}
        sx={isMobileDevice ? { width: '70%', marginLeft: '12%' } : undefined}

      >
        <Alert severity="info" sx={{ width: "100%" }}>
          {mensajeSnackbar}
        </Alert>
      </Snackbar>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={8000}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
      <ComponentDialog
          listName={"Detalle"}
          buttonClicked={abrirDetalle}
          setButtonClicked={setAbrirDetalle}
          withErrors={false} //Sirve para controlar el boton de crear y los errorres del formulario
          setPushData={procesar} //Sirve para enviar los datos al servidor
          child={
            <Grid container
              style={isMobileDevice ? { background: "white", padding: "10px", marginBottom: "10px" } : { background: "white", padding: "30px", marginBottom: "50px" }}>
              {/* <TextField
                sx={isMobileDevice ? { width: 200 } : { width: 500 }}
                id="filled-multiline-static"
                label="Detalle"
                multiline
                rows={4}
                value={detalleProcesar}
                onChange={(e) => {
                  setDetalleProcesar(e.target.value);
                }}
              /> */}
              <FormControl sx={{ mt: 1, minWidth: "100%" }} fullWidth variant="outlined" size="small">
                <InputLabel id="aProcesarLabel">A procesar</InputLabel>
                <Select
                  labelId="aProcesarLabel"
                  value={aProcesar}
                  onChange={handleAProcesarChange}
                  label="A procesar"
                >
                  { s3Values && <MenuItem value="soloMarcadas">{"Solo marcadas ("+cantidadMarcadas+")"}</MenuItem>}
                  <MenuItem value="soloTildadas">{"Solo tildadas ("+cantidadTildadas+")"}</MenuItem>
                  <MenuItem value="todas">{"Todas ("+cantidadTotal+")"}</MenuItem>
                </Select>
              </FormControl>
              <Grid item style={{marginTop:'5px'}}> 
                <Button
                variant="contained"
                onClick={copiarTodos}
                fullWidth
                startIcon={<ContentCopyIcon />}
              >
                Copiar cajas
                </Button>
                
              </Grid>
              <Grid item style={{marginTop:'5px',marginLeft:'15px'}}> 
                <Button
                variant="contained"
                onClick={verComprobante}
                fullWidth
                startIcon={<VisibilityIcon/>}
                style={{ backgroundColor: 'green', color: 'white' }}
              >
                Ver Comprobante
                </Button>
                
              </Grid>
            </Grid>
          }
        />
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={modalState}
        onClose={toggleModal}
      >
        <DialogTitle>Ubicacion Temporal</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <CajasFormUbicacionTemporal />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} variant="contained" color="error">
            Cancelar
          </Button>
          <Button 
          onClick={procesar} 
          variant="contained" color="success">
            Procesar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};


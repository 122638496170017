
import datos from "src/services/datos";

/* Cuentas Data para Personas Juridicas */
export const getCuentas = async () => {
    const objectCuentas = new datos("cuentas")

    objectCuentas.addCondition("cuentas", "id", 0, ">")
    const response = await objectCuentas.get()

    if (response) {
        return response.wsResponse.cuentas
    }
}

/* obtiene los dato de la cuenta propia del id pasado como parametro */

export const getCuentasById = async (id) => {
    const objectCuentas = new datos("cuentas")
    /* joins correspondientes a todas las relaciones de la cuenta */
    objectCuentas.addCondition("cuentas", "id", id, "=")
    objectCuentas.join('cuentas_personas_juridicas', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_categoria_cuentas', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_documentos_inscripciones', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_localizaciones', "id_cuentas", "id", "=")
    //  objectCuentas.innerJoinTables('cuentas_localizaciones_tipo_contenidos','cuentas_localizaciones',"id_cuentas_localizaciones","id", "=")
    objectCuentas.join('cuentas_servicios_internet', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_telefonos', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_parametros', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_contactos', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_info_adicional', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_impuestos_situaciones', "id_cuentas", "id", "=")
    objectCuentas.innerJoinTables('localidad', 'cuentas_localizaciones', "id", "id_localidad", "=")
    objectCuentas.innerJoinTables('departamento', 'localidad', "id", "id_departamento", "=")
    objectCuentas.innerJoinTables('provincia', 'departamento', "id", "id_provincia", "=")
    objectCuentas.innerJoinTables('pais', 'provincia', "id", "id_pais", "=")
    const response = await objectCuentas.get()
    if (response) {
        return response.wsResponse.cuentas
    }
}

export const getCuentasFisicasById = async (id) => {
    const objectCuentas = new datos("cuentas")
    /* joins correspondientes a todas las relaciones de la cuenta */
    objectCuentas.addCondition("cuentas", "id", id, "=")
    objectCuentas.join('cuentas_personas_fisicas', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_categoria_cuentas', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_documentos_inscripciones', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_localizaciones', "id_cuentas", "id", "=")
    //  objectCuentas.innerJoinTables('cuentas_localizaciones_tipo_contenidos','cuentas_localizaciones',"id_cuentas_localizaciones","id", "=")
    objectCuentas.join('cuentas_servicios_internet', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_telefonos', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_parametros', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_contactos', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_info_adicional', "id_cuentas", "id", "=")
    objectCuentas.join('cuentas_impuestos_situaciones', "id_cuentas", "id", "=")
    objectCuentas.innerJoinTables('localidad', 'cuentas_localizaciones', "id", "id_localidad", "=")
    objectCuentas.innerJoinTables('departamento', 'localidad', "id", "id_departamento", "=")
    objectCuentas.innerJoinTables('provincia', 'departamento', "id", "id_provincia", "=")
    objectCuentas.innerJoinTables('pais', 'provincia', "id", "id_pais", "=")
    const response = await objectCuentas.get()
    if (response) {
        return response.wsResponse.cuentas
    }
}

export const postCuentas = async (data) => {
    //console.log(data,'datos')

    const id = data.cuentas.id && data.cuentas.id.toString();
    //const es_owner = data.cuentas.es_owner && data.cuentas.es_owner.toString();

    const id_categoria_cuentas = data.cuentas_categoria_cuentas.id_categoria_cuentas && (data.cuentas_categoria_cuentas.id_categoria_cuentas).toString();
    const relacion_id_categoria_cuentas = data.cuentas_categoria_cuentas.id && (data.cuentas_categoria_cuentas.id).toString();


    const relacion_id_documento_tipo = data.cuentas_documentos_inscripciones.id && (data.cuentas_documentos_inscripciones.id).toString();
    const id_documento_tipo = data.cuentas_documentos_inscripciones.id_documento_tipo && (data.cuentas_documentos_inscripciones.id_documento_tipo).toString();
    const numero = data.cuentas_documentos_inscripciones.numero && (data.cuentas_documentos_inscripciones.numero).toString();

    const {
        razon_social,
        nombre_fantasia
    } = data.cuentas_personas_juridicas

    const relacion_id_impuesto_situacion_seleccionado = data.cuentas_impuesto_situacion.id || "";
    const id_impuesto_situacion_seleccionado = data.cuentas_impuesto_situacion.id_impuestos_situaciones || "";

    let raw = ""

    console.log("relacion_id_categoria_cuentas",relacion_id_categoria_cuentas)
    raw = JSON.stringify({
        "cuentas": {
            ...id && { "id": id }, //setea el id condicionalmente para hacer un insert o un update
            "es_owner": '0'
        },
        "cuentas_personas_juridicas": {
            "razon_social": razon_social,
            "nombre_fantasia": nombre_fantasia
        },
        "cuentas_categoria_cuentas": {
            "id": relacion_id_categoria_cuentas,
            "id_categoria_cuentas": id_categoria_cuentas
        },

        "cuentas_documentos_inscripciones": {
            "id": relacion_id_documento_tipo,
            "id_documento_tipo": id_documento_tipo,
            "numero": numero,
        },

        "cuentas_impuestos_situaciones": id_impuesto_situacion_seleccionado !== "" ? [
            {
                "cuentas_impuestos_situaciones": {
                    ...relacion_id_impuesto_situacion_seleccionado && { "id": relacion_id_impuesto_situacion_seleccionado },
                    "id_impuestos_situaciones": id_impuesto_situacion_seleccionado
                }
            }
        ] : [],
    });

    var datosParse = JSON.parse(raw);
    const objectCuentas = new datos("cuentas");

    const response = id ? await objectCuentas.cuentasCreate(datosParse, true) :
        await objectCuentas.cuentasCreate(datosParse);
    return response
}

export const postCuentasFisicas = async (data) => {

    const id = data.cuentas.id && data.cuentas.id.toString();
    //const es_owner = data.cuentas.es_owner && data.cuentas.es_owner.toString();

    const id_categoria_cuentas = data.cuentas_categoria_cuentas.id_categoria_cuentas && (data.cuentas_categoria_cuentas.id_categoria_cuentas).toString();
    const relacion_id_categoria_cuentas = data.cuentas_categoria_cuentas.id && (data.cuentas_categoria_cuentas.id).toString();


    const relacion_id_documento_tipo = data.cuentas_documentos_inscripciones.id && (data.cuentas_documentos_inscripciones.id).toString();
    const id_documento_tipo = data.cuentas_documentos_inscripciones.id_documento_tipo && (data.cuentas_documentos_inscripciones.id_documento_tipo).toString();
    const numero = data.cuentas_documentos_inscripciones.numero && (data.cuentas_documentos_inscripciones.numero).toString();

    const {
        nombre,
        apellido,
        fecha_nacimiento,
        id_estado_civil,
        id_genero
    } = data.cuentas_personas_fisicas

    const relacion_id_impuesto_situacion_seleccionado = data.cuentas_impuesto_situacion.id || "";
    const id_impuesto_situacion_seleccionado = data.cuentas_impuesto_situacion.id_impuestos_situaciones || "";

    let raw = ""

    raw = JSON.stringify({
        "cuentas": {
            ...id && { "id": id }, //setea el id condicionalmente para hacer un insert o un update
            "es_owner": '0'
        },
        "cuentas_personas_fisicas": {
            "nombre" : nombre,
            "apellido": apellido,
            "fecha_nacimiento": fecha_nacimiento,
            "id_estado_civil": id_estado_civil,
            "id_genero": id_genero
        },
        "cuentas_categoria_cuentas": {
            "id": relacion_id_categoria_cuentas,
            "id_categoria_cuentas": id_categoria_cuentas
        },

        "cuentas_documentos_inscripciones": {
            "id": relacion_id_documento_tipo,
            "id_documento_tipo": id_documento_tipo,
            "numero": numero,
        },

        "cuentas_impuestos_situaciones": id_impuesto_situacion_seleccionado !== "" ? [
            {
                "cuentas_impuestos_situaciones": {
                    ...relacion_id_impuesto_situacion_seleccionado && { "id": relacion_id_impuesto_situacion_seleccionado },
                    "id_impuestos_situaciones": id_impuesto_situacion_seleccionado
                }
            }
        ] : [],
    });

    var datosParse = JSON.parse(raw);
    const objectCuentas = new datos("cuentas");

    const response = id ? await objectCuentas.cuentasCreate(datosParse, true) :
        await objectCuentas.cuentasCreate(datosParse);
    return response
}



export const deleteCuentas = async (data) => {
}

export const schemaArticulosForm = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "id",
      description: "Id",
      hidden: true,
    },
    nombre: {
      type: "string",
      title: "Nombre",
      description: "Nombre",
      minLength: 3 
    },
    nombre_reducido: {
      type: "string",
      title: "Nombre Reducido",
      description: "Nombre Reducido",
      minLength: 3
    },
    alias: {
      type: "string",
      title: "Alias",
      description: "Alias",
    },
    selectedCategoria: {
      type: "string",
      customRender: "SelectorArticulosCategorias",
      description: "Seleccione una categoria",
    },
    selectedArticulosTipo: {
      type: "string",
      customRender: "SelectorArticulosTipo",
    },
    selectedTipoMoneda: {
      type: "string",
      customRender: "SelectorTipoMoneda",
    },
    selectedAlicuotasIVA: {
      type: "string",
      customRender: "SelectorAlicuotasIVA",
    }
    
  },
  required: [ "nombre", "nombre_reducido","alias" ],
  
}
 




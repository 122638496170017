import React, { useContext, useEffect, useState } from "react";
import { ArticulosContext } from "src/context/ArticulosContext";
import { Box, Grid, Container } from "@mui/material";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import { schemaArticulosForm } from "src/forms/schemaArticulosForm";
import { uiSchemaArticulosForm } from "src/forms/uiSchemaArticulosForm";
import { JsonForms } from "@jsonforms/react";
import { SelectorArticulosTipoComponent } from "src/forms/customRenders/selectorArticulosTipo";
import { SelectorArticulosCategoriaComponent } from "src/forms/customRenders/selectorArticulosCategorias";
import { SelectorTipoMonedaComponent } from "src/forms/customRenders/selectorTipoMoneda";
import { SelectorAlicuotasIVAComponent } from "src/forms/customRenders/selectorAlicuotasIVA";
import { createArticulos } from "../helpers/ArticulosData";
import { Buttons } from "src/general_components/Buttons";
const localize = require("ajv-i18n");
/* Formulario contenedor de los datos de las CUENTAS JURIDICAS */

export const ArticulosForm = (props) => {


  const {
    modEdit,
    articulosFormData,
    setArticulosFormData,
    setMessage,
    setSeverity,
    setSnackbarVisible,
    saveData,
    setRefresh,
    setModalState,
  } = useContext(ArticulosContext);

  const {
    toggleModal
  } = props;

  /* definicion de constantes de estado para utiliar con sus respectivos formularios */
  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [dataForm, setDataForm] = useState({});
  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [withErrors, setWithErrors] = useState(false);

  // Consume el servicio articulosCreate para insertar un articulo
  const procesarAlta = async () => {
    // se arma la estrustura del json para que se una al servicio articulosCreate
    var datosCreate = {
      articulos: {
        id_articulos_tipo: `${articulosFormData.selectedArticulosTipo}`,
        alias: `${articulosFormData.alias}`,
        id_categorias: `${articulosFormData.selectedCategoria}`,
        nombre: `${articulosFormData.nombre}`,
        nombre_reducido: articulosFormData.nombre_reducido ? `${articulosFormData.nombre_reducido}` : "",
        id_alicuotas_iva: articulosFormData.selectedAlicuotasIVA ? `${articulosFormData.selectedAlicuotasIVA}` : "1",
        id_tipo_moneda: `${articulosFormData.selectedTipoMoneda}`,
      },
      articulos_servicios: [
        {
          insert: true,
        },
      ],
    };

    var response = await createArticulos(datosCreate);
    // En base al status que devuelve el servicio se muestra un mensaje
    // Si es true se refresca la grilla y se cierra el modal
    // De lo contrario se muestra el mensaje de validacion del servicio
    if (response.status === "true") {
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage("Articulo añadido con exito!!!");
      setRefresh(true);
      setModalState(false);
    } else {
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage(response.message);
    }
    return response;
  };

  // Se usa para asignarle valores a todos los atributos del json form en el caso de que sea un update
  useEffect(() => {
    if (props.data) {
      setArticulosFormData({
        id: props.data.id,
        nombre: props.data.nombre,
        nombre_reducido: props.data.nombre_reducido,
        alias: props.data.alias,
        selectedCategoria: props.data.id_categorias,
        selectedArticulosTipo: props.data.id_articulos_tipo,
        selectedTipoMoneda: props.data.id_tipo_moneda,
        selectedAlicuotasIVA: props.data.id_alicuotas_iva,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setArticulosFormData(data);

    return { errors, data };
  };

  const handleSubmit = () => {
    if (modEdit) {
      saveData();
    } else {
      procesarAlta();
    }
  };


  const toggleModalForm = () => {
    setModalState(false);
    if (modEdit) {
      toggleModal();
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid item xs={12}>
          <Box
            sx={{
              mx: "auto",
              width: "100%",
              p: 1,
              m: 1,
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            <JsonForms
              onChange={({ errors, data }) => onChange && onChange(errors, data)}
              schema={schemaArticulosForm}
              uischema={uiSchemaArticulosForm}
              data={articulosFormData}
              renderers={[
                ...materialRenderers,
                SelectorArticulosTipoComponent,
                SelectorArticulosCategoriaComponent,
                SelectorTipoMonedaComponent,
                SelectorAlicuotasIVAComponent,
              ]}
              cells={materialCells}

            />
          </Box>
        </Grid>
        {/* <Grid item xs={2} textAlign="left">
          <Button
            variant="contained"
            size="small"
            disabled={withErrors ? true : false}
            onClick={() => {
              if (modEdit) {
                saveData();
              } else {
                procesarAlta();
              }
            }}
          >
            {" "}
            Guardar
          </Button>
        </Grid> */}

        <Grid sx={{ mt: 1, marginTop: 5 }}>
          <Buttons
            disableButton={false}
            onClickSubmit={handleSubmit}
            labelButtonSave={"Confirmar"}
            buttonCerrar={toggleModalForm}
          />
        </Grid>
      </Container>
    </>
  );
};

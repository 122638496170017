export const schemaCuentasSearchForm  = {
    type: "object",
    properties: {
      id: {
        type: "string",
        title: "ID Cuenta",
        description: "ID",
      },
      razon_social:{
        type: "string",
        hidden: true,
      },
      localidad: {
        type: "string",
        customRender: "SelectorLocalidad",
      },
      cuit:{
        type: "string",
        hidden: true,
      }
    },
    required: [ ""],
  };
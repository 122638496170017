import { useContext, useEffect, useState } from "react";

import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { AuthContext } from "src/context/AuthContext";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { DateFormComponent } from "../../../forms/customRenders/dateForm";
import { schemaCajasContenidos } from "../../../forms/schemaCajasContenidos";
import { schemaDigitalizacionAgregar } from "../../../forms/schemaDigitalizacionAgregar";
import { uiSchemaCajasContenidos } from "../../../forms/uiSchemaCajasContenidos";
import { uiSchemaDigitalizacionAgregar } from "../../../forms/uiSchemaDigitalizacionAgregar";
import { updateContenidos } from "../helpers/ContenidosData";

export const SearchContenidosFormEdit = (data = null) => {
  const auth = useContext(AuthContext);
  const {
    tipoContenido,
    dataInfoBusqueda,
    setDataInfoBusqueda,
    modalState,
    setModalState,
    setSnackbarVisible,

    setMessage,

    setSeverity,
    refresh,
    setRefresh,
  } = useContext(CajasContext);

  const [contenidoUpd, setContenidoUpd] = useState({
    id: data.data.idContenido,
    id_cajas: data.data.idCaja,
    id_tipo_contenidos: tipoContenido,
  });
  let contenido = [];
  contenido.id = data.data.idContenido;
  contenido.id_cajas = data.data.idCaja;
  contenido.id_tipo_contenidos = tipoContenido;
  const localize = require("ajv-i18n");

  const onChangeBusqueda = (errors, data) => {
    localize.es(errors);
    setDataInfoBusqueda(data);
    return { errors, data };
  };

  useEffect(() => {
    if (!contenido.meta_datos) {
      contenido.meta_datos = [];
    }
    let metaDatos = esArray(data.data.meta_datos);
    for (let i = 0; i < metaDatos.length; i++) {
      switch (metaDatos[i].metadato_label_form) {
        case "TEXTO":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: dataInfoBusqueda.detalle,
          });
          break;
        case "FECHA_DESDE":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: dataInfoBusqueda.fecha_desde,
          });
          break;
        case "FECHA_HASTA":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: dataInfoBusqueda.fecha_hasta,
          });
          break;
        case "NUMERO_DESDE":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: `${dataInfoBusqueda.numero_desde}`,
          });
          break;
        case "NUMERO_HASTA":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: `${dataInfoBusqueda.numero_hasta}`,
          });
          break;
        case "GRUPO":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: dataInfoBusqueda.grupo,
          });
          break;
        case "CONTRATO":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: dataInfoBusqueda.contrato,
          });
          break;
        case "TITULAR":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: dataInfoBusqueda.titular,
          });
          break;
        case "DNI":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: `${dataInfoBusqueda.dni}`,
          });
          break;
        case "FECHA_COMPRA":
          contenido.meta_datos.push({
            id: metaDatos[i].id,
            id_metadatos: metaDatos[i].id_metadatos,
            valor: dataInfoBusqueda.fechaCompra,
          });
          break;
        default:
      }
    }

    verificarExistencia();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfoBusqueda, refresh]);

  useEffect(() => {
    if (data.data.meta_datos) {
      let metaDatos = esArray(data.data.meta_datos);
      let numero_desde = undefined;
      let numero_hasta = undefined;
      let fecha_desde = undefined;
      let fecha_hasta = undefined;
      let detalle = undefined;
      let grupo = undefined;
      let contrato = undefined;
      let titular = undefined;
      let dni = undefined;
      let fecha_compra = undefined;
      let id_caja = Number(data.data.idCaja);
      let id_contenido = Number(data.data.idContenido);
      for (let i = 0; i < metaDatos.length; i++) {
        switch (metaDatos[i].metadato_label_form) {
          case "TEXTO":
            detalle = metaDatos[i].metadato_valor;
            break;
          case "FECHA_DESDE":
            fecha_desde = metaDatos[i].metadato_valor;
            break;
          case "FECHA_HASTA":
            fecha_hasta = metaDatos[i].metadato_valor;
            break;
          case "NUMERO_DESDE":
            numero_desde = Number(metaDatos[i].metadato_valor);
            break;
          case "NUMERO_HASTA":
            numero_hasta = Number(metaDatos[i].metadato_valor);
            break;
          case "GRUPO":
            grupo = metaDatos[i].metadato_valor;
            break;
          case "CONTRATO":
            contrato = metaDatos[i].metadato_valor;
            break;
          case "TITULAR":
            titular = metaDatos[i].metadato_valor;
            break;
          case "DNI":
            dni = Number(metaDatos[i].metadato_valor);
            break;
          case "FECHA_COMPRA":
            fecha_compra = metaDatos[i].metadato_valor;
            break;
          default:
        }
      }
      setDataInfoBusqueda({
        idContenido: id_contenido,
        idCaja: id_caja,
        detalle: detalle,
        numero_desde: numero_desde,
        numero_hasta: numero_hasta,
        fecha_desde: fecha_desde,
        fecha_hasta: fecha_hasta,
        grupo: grupo,
        contrato: contrato,
        titular: titular,
        dni: dni,
        fechaCompra: fecha_compra,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verificarExistencia = (data) => {
    let existeMetaDato = "";
    if (tipoContenido === "1") {
      // Tipo de contenido Estandar
      for (let i = 1; i < 6; i++) {
        existeMetaDato = contenido.meta_datos
          ? contenido.meta_datos.find(
            (metadato) => metadato.id_metadatos === `${i}`
          )
          : undefined;
        switch (i) {
          case 1: // fecha desde
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "1",
                valor: dataInfoBusqueda.fecha_desde,
              });
            }
            break;
          case 2: // fecha hasta
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "2",
                valor: dataInfoBusqueda.fecha_hasta,
              });
            }
            break;
          case 3: // numero desde
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "3",
                valor: `${dataInfoBusqueda.numero_desde}`,
              });
            }
            break;
          case 4: // numero hasta
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "4",
                valor: `${dataInfoBusqueda.numero_hasta}`,
              });
            }
            break;
          case 5: // detalle
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "5",
                valor: dataInfoBusqueda.detalle,
              });
            }
            break;
          default:
        }
      }
    } else if (tipoContenido === "2") {
      // tipo contenido legajo
      for (let i = 6; i < 11; i++) {
        existeMetaDato = contenido.meta_datos
          ? contenido.meta_datos.find(
            (metadato) => metadato.id_metadatos === `${i}`
          )
          : undefined;
        switch (i) {
          case 6: // Grupo
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "6",
                valor: dataInfoBusqueda.grupo,
              });
            }
            break;
          case 7: // contrato
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "7",
                valor: dataInfoBusqueda.contrato,
              });
            }
            break;
          case 8: // titular
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "8",
                valor: dataInfoBusqueda.titular,
              });
            }
            break;
          case 9: // dni
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "9",
                valor: `${dataInfoBusqueda.dni}`,
              });
            }
            break;
          case 10: // fecha_compra
            if (existeMetaDato === undefined) {
              contenido.meta_datos.push({
                id: "",
                id_metadatos: "10",
                valor: dataInfoBusqueda.fechaCompra,
              });
            }
            break;
          default:
        }
      }
    }
    setContenidoUpd({ ...contenidoUpd, meta_datos: contenido.meta_datos });
  };

  // funcion que verifica si una variable es un array y lo convierte en caso de que no lo sea
  const esArray = (data) => {
    var arr = "";
    // Si es un array se mantiene como estaba
    if (Array.isArray(data)) {
      arr = data;
      // Si no es un array... primero se verifica que tenga valores ... si no los tiene
    } else if (typeof data === "undefined") {
      // se pasa un array vacio
      arr = [];
    } else {
      // si trae un solo elemento , es un objeto
      arr = [data]; // se lo convierte a array
    }
    return arr;
  };

  const handleSubmit = async (e) => {
    let responseServicio = await updateContenidos(contenidoUpd, auth);
    if (responseServicio.status === "false") {
      setMessage(responseServicio.message);
      setSeverity("error");
      setSnackbarVisible(true);
    } else {
      setMessage("Se ha modificado exitosamente el contenido");
      setSeverity("success");
      setSnackbarVisible(true);
      setModalState(!modalState);
      setDataInfoBusqueda({});
      setRefresh(!refresh);
    }
  };

  return (
    <>
      {tipoContenido === "1" && (
        <JsonForms
          schema={schemaCajasContenidos}
          uischema={uiSchemaCajasContenidos}
          data={dataInfoBusqueda}
          renderers={[...materialRenderers, DateFormComponent]}
          cells={materialCells}
          onChange={({ errors, data }) =>
            onChangeBusqueda && onChangeBusqueda(errors, data)
          }
        />
      )}
      {tipoContenido === "2" && (
        <JsonForms
          schema={schemaDigitalizacionAgregar}
          uischema={uiSchemaDigitalizacionAgregar}
          renderers={[...materialRenderers, DateFormComponent]}
          data={dataInfoBusqueda}
          cells={materialCells}
          onChange={({ errors, data }) =>
            onChangeBusqueda && onChangeBusqueda(errors, data)
          }
        />
      )}
      <Stack direction="row" spacing={2} style={{ marginTop: "5%" }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<CheckCircleOutlineIcon />}
          onClick={handleSubmit}
        >
          Procesar
        </Button>
        <Button
          variant="contained"
          color="error"
          endIcon={<HighlightOffIcon />}
        >
          Cancelar
        </Button>
      </Stack>
    </>
  );
};

import {
  AddCircleOutlined,
  DeleteSweepOutlined,
  FilterListOutlined,
} from "@mui/icons-material";
import { Button, Container, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { getArticulos } from "src/projects/articulos/helpers/ArticulosData";
import { getCuentasJuridicas } from "../cuentas/helpers/CuentasJuridicasData";
import {
  deleteCuentasServicios,
  getCuentasServiciosPlain,
} from "../cuentas/helpers/CuentasServicios";
import { CuentasServiciosFiltroForm } from "../cuentas/components/CuentasServiciosFiltroForm";
import { CuentasServiciosForm } from "../cuentas/components/CuentasServiciosForm";
import { ListTableCuentasServicios } from "../cuentas/components/ListTableCuentasServicios";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";

export const CuentasServiciosList = (props) => {
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState("");

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [severity, setSeverity] = useState("");

  const [cuentasFiltrosOpen, setCuentasFiltrosOpen] = useState(false);
  const [serviciosFormOpen, setServiciosFormOpen] = useState(false);
  const [rows, setRows] = useState();
  const [cuentas, setCuentas] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modalModificar, setModalModificar] = useState(false);
  const initialDataForm = {
    activo: true,
    cantidad: 0,
    importe: 0,
    activoFechaInicio: true,
    activoFechaFin: true,
    descripcion: "",
    selectedCuentaJuridica: undefined,
    selectedServicio: undefined,
  };

  const [dataForm, setDataForm] = useState({
    ...initialDataForm,
  });

  const filtros = (data) => {
    setDataForm({
      ...dataForm,
      selectedCuentaJuridica: data.selectedCuentaJuridica
        ? data.selectedCuentaJuridica
        : undefined,
      selectedServicio: data.selectedServicio
        ? data.selectedServicio
        : undefined,
    });
  };

  const getAllCuentas = async () => {
    let aux_storage = JSON.parse(localStorage.getItem("siam-cuentasjuridicas"));
    if (aux_storage) {
      setCuentas(aux_storage);
    } else {
      const response = await getCuentasJuridicas();
      if (response) {
        setCuentas(response);
        localStorage.setItem("siam-cuentasjuridicas", JSON.stringify(response));
      }
    }
  };

  const getArticulosCall = async () => {
    let aux_storage = JSON.parse(localStorage.getItem("siam-cuentasservicios"));
    if (aux_storage) {
      setArticulos(aux_storage);
    } else {
      const response = await getArticulos();
      if (response) {
        setArticulos(response.data);
        localStorage.setItem(
          "siam-cuentasservicios",
          JSON.stringify(response.data)
        );
      }
    }
  };

  useEffect(() => {
    getAllCuentas();
    getArticulosCall();
  }, []);

  const columns = [
    { field: "articulo", headerName: "Servicio", flex: 1 },
    { field: "razon_social", headerName: "Cuenta", flex: 1 },
    {
      field: "importeTrimmed",
      headerName: "Importe",
      flex: 0.4,
      align: "right",
    },
    { field: "activoString", headerName: "Activo", flex: 0.2, align: "center" },
  ];

  const getCuentasServicios = async () => {
    let response = await getCuentasServiciosPlain(
      dataForm?.selectedCuentaJuridica?.id_cuentas || undefined,
      dataForm?.selectedServicio?.id || undefined
    );
    if (response) {
      Array.isArray(response) ? setRows(response) : setRows([response]);
    }
  };

  useEffect(() => {
    if (!modalModificar) {
      getCuentasServicios();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  const selectToEdit = (id) => {
    setModalModificar(!modalModificar);
    setSelectedId(id);

  };


  const [options, setOptions] = useState({
    cols: columns,
    get: "",
    getPaginated: "",
    pageSize: [50, 100, 150, 200],
    edit: true,
    editButton: true,
    deleteButton: true,
    limit: 50,
    filter: true,
    exportData: true,
    createButton: true,
    setCreateButton: true,
    setDataEdit: false,
    server: false,
    toEdit: selectToEdit,
    delete: deleteCuentasServicios,
    callback: getCuentasServicios,
  });

  useEffect(() => {
    if (rows) {
      if (cuentas.length > 0 && articulos.length > 0) {
        rows.forEach((element) => {
          let art = articulos.filter((a) => a.id === element.id_articulos)[0]
            .nombre;
          let importeTrimmed = element.importe.slice(0, -2);
          element.articulo = art;
          let filtro = cuentas.filter(
            (c) => c.id_cuentas === element.id_cuentas
          )
          if (filtro.length > 0) {
            element.razon_social = cuentas.filter(
              (c) => c.id_cuentas === element.id_cuentas
            )[0].razon_social;
          }
          element.activoString = element.activo === '1' ? "SI" : "NO";
          element.importeTrimmed = "$ " + importeTrimmed;
        });
        setOptions({ ...options, res: rows });
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, articulos, cuentas]);

  useEffect(() => {
    if (modalModificar) {
      if (selectedId > 0) {
        let filtered = rows.filter((r) => r.id === selectedId);
        setDataForm(filtered);
        setServiciosFormOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, modalModificar]);

  const vaciarFiltro = () => {
    setDataForm({
      ...dataForm,
      selectedCuentaJuridica: undefined,
      selectedServicio: undefined,
    });
  };
  console.log("serviciosFormOpen", serviciosFormOpen)

  return (
    <Grid style={{ marginBottom: "50px", background: "#FFFFFFF", paddingTop: 10 }}>
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          Servicios
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <Container maxWidth="lg" style={{ padding: "0px 0px 0px 0px" }}>


        <Box display="flex" justifyContent="flex-end">

          <Button
            onClick={() => setCuentasFiltrosOpen(true)}
            variant="contained"
            startIcon={<FilterListOutlined />}
            sx={{ mr: 1 }}
          >
            Filtrar
          </Button>

          <Button
            onClick={() => {
              setDataForm(initialDataForm);
              setServiciosFormOpen(true);
            }}
            variant="contained"
            startIcon={<AddCircleOutlined />}
            sx={{ mr: 1 }}

          >
            Agregar
          </Button>

          <Button
            onClick={vaciarFiltro}
            variant="contained"
            startIcon={<DeleteSweepOutlined />}

          >
            Limpiar Filtro
          </Button>

        </Box>
        <Grid container spacing={2} justifyContent="center" marginTop={'10px'}>
          <Grid item xs={12} sm={6} >
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography align="left" variant="h8" component="div">
                  Razon Social
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  value={dataForm?.selectedCuentaJuridica?.razon_social || ""}
                  disabled
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography align="left" variant="h8" component="div">
                  Servicio
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  value={dataForm?.selectedServicio?.nombre || ""}
                  disabled
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <SimpleDialogComponent
          modalName={"Filtro de Cuentas"}
          open={cuentasFiltrosOpen}
          handleClose={() => setCuentasFiltrosOpen(!cuentasFiltrosOpen)}
          child={
            <CuentasServiciosFiltroForm
              props={{ filtros }}
              close={() => setCuentasFiltrosOpen(!cuentasFiltrosOpen)}
            />
          }
          buttonEnviar={false}
        />
        <SimpleDialogComponent
          modalName={"Formulario de Servicios"}
          maxWidth={"sm"}
          open={serviciosFormOpen}
          handleClose={() => { setServiciosFormOpen(!serviciosFormOpen); setModalModificar(!modalModificar) }}
          child={
            <CuentasServiciosForm
              datos={dataForm}
              update={() => getCuentasServicios()}
              close={() => { setServiciosFormOpen(!serviciosFormOpen); setModalModificar(!modalModificar) }}
            />
          }
          buttonEnviar={false}
        />

        {!loading && <ListTableCuentasServicios options={options} />}

        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          close={() => {
            setSnackbarVisible(false);
          }}
        />
      </Container>
    </Grid>
  );
};

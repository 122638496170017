import datos from "src/services/datos";
import Swal from "sweetalert2";

const alicuota = new datos('alicuotas_iva')

export const getAlicuotaIVA = async (auth) => {
    alicuota.addCondition('alicuotas_iva','id',0,'>')
    const response = await alicuota.get()
    return response.wsResponse.alicuotas_iva
};

export const getAlicuotaIVAPaginated = async (limit, offset) => {
    alicuota.addCondition('alicuotas_iva','id',0,'>')
    alicuota.addLimitCondition(limit,offset)
    const response = await alicuota.get()
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/
    
    let result = {}
    if (!response.wsResponse.empty) {

        result = {
            data: [...response.wsResponse.alicuotas_iva],
            total_records: response.wsResponse.total_records
        }
        return result

    }
};

export const postAlicuotaIVA = async(alicuotas) => {
    const response = alicuota.insert(alicuotas)
    return response;
};

export const deleteAlicuotaIVA = async(id) => {
    let status = false;
    await Swal.fire({
        title: "Esta seguro de que desea eliminar este Alicuota?",
        text: "Si sigue adelante, no se podran revertir los cambios!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, quiero eliminarla!",
        cancelButtonText: "No, no estoy seguro!",
    }).then(async (result) => {
        if (result.isConfirmed) {
            const userData = new datos('alicuotas_iva');
            let data = {
                id: id
            }
            const response = await userData.delete(data);

            if (response.status === "true") {
                status = true;
                await Swal.fire(
                "Eliminado!",
                "La Alicuota ha sido eliminada exitosamente",
                "success"
                );
            }else{
                await Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response.message
                });
            }
            }
        });
            
    return status
};

import datos from "src/services/datos";
import Swal from "sweetalert2";

const estados = new datos("estado_civil");

export const getEstadoCivil = async (auth) => {
  estados.addCondition("estado_civil", "id", 0, ">");
  const response = await estados.get();
  return response.wsResponse.estado_civil;
};

export const getEstadoCivilPaginated = async (limit, offset) => {
  estados.addCondition("estado_civil", "id", 0, ">");
  estados.addLimitCondition(limit, offset);
  const response = await estados.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: [...response.wsResponse.estado_civil],
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const postEstadoCivil = async (estado) => {
  if (estado) {
    const data = {
      id: estado.id,
      nombre: estado.detalle,
    };
    const response = await estados.insert(data)
    if (response) {
      return response.wsResponse.estado_civil;
    }
  }
  return false;
};

export const deleteEstadoCivil = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este estado civil?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarloa!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {

      const userData = new datos('estado_civil');
      let data = {
        id: id
      }
      const response = await userData.delete(data);

      if (response.status === "true") {

        status = true;
        await Swal.fire(
          "Eliminado!",
          "El estado civil ha sido eliminado exitosamente.",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message
        });
      }
    }
  });
  return status;
};

// Metodo creada unicamente para el insert.
// Recibe un props que contiene el modelo y los datos
//
// retorna el json parametros con params armado.

/**
 * NOTE: Servicio para insertar datos en el servidor
 * 
 * Fue modificado para que en el caso de que se le necesite pasar un array con varios objetos pueda modificarlo y metener un name para cada objeto
 */

export const paramsInsert = (params) => {
  let model = [];
  if(!Array.isArray(params.attributes)){

    const modelPost= {'model':[{'@name':params.model,'attributes':params.attributes}]}
    return modelPost;

  }else{
    params.attributes.forEach((element) => {
      model.push({ "@name": params.model, attributes: element });
    });
    const modelPost = {
      model: model,
    };
  
    return modelPost;
  }

  
};

export default paramsInsert;

export const schemaComprobantesForm = {
  type: "object",
  properties: {
    cliente: {
      type: "number",
      customRender: "SelectorClientes",
      description: "Ingrese cliente",
    },
    tipo: {
      type: "number",
      customRender: "SelectorTipoComprobantes",
      description: "Ingrese un Tipo",
    },
    comprobante_codigo: {
      type: "number",
      customRender: "SelectorComprobantesCodigo",
      description: "Seleccione un codigo",
    },
    codigo_cajas: {
      type: "number",
      customRender: "SelectorCodigoCajas",
      description: "Seleccione una caja",
    },
    comprobante_estado: {
      type: "number",
      customRender: "SelectorComprobanteEstado",
      description: "Seleccione un Estado",
    },
    punto_venta: {
      type: "number",
      customRender: "SelectorPuntoVenta",
      description: "Seleccione un Punto de Venta",
    },
    terminal: {
      type: "number",
      customRender: "SelectorTerminal",
      description: "Seleccione una terminal",
    },
    tipo_fecha: {
      type: "number",
      customRender: "SelectorTipoFecha",
      description: "Seleccione un tipo de fecha",
    },
    fecha_desde: {
      type: "string",
      customRender: "DateForm",
      description: "Seleccione una fecha",
    },
    fecha_hasta: {
      type: "string",
      customRender: "DateForm",
      description: "Seleccione una fecha",
    },
  },
};

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import React, { useState } from "react";

export const SwitchDefinitivo = (props) => {
  const [definitivo, setDefinitivo] = useState(false);

  const handleChange = (event) => {
    setDefinitivo(event.target.checked);
    props.setDefinitivo(event.target.checked)
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            {...(props.disabledCheckDefinitivo ? { disabled: true } : {})}
            checked={definitivo}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Definitivo"
      />
    </FormGroup>
  );
};
export const uiSchemaCajasContenidos = {
  type: "Group",
  elements: [
    {
      type: "Control",
      scope: "#/properties/idCaja",
      label: "ID CAJA",
      options: {
        readonly: true,
      },
    },
    {
      type: "Control",
      scope: "#/properties/detalle",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/numero_desde",
        },
        {
          type: "Control",
          scope: "#/properties/numero_hasta",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/fecha_desde",
        },
        {
          type: "Control",
          scope: "#/properties/fecha_hasta",
        },
      ],
    },
  ],
};

export const uiSchemaCajasContenidosVertical = {
  type: "Group",
  elements: [
    {
      type: "Control",
      scope: "#/properties/idCaja",
      label: "ID CAJA",
      options: {
        readonly: true,
      },
    },
    {
      type: "Control",
      scope: "#/properties/detalle",
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/numero_desde",
        },
        {
          type: "Control",
          scope: "#/properties/numero_hasta",
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/fecha_desde",
        },
        {
          type: "Control",
          scope: "#/properties/fecha_hasta",
        },
      ],
    },
  ],
};

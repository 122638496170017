import { types } from "./types";

export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case types.login:
            return {
                ...action.payload,
                isLogged: true,
            };
        case types.logout:
            return {
                ...action.payload,
                isLogged: false,
            };

        default:
            return state;
    }
};

import {
    and,
    rankWith,
    schemaMatches,
    schemaTypeIs,
    uiTypeIs,
  } from "@jsonforms/core";
  import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
  import FormControl from "@mui/material/FormControl";
  import InputLabel from "@mui/material/InputLabel";
  import MenuItem from "@mui/material/MenuItem";
  import Select from "@mui/material/Select";
  import { useEffect, useState } from "react";
  import { getEstadosCajas } from "src/projects/gestion_documental/helpers/EstadosCajasData";
  
  const SelectorEstadoCajas = (props) => {
    const estilosSelect = { fontSize: "1em", color: "#646464" };
  
    const MenuProps = {
      PaperProps: {
        style: {
          //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          //width: 100,
          maxHeight: 250,
        },
      },
    };
    //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
    const jsonForm = useJsonForms();
    const [dataDefaults, setDataDefaults] = useState([]);
    const disabled = jsonForm.core.data.disabled;
    const estado = jsonForm.core.data.estado;
    
    useEffect(() => {
      const estadosDeCajas = async () => {
        const estadosObtenidos = await getEstadosCajas();
        if (estadosObtenidos) {
          setDataDefaults([...estadosObtenidos]);
        }
        if (estado && estadosObtenidos) {
          setEstadoDeCaja(props.data);
        }
      };
      estadosDeCajas();
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const [estadoDeCajaSeleccionado, setEstadoDeCaja] = useState("");
    //funcion para devolver al formulario la data seleccionada y para setearla en el selector
    const handleChange = (event) => {
      setEstadoDeCaja(event.target.value);
      props.handleChange(props.path, event.target.value);
    };
  
    return (
      <div>
        <FormControl
          variant="standard"
          sx={{ width: "100%", paddingBottom: "10px" }}
        >
          <InputLabel>Estado de Cajas</InputLabel>
          <Select
            style={estilosSelect}
            inputProps={{ disabled: disabled }}
            MenuProps={MenuProps}
            value={estadoDeCajaSeleccionado}
            onChange={handleChange}
            label="Estado de Cajas"
          >
            <MenuItem style={estilosSelect} value="">
              Ninguno
            </MenuItem>
            {dataDefaults && dataDefaults.length > 0 ? (
              dataDefaults.map((estadosCajas) => (
                <MenuItem
                  style={estilosSelect}
                  key={estadosCajas.id}
                  value={estadosCajas.id}
                >
                  {estadosCajas.detalle}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={0} value={0} style={estilosSelect}>
                {"No hay estados de cajas disponibles"}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
    );
  };
  
  /*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
  const SelectorEstadoCajasTester = rankWith(
    100,
    and(
      uiTypeIs("Control"),
      schemaTypeIs("number"),
      schemaMatches((schema) => {
        if (schema.hasOwnProperty("customRender")) {
          let cellschema = schema;
          return cellschema["customRender"] === "SelectorEstadoCajas";
        }
        return false;
      })
    )
  );
  
  // se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
  export const SelectorEstadoCajasComponent = {
    renderer: withJsonFormsControlProps(SelectorEstadoCajas),
    tester: SelectorEstadoCajasTester,
  };
  
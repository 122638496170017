import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { getCategoriaCuentas } from "../../../helpers/CategoriaCuentasData";

const SelectorCategoriaCuentas = (props) => {
  const jsonForm = useJsonForms();
  const dataDefaultsAssigned = jsonForm.core.data.selectedCategoriaCuentas;
  const [categorias, setCategorias] = useState([]);
  const [selectedCat, setSelectedCat] = useState(
    dataDefaultsAssigned || []
  );

  useEffect(()=>{
    setSelectedCat(jsonForm.core.data?.selectedCategoriaCuentas)
  },[jsonForm.core.data?.selectedCategoriaCuentas])

  const getCategorias = async () => {
    let aux_storage = JSON.parse(localStorage.getItem("siam-categoria_cuentas"));
    if (aux_storage) { setCategorias(aux_storage.categoria_cuentas) }
    else {
      const response = await getCategoriaCuentas()
      if (response) {
        setCategorias(response)
      }
    }
  }

    useEffect(() => {
      getCategorias()
    }, [])


    const handleChangeCategorias = (selectedOption) => {
      setSelectedCat({
        ...selectedOption
      })
      handleChanges(selectedOption)
    }

    const handleChanges = (selectedOption) => {
      props.handleChange(props.path, selectedOption);
    };

    return (
      <>
        <FormControl
          sx={{ width: "100%", paddingBottom: "10px" }}
        >
          <Autocomplete
            options={categorias && categorias}
            value={(selectedCat?.id && categorias.length > 0) && categorias.filter(x => x.id === selectedCat.id)[0]}
            getOptionLabel={(e) => e.id + " - " + e.nombre}
            getOptionValue={(e) => e.id}
            renderInput={(params) => categorias.length > 0 ? <TextField {...params} label="Categoria" size="medium" /> : <CircularProgress {...params} size={30} />}
            onChange={((e, newValue) => handleChangeCategorias(newValue))}
            fullWidth
            size="medium"
          />

        </FormControl>
      </>
    );
  };

  const SelectorCategoriaCuentasTester = rankWith(
    100,
    and(
      uiTypeIs("Control"),
      schemaTypeIs("object"),
      schemaMatches((schema) => {
        if (schema.hasOwnProperty("customRender")) {
          let cellschema = schema;
          return cellschema["customRender"] === "SelectorCategoriaCuentas";
        }
        return false;
      })
    )
  );

  // hand this over together with the provided `renderers` to the `JsonForms` component
  export const SelectorCategoriaCuentasComponent = {
    renderer: withJsonFormsControlProps(SelectorCategoriaCuentas),
    tester: SelectorCategoriaCuentasTester,
  };
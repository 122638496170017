import CuentasProvider from "src/context/CuentasContext";
import CuentasContent from "../cuentas/components/CuentasContent";


export const CuentasFisicas = () => {
  return (
    <CuentasProvider>
      <CuentasContent />
    </CuentasProvider>
  );
}

export default CuentasFisicas;

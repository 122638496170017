export const uiSchemaArticulosPrecioForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/selectedpropositoCalculoPrecio",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/costo",
        },
        {
          type: "Control",
          scope: "#/properties/impuestoInterno",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/detalle",
    },
  ],
};

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { LinearProgress } from '@mui/material';

export const CajasEstadoDashboard = (data) => {

   const sumaTotal = data.data.reduce((total, objeto) => total + parseFloat(objeto.cant.replace(',', '')), 0);

   const bajas = data.data
      .filter((objeto) => objeto.descripcion === 'Baja')
      .reduce((total, objeto) => total + parseFloat(objeto.cant.replace(',', '')), 0);

   const activos = data.data
      .filter((objeto) => objeto.descripcion !== 'Baja')
      .reduce((total, objeto) => total + parseFloat(objeto.cant.replace(',', '')), 0);

   const porcentajeBajas = (bajas / sumaTotal) * 100;
   const porcentajeActivos = (activos / sumaTotal) * 100;

   return (
      <Grid container spacing={2} style={{ width: "100%", height: '20vh', }}>
         <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', textAlign: 'left' }}>
               Activas / Inactivas
            </Typography>
         </Grid>
         <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4" style={{ textAlign: "left", fontSize: 12, marginTop: 10, padding: 0 }}>
               Cajas Activas: {porcentajeActivos.toFixed(2)}%
            </Typography>
         </Grid>
         <Grid item xs={12} sm={12} md={12}>
            <LinearProgress variant="determinate" value={porcentajeActivos} color="success" />
         </Grid>
         <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4" style={{ textAlign: "left", fontSize: 12, marginTop: 10, padding: 0 }}>
               Cajas Inactivas: {porcentajeBajas.toFixed(2)}%
            </Typography>
         </Grid>
         <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 10 }}>
            <LinearProgress variant="determinate" value={porcentajeBajas} color="warning" />
         </Grid>
      </Grid>
   );
};

// TODO: useEffect no se esta usando, revisar la necesidad de importarlo
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import { getAlicuotasIVA } from "../../projects/articulos/helpers/AlicuotasIVAData";
import { esArray } from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";
import { Autocomplete, TextField } from "@mui/material";


const SelectorAlicuotasIVA = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.selectedAlicuotasIVA;

  const alicuotas_iva = getFromLS("siam-alicuotas_iva").alicuotas_iva;
  const [alicuotaIVASeleccionado, setAlicuotaIVASeleccionado] = useState("");

  let proyecto = JSON.parse(localStorage.getItem("parametros_proyecto")).id === 30 ? false : true;


  useState(() => {
    const alicuotasIVA = async () => {
      if (alicuotas_iva) {
        setDataDefaults(esArray(alicuotas_iva));
      } else {
        const response = await getAlicuotasIVA()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    alicuotasIVA()

  }, []);

  useEffect(() => {
    setAlicuotaIVASeleccionado(dataDefaults.find((item) => item.id === tipo));
    if (proyecto && tipo === undefined) {
      setAlicuotaIVASeleccionado(dataDefaults.find((item) => item.id === '1'))
      console.log(dataDefaults.find((item) => item.id === '1'), 'valdef')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDefaults, tipo]);


  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setAlicuotaIVASeleccionado(newValue);
      props.handleChange(props.path, newValue.id); // Obtén el ID del objeto seleccionado
    } else {
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
          value={alicuotaIVASeleccionado ? alicuotaIVASeleccionado : null}
          onChange={handleChange}
          options={dataDefaults || []}
          getOptionLabel={(option) => option.id + ' - ' + option.nombre || ""}
          renderInput={(params) => (
            <TextField {...params}
              label="Alicuotas IVA disponible"
              disabled={disabled} />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorAlicuotasIVATester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorAlicuotasIVA";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform 
export const SelectorAlicuotasIVAComponent = {
  renderer: withJsonFormsControlProps(SelectorAlicuotasIVA),
  tester: SelectorAlicuotasIVATester,
};
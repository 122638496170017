import { encode } from "base-64";

export const headers = () => {
  const dataCliente = JSON.parse(localStorage.getItem("clientData"));

  const myHeaders = new Headers({
    "Content-Type": "application/json",
    Authorization: encode(
      `${dataCliente.owner_code}:${dataCliente.api_pass}`
    ),
  });

  return myHeaders;
};

import { useState, useEffect } from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import { Grid } from "@mui/material";
import { getCuentasParametros, getCuentasParametrosPaginated, deleteCuentasParametros } from "../helpers/CuentasParametrosData";
import { FormularioDinamicoModelos } from "src/general_components/FormularioDinamicoModelos";
import { getTablaEstructura, getDataSelect } from "src/hooks/structureTable";

export const CuentasParametrosList = (data) => {
    const [createButton, setCreateButton] = useState(false);
    const [dataEdit, setDataEdit] = useState([]);

    const listName = "Cuentas Parametros";
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.3 },  // la propiedad flex permite setear responsivamente el tamaño de la columna 
        { field: 'nombre', headerName: 'Nombre', flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
        { field: 'valor', headerName: 'Valor', flex: 1 }
    ];
    const modelo = "cuentas_parametros"
    const valuesDefaultForm = { id_cuentas: data.params.id_cuentas };

    const [formData, setFormData] = useState({});

    const [options, setOptions] = useState({
        cols: columns,
        get: getCuentasParametros,
        getPaginated: getCuentasParametrosPaginated,
        pageSize: [5, 10, 25],
        edit: true,
        delete: deleteCuentasParametros,
        limit: 50,
        filter: true,
        exportData: true,
        server: true,
        idElementData: data.params.id_cuentas,

        //DATOS PARA EL FORMULARIO
        createButton: createButton,
        setCreateButton: setCreateButton,
        setDataEdit: setDataEdit,
    });

    useEffect(() => {

        const filtrarCampos = async (formDataResponse) => {
            const camposFiltrados = {};
            for (const fieldName in formDataResponse) {
                if (fieldName.includes('id_')) {
                    const palabraExtraida = fieldName.replace('id_', ''); // Cortar "id_"
                    const valorLocalStorage = JSON.parse(localStorage.getItem("siam-" + palabraExtraida))?.[palabraExtraida];

                    if (valorLocalStorage) {
                        // Mapear cada objeto del valorLocalStorage para tener solo "id", "nombre" y manejar casos
                        const opcionesFiltradas = valorLocalStorage.map(({ id, nombre, descripcion, label, detalle }) => ({
                            id,
                            nombre: nombre || descripcion || detalle || label || 'Sin Nombre', // Prioridad: nombre > descripcion > label
                        }));

                        camposFiltrados[fieldName] = {
                            ...formDataResponse[fieldName],
                            options: opcionesFiltradas,
                        };
                    } else {
                        const datosSelect = await getDataSelect(palabraExtraida);

                        // / Mapear cada objeto del valorLocalStorage para tener solo "id", "nombre" y manejar casos
                        const opcionesFiltradas = datosSelect.map(({ id, nombre, descripcion, label, detalle }) => ({
                            id,
                            nombre: nombre || descripcion || detalle || label || 'Sin Nombre', // Prioridad: nombre > descripcion > label
                        }));

                        camposFiltrados[fieldName] = {
                            ...formDataResponse[fieldName],
                            options: opcionesFiltradas,
                        };
                    }
                }
            }

            // Combina formData con camposFiltrados
            let camposFinal = { ...formDataResponse, ...camposFiltrados };

            if (camposFinal.id) {
                delete camposFinal.id;
            }

            // Eliminar propiedades de camposFinal si coinciden con valuesDefaultForm
            Object.keys(valuesDefaultForm).forEach((key) => {
                delete camposFinal[key];
            });
            return camposFinal;
        };
        const getEstructuraTabla = async () => {
            // setIsLoadingData(true);
            let response = await getTablaEstructura(modelo);

            if (response.status === "true") {
                const formDataResponse = response.wsResponse;
                const camposFiltrados = await filtrarCampos(formDataResponse);
                setFormData(camposFiltrados);
            }

        };
        getEstructuraTabla();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
    //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
    //EL BUTTONMETADATOS SOLO ES PARA ESTE CASO, VERIFICAR CADA OCASION.
    if (
        createButton === false &&
        dataEdit.id !== undefined
    ) {
        setDataEdit([]);
    }

    return (
        <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
            <>
                <ListTablePaginated options={options} />
                <FormularioDinamicoModelos
                    data={dataEdit}
                    createButton={createButton}
                    listName={listName}
                    setCreateButton={setCreateButton}
                    setOptions={setOptions}
                    options={options}
                    formData={formData}
                    modelo={modelo}
                    dataDefault={valuesDefaultForm}
                />
            </>
        </Grid>
    );
};


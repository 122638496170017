import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { esArray } from "src/general_components/functionEsArray";
import { Autocomplete, TextField } from "@mui/material";
import { getFromLS } from "src/general_helpers/getFromLS";
import { getComprobantesCodigoData } from "src/projects/gestion_documental/helpers/ComprobantesCogidoData";

const SelectorComprobantesCodigo = (props) => {

  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const valueComprobantesCodigo = jsonForm.core.data.comprobante_codigo;
  const editar = jsonForm.core.data.editar;

  const comprobantes_codigos = getFromLS("siam-comprobantes_codigos").comprobantes_codigos;
  const [selectedComprobantesCodigo, setSelectedComprobantesCodigo] =
    useState("");

  useState(() => {
    const getComprobantesCodigo = async () => {
      if (comprobantes_codigos) {
        setDataDefaults(esArray(comprobantes_codigos));
      } else {
        const response = await getComprobantesCodigoData()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    getComprobantesCodigo()

  }, []);

  useEffect(() => {
    setSelectedComprobantesCodigo(dataDefaults.find((item) => item.id === valueComprobantesCodigo));
  }, [dataDefaults, valueComprobantesCodigo]);


  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setSelectedComprobantesCodigo(newValue);
      props.handleChange(props.path, newValue.id); // Obtén el ID del objeto seleccionado
    } else {
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
          value={selectedComprobantesCodigo ? selectedComprobantesCodigo : null}
          onChange={handleChange}
          options={dataDefaults || []}
          disabled={editar? true : false}
          getOptionLabel={(option) => option.id + ' - ' + option.alias + " " + option.descripcion || ""}
          renderInput={(params) => (
            <TextField {...params}
              label="Codigo de Comprobante"
              disabled={editar || disabled? true : false}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
const SelectorComprobantesCodigoTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorComprobantesCodigo";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorComprobantesCodigoComponent = {
  renderer: withJsonFormsControlProps(SelectorComprobantesCodigo),
  tester: SelectorComprobantesCodigoTester,
};

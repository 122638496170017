export const schemaTipoContenidosForm = {
  type: "object",
  properties: {
    selectedTipoContenidos: {
      title: "Tipo Contenidos",
      type: "object",
      customRender: "SelectorTipoContenidos",
    },
  },
  required: ["selectedTipoContenidos"],
};

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function SelectorBasico(props) {
  const { label, listItems, data, disabled, setData } = props;

  return (
    <div>
      <FormControl sx={{ width: "100%", paddingBottom: "10px" }}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={data}
          onChange={(e) => {
            setData(e.target.value);
          }}
          label={label}
          disabled={disabled}
        >
          {listItems && listItems.length > 0 ? (
            listItems.map((item, itemKey) => (
              <MenuItem
                key={itemKey}
                value={item}
                style={{ display: "flex", textAlign: "left" }}
              >
                {item.detalle}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={0} value={0} style={{ display: "flex" }}>
              {"No hay " + label + " disponibles"}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}

import Box from '@mui/material/Box';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { DataGridPro } from "@mui/x-data-grid-pro";
import PersonIcon from '@mui/icons-material/Person';

export const MovimientosCajasDashboard = (cajasData) => {

  const columns = [
    {
      field: "avatar",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <Grid style={{ alignContent: 'center', alignItems: 'center' }}>
          <PersonIcon fontSize="small" style={{ color: "#3c4b64" }} />
        </Grid>
      ),
    },
    {
      field: "usuario_nombre_apellido",
      headerName: "Usuario",
      renderCell: (params) => (
        <Box style={{ textAlign: "left", margin: '2px', padding: 0 }}>
          <Typography variant="h4" style={{ fontWeight: "bold", fontSize: 14, margin: 0, padding: 0 }}>
            {params.row.usuario_nombre}
          </Typography>
          <Typography variant="caption" style={{ fontSize: 10, margin: 0, padding: 0 }}>
            {params.row.usuario_apellido}
          </Typography>
        </Box>
      ),
    },

    { field: "fecha", headerName: "Fecha", flex: 1.2 },
    { field: "id", headerName: "Caja", flex: 1},
    { field: "estado_caja", headerName: "Estado Caja", flex: 1 },
    { field: "estado_ubicacion", headerName: "Estado Ubicacion", flex: 1.5 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    { field: "id_ubicacion", headerName: "Ubicacion", flex: 1 },
  ];

  function esObjeto(variable) {
    return variable !== null && typeof variable === 'object' && !Array.isArray(variable);
  }

  const dataConId = cajasData.cajas.map((obj, index) => ({
    ...obj,
    fecha: esObjeto(obj.fecha) ? "" : obj.fecha,
    estado_caja: esObjeto(obj.estado_caja) ? "" : obj.estado_caja,
    estado_ubicacion: esObjeto(obj.fecha) ? "" : obj.estado_ubicacion,
    usuario_nombre: esObjeto(obj.usuario_nombre) ? "" : obj.usuario_nombre,
    usuario_apellido: esObjeto(obj.usuario_apellido) ? "" : obj.usuario_apellido,
    id_ubicacion: esObjeto(obj.id_ubicacion) ? "" : obj.id_ubicacion,



  }));


  return (
    <Grid container display={'block'} spacing={2} marginTop={2} justifyContent={'flex-start'} >
      <Grid item style={{ height: 550, width: "100%", padding: 10 }}>
        <DataGridPro

          density="compact"
          rows={dataConId}
          columns={columns}
          pagination={false}
          showToolbar={false}
          hideFooterRowCount
          disableHorizontalScrolling={true}
          hideFooter={true}
          getRowHeight={() => 'auto'}
        />
      </Grid>
    </Grid>

  );
};

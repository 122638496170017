import TextField from "@mui/material/TextField";
import { useContext } from "react";
import { CajasContext } from "../../../context/CajasContext/CajasContext";

export const CajasFormUbicacionTemporal = (data = null) => {
  const {
    valueDetalleUbicacionTemporal,
    setValueUbicacionTemporal,
  } = useContext(CajasContext);

  // TODO: Revisar el console log de la variable valueDetalleUbicacionTemporal
  // Si la variable no se usa en el console log, no se usa en ningun lado.
  console.log("valorDetalleUT", valueDetalleUbicacionTemporal);

  const handleChange = (newValue) => {
    setValueUbicacionTemporal(newValue.target.value);
  };

  return (
    <div>
      <>
        <TextField
          id="detalleUbicacion"
          label="Detalle de Ubicacion Temporal"
          multiline
          rows={4}
          //value={valueDetalleUbicacionTemporal}
          style={{ width: "100%" }}
          inputProps={{ maxLength: 75 }}
          onChange={handleChange}
        />
      </>
    </div>
  );
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getTipoUbicaciones } from "../../projects/gestion_documental/helpers/UbicacionesTipos";
import { esArray } from "src/general_components/functionEsArray";

const SelectorTipoUbicaciones = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.id_tipo_ubicaciones;

  useState(() => {
    const tiposDeCajas = async () => {

      let tipoUbicaciones = esArray(JSON.parse(localStorage.getItem("siam-tipo_ubicaciones"))?.tipo_ubicaciones);

      if (!tipoUbicaciones.length) {
        tipoUbicaciones = await getTipoUbicaciones();        
      }

      setDataDefaults(tipoUbicaciones);
    };
    tiposDeCajas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    setTipoDeUbicacion(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);


  const [tipoDeUbicacionSeleccionado, setTipoDeUbicacion] = useState("");
  //funcion para devolver al formulario la data seleccionada y para setearla en el selector
  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setTipoDeUbicacion(newValue);
      props.handleChange(props.path, newValue.id);
    }else{
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" ,marginRight:'120px'}}
      >
        <Autocomplete
        value={tipoDeUbicacionSeleccionado ? tipoDeUbicacionSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.detalle || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Tipo de Ubicaciones"
          disabled={disabled} />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorTipoUbicacionesTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorTipoUbicaciones";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorTipoUbicacionesComponent = {
  renderer: withJsonFormsControlProps(SelectorTipoUbicaciones),
  tester: SelectorTipoUbicacionesTester,
};

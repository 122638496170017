import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";


export const getCuentasLocalizaciones = async (idCuenta) => {
    const cuentas_localizaciones = new datos("cuentas_localizaciones");
    cuentas_localizaciones.addCondition("cuentas_localizaciones", "id", 0, ">");
    cuentas_localizaciones.addCondition("cuentas_localizaciones", "id_cuentas", parseInt(idCuenta), "=")
    const response = await cuentas_localizaciones.get();
    return esArray(response.wsResponse.cuentas_localizaciones);
};

export const getCuentasLocalizacionesPaginated = async (limit, offset, idCuenta) => {
    const cuentas_localizaciones = new datos("cuentas_localizaciones");
    cuentas_localizaciones.addCondition("cuentas_localizaciones", "id", 0, ">");
    cuentas_localizaciones.addCondition("cuentas_localizaciones", "id_cuentas", parseInt(idCuenta), "=")
    cuentas_localizaciones.innerJoinTables('localidad', 'cuentas_localizaciones', "id", "id_localidad", "=");
    cuentas_localizaciones.innerJoinTables('cuentas_localizaciones_tipo_contenidos', 'cuentas_localizaciones', "id_cuentas_localizaciones", "id", "=");
    cuentas_localizaciones.innerJoinTables('tipo_contenidos', 'cuentas_localizaciones_tipo_contenidos', "id", "id_tipo_contenidos", "=");
    cuentas_localizaciones.addLimitCondition(limit, offset);
    const response = await cuentas_localizaciones.get();
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

    // Añadir las propiedades genero y estado_civil a cada objeto del array
    const datosConPropiedades = esArray(response.wsResponse.cuentas_localizaciones).map(item => ({
        ...item,
        localidad: item.relations.localidad.nombre,
    }));

    let result = {};
    if (!response.wsResponse.empty) {
        result = {
            data: esArray(datosConPropiedades),
            total_records: response.wsResponse.total_records,
        };
        return result;
    }
};

export const deleteCuentasLocalizaciones = async (id) => {
    let status = false;
    await Swal.fire({
        title: "¿Está seguro de que desea eliminar este registro?",
        text: "Si sigue adelante, no se podrán revertir los cambios.",
        icon: "warning",
        showCancelButton: true,
        customClass: {
            container: 'my-swal-delete'
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, quiero eliminarlo",
        cancelButtonText: "No, no estoy seguro",
    }).then(async (result) => {
        if (result.isConfirmed) {
            const cuentas_localizaciones = new datos("cuentas_localizaciones");
            let data = {
                id: id,
            };
            const response = await cuentas_localizaciones.delete(data);

            if (response.status === "true") {
                status = true;
                await Swal.fire({
                    customClass: {
                        container: 'my-swal-delete'
                    },
                    title: "¡Eliminado!",
                    text: "El registro ha sido eliminado exitosamente",
                    icon: "success"
                });
            } else {
                await Swal.fire({
                    customClass: {
                        container: 'my-swal-delete'
                    },
                    icon: "error",
                    title: "¡Oops!",
                    text: response.message,
                });
            }
        }
    });
    return status;
};
import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";

const estados_ubicaciones = new datos("estados_ubicaciones");
export const getEstadoUbicaciones = async () => {
    estados_ubicaciones.addCondition("estados_ubicaciones", "id", 0, ">");
  const response = await estados_ubicaciones.get();
  let arrResponse = esArray(response.wsResponse?.estados_ubicaciones)
  return arrResponse;
};


import React from 'react';
import { Grid } from '@mui/material';
import { DataGridPro } from "@mui/x-data-grid-pro";
import { v4 as uuidv4 } from 'uuid';
import Tooltip from '@mui/material/Tooltip';

export const MovimientoDocDashboard = (documentosData) => {
  const columns = [
    { field: "fecha", headerName: "Fecha", flex: 0.3 },
    { field: "id_contenido", headerName: "Contenido", flex: 0.2 },
    { field: "estado_contenido", headerName: "Estado Contenido", flex: 0.6 },
    {
      field: "tipo_movimiento", headerName: "Tipo Movimiento", flex: 0.6,
      renderCell: (params) => (
        <Tooltip title={params.row.detalle}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
  ];
  const dataConId = documentosData.documentos.map((obj, index) => ({
    ...obj,
    id: uuidv4(), detalle: typeof obj.detalle === 'object' && Object.keys(obj.detalle).length === 0
      ? ''  // Asigna un string vacío si 'detalle' es un objeto vacío
      : obj.detalle,
  }));

  return (
    <Grid container display={'block'} spacing={2} marginTop={2} justifyContent={'flex-start'} >
      <Grid item style={{ height: 550, width: "100%", padding: 10 }}>
        <DataGridPro
          density="compact"
          rows={dataConId}
          getRowId={(row) => row.id}
          columns={columns}
          pagination={false}
          showToolbar={false}
          hideFooterRowCount
          hideFooter={true}
        />
      </Grid>
    </Grid>

  );
}
import React, { useContext, useEffect, useState } from "react";
import { ArticulosContext } from "src/context/ArticulosContext";
import { Box, Grid, Container } from "@mui/material";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import { schemaArticulosPrecioForm } from "src/forms/schemaArticulosPrecioForm";
import { uiSchemaArticulosPrecioForm } from "src/forms/uiSchemaArticulosPrecioForm";
import { JsonForms } from "@jsonforms/react";
import { SelectorPropositoCalculoPrecioComponent } from "src/forms/customRenders/selectorPropositoCalculoPrecio";
import { Buttons } from "src/general_components/Buttons";
/* Formulario contenedor de los datos de las CUENTAS JURIDICAS */
const localize = require("ajv-i18n");
export const ArticulosPrecioForm = (props) => {
  const {
    articulosPrecio,
    articulosPrecioFormData,
    setArticulosPrecioFormData,
    setArticulosFormData,
    setMessage,
    setSeverity,
    setSnackbarVisible,
    saveData,
  } = useContext(ArticulosContext);

  const {
    toggleModal
  } = props;

  //Actualiza estado articulosPrecioFormData cuando articulosPrecio cambia
  useEffect(() => {
    let idArticuloPrecio = articulosPrecio.id;
    //Si es un objeto establece valores predeterminados al formulario
    if (typeof idArticuloPrecio === "object") {
      setArticulosPrecioFormData({
        costo: "",
        detalle: "",
        id_proposito_calculo_precios: "",
        impuesto_interno: "",
      });
    } else {
      setArticulosPrecioFormData({ ...articulosPrecio });
    }
    //Actualiza articulosFormData con los datos de artículos pasados por props
    if (props.data) {
      setArticulosFormData({
        id: props.data.id,
        nombre: props.data.nombre,
        nombre_reducido: props.data.nombre_reducido,
        alias: props.data.alias,
        selectedCategoria: props.data.id_categorias,
        selectedArticulosTipo: props.data.id_articulos_tipo,
        selectedTipoMoneda: props.data.id_tipo_moneda,
        selectedAlicuotasIVA: props.data.id_alicuotas_iva,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articulosPrecio]);

  //FIXME VER QUE HACER CON EL WITHERRORS
  // eslint-disable-next-line no-unused-vars
  const [withErrors, setWithErrors] = useState(false);
  //Se utiliza para manejar los cambios del el formulario y detectar errores jsonform
  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setArticulosPrecioFormData(data);

    return { errors, data };
  };

  //Se llama cuando se hace clic en guardar
  const handleSubmit = () => {
    //Esta función verifica si se han completado todos los campos obligatorios del formulario
    if (
      !articulosPrecioFormData.costo &&
      !articulosPrecioFormData.detalle &&
      !articulosPrecioFormData.selectedpropositoCalculoPrecio
    ) {
      setMessage("Debe completar todos los campos");
      setSeverity("error");
      setSnackbarVisible(true);
    } else if (!articulosPrecioFormData.costo) {
      setMessage("Debe ingresar el costo");
      setSeverity("error");
      setSnackbarVisible(true);
    } else if (!articulosPrecioFormData.detalle) {
      setMessage("Debe ingresar el detalle");
      setSeverity("error");
      setSnackbarVisible(true);
    } else if (!articulosPrecioFormData.selectedpropositoCalculoPrecio) {
      setMessage("Debe seleccionar un propósito de cálculo de precio");
      setSeverity("error");
      setSnackbarVisible(true);
    } else {
      saveData();
    }
  };

  const toggleModalForm = () => {
    toggleModal();
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid item xs={12}>
          <Box
            sx={{
              mx: "auto",
              width: "100%",
              p: 1,
              m: 1,
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            <JsonForms
              schema={schemaArticulosPrecioForm}
              uischema={uiSchemaArticulosPrecioForm}
              data={articulosPrecioFormData}
              renderers={[
                ...materialRenderers,
                SelectorPropositoCalculoPrecioComponent,
              ]}
              cells={materialCells}
              onChange={({ errors, data }) =>
                onChange && onChange(errors, data)
              }
            />
          </Box>
        </Grid>
        <Grid sx={{ mt: 1, marginTop: 5 }}>
          <Buttons
            disableButton={withErrors ? true : false}
            onClickSubmit={handleSubmit}
            labelButtonSave={"Confirmar"}
            buttonCerrar={toggleModalForm}
          />
        </Grid>
      </Container>
    </>
  );
};

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { DataGridPro } from "@mui/x-data-grid-pro";

export const ClienteCajasDashboard = (clienteCajasData) => {
    const columns = [
        {
            field: "descripcion", headerName: " Cliente", flex: 3,
            valueGetter: (params) => {
                return `${params.row.id} - ${params.row.descripcion}`;
            }
        },
        {
            field: "cant", headerName: "Cajas", flex: 1,
            valueGetter: (params) => {
                return parseInt(params.row.cant, 10);
            },
        }
    ];

    return (
        <Grid container>
            <Grid item style={{ width: "100%", height: '40vh', }}>
                <Typography variant="h4"
                    sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', textAlign: 'left', paddingBottom: 2 }}>
                    Clientes
                </Typography>
                <DataGridPro
                    autoPageSize
                    density="compact"
                    sx={{
                        width: '100%',
                        height: '35vh'
                    }}
                    rows={clienteCajasData.clienteCaja}
                    columns={columns}
                    pagination={false}
                    showToolbar={false}
                    hideFooterRowCount
                    disableHorizontalScrolling={true}
                    hideFooter={true}
                    hideFooterPagination
                />
            </Grid>
        </Grid>
    );
};

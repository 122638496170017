import datos from "src/services/datos";
import Swal from "sweetalert2";
import "src/services/styles/sweetAlert.css"

const estados = new datos("estados_ubicaciones");

export const getEstadosUbicaciones = async (auth) => {
  estados.addCondition("estados_ubicaciones", "id", 0, ">");
  const response = await estados.get();
  return response.wsResponse.estados_ubicaciones;
};

export const getEstadosUbicacionesPaginated = async (limit, offset) => {
  estados.addCondition("estados_ubicaciones", "id", 0, ">");
  estados.addLimitCondition(limit, offset);
  const response = await estados.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: [...response.wsResponse.estados_ubicaciones],
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const postEstadosUbicaciones = async (estado) => {
  const response = estados.insert(estado);
  return response;
};

export const deleteEstadosUbicaciones = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este estado de Ubicaciones?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
    customClass: {
      container: 'my-swal-delete'
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos("estados_ubicaciones");
      let data = {
        id: id,
      };
      const response = await userData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El estado de Ubicaciones ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};

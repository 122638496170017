/**/
import Grid from '@mui/material/Grid';
import { ArticulosList } from "./ArticulosList";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";


export function ArticulosContent() {
  let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));
  const titulo_config = parametros_proyecto?.titulo_articulos;
  const titulo_proceso = titulo_config ? titulo_config : "Articulos/Servicios";
  return (
    <>
      <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
        <Grid
          style={{
            background: "white",
            display: 'flex',
            justifyContent: 'space-between', // Para alinear a los extremos
            alignItems: 'center', // Para alinear verticalmente
            backgroundColor: 'white',
            fontFamily: 'Roboto, sans-serif',
            borderRadius: 10,
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            marginBottom: 20,
            height: 50
          }}
        >
          <Typography 
            align="left"
            variant="h2"
            component="div"
            sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
          >
            {titulo_proceso}
          </Typography>
          <CustomBreadcrumbs></CustomBreadcrumbs>
        </Grid>
        <ArticulosList />
      </Grid>
    </>
  )
}

export default ArticulosContent;
export const schemaDigitalizacionAgregar = {
  type: "object",
  properties: {
    grupo: {
      type: "string",
      title: "Grupo",
      description: "Ingresar Grupo",
    },
    contrato: {
      type: "string",
      title: "Contrato",
      description: "Ingresar Contrato",
    },
    titular: {
      type: "string",
      title: "Titular",
      description: "Ingresar Titular",
    },
    dni: {
      type: "number",
      title: "DNI",
      description: "Ingresar DNI",
    },
    fechaCompra: {
      type: "string",
      title: "Fecha Compra",
      customRender: "DateForm",
      description: "Seleccione Fecha de Compra",
    },
  },
  required: ["grupo", "contrato", "titular", "dni", "fechaCompra"],
};

import React, { useContext, useEffect, useState } from "react";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import SearchIcon from "@mui/icons-material/Search";
import { SearchFormGeneral } from "./SearchFormGeneral";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { getContenidosSearchPaginated } from "../helpers/ContenidosData";
import StyleIcon from "@mui/icons-material/Style";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { imprimirEtiquetas } from "../helpers/EtiquetasData";
import { SnackbarMessenger } from "../../../general_components/SnackbarMessenger";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import Divider from "@mui/material/Divider";
import HistoryIcon from "@mui/icons-material/History";
import { getContenidosSearchHistorico } from "../helpers/HistoricoCajasData";
import { MovimientosTimelineMUI } from "./MovimientosTimelineMUI";
import esArray from "src/general_components/functionEsArray";
import { TheLayoutContext } from "src/context/TheLayoutContext";
import { SwitchAjuste } from "./SwitchAjuste";
import AddIcon from "@mui/icons-material/Add";
import { FormUbicaciones } from "./FormUbicaciones";
import { CajasFormProcesosVarios } from "./CajasFormProcesosVarios";
import Popover from "@mui/material/Popover";
import Snackbar from "@mui/material/Snackbar";
import { MenuItem } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { postCajasProcesosVarios } from "../helpers/UbicacionCajasData";
import { estadoColores, estadoIconos, iconosMarcadoCajasList, colorMarcadoCajas } from "./estadosCajas";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AWS from 'aws-sdk';
import { configS3 } from "src/configs/config";
import { FormCajasProcesosVarios } from "./FormCajasProcesosVarios";
// import ArchiveIcon from "@mui/icons-material/Archive";
import { TipoContenidosSelectProcesosVarios } from "./TipoContenidosSelectProcesosVarios";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ConfimDialogComponent } from "src/general_components/ConfirmDialogComponent";
import { postCajas } from "../helpers/CajasData";
import { ModalImpresionEtiquetasComprobantes } from "./ModalImpresionEtiquetasComprobantes";
import { comprobantesPrintData } from "../helpers/ComprobantesData";
import { Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// INFORME SOBRE CAJAS
export const CajasProcesoList = (datos) => {

  const {

    valorUbicacionTemporal,
    //  valorRetiroCaja,
    // valorDevolucionCaja,
    procesoSeleccionado,
    //ubicacion_caja,
    tituloProceso,
    changeProceso,
    //valorMudanza,
    restricciones
  } = datos.datos;


  const {
    limpiarSearchForm, dataSearchFormGeneral, snackbarVisible, message, severity, setOmitirQuerySearch,
    setListRecords, setSnackbarVisible, setResponseSearchFormGeneral, setSoloCajas, actualizarListado, setActualizarListado,
    selectionModel, setSelectionModel, datosFormUbicaciones, setDatosFormUbicaciones, setMessage, setSeverity, modalState,
    setModalState, cajasUbicadas, responseSearchFormGeneral,
    tipoRetiroMovimientoSeleccionado, valueDetalleUbicacionTemporal, desmarcar, setDataSearchFormGeneral, setBusquedaSearchOn,
    setIdsCajasCreadas,
    setDisabledButtonsCopy, idsCajasCreadas
  } = useContext(CajasContext);

  const { setChildFooter, setExpand, s3Json, subProcesoLocal, clienteIdLocal, subProceso, setS3Json, valueSubproceso, setValueSubproceso, setProcesandoMarcas } = useContext(TheLayoutContext)

  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const [pushData, setPushData] = useState(false);
  const [modalStateHistorico, setMOdalStateHistorico] = useState(false);
  const [modalStateProcesar, setMOdalStateProcesar] = useState(false);
  const [modalStateCajasForm, setModalStateCajasForm] = useState(false);
  const [historicoCaja, setHistoricoCaja] = useState(false);
  const [descripcionCuentaSectorFormCajas, setDescripcionCuentaSectorFormCajas] = useState("");
  const [sectorIdFormCajas, setSectorIdFormCajas] = useState("");
  const [mostrarUbicacionesDisponibles, setMostrarUbicacionesDisponibles] = useState(false);
  let mensajeSnackbar = "";
  let baja = false;
  let definitivo = false;
  const [idCuenta, setIdCuenta] = useState(false);
  const [modalStateContenidosForm, setModalStateContenidosForm] = useState(false);
  const [modalStateExcedida, setModalStateExcedida] = useState(false);
  const [modalStateImpresionEtiquetasComprobantes, setModalStateImpresionEtiquetasComprobantes] = useState(false);
  const [idCaja, setIdCaja] = useState(false);
  const [mensajeExcedida, setMensajeExcedida] = useState("");
  const [cajaExcedida, setCajaExcedida] = useState(false);
  const [responseProcesar, setResponseProcesar] = useState(false);
  const [clienteSectorFormCajas, setClienteSectorFormCajas] = useState(false);
  const [dataCajasFormEdit, setDataCajasFormEdit] = useState(false);
  const [nameFormCajas, setNameFormCajas] = useState("");
  const mensajeImpresionEtiquetasComprobantes = `El proceso ${tituloProceso} se ha ejecutado correctamente`;
  const [cajaDeformada, setCajaDeformada] = useState(false);
  const [modalStateDeformada, setModalStateDeformada] = useState(false);
  const [mensajeDeformada, setMensajeDeformada] = useState("");
  const [conJerarquia, setConJerarquia] = useState(false);
  const [params, setParams] = useState(false);

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let Imprimir = true;
  let Historico = true;
  let Editar = true;
  let MarcarExcedida = true;
  let MarcarDeformada = true;
  let AgregarCajas = true;
  let AgregarCajasGeneral = true;
  let abmContenidos = true;
  if (restricciones) {
    Imprimir = restricciones.Imprimir;
    Historico = restricciones.Historico;
    Editar = restricciones.Editar;
    MarcarExcedida = restricciones.MarcarExcedida;
    MarcarDeformada = restricciones.MarcarDeformada;
    AgregarCajas = restricciones.AgregarCajas;
    AgregarCajasGeneral = restricciones.AgregarCajasGeneral;
    abmContenidos = restricciones.abmContenidos
  }
  //Se asignan valores a localstorage para utilizarlos en renderIconBasedOnId ya que no los toma si no se hace de esta forma
  localStorage.setItem("siam-procesoSeleccionado", JSON.stringify(procesoSeleccionado));
  localStorage.setItem("siam-subProcesoSeleccionado", JSON.stringify(subProceso));

  const renderIconBasedOnId = (idrow) => {
    // Obtener el ID del usuario actual desde el almacenamiento local
    const userId = JSON.parse(localStorage.getItem("siam-user-data")).id;

    // Obtener los datos del almacenamiento local y parsearlos si existen
    const localStorageData = localStorage.getItem("siam-datos3");
    let data = localStorageData ? JSON.parse(localStorageData) : undefined;

    // Objeto para almacenar los IDs por proceso
    const idsPorProceso = {};

    // Verificar si existen datos en el almacenamiento local
    if (data) {
      // Iterar sobre los procesos y usuarios en los datos
      for (const proceso in data) {
        for (const usuario in data[proceso]) {
          // Verificar si el usuario actual es el usuario deseado (userId)
          if (usuario === userId) {
            // Inicializar el array de IDs para el proceso actual si aún no existe
            if (!idsPorProceso[proceso]) {
              idsPorProceso[proceso] = [];
            }

            // Iterar sobre los subprocesos y clientes en los datos
            for (const subProcesos in data[proceso][usuario]) {
              for (const cliente in data[proceso][usuario][subProcesos]) {
                // Obtener los IDs y agregarlos al array de IDs para el proceso actual
                const ids = data[proceso][usuario][subProcesos][cliente].id;
                if (ids && Array.isArray(ids)) {
                  idsPorProceso[proceso] = [...idsPorProceso[proceso], ...(ids[0] || [])];
                }
              }
            }
          }
        }
      }

      // Mapeo de procesos a colores
      const procesoColorMapping = {
        devolucion_caja: 'devolucion_caja',
        devolucion_cajas: 'devolucion_cajas',
        retiro_cajas: 'retiro_cajas',
        retiro_contenidos: 'retiro_contenidos',
        mudanza_cajas: 'mudanza_cajas',
        ubicacion_cajas: 'ubicacion_cajas',
        devolucion_contenidos: 'devolucion_contenidos',
        ubicacion_temporal: 'ubicacion_temporal',
        retiro_definitivo: 'retiro_definitivo',
        baja_expurgue: 'baja_expurgue',
        retiro_contenido_parcial: 'retiro_contenido_parcial',
        retiro_contenido_total: 'retiro_contenido_total',
        quita_total_contenidos: 'quita_total_contenidos',
        devolucion_contenidos_parcial: 'devolucion_contenidos_parcial',
        devolucion_contenidos_total: 'devolucion_contenidos_total',
      };

      // Obtener el color seleccionado
      let selectedColor = obtenerColoresPorProceso(idsPorProceso, idrow, procesoColorMapping);
      return selectedColor;
    }

    // Retornar null si no hay datos en el almacenamiento local
    return null;
  };

  const convertirTexto = (texto) => {
    // Dividir el texto en palabras usando el guion bajo como delimitador
    const palabras = texto.split('_');
    // Convertir la primera letra de cada palabra a mayúscula
    const palabrasMayusculas = palabras.map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1));
    // Unir las palabras con espacios
    const textoConvertido = palabrasMayusculas.join(' ');
    return textoConvertido;
  }

  const obtenerColoresPorProceso = (idsPorProceso, idrow, procesoColorMapping) => {
    const colores = [];
    for (const proceso in idsPorProceso) {
      if (idsPorProceso.hasOwnProperty(proceso)) {
        const procesoIds = idsPorProceso[proceso];
        if (procesoIds && procesoIds.some(ids => ids && ids.includes && ids.includes(idrow.toString()))) {
          const selectedColor = procesoColorMapping[proceso];
          const title = convertirTexto(proceso);
          colores.push({ title, color: selectedColor, proceso: proceso });
        }
      }
    }
    return colores;
  }


  let isMobileDevice = regexp.test(details);

  const listName = false;
  let proceso = "InformeGeneralCajas";
  const columns = [
    {
      sortable: false,
      field: "clienteName",
      headerName: "Cliente",
      headerAlign: "left",
      flex: 1
    },
    {
      field: "sectorName",
      headerName: "Sector",
      headerAlign: "left",
      flex: 1
    },
    {
      field: "concatCaja",
      headerName: "Caja",
      headerAlign: "left",
      flex: 0.6,
      renderCell: (params) => {
        const procesosYColores = renderIconBasedOnId(params.id); // Icono predeterminado si el estado no tiene un icono definido
        if (params.value) {
          return (
            <Grid container alignItems="center">
              {params.row.excedida === "1" && (
                <Tooltip title="Excedida">
                  <span>
                    <WarningAmberIcon size="small" style={{ color: "red", fontSize: 20, }} />
                  </span>
                </Tooltip>
              )}
              {params.row.deformada === "1" && (
                <Tooltip title="Deformada">
                  <span>
                    <BrokenImageIcon size="small" style={{ color: "red", fontSize: 20, }} />
                  </span>
                </Tooltip>
              )}
              {procesosYColores && procesosYColores.map((item, index) => {
                const color = colorMarcadoCajas[item.proceso] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
                const Icono = iconosMarcadoCajasList[item.proceso] || null; // Obtiene el icono dinámicamente
                return (
                  <Tooltip key={index} title={`Marcada en ${item.title}`}>
                    <span>
                      {Icono && (
                        <Icono size="small" style={{ color: color, fontSize: 20 }} />
                      )}
                    </span>
                  </Tooltip>
                );
              })}
              <Typography variant="body1" style={{ fontSize: 13, fontFamily: 'Roboto, sans-serif', marginLeft: 5, color: 'black' }}>
                {params.value}
              </Typography>
            </Grid>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "ubicacion",
      headerName: "Ubicacion",
      headerAlign: "left",
      flex: 0.6
    },
    {
      field: "tipo_cajas",
      headerName: "Tipo de Caja",
      headerAlign: "left",
      flex: 0.5,
    },
    {
      field: "estados_cajas",
      headerName: "Estado",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (params) => {
        if (params.value) {
          const color = estadoColores[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
          const icono = estadoIconos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
          return (
            <Grid style={{ alignItems: 'center', textAlign: 'center', margin: 'auto' }}>

              <Tooltip title={params.value}>
                <IconButton style={{ color: color }}>
                  {icono}
                </IconButton>

              </Tooltip>
            </Grid>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      flex: 0.5,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Acciones">
              <span>
                <MoreVertIcon color="info" />
              </span>
            </Tooltip>
          }
          hidden= {Imprimir || Historico || Editar || MarcarExcedida || MarcarDeformada || AgregarCajas? false : true}
          onClick={(e) => {
            handleOpenMenu(e);
            setParams(params)
          }}
          label="Acciones"
        />,
      ],
    },
  ];

  /* Se arma la columna "Acciones que va a tener editar y borrar " */
  const actionColumn =  {
    field: "actions",
    headerName: "Acciones",
    type: "actions",
    flex: 2,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Tooltip title="Acciones">
            <span>
              <MoreVertIcon color="info" />
            </span>
          </Tooltip>
        }
        onClick={(e) => {
          handleOpenMenu(e);
          setParams(params)
        }}
        label="Acciones"
      />
    ],
  };

  const toggleModalHistorico = async (id) => {
    const response = await getContenidosSearchHistorico(id, "caja")
    if (response) {
      setHistoricoCaja(esArray(response.wsResponse.movimientos?.historico));
      toggleModalMovimientos();
    }
  };

  const toggleModalMovimientos = async () => {
    setMOdalStateHistorico(!modalStateHistorico);
  };


  //Funcion que abre y cierra el modal del formulario de contenidos
  const toggleModalContenidosForm = async () => {
    if (!modalStateContenidosForm) {
      setActualizarListado(false);
    }
    setModalStateContenidosForm(!modalStateContenidosForm);

  };

  //Funcion que abre y cierra el modal del formulario de cajas
  const toggleModalCajasForm = async (data, id) => {
    setIdsCajasCreadas('')
    setDisabledButtonsCopy(false)
    if (!modalStateCajasForm) {
      setActualizarListado(false);
    } else {
      // setActualizarListado(true);
      const cajas = data?.wsResponse?.cajas || idsCajasCreadas ? idsCajasCreadas : []
      let registrosFormateados = esArray(cajas).map((data) => {
        return { id: data };
      });
      if (registrosFormateados.length > 0) {
        recargarPaginaProcesados(registrosFormateados, id);
      }
      setClienteSectorFormCajas(false);
      setSectorIdFormCajas("");
      setDataCajasFormEdit(false);
      setDescripcionCuentaSectorFormCajas("")
      setNameFormCajas("");

    }
    setModalStateCajasForm(!modalStateCajasForm);

  };

  //Funcion que abre y cierra el modal del formulario de contenidos
  const toggleModalExcedida = async () => {
    if (!modalStateExcedida) {
      setActualizarListado(false);
    }
    setModalStateExcedida(!modalStateExcedida);

  };

  const toggleModalDeformada = async () => {
    if (!modalStateDeformada) {
      setActualizarListado(false);
    }
    setModalStateDeformada(!modalStateDeformada);

  };

  //Funcion que abre y cierra el modal del formulario de contenidos
  const toggleModalImpresionEtiquetaComprobante = async () => {
    if (!modalStateImpresionEtiquetasComprobantes) {
      setActualizarListado(false);
    } else {
      setActualizarListado(true);
      const cajas = responseProcesar?.wsResponse?.id_cajas || []
      let registrosFormateados = esArray(cajas).map((data) => {
        return { id: data };
      });
      recargarPaginaProcesados(registrosFormateados);
    }
    setModalStateImpresionEtiquetasComprobantes(!modalStateImpresionEtiquetasComprobantes);

  };

  //Funcion para el boton imprimir
  const onClickEtiqueta = (params) => {
    const URI_aux = imprimirEtiquetas(params.id);
    if (URI_aux) window.open(URI_aux, "_blank");
  };

  useEffect(() => {

    if (changeProceso) {
      limpiarSearchForm();
      setOptions({
        ...options,
        // abmContenidos:  procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? true : "false",
        selectCheckbox: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : true,
        valuesSelected: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : setSelectionModel,
        sinFilas: true,
      });
      setOptions2({
        ...options2,
        // abmContenidos:  procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? true : "false",
        selectCheckbox: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : true,
        valuesSelected: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : setSelectionModel,
        sinFilas: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procesoSeleccionado]);

  const buttonBuscar = (params) => (
    <Button
      endIcon={<SearchIcon />}
      onClick={() => {
        if (params?.procesoSeleccionado?.id !== 1) {
          if (params?.subProceso && params?.subProceso !== 0) {
            setAbrirBusqueda(!abrirBusqueda);
            setOmitirQuerySearch(true);
            setSoloCajas(true);
          } else {
            setMessage("Debe seleccionar un subproceso para poder continuar");
            setSeverity("warning");
            setSnackbarVisible(true);
          }
        } else {
          setAbrirBusqueda(!abrirBusqueda);
          setOmitirQuerySearch(true);
          setSoloCajas(true);
        }

      }}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
      }}
    >
      Buscar
    </Button>
  );

  const buttonAgregarCajas = (params) => (
    <Tooltip title="Agregar Cajas">
      <Button
        startIcon={<AddIcon />}
        onClick={() => {
          setNameFormCajas("Alta de Cajas")
          setClienteSectorFormCajas(true);
          toggleModalCajasForm(params.id);
        }}
        color="success"
        variant="contained"
        style={{
          color: '#FFFFFF',
          fontFamily: 'Roboto, sans-serif',
          minWidth: '120px',
          width: '120px',
          fontSize: '11px',
          height: '30px',
        }}
      >
        Cajas
      </Button>
    </Tooltip>

  );

  const [options, setOptions] = useState({
    cols: columns,
    getPaginated: getContenidosSearchPaginated,
    setListRecords: setListRecords,
    pageSize: [5, 10, 25],
    listName,
    limit: 50,
    filter: true,
    exportData: true,
    server: false,
    sinFilas: true,
    actionColumnCustom: actionColumn,
    idElementData: dataSearchFormGeneral,
    hierarchyOn: true,
    detailsOn: true,
    abmContenidos: abmContenidos &&  procesoSeleccionado?.id === 1? true : "false",
    checkboxContenidos: "false",
    selectCheckbox: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : true,
    valuesSelected: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : setSelectionModel,
    procesoSeleccionado: procesoSeleccionado,
    setResponseService: setResponseSearchFormGeneral,
    soloContenidosSearch: true,
    exportarCajasContenidos: true,
    notas: true,
    totales: true,
    buttonExtraOn: true,
    ButtonExtra: buttonBuscar,
    ButtonExtraMarcar: true,
    ButtonExtraDesmarcar: true,
    buttonExtraViewOn: true,
    ButtonExtraView: buttonAgregarCajas,
    marcadoYdesmarcado: true,
    ButtonExtraMarcarTodo: true
  });

  const [options2, setOptions2] = useState({
    cols: columns,
    getPaginated: getContenidosSearchPaginated,
    setListRecords: setListRecords,
    pageSize: [5, 10, 25],
    listName,
    limit: 50,
    filter: true,
    exportData: true,
    server: false,
    sinFilas: true,
    actionColumnCustom: actionColumn,
    hierarchyOn: false,
    detailsOn: true,
    abmContenidos: abmContenidos &&  procesoSeleccionado?.id === 1? true : "false",
    checkboxContenidos: "false",
    selectCheckbox: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : true,
    valuesSelected: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : setSelectionModel,
    procesoSeleccionado: procesoSeleccionado,
    setResponseService: setResponseSearchFormGeneral,
    soloContenidosSearch: true,
    exportarCajasContenidos: true,
    notas: true,
    totales: true,
    buttonExtraOn: true,
    ButtonExtra: buttonBuscar,
    ButtonExtraMarcar: true,
    ButtonExtraDesmarcar: true,
    buttonExtraViewOn: AgregarCajasGeneral? true : false,
    ButtonExtraView:  buttonAgregarCajas,
    marcadoYdesmarcado: true,
    ButtonExtraMarcarTodo: true
  });

  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };


  useEffect(() => {
    if (!Array.isArray(dataSearchFormGeneral) && actualizarListado) {
      setConJerarquia(dataSearchFormGeneral.jerarquia);
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: false,
      });
      setOptions2({
        ...options2,
        idElementData: dataSearchFormGeneral,
        sinFilas: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSearchFormGeneral, actualizarListado]);



  if (pushData) {
    if (abrirBusqueda) {
      //CIERRO EL DIALOGO DE BUSQUEDA
      setAbrirBusqueda(!abrirBusqueda);
      //SETEO EL ESTADO PARA QUE SE ACTUALICE EL LISTADO
      setActualizarListado(true);
    }
    setPushData(!pushData);
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setAbrirBusqueda(!abrirBusqueda);
        setActualizarListado(true);
        setOmitirQuerySearch(true);
        setSoloCajas(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);


  // ----------------------------- Footer Actions ----------------------------------------------------------------------------
  const [modalStateUbicaciones, setModalStateUbicaciones] = useState(false);
  const [ajuste, setAjuste] = useState(false);
  const setearAjuste = async (data) => {
    setAjuste(data);
  };


  const toggleModalUbicaciones = async () => {
    setModalStateUbicaciones(!modalStateUbicaciones);

  };

  const toggleModalProcesar = async () => {
    setMOdalStateProcesar(!modalStateProcesar);
    if (!modalStateProcesar) {
      let arrayCajas = responseSearchFormGeneral.data ? responseSearchFormGeneral.data : [];

      let objetoEncontrado = null;

      // Iterar sobre los IDs buscados hasta encontrar un objeto
      selectionModel.some(id => {
        const objeto = arrayCajas.find(obj => obj.id === id);
        if (objeto) {
          objetoEncontrado = objeto;
          return true; // Detener la iteración si se encuentra un objeto
        }
        return false; // Continuar la iteración si no se encuentra un objeto
      });
      let idCuenta;
      if (objetoEncontrado) {
        // Hacer algo con el objeto encontrado
        idCuenta = objetoEncontrado.id_cuentas;
        setIdCuenta(idCuenta);
      } else {
        console.log('Ningún objeto encontrado');
      }
    }

  };

  const procesar = async () => {
    if (selectionModel.length === 0) {
      return;
    }

  };

  useEffect(() => {
    if (selectionModel?.length > 0 && procesoSeleccionado?.id !== 1) {
      setExpand(true);
    }
    if (procesoSeleccionado?.id !== 1) {
      setChildFooter(
        <>
          <Grid container spacing={0} alignItems="center" style={{ display: 'flex' }}>

            <Grid item xs={!isMobileDevice ? 6 : 6} justifyContent="flex-start" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Grid container>
                <Grid item>
                  {procesoSeleccionado?.id !== 6 && procesoSeleccionado?.id !== 7 && (
                    <SwitchAjuste
                      setAjuste={setearAjuste}
                      disabledCheckAjuste={selectionModel?.length > 0 ? false : true}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>
              <Button
                // disabled={selectionModel?.length > 0 ? false : true}
                onClick={toggleModalProcesar
                }
                endIcon={<AddIcon />}
                variant="contained"
                color="success"
                style={{
                  bottom: "7px",
                  marginTop: '10px'
                }}
              >
                Procesa
              </Button>
            </Grid>
            {isMobileDevice ? (
              <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}></Grid>
            ) : null}
          </Grid>
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionModel, ajuste]);

  // ------------------------------------------------------------------------------------------------------------------------------


  const handleDesmarcar = () => {
    // Lógica para desmarcar las cajas si el switch está activo
    if (desmarcar) {
      desmarcarChecks()
    }
  };

  const obtenerProceso = () => {
    let proceso = '';

    if (procesoSeleccionado.label !== 'Consulta General') {
      const palabra = procesoSeleccionado.label.split(/[ /]/);
      // Usamos una expresión regular para dividir por espacio o barra
      const filtro = palabra.filter(word => word.toLowerCase() !== 'de');
      proceso = filtro.join('_').toLowerCase();
    } else {
      let url = window.location.href;
      const arrUrl = url.split("/");
      proceso = arrUrl[arrUrl.length - 1];
      if (proceso.includes("?")) {

        const arrUrl = url.split("?");
        proceso = arrUrl[arrUrl.length - 1];

        const arrUrls = proceso.split("=");
        proceso = arrUrls[arrUrls.length - 2];

      } else {
        proceso = proceso === 'proceso' ? 'ubicacion_cajas' : proceso
      }
    }
    return proceso
  }

  const desmarcarChecks = async () => {
    let marcasAWS = { procesos: s3Json }

    let procesoActual = obtenerProceso();
    let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;


    if (procesoActual === 'devolucion') {
      //   tipo_id = 'id_contenidos'
      procesoActual = 'devolucion_contenidos'
    } else if (procesoActual === 'retiro') {
      //  tipo_id = 'id_contenidos'
      procesoActual = 'retiro_contenidos'
    }

    if (!marcasAWS) {
      marcasAWS = {}
    }

    const cantidades = Object.keys(marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal] ?? {}).reduce((total, key) => {
      const nestedObject = marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal]?.[key];

      if (nestedObject && typeof nestedObject === 'object') {
        total += Object.keys(nestedObject).length;
      }

      return total;
    }, 0);

    if (cantidades > 1) {
      delete marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal]?.[clienteIdLocal.id_cuentas];
    } else {
      delete marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal];
    }

    const valueSubprocesoFormated = { ...valueSubproceso };
    // Buscar la posición del tercer guion
    const tercerGuionIndex = valueSubprocesoFormated.label.indexOf('-', valueSubprocesoFormated.label.indexOf('-', valueSubprocesoFormated.label.indexOf('-') + 1) + 1);

    // Verificar si se encontró el tercer guion y eliminarlo junto con lo que le sigue
    if (tercerGuionIndex !== -1) {
      valueSubprocesoFormated.label = valueSubprocesoFormated.label.substring(0, tercerGuionIndex);

      setValueSubproceso(valueSubprocesoFormated);
    }


    AWS.config.update(configS3);
    const s3 = new AWS.S3();

    const jsonString = JSON.stringify(marcasAWS, null, 2);
    const file = new Blob([jsonString], { type: 'application/json' });

    const bucketName = configS3.bucketName;
    const ownerCode = JSON.parse(localStorage.getItem("clientData")).owner_code.toLowerCase();
    const key = ownerCode + '-marks.json';

    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
      ContentType: 'application/json',
    };

    try {
      await s3.upload(params).promise();

      localStorage.setItem("siam-datos3", JSON.stringify(marcasAWS.procesos));
      setS3Json(marcasAWS.procesos)
      setProcesandoMarcas(true)
    } catch (error) {
      console.log("¡Error el proceso de Desmarcado no fue realizado con éxito!");
    }


  };


  //Funcion encargada de recargar la pagina con los ids procesados
  const recargarPaginaProcesados = (registrosFiltrados, id) => {
    if (!id || id === "") {
      const arrayIndexado = registrosFiltrados.map(item => item.id);
      const cadenaFormateada = arrayIndexado.join(',');
      const data = {
        coincidencias: '1',
        eliminados: '0',
        search_params: {
          cajas: {
            fisica: '1',
            ...(cadenaFormateada !== "" && { idCajasRango: cadenaFormateada }),
            ...(cadenaFormateada !== "" && { id_cajas: cadenaFormateada })
          }
        },
        soloCajas: '1'
      };
      setDataSearchFormGeneral(data);
    }
    setBusquedaSearchOn(true);
    setActualizarListado(true)
  }

  // ---------------- PRocesar --------------------------------------------------
  // Consume el servicio de postCajasUbicaciones y devuelve los mensajes que retorna el servicio.
  const procesarService = async (detalleConcat, solicitado, detalle, contactoSelect, direccionSelect, nodoExtra) => {
    if (selectionModel.length === 0) {
      return;
    }


    // Valido tipo retiro seleccionado para mandarlo al servicio
    if (tipoRetiroMovimientoSeleccionado === '2') {
      datos.datos.ret_preferencial = '1';
    } else if (tipoRetiroMovimientoSeleccionado === '3') {
      datos.datos.ret_urgente = '1';
    }

    let registrosFormateados = selectionModel.map((data) => {
      return { id: data };
    });

    // Consumo servicio
    const resultado = await postCajasProcesosVarios(
      datosFormUbicaciones,
      registrosFormateados,
      datos,
      cajasUbicadas,
      valueDetalleUbicacionTemporal,
      ajuste,
      baja,
      definitivo,
      setMOdalStateProcesar,
      detalleConcat,
      contactoSelect,
      direccionSelect,
      nodoExtra
    );
    // Valido respuesta del servicio
    if (resultado.status === "true") {
      if (valorUbicacionTemporal === "1") {
        setModalState(!modalState);
      }
      handleDesmarcar()
      setMostrarUbicacionesDisponibles(false);
      limpiar();
      setResponseProcesar(resultado);
      toggleModalImpresionEtiquetaComprobante();
      toggleModalProcesar();
    } else {
      if (valorUbicacionTemporal === "1") {
        setModalState(!modalState);
      }
    }
  };

  // Parametros Popover
  const [open, setOpen] = useState(null); // variable popover
  // eslint-disable-next-line no-unused-vars
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const state = {
    vertical: "bottom",
    horizontal: "center",
  };

  const { vertical, horizontal } = state;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // Se obtienen los id de los registros que se vayan seleccionando en la grilla
  // const registrosSeleccionados = selectionModel.map((data) => {
  //   return { id: data };
  // });
  // eslint-disable-next-line no-unused-vars
  const registrosSeleccionados = Array.isArray(selectionModel)
    ? selectionModel.map((data) => ({ id: data }))
    : [{ id: selectionModel }];

  const limpiar = async () => {
    setModalStateUbicaciones(false);
    setMostrarUbicacionesDisponibles(false);
    setDatosFormUbicaciones({ idDep: "", disabled: false });
    setChildFooter(null);
    setExpand(false);
    setSelectionModel([]);
    setOptions({ ...options, sinFilas: true });
    setOptions2({ ...options2, sinFilas: true });
    setSelectionModel([]);
    setAjuste(false);
  };

  const handleConfirmExcedida = async () => {
    const data = [{ id: idCaja, excedida: cajaExcedida ? "0" : "1" }];
    const responseServicio = await postCajas(data);

    if (responseServicio.status === "false") {
      setMessage("No se pudo realizar la modificacion del campo excedida!");
      setSeverity("error");
      setSnackbarVisible(true);
    } else {
      const mensajeSuccess = cajaExcedida ? "La caja cambio a no excedida" : "La caja cambio a excedida";
      setMessage(mensajeSuccess);
      setSeverity("success");
      setSnackbarVisible(true);
      setActualizarListado(true);
      toggleModalExcedida();
    }
  };

  const handleConfirmDeformada = async () => {
    const data = [{ id: idCaja, deformada: cajaDeformada ? "0" : "1" }];
    const responseServicio = await postCajas(data);

    if (responseServicio.status === "false") {
      setMessage("No se pudo realizar la modificacion del campo deformada!");
      setSeverity("error");
      setSnackbarVisible(true);
    } else {
      const mensajeSuccess = cajaExcedida ? "La caja cambio a no deformada" : "La caja cambio a deformada";
      setMessage(mensajeSuccess);
      setSeverity("success");
      setSnackbarVisible(true);
      setActualizarListado(true);
      toggleModalDeformada();
    }
  };

  const handleConfirmImpresionEtiquetas = async () => {
    const cajas = responseProcesar?.wsResponse?.id_cajas || []
    let registrosFormateados = esArray(cajas).map((data) => {
      return { id: data };
    });

    let idCajasParaEtiquetas = registrosFormateados
      .map((registro) => registro.id)
      .join(",");
    let URI_aux = imprimirEtiquetas(idCajasParaEtiquetas);
    if (URI_aux) window.open(URI_aux, "_blank");

  };

  const handleConfirmImpresionComprobantes = async () => {
    const id_comprobante = responseProcesar?.wsResponse?.id_comprobantes || false;

    if (id_comprobante) {
      try {
        // Obtén el contenido HTML
        const contenidoChild = await obtenerComprobanteAimprimir(id_comprobante);
        // Abre una nueva pestaña con el contenido HTML
        const nuevaVentana = window.open();
        nuevaVentana.document.write(contenidoChild);
      } catch (error) {
        console.error("Error al obtener el contenido HTML:", error);
        // Maneja el error según sea necesario
      }
    }
  };

  const obtenerComprobanteAimprimir = async (idComprobante) => {
    let paramsPrint = {
      id_comprobantes: idComprobante
    }
    let response = await comprobantesPrintData(paramsPrint)
    return response;
  };

  const handleEtiquetaButton = () => {
    onClickEtiqueta(params);
    handleCloseMenu()
  };

  const handleHistoricoButton = () => {
    toggleModalHistorico(params.id);
    handleCloseMenu()
  };

  const handleAgregarCajasButton = () => {
    const clienteSectorConcat = params.row.cliente + " - " + params.row.sector;
    setNameFormCajas("Alta de Cajas");
    setSectorIdFormCajas(params.row.id_sector);
    setDescripcionCuentaSectorFormCajas(clienteSectorConcat)
    toggleModalCajasForm(params.id);
    handleCloseMenu()
  };

  const handleEditarButton = () => {
    const clienteSectorConcat = params.row.cliente + " - " + params.row.sector;
    setClienteSectorFormCajas(true);
    setNameFormCajas("Modificacion de Caja")
    setDescripcionCuentaSectorFormCajas(clienteSectorConcat)
    setDataCajasFormEdit(params.row);
    toggleModalCajasForm(params.row.id_cuentas_localizaciones, params.row);
    handleCloseMenu()
  };

  const handleExcedidaButton = () => {
    setIdCaja(params.id);
    const mensaje = params.row.excedida === "1"
      ? `Se marcará la caja con ID ${params.id} como NO excedida, ¿Desea continuar?`
      : `Se marcará la caja con ID ${params.id} como excedida, ¿Desea continuar?`;
    setMensajeExcedida(mensaje);
    setCajaExcedida(params.row.excedida === "1" ? true : false);
    toggleModalExcedida();
    handleCloseMenu()
  };

  const handleDeformadaButton = () => {
    setIdCaja(params.id);
    const mensaje = params.row.deformada === "1"
      ? `Se marcará la caja con ID ${params.id} como NO deformada, ¿Desea continuar?`
      : `Se marcará la caja con ID ${params.id} como deformada, ¿Desea continuar?`;
    setMensajeDeformada(mensaje);
    setCajaDeformada(params.row.deformada === "1" ? true : false);
    toggleModalDeformada();
    handleCloseMenu()
  };

  return (
    <>
      {abrirBusqueda && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirBusqueda}
          modalName={"Buscador- Proceso: " + tituloProceso}
          child={
            <SearchFormGeneral procesoSeleccionado={procesoSeleccionado} toggleModal={toggleModal} proceso={proceso} />
          }
          setPushData={setPushData}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}

      {modalStateHistorico && (
        <SimpleDialogComponent
          open={modalStateHistorico}
          modalName={"Historico"}
          child={<MovimientosTimelineMUI data={historicoCaja} />}
          setPushData={toggleModalMovimientos}
          handleClose={toggleModalMovimientos}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}
      {modalStateUbicaciones && (
        <SimpleDialogComponent
          open={modalStateUbicaciones}
          modalName={"Campos de ubicaciones"}
          child={<FormUbicaciones buttonEnviar={true} setPushData={procesar} toggleModal={toggleModalUbicaciones} data={datosFormUbicaciones} setData={setDatosFormUbicaciones} valorUbicacionTemporal={false} />}
          setPushData={procesar}
          handleClose={toggleModalUbicaciones}
          buttonEnviar={false}
          buttonCancelar={false}
        />
      )}

      {modalStateCajasForm && (
        <SimpleDialogComponent
          open={modalStateCajasForm}
          modalName={nameFormCajas}
          child={<FormCajasProcesosVarios sectorIdFormCajas={sectorIdFormCajas} clienteSectorFormCajas={clienteSectorFormCajas}
            descripcionCuentaSectorFormCajas={descripcionCuentaSectorFormCajas} toggleModal={toggleModalCajasForm} dataCajasFormEdit={dataCajasFormEdit} />}
          setPushData={toggleModalCajasForm}
          handleClose={toggleModalCajasForm}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}

      {modalStateContenidosForm && (
        <SimpleDialogComponent
          open={modalStateContenidosForm}
          modalName={"Alta de Contenidos"}
          child={<TipoContenidosSelectProcesosVarios modalStateContList={modalStateContenidosForm}
            setModalStateContList={setModalStateContenidosForm}
            cajaId={idCaja}
            setActualizarListadoContenidos={setActualizarListado}
            digitalizado={false}
            sectorId={sectorIdFormCajas} />}
          setPushData={toggleModalContenidosForm}
          handleClose={toggleModalContenidosForm}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}

      {modalStateProcesar && (
        <SimpleDialogComponent
          open={modalStateProcesar}
          modalName={"Procesar - Proceso: " + tituloProceso}
          child={<CajasFormProcesosVarios idCuenta={idCuenta} ajuste={ajuste} toggleModal={toggleModalProcesar} proceso={procesoSeleccionado} procesar={procesarService} registrosSeleccionados={selectionModel} />}
          setPushData={procesarService}
          handleClose={toggleModalProcesar}
          buttonEnviar={false}
          buttonCancelar={false}
        />
      )}
      {modalStateExcedida && (
        <ConfimDialogComponent
          open={modalStateExcedida}
          modalName={"Marcar como excedida"}
          mensaje={mensajeExcedida}
          handleConfirm={handleConfirmExcedida}
          handleClose={toggleModalExcedida}
        />
      )}

      {modalStateDeformada && (
        <ConfimDialogComponent
          open={modalStateDeformada}
          modalName={"Marcar como deformada"}
          mensaje={mensajeDeformada}
          handleConfirm={handleConfirmDeformada}
          handleClose={toggleModalDeformada}
        />
      )}
      {modalStateImpresionEtiquetasComprobantes && (
        <ModalImpresionEtiquetasComprobantes
          open={modalStateImpresionEtiquetasComprobantes}
          modalName={"Etiquetas y Comprobante"}
          mensaje={mensajeImpresionEtiquetasComprobantes}
          handleConfirmImpresionEtiquetas={handleConfirmImpresionEtiquetas}
          handleConfirmImpresionComprobantes={handleConfirmImpresionComprobantes}
          response_procesar={responseProcesar}
          handleClose={toggleModalImpresionEtiquetaComprobante}
        />
      )}
      <Divider />
      <Grid>
        {conJerarquia && (<ListTablePaginated options={options} />)}
        {!conJerarquia && (<ListTablePaginated options={options2} />)}

      </Grid>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {Imprimir && (
          <MenuItem onClick={handleEtiquetaButton}>
            <StyleIcon color="info" sx={{ mr: 2 }} />
            Imprimir
          </MenuItem>
        )}
        {Historico && (
          <MenuItem
            onClick={handleHistoricoButton}
            {...(params?.id?.includes("cliente") || params?.id?.includes("sector")
              ? { hidden: true }
              : {})}>
            <HistoryIcon color="info" sx={{ mr: 2 }} />
            Historico
          </MenuItem>
        )}
        {AgregarCajas && (
          <MenuItem
            hidden={params?.id?.includes("sector") && params?.row?.proceso_seleccionado?.id === 1 ? false : true}
            onClick={handleAgregarCajasButton}>
            <AddIcon color="info" sx={{ mr: 2 }} />
            Agregar Cajas
          </MenuItem>
        )}
        {Editar && (
          <MenuItem
            onClick={handleEditarButton}
            {...(params?.id?.includes("cliente") || params?.id?.includes("sector")
              ? { hidden: true }
              : {})}>
            <EditIcon color="info" sx={{ mr: 2 }} />
            Editar
          </MenuItem>
        )}
        {MarcarExcedida && (
          <MenuItem
            onClick={handleExcedidaButton}
            {...(params?.id?.includes("cliente") || params?.id?.includes("sector")
              ? { hidden: true }
              : {})}>
            <WarningAmberIcon color="info" sx={{ mr: 2 }} />
            {params?.row?.excedida === "1" ? "Marcar como no excedida" : "Marcar como excedida"}
          </MenuItem>
        )}
        {MarcarDeformada && (
          <MenuItem
            onClick={handleDeformadaButton}
            {...(params?.id?.includes("cliente") || params?.id?.includes("sector")
              ? { hidden: true }
              : {})}>
            <BrokenImageIcon color="info" sx={{ mr: 2 }} />
            {params?.row?.deformada === "1" ? "Marcar como no deformada" : "Marcar como deformada"}
          </MenuItem>
        )}


      </Popover>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={mostrarUbicacionesDisponibles}
        key={vertical + horizontal}
        ContentProps={{
          sx: {
            background: "green",
            display: "block",
            textAlign: "center",
          },
        }}
        sx={isMobileDevice ? { width: '70%', marginLeft: '12%' } : undefined}
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          {mensajeSnackbar}
        </Alert>
      </Snackbar>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </>
  );
};

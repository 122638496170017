import {
  AddCircle,
  DeleteForever,
  ListAltOutlined,
  Edit,
} from "@mui/icons-material";
import { Button, Container, Grid, Tooltip } from "@mui/material";
import { GridActionsCellItem, esES } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import { ArticulosContext } from "src/context/ArticulosContext";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { ArticulosForm } from "./ArticulosForm";
import { ArticulosInfoAdicionalForm } from "./ArticulosInfoAdicionalForm";
import { ArticulosPrecioForm } from "./ArticulosPrecioForm"
import Typography from "@mui/material/Typography";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { CustomNoRowsOverlay } from "src/general_components/Overlay";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,

} from "@mui/x-data-grid";
import PrintIcon from '@mui/icons-material/Print';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import { v4 as uuidv4 } from 'uuid';
import { id_proyecto } from "src/configs/config";
import { esArray } from "src/general_components/functionEsArray";
import { getArticulosPrinted } from "src/projects/articulos/helpers/ArticulosData"
import { obtenerProcesosPorPerfilYMenu } from "src/general_components/functionEsArray";

/**
 **      cols: define las columnas que se van a mostrar | required 
 *       get: funcion con las que se obtienen los datos para mostrar (get()), 
 *       getPaginated: get pero con paginacion, | required
 *       pageSize: tamaño de la pagina/quizas deprecado | required aunque por defecto usa 10
 *       listName: nombre a mostrarse en la lista | required
 *       redirectURLForm: formulario donde redirecciona la lista (en caso de usarse)// con modales, deprecado,
 *       redirectURLList: lista donde retorna o muestra luego del la creacion/edicion de los datos, deprecado
 *       create: funcion que muestra el modal|boton (se usa el mismo formulario) para agregar|editar datos,
 *       delete: funcion para eliminar datos, false por defecto
 *       edit: por defecto, false. permite editar los datos de la columna en el form de create. combinado en false con delete, no muestra la colmuan de acciones   
 *       limit: limite para la paginacion,
 *       server: false por defecto | indica si la paginacion se hace desde el servidor usando el limit. Ademas, es flag de paginacion. FUNDAMENTAL PARA PAGINACION SERVERSIDE  
 *       filter: false por defecto | muestra los filtros del datagrid para los listados
 *       exportData: false por defecto | muestra el boton export del datagrid para los listados
 *       density: false por defecto | muestra la densidad del datagrid
 *       columnsTool: false por defecto | muestra la tool para las columnas del datagrid
 *       idElementData: esta variable sirve para mostrar los datos de un elemento seleccionado (ver ejemplo MetadatosList.js)
 *       sinFilas: false por defecto | muestra un mensaje cuando no hay datos (sin filas)
 *       actionColumnCustom: permite agregar una columna de acciones personalizada, se debe redefinir las ya existentes pero se pueden agregar mas
 * 
 *      DATOS PARA USAR EL FORMULARIO, las tres propiedades son opcionales y se llaman desde el list 
 *      No es necesario definir ninguno de los 3 si es que no se llega a tener que usar el formulario (tener cuidado con el edit)
 *       createButton: createButton,  // boton para crear un nuevo registro
 *       setCreateButton: setCreateButton, // funcion para setear el boton de crear un nuevo registro
 *       setDataEdit: setEstadosCajasSeleccionado,  // funcion para setear los datos del registro a editar
 * 
 * 
 * Example:
 **       const options = {
        cols: columns,
        get: getGeneros,
        getPaginated: getGenerosPaginated,
        pageSize: 5,
        listName: "Generos",
        redirectURLForm: "/generales/generos-form",
        redirectURLList: "/generales/generos-list",
        create: GenerosForm,
        edit: true,
        delete: deleteGenero,
        limit: 10,
        filter: true,
        exportData: true,
        server: true

        createButton: createButton,
        setCreateButton: setCreateButton,
        setDataEdit: setEstadosCajasSeleccionado,
    }
 */

export const ListTablePaginated = (data) => {
  const {
    setParamsRow,
    setModEdit,
    saveData,
    articulo,
    setArticulo,
    setModelSave,
    refresh,
    setRefresh,
    openModal,
    setOpenModal,
    deleteData,
  } = useContext(ArticulosContext);

  console.log(data.options)
  //Variables para el formulario ya sea edicion o creacion
  const {
    setDataEdit,
    createButton,
    setCreateButton,
    idElementData,
    sinFilas,
    buttonExtraOn,
    ButtonExtra,
    printButton
  } = data.options;

  let buttomCreate = createButton;
  if (createButton === undefined) {
    buttomCreate = true;
  }
  const [records, setRecords] = useState([]); // filas de la lista
  const [columns, setColumns] = useState(data.options.cols); // columnas que se muestran

  const server = data.options.server ? "server" : "client";
  let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));
  const titulo_config = parametros_proyecto?.titulo_articulos;
  const titulo_modificacion = titulo_config ? "Modificacion de " + titulo_config : "Modificacion de Articulos";
  const info_header = titulo_config ? "Info de " + titulo_config : "Info de Articulos";
  const restricciones = obtenerProcesosPorPerfilYMenu();
  let baja = true;
  let modificacion = true;
  let precio = true;
  let especificaciones= true;
  let imprimir= true;
  if (restricciones) {
    baja = restricciones.baja;
    modificacion = restricciones.modificacion;
    precio = restricciones.precio;
    especificaciones= restricciones.especificaciones;
    imprimir= restricciones.imprimir;
  }


  /* los siguientes estados son para controlar la paginacion */

  const [page, setPage] = useState(0); // PAGINA INICIAL
  const [limit, setLimit] = useState(data.options.limit || 10); // LIMITE DEL FETCH. POR DEFECTO ES 10
  const [offset, setOffset] = useState(0); // OFFSET DEL FETCH. POR DEFECTO ES 0
  const [loading, setLoading] = useState(false); // ESTADO DE LA TABLA
  const [totalRowCount, setTotalRowCount] = useState(0); // FILAS TOTALES QUE TRAE EL FECTCH SIN PAGINACION EL
  // const [refresh, setRefresh] = useState(false); // REFRESCAR LA TABLA
  const [selectedForm, setSelectedForm] = useState(false);
  const [modalName, setModalName] = useState(false);
  const viewCustomToolbar = data.options.viewCustomToolbar || true;
  // eslint-disable-next-line no-unused-vars
  const [hiddenButtonAgregar, setHiddenButtonAgregar] = useState(
    data.options.hiddenButtonAgregar || false
  ); // Se agrega este parametro para desabilitar el boton agregar

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [buttonEnviar, setButtonEnviar] = useState(false);

  const [filteredData, setFilteredData] = useState([]);

  //calcular la cantidad de paginas totales
  const totalPages = parseInt(totalRowCount / limit);

  const toggleModal = () => {
    setOpenModal(!openModal);
    if (openModal) {
      setModelSave("");
      setArticulo("0");
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setModelSave("");
    setArticulo("0");
  };

  const editarArticulo = (item) => {
    let articulo_aux = { ...articulo };
    articulo_aux.id_articulo = item.row.id;
    setArticulo(articulo_aux);
  };

  /* se actualiza el limite de la tabla en funcion de si se trae un limite para haver el fetch */
  useEffect(() => {
    setLimit(data.options.limit || 10);
  }, [data]);


  function buscarPorIdProyecto(id) {
    return esArray(parametros_proyecto)?.find(objeto => objeto.id === id);
  }

  const containsUnicode = (str) => {
    const unicodeChars = /[\u0080-\uFFFF]/;
    return unicodeChars.test(str);
  };

  const cleanUnicode = (str) => {
    const unicodeChars = /[\u0080-\uFFFF]/g;
    return str.replace(unicodeChars, '');
  };
  const handlePrintClick = async () => {

    let datos = []
    console.log(filteredData, 'filteredData.length')
    if (filteredData.length > 0 && filteredData['items'][0].value) {

      datos = await getArticulosPrinted('', '', filteredData)
    } else {
      console.log(records, 'l;')


      datos = records.map(item => {
        if (item.detalle && containsUnicode(item.detalle)) {
          return {
            ...item,
            detalle: cleanUnicode(item.detalle)
          };
        }
        return item;
      });



    }

    let columnsWithoutInfo = columns.filter(column => column.field !== 'Info de Articulos');
    columnsWithoutInfo.push({
      "field": "detalle",
      "headerName": "Especificación"
    });


    const proyecto_encontrado = buscarPorIdProyecto(id_proyecto);

    const logoUrl = proyecto_encontrado?.url_imagen;
    const doc = new jsPDF();
    doc.addImage(logoUrl, 'PNG', 10, 10, 22, 18);
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text("Listado de Propiedades", 50, 30);
    doc.setFont('helvetica', 'normal');
    const startY = 40;
    const spaceBetween = 5;
    const tableStartY = startY + spaceBetween;


    doc.autoTable({
      head: [columnsWithoutInfo.map(column => ({ content: column.headerName, styles: { fillColor: [255, 0, 0] } }))],
      body: datos.map(row => columnsWithoutInfo.map(column => row[column.field])),
      startY: tableStartY,
      styles: {
        fontSize: 8,
      },
    });
    doc.save('ListaPropiedades.pdf');
  };

  const verArticulosColumn = {
    field: "Info de Articulos",
    headerName: info_header,
    type: "actions",
    flex: 0.7,
    getActions: (params) => {
      const actions = [
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar">
              <span>
                <Edit color="info" />
              </span>
            </Tooltip>
          }
          hidden={!modificacion? true : false}
          onClick={() => {
            setModEdit(true);
            setModelSave("articulosUpdate");
            editarArticulo(params);
            setParamsRow(params.row);
            setSelectedForm(<ArticulosForm data={params.row} toggleModal={handleClose} />);
            setButtonEnviar(false);
            setModalName(titulo_modificacion);
            toggleModal();
          }}
          label="MenuBook"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Especificaciones">
              <span>
                <ListAltOutlined color="info" />
              </span>
            </Tooltip>
          }
          hidden={!especificaciones? true : false}
          onClick={() => {
            setModelSave("articulos_especificacion");
            editarArticulo(params);
            setSelectedForm(<ArticulosInfoAdicionalForm data={params.row} toggleModal={handleClose} />);
            setModalName("Especificacion");
            setButtonEnviar(true);
            toggleModal();
          }}
          label="Parametros"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Eliminar">
              <span>
                <DeleteForever color="warning" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            deleteData(params.row);
          }}
          hidden={!baja? true : false}
          label="Parametros"
        />,
      ];

      // Verificar si el id del proyecto es 29 para agregar la acción de precio
      if (parametros_proyecto?.id === "27") {
        actions.splice(1, 0,
          <GridActionsCellItem
            icon={
              <Tooltip title="Precio">
                <span>
                  <AttachMoneyIcon color="info" />
                </span>
              </Tooltip>
            }
            hidden={!precio? true : false}
            onClick={() => {
              setModelSave("articulos_calculo_precios");
              editarArticulo(params);
              setSelectedForm(<ArticulosPrecioForm data={params.row} toggleModal={handleClose} />);
              setButtonEnviar(true);
              setModalName("Asignar Precio");
              toggleModal();
            }}
            label="Precio"
          />
        );
      }

      return actions;
    },
  };



  /* funcion que trae los records a mostrar en la tabla. Se ejecuta cada vez que se cambia la pagina o se 
    modifica la cantidad de rows a mostart */

  useEffect(() => {
    setRecords([]);
    const getData = async () => {
      setLoading(true);

      //llama a la consulta para traer los datos a mostrar
      let response = undefined;

      if (!sinFilas) {
        if (idElementData !== undefined) {
          response = await data.options.getPaginated(
            limit,
            offset,
            idElementData
          ); // se trae los records mediante un id de algun tipo de elemento que sea necesario
        } else {
          response = await data.options.getPaginated(limit, offset); //probelma esta cuando el offfsett (limit*page supera la cantidad de registros)
        }
      }

      if (response) {
        setRecords(response.data); // EL RESPONSE SE DESARMA EN EL CONTROLADOR DEL MODELO PARA TRAER POR SEPARADO LOS DATOS Y LA CANTIDAD DE RESULTADOS

        let cols_aux = [...data.options.cols];
        cols_aux.push(verArticulosColumn);
        setColumns(cols_aux);

        setTotalRowCount(parseInt(response.total_records)); // SE ASIGNA LA CANTIDAD TOTAL DE RESULTADOS QUE EXISTEN EN LA DB CON RESPECTO A ESE FETCH
      }
      setRefresh(false);
      setLoading(false);
    };

    !data.options?.res && server && getData(); // SOLO SE REALIZA EL FETCH SI SE USA LA PAGINACION, POR LO QUE SE DEBE ASIGNAR LA FUNCION DEL CONTROLADOR
    // SI SE QUIERE USAR SIN PAGINACION, SE PUEDE PASAR DIRECTAMENTE EL ARREGLO A MOSTRAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refresh]); // falta el data.options

  /* ESTADO DEL CONTEO DE PAGINAS */
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);

  /* actualiza el estado de la pagina y donde se encuentra el paginador */

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [totalRowCount, setRowCountState]);


  //Maneja el delete del item seleccionado--

  //Funcion para poder abrir el popup o para que te lleve al formulario de edicion o creacion
  const handleForm = (dataSelect) => {
    if (dataSelect.row) setDataEdit(dataSelect.row);

    setCreateButton(!createButton);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>

        <Grid>
          {viewCustomToolbar !== "false" && (<><GridToolbarColumnsButton
            style={{
              color: '#FFFFFF',
              fontFamily: 'Roboto, sans-serif',
              background: "#0A395F",
              margin: '4px',
              minWidth: '120px',
              width: '120px',
              fontSize: '11px',
              height: '30px',
            }}
          />
            <GridToolbarFilterButton
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                background: "#0A395F",
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            />
            <GridToolbarDensitySelector
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                background: "#0A395F",
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            />
          </>)}

        </Grid>
        <Grid>
          {!buttomCreate && (
            <Button
              {...(hiddenButtonAgregar === "true" ? { hidden: true } : null)}
              variant="contained"
              size="small"
              onClick={handleForm}
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            >
              <AddCircle />
              <div>Agregar</div>
            </Button>
          )}
          {buttonExtraOn && (<ButtonExtra></ButtonExtra>)}

        </Grid>
        <Grid>

          {printButton && (
            <Button
              startIcon={<PrintIcon />}
              disabled={records.length > 0 ? false : true}
              onClick={handlePrintClick}
              hidden={!imprimir? true : false}
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
                background: records.length > 0 ? "#0A395F" : 'gray',
              }}
            >
              Imprimir
            </Button>
          )}
        </Grid>

      </GridToolbarContainer>
    );
  }

  const handleFilterModelChange = (newFilterModel) => {
    // Aquí puedes interceptar los datos filtrados
    // y hacer cualquier manipulación adicional que necesites
    // antes de actualizar el estado
    console.log('Datos filtrados:', newFilterModel);

    setFilteredData(newFilterModel);
    // setFilteredData(newFilterModel);
  };

  return (
    <Container maxWidth="xxl" style={{ padding: "0px 0px 0px 0px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="left" variant="h5" gutterBottom component="div" style={{ padding: "0px 0px 0px 0px" }}>
            {data.options.listName}
          </Typography>

        </Grid>

        {!buttomCreate && (
          <Grid item xs={1}>
            <Button variant="contained" size="small" onClick={handleForm}>
              <AddCircle />
              <div>Agregar {/* BOTON AGREGAR */}</div>
            </Button>
          </Grid>
        )}
        <Grid /*height="800px"*/ item xs={12}>
          <DataGridPro


            onFilterModelChange={handleFilterModelChange}
            density="compact"
            autoHeight
            autoPageSize
            localeText={esES.components.MuiDataGrid.defaultProps.localeText} // hace que la cabecera de los filtros este español
            // components={{ Toolbar: CustomToolbar }}
            sx={{
              '--DataGrid-overlayHeight': '50vh',
              backgroundColor: 'white',
              fontFamily: 'Roboto, sans-serif', // Cambia el tipo de letra
              borderRadius: 3, // Elimina los bordes redondeados del DataGridPro
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", /* Agrega sombra */
              height: '50vh',
              // FORMATO DE LAS CABECERAS // TODO: CSS
              "& .MuiDataGrid-cell": {
                fontSize: 13,
                fontFamily: 'Roboto, sans-serif',
              }, '.MuiDataGrid-columnSeparator': {
                display: 'none',
              }

              , '& .MuiDataGrid-overlay': {
                height: '800px !important',
              },
            }}
            rows={records} // filas de la tabla OBTENIDAS EN EL FETCH
            columns={columns} // columnas que se van a mostrar || DEBEN CORRESPONDERSE CON LAS QUE TRAE EL FECTH EN EL RECORD
            rowCount={rowCountState}
            loading={loading}
            rowsPerPageOptions={[50, 100, 150, 200]}
            pagination
            page={page}
            pageSize={data.options.limit || 10} // TAMAÑO DE LA PAGINA A MOSTRAR (CANTIDAD DE RECORDS). SI NO SE SETEA EN LOS PROPS, USA POR DEFECTO 10
            paginationMode={server} // PAGINACION VOLCADA AL SERVIDOR
            onPageChange={(newPage) => {
              setPage(newPage);

              //CONTROLO QUE SI LA PAGINA QUE SE ESTA POR CAMBIAR NO SEA LA ULTIMA
              if (newPage !== totalPages) {
                //SI NO ES LA ULTIMA PAGINA, SE ACTUALIZA EL OFFSET Y COMPRUEBO DE QUE EL LIMIT SEA DISTINTO AL LIMIT POR DEFECTO
                limit !== data.options.limit && setLimit(data.options.limit);
                setOffset(data.options.limit * newPage);
              } else {
                //SI ES LA ULTIMA PAGINA SE ACTUALIZA EL LIMIT PARA QUE SE ADAPTE CON LA CANTIDAD DE ITEMS QUE NECESITE TRAER
                setOffset(limit * newPage);
                setLimit(totalRowCount - limit * newPage);
              }
            }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              // las cabeceras de filtrado que van a aparecer
              Toolbar: CustomToolbar,
            }}
            {...data.options.props}
          // se pueden hacer las celdas editables individualmente
          />
          <SimpleDialogComponent
            open={openModal}
            modalName={modalName}
            child={selectedForm}
            handleClose={toggleModal}
            buttonEnviar={false}
            setPushData={saveData}
            buttonCancelar={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

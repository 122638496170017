import { useState } from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import {
  deleteAlicuotaIVA,
  getAlicuotaIVA,
  getAlicuotaIVAPaginated,
} from "../helpers/AlicuotasIVAData";
import { AlicuotasIVAForm } from "./AlicuotasIVAForm";
import { Grid } from "@mui/material";

export const AlicuotasIVAList = () => {
  const [createButton, setCreateButton] = useState(false);
  const [alicuotas, setAlicuotas] = useState([]);

  const listName = "Alicuotas IVA";
  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "nombre", headerName: "Nombre", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    { field: "valor", headerName: "Valor", flex: 1 },
    {
      field: "codigo_afip",
      headerName: "Codigo AFIP",
      align: "center",
      flex: 0.5,
    },
  ];

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  if (createButton === false && alicuotas.id !== undefined) {
    setAlicuotas([]);
  }

  const options = {
    cols: columns,
    get: getAlicuotaIVA,
    getPaginated: getAlicuotaIVAPaginated,
    pageSize: 5,
    listName: "Alicuotas IVA",
    delete: deleteAlicuotaIVA,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,
    edit: true,
    sinFilas: false,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setAlicuotas,
  };

  return (
    <>
      <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>
        <ListTablePaginated options={options} />
        {createButton && (
          <AlicuotasIVAForm
            data={alicuotas}
            setCreateButton={setCreateButton}
            createButton={createButton}
            listName={listName}
          />
        )}
      </Grid>
    </>
  );
};


export const schemaTipoCuentasForm = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "id",
      description: "Id",
      hidden: true,
    },
    detalle: {
      type: "string",
      title: "Detalle",
      description: "Detalle",
      minLength: 3 
    },
    selectedCategoriaCuentas: {
      type: "object",
      customRender: "SelectorCategoriaCuentas",
    },
  },
    required: ["detalle","selectedCategoriaCuentas"],
  
}
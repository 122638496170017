import { createContext, useState, useEffect } from "react";
import { esArray } from "src/general_components/functionEsArray"
import { v4 as uuidv4 } from 'uuid';

export const CajasContext = createContext();

export function CajasProvider({ children }) {
  const [modalState, setModalState] = useState(false);
  const [modalStateCont, setModalStateCont] = useState(false);
  const [clientesList, setClientesList] = useState([]);
  const [clienteId, setClienteId] = useState([]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState();
  const [sectoresList, setSectoresList] = useState([]);
  const [sectorId, setSectorId] = useState([]);
  const [sectorSeleccionado, setSectorSeleccionado] = useState();
  const [cajasList, setCajasList] = useState([]);
  const [cajaId, setCajaId] = useState([""]);
  const [tiposCajasList, setTiposCajasList] = useState([]);
  const [tipoCaja, setTipoCaja] = useState([]);
  const [contenidosId, setContenidosId] = useState([]);
  const [contenidosList, setContenidosList] = useState([]);
  const [tipoContenido, setTipoContenido] = useState("");
  const [tipoContenidoList, setTipoContenidoList] = useState([]);
  const [metadatos, setMetadatos] = useState([]);
  const [contenidos_metadatos, setContenidos_metadatos] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selected, setSelected] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [eliminado, setEliminado] = useState(false);
  const [altaGenerica, setAltaGenerica] = useState(false);
  const [listCajas, setListCajas] = useState([]);
  const [cajasUbicadas, setCajasUbicadas] = useState('0');
  const [checked, setChecked] = useState(false);
  const [valueDetalleUbicacionTemporal, setValueUbicacionTemporal] = useState();
  const [retiroCajas, setRetiroCajas] = useState();
  const [showForm, setShowForm] = useState(false);
  const [tipoContenidoSeleccionado, setTipoContenidoSeleccionado] = useState([]);
  const [tipoContenidoSeleccionadoinSearch, setTipoContenidoSeleccionadoinSearch] = useState(window.tipoContenidoSeleccionadoinSearch || []);
  const [tipoContenidoListCuentaSector, setTipoContenidoListCuentaSector] = useState([]);
  const [retiroContenido, setRetiroContenido] = useState(false);
  const [tipoRetiroSeleccionado, setTipoRetiroSeleccionado] = useState("");
  const [dataInfoBusqueda, setDataInfoBusqueda] = useState({});
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');
  const [refresh, setRefresh] = useState('');
  const [listDatosFormCuentaSector, setListDatosFormCuentaSector] = useState(window.listDatosFormCuentaSector || []);
  const [listDatosFormMetadatos, setListDatosFormMetadatos] = useState(window.listDatosFormMetadatos || []);
  const [metadatosList, setMetadatosList] = useState(window.metadatosList || []);
  const [listDatosFormUbicaciones, setListDatosFormUbicaciones] = useState(window.listDatosFormUbicaciones || { idDep: '', activoUbicacionTemporal: false });
  const [listDatosFormIdCajas, setListDatosFormIdCajas] = useState(window.listDatosFormIdCajas || { id_cajas: "", id_cajas_desde: "", id_cajas_hasta: "", referencia: "", idCajasRango: "", tiposCajasSeleccionadas: [], ordenamiento: "", excedida: "2", deformada: "2" });
  const [listDatosEstadosCajas, setListDatosEstadosCajas] = useState(window.listDatosEstadosCajas || []);
  const [listDatosFechaAltaCajas, setListDatosFechaAltaCajas] = useState(window.listDatosFechaAltaCajas || []);
  const [formFechaAltaDisabled, setFormFechaAltaDisabled] = useState(window.formFechaAltaDisabled || false);
  const [formUbicacionesDisabled, setFormUbicacionesDisabled] = useState(window.formUbicacionesDisabled || false);
  const [dataSearchFormUbicaciones, setDataSearchFormUbicaciones] = useState(window.dataSearchFormUbicaciones || []);
  const [formIdCajasDisabled, setFormIdCajasDisabled] = useState(window.formIdCajasDisabled || false);
  const [dataSearchFormEstadosCajas, setDataSearchFormEstadosCajas] = useState(window.dataSearchFormEstadosCajas || []);
  const [formEstadosCajasDisabled, setFormEstadosCajasDisabled] = useState(window.formEstadosCajasDisabled || false);
  const [dataSearchFormTipoContenido, setDataSearchFormTipoContenido] = useState(window.dataSearchFormTipoContenido || []);
  const [dataSearchFormMetadatos, setDataSearchFormMetadatos] = useState(window.dataSearchFormMetadatos || []);
  const [formMetadatosDisabled, setFormMetadatosDisabled] = useState(false);
  const [formCuentaSectorDisabled, setFormCuentaSectorDisabled] = useState(false);
  const [showFormCuentaSector, setShowFormCuentaSector] = useState(window.showFormCuentaSector || true);
  const [requiredFormCuentaSector, setRequiredFormCuentaSector] = useState(window.requiredFormCuentaSector || false);
  const [showFormMetadatos, setShowFormMetadatos] = useState(window.showFormMetadatos || true);
  const [requiredFormMetadatos, setRequiredFormMetadatos] = useState(window.requiredFormMetadatos || false);
  const [showFormUbicaciones, setShowFormUbicaciones] = useState(window.showFormUbicaciones || true);
  const [requiredFormUbicaciones, setRequiredFormUbicaciones] = useState(window.requiredFormUbicaciones || false);
  const [showFormIdCajas, setShowFormIdCajas] = useState(window.showFormIdCajas || true);
  const [requiredFormIdCajas, setRequiredFormIdCajas] = useState(window.requiredFormIdCajas || false);
  const [showFormEstadosCajas, setShowFormEstadosCajas] = useState(window.showFormEstadosCajas || true);
  const [requiredFormEstadosCajas, setRequiredFormEstadosCajas] = useState(window.requiredFormEstadosCajas || false);
  const [showFormFechaAltaCajas, setShowFormFechaAltaCajas] = useState(window.showFormFechaAltaCajas || true);
  const [requiredFormFechaAltaCajas, setRequiredFormFechaAltaCajas] = useState(window.requiredFormFechaAltaCajas || false);
  const [requiredTipoContenido, setRequiredTipoContenido] = useState(window.requiredTipoContenido || false);
  const [responseSearchFormGeneral, setResponseSearchFormGeneral] = useState([]);
  const [dataSearchFormGeneral, setDataSearchFormGeneral] = useState(window.dataSearchFormGeneral || []);
  const [checkFisica, setCheckFisica] = useState(window.checkFisica || true);
  const [checkVirtual, setCheckVirtual] = useState(window.checkVirtual || false);
  const [soloCajas, setSoloCajas] = useState(window.soloCajas || false);
  const [clienteIdSearch, setClienteIdSearch] = useState(window.clienteIdSearch || null);
  const [sectorIdSearch, setSectorIdSearch] = useState(window.sectorIdSearch || []);
  const [listRecords, setListRecords] = useState([]);
  const [requiredSector, setRequiredSector] = useState(window.requiredSector || false);
  const [rows, setRows] = useState([]);
  const [clienteName, setClienteName] = useState(window.clienteName || "");
  const [omitirQuerySearch, setOmitirQuerySearch] = useState(window.omitirQuerySearch || false);
  const [requiredFormMovimientos, setRequiredFormMovimientos] = useState(window.requiredFormMovimientos || false);
  const [showFormMovimientos, setShowFormMovimientos] = useState(window.showFormMovimientos || false);
  const [dataSearchMovimientos, setDataSearchMovimientos] = useState(window.dataSearchMovimientos || {});
  const [tipoHistorico, setTipoHistorico] = useState(true);
  const [valorContenido, setValorContenido] = useState({});
  const [ajuste, setAjuste] = useState(false);
  const [disabledCheckAjuste, setDisabledCheckAjuste] = useState(false);
  const [checkedTotal, setCheckedTotal] = useState(false);
  const [digitalizacionesSeleccionadas, setDigitalizacionesSeleccionadas] = useState({ digitalizaciones_contenidos: [], contenidos: [] });
  const [checkedTotalCaratula, setCheckedTotalCaratula] = useState(false);
  const [digitalizacionesSeleccionadasCompleto, setDigitalizacionesSeleccionadasCompleto] = useState({ digitalizaciones_contenidos: [], contenidos: [] });
  const [checkedCaratula, setCheckedCaratula] = useState(false);
  const [digitalizacionesAfacturar, setDigitalizacionesAfacturar] = useState({ digitalizaciones_contenidos: [], contenidos: [] });
  const [checkedConCambios, setCheckedConCambios] = useState(false);
  const [actualizacionIndividualCheckbox, setActualizacionIndividualCheckbox] = useState(false);
  const [criteriaComprobantes, setcriteriaComprobantes] = useState(false);
  const [actualizarListadoContenidos, setActualizarListadoContenidos] = useState(false);
  const [contenidoSeleccionado, setContenidoSeleccionado] = useState([]);
  const [busquedaSearchOn, setBusquedaSearchOn] = useState(window.busquedaSearchOn || false);
  const [clientesListSearch, setClientesListSearch] = useState([]);
  const [sectoresListSearch, setSectoresListSearch] = useState([]);
  const [metadatosContenidoSeleccionado, setMetadatosContenidoSeleccionado] =
    useState([]);
  const [openDialogImage, setOpenDialogImage] = useState(false);
  const [clickCheckedTotal, setClickCheckedTotal] = useState(false);
  const [combinarFiltro, setCombinarFiltro] = useState(window.combinarFiltro || true);
  const [checkReferencia, setCheckReferencia] = useState(false);
  const [tipoRetiroMovimientoSeleccionado, setTipoRetiroMovimientoSeleccionado] = useState("1");
  const [verTodosTContenidos, setVerTodosTContenidos] = useState(true);
  const [disabledVerTodosTContenidos, setDisabledVerTodosTContenidos] = useState(true);
  const [searchModal, setSearchModal] = useState(true);
  const [noActualizar, setNoActualizar] = useState(false);
  const [actualizarListado, setActualizarListado] = useState(window.actualizarListado || false);
  const [inputFields, setInputFields] = useState(window.inputFields || [{
    id: uuidv4(), metadato: '', operador: '', valor: '',
    valorDesde: '', valorHasta: '', listOperadores: [], listMetadatos: metadatosList, tipoDato: 'text',
    valorDesdeHasta: false
  }]);
  const [datosFormUbicaciones, setDatosFormUbicaciones] = useState(window.listDatosFormUbicaciones || { idDep: '', activoUbicacionTemporal: false });
  const [abrirAgregarContenido, setAbrirAgregarContenido] = useState(false);
  const [aProcesar, setAProcesar] = useState(null);
  const [desmarcar, setDesmarcar] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedLocalizacion, setSelectedLocalizacion] = useState([]);
  const [idCuentaLocalizacionesSelected, setIdCuentaLocalizacionesSelected] = useState('');

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [grupoBusquedaSectorCliente, setGrupoBusquedaSectorCliente] = useState(false);
  const [grupoBusquedaMetadato, setGrupoBusquedaMetadato] = useState(false);
  const [grupoBusquedaUbicaciones, setGrupoBusquedaUbicaciones] = useState(false);
  const [grupoBusquedaCajas, setGrupoBusquedaCajas] = useState(false);
  const [grupoBusquedaEstadosCajas, setGrupoBusquedaEstadosCajas] = useState(false);
  const [grupoBusquedaFecha, setGrupoBusquedaFecha] = useState(false);
  const [grupoBusquedaMovimiento, setGrupoBusquedaMovimiento] = useState(false);
  const [idsCajasCreadas, setIdsCajasCreadas] = useState('');
  const [disabledButtonsCopy, setDisabledButtonsCopy] = useState(false);
  const [cajasIds, setCajasIds] = useState('');
  const [combinarFiltroContenidos, setCombinarFiltroContenidos] = useState(window.combinarFiltro || false);


  const traerContenidosmetadatos = (contmetadatos) => {
    setContenidos_metadatos((prevState) => [...prevState, { contmetadatos }]);
  };

  const getMeta_datos = (mdatos) => {
    setMetadatos((prevState) => [...prevState, { mdatos }]);
  };

  const getTipoContenido = (id) => {
    setTipoContenido((prevState) => [...prevState, { id }]);
  };
  const getTipoContenidoList = (id, detalle) => {
    setTipoContenidoList((prevState) => [...prevState, { id, detalle }]);
  };
  const getContenidosId = (id) => {
    setContenidosId((prevState) => [...prevState, { id }]);
  };
  // useEffect(() => {
  //   const traerContenidos = async () => {
  //     const contenidos = await getContenidos();
  //     setContenidosList(contenidos);
  //   };
  //   const metadatos = async () => {
  //     const meta = await getMetadatos();
  //     setMetadatos(meta);
  //   };
  //   const contmdatos = async () => {
  //     const cmdatos = await getContenidos_metadatos();
  //     setContenidos_metadatos(cmdatos);
  //   };
  //   metadatos();
  //   traerContenidos();
  //   contmdatos();
  // }, []);

  const getContenidosList = (contenidosList) => {
    setContenidosList((prevState) => [...prevState, { contenidosList }]);
  };
  const getClientes = (id, nombre_fantasia) => {
    setClientesList((prevState) => [...prevState, { id, nombre_fantasia }]);
  };
  const selectClienteId = (id, razon_social) => {
    setClienteId((prevState) => [...prevState, { id, razon_social }]);
  };
  const getSectores = (sectores) => {
    setSectoresList((prevState) => [...prevState, { sectores }]);
  };
  const selectSectorId = (sectorId) => {
    setSectorId((prevState) => [...prevState, { sectorId }]);
  };

  const traerCajas = (cajasList) => {
    setCajasList((prevState) => [...prevState, { cajasList }]);
  };

  const getTipos = (tiposCajasList) => {
    setTiposCajasList((prevState) => [...prevState, { tiposCajasList }]);
  };
  const selectedTipo = (tipoCaja) => {
    setTipoCaja((prevState) => [...prevState, { tipoCaja }]);
  };

  const selectedCaja = (cajaId) => {
    setCajaId((prevState) => cajaId);
  };

  const limpiarSearchForm = () => {
    setSelectionModel([])
    setFormCuentaSectorDisabled(false);
    setListDatosFormCuentaSector([]);
    setListDatosFormMetadatos([]);
    setMetadatosList([]);
    setListDatosFormUbicaciones({ idDep: '' });
    setListDatosFormIdCajas({ id_cajas: "", referenciasRango: "", idCajasRango: "", tiposCajasSeleccionadas: [], ordenamiento: "", excedida: "2", deformada: "2" });
    setListDatosEstadosCajas([]);
    setListDatosFechaAltaCajas([]);
    setFormUbicacionesDisabled(false);
    setDataSearchFormUbicaciones([]);
    setFormIdCajasDisabled(false);
    setDataSearchFormEstadosCajas([]);
    setFormEstadosCajasDisabled(false);
    setDataSearchFormMetadatos([]);
    setFormMetadatosDisabled(false);
    setFormCuentaSectorDisabled(false);
    setResponseSearchFormGeneral([]);
    setDataSearchFormGeneral([]);

  };

  // TRAIGO LA DATA DEL CLIENTE SELECCIONADO Y DEL SECTOR SELECCIONADO
  useEffect(() => {
    if (clienteId && clienteSeleccionado?.id_cuentas !== clienteId.id_cuentas) {
      const dataCliente = clientesList.filter(
        (cliente) => cliente.id_cuentas === clienteId.id_cuentas
      );
      setClienteSeleccionado(dataCliente[0]);
    }
    if (sectorId && sectorSeleccionado?.id !== sectorId.id) {
      if (Array.isArray(sectoresList)) {
        const dataSector = sectoresList.filter(
          (sector) => sector.id === sectorId.id
        );
        setSectorSeleccionado(dataSector[0]);
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteId, sectorId]);


  const obtenerMetadato = async (row) => {
    let rowArr = esArray(row);
    let arrayMetadatos = Object.entries(rowArr).map(([key, value]) => {
      let objeto = {};
      objeto["label"] = value.metadato_label_form;
      objeto["valor"] = value.metadato_valor;
      return objeto;
    });
    setMetadatosContenidoSeleccionado(arrayMetadatos);
  };

  return (
    <CajasContext.Provider
      value={{
        clientesList,
        setClientesList,
        clienteId,
        selectClienteId,
        setClienteId,
        getClientes,
        sectoresList,
        setSectoresList,
        sectorId,
        setSectorId,
        getSectores,
        selectSectorId,
        traerCajas,
        cajasList,
        setCajasList,
        tiposCajasList,
        setTiposCajasList,
        tipoCaja,
        setTipoCaja,
        getTipos,
        selectedTipo,
        selectedCaja,
        cajaId,
        setCajaId,
        contenidosId,
        contenidosList,
        setContenidosList,
        getContenidosId,
        getTipoContenido,
        tipoContenido,
        setTipoContenido,
        tipoContenidoList,
        getTipoContenidoList,
        setTipoContenidoList,
        getContenidosList,
        metadatos,
        setMetadatos,
        getMeta_datos,
        contenidos_metadatos,
        setContenidos_metadatos,
        traerContenidosmetadatos,
        modalState,
        setModalState,
        selectionModel,
        setSelectionModel,
        selected,
        setSelected,
        disabled,
        setDisabled,
        modalStateCont,
        setModalStateCont,
        eliminado,
        setEliminado,
        clienteSeleccionado,
        sectorSeleccionado,
        setSectorSeleccionado,
        setClienteSeleccionado,
        altaGenerica,
        setAltaGenerica,
        listCajas,
        setListCajas,
        cajasUbicadas,
        setCajasUbicadas,
        checked,
        setChecked,
        valueDetalleUbicacionTemporal,
        setValueUbicacionTemporal,
        retiroCajas,
        setRetiroCajas,
        tipoContenidoSeleccionado,
        setTipoContenidoSeleccionado,
        showForm,
        setShowForm,
        retiroContenido,
        setRetiroContenido,
        tipoRetiroSeleccionado,
        setTipoRetiroSeleccionado,
        dataInfoBusqueda,
        setDataInfoBusqueda,
        snackbarVisible,
        setSnackbarVisible,
        message,
        setMessage,
        severity,
        setSeverity,
        refresh,
        setRefresh,
        listDatosFormMetadatos,
        setListDatosFormMetadatos,
        listDatosFormUbicaciones,
        setListDatosFormUbicaciones,
        listDatosFormIdCajas,
        setListDatosFormIdCajas,
        listDatosEstadosCajas,
        setListDatosEstadosCajas,
        listDatosFechaAltaCajas,
        setListDatosFechaAltaCajas,
        metadatosList,
        setMetadatosList,
        tipoContenidoSeleccionadoinSearch,
        setTipoContenidoSeleccionadoinSearch,
        listDatosFormCuentaSector,
        setListDatosFormCuentaSector,
        formUbicacionesDisabled,
        setFormUbicacionesDisabled,
        dataSearchFormUbicaciones,
        setDataSearchFormUbicaciones,
        formIdCajasDisabled,
        setFormIdCajasDisabled,
        formFechaAltaDisabled,
        setFormFechaAltaDisabled,
        dataSearchFormEstadosCajas,
        setDataSearchFormEstadosCajas,
        formEstadosCajasDisabled,
        setFormEstadosCajasDisabled,
        dataSearchFormMetadatos,
        setDataSearchFormMetadatos,
        formMetadatosDisabled,
        setFormMetadatosDisabled,
        showFormCuentaSector, setShowFormCuentaSector,
        requiredFormCuentaSector, setRequiredFormCuentaSector,
        showFormMetadatos, setShowFormMetadatos,
        requiredFormMetadatos, setRequiredFormMetadatos,
        showFormUbicaciones, setShowFormUbicaciones,
        requiredFormUbicaciones, setRequiredFormUbicaciones,
        showFormIdCajas, setShowFormIdCajas,
        requiredFormIdCajas, setRequiredFormIdCajas,
        showFormEstadosCajas, setShowFormEstadosCajas,
        requiredFormEstadosCajas, setRequiredFormEstadosCajas,
        showFormFechaAltaCajas, setShowFormFechaAltaCajas,
        requiredFormFechaAltaCajas, setRequiredFormFechaAltaCajas,
        responseSearchFormGeneral, setResponseSearchFormGeneral,
        formCuentaSectorDisabled, setFormCuentaSectorDisabled,
        dataSearchFormGeneral, setDataSearchFormGeneral, limpiarSearchForm,
        dataSearchFormTipoContenido, setDataSearchFormTipoContenido,
        checkFisica, setCheckFisica, checkVirtual, setCheckVirtual,
        requiredTipoContenido, setRequiredTipoContenido,
        soloCajas, setSoloCajas, clienteIdSearch, setClienteIdSearch,
        sectorIdSearch, setSectorIdSearch, listRecords, setListRecords,
        requiredSector, setRequiredSector, rows, setRows, clienteName, setClienteName,
        omitirQuerySearch,
        setOmitirQuerySearch,
        showFormMovimientos, setShowFormMovimientos,
        requiredFormMovimientos, setRequiredFormMovimientos,
        dataSearchMovimientos, setDataSearchMovimientos, clickCheckedTotal, setClickCheckedTotal,
        tipoHistorico, setTipoHistorico, sectoresListSearch, setSectoresListSearch,
        valorContenido, setValorContenido, clientesListSearch, setClientesListSearch,
        ajuste, setAjuste, disabledCheckAjuste, setDisabledCheckAjuste, checkedTotal, setCheckedTotal,
        digitalizacionesSeleccionadas, setDigitalizacionesSeleccionadas, checkedTotalCaratula, setCheckedTotalCaratula,
        digitalizacionesSeleccionadasCompleto, setDigitalizacionesSeleccionadasCompleto,
        checkedCaratula, setCheckedCaratula, digitalizacionesAfacturar, setDigitalizacionesAfacturar, obtenerMetadato,
        checkedConCambios, setCheckedConCambios, actualizacionIndividualCheckbox, setActualizacionIndividualCheckbox,
        criteriaComprobantes, setcriteriaComprobantes, actualizarListadoContenidos, setActualizarListadoContenidos,
        contenidoSeleccionado, setContenidoSeleccionado, metadatosContenidoSeleccionado,
        setMetadatosContenidoSeleccionado, openDialogImage, setOpenDialogImage, busquedaSearchOn, setBusquedaSearchOn,
        tipoRetiroMovimientoSeleccionado, setTipoRetiroMovimientoSeleccionado, combinarFiltro, setCombinarFiltro,
        verTodosTContenidos, setVerTodosTContenidos, checkReferencia, setCheckReferencia, tipoContenidoListCuentaSector, noActualizar, setNoActualizar,
        setTipoContenidoListCuentaSector, searchModal, setSearchModal, inputFields, setInputFields, disabledVerTodosTContenidos, aProcesar, setAProcesar,
        setDisabledVerTodosTContenidos, actualizarListado, setActualizarListado, datosFormUbicaciones, setDatosFormUbicaciones, abrirAgregarContenido,
        setAbrirAgregarContenido, desmarcar, setDesmarcar, loading, setLoading, selectedLocalizacion, setSelectedLocalizacion, isLoadingData, setIsLoadingData,
        idCuentaLocalizacionesSelected, setIdCuentaLocalizacionesSelected, grupoBusquedaSectorCliente, setGrupoBusquedaSectorCliente, grupoBusquedaMetadato, setGrupoBusquedaMetadato,
        grupoBusquedaUbicaciones, setGrupoBusquedaUbicaciones, grupoBusquedaCajas, setGrupoBusquedaCajas, grupoBusquedaEstadosCajas, setGrupoBusquedaEstadosCajas, grupoBusquedaFecha, setGrupoBusquedaFecha,
        grupoBusquedaMovimiento, setGrupoBusquedaMovimiento, idsCajasCreadas, setIdsCajasCreadas, disabledButtonsCopy, setDisabledButtonsCopy, 
        cajasIds, setCajasIds,combinarFiltroContenidos, setCombinarFiltroContenidos
      }}
    >
      {children}
    </CajasContext.Provider>
  );
}

// export default CajasContext;

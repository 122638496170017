import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
import { getEstadosCajas } from "../helpers/EstadosCajasData";

export const getCajas = async (sectorId, altaGenerica, altaContenidosGenericas, limit, offset) => {
  const objectCajas = new datos("cajas");
  if (altaGenerica === false && !altaContenidosGenericas) {
    objectCajas.addCondition("estados_cajas", "detalle", "'Creada', 'Sin Ubicación'", "in");
  } else if (altaContenidosGenericas) {
    objectCajas.addCondition("estados_cajas", "detalle", "'Alojada generica'", "in");
  }
  else {
    objectCajas.addCondition("estados_cajas", "detalle", "'Creada Generica'", "in");
  }
  objectCajas.addCondition("cajas", "id_cuentas_localizaciones", sectorId, "=");
  objectCajas.join("estados_cajas", "id", "id_estados_cajas", "=");
  objectCajas.join(
    "cuentas_localizaciones",
    "id",
    "id_cuentas_localizaciones",
    "="
  );
  objectCajas.join("tipo_cajas", "id", "id_tipo_cajas");
  objectCajas.join("contenidos", "id_cajas", "id", " = ");
  const response = await objectCajas.get();
  if (response.wsResponse.cajas) {
    const arrResponse = esArray(response.wsResponse.cajas);
    return arrResponse;
  } else {
    return response;
  }

};

export const getCajasPaginated = async (limit, offset, conditions) => {
  const { sectorId, altaGenerica, altaContenidosGenericas, clienteName, cajasIds } = conditions
  const objectCajas = new datos("cajas");
  if (altaGenerica === false && !altaContenidosGenericas) {
    objectCajas.addCondition("estados_cajas", "detalle", "'Creada', 'Sin Ubicación'", "in");
  } else if (altaContenidosGenericas) {
    objectCajas.addCondition("estados_cajas", "detalle", "'Alojada generica'", "in");
  }
  else {
    objectCajas.addCondition("estados_cajas", "detalle", "'Creada Generica', 'Alojada generica'", "in");
  }

  if (cajasIds !== '') {
    objectCajas.addCondition("cajas", "id", cajasIds, "in");
  }
  objectCajas.addCondition("cajas", "id_cuentas_localizaciones", sectorId, "=");
  objectCajas.join("estados_cajas", "id", "id_estados_cajas", "=");
  objectCajas.join(
    "cuentas_localizaciones",
    "id",
    "id_cuentas_localizaciones",
    "="
  );
  objectCajas.join("tipo_cajas", "id", "id_tipo_cajas");
  objectCajas.addLimitCondition(limit, offset);

  const response = await objectCajas.get();

  if (response.wsResponse.cajas) {
    let arrResponse = [];
    if (response.wsResponse.cajas.length > 0) {
      if (response.wsResponse.cajas.some(item => item.hasOwnProperty('id'))) {
        arrResponse = esArray(response.wsResponse.cajas);
      } else {
        arrResponse = response.wsResponse.cajas.map(caja => caja.relations).filter(Boolean);
      }
    } else {
      if (response.wsResponse.cajas.id) {
        arrResponse = esArray(response.wsResponse.cajas);
      } else {
        arrResponse = esArray(response.wsResponse.cajas.relations);
      }
    }

    let cajasData = arrResponse;


    for (let i = 0; i < cajasData.length; i++) {
      cajasData[i].estado = cajasData[i]?.relations?.estados_cajas?.detalle;
      cajasData[i].tipo_cajas = cajasData[i]?.relations?.tipo_cajas?.id + " - " + cajasData[i]?.relations?.tipo_cajas?.detalle;
      cajasData[i].ubicacion = "Sin ubicacion"
      cajasData[i].tipo = cajasData[i]?.relations?.tipo_cajas?.detalle;
      const caja = "CAJA: " + cajasData[i].id;
      const referencia = typeof cajasData[i].referencia === 'object' ? '' : " | REF: " + cajasData[i].referencia;
      cajasData[i].caja = caja + referencia;
      cajasData[i].hierarchy = ['cliente0', 'sector1', cajasData[i].id];
    }
    cajasData.push({ id: 'cliente0', cliente: clienteName, hierarchy: ['cliente0'] });
    cajasData.push({
      id: 'sector1', sector: cajasData[0]?.relations?.cuentas_localizaciones?.descripcion,
      hierarchy: ['cliente0', 'sector1']
    })
    let result = {
      data: cajasData,
      total_records: response.wsResponse.total_records,
    };
    return result;

  } else {
    let result = {
      data: [],
      total_records: 0,
    };
    return result;
  }

};

export const getCajasSectorDigitalizadas = async (sectorId, limit, offset) => {
  const objectCajas = new datos("cajas");
  objectCajas.addCondition(
    "estados_cajas",
    "detalle",
    "'Creada', 'Sin Ubicación'",
    "in"
  );
  objectCajas.addCondition("cajas", "id_cuentas_localizaciones", sectorId, "=");
  objectCajas.join("estados_cajas", "id", "id_estados_cajas", "=");
  objectCajas.join(
    "cuentas_localizaciones",
    "id",
    "id_cuentas_localizaciones",
    "="
  );
  objectCajas.join("tipo_cajas", "id", "id_tipo_cajas");
  objectCajas.join("contenidos", "id_cajas", "id", " = ");
  objectCajas.addCondition("tipo_cajas", "digitalizado", 1, " = ");
  const response = await objectCajas.get();
  return response ? response.wsResponse.cajas : response;
};

export const getCuentasGenericas = async (auth) => {
  const cuentas = new datos("cuentas");
  const response = await cuentas.getCuentasGenericas();

  const res = response.wsResponse.cuentas;
  // Si es un array se mantiene como estaba
  if (Array.isArray(res)) {
    var arr = res;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (typeof res === "undefined") {
    // se pasa un array vacio
    arr = [];
  } else {
    // si trae un solo elemento , es un objeto
    arr = [res]; // se lo convierte a array
  }
  return arr;
};

export const getCajasByIDEstadoTipoReferenciaDetalleFecha = async (searchParams) => {

  const objectCajas = new datos("cajas");

  searchParams?.ids && objectCajas.addCondition("cajas", "id", searchParams.ids, "in");
  searchParams?.id_cuentas_localizaciones && objectCajas.addCondition("cajas", "id_cuentas_localizaciones", searchParams.id_cuentas_localizaciones, "=");
  searchParams?.id_tipo_caja && objectCajas.addCondition("cajas", "id_tipo_cajas", searchParams.id_tipo_caja, "=");
  searchParams?.id_tipo_estado && objectCajas.addCondition("cajas", "id_estados_cajas", searchParams.id_tipo_estado, "=");
  searchParams?.fecha_creacion && objectCajas.addCondition("cajas", "fecha_creacion", searchParams.fecha_creacion, "=");
  searchParams?.referencia && objectCajas.addLikeCondition("cajas", "referencia", searchParams.referencia);
  searchParams?.detalle && objectCajas.addLikeCondition("cajas", "detalle", searchParams.detalle);

  const response = await objectCajas.get();
  const arrResponse = esArray(response.wsResponse.cajas);

  let responseEstadosCajas = await getEstadosCajas();
  const finalRows = []

  for (let i = 0; i < response.length; i++) {

    let partialRow = {
      id: response[i].id,
      IDandRef: "ID: " + response[i].id + " Ref: " + response[i].referencia,
      cuenta: "Honorable Consejo Deliberante",
      estado: responseEstadosCajas.filter(te => te?.id === response[i]?.id_estados_cajas)[0]?.detalle,
      isRowSelectable: true
    }
    finalRows.push(partialRow)
  }

  if (arrResponse.length > 0) {
    let result = {
      data: finalRows,
      total_records: arrResponse.length,
    };
    return result;
  } else {
    return undefined;
  }
};

export const getCajasByIDEstadoTipoReferenciaDetalleFechaPaginated = async (limit, offset, searchParams) => {

  const objectCajas = new datos("cajas");

  searchParams?.ids && objectCajas.addCondition("cajas", "id", searchParams.ids, "in");
  searchParams?.id_cuentas_localizaciones && objectCajas.addCondition("cajas", "id_cuentas_localizaciones", searchParams.id_cuentas_localizaciones, "=");
  searchParams?.id_tipo_caja && objectCajas.addCondition("cajas", "id_tipo_cajas", searchParams.id_tipo_caja, "=");
  searchParams?.id_tipo_estado && objectCajas.addCondition("cajas", "id_estados_cajas", searchParams.id_tipo_estado, "=");
  searchParams?.fecha_creacion && objectCajas.addCondition("cajas", "fecha_creacion", searchParams.fecha_creacion, "=");
  searchParams?.referencia && objectCajas.addLikeCondition("cajas", "referencia", searchParams.referencia);
  searchParams?.detalle && objectCajas.addLikeCondition("cajas", "detalle", searchParams.detalle);
  objectCajas.addLimitCondition(limit, offset)

  const response = await objectCajas.get();
  const arrResponse = esArray(response.wsResponse.cajas);

  let responseEstadosCajas = await getEstadosCajas();
  const finalRows = []

  for (let i = 0; i < arrResponse.length; i++) {

    let partialRow = {
      id: arrResponse[i].id,
      IDandRef: "ID: " + arrResponse[i].id + " Ref: " + arrResponse[i].referencia,
      cuenta: "Honorable Consejo Deliberante",
      estado: responseEstadosCajas.filter(te => te?.id === arrResponse[i]?.id_estados_cajas)[0]?.detalle,
      isRowSelectable: true
    }
    finalRows.push(partialRow)
  }

  if (arrResponse.length > 0) {
    let result = {
      data: finalRows,
      total_records: response.wsResponse.total_records,
    };
    return result;
  } else {
    return undefined;
  }
};

export const postCajas = async (caja) => {
  const objetoCajas = new datos("cajas");
  return await objetoCajas.insert(caja);
};

export const deleteCaja = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar esta Caja?",
    text: "Si sigue adelante, no se podran revertir los cambio!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos("cajas");
      let data = {
        id: id,
      };
      const response = await userData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "La caja ha sido ha sido eliminada exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};

import { useEffect, useState } from "react";
import { useCuentasContext } from "src/context/CuentasContext";
import { CuentasList } from "./CuentasList";

/* Clase contenedora a los efectos de mostrar el Listado de Cuentas o el formulario de carga/edicion de CUENTAS */


export const CuentasContent = ( data ) => {
  const { cuenta, setAddCuenta, setEditCuenta, endAsyncGetData,setEsJuridica } =
    useCuentasContext();

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (cuenta.id_cuentas > 0) setEditCuenta(true);
    else setEditCuenta(false);

    if(data.esJuridica){
      setEsJuridica(true);
    }
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuenta]);

  function addCuentaFunc() {
    setAddCuenta(true);
  }

  useEffect(() => {
    setEdit(true);
  }, [endAsyncGetData]);

  return (
    <>
      <CuentasList add={addCuentaFunc} esJuridica={data.esJuridica} />
    </>
  );
}

export default CuentasContent;

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";

const SelectorTipoFecha = (props) => {
  const estilosSelect = { fontSize: "1em", color: "#646464" };
  const [selectedTipoFecha, setSelectedTipoFecha] = useState("");

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
  };

  const jsonForm = useJsonForms();
  const disabled = jsonForm.core.data.disabled;

  const handleChange = (event) => {
    setSelectedTipoFecha(event.target.value);
    props.handleChange(props.path, event.target.value);
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <InputLabel>Fecha</InputLabel>
        <Select
          style={estilosSelect}
          inputProps={{ disabled: disabled }}
          MenuProps={MenuProps}
          value={selectedTipoFecha}
          onChange={handleChange}
          label="Fecha"
        >
          <MenuItem style={estilosSelect} value="">
            Ninguno
          </MenuItem>
          <MenuItem style={estilosSelect} key={1} value={1}>
            fecha_comprobante
          </MenuItem>
          <MenuItem style={estilosSelect} key={2} value={2}>
            fecha_trabajo
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

/*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
const SelectorTipoFechaTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorTipoFecha";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorTipoFechaComponent = {
  renderer: withJsonFormsControlProps(SelectorTipoFecha),
  tester: SelectorTipoFechaTester,
};

export const uiSchemaMiPerfilUsuarioForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/name",
        },
        {
          type: "Control",
          scope: "#/properties/surname",
        },
      ],
    }
    ,
    {
      type: "HorizontalLayout",
      elements: [
        
        {
          type: "Control",
          scope: "#/properties/username",
        },
        {
          type: "Control",
          scope: "#/properties/email",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        
        {
          type: "Control",

          scope: "#/properties/password",
          options: {
            format: "password",
          },
        },
        {
          type: "Control",

          scope: "#/properties/passwordCoincidencia",
          options: {
            format: "password",
          },
        },
      ],
    }
  ],
};

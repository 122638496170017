import Grid from "@mui/material/Grid";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { TipoUbicacionesList } from "../components/TipoUbicacionesList";

export function TipoUbicaciones() {
  return (
    <Grid style={{ background: "white", height: "100%", marginBottom: "50px" }}>
     
      <CajasProvider>  
        <TipoUbicacionesList />
      </CajasProvider>
    </Grid>
  );
}

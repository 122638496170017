import { esArray } from "src/general_components/functionEsArray";
import datos from "src/services/datos";
import Swal from "sweetalert2";

export const getComprobantesDataPaginarCliente = async (comprobante) => {

  const data = {
    criteria: comprobante,
  };

  const comprobantes = new datos("comprobantes");
  const response = await comprobantes.getComprobantes(data);
  const arrResponse = esArray(response.wsResponse.comprobantes);

  let arrayComprobantes = arrResponse.map(function (objeto, index) {
    let comprobante = {};
    let total;
    comprobante.id = objeto.comprobantes.id;
    comprobante.fecha = objeto.comprobantes.fecha_comprobante;
    comprobante.observaciones = typeof (objeto.comprobantes.observaciones_accesorias) === 'object' ? "" : objeto.comprobantes.observaciones_accesorias;
    total = objeto.comprobantes.total;
    total = parseFloat(total).toFixed(2);
    comprobante.total = '$' + total;
    comprobante.cliente = objeto.cuentas_personas_juridicas.razon_social;
    comprobante.codigo = objeto.comprobantes_codigos.alias;
    comprobante.prioridad = objeto.comprobantes_datos_emision?.detalle_emision || "";
    comprobante.cajas_por_tamanio = "";
    comprobante.excedidas = "";
    comprobante.movimiento = objeto.articulos?.nombre_reducido || "";
    comprobante.marcar_exc_deformadas = "";

    comprobante.item = esArray(objeto.comprobantes_valores_items).map(function (
      item,
      index
    ) {
      let valorItem = {};
      let valorArticulo = esArray(objeto.articulos).find(
        (element) => item.id_articulos === element.id
      );
      valorItem.articulo = valorArticulo.nombre_reducido;
      valorItem.id = item.id;
      valorItem.descripcion = item.descripcion;
      total = item.total;
      total = parseFloat(total).toFixed(2);
      valorItem.total = '$' + total;
      return valorItem;
    });

    return comprobante;
  });

  return arrayComprobantes;
};

const contarCajasPorTipo = (cajas) => {
  const conteo = {};
  esArray(cajas).forEach(caja => {
    const tipo = caja.id_tipo_cajas;
    conteo[tipo] = (conteo[tipo] || 0) + 1;
  });

  let resultado = '';
  for (const tipo in conteo) {
    resultado += `T${tipo}: ${conteo[tipo]} - `;
  }
  // Eliminar el último guion y espacio
  resultado = resultado.slice(0, -2);
  return resultado;
};

const contarCajasPorEstado = (cajas, estado) => {
  let cantidad = 0;
  esArray(cajas).forEach(caja => {
    if (caja[estado] === "1") {
      cantidad++;
    }
  });
  return cantidad;
};

export const actualizarEstadoComprobante = async (idComprobante, idEstado) => {
  const items = { "id": idComprobante, "id_comprobantes_estados": idEstado };
  const comprobantes = new datos("comprobantes");
  let response = [];
  response = comprobantes.insert(items);

  return response;
};

export const actualizarComprobante = async (idComprobante, fecha, idEstado, fechaTrabajo, total, codigoComprobante, itemsComprobante) => {
  // const items = { "id": idComprobante, "fecha_comprobante": fecha, "id_comprobantes_estados": idEstado };
  const items = {};

  // Verificar si cada variable contiene datos y agregarlas al objeto items si es así
  if (idComprobante) {
    items.id = idComprobante;
  }

  if (fecha) {
    items.fecha_comprobante = fecha;
  }

  if (idEstado) {
    items.id_comprobantes_estados = idEstado;
  }

  if (fechaTrabajo) {
    items.fecha_trabajo = fechaTrabajo;
  }

  if (codigoComprobante) {
    items.id_comprobantes_codigos = codigoComprobante;
  }


  if (total) {
    items.total = total;
  }
  const comprobantes_valores_items = itemsComprobante.map((item, index) => ({
    id: item.id,
    total_neto_bruto: item.importe.toString(),
    total_neto: item.importe.toString(),
    total: item.importe.toString(),

  }));

  const comprobantes = new datos("comprobantes");
  let response = [];
  response = comprobantes.insert(items);

  const comprobantesItems = new datos("comprobantes_valores_items");
  //let response = [];
  response = comprobantesItems.insert(comprobantes_valores_items);

  return response;
};


export const getComprobantesData = async (limit, offset, comprobante) => {

  const data = {
    limit: `${limit}`,
    offset: `${offset}`,
    criteria: comprobante,
  };

  const comprobantes = new datos("comprobantes");
  const response = await comprobantes.getComprobantes(data);
  const arrResponse = esArray(response.wsResponse.comprobantes);

  const gridviewsIds = JSON.parse(localStorage.getItem("siam-gridviews_ids")).gridviews_ids;
  const gridManipulabre = gridviewsIds?.filter(gridviews_id => gridviews_id.nombre === 'Comprobante');

  let arrayComprobantes = arrResponse.map(function (objeto, index) {
    let comprobante = {};
    let total;

    if (gridManipulabre.length > 0) {

      comprobante.tipo_moneda = `${objeto.tipo_moneda?.simbolo} (${objeto.tipo_moneda?.unidad_monetaria})`;

      comprobante.id = objeto.comprobantes.id;
      comprobante.tipo_moneda = comprobante.tipo_moneda.includes('undefined') ? '' : comprobante.tipo_moneda;

      const partesFecha = objeto.comprobantes.fecha_comprobante.split("-"); // Dividir la fecha en partes: año, mes, día
      const fechaFormateada = `${partesFecha[2]}/${partesFecha[1]}/${partesFecha[0]}`; // Formato: dd/mm/yyyy

      comprobante.fecha_comprobante = fechaFormateada;

      comprobante.id_cuenta = objeto.cuentas.id
      const partesFechaTrabajo = objeto.comprobantes.fecha_trabajo.split("-"); // Dividir la fecha en partes: año, mes, día
      const fechaFormateadaTrabajo = `${partesFechaTrabajo[2]}/${partesFechaTrabajo[1]}/${partesFechaTrabajo[0]}`
      comprobante.fecha_trabajo = fechaFormateadaTrabajo;

      comprobante.total = objeto.comprobantes.total;
      comprobante.numero_comprobante = objeto.comprobantes.numero_comprobante;
      comprobante.razon_social = objeto.cuentas_personas_juridicas.razon_social;
      comprobante.codigo = objeto.comprobantes_codigos.alias;
      comprobante.estado = objeto.comprobantes_estados.nombre;
      comprobante.observaciones = objeto.comprobantes.observaciones_accesorias;
      comprobante.id_codigo = objeto.comprobantes_codigos.id
      comprobante.item = esArray(objeto.comprobantes_valores_items).map(function (
        item,
        index
      ) {
        let valorItem = {};
        let valorArticulo = esArray(objeto.articulos).find(
          (element) => item.id_articulos === element.id
        );
        valorItem.articulo = valorArticulo.nombre_reducido;
        valorItem.id = item.id;
        valorItem.descripcion = item.descripcion;
        total = item.total;
        total = parseFloat(total).toFixed(2);
        valorItem.total = '$' + total;
        return valorItem;
      });


      comprobante.id_comprobantes_estado = objeto.comprobantes_estados.id

      comprobante.imagenes = objeto.imagenes

      total = objeto.comprobantes.total;
      total = parseFloat(total).toFixed(2);
      comprobante.total = '$' + total;

      //comprobante.ingreso = objeto.comprobantes_codigos.afecta_cuentas_corrientes === 'D' ? '$' + total : '';
      // comprobante.egreso = objeto.comprobantes_codigos.afecta_cuentas_corrientes === 'H' ? '$' + total : '';


    } else {

      const cantDeformadas = contarCajasPorEstado(objeto.cajas, "deformada");
      const cantExcedidas = contarCajasPorEstado(objeto.cajas, "excedida");
      const cantidadTipoCajas = contarCajasPorTipo(objeto.cajas)
      comprobante.observaciones = typeof (objeto.comprobantes.observaciones_accesorias) === 'object' ? "" : objeto.comprobantes.observaciones_accesorias;
      total = objeto.comprobantes.total;
      total = parseFloat(total).toFixed(2);
      comprobante.total = '$' + total;
      comprobante.id = objeto.comprobantes.id;
      comprobante.fecha = objeto.comprobantes.fecha_comprobante;
      comprobante.cliente = objeto.cuentas_personas_juridicas.razon_social;
      comprobante.codigo = objeto.comprobantes_codigos.alias;
      comprobante.prioridad = objeto.comprobantes_datos_emision?.detalle_emision || "";
      comprobante.cajas_por_tamanio = cantidadTipoCajas;
      comprobante.excedidas = cantExcedidas;
      comprobante.movimiento = objeto.articulos?.nombre_reducido || "";
      comprobante.marcar_exc_deformadas = cantDeformadas;
      comprobante.extra_data = objeto.comprobantes_extra_data || false;

      comprobante.item = esArray(objeto.comprobantes_valores_items).map(function (
        item,
        index
      ) {
        let valorItem = {};
        let valorArticulo = esArray(objeto.articulos).find(
          (element) => item.id_articulos === element.id
        );
        valorItem.articulo = valorArticulo.nombre_reducido;
        valorItem.id = item.id;
        valorItem.descripcion = item.descripcion;
        total = item.total;
        total = parseFloat(total).toFixed(2);
        valorItem.total = '$' + total;
        return valorItem;
      });

    }
    return comprobante;
  });

  let respuesta = {
    data: arrayComprobantes,
    total_records: response.wsResponse.total,
  };

  return respuesta;
};

export const getFichaData = async (comprobante) => {
  const data = {
    criteria: comprobante,
  };

  const comprobantes = new datos("comprobantes");
  const response = await comprobantes.getComprobantes(data);
  const arrResponse = esArray(response.wsResponse.comprobantes);
  const gridviewsIds = JSON.parse(localStorage.getItem("siam-gridviews_ids")).gridviews_ids;
  const gridManipulabre = gridviewsIds?.filter(gridviews_id => gridviews_id.nombre === 'Comprobante');

  let arrayComprobantes = arrResponse.map(function (objeto, index) {
    let comprobante = {};
    let total;
    if (gridManipulabre.length > 0) {

      comprobante.moneda = `${objeto?.tipo_moneda?.simbolo}`;

      const partesFecha = objeto.comprobantes.fecha_comprobante.split("-"); // Dividir la fecha en partes: año, mes, día
      const fechaFormateada = `${partesFecha[2]}/${partesFecha[1]}/${partesFecha[0]}`; // Formato: dd/mm/yyyy

      comprobante.fecha_comprobante = fechaFormateada;


      const partesFechaTrabajo = objeto.comprobantes.fecha_comprobante.split("-"); // Dividir la fecha en partes: año, mes, día
      const fechaFormateadaTrabajo = `${partesFechaTrabajo[2]}/${partesFechaTrabajo[1]}/${partesFechaTrabajo[0]}`
      comprobante.fecha_trabajo = fechaFormateadaTrabajo;

      comprobante.total = objeto.comprobantes.total;
      comprobante.numero_comprobante = objeto.comprobantes.numero_comprobante;
      comprobante.codigo = objeto.comprobantes_codigos.descripcion;
      comprobante.estado = objeto.comprobantes_estados.nombre;
      comprobante.observaciones = objeto.comprobantes.observaciones_accesorias;
      if (objeto?.comprobantes_extra_data?.extra_data) {

        let comprobantesDataExtra = objeto?.comprobantes_extra_data?.extra_data.replace(/'/g, '"')
        comprobantesDataExtra = JSON.parse(comprobantesDataExtra);
        comprobante.extraData = comprobantesDataExtra.detalle_externo;
      }

      comprobante.item = esArray(objeto.comprobantes_valores_items).map(function (
        item,
        index
      ) {
        let valorItem = {};
        let valorArticulo = esArray(objeto.articulos).find(
          (element) => item.id_articulos === element.id
        );
        valorItem.articulo = valorArticulo.nombre_reducido;
        valorItem.id = item.id;
        valorItem.descripcion = item.descripcion;
        total = item.total;
        total = parseFloat(total).toFixed(2);
        valorItem.total = '$' + total;
        return valorItem;
      });

      total = objeto.comprobantes.total;
      total = parseFloat(total).toFixed(2);
      comprobante.total = '$' + total;
      comprobante.totalInt = total;

      comprobante.ingreso = objeto.comprobantes_codigos.afecta_cuentas_corrientes === 'C' ? comprobante.moneda + ' ' + total : '';
      comprobante.egreso = objeto.comprobantes_codigos.afecta_cuentas_corrientes === 'D' ? comprobante.moneda + ' ' + total : '';
      comprobante.estado = objeto.comprobantes_estados.nombre;
      comprobante.id_codigo = objeto.comprobantes_codigos.id;
      comprobante.tachar = true;
      comprobante.pendiente_cuenta_corriente = true;
      if (objeto.comprobantes_codigos.afecta_cuentas_corrientes === 'N' || objeto.comprobantes_estados.pendiente_cuenta_corriente === '0') {
        comprobante.tachar = false;
        comprobante.pendiente_cuenta_corriente = false;
      }
    }
    return comprobante;
  });

  arrayComprobantes.forEach(comprobante => {
    const partesFecha = comprobante.fecha_trabajo.split("/");
    comprobante.fecha_trabajo = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]);
  });

  // Ordenar el array de comprobantes por fecha de trabajo
  arrayComprobantes.sort((a, b) => a.fecha_trabajo - b.fecha_trabajo);


  let respuesta = {
    data: arrayComprobantes,
    total_records: response.wsResponse.total,
  };

  console.log("respuesta",respuesta)
  return respuesta;
};

export const comprobantesPrintData = async (data) => {
  // Función para decodificar la cadena en Base64
  const decodeBase64 = (data) => {
    try {
      const decodedString = atob(data);
      const decodedHTML = decodeURIComponent(escape(decodedString));
      return decodedHTML;
    } catch (error) {
      console.error("Error decoding Base64:", error);
    }
  };

  const comprobantes = new datos("comprobantes");
  const response = await comprobantes.comprobantesPrint(data);

  if (response.status === 'true') {
    return decodeBase64(response.wsResponse);
  }

  return response;
};

export const getComprobantesEstados = async () => {

  const objectCodigoCajas = new datos("comprobantes_estados");
  objectCodigoCajas.addCondition("comprobantes_estados", "id", 0, ">");
  const response = await objectCodigoCajas.get();
  const codigoCajas = response.wsResponse.comprobantes_estados;
  const arr = esArray(codigoCajas);

  return arr;
};




export const deleteComprobantes = async (id) => {

  let status = '';

  await Swal.fire({
    title: "Esta seguro de que desea eliminar este comprobante?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const comprobantes = new datos("comprobantes");
      const datosComprobante = { id_comprobantes: id }

      const response = await comprobantes.comprobantesDelete(datosComprobante);
      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El comprobante ha sido eliminado exitosamente",
          "success"
        );
        status = true
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message
        });
        status = false
      }
    }
  });
  return status

};

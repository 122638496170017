import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Container} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { SelectorCuentasJuridicasComponent } from "../../components/forms/customRenders/selectorCuentasJuridicas";
import { SelectorServiciosComponent } from "../../components/forms/customRenders/selectorServicios";
import { schemaFiltroServiciosFullForm } from "../../components/forms/schemaFiltroServiciosFullForm";
import { uiSchemaFiltroServiciosFullForm } from "../../components/forms/uiSchemaFiltroServiciosFullForm";
import { DateFormComponent } from "src/forms/customRenders/dateForm";
import { Buttons } from "src/general_components/Buttons";
import { postCuentasServicios } from "../helpers/CuentasServicios";

const localize = require("ajv-i18n");

export const CuentasServiciosForm = (props) => {
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [withErrors, setWithErrors] = useState(false);

   //FIXME VER QUE HACER CON ESTA VARIABLE
    // eslint-disable-next-line no-unused-vars
  const [updater, setUpdater] = useState(Date.now());
  
  const initialDataForm = {
    id: 0,
    id_cuentas: 0,
    id_articulos: 0,
    activo: false,
    cantidad: undefined,
    importe: undefined,
    activoFechaInicio: false,
    fechaActivoInicio: undefined,
    activoFechaFin: false,
    fechaActivoFin: undefined,
    descripcion: "",
    selectedCuentaJuridica: "",
    selectedServicio: "",
  };

  const [dataForm, setDataForm] = useState({
    id: props?.datos[0]?.id || 0,
    id_cuentas: props?.datos[0]?.id_cuentas || 0,
    id_articulos: props?.datos[0]?.id_articulos || 0,
    activo: props?.datos[0]?.activo === "1" ? true : false,
    cantidad: Number(props?.datos[0]?.cantidad) || 0,
    importe: Number(props?.datos[0]?.importe) || 0,
    activoFechaInicio: props?.datos[0]?.tiene_fecha_inicio === "1" ? true : false,
    fechaActivoInicio: typeof props?.datos[0]?.fecha_inicio === 'object'? null : props?.datos[0]?.fecha_inicio ,
    activoFechaFin: props?.datos[0]?.tiene_fecha_finalizacion === "1" ? true : false,
    fechaActivoFin: typeof props?.datos[0]?.fecha_finalizacion === 'object'? null: props?.datos[0]?.fecha_finalizacion,
    descripcion: typeof props?.datos[0]?.descripcion === 'object'? "" : props?.datos[0]?.descripcion ,
    selectedCuentaJuridica: { id_cuentas: props?.datos[0]?.id_cuentas  },
    selectedServicio: { id: props?.datos[0]?.id_articulos },
  });


  console.log("dataFOrm",dataForm)


  const onChange = (errors, data) => {
    localize.es(errors);
    console.log("errors",errors)
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setDataForm(data);

    return { errors, data };
  };

   useEffect(() => {
    setUpdater(Date.now());
  }, [props.datos]);

  const guardar = () => {
    if (withErrors) {
      setMessage("Error en el formulario. Revise los Campos");
      setSeverity("error");
      setSnackbarVisible(true);
    } else {
      const servicio = {
        id: dataForm.id,
        id_cuentas: dataForm.selectedCuentaJuridica.id_cuentas,
        id_articulos: dataForm.selectedServicio.id,
        activo: dataForm.activo ? "1" : "0",
        cantidad: dataForm.cantidad.toString(),
        tiene_fecha_inicio: dataForm.activoFechaInicio ? "1" : "0",
        fecha_inicio: dataForm.fechaActivoInicio,
        tiene_fecha_finalizacion: dataForm.activoFechaFin ? "1" : "0",
        fecha_finalizacion: dataForm.fechaActivoFin,
        importe: dataForm.importe.toString(),
        descripcion: dataForm.descripcion,
      };

      postCuentasServicios(servicio)
        .then((response) => {
          if (response.status === "true") {
            setMessage("Servicio agregado exitosamente");
            setSeverity("success");
            setSnackbarVisible(true);
          } else {
            setMessage("Hubo un error al procesar los datos");
            setSeverity("error");
            setSnackbarVisible(true);
          }
        })
        .then(() => {
          props.update();
          props.close();
        });

      //}
    }
  };

  return (
    <Container maxWidth="sm">
      <JsonForms
        onChange={({ errors, data }) => onChange && onChange(errors, data)}
        schema={schemaFiltroServiciosFullForm}
        uischema={uiSchemaFiltroServiciosFullForm}
        renderers={[
          ...materialRenderers,
          SelectorCuentasJuridicasComponent,
          SelectorServiciosComponent,
          DateFormComponent,
        ]}
        data={dataForm}
        cells={materialCells}
      />
      <Buttons
        labelButtonSave={"Guardar"}
        onClickSubmit={() => {
          guardar();
        }}
        buttonCerrar={() => {
          setDataForm({ initialDataForm });
          props.close();
        }}
      />

      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </Container>
  );
};

import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";


export const getComprobantesEstados = async (id_comprobantes_codigo) => {
  const comprobantes_estados = new datos("comprobantes_estados");
  comprobantes_estados.addCondition("comprobantes_estados", "id", 0, ">");
  comprobantes_estados.addCondition("comprobantes_estados", "id_comprobantes_codigos", id_comprobantes_codigo, "=");
  const response = await comprobantes_estados.get();
  return esArray(response.wsResponse.comprobantes_estados);
};

export const getComprobantesEstadosPaginated = async (limit, offset) => {
  const comprobantes_estados = new datos("comprobantes_estados");
  comprobantes_estados.addCondition("comprobantes_estados", "id", 0, ">");
  comprobantes_estados.join("comprobantes_codigos", "id", "id_comprobantes_codigos", " = ");
  comprobantes_estados.addLimitCondition(limit, offset);
  comprobantes_estados.addOrderCondition("comprobantes_estados", "id_comprobantes_codigos", "ASC")
  const response = await comprobantes_estados.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  const data = esArray(response.wsResponse.comprobantes_estados);
  const updatedData = data.map(item => {
    const alias = item.relations.comprobantes_codigos.id + " - " + item.relations.comprobantes_codigos.descripcion;
    const pendiente = item.pendiente_cuenta_corriente === '1' ? "Si" : "No";
    return {
      ...item,
      pendiente: pendiente,
      alias_comprobantes_codigos: alias
    };
  });

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: updatedData,
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const deleteComprobanteEstado = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este Comprobante Estado?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const comprobantes_estados = new datos("comprobantes_estados");
      let data = {
        id: id,
      };
      const response = await comprobantes_estados.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El Comprobante Estado ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};
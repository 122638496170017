import { useState, useEffect } from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import { Grid } from "@mui/material";
import { getCuentasLocalizaciones, getCuentasLocalizacionesPaginated, deleteCuentasLocalizaciones } from "../helpers/CuentasLocalizacionesData";
import { Call } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponentRC";
import { FormularioDinamicoModelos } from "src/general_components/FormularioDinamicoModelos";
import { getTablaEstructura, getDataSelect } from "src/hooks/structureTable";
import { CuentasTelefonosList } from "./CuentasTelefonosList2";
import { useCuentasContext } from "src/context/CuentasContext";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { TipoContenidosForm } from "./TipoContenidosForm";
import { LocalidadSelector } from "src/general_components/SelectLocalidades";
import Swal from "sweetalert2";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";

export const CuentasLocalizacionesList = (data) => {
    const {
        setSelectedLocalizacion, setLocalizacionesEdit,
        updateTelefonos, setUpdateTelefonos, saveData,snackbarVisible,message,setSnackbarVisible,severity
    } = useCuentasContext();



    const [createButton, setCreateButton] = useState(false);
    const [dataEdit, setDataEdit] = useState([]);
    const [modalState, setModalState] = useState(false);
    const [buttonEnviar, setButtonEnviar] = useState(false);
    const [modalName, setModalName] = useState("Localizaciones");
    const [dialogChild, setDialogChild] = useState(null);
    const listName = "Cuentas Localizaciones";
    const columns = [
        { field: "id", headerName: "ID", flex: 0.3 },
        { field: "descripcion", headerName: "Descripcion", flex: 1 },
        { field: "domicilio", headerName: "Domicilio", flex: 0.5 },
        { field: "localidad", headerName: "Localidad", flex: 0.5 },
        {
            field: "telefonos",
            headerName: "Telefonos",
            type: "actions",
            flex: 0.3,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Localizaciones y Telefonos">
                            <span>
                                <Call color="info" />
                            </span>
                        </Tooltip>
                    }
                    onClick={() => {
                        setSelectedLocalizacion(params.row.id);
                        setDialogChild(<CuentasTelefonosList handleClose={setModalState} id_cuentas={data.params.id_cuentas} id_cuentas_localizaciones={params.row.id} />);
                        setModalName("Telefonos");
                        setModalState(!modalState);
                        setButtonEnviar(false);
                    }}
                    label="Telefonos"
                />,
            ],
        },
    ];
    const [actualizarListado, setActualizarListado] = useState(false);

    let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));
    // Verificar si la variable es igual a "27"
    if (parametros_proyecto.id === "27") {
        // Si es igual a "27", agregar la columna "Tipo Contenidos"
        columns.push({
            field: "tipoContenidos",
            headerName: "Tipo Contenidos",
            type: "actions",
            flex: 0.3,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Tipo Contenidos">
                            <span>
                                <LocalOfferIcon color="primary" />
                            </span>
                        </Tooltip>
                    }
                    onClick={() => {
                        setButtonEnviar(false);
                        setSelectedLocalizacion(params.row);
                        setDialogChild(<TipoContenidosForm handleClose={setModalState} setActualizarListado={setActualizarListado}/>);
                        setModalName("Tipo Contenidos");
                        setModalState(!modalState);
                        console.log(params.row, '//as')
                    }}
                    label="Tipo Contenidos"
                />,
            ],
        });
    }
    const modelo = "cuentas_localizaciones"
    const valuesDefaultForm = { id_cuentas: data.params.id_cuentas };
    const customComponents = {
        id_localidad: {
            component: LocalidadSelector,
        }
    };

    const [formData, setFormData] = useState({});

    const [options, setOptions] = useState({
        cols: columns,
        get: getCuentasLocalizaciones,
        getPaginated: getCuentasLocalizacionesPaginated,
        pageSize: [5, 10, 25],
        edit: true,
        delete: deleteCuentasLocalizaciones,
        limit: 50,
        filter: true,
        exportData: true,
        server: true,
        idElementData: data.params.id_cuentas,
        refresh: actualizarListado,

        //DATOS PARA EL FORMULARIO
        createButton: createButton,
        setCreateButton: setCreateButton,
        setDataEdit: setDataEdit,
    });

    useEffect(() => {
        if (actualizarListado) {
            setOptions({
                ...options,
                refresh: true,
            });
            setActualizarListado(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualizarListado]);

    const combinarObjetos = (objeto1, objeto2) => {
        const resultado = { ...objeto1 };

        for (const key in objeto2) {
            if (resultado.hasOwnProperty(key)) {
                resultado[key] = { ...resultado[key], ...objeto2[key] };
            } else {
                resultado[key] = objeto2[key];
            }
        }

        return resultado;
    }

    useEffect(() => {

        const filtrarCampos = async (formDataResponse) => {
            const camposFiltrados = {};
            for (const fieldName in formDataResponse) {
                if (!valuesDefaultForm[fieldName] && !customComponents[fieldName] && fieldName.includes('id_')) {
                    const palabraExtraida = fieldName.replace('id_', ''); // Cortar "id_"
                    const valorLocalStorage = JSON.parse(localStorage.getItem("siam-" + palabraExtraida))?.[palabraExtraida];

                    if (valorLocalStorage) {
                        const opcionesFiltradas = valorLocalStorage.map(({ id, nombre, descripcion, label, detalle }) => ({
                            id,
                            nombre: nombre || descripcion || detalle || label || 'Sin Nombre', // Prioridad: nombre > descripcion > label
                        }));

                        camposFiltrados[fieldName] = {
                            ...formDataResponse[fieldName],
                            options: opcionesFiltradas,
                        };
                    } else {
                        const datosSelect = await getDataSelect(palabraExtraida);

                        const opcionesFiltradas = datosSelect.map(({ id, nombre, descripcion, label, detalle }) => ({
                            id,
                            nombre: nombre || descripcion || detalle || label || 'Sin Nombre', // Prioridad: nombre > descripcion > label
                        }));

                        camposFiltrados[fieldName] = {
                            ...formDataResponse[fieldName],
                            options: opcionesFiltradas,
                        };
                    }
                }
            }

            // Combina formData con camposFiltrados
            let camposFinal = { ...formDataResponse, ...camposFiltrados };

            if (camposFinal.id) {
                delete camposFinal.id;
            }

            // Eliminar propiedades de camposFinal si coinciden con valuesDefaultForm
            Object.keys(valuesDefaultForm).forEach((key) => {
                delete camposFinal[key];
            });
            return camposFinal;
        };
        const getEstructuraTabla = async () => {
            // setIsLoadingData(true);
            let response = await getTablaEstructura(modelo);

            if (response.status === "true") {
                const formDataResponse = response.wsResponse;
                const camposFiltrados = await filtrarCampos(formDataResponse);
                const resultado = combinarObjetos(camposFiltrados, customComponents);

                setFormData(resultado);
            }

        };
        getEstructuraTabla();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
    //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
    //EL BUTTONMETADATOS SOLO ES PARA ESTE CASO, VERIFICAR CADA OCASION.
    if (
        createButton === false &&
        dataEdit.id !== undefined
    ) {
        setDataEdit([]);
    }

    return (
        <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
            <>
                <ListTablePaginated options={options} />
                <FormularioDinamicoModelos
                    data={dataEdit}
                    createButton={createButton}
                    listName={listName}
                    setCreateButton={setCreateButton}
                    setOptions={setOptions}
                    options={options}
                    formData={formData}
                    modelo={modelo}
                    dataDefault={valuesDefaultForm}
                />
                 <SnackbarMessenger
              open={snackbarVisible}
              message={message}
              severity={severity}
              close={() => {
                setSnackbarVisible(false);
              }}
            />
                <SimpleDialogComponent
                    open={modalState}
                    modalName={modalName}
                    child={dialogChild}
                    handleClose={() => {
                        if (updateTelefonos) {
                            Swal.fire({
                                title: 'Atencion!!!',
                                text: "Si sale ahora ,la/s localizacion/es que se agreguen,editen o eliminen no impactara en la base de datos.",
                                icon: 'warning',
                                customClass: {
                                    container: 'my-swal-delete'
                                },
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Guardar y Cerrar',
                                cancelButtonText: 'Cerrar Sin Guardar'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    saveData();
                                    setUpdateTelefonos(false);
                                    setModalState(false);
                                } else {
                                    setUpdateTelefonos(false);
                                    setModalState(false);
                                }
                            })
                        } else {
                            setModalState(false);
                            setUpdateTelefonos(false);
                            if (modalState) {
                                setLocalizacionesEdit(undefined);
                            }
                        }

                    }}
                    buttonEnviar={buttonEnviar}
                    buttonCancelar={true}
                />
            </>
        </Grid>
    );
};



export const uiSchemaComprobantesEdit = {
    type: "VerticalLayout",
    elements: [
        {
            type: "HorizontalLayout",
            elements: [
                {
                    type: "Control",
                    scope: "#/properties/cliente",
                },
                {
                    type: "Control",
                    scope: "#/properties/comprobante_codigo",
                },
                {
                    type: "Control",
                    scope: "#/properties/comprobante_id_estado",
                },
            ],
        },
        {
            type: "HorizontalLayout",
            elements: [
                {
                    type: "Control",
                    scope: "#/properties/fecha_creacion",
                },
                {
                    type: "Control",
                    scope: "#/properties/fecha_trabajo",
                },
            ],
        },
    ],
};

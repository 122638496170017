export const LicenseInfoDataGrid = {
    license: "80014de3201f4c8353bfecb10e3ba4bbTz03MzcyOSxFPTE3MjUwMzA1MzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=" // Data in https://materia.atlassian.net/wiki/spaces/D/pages/1376471/Proyecto+DLyS
}

//Clave utilizada para el consumo de la API de Google Drive
export const accessKey = "AIzaSyDZ6hH50ScBqGoOQOMUD2AVNKpPfkBaSOI" // Data in https://materia.atlassian.net/wiki/spaces/D/pages/1376471/Proyecto+DLyS

if (localStorage.getItem("clientData") === null) {
    localStorage.setItem(
        "clientData",
        JSON.stringify({
            owner_code: "",
            dominio: "",
            api_pass: "",
        })
    );
}

export const configS3 = {
    bucketName: JSON.parse(localStorage.getItem("siam-params-images"))?.BUCKET_NAME?.value ? JSON.parse(localStorage.getItem("siam-params-images"))?.BUCKET_NAME?.value : '',
    region: 'us-east-1', // Región global de S3 
    accessKeyId: JSON.parse(localStorage.getItem("siam-params-images"))?.PUBLIC_KEY?.value ? JSON.parse(localStorage.getItem("siam-params-images"))?.PUBLIC_KEY?.value : '',
    secretAccessKey: JSON.parse(localStorage.getItem("siam-params-images"))?.PRIVATE_KEY?.value ? JSON.parse(localStorage.getItem("siam-params-images"))?.PRIVATE_KEY?.value : ''
};

export const config = {
    credentials: {
        authorization: {
            owner_code: JSON.parse(localStorage.getItem("clientData")).owner_code,
            id_user: "1",
            language: "es",
        },
    },
    authToken: localStorage.getItem("authToken"),
};

export const appName = JSON.parse(localStorage.getItem("clientData")).owner_descripcion

export const id_proyecto = "30"

export const parametros_proyecto = [
    { id: "27", nombre: "DLyS", usar_video_login: true, url_video: "src/video/D-Box.mp4", usar_imagen_login: false, url_imagen: false },
    {
        id: "30", nombre: "Floripa", usar_video_login: false, url_video: false, usar_imagen_login: true,
        url_imagen: "https://siam-srv.materiasistemas.com.ar/static_content/img/FLORIPA_BEACH_REACT_LOGO.png", codigo_iso_moneda: "BRL", titulo_articulos: "Propiedades",
        key_encript: "a1b2c3d4e5f678901234567890abcdefa1b2c3d4e5f678901234567890abcdef"
    },
]
export const getMenuModules = () => {
    const menuArr = []
    let menuModules = JSON.parse(localStorage.getItem("siam-module-menu"));
    menuModules = !(menuModules instanceof Array) ? [menuModules] : menuModules
    menuModules.forEach((modulo) => {
        for (let index = 0; index < modulo?.menu?.length; index++) {
            if (JSON.stringify(modulo.menu[index].name) === '{}') {
                modulo.menu[index].name = modulo.menu[index].description;
            }
            menuArr.push(modulo.menu[index]);
        }
    })

    return menuArr.length > 0 ? 
        menuArr.sort((a,b) => (a.level > b.level) ? 1 : ((b.level > a.level) ? -1 : 0)) : 
        false;
}

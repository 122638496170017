import { requestService } from "./requestService";

class datos {
  constructor(modelo) {
    this.modelo = modelo;
    this.custom_query = "true";
    this.relations = "true";
    this.attributes = {};
  }

  addCondition(table, column, value, operator = " = ") {
    let indice = Object.keys(this.attributes).length;
    value = operator === "in" ? "(" + value + ")" : value;
    value = operator === "not in" ? "(" + value + ")" : value;
    this.attributes = {
      ...this.attributes,
      [`attribute_${indice}`]: {
        criteria: "addCondition",
        condition: `${table}.${column} ${operator} ${value}`,
      },
    };
  }
  addLikeCondition(table, column, value) {
    /*let operacion = {
      criteria: "addCondition",
      condition: `${table}.${column} like '%${value}'%`,
    };
    this.attributes = { ...this.attributes, operacion };*/
    let likeValue = "'%" + value + "%'";
    this.addCondition(table, column, likeValue, "like")
  }

  addLimitCondition(limit, offset) {
    let indice = Object.keys(this.attributes).length;

    this.attributes = {
      ...this.attributes,
      [`attribute_${indice}`]: { criteria: "limit", limit: `${limit}` },
    };

    this.attributes = {
      ...this.attributes,
      [`attribute_${indice + 1}`]: { criteria: "offset", offset: `${offset}` },
    };
  }
  addOrderCondition(table, column, order) {
    let indice = Object.keys(this.attributes).length;

    this.attributes = {
      ...this.attributes,
      [`attribute_${indice}`]:
      {
        criteria: "order",
        column: `${table}.${column}`,
        order: `${order}`
      },
    };
  }
  /*addIn(column,props,condicion = ' and '){

    }
    addNotIn(){

    }
    addBetween(){

    }*/
  join(table, columnD, columnO, operator = " = ") {
    let indice = Object.keys(this.attributes).length;
    const join =
      columnO.indexOf(".") !== -1 ? columnO : this.modelo + "." + columnO;
    this.attributes = {
      ...this.attributes,
      [`attribute_${indice}`]: {
        criteria: "join",
        condition: `${table}.${columnD} ${operator} ${join}`,
        table: table,
      },
    };
  }

  /*
    -JOIN CON DOS TABLAS POR SEPARADO (innerJoinTables)-

    table: PRIMER TABLA A PASARSE TABLA A LA QUE SE LE VA A HACER EL JOIN
    secondTable: SEGUNDA TABLA A PASARSE
    columnD: COLUMNA DE LA PRIMERA TABLA
    columnO: COLUMNA DE LA SEGUNDA TABLA
    operator: OPERADOR DE LA CONDICION
  */
  innerJoinTables(table, secondTable, columnD, columnO, operator = " = ") {
    let indice = Object.keys(this.attributes).length;
    this.attributes = {
      ...this.attributes,
      [`attribute_${indice}`]: {
        criteria: "join",
        condition: `${table}.${columnD} ${operator} ${secondTable}.${columnO} `,
        table: table,
      },
    };
  }

  get() {
    const propiedades = {
      services: "get",
      structure: {
        params: {
          model: this.modelo,
          attributes: this.attributes,
          relations: this.relations,
          custom_query: this.custom_query,
        },
      },
    };

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  insert(data) {
    const propiedades = {
      services: "insert",
      structure: {
        model: this.modelo,
        attributes: data,
      },
    };

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  createUbicaciones(data) {
    const propiedades = { services: "ubicaciones", structure: data };

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  cajasUbicacionesSetProcesosVarios(data, cajas, datos, cajasUbicadas, ubicacionTemporal, ajuste, baja, definitivo,
    detalleConcat, contactoSelect, direccionSelect, nodoExtra) {
    var sector = data.idSector ? data.idSector : "";
    var modulo = data.idModulo ? data.idModulo : "";
    var estante = data.idEstante ? data.idEstante : "";
    var posicion = data.idPosicion ? data.idPosicion : "";
    var estibe = data.idEstiba ? data.idEstiba : "";
    var profundidad = data.idProfundidad ? data.idProfundidad : "";
    var valorMudanza = datos.datos.valorMudanza;
    var valorUbicacionTemporal = ubicacionTemporal ? ubicacionTemporal : '0';
    var valorRetiro = datos.datos.valorRetiroCaja;
    var valorPreferencial = datos.datos.ret_preferencial;
    var valorUrgente = datos.datos.ret_urgente;
    var valorDefinitivo = datos.datos.ret_definitivo;
    var valorDevolucionCaja = datos.datos.valorDevolucionCaja;
    var valorAjuste = ajuste ? '1' : '0';
    var tipo = data.tipo ? data.tipo : "";
    var valorBaja = datos.datos.baja;
    var detalle = detalleConcat ? detalleConcat : '';
    var contacto = contactoSelect ? contactoSelect : '';
    var direccion = direccionSelect ? direccionSelect : '';
    var valorAlojamiento = "0";
    var valorUbicacionInicial = datos.datos.ubicacion_caja;
    var id_tipo_ubicacion = data.id_tipo_ubicaciones ? data.id_tipo_ubicaciones : "0";
    var id_usuario = JSON.parse(localStorage.getItem("siam-user-data")).id
    const propiedades = {
      services: "cajasUbicacionesSet",
      structure: {
        mudanza: `${valorMudanza}`,
        id_owner_localizaciones: `${data.idDep}`,
        cajas: cajas,
        ubicacion_temporal: `${valorUbicacionTemporal}`,
        retiro_caja: `${valorRetiro}`,
        ret_preferencial: `${valorPreferencial}`,
        ret_urgente: `${valorUrgente}`,
        ret_definitivo: `${valorDefinitivo}`,
        devolucion_caja: `${valorDevolucionCaja}`,
        cajas_ubicaciones_cuentas_contactos: `${contacto}`,
        cajas_ubicaciones_cuantas_localizaciones: `${direccion}`,
        ajuste: `${valorAjuste}`,
        detalle: `${detalle}`,
        baja: `${valorBaja}`,
        alojamiento: `${valorAlojamiento}`,
        ubicacion_inicial: `${valorUbicacionInicial}`,
        id_usuario: `${id_usuario}`,

        matriz: {
          sector: `${sector}`,
          modulo: `${modulo}`,
          estante: `${estante}`,
          posicion: `${posicion}`,
          estibe: `${estibe}`,
          profundidad: `${profundidad}`,
          id_tipo_cajas: `${tipo}`,
          id_tipo_ubicacion: `${id_tipo_ubicacion}`,
        },
      },
    };
    // Verifica si nodoExtra no está vacío y es un objeto
    if (nodoExtra) {
      // Agrega las propiedades adicionales a la estructura propiedades
      propiedades.structure.comprobantes_extra_data = nodoExtra.comprobantes_extra_data;
    }


    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  cajasUbicacionesSet(data, cajas, datos, cajasUbicadas, ubicacionTemporal, ajuste, baja, definitivo) {
    var sector = data.idSector ? data.idSector : "";
    var modulo = data.idModulo ? data.idModulo : "";
    var estante = data.idEstante ? data.idEstante : "";
    var posicion = data.idPosicion ? data.idPosicion : "";
    var estibe = data.idEstiba ? data.idEstiba : "";
    var profundidad = data.idProfundidad ? data.idProfundidad : "";
    var valorMudanza = datos.datos.valorMudanza;
    var valorUbicacionTemporal = ubicacionTemporal ? ubicacionTemporal : '0';
    var valorRetiro = datos.datos.valorRetiroCaja;
    var valorPreferencial = datos.datos.ret_preferencial;
    var valorUrgente = datos.datos.ret_urgente;
    var valorDefinitivo = definitivo ? '1' : '0';
    var valorDevolucionCaja = datos.datos.valorDevolucionCaja;
    var valorAjuste = ajuste ? '1' : '0';
    var tipo = data.tipo ? data.tipo : "";
    var valorBaja = baja ? '1' : '0';

    const propiedades = {
      services: "cajasUbicacionesSet",
      structure: {
        mudanza: `${valorMudanza}`,
        id_owner_localizaciones: `${data.idDep}`,
        cajas: cajas,
        ubicacion_temporal: `${valorUbicacionTemporal}`,
        retiro_caja: `${valorRetiro}`,
        ret_preferencial: `${valorPreferencial}`,
        ret_urgente: `${valorUrgente}`,
        ret_definitivo: `${valorDefinitivo}`,
        devolucion_caja: `${valorDevolucionCaja}`,
        ajuste: `${valorAjuste}`,
        baja: `${valorBaja}`,
        matriz: {
          sector: `${sector}`,
          modulo: `${modulo}`,
          estante: `${estante}`,
          posicion: `${posicion}`,
          estibe: `${estibe}`,
          profundidad: `${profundidad}`,
          id_tipo_cajas: `${tipo}`
        },
      },
    };

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  getCuentasGenericas() {

    const propiedades = {
      'services': 'getCuentaGenerica', 'structure': {

      }
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  getLoadData() {

    const propiedades = {
      services: 'getLoadData',
      structure: {
        models: [
          "alicuotas_iva",                                 // No responde
          "articulos_tipo",                                   // 2 records
          // "caracteristicas_articulos",                     // 0 records
          "categoria_cuentas",                                // 6 records
          "categorias",                                       // 16 records
          "comprobantes_tipo",
          "comprobantes_estados",
          "comprobantes_codigos",
          "codigo_caja",                                      // 1 record
          // "columnas_comprobantes",                         // No responde
          // "comprobantes_afip",                             // 80 records
          // "condicion_venta",                                  // 3 records
          // "configuracion_articulos",                          // 3 records
          // "configuracion_operaciones_financieras",            // 6 records
          "cuentas",
          "cuentas_personas_juridicas",                       // 6 records 
          "cuentas_localizaciones_tipo_contenidos",
          // "cuentas_localizaciones",
          // "departamento",
          "documento_tipo",
          "estados_ubicaciones",                              // 38 records
          // "ejercicios_contables",                             // 1 record
          "estado_civil",                                     // 5 records
          "estados_cajas",
          "genero",
          "gridviews_ids",
          "gridviews_atributos",
          // "impuestos",                                        // 4 records
          "impuestos_situaciones",
          // "maestros_bancos",                               // 78 records
          // "localidad",
          // "metadatos",
          "modulos",                                          // 11 records
          "modulos_instalados",                               // 11 records
          // "numeradores",                                      // 10 records
          "pais",                                             // 1 record
          "parametros_cuentas",                               // 3 records
          "params",                                        // 115 records
          "perfiles",                                         // 9 records
          // "plan_de_cuentas",                               // 1448 records
          "provincia",
          "proposito_calculo_precios",
          "puntos_venta",                                     // 4 records
          // "roles",                                            // 11 records
          // "rutas",                                            // 0 records
          "owner_localizaciones",
          "terminal",
          "tipo_cajas",                             // 4 records pero el tiempo de respuesta de > 3 seg
          // "tipo_calculo", // 2 records pero el tiempo de respuesta de > 2 seg
          "tipo_cambio",                                      // 0 records
          // "tipo_cheque",                                      // 4 records
          // "tipo_comercializacion",                         // No responde
          "tipo_contenidos",
          // "tipo_cuenta_contable",                             // 2 records
          "tipo_cuentas",                                     // 0 records
          // "tipo_licencia",                                    // 2 records
          "tipo_localizacion",                                // 3 records
          "tipo_moneda",                                      // 1 record
          // "tipo_movimiento",                               // No responde
          // "tipo_oferta",                                      // 3 records
          "tipo_parametro",                                   // 2 records
          // "tipo_relacion_cuentas",                            // 0 records
          "tipo_servicios_internet",                          // 4 records
          "tipo_telefonos",                                   // 3 records
          "tipo_ubicaciones"
          // "tipo_valores",                                     // 8 records
          // "unidades",                                         // 3 records
          // "zonas",                                            // 0 records
        ]
      }
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }



  getCajasDisponibles(data, id_cuentas_localizaciones, datos, cajasUbicadas, limit, offset) {
    const sector = data.idSector ? data.idSector : "";
    const modulo = data.idModulo ? data.idModulo : "";
    const estante = data.idEstante ? data.idEstante : "";
    const posicion = data.idPosicion ? data.idPosicion : "";
    const estibe = data.idEstiba ? data.idEstiba : "";
    const profundidad = data.idProfundidad ? data.idProfundidad : "";
    const valorMudanza = datos.datos.valorMudanza;
    const valorUbicacionTemporal = cajasUbicadas;
    const valorRetiro = datos.datos.valorRetiroCaja;
    const valorDevolucionCaja = datos.datos.valorDevolucionCaja;
    const cajas = datos.datos.cajas;
    const id_tipo_ubicacion = data.id_tipo_ubicaciones ? data.id_tipo_ubicaciones : "0";

    const propiedades = {
      services: "getCajasDisponibles",
      structure: {
        mudanza: `${valorMudanza}`,
        id_owner_localizaciones: `${data.idDep}`,
        id_cuentas_localizaciones: `${id_cuentas_localizaciones}`,
        ubicacion_temporal: `${valorUbicacionTemporal}`,
        retiro_caja: `${valorRetiro}`,
        devolucion_caja: `${valorDevolucionCaja}`,
        cajas: `${cajas}`,
        matriz: {
          sector: `${sector}`,
          modulo: `${modulo}`,
          estante: `${estante}`,
          posicion: `${posicion}`,
          estibe: `${estibe}`,
          profundidad: `${profundidad}`,
          id_tipo_ubicacion: `${id_tipo_ubicacion}`,
        },
      },
    };

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  multipleFromService(data) {
    const propiedades = { services: "MultipleFromService", structure: data };

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  searchContenidos(data) {

    const propiedades = {
      services: 'contenidos',
      structure: {
        coincidencias: data.coincidencias,
        id_tipo_contenidos: data.tipoContenido,
        id_cuentas_localizaciones: data.sector,
        virtual: data.virtual,
        search_params: data.search
      }
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  movementContenidos(data) {

    let propiedades = {
      services: 'contenidosMovement',
      structure: {
        ret_total: data.ret_total,
        ret_partial: data.ret_partial,
        dev_total: data.dev_total,
        ret_preferencial: data.ret_preferencial,
        ret_urgente: data.ret_urgente,
        dev_partial: data.dev_partial,
        quita_total: data.quita_total,
        detalle: data.detalle,
        id_contenidos: data.contenidos,
        ajuste: data.ajuste,
        id_contacto: data.contacto,
        id_direccion: data.direccion
      }
    }

    // Verifica si nodoExtra no está vacío y es un objeto
    if (data.comprobantes_extra_data) {
      // Agrega las propiedades adicionales a la estructura propiedades
      propiedades.structure.comprobantes_extra_data = data.comprobantes_extra_data;
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  articulosGet(limit, offset, data = {}) {
    var estructura = {}
    // if(limit && offset){
    //   estructura.limit = `${limit}`
    //   estructura.offset = `${offset}`
    // }
    estructura.criteria = data
    const propiedades = {
      services: 'articulosGet',
      structure: estructura

    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  articulosCreate(data) {
    const propiedades = {
      services: 'articulosCreate',
      structure: data
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  articulosUpdate(data) {
    const propiedades = {
      services: 'articulosUpdate',
      structure: data
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  articulosDelete(data) {
    const propiedades = {
      services: 'articulosDelete',
      structure: data
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  contenidosUpdate(data) {
    const propiedades = {
      services: 'contenidosUpdate',
      structure: {
        contenidos: {
          id: data.id,
          id_cajas: data.id_cajas,
          id_tipo_contenidos: data.id_tipo_contenidos,
          meta_datos: data.meta_datos,
          lote: data.lote
        },
      }
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  delete(data) {
    const propiedades = {
      services: "delete",
      structure: {
        model: this.modelo,
        attributes: data,
      },
    };

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  contenidosCreate(data) {
    const propiedades = {
      services: 'contenidosCreate',
      structure: {
        contenidos: {
          id: data.id,
          id_cajas: data.id_cajas,
          id_tipo_contenidos: data.id_tipo_contenidos,
          meta_datos: data.meta_datos,
          lote: data.lote,
        },
      }
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  searchContenidosPrincipal(data, limit, offset, ordenamiento, devuelveContenidos) {
    let estructura = {
      coincidencias: data.coincidencias,
      eliminados: data.eliminados,
      soloCajas: data.soloCajas,
      jerarquia: data.jerarquia ? "1" : "0",
      devuelveContenidos: devuelveContenidos || data.devuelveContenidos ? "1" : "0",
      exportarCajasContenidos: data.exportarCajasContenidos ? "1" : "0",
      // incluyeDigitalizaciones: "1",
      search_params: data.search_params
    }


    if (limit) {
      estructura = {
        ...estructura,
        limit: `${limit}`,
        offset: `${offset}`,
      }
    }

    // let id_contenido=localStorage.getItem('id_contenidos')?.length>0?localStorage.getItem('id_contenidos'):'';

    if (ordenamiento) {
      estructura = {
        ...estructura,
        orden: `${ordenamiento}`
      }
    }

    if (data.soloContenidos) {
      estructura = {
        ...estructura,
        soloCajas: "0",
        soloContenidos: "1",
        id_caja: `${data.id_caja}`,
        // id_contenidos_search:`${id_contenido}`
      }
    }

    const propiedades = {
      services: 'contenidosSearch',
      structure: estructura
    }
    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  searchContenidosHistoricoCaja(id_caja) {
    let estructura = {
      "id_caja": id_caja,
      "solo_movimientos_cajas": "1",
    }


    const propiedades = {
      services: 'contenidosSearch',
      structure: estructura
    }
    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  searchContenidosHistoricoContenido(id_contenido) {
    let estructura = {
      "soloContenidos": "1",
      "id_contenido": id_contenido,
      "solo_movimientos_contenidos": "1",
    }


    const propiedades = {
      services: 'contenidosSearch',
      structure: estructura
    }
    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  cuentasCreate(data, update) {
    let servicio = "cuentasCreate";
    if (update) {
      servicio = "cuentasUpdate"
    }
    const propiedades = {
      services: servicio,
      structure: data
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  getImages(data) {
    const propiedades = {
      services: 'getImages',
      structure: data
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  createImages(data) {
    const propiedades = {
      services: 'createImages',
      structure: data
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  createImagesMultiple(data) {
    const propiedades = {
      services: 'createImagesMultiple',
      structure: data
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  getCajasDisponiblesGenericas(data, limit, offset) {


    const propiedades = {
      services: "getCajasDisponibles",
      structure: {
        limit: `${limit}`,
        offset: `${offset}`,
        alojamiento: `${data.alojamiento}`,
        id_cuentas_localizaciones: `${data.id_cuentas_localizaciones}`,
        matriz: {
          sector: "",
          modulo: "",
          estante: "",
          posicion: "",
          estibe: "",
          profundidad: "",
        },
      },
    };

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  cajasUbicacionesSetGenerica(data) {

    const propiedades = {
      services: "cajasUbicacionesSet",
      structure: {
        alojamiento: `${data.alojamiento}`,
        ajuste: `${data.ajuste}`,
        cajas: data.cajas,
        matriz: {
          sector: "",
          modulo: "",
          estante: "",
          posicion: "",
          estibe: "",
          profundidad: "",
        },
      },
    };

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  spUpdate(data) {
    const propiedades = {
      services: 'spUpdate',
      structure: data,
      asincrono: true
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  getMovimientos() {
    const propiedades = {
      services: 'getMovimientos',
      structure: {},
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  dboxDashboard(vistaConsulta, proyecto) {
    const propiedades = {
      services: 'dboxDashboard',
      structure: {
        vistaConsulta: vistaConsulta,
        proyecto: proyecto
      },
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  getComprobantes(data) {
    const propiedades = {
      services: 'getComprobantes',
      structure: data,
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  setComprobantesDigitalizaciones(data) {
    const propiedades = {
      services: 'setComprobantesDigitalizaciones',
      structure: data,
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  comprobantesPrint(data) {
    const propiedades = {
      services: 'comprobantesPrint',
      structure: data,
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  getNewPassword(data) {
    const propiedades = {
      services: 'getNewPassword',
      structure: data,
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }


  getTableStructure(data) {
    const propiedades = {
      services: 'getTableStructure',
      structure: data,
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  comprobantesCreate(data) {
    const propiedades = {
      services: 'comprobantesCreate',
      structure: data,
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  comprobantesDelete(data) {
    const propiedades = {
      services: 'comprobantesDelete',
      structure: data,
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }

  produccionGet(data) {
    const propiedades = {
      services: 'produccionGet',
      structure: data,
    }

    const respuestaGet = requestService(propiedades);

    return respuestaGet;
  }



}
export default datos;

export const schemaAlicuotasIVAForm = {
  type: "object",
  properties: {
    nombre: {
      type: "string",
      title: "Nombre de la Alicuota",
      description: "Ingresar nombre",
      minLength: 3,
     
    },
    valor: {
      type: "number",
      title: "Valor",
      description: "Ingresar Valor",
      minLength: 3,
     
    },
    codigo_afip: {
      type: "integer",
      title: "Codigo AFIP",
      description: "Ingresar Codigo",
      minLength: 1,
      
    },
  },

  required: ["nombre", "valor", "codigo_afip"],
};

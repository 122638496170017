export const schemaFiltroCuentasServiciosPreciosForm = {
  type: "object",
  properties: {
    activo: {
      type: "boolean",
      title: "Activo",
      description: "Activo",
      default: true,
    },
    cantidad: {
      type: "number",
      title: "Cantidad",
      description: "Cantidad",
      minimum: 0,
      multipleOf: 0.0001,
      maximum: 999999999999.9999,
    },
    importe: {
      type: "number",
      title: "Importe",
      description: "Importe",
      minimum: 0,
      multipleOf: 0.0001,
      maximum: 999999999999.9999,
    },
    activoFechaInicio: {
      type: "boolean",
      title: "Activo Inicio",
      description: "Activo Inicio",
    },
    fechaActivoInicio: {
      type: "string",
      customRender: "DateForm",
    },
    activoFechaFin: {
      type: "boolean",
      title: "Activo Fin",
      description: "Activo Fin",
    },
    fechaActivoFin: {
      type: "string",
      customRender: "DateForm",
    },
    descripcion: {
      type: "string",
      title: "Descripcion",
      description: "Descripcion",
    },
    selectedTipoCuenta: {
      title: "Tipo cuentas",
      type: "string",
      customRender: "SelectorCuentasTipo",
    },
    selectedModeloArticulo: {
      title: "Articulo / Sercio",
      type: "string",
      customRender: "SelectorModeloArticulo",
    },
  },
  required: [
    "selectedModeloArticulo",
    "selectedTipoCuenta",
    "cantidad",
    "importe",
    "activo",
  ],
};

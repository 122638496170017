import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { getTipoContenidosCaja } from "../helpers/TipoContenidosData";
import { getEstadosCajas } from "../helpers/EstadosCajasData";
import { updateSp } from "../helpers/SearchFormData";
import { getMetadatosWhereIdTipoContenidos } from "../helpers/MetadatoData";
import { getContenidosSearch } from "../helpers/ContenidosData";
import Grid from "@mui/material/Grid";
import { Buttons } from "src/general_components/Buttons";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { FormCuentaSector } from "./FormCuentaSector";
import { FormMetadatos } from "./FormMetadatos";
import { FormUbicaciones } from "./FormUbicaciones";
import { FormIdCajas } from "./FormIdCajas";
import { FormEstadosCajas } from "./FormEstadosCajas";
import { FormFechaDesdeHasta } from "./FormFechaDesdeHasta";
import { FormMovimientos } from "./FormMovimientos";
import { getTipoMovimientos } from "../helpers/TipoMovimientosData";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import { esArray } from "src/general_components/functionEsArray";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import WarningIcon from "@mui/icons-material/Warning";
import ReplayIcon from '@mui/icons-material/Replay';
import { v4 as uuidv4 } from 'uuid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TheLayoutContext } from "src/context/TheLayoutContext";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const SearchFormGeneral = (prop = null) => {
  const {
    dialogPreguntaDistintoCliente,
    onClickSubmitDistintoCliente,
    cuentaDigitalizaciones,
    valorUbicacionTemporal,
  } = prop;
  const [abrirPreguntaDistintoCliente, setAbrirPreguntaDistintoCliente] =
    useState(false);
  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [modalName, setModalName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [labelCuentaSector, setLabelCuentaSector] = useState("");
  const [estadosCajasList, setEstadosCajasList] = useState([]);
  const [tiposContenidosList, setTiposContenidosList] = useState([]);
  const [tiposMovimientosList, setTiposMovimientosList] = useState([]);
  const [movimientosContenidosList, setMovimientosContenidosList] = useState(
    []
  );
  const [mensajeSp, setMensajeSp] = useState(false);
  var child = "";
  const { listDatosFormCuentaSector, listDatosFormUbicaciones, dataSearchFormUbicaciones, listDatosFormIdCajas, listDatosEstadosCajas,
    listDatosFechaAltaCajas, dataSearchFormEstadosCajas, dataSearchFormMetadatos, showFormCuentaSector, showFormMetadatos,
    showFormUbicaciones, showFormIdCajas, showFormEstadosCajas, showFormFechaAltaCajas, showFormMovimientos, setResponseSearchFormGeneral,
    requiredFormCuentaSector, requiredFormMetadatos, requiredFormUbicaciones, requiredFormIdCajas, setClientesListSearch,
    setSnackbarVisible, setMessage, setSeverity, setMetadatosList, setDataSearchFormGeneral, checkFisica, checkVirtual, requiredTipoContenido,
    soloCajas, requiredSector, omitirQuerySearch, dataSearchMovimientos, requiredFormMovimientos, setBusquedaSearchOn,
    digitalizacionesAfacturar, clienteIdSearch, setListDatosFormCuentaSector, setClienteIdSearch, setSectorIdSearch,
    setDataSearchFormMetadatos, setListDatosFormMetadatos, metadatosList, setListDatosFormUbicaciones, setFormUbicacionesDisabled,
    setListDatosFechaAltaCajas, setDataSearchMovimientos, sectorIdSearch, setCheckFisica, setCheckVirtual, setListDatosFormIdCajas,
    setListDatosEstadosCajas, altaGenerica, combinarFiltro, tipoContenidoSeleccionadoinSearch, setTipoContenidoSeleccionadoinSearch,
    setFormMetadatosDisabled, setInputFields, inputFields, setActualizarListado, dataSearchFormGeneral, setLoading, setGrupoBusquedaSectorCliente, grupoBusquedaSectorCliente, grupoBusquedaMetadato, setGrupoBusquedaMetadato, grupoBusquedaUbicaciones,
    setGrupoBusquedaUbicaciones, grupoBusquedaCajas, setGrupoBusquedaCajas, grupoBusquedaEstadosCajas, setGrupoBusquedaEstadosCajas,
    grupoBusquedaFecha, setGrupoBusquedaFecha, grupoBusquedaMovimiento, setGrupoBusquedaMovimiento,combinarFiltroContenidos

  } = useContext(CajasContext);
  //Utilizada para el btn LimpiarTodo
  const [data, setData] = useState(listDatosFormUbicaciones);

  const [valorDesde, setValorDesde] = React.useState(listDatosFechaAltaCajas.fecha_desde || "");
  const [valorHasta, setValorHasta] = React.useState(listDatosFechaAltaCajas.fecha_hasta || "");

  const [rangoId, setRangoId] = React.useState(listDatosFormIdCajas.idCajasRango || "");
  const [referencia, setReferencia] = React.useState(listDatosFormIdCajas.referenciasRango || "");
  const [tiposCajasSeleccionadas, setTiposCajasSeleccionadas] = React.useState(listDatosFormIdCajas.tiposCajasSeleccionadas || "");
  const [selectOrdenamiento, setSelectOrdenamiento] = useState(listDatosFormIdCajas.ordenamiento || "");
  const [cambioPestania, setCambioPestania] = React.useState(false);
  const [checkedNewPestania, setCheckedNewPestania] = React.useState(true);
  const [searchMarcas, setSearchMarcas] = React.useState(false);
  const [excedidoSeleccionado, setExcedidoSeleccionado] = useState(listDatosFormIdCajas.excedida || "2");
  const [deformadaSeleccionado, setDeformadaSeleccionado] = useState(listDatosFormIdCajas.deformada || "2");
  const [checkedJerarquia, setCheckedJerarquia] = React.useState(false);


  let searchIdContenidos = false;

  const { setNewCheck, setSelection, setButtonMarcaDisabled, setButtonMarcarTodoDisabled, subProceso, setS3Values,
    procesoSeleccionado, clienteId, s3Json } = useContext(TheLayoutContext)
  const handleChangeNewPestania = (event) => {
    setCheckedNewPestania(event.target.checked);
  };

  const handleChangeJerarquia = (event) => {
    setCheckedJerarquia(event.target.checked);
  };

  const handleChangeSearchMarcas = (event) => {
    setSearchMarcas(event.target.checked);
  };

  const [estadosCajas, setEstadosCajas] = React.useState([]);
  const [s3ValuesLocal, setS3ValuesLocal] = React.useState([]);

  // Abrir y Cerrar modal
  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };

  //NOTE SE CREA EL USESTATE PARA EL ID DEL CLIENTE PARA QUE NO SE VUELVA A LLAMAR A LA QUERY DE SECTOR DENTRO DEL FORMULARIO DE CUENTA Y SECTOR
  const [valueIdCliente, setValueIdCliente] = React.useState(clienteIdSearch);

  // Abrir modal
  const ObtenerDatos = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cargaValoresS3 = async () => {
      let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;
      let procesoActual = obtenerProceso();
      try {

        let tipo_id = 'id'

        if (procesoActual === 'devolucion') {

          procesoActual = 'devolucion_contenidos'
        } else if (procesoActual === 'retiro') {

          procesoActual = 'retiro_contenidos'
        }

        if (clienteId > 0) {
          if (s3Json && s3Json[procesoActual]?.[id_user]?.[subProceso]?.[clienteId]?.[tipo_id]) {
            localStorage.setItem("siam-datos3", JSON.stringify(s3Json));
            const tipoProceso = s3Json[procesoActual][id_user][subProceso][clienteId][tipo_id];

            if (Object.keys(tipoProceso).length > 0) {
              // eslint-disable-next-line no-unused-vars
              const [key, value] = Object.entries(tipoProceso)[0];
              setS3ValuesLocal(value)

            } else {

              console.log("El objeto tipoProceso está vacío");
            }

          } else {
            setS3Values([]);
          }
        } else {
          if (s3Json && s3Json[procesoActual]?.[id_user]?.[subProceso]) {
            localStorage.setItem("siam-datos3", JSON.stringify(s3Json));
            const tipoProceso = s3Json[procesoActual][id_user][subProceso];

            const value = Object.values(tipoProceso).flatMap(objeto => objeto.id[0]);

            setS3ValuesLocal(value)
          }
        }
      } catch (error) {
        console.log('Error al descargar o parsear el archivo JSON desde S3:', error);
      }

    };

    cargaValoresS3()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteId]);

  const obtenerProceso = () => {
    let proceso = '';
    if (procesoSeleccionado && procesoSeleccionado?.label !== 'Consulta General') {
      const palabra = procesoSeleccionado.label.split(/[ /]/)
      // Usamos una expresión regular para dividir por espacio o barra
      const filtro = palabra.filter(word => word.toLowerCase() !== 'de');
      proceso = filtro.join('_').toLowerCase();
    } else {

      let url = window.location.href;
      const arrUrl = url.split("/");
      proceso = arrUrl[arrUrl.length - 1];
      if (proceso.includes("?")) {

        const arrUrl = url.split("?");
        proceso = arrUrl[arrUrl.length - 1];

        const arrUrls = proceso.split("=");
        proceso = arrUrls[arrUrls.length - 2];

        proceso = proceso === 'mudanza' ? 'mudanza_cajas' : proceso
      } else {

        proceso = proceso === 'proceso' ? 'ubicacion_cajas' : proceso
      }
    }
    return proceso
  }




  const updateSearchData = () => {
    let data = { name: "Sp_search_contenidos" };
    // eslint-disable-next-line no-unused-vars
    let spUpdate = updateSp(data);
    setMensajeSp(true);
    setOpen(true);
    // TODO: Consumir servicio /sp/update
    // params "name": "Sp_search_contenidos"
  };

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  // eslint-disable-next-line no-unused-vars
  const isMobileDevice = regexp.test(details);

  // Se obtienen los estados y los Tipo de Contenidos
  useEffect(() => {
    const getEstados = async (proceso) => {
      let dataEstados = [];
      let estadosCajas = [];
      let responseStorage = JSON.parse(
        localStorage.getItem("siam-estados_cajas")
      );
      if (responseStorage.estados_cajas) {
        estadosCajas = esArray(responseStorage.estados_cajas);
      } else {
        // eslint-disable-next-line no-unused-vars
        let estadosCajas = await getEstadosCajas();
      }
      dataEstados = estadosCajas;
      let estadosHabilitados = [];
      switch (proceso) {
        case "retiro":
          estadosHabilitados = [
            { id: "6", detalle: "Alojada" },
            { id: "7", detalle: "Alojada Temporalmente" },
            { id: "11", detalle: "Alojada - Esperando devolución" },
          ];
          break;
        case "devolucion":
          estadosHabilitados = [
            { id: "11", detalle: "Alojada - Esperando devolución" },
          ];
          break;
        case "quita":
          estadosHabilitados = [
            { id: "6", detalle: "Alojada" },
            { id: "7", detalle: "Alojada Temporalmente" },
            { id: "11", detalle: "Alojada - Esperando devolución" },
            { id: "10", detalle: "En Cliente" },
          ];
          break;
        case "edit":
          estadosHabilitados = [
            { id: "5", detalle: "Sin Ubicación" },
            { id: "6", detalle: "Alojada" },
            { id: "7", detalle: "Alojada Temporalmente" },
            { id: "11", detalle: "Alojada - Esperando devolución" },
            { id: "10", detalle: "En Cliente" },
          ];
          break;
        case "list":
          estadosHabilitados = [
            { id: "4", detalle: "Creada" },
            { id: "5", detalle: "Sin Ubicación" },
            { id: "6", detalle: "Alojada" },
            { id: "7", detalle: "Alojada Temporalmente" },
            { id: "8", detalle: "Creada Genérica" },
            { id: "9", detalle: "Alojada Genérica" },
            { id: "10", detalle: "En Cliente" },
            { id: "11", detalle: "Alojada - Esperando devolución" },
            { id: "13", detalle: "Baja" },
            { id: "28", detalle: "No aplica" },
          ];
          break;
        case "CajasGenericasAsignacion":
          estadosHabilitados = [{ id: "9", detalle: "Alojada Genérica" }];
          break;

        default:
          estadosHabilitados = false;
      }
      if (estadosHabilitados) {
        dataEstados = estadosCajas.filter((estado) =>
          estadosHabilitados.some((obj) => obj.id === estado.id)
        );
      }
      setEstadosCajasList(dataEstados);
    };

    const getTipoContenido = async () => {
      // se obtiene url para saber que proceso esta usando el selector
      // dependiendo el proceso se aplica un filtro a la consulta
      let url = window.location.href;
      const arrUrl = url.split("/");
      const proceso = arrUrl[arrUrl.length - 1]; // retorna el ultimo valor de la url
      let filtroDigitalizado = false;
      switch (proceso) {
        case "impresion":
          filtroDigitalizado = "0";
          break;
        case "edit":
          filtroDigitalizado = "0";
          break;
        case "list":
          filtroDigitalizado = "0";
          break;
        case "proceso":
          filtroDigitalizado = "estandar";
          break;
        default:
          filtroDigitalizado = false;
      }
      let responseStorage = JSON.parse(
        localStorage.getItem("siam-tipo_contenidos")
      );
      if (responseStorage) {
        console.log("TIPO CONTENIDO")
        let response = await getTipoContenidosCaja(filtroDigitalizado);
        setTiposContenidosList(FiltrarPorDigitalizado(response));
      } else {
        let tipoContenidosStorage = FiltrarPorDigitalizado(esArray(responseStorage.tipo_contenidos));
        if (responseStorage !== null) {
          setTiposContenidosList(tipoContenidosStorage);
        }
      }
    };

    function FiltrarPorDigitalizado(arrayDeObjetos) {
      return arrayDeObjetos
      /*
      // Utilizamos el método filter para obtener un nuevo array con solo los objetos que cumplen la condición
      // let objetosFiltrados;
      // if(digitalizado){
      //   objetosFiltrados = arrayDeObjetos.filter(objeto => objeto.digitalizado === '1');
      // }else{
      //   objetosFiltrados = arrayDeObjetos.filter(objeto => objeto.digitalizado === '0');
      // }

      // Devolvemos el nuevo array (puedes ajustar esto según tus necesidades)
      return arrayDeObjetos;
      */
    }
    const getClientes = async () => {
      var clientes = [];
      const user_cuentas = JSON.parse(
        localStorage.getItem("siam-user-cuentas")
      );
      let userCuentasStorage = esArray(user_cuentas);
      if (userCuentasStorage.length < 2) {
        let usersCuentasModificado = [
          { usuarios_cuentas: userCuentasStorage[0] },
        ];
        userCuentasStorage = usersCuentasModificado;
      }

      let cuentas = esArray(JSON.parse(localStorage.getItem("siam-service-getCuentasGenericas")).cuentas);
      let condicionIN = false;

      if (prop.procesoGenerica || altaGenerica) {

        condicionIN = true;
      }


      let clients = esArray(JSON.parse(localStorage.getItem("siam-cuentas_personas_juridicas")).cuentas_personas_juridicas);
      clientes = clients.filter((cliente) =>
        cuentas.find(
          (cuenta) => {
            if (condicionIN === true) {
              return cuenta.id_cuentas === cliente.id_cuentas

            } else {
              return cuenta.id_cuentas !== cliente.id_cuentas
            }
          }
        )
      );

      if (user_cuentas.length > 0) {
        let clientesFilter = clientes.filter((cliente) =>
          userCuentasStorage.find(
            (obj) => obj.usuarios_cuentas.id_cuentas === cliente.id_cuentas
          )
        );
        let newClientes = Object.entries(clientesFilter).map(([key, value]) => {
          let objeto = value;
          objeto["label"] = value.razon_social;
          return objeto;
        });
        setClientesListSearch(newClientes);
      } else {
        let newClientes = Object.entries(clientes).map(([key, value]) => {
          let objeto = value;
          objeto["label"] = value.razon_social;
          return objeto;
        });
        setClientesListSearch(newClientes);
      }
    };
    const getMovimientos = async () => {
      let response = await getTipoMovimientos();
      setTiposMovimientosList(
        Object.values(response.wsResponse.movimientos_cajas)
      );

      setMovimientosContenidosList(
        Object.values(response.wsResponse.movimientos_contenidos)
      );
    };

    if (showFormMovimientos) {
      getMovimientos();
    }
    console.log("entreeee")
    getClientes();
    getTipoContenido();
    getEstados(prop.proceso);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [altaGenerica]);

  // En base al tipo de contenido seleccionado , se obtienen los metadatos de ese tipo de contenido
  useEffect(() => {
    const getMetadatosWhereIdTC = async () => {
      const meta_datos = await getMetadatosWhereIdTipoContenidos(
        tipoContenidoSeleccionadoinSearch.id
      );

      const labelGrillaSet = new Set();
      const resultadoSinDuplicados = meta_datos.filter((metadato) => {
        const { label_form } = metadato;

        if (!labelGrillaSet.has(label_form)) {
          labelGrillaSet.add(label_form);
          return true;
        }
        return false;
      });

      setMetadatosList(resultadoSinDuplicados);
    }

    tipoContenidoSeleccionadoinSearch?.id && getMetadatosWhereIdTC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoContenidoSeleccionadoinSearch]);

  useEffect(() => {
    const datosRecibidos = window.dataSearch;
    if (datosRecibidos) {
      setDataSearchFormGeneral(datosRecibidos);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Funcion principal que arma los parametros de busqueda y consume el servicio
  const handleSubmit = async (e, continuar) => {
    setLoading(true);
    setActualizarListado(true);
    //Se limpian los datos tildados
    setNewCheck([])
    setSelection([])
    setButtonMarcaDisabled(true)
    setButtonMarcarTodoDisabled(true)

    let data = { coincidencias: "1", eliminados: "0", jerarquia: checkedJerarquia };
    if (soloCajas) {
      data["soloCajas"] = "1";
    } else {
      data["soloCajas"] = "0";
    }
    let dataSearch = {};
    // Se setea los filtros de busquedas
    if (listDatosFormCuentaSector) {
      if (listDatosFormCuentaSector["sector"]) {
        dataSearch["cuentas_localizaciones"] = {
          id_cuentas_localizaciones: listDatosFormCuentaSector["sector"],
        };
      } else if (listDatosFormCuentaSector["cuenta"]) {
        dataSearch["cuentas_localizaciones"] = {
          id_cuentas: listDatosFormCuentaSector["cuenta"],
        };
      }
    }
    if (listDatosFormUbicaciones) {
      if (listDatosFormUbicaciones["idDep"] || listDatosFormUbicaciones["ubicacionTemporal"]) {
        dataSearch["ubicaciones"] = dataSearchFormUbicaciones;
      }
    }

    if (listDatosFormIdCajas || s3ValuesLocal.length > 0) {
      if (searchMarcas === true && s3ValuesLocal.length > 0) {
        let procesoActual = obtenerProceso();

        if (
          (!procesoActual.includes("contenidos") && !procesoActual.includes("contenido")) &&
          (procesoActual !== 'retiro' && procesoActual !== 'devolucion')
        ) {

          if (listDatosFormIdCajas["id_cajas"].trim() === '') {
            // Si está vacío, simplemente asigna la cadena resultante del nuevo array
            listDatosFormIdCajas["id_cajas"] = s3ValuesLocal.join(',');
          } else {
            // Si no está vacío, realiza la concatenación
            const arrayActual = listDatosFormIdCajas["id_cajas"].split(',');
            const arrayResultado = [...arrayActual, ...s3ValuesLocal];
            listDatosFormIdCajas["id_cajas"] = arrayResultado.join(',');
          }
        } else {
          searchIdContenidos = true;
        }

      } else {
        if (searchMarcas) {
          setSnackbarVisible(true);
          setSeverity("warning");
          setMessage("El Proceso y Subproceso seleccionado no contiene marcas.");
          return;
        }
        //Si el check es falso se deve realizar solo la busqueda de los ids ingresados manualmente
        if (listDatosFormIdCajas && listDatosFormIdCajas["id_cajas"]) {
          listDatosFormIdCajas["id_cajas"] = listDatosFormIdCajas["id_cajas"]
            .split(',')
            .filter((item) => !s3ValuesLocal.includes(item))
            .join(',');
        } else {
          // Manejar el caso en el que listDatosFormIdCajas o listDatosFormIdCajas["id_cajas"] es undefined
        }
      }

      if (searchIdContenidos) {
        dataSearch["id_contenidos"] = s3ValuesLocal.join(',');
      }

      if (
        listDatosFormIdCajas["id_cajas"] !== "" ||
        listDatosFormIdCajas["id_cajas_desde"] !== "" ||
        listDatosFormIdCajas["id_cajas_hasta"] !== "" ||
        listDatosFormIdCajas["referencia"] !== "" ||
        listDatosFormIdCajas["ordenamiento"] !== ""
      ) {
        dataSearch["cajas"] = validarPropiedades(listDatosFormIdCajas);
      } else {
        let dataFisica = {};
        let fisica = false;
        let virtual = false;
        if (checkFisica) {
          fisica = "1";
        }
        if (checkVirtual) {
          virtual = "1";
        }
        if (fisica) {
          dataFisica.fisica = fisica;
        }
        if (virtual) {
          dataFisica.virtual = virtual;
        }
        if (Object.entries(dataFisica).length !== 0) {
          dataSearch["cajas"] = dataFisica;
        }
      }
    }
    if (listDatosEstadosCajas) {
      if (listDatosEstadosCajas.length > 0) {
        if (!Array.isArray(dataSearchFormEstadosCajas)) {
          dataSearch["estados_cajas"] = dataSearchFormEstadosCajas;
        } else if (listDatosEstadosCajas.length > 0) {
          let dataEstados = "";
          for (var i = 0; i < listDatosEstadosCajas.length; i++) {
            if (i === 0) {
              dataEstados += listDatosEstadosCajas[i].id;
            } else {
              dataEstados += "," + listDatosEstadosCajas[i].id;
            }
          }
          if (dataEstados !== "") {
            let datos = { id_estados_cajas: dataEstados };
            dataSearch["estados_cajas"] = datos;
            // setDataSearchFormEstadosCajas(datos);
          }
        }
      }
    }
    if (tipoContenidoSeleccionadoinSearch !== "") {
      const idsExtraidos = esArray(tipoContenidoSeleccionadoinSearch).map(objeto => objeto.id);
      dataSearch["tipo_contenidos"] = {
        id_tipo_contenidos: idsExtraidos,
      };
    }
    if (listDatosFechaAltaCajas) {
      if (
        listDatosFechaAltaCajas["fecha_desde"] ||
        listDatosFechaAltaCajas["fecha_hasta"]
      ) {
        dataSearch["fecha_creacion_cajas"] = listDatosFechaAltaCajas;
      }
    }
    if (combinarFiltro) {
      dataSearch["combinarFiltro"] = combinarFiltro ? "true" : "false";
    }
    if (combinarFiltroContenidos) {
      dataSearch["combinarFiltrosPorContenidosCaja"] = combinarFiltroContenidos ? "true" : "false";
    }
    if (dataSearchFormMetadatos) {
      if (dataSearchFormMetadatos.length > 0) {
        dataSearch["metadatos"] = dataSearchFormMetadatos;
      }
    }
    dataSearch["movimientos"] = dataSearchMovimientos;
    if (dataSearchMovimientos) {
      if (
        dataSearchMovimientos["detalle"] &&
        (dataSearchMovimientos["fecha_desde"] ||
          dataSearchMovimientos["fecha_hasta"]) &&
        (dataSearchMovimientos["cajas"] === "1" ||
          dataSearchMovimientos["contenidos"] === "1" ||
          dataSearchMovimientos["digitalizaciones"] === "1")
      ) {
        dataSearch["movimientos"] = dataSearchMovimientos;
      }
    }

    // Validaciones de las pestanas obligatorias de acuerdo a cada proceso.
    dataSearch["searchModal"] = true;

    if (requiredFormMovimientos) {
      if (!dataSearch["movimientos"]) {
        setSnackbarVisible(true);
        setSeverity("warning");
        setMessage("Pestaña movimientos obligatorio");
        return;
      } else {
        if (
          dataSearch["movimientos"]["cajas"] === "0" &&
          dataSearch["movimientos"]["contenidos"] === "0" &&
          dataSearch["movimientos"]["digitalizaciones"] === "0"
        ) {
          setSnackbarVisible(true);
          setSeverity("warning");
          setMessage(
            "Debe tener al menos una opción seleccionada: cajas, contenidos y digitalizaciones"
          );
          return;
        } else if (
          !dataSearch["movimientos"]["fecha_desde"] &&
          !dataSearch["movimientos"]["fecha_hasta"]
        ) {
          setSnackbarVisible(true);
          setSeverity("warning");
          setMessage("Debe tener al menos una fecha asignada");
          return;
        }
      }
    }
    data["search_params"] = dataSearch;

    if (
      dialogPreguntaDistintoCliente &&
      digitalizacionesAfacturar.digitalizaciones_contenidos.length > 0 &&
      cuentaDigitalizaciones &&
      !continuar
    ) {
      if (cuentaDigitalizaciones !== clienteIdSearch.id_cuentas) {
        setAbrirPreguntaDistintoCliente(true);
        return;
      }
    }
    if (checkedNewPestania) {

      // Se utiliza al momento de hacer la busqueda en una pestania nueva para el correcto manejo de los subprocesos seleccionados
      localStorage.setItem('subProceso', JSON.stringify(subProceso));

      const nuevaPestana = window.open(window.location.href, '_blank');
      // Envía los datos a la nueva pestaña
      nuevaPestana.dataSearchFormGeneral = data;
      nuevaPestana.actualizarListado = true;
      nuevaPestana.newPestania = true;
      nuevaPestana.listDatosFormCuentaSector = listDatosFormCuentaSector;
      nuevaPestana.listDatosFormUbicaciones = listDatosFormUbicaciones;
      nuevaPestana.dataSearchFormUbicaciones = dataSearchFormUbicaciones;
      nuevaPestana.listDatosFormIdCajas = listDatosFormIdCajas;
      nuevaPestana.listDatosEstadosCajas = listDatosEstadosCajas;
      nuevaPestana.listDatosFechaAltaCajas = listDatosFechaAltaCajas;
      nuevaPestana.dataSearchFormEstadosCajas = dataSearchFormEstadosCajas;
      nuevaPestana.dataSearchFormMetadatos = dataSearchFormMetadatos;
      nuevaPestana.showFormCuentaSector = showFormCuentaSector;
      nuevaPestana.showFormMetadatos = showFormMetadatos;
      nuevaPestana.showFormUbicaciones = showFormUbicaciones;
      nuevaPestana.showFormIdCajas = showFormIdCajas;
      nuevaPestana.showFormEstadosCajas = showFormEstadosCajas;
      nuevaPestana.showFormFechaAltaCajas = showFormFechaAltaCajas;
      nuevaPestana.showFormMovimientos = showFormMovimientos;
      nuevaPestana.setResponseSearchFormGeneral = setResponseSearchFormGeneral;
      nuevaPestana.requiredFormCuentaSector = requiredFormCuentaSector;
      nuevaPestana.requiredFormMetadatos = requiredFormMetadatos;
      nuevaPestana.requiredFormUbicaciones = requiredFormUbicaciones;
      nuevaPestana.requiredFormIdCajas = requiredFormIdCajas;
      nuevaPestana.checkFisica = checkFisica;
      nuevaPestana.checkVirtual = checkVirtual;
      nuevaPestana.requiredTipoContenido = requiredTipoContenido;
      nuevaPestana.soloCajas = soloCajas;
      nuevaPestana.requiredSector = requiredSector;
      nuevaPestana.omitirQuerySearch = omitirQuerySearch;
      nuevaPestana.dataSearchMovimientos = dataSearchMovimientos;
      nuevaPestana.requiredFormMovimientos = requiredFormMovimientos;
      nuevaPestana.altaGenerica = altaGenerica;
      nuevaPestana.combinarFiltro = combinarFiltro;
      nuevaPestana.tipoContenidoSeleccionadoinSearch = tipoContenidoSeleccionadoinSearch;
      nuevaPestana.metadatosList = metadatosList;
      nuevaPestana.digitalizacionesAfacturar = digitalizacionesAfacturar;
      nuevaPestana.clienteIdSearch = clienteIdSearch;
      nuevaPestana.sectorIdSearch = sectorIdSearch;
      nuevaPestana.busquedaSearchOn = true;
      nuevaPestana.inputFields = inputFields;
      nuevaPestana.procesoSeleccionado = procesoSeleccionado;
      nuevaPestana.subProceso = subProceso;
      prop.toggleModal();
      return;
    }

    setDataSearchFormGeneral(data);
    setBusquedaSearchOn(true);
    if (!omitirQuerySearch) {
      let response = await getContenidosSearch(data);
      if (response.status === "true") {
        if (response.wsResponse.cuentas) {
          const cajasCont = esArray(response.wsResponse.cuentas);
          setSnackbarVisible(true);
          setSeverity("success");
          if (soloCajas) {
            setMessage(
              "Se encontraron " + response.wsResponse.total_results + " cajas"
            );
          } else {
            setMessage(
              "Se encontraron " +
              response.wsResponse.total_results +
              " contenidos"
            );
          }
          setResponseSearchFormGeneral(cajasCont);
          prop.toggleModal();
        }
      } else {
        setSnackbarVisible(true);
        setSeverity("warning");
        if (soloCajas) {
          setMessage("No se encontraron cajas");
        } else {
          setMessage("No se encontraron cajas");
        }
      }
    } else {
      prop.toggleModal();
    }
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    if (pestanasActivas.includes(newValue)) {
      setValue(newValue); // Cambia a la pestaña si está activa
    }
    //NOTE SE ACTUALIZA EL USESTATE DEL CLIENTE ID PARA EL FORMULARIO DE CLIENTE SECTOR
    setValueIdCliente(clienteIdSearch);
    setCambioPestania(true);
    setValue(newValue);
  };

  //NOTE FUNCION PARA Ejecutar el search luego de haber mostrato el modal de aviso de distinto cliente
  const onClickSubmitDialogDistintoCliente = async () => {
    toggleModal();
    onClickSubmitDistintoCliente();
    handleSubmit(null, true);
  };

  const toggleModalDistintoCliente = () => {
    setAbrirPreguntaDistintoCliente(!abrirPreguntaDistintoCliente);
  };

  const limpiarGeneral = (e) => {
    setListDatosFormCuentaSector({});
    setClienteIdSearch(null);
    setSectorIdSearch([]);
    setDataSearchFormMetadatos([]);
    setFormMetadatosDisabled(false);
    setInputFields([{
      id: uuidv4(), metadato: '', operador: '', valor: '',
      valorDesde: '', valorHasta: '', listOperadores: [], listMetadatos: metadatosList, tipoDato: 'text',
      valorDesdeHasta: false
    }])
    setTipoContenidoSeleccionadoinSearch([]);
    setListDatosFormMetadatos([{
      id: uuidv4(), metadato: '', operador: '', valor: '',
      valorDesde: '', valorHasta: '', listOperadores: [], listMetadatos: metadatosList, tipoDato: 'text',
      valorDesdeHasta: false
    }])
    setData({ idDep: "", disabled: false });
    setListDatosFormUbicaciones({});
    setFormUbicacionesDisabled(false);
    setListDatosFechaAltaCajas({});
    setValorDesde("");
    setValorHasta("");
    setDataSearchMovimientos({});
    setCheckFisica(true);
    setCheckVirtual(false);
    setListDatosFormIdCajas({ id_cajas: "", referencia: "", tipoCajasSeleccionado: [], idCajasRango: '', referenciasRango: "", ordenamiento: "", excedida: "2", deformada: "2" });
    setRangoId("");
    setExcedidoSeleccionado("2");
    setReferencia("");
    setTiposCajasSeleccionadas("");
    setListDatosEstadosCajas([]);
    setEstadosCajas([]);
    if (proceso === 'retiro') {
      setListDatosEstadosCajas([
        { id: "6", detalle: "Alojada" },
        { id: "7", detalle: "Alojada Temporalmente" },
        { id: "11", detalle: "Alojada - Esperando devolución" },
      ]);
    }
    setGrupoBusquedaSectorCliente(false)
    setGrupoBusquedaMetadato(false)
    setGrupoBusquedaUbicaciones(false)
    setGrupoBusquedaCajas(false)
    setGrupoBusquedaEstadosCajas(false)
    setGrupoBusquedaFecha(false)
    setGrupoBusquedaMovimiento(false)
  };

  let url = window.location.href;
  const arrUrl = url.split("/");
  const proceso = arrUrl[arrUrl.length - 1]; // retorna el ultimo valor de la url

  const [pestanasActivas, setPestanasActivas] = useState([]);

  // eslint-disable-next-line
  const [indicePestanaActiva, setIndicePestanaActiva] = useState('');


  useEffect(() => {
    const indice = pestanasActivas.indexOf(value);
    setIndicePestanaActiva(indice)
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (dataSearchFormGeneral) {
      setLoading(false);
    }

  }, [dataSearchFormGeneral, setLoading]);

  const pestanasActivasRef = useRef([]);
  useEffect(() => {
    const activas = [];
    if (showFormCuentaSector) activas.push("1");
    if (showFormMetadatos) activas.push("2");
    if (showFormUbicaciones) activas.push("3");
    if (showFormIdCajas) activas.push("4");
    if (showFormEstadosCajas) activas.push("5");
    if (showFormFechaAltaCajas) activas.push("6");
    if (showFormMovimientos) activas.push("7");

    setPestanasActivas(activas);
    setIndicePestanaActiva(0);
    pestanasActivasRef.current = activas; // Almacena el valor en la referencia mutable
    // eslint-disable-next-line
  }, [showFormCuentaSector, showFormMetadatos, showFormUbicaciones, showFormIdCajas, showFormEstadosCajas, showFormFechaAltaCajas, showFormMovimientos]);

  const handleKeyDown = useCallback((event) => {

    if (event.shiftKey && event.key === ' ') {
      const activas = pestanasActivasRef.current; // Accede al valor almacenado en la referencia mutable
      setIndicePestanaActiva((indicePestanaActiva) => {
        if (indicePestanaActiva === activas.length - 1) {
          const siguienteIndice = 0
          setIndicePestanaActiva(0);
          const valorActual = activas[siguienteIndice];
          setValue(valorActual);
        } else {
          const siguienteIndice = indicePestanaActiva + 1;
          setIndicePestanaActiva(siguienteIndice);
          const valorActual = activas[siguienteIndice];
          setValue(valorActual);
        }
      });
    }
    if (event.shiftKey && event.key === 'Enter') {
      const button = document.getElementById('buttonGuardar');
      if (button) {
        button.click();
      }
    }
    // eslint-disable-next-line
  }, [pestanasActivas]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const validarPropiedades = (objeto) => {
    // Filtrar las propiedades del objeto
    const objetoFiltrado = Object.fromEntries(
      // Convertir el objeto en un array de pares [clave, valor]
      Object.entries(objeto)
        // Filtrar los pares [clave, valor] según las condiciones dadas
        .filter(([clave, valor]) => {
          // Eliminar propiedades con arrays vacíos o strings vacíos
          if (Array.isArray(valor)) {
            return valor.length > 0;
          } else if (typeof valor === 'string') {
            return valor.trim() !== '';
          }
          // Mantener las propiedades que no sean arrays vacíos o strings vacíos
          return true;
        })
        // También eliminar propiedades "deformada" y "excedida" con valor "2" o vacío
        .filter(([clave, valor]) => {
          if (clave === 'deformada' || clave === 'excedida') {
            return valor !== '2' && valor.trim() !== '';
          }
          // Mantener las propiedades que no sean "deformada" o "excedida"
          return true;
        })
    );

    return objetoFiltrado;
  };



  return (
    <>
      <Grid style={{ background: "white" }}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
                autoFocus
              >
                {showFormCuentaSector && (
                  <Tab label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Cuenta/Sector
                      {grupoBusquedaSectorCliente && <CheckCircleIcon style={{ marginLeft: 5 }} />}
                    </div>
                  } value="1" sx={{
                    ...(grupoBusquedaSectorCliente && { fontWeight: 'bold' }),
                  }} />
                )}


                {showFormMetadatos && <Tab
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Metadatos
                      {grupoBusquedaMetadato && <CheckCircleIcon style={{ marginLeft: 5 }} />}
                    </div>
                  }
                  value="2" sx={{
                    ...(grupoBusquedaMetadato && { fontWeight: 'bold' }),
                  }} />}
                {showFormUbicaciones && <Tab label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Ubicaciones
                    {grupoBusquedaUbicaciones && <CheckCircleIcon style={{ marginLeft: 5 }} />}
                  </div>
                } value="3" sx={{
                  ...(grupoBusquedaUbicaciones && { fontWeight: 'bold' }),
                }} />}
                {/* {showFormIdCajas &&  */}
                <Tab label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Cajas
                    {grupoBusquedaCajas && <CheckCircleIcon style={{ marginLeft: 5 }} />}
                  </div>
                } value="4" sx={{
                  ...(grupoBusquedaCajas && { fontWeight: 'bold' }),
                }} />
                {/* } */}
                {showFormEstadosCajas && (
                  <Tab label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Estados Cajas
                      {grupoBusquedaEstadosCajas && <CheckCircleIcon style={{ marginLeft: 5 }} />}
                    </div>
                  } value="5" sx={{
                    ...(grupoBusquedaEstadosCajas && { fontWeight: 'bold' }),
                  }} />
                )}
                {showFormFechaAltaCajas && (
                  <Tab label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Fecha de Alta Cajas
                      {grupoBusquedaFecha && <CheckCircleIcon style={{ marginLeft: 5 }} />}
                    </div>
                  } value="6" sx={{
                    ...(grupoBusquedaFecha && { fontWeight: 'bold' }),
                  }} />
                )}
                {/* {showFormMovimientos && */}
                <Tab label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Movimiento
                    {grupoBusquedaMovimiento && <CheckCircleIcon style={{ marginLeft: 5 }} />}
                  </div>
                } value="7" sx={{
                  ...(grupoBusquedaMovimiento && { fontWeight: 'bold' }),
                }} />
                {/* } */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <FormCuentaSector
                valueIdCliente={valueIdCliente}
                toggleModal={toggleModal}
                valorUbicacionTemporal={valorUbicacionTemporal}
                setCambioPestania={setCambioPestania}
                cambioPestania={cambioPestania}
              ></FormCuentaSector>
            </TabPanel>
            <TabPanel value="2">
              <FormMetadatos
                toggleModal={toggleModal}
                setLabelMetadatos={setLabelCuentaSector}
                tiposContenidosList={tiposContenidosList}
              />
            </TabPanel>
            <TabPanel value="3">
              <FormUbicaciones toggleModal={toggleModal} data={data} setData={setData} valorUbicacionTemporal={valorUbicacionTemporal} />
            </TabPanel>
            <TabPanel value="4">
              <FormIdCajas
                toggleModal={toggleModal}
                rangoId={rangoId}
                setRangoId={setRangoId}
                referencia={referencia}
                setReferencia={setReferencia}
                tiposCajasSeleccionadas={tiposCajasSeleccionadas}
                setTiposCajasSeleccionadas={setTiposCajasSeleccionadas}
                selectOrdenamiento={selectOrdenamiento}
                setSelectOrdenamiento={setSelectOrdenamiento}
                excedidoSeleccionado={excedidoSeleccionado}
                setExcedidoSeleccionado={setExcedidoSeleccionado}
                deformadaSeleccionado={deformadaSeleccionado}
                setDeformadaSeleccionado={setDeformadaSeleccionado}
              />
            </TabPanel>
            <TabPanel value="5">
              <FormEstadosCajas
                toggleModal={toggleModal}
                estadosLista={estadosCajasList}
                estadosCajas={estadosCajas}
                setEstadosCajas={setEstadosCajas}
              />
            </TabPanel>
            <TabPanel value="6">
              <FormFechaDesdeHasta toggleModal={toggleModal} valorDesde={valorDesde} setValorDesde={setValorDesde} valorHasta={valorHasta} setValorHasta={setValorHasta} />
            </TabPanel>
            <TabPanel value="7">
              <FormMovimientos
                toggleModal={toggleModal}
                tiposMovimientosList={tiposMovimientosList}
                movimientosContenidosList={movimientosContenidosList}
              />
            </TabPanel>
          </TabContext>
        </Box>

        <Grid container justifyContent="center" alignItems="center" marginBottom={5} marginLeft={3}>
          <Grid item xs={3}>
            <FormControlLabel control={<Switch checked={checkedNewPestania}
              onChange={handleChangeNewPestania} />} label="Búscar en otra pestaña"
              sx={{
                '&  .MuiTypography-root': {
                  fontSize: '14px'
                },
              }} />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel control={<Switch checked={checkedJerarquia}
              onChange={handleChangeJerarquia} />} label="Jerarquia"
              sx={{
                '&  .MuiTypography-root': {
                  fontSize: '14px'
                },
              }} />

          </Grid>
          <Grid item xs={4}>
            <FormControlLabel control={<Switch checked={searchMarcas}
              onChange={handleChangeSearchMarcas} />} label="Cajas/Contenidos Marcados"
              sx={{
                '&  .MuiTypography-root': {
                  fontSize: '14px'
                },
              }} />

          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="secondary" onClick={limpiarGeneral} startIcon={<ReplayIcon />}>
              Limpiar Todo
            </Button>
          </Grid>
          <Grid item marginTop={5}>
            <Buttons
              id='buttonGuardar'
              onClickSubmit={handleSubmit}
              labelButtonSave={"Buscar"}
              buttonCerrar={prop.toggleModal}
            // disabled={buttonBuscarDisabled}
            />
          </Grid>
        </Grid>
        <div>

          <Accordion style={{ backgroundColor: "#EEE" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Aviso</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <small>
                  Los datos para busquedas son actualizados cada 30 min - Si
                  agregaste un dato recientemente y no lo encontras, podes
                  forzar la actualización de los datos de busqueda con este
                  boton. El proceso puede demorar entre 5 y 10 minutos.
                </small>
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  updateSearchData();
                }}
              >
                Actualizar
              </Button>
            </AccordionDetails>
          </Accordion>
        </div>
        {mensajeSp && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            onClose={handleClose}
            key={"snackSP"}
          >
            <Alert severity="info" variant="filled">
              El proceso esta en ejecucion, la indexación de los datos puede
              demorar entre 5 o 10 minutos.
            </Alert>
          </Snackbar>
        )}
      </Grid>
      <SimpleDialogComponent
        open={abrirBusqueda}
        modalName={modalName}
        child={child}
        setPushData={ObtenerDatos}
        handleClose={toggleModal}
        buttonEnviar={false}
        buttonCancelar={true}
      />
      {dialogPreguntaDistintoCliente && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirPreguntaDistintoCliente}
          modalName={"Aviso"}
          child={
            <Grid
              sx={{
                mt: 1,
                minWidth: "100%",
                textAlign: "center",
                wordBreak: "break-all",
              }}
            >
              <WarningIcon sx={{ fontSize: "400%", color: "yellow" }} />
              <Typography
                align="center"
                variant="h4"
                gutterBottom
                component="div"
              >
                ATENCION
              </Typography>
              <Grid>
                <Typography align="center" variant="h6">
                  Solo puede elegir digitalizaciones de una misma cuenta. Si
                  continua con la busqueda perdera la seleccion realizada
                </Typography>
              </Grid>
              <Grid sx={{ paddingTop: "30px" }}>
                <Buttons
                  onClickSubmit={onClickSubmitDialogDistintoCliente}
                  labelButtonSave={"Continuar"}
                  buttonCerrar={toggleModalDistintoCliente}
                />
              </Grid>
            </Grid>
          }
          setPushData={onClickSubmitDialogDistintoCliente}
          handleClose={toggleModalDistintoCliente}
          buttonEnviar={false}
          buttonCancelar={true}
        ></SimpleDialogComponent>
      )}

    </>
  );
};

import * as React from "react";
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
    ListItem,
    List,
    ListSubheader,
    ListItemSecondaryAction,
    Popover,
    Box,
    Tooltip,
    useTheme
} from "@mui/material";
import { useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import TimelineIcon from '@mui/icons-material/Timeline';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import nav from "./nav";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import HolidayVillageSharpIcon from '@mui/icons-material/HolidayVillageSharp';
import MiscellaneousServicesSharpIcon from '@mui/icons-material/MiscellaneousServicesSharp';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import CategoryIcon from '@mui/icons-material/Category';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const SideMenu = ({ open, Logouts }) => {
    const [openSubMenu, setOpenSubMenu] = useState({});
    const [selectedMenu, setSelectedMenu] = useState(localStorage.getItem("selected-menu") || null);
    const [selectedSubMenu, setSelectedSubMenu] = useState(localStorage.getItem("selected-submenu") || null);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const backgroundMenu = theme.backgroundMenuItem?.default;

    const commonLabelStyles = {
        fontFamily: '"Public Sans", sans-serif',
        fontWeight: '600 !important',
        textTransform: 'capitalize',
        color: theme.palette.mode === 'light'? '#637381' : "#FFFFFF",
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        lineHeight: 1.57143,
        width: '100%',
        maxWidth: '100%',
        display: 'block',
        fontSize: '0.875rem !important'
    };
    
    const labelStyles = {
        ...commonLabelStyles,
        fontSize: '0.875rem !important'
    };
    
    const labelSubMenuStyles = {
        ...commonLabelStyles,
        fontSize: '0.815rem !important',
        fontWeight: 500,
    };
    
    const labelSubMenuActiveStyles = {
        ...commonLabelStyles,
        fontSize: '0.815rem !important',
        fontWeight: 'bold !important',
        color: theme.palette.mode === 'light'? '#0A395F': "#FFFFFF",
    };
    
    const activeIconStyles = {
        color: backgroundMenu,
        minWidth: 40
    };
    
    const activeLabelStyles = {
        color: backgroundMenu,
        fontSize: '0.875rem !important',
        fontWeight: 'bold !important'
    };
    
    const activeMenuItemStyles = {
        backgroundColor: 'rgba(25, 124, 203, 0.1)',
        borderRadius: '10px'
    };
    

    const navigation = nav();
    const convertToMenuData = (originalArray, menuIcons) => {
        console.log("originalArray",originalArray)
        return originalArray.map((item) => {
            const header = item.name;
            const menus = item?._children?.map((child) => {
                const matchingIcon = menuIcons.find((iconItem) => iconItem.name === child.name);
                return {
                    name: child.name,
                    icon: matchingIcon ? matchingIcon.icon : null,
                    submenus: child._children.map((submenu) => {
                        return {
                            label: submenu.name,
                            url: submenu.to,
                            level: submenu.level
                        };
                    })
                };
            });
            return { header, menus };
        });
    };

    const handleClick = (menu, event) => {

        localStorage.setItem("selected-menu", menu);
        if (open) {
            setOpenSubMenu(prev => ({ ...prev, [menu]: !prev[menu] }));
        } else {
            setAnchorEl(event.currentTarget);
            setSelectedMenu(menu);
        }
        if (menu === "Dashboard" || menu === "Cerrar Sesion") {
            setSelectedSubMenu(null);
            localStorage.setItem("selected-submenu", null);
        }
    };

    const handleSubMenuClick = (subMenu, menu, level) => {
        setearLevel(level);
        localStorage.setItem("selected-menu", menu);
        localStorage.setItem("selected-submenu", subMenu);
        setSelectedSubMenu(subMenu);
        setSelectedMenu(menu);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const menuIcons = [
        {
            name: 'Usuarios, Roles ',
            icon: <SupervisedUserCircleIcon />,
        },
        {
            name: 'Servicios ',
            icon: <SupportAgentIcon />,
        },
        {
            name: 'Administracion de Cuentas ',
            icon: <ManageAccountsIcon />,
        },
        {
            name: 'Relacion Cuentas-Servicios ',
            icon: <SettingsApplicationsIcon />,
        },
        {
            name: 'Emision – Registracion ',
            icon: <ConfirmationNumberIcon />,
        },
        {
            name: 'Parametros ',
            icon: <DisplaySettingsIcon />,
        },
        {
            name: 'Ubicaciones ',
            icon: <AddLocationAltIcon />,
        },
        {
            name: 'Procesos de Ingreso ',
            icon: <ImportExportIcon />,
        },
        {
            name: 'Procesos internos ',
            icon: <AllInboxIcon />,
        },
        {
            name: 'Consultas e Informes ',
            icon: <TimelineIcon />,
        },
        {
            name: 'Digitalizaciones',
            icon: <ImageSearchIcon />,
        },
        {
            name: 'Cajas Genericas',
            icon: <Inventory2Icon />,
        },
        {
            name: 'Procesos de Retiro ',
            icon: <UnarchiveIcon />,
        },
        {
            name: 'Procesos de Devolucion ',
            icon: <ArchiveIcon />,
        },
        {
            name: 'Propiedades',
            icon: <HolidayVillageSharpIcon />,
        },
        {
            name: 'Articulos',
            icon: <CategoryIcon />,
        },
        {
            name: 'Informes',
            icon: <AssignmentIcon />,
        },
        {
            name: 'Relacion Cuentas-Servicios ',
            icon: <MiscellaneousServicesSharpIcon />,
        },
        {
            name: 'Relacion Cuentas-Propiedades ',
            icon: <MiscellaneousServicesSharpIcon />,
        },
        {
            name: 'Cuentas Corrientes ',
            icon: <AccountBalanceRoundedIcon />,
        },
        {
            name: 'Modelos ',
            icon: <TableRowsRoundedIcon />,
        },
        {
            name: 'Modelos',
            icon: <TableRowsRoundedIcon />,
        }
    ];

    const setearLevel = (level) => {
        localStorage.setItem("levelMenuActual", level);

    }

    const isPopoverOpen = Boolean(anchorEl);
    const popoverId = isPopoverOpen ? 'sub-menu-popover' : undefined;

    const menuData = navigation ? convertToMenuData(navigation, menuIcons) : null;

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Box flex="1">
                <List>
                    <ListItem disablePadding sx={{ pl: 1, pr: 1 }}>
                        <Tooltip title={!open ? "Dashboard" : ""} placement="right">
                            <ListItemButton
                                onClick={(event) => handleClick('Dashboard', event)}
                                sx={selectedMenu === 'Dashboard' ? activeMenuItemStyles : {}}
                                href={"/dashboard"}
                            >
                                <ListItemIcon sx={selectedMenu === 'Dashboard' ? activeIconStyles : { minWidth: 40 }}>
                                    <DashboardIcon color="primary" />
                                </ListItemIcon>
                                {open && (
                                    <ListItemText
                                        primary='Dashboard'
                                        sx={selectedMenu === 'Dashboard' ? activeLabelStyles : labelStyles}
                                        primaryTypographyProps={selectedMenu === 'Dashboard' ? activeLabelStyles : labelStyles}
                                    />
                                )}
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </List>
                <Divider component="li" />
                <List>
                    {menuData?.map((headerData, index) => (
                        <React.Fragment key={index}>
                            {open && (
                                <ListSubheader sx={{ textAlign: 'left', fontFamily: '"Public Sans", sans-serif', fontSize: '0.775rem' }}>
                                    {headerData.header}
                                </ListSubheader>
                            )}
                            {headerData.menus.map((menu, menuIndex) => (
                                <div key={menuIndex}>
                                    <ListItem disablePadding sx={{ pl: 1, pr: 1, pb: 1 }}>
                                        <Tooltip title={!open ? menu.name : ""} placement="right">
                                            <ListItemButton
                                                onClick={(event) => handleClick(menu.name, event)}
                                                sx={selectedMenu === menu.name ? activeMenuItemStyles : {}}
                                            >
                                                <ListItemIcon sx={selectedMenu === menu.name ? activeIconStyles : { minWidth: 40, color: "#637381", }}>
                                                    {menu.icon}
                                                </ListItemIcon>
                                                {open && (
                                                    <ListItemText
                                                        primary={menu.name}
                                                        primaryTypographyProps={selectedMenu === menu.name ? activeLabelStyles : labelStyles}
                                                    />
                                                )}
                                                {menu.submenus && (
                                                    <ListItemSecondaryAction>
                                                        {open && (openSubMenu[menu.name] ? (
                                                            <ExpandLess sx={{ fontSize: '15px', ...(selectedMenu === menu.name ? activeLabelStyles : labelStyles) }} />
                                                        ) : (
                                                            <ExpandMore sx={{ fontSize: '15px', ...(selectedMenu === menu.name ? activeLabelStyles : labelStyles) }} />
                                                        ))}
                                                    </ListItemSecondaryAction>
                                                )}
                                            </ListItemButton>
                                        </Tooltip>
                                    </ListItem>
                                    {menu.submenus && (
                                        <>
                                            {open ? (
                                                <Collapse in={openSubMenu[menu.name]} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {menu.submenus.map((subMenu, subMenuIndex) => (
                                                            <ListItem key={subMenuIndex} disablePadding sx={{ pl: 1, pr: 1 }}>
                                                                <ListItemButton
                                                                    onClick={() => handleSubMenuClick(subMenu.label, menu.name, subMenu.level)}
                                                                    href={subMenu.url}>
                                                                    <ListItemIcon sx={{ pl: 2 }}>
                                                                        <CircleIcon
                                                                            sx={{ fontSize: selectedSubMenu === subMenu.label ? '10px' : '5px', color: selectedSubMenu === subMenu.label ? '#00A76F' : null }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={subMenu.label}
                                                                        primaryTypographyProps={selectedSubMenu === subMenu.label ? labelSubMenuActiveStyles : labelSubMenuStyles}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Collapse>
                                            ) : (
                                                selectedMenu === menu.name && (
                                                    <Popover
                                                        id={popoverId}
                                                        open={isPopoverOpen}
                                                        anchorEl={anchorEl}
                                                        onClose={handlePopoverClose}
                                                        anchorOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <Box>
                                                            <List component="div" disablePadding sx={{ p: 0.5 }}>
                                                                {menu.submenus.map((subMenu, subMenuIndex) => (
                                                                    <ListItem key={subMenuIndex} disablePadding >
                                                                        <ListItemButton
                                                                            sx={selectedSubMenu === subMenu.label ? activeMenuItemStyles : {}}
                                                                            onClick={() => handleSubMenuClick(subMenu.label, menu.name, subMenu.level)}
                                                                            href={subMenu.url}>
                                                                            <ListItemText
                                                                                primary={subMenu.label}
                                                                                primaryTypographyProps={selectedSubMenu === subMenu.label ? labelSubMenuActiveStyles : labelSubMenuStyles}
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        </Box>
                                                    </Popover>
                                                )
                                            )}
                                        </>
                                    )}
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </List>
            </Box>
            <Box>
                <Divider component="li" />
                <List>
                    <ListItem disablePadding sx={{ pl: 1, pr: 1 }}>
                        <Tooltip title={!open ? "Cerrar Sesion" : ""} placement="right">
                            <ListItemButton
                                onClick={Logouts}
                                sx={selectedMenu === 'Cerrar Sesion' ? activeMenuItemStyles : {}}
                            >
                                <ListItemIcon sx={selectedMenu === 'Cerrar Sesion' ? activeIconStyles : { minWidth: 40 }}>
                                    <LogoutIcon color="error" />
                                </ListItemIcon>
                                {open && (
                                    <ListItemText
                                        primary='Cerrar Sesion'
                                        sx={selectedMenu === 'Cerrar Sesion' ? activeLabelStyles : labelStyles}
                                        primaryTypographyProps={selectedMenu === 'Cerrar Sesion' ? activeLabelStyles : labelStyles}
                                    />
                                )}
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </List>
            </Box>
        </Box>
    );
};



// const menuData = [
//     {
//         header: 'Seguridad',
//         menus: [
//             { name: 'Usuarios y Roles', icon: <SupervisedUserCircleIcon />, submenus: ['Usuarios'] }
//         ]
//     },
//     {
//         header: 'Articulos, Servicios y Conceptos',
//         menus: [
//             { name: 'Servicios', icon: <SupportAgentIcon />, submenus: ['Categorias Servicios', 'Administracion Servicios'] }
//         ]
//     },
//     {
//         header: 'Cuentas',
//         menus: [
//             { name: 'Administracion de cuentas', icon: <ManageAccountsIcon />, submenus: ['Tipo Cuentas', 'Cuentas Personas Fisicas', 'Cuentas Personas Juridicas'] },
//             { name: 'Relacion Cuentas-Servicios', icon: <SettingsApplicationsIcon />, submenus: ['Cuentas - Servicios', 'Tipo Cuentas - Servicios'] }
//         ]
//     },
//     {
//         header: 'Comprobantes',
//         menus: [
//             { name: 'Emision-Registracion', icon: <ConfirmationNumberIcon />, submenus: ['Categorias Servicios', 'Administracion Servicios'] }
//         ]
//     },
//     {
//         header: 'Gestion Documental - Contenidos',
//         menus: [
//             { name: 'Parametros', icon: <DisplaySettingsIcon />, submenus: ['Tipos de Cajas', 'Tipos de Contenidos', 'Estados de Cajas', 'Estados de Ubicaciones'] },
//             { name: 'Ubicaciones', icon: <AddLocationAltIcon />, submenus: ['Administracion de Ubicaciones'] },
//             { name: 'Proceso de Ingreso', icon: <ImportExportIcon />, submenus: ['Impresion Etiquetas Cajas', 'Importacion'] },
//             { name: 'Procesos Internos', icon: <AllInboxIcon />, submenus: ['Cajas - Procesos Generales', 'Contenidos - Procesos Generales'] },
//             { name: 'Consultas e Informes', icon: <TimelineIcon />, submenus: ['Movimientos'] },
//             { name: 'Digitalizaciones', icon: <ImageSearchIcon />, submenus: ['Digitalizaciones'] },
//             { name: 'Cajas Genericas', icon: <Inventory2Icon />, submenus: ['Ubicacion', 'Asignacion', 'Contenidos', 'Alojamiento'] }
//         ]
//     }
// ];
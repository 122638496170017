import React, { useState } from 'react';
import json2csv from 'json2csv';
import FileSaver from 'file-saver';
// import { Button } from "@mui/material";
import TransferList from 'src/general_components/TransferList';
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Buttons } from 'src/general_components/Buttons';
import { SnackbarMessenger } from 'src/general_components/SnackbarMessenger';

const flattenJSON = (data, fields, reportTitle, armadoPor) => {
    console.log("data",data);
    const flattenData = [];

    const gridviews_ids = JSON.parse(localStorage.getItem("siam-gridviews_ids")).gridviews_ids;
    const gridManipulable = gridviews_ids?.filter(gridviews_id => gridviews_id.nombre === 'Comprobante');

    const esArray = (value) => (Array.isArray(value) ? value : [value]);

    var flattenTitle = {
        Titulo: reportTitle,
        Armado_por: armadoPor,

    };
    flattenData.push(flattenTitle);

    esArray(data).forEach((objeto) => {

        if (gridManipulable.length > 0) {

            const datos = {};
            for (const key in objeto) {
                const formattedKey = key
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                datos[formattedKey] = objeto[key];
            }
            flattenData.push(datos);

        } else {
            const { fecha, cliente, prioridad, cajas_por_tamanio, excedidas, movimiento, marcar_exc_deformadas } = objeto;
            var datos = {
                'Fecha': fecha,
                'Cliente': cliente,
                'Prioridad': prioridad,
                'Cajas por tamaño': cajas_por_tamanio,
                'Excedidas': excedidas,
                'Movimiento': movimiento,
                'Marcar exc deformadas': marcar_exc_deformadas,
            };
            flattenData.push(datos);
        }

    });

    return flattenData;
};

const obtenerFechaConFormato = () => {
    const fechaActual = new Date();
    const dia = String(fechaActual.getDate()).padStart(2, '0');
    const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const año = fechaActual.getFullYear();

    return `${dia}/${mes}/${año}`;
};

const ButtonsExportJsonCSVComprobantes = (props) => {
    const { totalPages, limit, idElementData, data } = props.props;
    let usuarioValues = JSON.parse(
        localStorage.getItem("siam-user-data")
    )

    const gridviews_ids = JSON.parse(localStorage.getItem("siam-gridviews_ids")).gridviews_ids;
    const gridviews_atributos = JSON.parse(localStorage.getItem("siam-gridviews_atributos")).gridviews_atributos;
    const gridManipulable = gridviews_ids?.filter(gridviews_id => gridviews_id.nombre === 'Comprobante');
    const filtroGridviewsAtributos = gridviews_atributos?.filter(gridviews_atributo => gridviews_atributo.id_gridviews_ids === gridManipulable[0]?.id);

    const esArray = (value) => (Array.isArray(value) ? value : [value]);

    let columnas = [];
    
    if(esArray(filtroGridviewsAtributos).length > 0){
        esArray(filtroGridviewsAtributos).forEach((objeto) => {

            if (gridManipulable.length > 0) {
    
                const formattedKey = objeto.data
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
    
                columnas.push(formattedKey);
            }
            else {
                columnas = ['Fecha', 'Cliente', 'Prioridad', 'Cajas por tamaño', 'Excedidas', 'Movimiento', 'Marcar exc deformadas'];
    
            }
        });
    }else{
        columnas = ['Fecha', 'Cliente', 'Prioridad', 'Cajas por tamaño', 'Excedidas', 'Movimiento', 'Marcar exc deformadas'];
    }
    

    // const columnas = ['Fecha', 'Cliente', 'Prioridad', 'Cajas por tamaño', 'Excedidas', 'Movimiento', 'Marcar exc deformadas'];

    const [selectedColumns, setSelectedColumns] = useState([]);
    const [fileType, setFileType] = useState('CSV');
    const [reportTitle, setReportTitle] = useState('');
    const [armadoPor, setArmadoPor] = useState(usuarioValues.username || '');
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [snackbarVisible, setSnackbarVisible] = useState(false);

    const obtenerJsonReporte = async () => {
        // Lógica para obtener y establecer el objeto JSON en el estado jsonData

        let mergedObj = []
        let response
        console.log("totalPages",totalPages)
        for (var i = 0; i < totalPages; i++) {
            let offsetReporte = limit * i;
            if (idElementData !== undefined) {
                response = await data?.options?.getPaginated(
                    limit,
                    offsetReporte,
                    idElementData
                ); // se trae los records mediante un id de algun tipo de elemento que sea necesario
            } else {
                response = await data?.options?.getPaginated(limit, offsetReporte); //probelma esta cuando el offfsett (limit*page supera la cantidad de registros)
            }

            if (response.response) {
                response = response.response;
            }
            if (response.data) {
                console.log("response", response)
                mergedObj = mergedObj.concat(response.data);
            }
        }

        return mergedObj;
    };

    function ordenarArray(originalArray, order) {
        // Crear un nuevo array vacío para almacenar los elementos ordenados
        const newArray = [];

        // Recorrer el orden deseado y agregar los elementos existentes al nuevo array
        for (const element of order) {
            const foundElement = originalArray.find(item => item === element);

            if (foundElement) {
                newArray.push(foundElement);
            }
        }

        // Recorrer el array original y agregar los elementos que no estén en el nuevo array
        for (const element of originalArray) {
            if (!newArray.includes(element)) {
                newArray.push(element);
            }
        }

        return newArray;
    }

    const handleClick = (jsonData) => {
        let data = jsonData

        const ordenDeseado = columnas;
        const arrayOrdenado = ordenarArray(selectedColumns, ordenDeseado);

        const columns = arrayOrdenado;
        // columnas completas
        const fields = [...columns];
        // datos
        const flattenData = flattenJSON(data, fields, reportTitle, armadoPor);


        // Convierte cada parte a CSV
        const csvDataFirstLine = json2csv.parse(flattenData, { fields: ['Titulo', 'Armado_por'] });
        const csvDataSecondLine = json2csv.parse(flattenData, { fields: fields });


        // Combina las dos líneas
        const csvData = `${csvDataFirstLine}\n\n${csvDataSecondLine}`;

        // const csvData = json2csv.parse(flattenData, { fields });
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

        const fileName = `export_${usuarioValues.username}_${obtenerFechaConFormato()}.csv`;
        FileSaver.saveAs(blob, fileName);
    };

    const exportToJsonFile = async () => {
        try {
            let data = await obtenerJsonReporte();
            // Convertir el objeto en formato JSON
            const jsonData = JSON.stringify({ comprobantes: data });
            // Generar un nombre de archivo único basado en la fecha y hora actual
            const fileName = `export_${usuarioValues.username}_${obtenerFechaConFormato()}.json`;

            // Crear un Blob con el contenido JSON
            const blob = new Blob([jsonData], { type: 'application/json' });

            // Generar la URL del archivo Blob
            const url = URL.createObjectURL(blob);

            // Crear un enlace (<a>) para descargar el archivo
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;

            // Agregar un event listener para ejecutar props.toggleModal() después de la descarga
            link.addEventListener('click', () => {
                props.toggleModal();
                // Limpiar la URL del objeto Blob después de un tiempo para liberar recursos
                setTimeout(() => URL.revokeObjectURL(url), 100);
            });

            // Simular un clic en el enlace
            link.click();
        } catch (error) {
            // Manejar errores aquí
            console.error('Error al exportar a archivo JSON:', error);
        }
    };

    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0;
    }

    const handleDownload = async () => {
        // Lógica para obtener y establecer el objeto JSON en el estado jsonData
        let jsonReporte = await obtenerJsonReporte();
        let jsonReporteFinal = jsonReporte
        if (!isEmptyObject(jsonReporte)) {
            // Llama a la función de conversión y descarga
            handleClick(jsonReporteFinal)
        } else {
            setMessage("No se encontraron registros como para armar un reporte");
            setSeverity("warning");
            setSnackbarVisible(true);
        }
    };

    const validateFields = () => {
        // Obtiene los valores actuales de los campos
        const titleValue = reportTitle.trim();
        const armadoPorValue = armadoPor.trim();

        // Verifica si los campos cumplen con los requisitos
        const isTitleValid = titleValue.length >= 3;
        const isArmadoPorValid = armadoPorValue.length >= 3;

        if (isTitleValid && isArmadoPorValid) {
            return false;
        } else {
            return true;
        }

    };

    return (
        <div style={{ textAlign: "center" }}>
            {/* Select para elegir el tipo de archivo */}
            <FormControl fullWidth size="small" style={{ marginBottom: 20 }}>
                <InputLabel id="id_tipo_archivo">Tipo de Archivo</InputLabel>
                <Select
                    labelId="id_tipo_archivo"
                    value={fileType}
                    label="Tipo de Archivo"
                    onChange={(e) => setFileType(e.target.value)}>
                    <MenuItem value="CSV">CSV</MenuItem>
                    <MenuItem value="JSON">JSON</MenuItem>
                </Select>
            </FormControl>

            {/* Campos adicionales para el tipo de archivo CSV */}
            {fileType === 'CSV' && (
                <>
                    {/* Componente TransferList para seleccionar las columnas */}
                    <TransferList
                        availableColumns={columnas}
                        selectedColumns={selectedColumns}
                        onChange={(columns) => setSelectedColumns(columns)}
                    />
                    <TextField size="small" style={{ marginTop: 10 }} label="Título del Reporte" fullWidth value={reportTitle} onChange={(e) => setReportTitle(e.target.value)} required />
                    <TextField size="small" style={{ marginTop: 10 }} label="Armado Por" fullWidth value={armadoPor} onChange={(e) => setArmadoPor(e.target.value)} required />
                </>
            )}


            <Grid sx={{ mt: 1, marginTop: 5 }}>
                <Buttons
                    disableButton={fileType === "CSV" ? validateFields() : false}
                    onClickSubmit={fileType === "CSV" ? handleDownload : exportToJsonFile}
                    labelButtonSave={"Confirmar"}
                    buttonCerrar={props.toggleModal}
                />
            </Grid>
            <SnackbarMessenger
                open={snackbarVisible}
                message={message}
                severity={severity}
                close={() => {
                    setSnackbarVisible(false);
                }}
            />
        </div>
    );
};

export default ButtonsExportJsonCSVComprobantes;
import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getTipoDocumento } from "../../general_helpers/TipoDocumentoData"
import {esArray} from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";
import { Autocomplete, TextField } from "@mui/material";

const SelectorTipoDocumentos = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.selectedTipoDocumento;
  const [selectedTipoDocumento, setSelectedTipoDocumento] = useState("");

  const [dataDefaults, setDataDefaults] = useState([]);

  const documento_tipo = getFromLS("siam-documento_tipo").documento_tipo;

  useState(() => {
    const getCategorias = async () => {
      if (documento_tipo) {
         setDataDefaults(esArray(documento_tipo));
      }else {
        const response = await getTipoDocumento()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    getCategorias()
  
  }, []);


  useEffect(() => {
    setSelectedTipoDocumento(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);


  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setSelectedTipoDocumento(newValue);
      props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
    }else{
      props.handleChange(props.path, "");
    }  
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
        value={selectedTipoDocumento  ? selectedTipoDocumento : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.nombre || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Tipo de Documento" 
          disabled={disabled}
          />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorTipoDocumentosTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorTipoDocumentos";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorTipoDocumentosComponent = {
  renderer: withJsonFormsControlProps(SelectorTipoDocumentos),
  tester: SelectorTipoDocumentosTester,
};

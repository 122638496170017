export const schemaMetadatos = {
  type: "object",
  properties: {
    id_tipo_contenidos: {
      type: "string",
      title: "Id Tipo Contenido",
      description: "Id Tipo Contenido",
    },
    detalleContenido: {
      type: "string",
      title: "Detalle Contenido",
      description: "Detalle Contenido",
    },
    id: {
      type: "string",
      title: "id",
      description: "Id",
    },
    label_form: {
      type: "string",
      title: "Label del Formulario",
      description: "Ingresar Label del Formulario",
    },
    label_grilla: {
      type: "string",
      title: "Label de Grilla",
      description: "Ingresar Label de Grilla",
      enum: [
        "TEXTO",
        "TEXTO_DESDE",
        "TEXTO_HASTA",
        "NUMERO",
        "NUMERO_DESDE",
        "NUMERO_HASTA",
        "FECHA",
        "FECHA_DESDE",
        "FECHA_HASTA",
      ]
    },
    tipo_dato: {
      type: "string",
      title: "Tipo de Dato",
      description: "Ingresar Tipo de Dato",
      "enum": [
        "INT",
        "DATE",
        "CHAR",
      ]
    },
  },
  required: ["label_form", "label_grilla", "tipo_dato"],
};

import datos from "src/services/datos";
import Swal from "sweetalert2";
import { getCategoriaCuentas } from "../cuentas/helpers/CategoriaCuentasData";
import { esArray } from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css";

const categoriaCuentas = new datos("tipo_cuentas");

export const getTipoCuentas = async (auth) => {
  categoriaCuentas.addCondition("tipo_cuentas", "id", 0, ">");
  const response = await categoriaCuentas.get();
  return response.wsResponse.tipo_cuentas;
};

export const getTipoCuentasPaginated = async (limit, offset) => {
  categoriaCuentas.addCondition("tipo_cuentas", "id", 0, ">");
  categoriaCuentas.addLimitCondition(limit, offset);
  const response = await categoriaCuentas.get();
  const responseCategorias = await getCategoriaCuentas()
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    let dataResponse = esArray(response.wsResponse.tipo_cuentas)
    result = {
      data: dataResponse,
      total_records: response.wsResponse.total_records,
    };
    result.data.forEach(element => {
      element.tipo = responseCategorias.filter(cat => cat.id === element.id_categoria_cuentas)[0].nombre
    })
    return result;
  }
};

export const postTipoCuentas = async (cuenta) => {
  if (cuenta) {
    const data = {
      id: cuenta.id,
      detalle: cuenta.detalle,
      id_categoria_cuentas: cuenta.id_categoria_cuentas
    };
    const response = await categoriaCuentas.insert(data)
    if (response) {
      return response.wsResponse.tipo_cuentas;
    }
  }
  return false;
};

export const deleteTipoCuentas = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este Tipo de Cuenta?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
    customClass: {
      container: 'my-swal-delete'
    },
  }).then(async (result) => {
    if (result.isConfirmed) {

      const userData = new datos('tipo_cuentas');
      let data = {
        id: id
      }
      const response = await userData.delete(data);

      if (response.status === "true") {

        status = true;
        await Swal.fire(
          "Eliminado!",
          "El Tipo de Cuenta ha sido eliminado exitosamente.",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message
        });
      }
    }
  });
  return status;
};

import React, { useContext, useEffect } from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import { CajasContext } from "src/context/CajasContext/CajasContext";

export const FormFechaDesdeHasta = ({ valorDesde, setValorDesde, valorHasta, setValorHasta }) => {
  const { setListDatosFechaAltaCajas,
    formFechaAltaDisabled, setFormFechaAltaDisabled, setGrupoBusquedaFecha } = useContext(CajasContext);

  const handleChange = (newValue, datePicker) => {
    if (datePicker === "valorDesde") {
      var dateValueDesde = moment(newValue?.$d).format("YYYY-MM-DD")
      setValorDesde(dateValueDesde);
    } else {
      var dateValueHasta = moment(newValue?.$d).format("YYYY-MM-DD")
      setValorHasta(dateValueHasta);
    }
  };

  const limpiar = (e) => {
    setListDatosFechaAltaCajas({});
    setValorDesde("");
    setValorHasta("");
    setFormFechaAltaDisabled(false);
    setGrupoBusquedaFecha(false)
  };

  useEffect(() => {
    let data = {};
    setListDatosFechaAltaCajas({});
    if (valorDesde !== "") {
      data.fecha_desde = valorDesde;
      setGrupoBusquedaFecha(true)
      console.log('aaas')
    }

    if (valorHasta !== "") {
      data.fecha_hasta = valorHasta;
      setGrupoBusquedaFecha(true)
    }

    setListDatosFechaAltaCajas({ ...data });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorDesde, valorHasta]);


  return (
    <div>
      <Grid container justifyContent="end" style={{ marginBottom: '3px' }}>

        <Button
          variant="contained"
          onClick={limpiar}
          color="secondary"

          sx={{
            padding: '5px',
            minWidth: 'auto',
          }}
        >
          <ReplayIcon style={{
            padding: '0px',      // Ajusta el relleno (padding) para hacer el botón más pequeño
            position: 'center',

          }} />
        </Button>

      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <>
          <DatePicker
            name="valorDesde"
            label="Desde"
            inputFormat="DD/MM/YYYY"
            value={valorDesde === "" ? null : valorDesde}
            variant="outlined"
            onChange={date => handleChange(date, "valorDesde")}
            {...formFechaAltaDisabled ? { disabled: true } : {}}
            renderInput={(params) => <TextField name="valor" size="small" sx={{ m: 1, minWidth: 380 }}{...params} />}
          />
          <DatePicker
            name="valorHasta"
            label="Hasta"
            inputFormat="DD/MM/YYYY"
            value={valorHasta === "" ? null : valorHasta}
            variant="outlined"
            onChange={date => handleChange(date, "valorHasta")}
            {...formFechaAltaDisabled ? { disabled: true } : {}}
            renderInput={(params) => <TextField name="valor" size="small" sx={{ m: 1, minWidth: 380 }}{...params} />}
          />
        </>
      </LocalizationProvider>
    </div>);
};

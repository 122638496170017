export const uiSchemaAlicuotasIVAForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/nombre",
       
        },
        {
          type: "Control",
          scope: "#/properties/valor",
      
        },
        {
          type: "Control",
          scope: "#/properties/codigo_afip",
          
        },
      ],
    },
  ],
};

import { headers } from "./header";
import { bodyRequest } from "./body";


export const serviceQuest = (props) => {
    const request = {
        method: 'POST',
        headers: headers(),
        body: bodyRequest(props)
    };

    return request;
}

export default serviceQuest
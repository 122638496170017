import React, { useContext, useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { ContenidosProcesoList } from '../components/ContenidosProcesoList';
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { TheLayoutContext } from 'src/context/TheLayoutContext';
import AWS from 'aws-sdk';
import { configS3 } from "src/configs/config";
import { iconosMarcadoContenidos } from '../components/estadosCajas';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { ConfimDialogComponent } from "src/general_components/ConfirmDialogComponent";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { obtenerProcesosPorPerfilYMenu } from 'src/general_components/functionEsArray';


export const ContenidosProcesoAdmin = () => {
  const { setChildFooter, setExpand, procesoSeleccionado, setProcesoSeleccionado, setChangeProceso, changeProceso, setNewCheck,
    setButtonMarcaDisabled, setButtonMarcarTodoDisabled, setButtonDesmarcaDisabled, setS3Json, setSubProceso, setSubProcesoChange, valueSubproceso,
    setValueSubproceso, s3Json, procesandoMarcas } = useContext(TheLayoutContext);

  const restricciones = obtenerProcesosPorPerfilYMenu();
  const getDefaultProceso = (procesoSeleccionado) => {
    switch (procesoSeleccionado?.id) {
      case 2:
        return { retiro_contenido_parcial: "1", tituloModulo: "Retiro de Contenido Parcial" };
      case 3:
        return { retiro_contenido_total: "1", tituloModulo: "Retiro de Contenido Total" };
      case 4:
        return { quita_total_contenidos: "1", tituloModulo: "Quita Total de Contenidos" };
      case 5:
        return { devolucion_contenidos_parcial: "1", tituloModulo: "Devolucion de Contenidos Parcial" };
      case 6:
        return { devolucion_contenidos_total: "1", tituloModulo: "Devolucion de Contenido Total" };
      default:
        return { consulta_general: "1", tituloModulo: "Consulta General", };
    }

  };

  const { retiro_contenido_parcial, retiro_contenido_total, quita_total_contenidos, devolucion_contenidos_parcial,
    devolucion_contenidos_total, tituloModulo, consulta_general } = getDefaultProceso(procesoSeleccionado);


  // eslint-disable-next-line no-unused-vars
  const [selectedColor, setSelectedColor] = useState('#ffffff');
  const [subProcesoDisabled, setSubProcesoDisabled] = useState(true);

  const [optionsSubProceso, setOptionsSubProceso] = useState([]);
  const [modalStateConfirmEliminarSubproceso, setModalStateConfirmEliminarSubproceso] = useState(false);
  const [mensajeConfirmEliminar, setMensajeConfirmEliminar] = useState("");
  const [paramSubprocesoDelete, setParamSubprocesoDelete] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');

  const datos = {
    retiro_contenido_parcial: retiro_contenido_parcial ? "1" : "0",
    retiro_contenido_total: retiro_contenido_total ? "1" : "0",
    quita_total_contenidos: quita_total_contenidos ? "1" : "0",
    devolucion_contenidos_parcial: devolucion_contenidos_parcial ? "1" : "0",
    procesoSeleccionado: procesoSeleccionado,
    devolucion_contenidos_total: devolucion_contenidos_total ? "1" : "0",
    consulta_general: consulta_general ? "1" : "0",
    tituloProceso: tituloModulo,
    changeProceso: changeProceso,
    valueSubproceso: valueSubproceso,
    restricciones: restricciones
  };


  let options = [
    { id: 1, label: 'Consulta General', value: 1, category: 'Consultas', color: '' },
    { id: 2, label: 'Retiro de Contenido Parcial', value: 2, category: 'Retiros', color: '#5e492d', icono: 'retiro_contenido_parcial' },
    { id: 3, label: 'Retiro de Contenido Total', value: 3, category: 'Retiros', color: '#7b6447', icono: 'retiro_contenido_total' },
    { id: 4, label: 'Quita Total de Contenidos', value: 4, category: 'Retiros', color: '#998062', icono: 'quita_total_contenidos' },
    { id: 5, label: 'Devolucion de Contenidos Parcial', value: 5, category: 'Devoluciones', color: '#c44ff9', icono: 'devolucion_contenido_total' },
    { id: 6, label: 'Devolucion de Contenido Total', value: 6, category: 'Devoluciones', color: '#a832e7', icono: 'devolucion_contenidos_parcial' },
  ];

  if (restricciones) {
    options = options.filter(option => restricciones[option.label]);
  }

  useEffect(() => {
    localStorage.setItem("proceso_seleccionado", JSON.stringify(procesoSeleccionado));
  }, [procesoSeleccionado]);

  const onChange = async (event, value) => {
    setChildFooter(null);
    setExpand(false);
    setProcesoSeleccionado(event);
    setChangeProceso(true);
    setNewCheck([])
    setButtonMarcaDisabled(true)
    setButtonDesmarcaDisabled(true)
    setButtonMarcarTodoDisabled(true)
    //setSelectedColor(event ? event.color : null);
    await cargaValoresS3(event)
  };


  const onChangeSuproceso = async (event, value) => {
    setSubProceso(event.label)
    setNewCheck([])
    setButtonMarcaDisabled(true)
    setButtonMarcarTodoDisabled(true)
    setButtonDesmarcaDisabled(true)
    setValueSubproceso(event)
    setSubProcesoChange(true)

    // Se utiliza al momento de hacer la busqueda en una pestania nueva
    localStorage.setItem('subProcesoSelected', JSON.stringify(event));

  };

  const obtenerInicialesMayusculas = (palabra) => {
    // Dividir el nombre completo en palabras
    const palabras = palabra.split(' ');

    // Obtener las primeras letras de cada palabra y convertirlas a mayúsculas
    const inicialesMayusculas = palabras.map(palabra => palabra.charAt(0).toUpperCase());

    // Unir las iniciales para formar el resultado
    return inicialesMayusculas.join('');
  }

  const generateOptionsSubProceso = (proceso, datos) => {
    let nombre = JSON.parse(localStorage.getItem("siam-user-data")).nombre;
    let apellido = JSON.parse(localStorage.getItem("siam-user-data")).apellido;
    let userId = JSON.parse(localStorage.getItem("siam-user-data")).id;
    let userName = nombre + ' ' + apellido;
    let user = obtenerInicialesMayusculas(userName)
    let process = obtenerInicialesMayusculas(proceso.label)

    let procesoActual = obtenerProceso(proceso);

    let originalArray = [];

    if (datos && datos[procesoActual]?.[userId]) {
      originalArray = Object.keys(datos[procesoActual][userId]);
    }

    let arrCantidades = []
    for (let i = 0; i < originalArray.length; i++) {
      const subproceso = originalArray[i];
      const idCliente = Object.keys(datos[procesoActual][userId][subproceso])[0];
      const cantidad = datos[procesoActual][userId][subproceso][idCliente].id[0].length;
      arrCantidades.push(cantidad);
    }

    const newArray = originalArray?.map((label, index) => ({
      id: index + 1,
      label: label,
      value: index + 1,
      estado: `(Existe)(${arrCantidades[index]})`,
    }));

    let ultimoObjetoExistente = newArray.filter(objeto => objeto.estado.includes('Existe')).pop();
    let numeroEnLabel = 0

    if (ultimoObjetoExistente !== undefined) {
      numeroEnLabel = parseInt(ultimoObjetoExistente.label.match(/\d+/)[0], 10);
    }

    newArray.push({
      id: newArray.length + 1,
      label: user + '-' + process + '-' + (numeroEnLabel + 1),
      value: newArray.length + 1,
      estado: '(Nuevo)',
    });


    setSubProcesoDisabled(false)

    setOptionsSubProceso(newArray)
  }

  const obtenerProceso = (procesoSeleccionado) => {
    let proceso = '';
    if (procesoSeleccionado && procesoSeleccionado?.label !== 'Consulta General') {
      const palabra = procesoSeleccionado.label.split(/[ /]/);
      // Usamos una expresión regular para dividir por espacio o barra
      const filtro = palabra.filter(word => word.toLowerCase() !== 'de');
      proceso = filtro.join('_').toLowerCase();
    } else {
      let url = window.location.href;
      const arrUrl = url.split("/");
      proceso = arrUrl[arrUrl.length - 1];

      if (proceso.includes("?")) {

        const arrUrl = url.split("?");
        proceso = arrUrl[arrUrl.length - 1];

        const arrUrls = proceso.split("=");
        proceso = arrUrls[arrUrls.length - 2];

        // console.log(proceso,'22222222')
        proceso = proceso === 'mudanza' ? 'mudanza_cajas' : proceso
      } else {
        //console.log(proceso,'prossssss')
        proceso = proceso === 'proceso' ? 'ubicacion_cajas' : proceso
      }
    }
    return proceso
  }


  const cargaValoresS3 = async (procesoSelected) => {
    try {
      AWS.config.update(configS3);

      const bucketName = configS3.bucketName;
      const ownerCode = JSON.parse(localStorage.getItem("clientData")).owner_code.toLowerCase();
      const key = ownerCode + '-marks.json';

      const s3 = new AWS.S3();
      const params = {
        Bucket: bucketName,
        Key: key,
      };

      let datos = {};
      const dataS3 = await s3.getObject(params).promise();
      const jsonString = dataS3.Body.toString('utf-8');
      datos = JSON.parse(jsonString).procesos;

      setValueSubproceso(null)
      generateOptionsSubProceso(procesoSelected, datos)

      setS3Json(datos);
    } catch (error) {
      console.log('Error al descargar o parsear el archivo JSON desde S3:', error);
      // return Promise.reject(error);
    }
  };

  // Se utiliza al momento de hacer la busqueda en una pestania nueva
  if (window.subProceso && localStorage.getItem("subProceso")) {

    const cargarYFiltrar = async () => {
      await cargaValoresS3(procesoSeleccionado);
      setValueSubproceso(JSON.parse(localStorage.getItem("subProcesoSelected")))

    };

    // Llama a la función async
    cargarYFiltrar();
    localStorage.removeItem('subProceso');
  }


  // const handleClear = (nulo, params, value) => {
  //   setValueSubproceso(null);
  //   eliminarSubprocesoSelected(params)
  // };

  const handleClear = (nulo, params, value) => {
    toggleModalConfirmEliminarSubproceso()
    setParamSubprocesoDelete(params)
  };

  const handleConfirmEliminarSubproceso = async () => {
    setValueSubproceso(null);
    eliminarSubprocesoSelected(paramSubprocesoDelete)
    setModalStateConfirmEliminarSubproceso(!modalStateConfirmEliminarSubproceso);
  };

  const toggleModalConfirmEliminarSubproceso = () => {
    setMensajeConfirmEliminar("¿Esta seguro que quiere Eliminar el Subproceso?")
    setModalStateConfirmEliminarSubproceso(!modalStateConfirmEliminarSubproceso);
  };



  const eliminarSubprocesoSelected = async (subprocesoSelected) => {

    let procesoActual = obtenerProceso(procesoSeleccionado);

    let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;

    delete s3Json?.[procesoActual]?.[id_user]?.[subprocesoSelected.label];


    let marcasAWS = { procesos: s3Json };

    AWS.config.update(configS3);
    const s3 = new AWS.S3();

    const jsonString = JSON.stringify(marcasAWS, null, 2);
    const file = new Blob([jsonString], { type: 'application/json' });

    const bucketName = configS3.bucketName;
    const ownerCode = JSON.parse(localStorage.getItem("clientData")).owner_code.toLowerCase();
    const key = ownerCode + '-marks.json';

    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
      ContentType: 'application/json',
    };

    await s3.upload(params).promise();
    cargaValoresS3(procesoSeleccionado)

    setMessage('Se elimino correctamente el subproceso!');
    setSeverity("success");
    setSnackbarVisible(true);

  };

  useEffect(() => {
    if (procesandoMarcas) {
      generateOptionsSubProceso(procesoSeleccionado, s3Json);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procesandoMarcas]);


  return (
    <Grid style={{ marginBottom: "50px", backgroundColor: "#FFFFFF", paddingTop: 10 }}>
      <Grid
        style={{
          background: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
          marginBottom: 20,
          height: 50,
        }}
      >
        <Typography
          variant="h2"
          component="div"
          sx={{
            fontSize: "1vw",
            fontWeight: 'bold',
            fontFamily: 'Roboto, sans-serif',
            paddingLeft: 3,
            color: '#0A395F',
          }}
        >
          Administracion de Procesos - Contenidos
        </Typography>

        <Autocomplete
          size='small'
          id="checkboxes-tags-demo"
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={procesoSeleccionado ? procesoSeleccionado : null}
          groupBy={(option) => option.category}
          disableClearable
          onChange={(e, selectedOption) => {
            onChange(selectedOption);
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(props, data) => (
            <div {...props}>
              {iconosMarcadoContenidos[data.icono] && (
                <span style={{ marginRight: '8px', color: data.color }}>{iconosMarcadoContenidos[data.icono]}</span>
              )}
              <Typography variant="body1" style={{ color: props.selected ? 'blue' : 'black',fontSize: '0.8vw' }}>
                {data.label}
              </Typography>
            </div>
          )}
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '1vw',
            fontFamily: 'Public Sans, sans-serif',
            color: "#0A395F",
          }}
          style={{ minWidth: "15vw" }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={<Typography style={{ fontSize: '0.8vw' }}>Proceso</Typography>}
              placeholder="Proceso"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: '0.8vw' },
                startAdornment: (
                  <InputAdornment position='end'>
                    {procesoSeleccionado && iconosMarcadoContenidos[procesoSeleccionado.icono] && (
                      <IconButton size="small" style={{ color: procesoSeleccionado.color }}>
                        {iconosMarcadoContenidos[procesoSeleccionado.icono]}
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          size='small'
          id="checkboxes-tags-demo2"
          options={optionsSubProceso}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={valueSubproceso ? valueSubproceso : null}
          groupBy={(option) => option.category}
          disabled={procesoSeleccionado?.id !== 1 ? subProcesoDisabled : true}
          onChange={(e, selectedOption) => {
            onChangeSuproceso(selectedOption);
          }}
          disableClearable
          getOptionLabel={(option) => option.label}
          renderOption={(props, data) => (
            <Typography key={data.id} {...props} variant="body1" style={{ backgroundColor: data.color }}>
              {data.label} {data.estado}
            </Typography>
          )}
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 13,
            fontFamily: 'Public Sans, sans-serif',
            fontWeight: 400,
            color: "#0A395F",
          }}
          style={{ minWidth: "15vw" , backgroundColor: selectedColor, }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={<Typography style={{ fontSize: '0.8vw' }}>Sub Proceso</Typography>}
              placeholder="Sub Proceso"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: '0.8vw' },
                endAdornment: (
                  procesoSeleccionado?.id !== 1 && valueSubproceso && (
                    <IconButton
                      size="small"
                      onClick={handleClear.bind(null, params, valueSubproceso)}
                      style={{
                        marginLeft: '-25px', // Ajusta este valor según sea necesario
                        color: 'rgba(0, 0, 0, 0.54)',
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )
                ),
              }}
            />
          )}
        />
        <CustomBreadcrumbs />
      </Grid>
      <ConfimDialogComponent
        open={modalStateConfirmEliminarSubproceso}
        modalName={"Confirmar Eliminar Subproceso"}
        mensaje={mensajeConfirmEliminar}
        handleConfirm={handleConfirmEliminarSubproceso}
        handleClose={toggleModalConfirmEliminarSubproceso}
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={8000}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
      <CajasProvider>
        <Grid item xs={12} md={12}>
          <ContenidosProcesoList datos={datos} />
        </Grid>
      </CajasProvider>
    </Grid>
  );
};


// EJEMPLO AUTOCOMPLETE CON ICONOS

// <Autocomplete
//           size='small'
//           id="checkboxes-tags-demo"
//           options={options}
//           isOptionEqualToValue={(option, value) => option.id === value.id}
//           value={procesoSeleccionado ? procesoSeleccionado : null}
//           groupBy={(option) => option.category}
//           disableClearable
//           onChange={(e, selectedOption) => {
//             onChange(selectedOption);
//           }}
//           getOptionLabel={(option) => option.label}
//           renderOption={(props, data) => (
//             <div key={data.id} style={{ display: 'flex', alignItems: 'center' }}>
//               <AddIcon color='data.color' style={{ marginRight: 8, color: data.color }} />
//               <AddIcon style={{ marginRight: 8, color: data.color }} />
//               <Typography {...props} variant="body1" style={{ backgroundColor: data.color }}>
//                 {data.label}
//               </Typography>
//               <AddIcon style={{ marginRight: 8, color: data.color }} />
//             </div>
//           )}
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             fontSize: 13,
//             fontFamily: 'Public Sans, sans-serif',
//             fontWeight: 400,
//             color: "#0A395F",
//           }}
//           style={{ minWidth: 320 }}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               variant="outlined"
//               label="Proceso"
//               placeholder="Proceso"
//               InputProps={{
//                 ...params.InputProps,
//                 endAdornment: (
//                   <AddIcon style={{ marginLeft: 8 }} />
//                 ),
//               }}
//             />
//           )}
//         />
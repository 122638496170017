import React, { useContext, useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { CajasProcesoList } from '../components/CajasProcesoList';
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { TheLayoutContext } from 'src/context/TheLayoutContext';
import AWS from 'aws-sdk';
import { configS3 } from "src/configs/config";
import { iconosMarcadoCajas } from '../components/estadosCajas';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { ConfimDialogComponent } from "src/general_components/ConfirmDialogComponent";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { SimpleBackdrop } from 'src/general_components/Backdrop';
import { obtenerProcesosPorPerfilYMenu } from 'src/general_components/functionEsArray';

export const CajasProcesoAdmin = () => {
  const { setChildFooter, setExpand, procesoSeleccionado, setProcesoSeleccionado, setChangeProceso, changeProceso, setNewCheck,
    setButtonMarcaDisabled, setButtonDesmarcaDisabled, setS3Json, setSubProceso, setSubProcesoChange,
    valueSubproceso, setValueSubproceso, s3Json, setButtonMarcarTodoDisabled, procesandoMarcas } = useContext(TheLayoutContext);

  const restricciones = obtenerProcesosPorPerfilYMenu();
  
  const getDefaultProceso = (procesoSeleccionado) => {
    switch (procesoSeleccionado?.id) {
      case 2:
        return { retiro_cajas: "1", tituloModulo: "Retiro de Cajas" };
      case 3:
        return { ret_definitivo: "1", tituloModulo: "Retiro Definitivo " };
      case 4:
        return { baja: "1", tituloModulo: "Baja/Expurgue" };
      case 5:
        return { devolucion_cajas: "1", tituloModulo: "Devolucion de Cajas" };
      case 6:
        return { procesoGenerica: true, tituloModulo: "Ubicacion de Cajas", ubicacion_caja: true };
      case 7:
        return { ubicacion_temporal: "1", tituloModulo: "Ubicacion Temporal de Cajas" };
      case 8:
        return { mudanza: "1", tituloModulo: "Mudanza de Cajas" };
      default:
        return { tituloModulo: "Consulta General", color: '', icono: '' };
    }

  };

  const { procesoGenerica, mudanza, ubicacion_temporal, retiro_cajas, devolucion_cajas,
    ubicacion_caja, tituloModulo, ret_definitivo, baja } = getDefaultProceso(procesoSeleccionado);

  // eslint-disable-next-line no-unused-vars
  const [selectedColor, setSelectedColor] = useState('#ffffff');
  const [subProcesoDisabled, setSubProcesoDisabled] = useState(true);
  const [modalStateConfirmEliminarSubproceso, setModalStateConfirmEliminarSubproceso] = useState(false);
  const [mensajeConfirmEliminar, setMensajeConfirmEliminar] = useState("");
  const [paramSubprocesoDelete, setParamSubprocesoDelete] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  const [optionsSubProceso, setOptionsSubProceso] = useState([]);
  // Datos esenciales que se utilizaran en distintas funciones como asi tambien para el armado del payload
  const datos = {
    valorMudanza: mudanza ? "1" : "0",
    valorUbicacionTemporal: ubicacion_temporal ? "1" : "0",
    valorRetiroCaja: retiro_cajas ? "1" : "0",
    valorDevolucionCaja: devolucion_cajas ? "1" : "0",
    procesoSeleccionado: procesoSeleccionado,
    procesoGenerica: procesoGenerica ? "1" : "0",
    ubicacion_caja: ubicacion_caja ? "1" : "0",
    tituloProceso: tituloModulo,
    ret_preferencial: "0",
    ret_urgente: "0",
    devolucion_caja: "0",
    ret_definitivo: ret_definitivo ? "1" : "0",
    baja: baja ? "1" : "0",
    changeProceso: changeProceso,
    valueSubproceso: valueSubproceso,
    restricciones: restricciones
  };

  // Array de elementos que va a tener el selector de procesos
  let options = [
    { id: 1, label: 'Consulta General', value: 1, category: 'Consultas', color: '#ffffff', icono: '' },
    { id: 2, label: 'Retiro de Cajas', value: 3, category: 'Retiros', color: "#003e90", icono: "retiro_cajas" },
    { id: 3, label: 'Retiro Definitivo', value: 4, category: 'Retiros', color: "#2e58af", icono: "retiro_definitivo" },
    { id: 4, label: 'Baja/Expurgue', value: 5, category: 'Retiros', color: "#4c73ce", icono: "baja_expurgue" },
    { id: 5, label: 'Devolucion de Cajas', value: 6, category: 'Devoluciones', color: "#ca7821", icono: "devolucion_cajas" },
    { id: 6, label: 'Ubicacion de Cajas', value: 7, category: 'Ubicacion', color: "#097835", icono: "ubicacion_cajas" },
    { id: 7, label: 'Ubicacion Temporal', value: 8, category: 'Ubicacion', color: "#149650", icono: "ubicacion_temporal" },
    { id: 8, label: 'Mudanza de Cajas', value: 9, category: 'Mudanza', color: "#1cb66c", icono: "mudanza_cajas" },
  ];

  if (restricciones) {
    options = options.filter(option => restricciones[option.label]);
  }


  useEffect(() => {
    localStorage.setItem("proceso_seleccionado", JSON.stringify(procesoSeleccionado));
  }, [procesoSeleccionado]);

  // Funcion del selector de procesos que cambia los valores de acuerdo a lo seleccionado
  const onChange = async (event, value) => {
    setChildFooter(null);
    setExpand(false);
    setProcesoSeleccionado(event);
    setChangeProceso(true);
    setNewCheck([])
    setButtonMarcaDisabled(true)
    setButtonDesmarcaDisabled(true)
    setButtonMarcarTodoDisabled(true)
    //setSelectedColor(event ? event.color : null);
    await cargaValoresS3(event)
  };


  const onChangeSuproceso = async (event, value) => {
    setSubProceso(event?.label || "")
    setNewCheck([])
    setButtonMarcaDisabled(true)
    setButtonDesmarcaDisabled(true)
    setButtonMarcarTodoDisabled(true)
    setValueSubproceso(event)
    setSubProcesoChange(true)

    // Se utiliza al momento de hacer la busqueda en una pestania nueva
    localStorage.setItem('subProcesoSelected', JSON.stringify(event));

  };

  const obtenerInicialesMayusculas = (palabra) => {
    // Dividir el nombre completo en palabras
    const palabras = palabra.split(' ');

    // Obtener las primeras letras de cada palabra y convertirlas a mayúsculas
    const inicialesMayusculas = palabras.map(palabra => palabra.charAt(0).toUpperCase());

    // Unir las iniciales para formar el resultado
    return inicialesMayusculas.join('');
  }

  const generateOptionsSubProceso = (proceso, datos) => {

    let nombre = JSON.parse(localStorage.getItem("siam-user-data")).nombre;
    let apellido = JSON.parse(localStorage.getItem("siam-user-data")).apellido;
    let userId = JSON.parse(localStorage.getItem("siam-user-data")).id;
    let userName = nombre + ' ' + apellido;
    let user = obtenerInicialesMayusculas(userName)
    let process = obtenerInicialesMayusculas(proceso.label)

    let procesoActual = obtenerProceso(proceso);

    let originalArray = [];

    if (datos && datos[procesoActual]?.[userId]) {
      originalArray = Object.keys(datos[procesoActual][userId]);
    }

    let arrCantidades = []
    for (let i = 0; i < originalArray.length; i++) {
      const subproceso = originalArray[i];
      const idCliente = Object.keys(datos[procesoActual][userId][subproceso])[0];
      let cantidad = 0
      if (
        datos &&
        datos[procesoActual] &&
        datos[procesoActual][userId] &&
        datos[procesoActual][userId][subproceso] &&
        datos[procesoActual][userId][subproceso][idCliente] &&
        datos[procesoActual][userId][subproceso][idCliente].id
      ) {
        cantidad = datos[procesoActual][userId][subproceso][idCliente].id[0]?.length;
      }
      arrCantidades.push(cantidad);
    }

    const newArray = originalArray?.map((label, index) => ({
      id: index + 1,
      label: label,
      value: index + 1,
      estado: `(Existe)(${arrCantidades[index]})`,
    }));

    let ultimoObjetoExistente = newArray.filter(objeto => objeto.estado.includes('Existe')).pop();
    let numeroEnLabel = 0

    if (ultimoObjetoExistente !== undefined) {
      numeroEnLabel = parseInt(ultimoObjetoExistente.label.match(/\d+/)[0], 10);
    }

    newArray.push({
      id: newArray.length + 1,
      label: user + '-' + process + '-' + (numeroEnLabel + 1),
      value: newArray.length + 1,
      estado: '(Nuevo)',
    });

    if (procesoSeleccionado.id !== 1) { }
    setSubProcesoDisabled(false)
    setOptionsSubProceso(newArray)
  }



  const obtenerProceso = (procesoSeleccionado) => {
    let proceso = '';
    if (procesoSeleccionado && procesoSeleccionado?.label !== 'Consulta General') {
      const palabra = procesoSeleccionado.label.split(/[ /]/);
      // Usamos una expresión regular para dividir por espacio o barra
      const filtro = palabra.filter(word => word.toLowerCase() !== 'de');
      proceso = filtro.join('_').toLowerCase();
    } else {
      let url = window.location.href;
      const arrUrl = url.split("/");
      proceso = arrUrl[arrUrl.length - 1];

      if (proceso.includes("?")) {

        const arrUrl = url.split("?");
        proceso = arrUrl[arrUrl.length - 1];

        const arrUrls = proceso.split("=");
        proceso = arrUrls[arrUrls.length - 2];


        proceso = proceso === 'mudanza' ? 'mudanza_cajas' : proceso
      } else {

        proceso = proceso === 'proceso' ? 'ubicacion_cajas' : proceso
      }
    }
    return proceso
  }

  const cargaValoresS3 = async (procesoSelected) => {

    try {
      AWS.config.update(configS3);
      const s3 = new AWS.S3();

      const bucketName = configS3.bucketName;
      const ownerCode = JSON.parse(localStorage.getItem("clientData")).owner_code.toLowerCase();
      const key = ownerCode + '-marks.json';

      let datos = {};

      try {
        const dataS3 = await s3.getObject({ Bucket: bucketName, Key: key }).promise();
        const existingData = JSON.parse(dataS3.Body.toString('utf-8'));

        if (typeof existingData === 'object' && existingData !== null) {
          datos = existingData.procesos || {};
        } else {
          console.warn('El contenido en S3 no es un objeto válido. Creando un nuevo objeto vacío.');
        }
      } catch (error) {
        if (error.code === 'NoSuchKey') {
          console.warn('El archivo no existe en S3. Creando un nuevo objeto vacío.');

          // Agrega un nuevo objeto vacío en caso de que el archivo no exista
          await s3.putObject({
            Bucket: bucketName,
            Key: key,
            Body: JSON.stringify({ procesos: {} }),
            ContentType: 'application/json',
          }).promise();
        } else {
          console.error('Error al obtener el archivo desde S3:', error);
          throw error;
        }
      }

      setValueSubproceso(null);
      generateOptionsSubProceso(procesoSelected, datos);
      setS3Json(datos);
    } catch (error) {
      console.log('Error general:', error);
    }
  }

  // Se utiliza al momento de hacer la busqueda en una pestania nueva
  if (window.subProceso && localStorage.getItem("subProceso")) {
    const cargarYFiltrar = async () => {
      await cargaValoresS3(procesoSeleccionado);
      setValueSubproceso(JSON.parse(localStorage.getItem("subProcesoSelected")))

    };

    // Llama a la función async
    cargarYFiltrar();
    localStorage.removeItem('subProceso');
  }



  useEffect(() => {
    if (procesandoMarcas) {
      generateOptionsSubProceso(procesoSeleccionado, s3Json);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procesandoMarcas]);

  const handleClear = (nulo, params, value) => {
    toggleModalConfirmEliminarSubproceso()
    setParamSubprocesoDelete(params)
  };

  const handleConfirmEliminarSubproceso = async () => {
    setLoading(true);
    setValueSubproceso(null);
    await eliminarSubprocesoSelected(paramSubprocesoDelete)
    setLoading(false);
    setModalStateConfirmEliminarSubproceso(!modalStateConfirmEliminarSubproceso);

  };

  const toggleModalConfirmEliminarSubproceso = () => {
    setMensajeConfirmEliminar("¿Esta seguro que quiere Eliminar el Subproceso?")
    setModalStateConfirmEliminarSubproceso(!modalStateConfirmEliminarSubproceso);
  };


  const eliminarSubprocesoSelected = async (subprocesoSelected) => {

    let procesoActual = obtenerProceso(procesoSeleccionado);

    let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;

    delete s3Json?.[procesoActual]?.[id_user]?.[subprocesoSelected.label];


    let marcasAWS = { procesos: s3Json };

    AWS.config.update(configS3);
    const s3 = new AWS.S3();

    const jsonString = JSON.stringify(marcasAWS, null, 2);
    const file = new Blob([jsonString], { type: 'application/json' });

    const bucketName = configS3.bucketName;
    const ownerCode = JSON.parse(localStorage.getItem("clientData")).owner_code.toLowerCase();
    const key = ownerCode + '-marks.json';

    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
      ContentType: 'application/json',
    };

    await s3.upload(params).promise();
    cargaValoresS3(procesoSeleccionado)

    setMessage('Se elimino correctamente el subproceso!');
    setSeverity("success");
    setSnackbarVisible(true);
  };

  return (
    <Grid style={{ marginBottom: "50px", backgroundColor: "#FFFFFF", paddingTop: 10 }}>
      {loading ? (<SimpleBackdrop open={true}></SimpleBackdrop>) : (<></>)}
      <Grid
        style={{
          background: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
          marginBottom: 20,
          height: 50,
        }}
      >
        <Typography
          variant="h2"
          component="div"
          sx={{
            fontSize: "1vw",
            fontWeight: 'bold',
            fontFamily: 'Roboto, sans-serif',
            paddingLeft: 3,
            color: '#0A395F',
          }}
        >
          Administracion de Procesos
        </Typography>
        <Grid>

        </Grid>

        <Autocomplete
          size='small'
          id="checkboxes-tags-demo"
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={procesoSeleccionado ? procesoSeleccionado : null}
          groupBy={(option) => option.category}
          disableClearable
          onChange={(e, selectedOption) => {
            onChange(selectedOption);
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(props, data) => (
            <div {...props}>
              {iconosMarcadoCajas[data.icono] && (
                <span style={{ marginRight: '8px', color: data.color }}>{iconosMarcadoCajas[data.icono]}</span>
              )}
              <Typography variant="body1" style={{ color: props.selected ? 'blue' : 'black', fontSize: '0.8vw' }}>
                {data.label}
              </Typography>
            </div>
          )}
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '1vw', // Tamaño de fuente relativo al ancho de la ventana
            fontFamily: 'Public Sans, sans-serif',
            color: "#0A395F",
          }}
          style={{ minWidth: "15vw" }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={<Typography style={{ fontSize: '0.8vw' }}>Proceso</Typography>}
              placeholder="Proceso"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: '0.8vw' },
                startAdornment: (
                  <InputAdornment position='end'>
                    {procesoSeleccionado && iconosMarcadoCajas[procesoSeleccionado.icono] && (
                      <IconButton size="small" style={{ color: procesoSeleccionado.color }}>
                        {iconosMarcadoCajas[procesoSeleccionado.icono]}
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}

            />
          )}
        />

        <Autocomplete
          size='small'
          id="checkboxes-tags-demo2"
          options={optionsSubProceso}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={valueSubproceso ? valueSubproceso : null}
          groupBy={(option) => option.category}
          disabled={procesoSeleccionado?.id !== 1 ? subProcesoDisabled : true}
          onChange={(e, selectedOption) => {
            onChangeSuproceso(selectedOption);
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(props, data) => (
            <Typography key={data.id} {...props} variant="body1" style={{ backgroundColor: data.color, fontSize: '0.8vw' }}>
              {data.label} {data.estado}
            </Typography>
          )}
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '1vw', // Tamaño de fuente relativo al ancho de la ventana
            fontFamily: 'Public Sans, sans-serif',
            color: "#0A395F",
          }}
          style={{ minWidth: "15vw", backgroundColor: selectedColor }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={<Typography style={{ fontSize: '0.8vw' }}>Sub Proceso</Typography>}
              placeholder="Sub Proceso"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: '0.8vw' },
                endAdornment: (
                  procesoSeleccionado?.id !== 1 && valueSubproceso && (
                    <InputAdornment position='end'>
                      <IconButton
                        size="small"
                        onClick={handleClear.bind(null, params, valueSubproceso)}
                      >
                        <ClearIcon size="small" style={{
                          // Ajusta este valor según sea necesario
                          color: 'rgba(0, 0, 0, 0.54)',
                          fontSize: '1.5vw'
                        }} />
                      </IconButton>
                    </InputAdornment>
                  )
                ),
              }}
            />
          )}
        />
        <CustomBreadcrumbs />
      </Grid>
      <ConfimDialogComponent
        open={modalStateConfirmEliminarSubproceso}
        modalName={"Confirmar Eliminar Subproceso"}
        mensaje={mensajeConfirmEliminar}
        handleConfirm={handleConfirmEliminarSubproceso}
        handleClose={toggleModalConfirmEliminarSubproceso}
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={8000}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
      <CajasProvider>
        <Grid item xs={12} md={12}>
          <CajasProcesoList datos={datos} />
        </Grid>
      </CajasProvider>
    </Grid>
  );
};
// import datos from "src/services/datos";


// export const getCuentasServiciosInternet = async() => {    
//     const objectCuentasServiciosInternet = new datos("cuentas_servicios_internet")
    
//     objectCuentasServiciosInternet.addCondition("cuentas_servicios_internet","id",0,">")
    
//     const response = await objectCuentasServiciosInternet.get()
//     if(response){
//             return response.wsResponse.cuentas_servicios_internet
//     }
//     return response
// }

// export const getCuentasServiciosInternetByCuenta = async(idCuenta) => {    
//     const objectCuentasServiciosInternet = new datos("cuentas_servicios_internet")
    
//     objectCuentasServiciosInternet.addCondition("cuentas_servicios_internet","id_cuentas",parseInt(idCuenta),"=")
    
//     const response = await objectCuentasServiciosInternet.get()
//     if(response){
//             return response.wsResponse.cuentas_servicios_internet
//     }
//     return response
// }

// export const postCuentasServiciosInternet = async(data) => {
//     const objectCuentasServiciosInternet = new datos("cuentas_servicios_internet")
    
//     const response = objectCuentasServiciosInternet.insert(data)
  
//     if (response){
//       return response.wsResponse.cuentas_servicios_internet
//     }
//     return response
    
//   }
  
//   export const deleteCuentasServiciosInternet = async(data) => {
//   }


  import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css"


export const getCuentasServiciosInternet = async (id_cuentas) => {
  const cuentas_servicios_internet = new datos("cuentas_servicios_internet");
  cuentas_servicios_internet.addCondition("cuentas_servicios_internet", "id", 0, ">");
  cuentas_servicios_internet.addCondition("cuentas_servicios_internet", "id_cuentas", parseInt(id_cuentas), "=")
  const response = await cuentas_servicios_internet.get();
  return esArray(response.wsResponse.cuentas_servicios_internet);
};

export const getCuentasServiciosInternetPaginated = async (limit, offset, id_cuentas) => {
  const cuentas_servicios_internet = new datos("cuentas_servicios_internet");
  cuentas_servicios_internet.addCondition("cuentas_servicios_internet", "id", 0, ">");
  cuentas_servicios_internet.addCondition("cuentas_servicios_internet", "id_cuentas", parseInt(id_cuentas), "=")
  cuentas_servicios_internet.addLimitCondition(limit, offset);
  const response = await cuentas_servicios_internet.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: esArray(response.wsResponse.cuentas_servicios_internet),
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const deleteCuentasServiciosInternet = async (id) => {
  let status = false;
  await Swal.fire({
    title: "¿Está seguro de que desea eliminar este registro?",
    text: "Si sigue adelante, no se podrán revertir los cambios.",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      container: 'my-swal-delete'
    },
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sí, quiero eliminarlo",
    cancelButtonText: "No, no estoy seguro",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const cuentas_servicios_internet = new datos("cuentas_servicios_internet");
      let data = {
        id: id,
      };
      const response = await cuentas_servicios_internet.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire({
          customClass: {
            container: 'my-swal-delete'
          },
          title: "¡Eliminado!",
          text: "El registro ha sido eliminado exitosamente",
          icon: "success"
        });
      } else {
        await Swal.fire({
          customClass: {
            container: 'my-swal-delete'
          },
          icon: "error",
          title: "¡Oops!",
          text: response.message,
        });
      }
    }
  });
  return status;
};

export const getCuentasServiciosInternetByCuenta = async(idCuenta) => {    
    const objectCuentasServiciosInternet = new datos("cuentas_servicios_internet")
    
    objectCuentasServiciosInternet.addCondition("cuentas_servicios_internet","id_cuentas",parseInt(idCuenta),"=")
    
    const response = await objectCuentasServiciosInternet.get()
    if(response){
            return response.wsResponse.cuentas_servicios_internet
    }
    return response
}

export const postCuentasServiciosInternet = async(data) => {
    const objectCuentasServiciosInternet = new datos("cuentas_servicios_internet")
    
    const response = objectCuentasServiciosInternet.insert(data)
  
    if (response){
      return response.wsResponse.cuentas_servicios_internet
    }
    return response
    
  }
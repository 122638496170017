import { useEffect, useState } from "react";
import {
    Box,
    Container,
    Grid
} from "@mui/material";
import { useCuentasContext } from "src/context/CuentasContext";
// import { SelectorLocalidadComponent } from "./forms/customRenders/selectorLocalidad";
// import { SelectorTipoLocalizacionComponent } from "./forms/customRenders/selectorTipoLocalizacion";
import { schemaCuentasLocalidades, } from "./forms/schemaCuentasLocalidades";
import { uiSchemaCuentasLocalidadesForm } from "./forms/uiSchemaCuentasLocalidades";
import { SelectorTipoLocalizacionComponent } from "src/forms/customRenders/selectorTipoLocalizacion";
import { SelectorPaisComponent } from "src/forms/customRenders/selectorPais";
import { SelectorProvinciaComponent } from "src/forms/customRenders/selectorProvincia";
import { SelectorDepartamentoComponent } from "src/forms/customRenders/selectorDepartamento";
import { SelectorLocalidadComponent } from "src/forms/customRenders/selectorLocalidad";
import { Buttons } from "src/general_components/Buttons";
import Swal from 'sweetalert2'
import "src/services/styles/sweetAlert.css";

import {
    materialCells,
    materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
const localize = require("ajv-i18n");

/* Formulario de Localizaciones */

export const CuentasLocalizacionesForm = (props) => {
  const { loadLocalizaciones, cuenta, localizacionesEdit,setLocalizacionesEdit,setMessage,setSeverity,setSnackbarVisible,setUpdateLocalizaciones } =
    useCuentasContext();
  /* estados de los mensajes de validacion del formulario */


  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [sendDisabledButton, setSendDisabledButton] = useState(true);

  /* estados de seleccion de los select */
  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [selectedTipoLocalizacion, setSelectedTipoLocalizacion] = useState("");

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [selectedLocalidad, setSelectedLocalidad] = useState("");
  const [updater, setUpdater] = useState(Date.now());
  useEffect(() => {
    setUpdater(Date.now());
  }, [localizacionesEdit]);

  const [dataForm, setDataForm] = useState({
    id: props.data?.id || "",
    id_cuentas: cuenta?.id_cuentas || "",
    id_tipo_localizacion: "",
    domicilio: "",
    id_localidad: "",
    descripcion: "",
    principal: false,
    selectedTipoLocalizacion: selectedTipoLocalizacion,
   // selectedLocalidad: selectedLocalidad,
    id_pais:"",
   // selectedPais: selectedPais,
    id_provincia:"",
    //selectedProvincia: selectedProvincia,
    id_departamento:"",
   // selectedDepartamento: selectedDepartamento,
  });
  useEffect(() => {

    console.log('localizacionesEdit',localizacionesEdit)
    if (localizacionesEdit)
      setDataForm({
        id: localizacionesEdit?.id || "",
        id_cuentas: localizacionesEdit?.id_cuentas || "",
        id_tipo_localizacion: localizacionesEdit?.id_tipo_localizacion || "",
        domicilio: localizacionesEdit?.domicilio || "",
        id_localidad: localizacionesEdit?.id_localidad || "",
        descripcion: localizacionesEdit?.descripcion || "",
        principal: localizacionesEdit?.principal === 1 ? true : false,
      //  selectedLocalidad:localizacionesEdit.id_localidad ,
        selectedTipoLocalizacion: localizacionesEdit?.id_tipo_localizacion,
        id_pais:localizacionesEdit?.id_pais || "",
       // selectedPais: localizacionesEdit.id_pais,
        id_provincia:localizacionesEdit?.id_provincia || "",
       // selectedProvincia: localizacionesEdit.id_provincia,
        id_departamento:localizacionesEdit?.id_departamento || "",
        //selectedDepartamento: localizacionesEdit.id_departamento,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updater]);

  const [withErrors, setWithErrors] = useState(false);

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setDataForm(data);
    if (err) {
      setSendDisabledButton(true);
    } else setSendDisabledButton(false);

    return { errors, data };
  };

  const sendInfo = (send) => {
    console.log('cuentaSEND',dataForm)
    if (withErrors || dataForm.selectedTipoLocalizacion === "" || dataForm.id_pais === "" || dataForm.id_provincia === ""
    || dataForm.id_departamento === "" || dataForm.id_localidad === "" ) {
      setMessage("Error en el formulario. Revise los Campos. Algunos son Obligatorios");
      setSeverity("error");
      setSnackbarVisible(true);
    } else if (send) {
      const cuentasToSend = { ...dataForm };
      cuenta.id_cuentas && (cuentasToSend.id_cuentas = cuenta.id_cuentas);
      cuentasToSend.principal = dataForm.principal ? "1" : "0";
      cuentasToSend.id_tipo_localizacion =
        cuentasToSend.selectedTipoLocalizacion;
      loadLocalizaciones({ ...cuentasToSend });
      // if (!props.data) {
      if (localizacionesEdit){
        setMessage("Localizacion editada exitosamente");
      }
      else {
        setMessage("Localizacion agregada exitosamente")
      };
      setSeverity("success");
      setSnackbarVisible(true);
      clearInputs();
      Swal.fire({
        title: 'Atencion!!!',
        text: "La localizacion/es que se agreguen o editen no impactara en la base de datos hasta que no confirme los mismos mediante"
        +" el boton Guardar que se encuentra debajo de la grilla de Localizaciones",
        icon: 'warning',
        customClass: {
          container: 'my-swal-delete'
        },
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar'
      })
      props.handleClose(false);
      setWithErrors(true);
      setLocalizacionesEdit(undefined)
      setUpdateLocalizaciones(true);
      // }
    }
  };

  /* funcion de limpieza de los imputs */

  const clearInputs = () => {
    setDataForm({
      id: "",
      id_cuentas: "",
      id_tipo_localizacion: "",
      domicilio: "",
      id_localidad: "",
      descripcion: "",
      principal: 0,
      selectedLocalidad: [],
      selectedTipoLocalizacion: [],
    });
  };

  const handleClose = () => {
    props.handleClose(false);
  };
  
  return (
    <>
      <Container maxWidth="lg">
        <Box
          sx={{
            mx: "auto",
            width: "100%",
            p: 1,
            m: 1,
            textAlign: "left",
            flexGrow: 1,
          }}
        >
          <JsonForms
            onChange={({ errors, data }) => onChange && onChange(errors, data)}
            schema={schemaCuentasLocalidades}
            uischema={uiSchemaCuentasLocalidadesForm}
            renderers={[
              ...materialRenderers,
              SelectorPaisComponent  ,
              SelectorProvinciaComponent,
              SelectorDepartamentoComponent,
              SelectorLocalidadComponent,
              SelectorTipoLocalizacionComponent,
            ]}
            data={dataForm}
            cells={materialCells}
          />

          <Grid container justifyContent="flex-end">
            <Buttons
              onClickSubmit={() => {
                sendInfo(true)
              }}
              labelButtonSave={"Guardar"}
              buttonCerrar={handleClose}
            />
          </Grid>

        </Box>
      </Container>
    </>
  );
};

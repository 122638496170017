export const uiSchemaSearchUbicacion =
{
  "type": "VerticalLayout",
  "elements": [
    {
      "type": "HorizontalLayout",
      "elements": [
        {
          type: "Control",
          scope: "#/properties/activoUbicacionTemporal",
          options: {
            toggle: true,
            align: "center",
          },
        },
        {
          "type": "Control",
          "label": "Deposito",
          "scope": "#/properties/idDep",
        }
        ,
        {
          "type": "Control",
          "label": "Sector",
          "scope": "#/properties/sector",
        }
        ,
        {
          "type": "Control",
          "label": "Modulo",
          "scope": "#/properties/modulo",
        }
        , {
          "type": "Control",
          "label": "Estante",
          "scope": "#/properties/estante",
        }
      ],
    }
    ,
    {
      "type": "HorizontalLayout",
      "elements": [
        {
          "type": "Control",
          "label": "Posicion",
          "scope": "#/properties/posicion",
        }
        ,
        {
          "type": "Control",
          "label": "Profundidad",
          "scope": "#/properties/profundidad",
        }
        ,
        {
          "type": "Control",
          "label": "Estibe",
          "scope": "#/properties/estiba",
        }
        ,
        {
          type: "Control",
          scope: "#/properties/id_tipo_ubicaciones",
        },
      ],
      rule: {
        effect: "HIDE",
        condition: {
          scope: "#/properties/activoUbicacionTemporal",
          schema: { "const": true }
        },
      },
    },
    {
      "type": "Control",
      "label": "Ubicacion Temporal",
      "scope": "#/properties/ubicacionTemporal",
      rule: {
        effect: "HIDE",
        condition: {
          scope: "#/properties/activoUbicacionTemporal",
          schema: { "const": false }
        },
      },
    }
  ]
}

import datos from "src/services/datos";
import Swal from "sweetalert2";
import "src/services/styles/sweetAlert.css"

const estados = new datos('estados_cajas')

// export const getEstadosCajas = async (auth) => {
//     estados.addCondition('estados_cajas','id',0,'>')
//     const response = await estados.get()
//     return response.wsResponse.estados_cajas
// };
export const getEstadosCajas = async () => {
    const objectEstados = new datos("estados_cajas");
    objectEstados.addCondition('estados_cajas', 'id', 0, '>')
    const response = await objectEstados.get();
    const mdatos = response.wsResponse.estados_cajas;

    var arr = [];
    if (Array.isArray(mdatos)) {
        arr = mdatos;
    } else if (typeof mdatos === "undefined") {
        arr = [];
    } else {
        arr = [mdatos];
    }
    //console.log(arr);
    return arr;
};

export const getEstadosCajasPaginated = async (limit, offset) => {
    estados.addCondition('estados_cajas', 'id', 0, '>')
    estados.addLimitCondition(limit, offset)
    const response = await estados.get()
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

    let result = {}
    if (!response.wsResponse.empty) {

        result = {
            data: [...response.wsResponse.estados_cajas],
            total_records: response.wsResponse.total_records
        }
        return result

    }
};

export const postEstadosCajas = async (estado) => {
    const response = estados.insert(estado)
    return response;
};

export const deleteEstadosCajas = async (id) => {

    let status = false;
    await Swal.fire({
        title: "Esta seguro de que desea eliminar este estado de cajas?",
        text: "Si sigue adelante, no se podran revertir los cambios!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, quiero eliminarlo!",
        cancelButtonText: "No, no estoy seguro!",
        customClass: {
            container: 'my-swal-delete'
        },
    }).then(async (result) => {
        if (result.isConfirmed) {

            const userData = new datos('estados_cajas');
            let data = {
                id: id
            }
            const response = await userData.delete(data);

            if (response.status === "true") {
                status = true;
                await Swal.fire(
                    "Eliminado!",
                    "El estado de cajas ha sido eliminado exitosamente",
                    "success"
                );
            } else {
                await Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response.message
                });
            }
        }
    });


    return status
};

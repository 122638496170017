
// Metodo creada unicamente para la insercion de ubicaciones.
// Recibe un props que contiene las ubicaciones
//  
// retorna el json parametros con params armado. 


export const paramsCreateUbicaciones = (ubicaciones) => {
    // const {dep,valorDep} = params;
    //    console.log(params)
     const parametros= {ubicaciones
         }
      
      return parametros;
  }
   
  export default paramsCreateUbicaciones
  
// TODO: useEffect no se esta usando, revisar la necesidad de importarlo
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const DateForm = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();

  //funcion para devolver al formulario la data seleccionada y para setearla en el input

  // se obtiene el path del jsonform para asi saber a que campo asociar cada date
  const datePath = props.path;
  var label = "";
  var fechaObtenida = null;
  var disabled = false;
  // Se hace un case para asi poder reutilizar el componente y poder manejar mejor el value y el label
  switch (datePath) {
    case "fecha_creacion":
      fechaObtenida = jsonForm.core.data.fecha_creacion;
      label = "Fecha de Creacion";
      break;
    case "fecha_desde":
      fechaObtenida = jsonForm.core.data.fecha_desde;
      label = "Fecha Desde";
      break;
    case "fecha_hasta":
      fechaObtenida = jsonForm.core.data.fecha_hasta;
      label = "Fecha Hasta";
      break;
    case "fechaCompra":
      fechaObtenida = jsonForm.core.data.fechaCompra;
      label = "Fecha Compra";
      break;
    case "fechaActivoInicio":
      fechaObtenida = jsonForm.core.data.fechaActivoInicio;
      label = "Fecha Activo Inicio";
      disabled = !jsonForm.core.data.activoFechaInicio;
      break;
    case "fechaActivoFin":
      fechaObtenida = jsonForm.core.data.fechaActivoFin;
      label = "Fecha Activo Fin";
      disabled = !jsonForm.core.data.activoFechaFin;
      break;
    case "fecha_nacimiento":
      fechaObtenida = jsonForm.core.data.fecha_nacimiento;
      label = "Fecha de Nacimiento";
      break;
    default:
      fechaObtenida = null;
      break;
  }
  if (fechaObtenida === undefined) {
    fechaObtenida = null;
  }

  // se obtiene un parametro modificar para ejecutar el useEfect y setear el valor
  const modificar = jsonForm.core.data.modificar;
  // const [validar, setValidar] = useState(true);
  const [primerRender, setPrimerRenden] = useState(true);
  const [value, setValue] = useState(fechaObtenida);

  // se ejecuta una sola vez , para cargar el valor inicial
  useEffect(() => {
    if (modificar) {
      // if (fechaObtenida) {
      //  if (validar) {
      const fechaObtenida2 = fechaObtenida === null || fechaObtenida === "" || fechaObtenida === undefined ? null : moment(fechaObtenida).format("YYYY-MM-DD");
      setValue(fechaObtenida2);
      // setValidar(false);
      //   }
      //  }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaObtenida]);

  useEffect(() => {
    if (primerRender) {
      if (fechaObtenida === null) {
        props.handleChange(props.path, "");
        setPrimerRenden(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // encargado de setear el nuevo valor cada vez que se cambie la fecha
  const handleChange = (newValue) => {
    var valordate = "";
    setValue(newValue);
    if (newValue !== null) {
      valordate = moment(newValue?.$d).format("YYYY-MM-DD");
    } else {
      valordate = "";
    }
    props.handleChange(props.path, valordate);
  };

  return (
    <div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl
          variant="standard"
          sx={{ width: "100%", paddingTop: "20px" }}
        >
          <DatePicker
            label={label}
            inputFormat="DD-MM-YYYY"
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField size="small"
              sx={{ mt: -1, minWidth: "100%" }} {...params} />}
            disabled={disabled}
          />
        </FormControl>
      </LocalizationProvider>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const DateFormTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "DateForm";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const DateFormComponent = {
  renderer: withJsonFormsControlProps(DateForm),
  tester: DateFormTester,
};

import React from 'react';
import { Table, TableBody, TableCell, TableRow, TableContainer } from '@mui/material';
import esArray from 'src/general_components/functionEsArray';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ThreeRowsTable = ({ data, idContenidos, idContenidosComparar }) => {

  const groupSize = 2;

  // Divide los elementos del arreglo en grupos de tamaño `groupSize`
  const groups = data.reduce((acc, _, index) => {
    if (index % groupSize === 0) {
      acc.push(data.slice(index, index + groupSize));
    }
    return acc;
  }, []);

  const storageIdContenidos = esArray(idContenidosComparar);

  return (
    <TableContainer >
      <Table sx={{
        minWidth: "auto", fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
      }} size="small" aria-label="a dense table">
        <TableBody>
          {groups.map((group, groupIndex) => (
            <TableRow key={groupIndex}>
              {group.map((row, rowIndex) => (
                <React.Fragment key={row.id}>
                  {storageIdContenidos && storageIdContenidos[0]?.includes(idContenidos[0]) && (
                    <TableCell style={{
                      position: 'relative', borderBottom: 'none', fontSize: 13,
                      fontFamily: 'Roboto, sans-serif',
                    }}>
                      {rowIndex === 0 && (
                        <CheckCircleIcon
                          color="info"
                          style={{
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)',
                          }}
                        />
                      )}
                    </TableCell>
                  )}
                  <TableCell component="th" scope="row" sx={{
                    fontSize: 13,
                    fontFamily: 'Roboto, sans-serif',
                  }}>
                    <strong>{row.metadato_label_form}</strong>
                  </TableCell>
                  <TableCell align="left" sx={{
                    fontSize: 13,
                    fontFamily: 'Roboto, sans-serif',
                  }}>
                    {typeof row.metadato_valor === 'object' || row.metadato_valor === null ? '' : row.metadato_valor}
                  </TableCell>
                </React.Fragment>
              ))}
              {group.length < groupSize && (
                <TableCell colSpan={(groupSize - group.length) * 2} />
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ContenidosMetadatosCell = ({ row, idsContenidos }) => {
  const contenidosMetadatos = esArray(row.contenidos_metadatos);
  const idContenido = esArray(row.id);

  return <ThreeRowsTable data={contenidosMetadatos} idContenidos={idContenido} idContenidosComparar={idsContenidos} />;
};

export default ContenidosMetadatosCell;
import { ABMGenerico } from "src/general_components/ABMGenerico";

export const Maquinas = () => {
    const modelo = "articulos";
    const condiciones = [
      {propiedad: "id_articulos_tipo" , valor: "1" , operador: "="}
    ]
    const columnasAexcluir = [
      "id_articulos_tipo",
      "id_categorias",
      "id_alicuotas_iva",
      "id_tipo_moneda"
    ]
    const valuesDefaultForm = { id_articulos_tipo: "1", id_categorias: "1",id_alicuotas_iva: "1",id_tipo_moneda: "1" };
    return (
        <ABMGenerico modelo={modelo} titulo={"Maquinas"} condiciones={condiciones} columnasAexcluir={columnasAexcluir} valuesDefaultForm={valuesDefaultForm}/>
    );
};

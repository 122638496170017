import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Box, Container, Grid } from "@mui/material";
import { useState } from "react";
import { useCuentasContext } from "src/context/CuentasContext";
import { Spinner } from "src/general_components/Spinner";
import { schemaTipoContenidosForm } from "./forms/schemaTipoContenidosForm";
import { uiSchemaTipoContenidosForm } from "./forms/uiSchemaTipoContenidosForm";
import { SelectorTipoContenidosComponent } from "src/forms/customRenders/selectorTipoContenidos";
import { Buttons } from "src/general_components/Buttons";
import { getCuentasLocalizacionesTiposContenidos, deleteTipoContenidosCuentaLocalizacion, postTipoContenidosCuentasLocalizaciones } from "src/projects/gestion_documental/helpers/TipoContenidosData";


/* Formulario contenedor de los datos de las CUENTAS JURIDICAS */
const localize = require("ajv-i18n");

export const TipoContenidosForm = (props) => {
  /* estados propios del context */
  const { cuenta, selectedLocalizacion, setSnackbarVisible, setMessage, setSeverity } = useCuentasContext();
  const { isLoadingData } = useCuentasContext();


  /* definicion de constantes de estado para utiliar con sus respectivos formularios */
  const [dataForm, setDataForm] = useState(
    //selectedLocalizacion?.tipo_contenidos || []
    {
      tipoContenido: selectedLocalizacion?.relations?.tipo_contenidos || [],
      id_cuentas_localizaciones: selectedLocalizacion?.id || "",
      selectedTipoContenidos: selectedLocalizacion?.relations?.tipo_contenidos || [],
    }
  );

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [withErrors, setWithErrors] = useState(false);
  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setDataForm(data);

    return { errors, data };
  };

  /* funciones para guardar telefonos en el context y validacion del formulario */

  const sendInfo = async (send) => {
    if (send) {
      const tipoContedidoToSend = { ...dataForm };
      const newArrayṔost = tipoContedidoToSend?.selectedTipoContenidos.map(item => ({
        id_tipo_contenidos: item.id,
        id_cuentas_localizaciones: tipoContedidoToSend.id_cuentas_localizaciones
      }));
      await deleteTipoContenidosCuentaLocalizacion(tipoContedidoToSend.id_cuentas_localizaciones);
      const responsePost = await postTipoContenidosCuentasLocalizaciones(newArrayṔost);
      clearInputs();
      if (responsePost.status === 'true') {
        const responseGet = await getCuentasLocalizacionesTiposContenidos();
        localStorage.setItem(`siam-cuentas_localizaciones_tipo_contenidos`, JSON.stringify({
          'total_records': responseGet.length,
          "cuentas_localizaciones_tipo_contenidos": responseGet
        }));
      }
      setMessage("Tipos de Contenidos guardados con exito!")
      setSeverity("success");
      setSnackbarVisible(true);
      props.setActualizarListado(true);
      props.handleClose(false);
    }
  };

  const clearInputs = () => {
    setDataForm({
      id: dataForm.id,
      id_cuenta_localizaciones: dataForm.id_cuentas,
      id_tipo_contenidos: dataForm.selectedTipoContenidos,
    });
  };

  const handleClose = () => {
    props.handleClose(false);
  };

  if (cuenta.id_cuentas > 0 && isLoadingData) {
    return <Spinner />;
  } else
    return (
      <>
        <Container maxWidth="lg">

          <Box
            sx={{
              mx: "auto",
              width: "100%",
              p: 1,
              m: 1,
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            <JsonForms
              onChange={({ errors, data }) =>
                onChange && onChange(errors, data)
              }
              schema={schemaTipoContenidosForm}
              uischema={uiSchemaTipoContenidosForm}
              renderers={[...materialRenderers, SelectorTipoContenidosComponent]}
              data={dataForm}
              cells={materialCells}
            />
            <Grid container justifyContent="flex-end">
              <Buttons
                onClickSubmit={() => {
                  sendInfo(true)
                }}
                labelButtonSave={"Guardar"}
                buttonCerrar={handleClose}
              />
            </Grid>

          </Box>
        </Container>
      </>
    );
};

import React, { useContext, useState } from "react";
import Grid from '@mui/material/Grid';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { SelectorDepositoComponent } from "../../../forms/customRenders/selectorDeposito";
import { InputNumberComponent } from "src/forms/customRenders/dobleInput";
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { schemaUbicacionCajas } from "src/forms/schemaUbicacionesForm";
import { uiSchemaUbicacionCaja } from "src/forms/uiSchemaUbicacionesCajas";
import { Buttons } from "src/general_components/Buttons";
import { getCajas } from "../helpers/UbicacionCajasData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TheLayoutContext } from "src/context/TheLayoutContext";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponentRC";
import { Container, Paper, styled } from '@mui/material';
import esArray from "src/general_components/functionEsArray";
import { SelectorTipoUbicacionesComponent } from "src/forms/customRenders/selectorTipoUbicaciones";
const localize = require("ajv-i18n");

export const FormUbicaciones = ({ data, setData, valorUbicacionTemporal, setPushData, toggleModal, buttonEnviar }) => {

  const {
    setDatosFormUbicaciones, setSnackbarVisible,
    setMessage,
    setSeverity, aProcesar, selectionModel
  } = useContext(CajasContext);

  const { s3Values } = useContext(TheLayoutContext)



  const limpiar = (e) => {
    setData({ idDep: "", disabled: false });
    setDatosFormUbicaciones({});

  };

  const [mensajeAcordeon, setMensajeAcordeon] = useState("");
  const [responseGetDisponible, setResponseGetDisponible] = useState(false);
  const [modalState, setModalState] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [withErrors, setWithErrors] = useState(false);

  const onChange = (errors, data) => {
    // console.log("data",data)
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    let valorSector = data.sector ? (data.sector <= 0 ? undefined : data.sector) : undefined;
    let valorModulo = data.modulo ? (data.modulo <= 0 ? undefined : data.modulo) : undefined;
    let valorEstante = data.estante ? (data.estante <= 0 ? undefined : data.estante) : undefined;
    let valorPosicion = data.posicion ? (data.posicion <= 0 ? undefined : data.posicion) : undefined;
    let valorEstiba = data.estiba ? (data.estiba <= 0 ? undefined : data.estiba) : undefined;
    let valorProfundidad = data.profundidad ? (data.profundidad <= 0 ? undefined : data.profundidad) : undefined;
    let dataFinal = {
      "idDep": data.idDep,
      "idSector": valorSector,
      "idModulo": valorModulo,
      "idEstante": valorEstante,
      "idEstiba": valorEstiba,
      "idPosicion": valorPosicion,
      "idProfundidad": valorProfundidad
    }

    var esPositivo =
      data.sector <= 0 ||
        data.modulo <= 0 ||
        data.estante <= 0 ||
        data.estiba <= 0 ||
        data.posicion <= 0 ||
        data.profundidad <= 0
        ? false
        : true;

    if (!esPositivo) {
      setData(dataFinal);
      return { errors, dataFinal };
    } else {
      setData(data);
      return { errors, data };
    }

  };

  function agruparPropiedades(data) {
    const newData = {};

    for (const key in data.wsResponse) {
      if (data.wsResponse.hasOwnProperty(key)) {
        const innerData = data.wsResponse[key];

        if (Array.isArray(innerData.name) && Array.isArray(innerData.count)) {
          const groupedData = innerData.name.map((name, index) => ({
            name,
            count: innerData.count[index]
          }));

          newData[key] = groupedData;
        } else {
          // Si no es un array, simplemente copia la propiedad tal como está
          newData[key] = innerData;
        }
      }
    }

    return {
      ...data,
      wsResponse: newData
    };
  }

  console.log("data",data)

  const verDisponibilidad = async () => {
    let registrosSeleccionados = [];

    if (aProcesar === 'soloTildadas' || aProcesar === 'soloMarcadas') {
      registrosSeleccionados = selectionModel.filter(item => {
        const condition = (aProcesar === 'soloTildadas') ? !s3Values.includes(item) : s3Values.includes(item);
        return condition;
      }).concat(s3Values.filter(item => !selectionModel.includes(item)));

    } else {
      registrosSeleccionados = selectionModel
    }

    const idsSeparadosPorComa = registrosSeleccionados.join(',');

    let sector = "";
    const datosUrl = {
      datos: {

        ubicacion_temporal: "0",
        valorRetiroCaja: "0",
        valorDevolucionCaja: "0",
        valorMudanza: "0",
        cajas: idsSeparadosPorComa
      }
    }
    if (!data.idDep || data.idDep === "") {
      setSnackbarVisible(true)
      setMessage("Debe seleccionar un deposito")
      setSeverity("warning")
      return false;
    }
    if (!validarDatos(data)) {
      setSnackbarVisible(true)
      setMessage("El valor de las coordenadas debe ser mayor a 0")
      setSeverity("warning")
      return false;
    }
    let response = await getCajas(data, sector, datosUrl, "0");

    if (response.status === "true") {
      setSnackbarVisible(true)
      setMessage(response.message)
      setSeverity("success")
      setMensajeAcordeon(response.message)
      const newData = agruparPropiedades(response, "wsResponse");
      setResponseGetDisponible(newData);
    } else {
      setSnackbarVisible(true)
      setMessage(response.message)
      setMensajeAcordeon("")
      setSeverity("warning")
    }
  };

  function validarDatos(dataFinal) {
    for (const clave in dataFinal) {
      // Verificar si la propiedad es propia del objeto (no heredada)
      if (dataFinal.hasOwnProperty(clave)) {
        // Verificar si el valor es un número y es mayor que 0
        if (typeof dataFinal[clave] === 'number') {
          if (dataFinal[clave] <= 0) {
            return false; // Devolver falso si encuentra un valor no válido
          }
        }
      }
    }
    return true; // Devolver verdadero si todos los valores son mayores que 0
  }

  const toggleModalDetalle = async () => {
    setModalState(!modalState);


  };

  const StyledContainer = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  });

  const StyledPaper = styled(Paper)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    backgroundColor: 'white', // Fondo blanco
    border: '1px solid blue', // Borde azul
    width: '98%',
    marginRight: theme.spacing(1),
    height: '50px',
    padding: theme.spacing(1.5),
    textAlign: "center",

  }));

  const styledPaper2 = {
    flexShrink: 0,
    minWidth: "32%",
    width: '32%',
    backgroundColor: 'white', // Fondo blanco
    border: '1px solid blue', // Borde azul
    height: '400px',
    overflowY: 'auto',
    overflowX: 'auto',
  }


  return (
    <div>
      <Grid container justifyContent="end" style={{ marginBottom: '3px' }}>
        <Button
          variant="contained"
          onClick={limpiar}
          color="secondary"
          sx={{
            padding: '5px',
            minWidth: 'auto',
          }}
        >
          <ReplayIcon style={{
            padding: '0px',      // Ajusta el relleno (padding) para hacer el botón más pequeño
            position: 'center',
          }} />
        </Button>
      </Grid>
      <JsonForms
        schema={schemaUbicacionCajas}
        uischema={uiSchemaUbicacionCaja}
        data={data}
        renderers={[
          ...materialRenderers,
          SelectorTipoUbicacionesComponent,
          SelectorDepositoComponent,
          InputNumberComponent
        ]}
        cells={materialCells}
        onChange={({ errors, data }) => onChange && onChange(errors, data)}
      />
      <div style={{ marginTop: 20 }}>
        <Accordion style={{ backgroundColor: "#EEE" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontSize: 15, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', color: "#0A395F", }}>
              {mensajeAcordeon ? mensajeAcordeon : "Ver Ubicaciones Disponibles"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <small>
                Seleccione las coordenadas de ubicacion deseadas y presione actualizar para obtener las ubicaciones
                disponibles que coincidan con los parametros ingresados.
              </small>
            </Typography>

            <Button
              variant="outlined"
              onClick={() => {
                verDisponibilidad();
              }}
            >
              Actualizar
            </Button>
            <Button
              variant="outlined"
              color="success"
              disabled={mensajeAcordeon !== "" ? false : true}
              sx={{ m: 2 }}
              onClick={() => {
                toggleModalDetalle();
              }}
            >
              Ver Detalle
            </Button>

          </AccordionDetails>
        </Accordion>
      </div>
      <Grid style={{ paddingTop: 40 }}>
        {
          buttonEnviar && (
            <Buttons
              onClickSubmit={() => {
                if (validarDatos(data)) {
                  setPushData();
                } else {
                  setSnackbarVisible(true)
                  setMessage("El valor de las coordenadas debe ser mayor a 0")
                  setSeverity("warning")
                }
              }}
              labelButtonSave={"Confirmar"}
              buttonCerrar={toggleModal}
            />
          )
        }
      </Grid>

      {modalState && (
        <SimpleDialogComponent
          open={modalState}
          maxWidth={"lg"}
          modalName={"Detalle Ubicaciones"}
          child={<div style={{ justifyContent: 'center' }}>
            {responseGetDisponible && (
              <StyledContainer>
                <Container style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledPaper style={{ backgroundColor: "#FFFFF", border: '3px solid #007D26' }}>
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', color: "#09450E" }}>
                      {responseGetDisponible.message}
                    </Typography>
                  </StyledPaper>
                </Container>

                <Container style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledPaper style={{ ...styledPaper2, backgroundColor: "#FFFFF", border: '3px solid #00447D' }}>
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', color: "#000B52" }}>
                      Tipos de Cajas
                    </Typography>
                    <Grid style={{ textAlign: "left" }}>
                      <ul>
                        {esArray(responseGetDisponible.wsResponse.tipo_cajas_count).map((item, index) => (
                          <li
                            style={{
                              fontSize: 13,
                              fontWeight: 'bold',
                              fontFamily: 'Roboto, sans-serif',
                              marginLeft: -20,
                              color: "#000B52"
                            }}
                            key={index}
                          >
                            {`Tipo: ${item.name}(`}
                            <span style={{ color: 'green' }}>{item.count}</span>
                            {`)`}
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  </StyledPaper>
                  <StyledPaper style={{ ...styledPaper2, backgroundColor: "#FFFFF", border: '3px solid #6E6E6E' }}>
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', color: "#3E3E3E" }}>
                      Tipos de Ubicaciones
                    </Typography>
                    <Grid style={{ textAlign: "left" }}>
                      <ul>
                        {esArray(responseGetDisponible.wsResponse.tipo_ubicaciones_count).map((item, index) => (
                          <li
                            style={{
                              fontSize: 13,
                              fontWeight: 'bold',
                              fontFamily: 'Roboto, sans-serif',
                              marginLeft: -20,
                              color: "#3E3E3E"
                            }}
                            key={index}
                          >
                            {`Tipo: ${item.name}(`}
                            <span style={{ color: 'green' }}>{item.count}</span>
                            {`)`}
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  </StyledPaper>
                  <StyledPaper style={{ ...styledPaper2, backgroundColor: "#FFFFF", border: '3px solid #7D000F' }}>
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', color: "#5D0000" }}>
                      Errores
                    </Typography>
                    <Grid style={{ textAlign: "left" }}>
                      <ul>
                        {esArray(responseGetDisponible.wsResponse.errors?.error).map((item, index) => (
                          <li style={{ fontSize: 13, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', color: "#5D0000",marginLeft: -20, }} key={index}>{`Error: ${item}`}</li>
                        ))}
                      </ul>
                    </Grid>
                  </StyledPaper>
                </Container>
              </StyledContainer>
            )}
          </div>}
          setPushData={""}
          handleClose={toggleModalDetalle}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}
    </div >);
};

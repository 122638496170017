import { useEffect, useState } from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import {
  deleteTipoCuentas, postTipoCuentas,
  getTipoCuentasPaginated
} from "../helpers/TipoCuentasData";
import { TipoCuentasForm } from "../cuentas/components/TipoCuentasForm";
import { getCategoriaCuentas } from "../cuentas/helpers/CategoriaCuentasData";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";
import { obtenerProcesosPorPerfilYMenu } from "src/general_components/functionEsArray";

export const TipoCuentasList = () => {
  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    { field: "id", headerName: "ID", flex: 0.3 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "detalle", headerName: "Detalle", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    { field: "tipo", headerName: "Tipo", flex: 1 },
  ]);
  const listName = "Tipos de Cuentas";
  const [tipoCuenta, setTipoCuenta] = useState([]);
  const [categorias, setCategorias] = useState([])
  const [createButton, setCreateButton] = useState(false);
  const restricciones = obtenerProcesosPorPerfilYMenu();
  let alta = true;
  let baja = true;
  let modificacion = true;
  if (restricciones) {
    alta = restricciones.alta;
    baja = restricciones.baja;
    modificacion = restricciones.modificacion;
  }

  useEffect(() => {
    const getCategorias = async () => {
      const response = await getCategoriaCuentas(); //hay que ver como lo manejamos con la paginacion
      if (response) {
        setCategorias(response)
      }
    };
    getCategorias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [options] = useState({
    cols: columns,
    //get: getTipoCuentas,
    pageSize: [50, 100, 150, 200],
    edit: modificacion,
    delete: baja ? deleteTipoCuentas : false,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,
    createButton: createButton,
    
    setCreateButton: setCreateButton,
    setDataEdit: setTipoCuenta,
    getPaginated: getTipoCuentasPaginated,
    hiddenButtonAgregar: !alta ? "true" : false
  });

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  if (createButton === false && tipoCuenta.id !== undefined) {
    setTipoCuenta([]);
  }

  return (
    <>
      <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
        <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          {listName}
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <TipoCuentasForm
        data={tipoCuenta}
        createButton={createButton}
        listName={listName}
        setCreateButton={setCreateButton}
        componentList={"/tipo_cuentas/admin"}
        postData={postTipoCuentas}
        opcionesSelector={categorias}
      />
        <ListTablePaginated options={options} />
        
      </Grid>
    </>
  );
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getPais } from "src/general_helpers/PaisData";

import { Autocomplete, TextField } from "@mui/material";
import { esArray } from "src/general_components/functionEsArray";

const SelectorPais = (props) => {
  
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const [paisSeleccionado, setPaisSeleccionado] = useState("");
  //const disabled = jsonForm.core.data.disabled;
  const id_pais = jsonForm.core.data.id_pais;

  //const [paises, setPaises] = useState([])
  var paisesLocalStorage=JSON.parse(localStorage.getItem("siam-pais"));

  useEffect(() => {
    const getPaises = async () => {
      if(paisesLocalStorage !== null){
          setDataDefaults(esArray(paisesLocalStorage.pais));
      }else{
          const paisesObtenidos = await getPais();
          if (paisesObtenidos) {
            setDataDefaults([...paisesObtenidos]);
          }
      }
      if (id_pais) {
          setPaisSeleccionado(props.data);
      }
    };
    getPaises();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

      if (dataDefaults) {

        const paisEncontrado = dataDefaults.find((pais) => pais.id === id_pais);
        setPaisSeleccionado(paisEncontrado);
      }
      
  }, [dataDefaults,id_pais]);


  //funcion para devolver al formulario la data seleccionada y para setearla en el selector
  const handleChange = (event,newValue) => {

    console.log(newValue,'newValue')
    if (newValue && newValue !== "") {
      setPaisSeleccionado(newValue);
      props.handleChange(props.path, newValue.id);
    }else{
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
         <Autocomplete
        value={paisSeleccionado  ? paisSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.nombre || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Pais" />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorPaisesTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorPais";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorPaisComponent = {
  renderer: withJsonFormsControlProps(SelectorPais),
  tester: SelectorPaisesTester,
};

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "../../../context/CajasContext/CajasContext";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { SnackbarMessenger } from "../../../general_components/SnackbarMessenger";
import { getComprobantesData, comprobantesPrintData, getComprobantesDataPaginarCliente, deleteComprobantes, actualizarEstadoComprobante } from "../helpers/ComprobantesData";
import { FormConsultaComprobantes } from "./FormCosultaComprobantes";
import { ItemComprobantesList } from "./ItemsComprobantesList";
import { FormEditarComprobante } from "./FormEditarComprobante";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import React from 'react';
import { ComprobantePrint } from "./ComprobantePrint";
import { DeleteForever } from "@mui/icons-material";
import { ComprobantesExtraData } from "./ComprobantesExtraData";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { ConfimDialogComponent } from "src/general_components/ConfirmDialogComponent";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { id_proyecto } from 'src/configs/config';

import { Button, Popover, List, ListItem, ListItemButton, ListItemText } from '@mui/material';


export const ConsultaComprobantesList = () => {
  const {
    snackbarVisible,
    message,
    severity,
    setMessage,
    setSeverity,
    setOmitirQuerySearch,
    setListRecords,
    setSnackbarVisible,
    modalState,
    setModalState,
    criteriaComprobantes, setActualizarListado
  } = useContext(CajasContext);

  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const [pushData, setPushData] = useState(false);
  const [dataBusqueda, setDataBusqueda] = useState(false);
  const [contenidoItem, setContenidoItem] = useState(false);
  const [tiposComprobantesList, setTiposComprobantesList] = useState([]);
  const [comprobantesCogidoList, setComprobantesCodigoList] = useState([]);
  const [cogidoCajasList, setCodigoCajasList] = useState([]);
  const [puntoVentaList, setPuntoVentaList] = useState([]);
  const [terminalList, setTerminalList] = useState([]);
  const [clientesList, setClientesList] = useState([]);
  const [modalListItem, setModalListItem] = useState(false);
  const [labelModal, setLabelModal] = useState("Items");
  const [childModal, setChildModal] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sizeModal, setSizeModal] = useState('xl');
  const [modalStateConfirm, setModalStateConfirm] = useState(false);
  const [mensajeConfirm, setMensajeConfirm] = useState("");
  const [idEstadoSelected, setIdEstadoSelected] = useState("");
  const [idComprobanteSelected, setIdComprobanteSelected] = useState('');

  let nombreEstadoSelect = ''

  const handleDelete = async (id) => {
    const response = await deleteComprobantes(id);

    if (response) {
      setRefresh(true);
    }
  };


  const getLocalUserCuentas = () => {
    let id_cuentas = "";
    const user_cuentas = JSON.parse(localStorage.getItem("siam-user-cuentas"));
    if (user_cuentas) {
      user_cuentas.forEach((user_cuenta) => {

        if (
          user_cuenta &&
          user_cuenta.id_cuentas
        ) {
          id_cuentas += user_cuenta.id_cuentas + ",";
        }
      });
    }

    return id_cuentas === "" ? false : id_cuentas.replace(/(^,)|(,$)/g, "");
  };


  const cuenta = getLocalUserCuentas()

  useEffect(() => {
    if (refresh) {
      setOptions({ ...options, refresh: true });
      setRefresh(false)
    }
  }, [refresh]);


  const handleConfirm = async () => {

    let response = await actualizarEstadoComprobante(idComprobanteSelected, idEstadoSelected)

    let message = "Se realizo correctamente la modificacion"
    let statusMessage = 'success'

    if (!response?.status) {
      message = response.message
      statusMessage = 'error'

    }

    setMessage(message);
    setSeverity(statusMessage);
    setSnackbarVisible(true);
    setModalStateConfirm(!modalStateConfirm);
    setRefresh(!refresh)
  };

  const toggleModalConfirm = () => {
    setModalStateConfirm(!modalStateConfirm);
  };


  const CustomCellRenderer = (params) => {
    const [anchorEl, setAnchorEl] = useState(null);


    nombreEstadoSelect = params.row?.estado
    let comprobantesEstados = JSON.parse(localStorage.getItem("siam-comprobantes_estados")).comprobantes_estados;
    const gridManipulabre = comprobantesEstados?.filter(comprobante => comprobante.id_comprobantes_codigos === params.row.id_codigo);

    // setOptions(gridManipulabre)

    const opcionesFiltradas = gridManipulabre.filter((opcion) => !opcion.nombre.includes(nombreEstadoSelect))
    const handleIconClick = (event, row) => {
      setAnchorEl(event.currentTarget);
      // nombreEstadoSelect = row?.estado
      // let comprobantesEstados = JSON.parse(localStorage.getItem("siam-comprobantes_estados")).comprobantes_estados;
      // const gridManipulabre = comprobantesEstados?.filter(comprobante => comprobante.id_comprobantes_codigos === row.id_codigo);


      setIdComprobanteSelected(row.id)
    };

    const buildOptionsList = (params) => {
      // console.log(opcionesFiltradas.length, 'opciones')
      return opcionesFiltradas.length > 0 ? (opcionesFiltradas.map(option => (
        <ListItem key={option.id} disablePadding>
          <ListItemButton onClick={(event) => { event.stopPropagation(); handleClose(option); }}>
            <ListItemText primary={option.nombre} />
          </ListItemButton>
        </ListItem>
      ))
      ) : null;
    };

    const handleClose = (option) => {
      if (option?.nombre !== undefined) {
        setIdEstadoSelected(option.id)

        setMensajeConfirm(`El comprobante va a cambiar de estado ‘${nombreEstadoSelect}' al estado '${option?.nombre}’ - Confirma`)
        setModalStateConfirm(true)
      }
      setAnchorEl(false);

    };

    return params.value ? (
      <div>
        {params.value}
        <Button
          onClick={(e) => {
            handleIconClick(e, params.row);

          }}
          disabled={opcionesFiltradas.length > 0 ? false : true}
        >


          <SyncAltIcon />
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List>
            {buildOptionsList()}
          </List>
        </Popover>
      </div>
    ) : null;
  };


  //DATOS DEL JSONFORM DE BUSQUEDA

  const [dataInfoBusqueda, setDataInfoBusqueda] = useState({
    grupo: "",
    contrato: "",
    titular: "",
  });
  const listName = "";

  var columns = [];
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);
  let gridviews_ids = JSON.parse(localStorage.getItem("siam-gridviews_ids"))?.gridviews_ids;
  let gridviews_atributos = JSON.parse(localStorage.getItem("siam-gridviews_atributos"))?.gridviews_atributos;
  let gridManipulabre = gridviews_ids?.filter(gridviews_id => gridviews_id.nombre === 'Comprobante');
  let filtroGridviewsAtributos = gridviews_atributos?.filter(gridviews_atributo => gridviews_atributo.id_gridviews_ids === gridManipulabre[0]?.id);

  if (gridManipulabre !== undefined && gridManipulabre.length > 0) {
    console.log("filtroGridviewsAtributos",filtroGridviewsAtributos)
    columns = filtroGridviewsAtributos.map(item => {
      console.log()
      if (item.data === 'estado') {

        return {
          field: item.data,
          headerName: item.header,
          flex: 2, // Puedes ajustar estos valores según tus necesidades
          minWidth: 50,
          maxWidth: 150,

          renderCell: !cuenta ? CustomCellRenderer : null,

        };

      } else {
        return {
          field: item.data,
          headerName: item.header,
          flex: 2, // Puedes ajustar estos valores según tus necesidades

        };
      }
    });
    columns.push({
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      width: 150,
      minWidth: 100,
      maxWidth: 125,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar">
              <span>
                <BorderColorIcon color="info" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            setContenidoItem(params.row.item);
            setChildModal(<FormEditarComprobante row={params.row} setRefresh={setRefresh} refresh={refresh}
              cerrarModal={setModalListItem} />);
            setLabelModal("Comprobante")
            setModalListItem(true);
            setModalState(true);
          }}
          label="Editar"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Items">
              <span>
                <FormatListNumberedIcon color="info" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            setContenidoItem(params.row.item);
            setChildModal(<ItemComprobantesList contenidoItem={params.row.item} />)
            setLabelModal("Items")
            setModalListItem(true);
            setModalState(true);
          }}
          label="Items"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Eliminar">
              <span>
                <DeleteForever color="warning" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            handleDelete(params.row.id);
          }}
          label="Delete"
        />,
      ],
    },);

  } else {


    if (isMobileDevice) {
      columns = [

        {
          field: "id", headerName: "ID", flex: 50,
          minWidth: 50,
          maxWidth: 100
        },
        {
          field: "fecha", headerName: "Fecha", flex: 100,
          minWidth: 100,
          maxWidth: 150
        },
        {
          field: "cliente", headerName: "Cliente", flex: 175,
          minWidth: 175,
          maxWidth: 200
        },
        {
          field: "codigo", headerName: "Codigo de comprobante", flex: 10,
          minWidth: 100,
          maxWidth: 150
        },
        {
          field: "observaciones", headerName: "Observaciones", flex: 150,
          minWidth: 150,
          maxWidth: 175
        },
        {
          field: "total", headerName: "Total", flex: 100,
          minWidth: 100,
          maxWidth: 150
        },
        {
          field: "actions",
          headerName: "Acciones",
          type: "actions",
          width: 100,
          minWidth: 100,
          maxWidth: 125,
          getActions: (params) => [
            <GridActionsCellItem
              icon={
                <Tooltip title="Items">
                  <span>
                    <FormatListNumberedIcon color="info" />
                  </span>
                </Tooltip>
              }
              onClick={() => {
                setSizeModal('xl');
                setChildModal(<ItemComprobantesList contenidoItem={contenidoItem} />)
                setLabelModal("Items")
                setContenidoItem(params.row.item);
                setModalListItem(true);
                setModalState(true);
              }}
              label="Items"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Datos Extras">
                  <span>
                    <ZoomInIcon color="info" />
                  </span>
                </Tooltip>
              }
              onClick={() => {
                setSizeModal('md');
                setChildModal(<ComprobantesExtraData data={params.row} />)
                setLabelModal("Datos Extras")
                setContenidoItem(params.row.item);
                setModalListItem(true);
                setModalState(true);
              }}
              label="Datos Extras"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Impresion">
                  <span>
                    <LocalPrintshopIcon color="info" />
                  </span>
                </Tooltip>
              }
              onClick={async () => {
                setSizeModal('xl');
                let contenidoChild = await obtenerComprobanteAimprimir(params.id);
                setChildModal(<ComprobantePrint contenidoChild={contenidoChild} />)
                setLabelModal("Impresion de Comprobante")
                setContenidoItem(params.row.item);
                setModalListItem(true);
                setModalState(true);
              }}
              label="Impresion"
            />,
          ],
        },
      ];
    } else {
      columns = [
        { field: "id", headerName: "ID", flex: 0.2 },
        { field: "fecha", headerName: "Fecha", flex: 0.3 },
        { field: "cliente", headerName: "Cliente", flex: 0.5 },
        { field: "codigo", headerName: "Codigo de comprobante", flex: 0.5 },
        { field: "observaciones", headerName: "Observaciones", flex: 0.3 },
        { field: "total", headerName: "Total", flex: 0.2 },
        {
          field: "actions",
          headerName: "Acciones",
          type: "actions",
          flex: 0.5,
          getActions: (params) => [
            <GridActionsCellItem
              icon={
                <Tooltip title="Items">
                  <span>
                    <FormatListNumberedIcon color="info" />
                  </span>
                </Tooltip>
              }
              onClick={() => {
                setChildModal(<ItemComprobantesList contenidoItem={contenidoItem} />)
                setLabelModal("Items")
                setContenidoItem(params.row.item);
                setSizeModal('xl');
                setModalListItem(true);
                setModalState(true);
              }}
              label="Items"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Datos Extras">
                  <span>
                    <ZoomInIcon color={params.row.extra_data ? "info" : "disabled"} />
                  </span>
                </Tooltip>
              }
              onClick={async () => {
                // let contenidoChild = await obtenerComprobanteAimprimir(params.id);
                setSizeModal('md');
                setChildModal(<ComprobantesExtraData data={params.row.extra_data} />)
                setLabelModal("Datos Extras")
                setContenidoItem(params.row.item);
                setModalListItem(true);
                setModalState(true);
              }}
              disabled={!params.row.extra_data}
              label="Datos Extras"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Impresion">
                  <span>
                    <LocalPrintshopIcon color="info" />
                  </span>
                </Tooltip>
              }
              onClick={async () => {
                setSizeModal('xl');
                let contenidoChild = await obtenerComprobanteAimprimir(params.id);
                setChildModal(<ComprobantePrint contenidoChild={contenidoChild} />)
                setLabelModal("Impresion de Comprobante")
                setContenidoItem(params.row.item);
                setModalListItem(true);
                setModalState(true);
              }}
              label="Impresion"
            />,
            <GridActionsCellItem
            icon={
              <Tooltip title="Eliminar">
              <span>
                <DeleteForever color="warning" />
              </span>
            </Tooltip>
            }
            onClick={async () => {
              deleteComprobantes(params.id);
            }}
            label="Eliminar"
          />,
          ],
        },
      ];
    }
  }

  const toggleModalItems = () => {
    setModalState(!modalState);
    setModalListItem(false);
  };
  const [options, setOptions] = useState({
    cols: columns,
    get: getComprobantesDataPaginarCliente,
    getPaginated: getComprobantesData,
    setListRecords: setListRecords,
    pageSize: [50, 100, 150, 200],
    listName,
    limit: 50, //cantidad de registros
    filter: true,
    exportData: true,
    sinFilas: true,
    idElementData: criteriaComprobantes,
    exportarCajasContenidos: id_proyecto !== '27' ? false : true,
    exportarComprobante: true,
    paginar: "false",
    // delete: gridManipulabre !== undefined && gridManipulabre.length > 0 ? false : deleteComprobantes,
    refresh: refresh
  });

  console.log("columns",columns)

  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
    //SETEO EL ESTADO PARA QUE SE ACTUALICE EL LISTADO
    setActualizarListado(true);
  };


  /// Despliega el buscador cuando se presiona F2
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setAbrirBusqueda(!abrirBusqueda);
        setOmitirQuerySearch(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const obtenerComprobanteAimprimir = async (idComprobante) => {
    let paramsPrint = {
      id_comprobantes: idComprobante
    }
    let response = await comprobantesPrintData(paramsPrint)
    return response;
  };

  useEffect(() => {
    if (criteriaComprobantes) {
      setOptions({
        ...options,
        idElementData: criteriaComprobantes,
        sinFilas: false,
      });
    }
  }, [criteriaComprobantes]);

  if (pushData) {
    if (abrirBusqueda) {
      //CIERRO EL DIALOGO DE BUSQUEDA
      setAbrirBusqueda(!abrirBusqueda);
      //SETEO LOS DATOS PARA QUE EL OTRO COMPONENTE PUEDA LEERLOS
      setDataBusqueda(dataInfoBusqueda);
      //SETEO EL ESTADO PARA QUE SE ACTUALICE EL LISTADO
      setActualizarListado(true);
    }
    setPushData(!pushData);
  }

  return (
    <>
      {abrirBusqueda && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirBusqueda}
          modalName={"Busqueda de comprobantes"}
          child={
            <FormConsultaComprobantes
              toggleModal={toggleModal}
              tiposComprobantesList={tiposComprobantesList}
              comprobantesCogidoList={comprobantesCogidoList}
              cogidoCajasList={cogidoCajasList}
              puntoVentaList={puntoVentaList}
              terminalList={terminalList}
              clientesList={clientesList}
              cerrarModal={setAbrirBusqueda}
            />
          }
          setPushData={setPushData}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />

      )}
      <Grid container marginTop={'10px'} marginBottom={'10px'} justifyContent="flex-end">

        <Button
          endIcon={<SearchIcon />}
          onClick={() => {
            setAbrirBusqueda(!abrirBusqueda);
            setOmitirQuerySearch(true);
          }}
          variant="contained"
        >
          Buscar
        </Button>

      </Grid>
      <Grid>
        <ListTablePaginated options={options} />
      </Grid>
      <Grid>
        {modalListItem === true && (
          <SimpleDialogComponent
            open={modalState}
            modalName={labelModal}
            child={childModal}
            handleClose={toggleModalItems}
            buttonEnviar={false}
            buttonCancelar={true}
            maxWidth={sizeModal}
          />
        )}
      </Grid>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
      <ConfimDialogComponent
        open={modalStateConfirm}
        modalName={"Confirmar Cambio de Estado"}
        mensaje={mensajeConfirm}
        handleConfirm={handleConfirm}
        handleClose={toggleModalConfirm}
      />
    </>
  );
};

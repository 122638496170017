import { useEffect, useState } from "react";
import { ListTablePaginated } from "./ListTablePaginated";
import { Grid } from "@mui/material";
import { NotasForm } from "src/projects/gestion_documental/components/NotasForm";
import { SimpleDialogComponent } from "./SimpleDialogComponent";
import { getNotasPaginated, getNotas, deleteNotas } from "../projects/gestion_documental/helpers/NotasData";
//const localize = require("ajv-i18n");

export const NotasList = (params) => {


  const listName = "";
  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "fecha", headerName: "Fecha", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    { field: "username", headerName: "Usuario", flex: 1 },
    { field: "detalle", headerName: "Comentario", flex: 1 },
  ];

  const [modalState, setmodalState] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);
  const [options, setOptions] = useState({
    cols: columns,
    pageSize: [5, 10, 25],
    edit: true,
    listName,
    limit: 50,
    filter: true,
    exportData: true,
    delete: deleteNotas,
    server: true,
    getPaginated: getNotasPaginated,
    get: getNotas,
    idElementData: params.params.id_modelo_padre,
    imagenes: true,
    //DATOS PARA EL FORMULARIO
    createButton: modalState,
    setCreateButton: setmodalState,
    setDataEdit: setDataEdit,
  });
  const [paramsNuevaNota, setParamsNuevaNota] = useState({});

  const toggleModal = () => {
    if (modalState) {
      setDataEdit(false);
    }
    setmodalState(!modalState);
  };

  const dialogChild = (
    <NotasForm
      params={paramsNuevaNota}
      toggleModal={toggleModal}
      options={options}
      setOptions={setOptions}
      dataEdit={dataEdit}
    />
  );

  useEffect(() => {
    setParamsNuevaNota(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState])

  useEffect(() => {
    if (options.refresh) {
      setOptions({ ...options, refresh: false });
    }
  }, [options])

  return (
    <>
      <Grid
        style={{ background: "white", padding: "10px", marginBottom: "5px" }}
      >
        <>
          <ListTablePaginated options={options} />
          <SimpleDialogComponent
            open={modalState}
            modalName={"Nueva Nota"}
            child={dialogChild}
            handleClose={toggleModal}
            buttonEnviar={false}
            buttonCancelar={false}
            maxWidth="lg"
          />
        </>
      </Grid>
    </>
  );
};

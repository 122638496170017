import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Buttons } from "./Buttons";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";

export const PasswordInput = (props) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  let passwordActual =
    localStorage.getItem("passUser");

  const handleSubmit = () => {
    if (passwordActual.toString() === password) {
      props.handleSubmit();
    } else {
      setSnackbarVisible(true);
      setMessage('Contraseña incorrecta')
      setSeverity("warning")
    }

  };

  return (
    <div>
      <TextField
        variant="outlined"
        label="Contraseña actual"
        placeholder='Ingrese su contraseña actual'
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={handlePasswordChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div style={{ marginTop: 20 }}>
        <Buttons
          style={{ marginTop: 10 }}
          onClickSubmit={handleSubmit}
          disableButton={password.length === 0 ? true : false}

          buttonCerrar={props.toggleModal} />
      </div>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={8000}
        close={() => { setSnackbarVisible(false) }} />
    </div>

  );
}

export default PasswordInput;
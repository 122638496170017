// TODO: useEffect no se esta usando, revisar la necesidad de importarlo
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import { Autocomplete, TextField } from "@mui/material";
import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import { getArticulosTipo } from "../../projects/articulos/helpers/ArticulosTipoData";
import {esArray} from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";

const SelectorArticulosTipo = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.selectedArticulosTipo;

  const articulos_tipo = getFromLS("siam-articulos_tipo").articulos_tipo;
  const [tipoDeArticuloSeleccionado, setTipoDeArticuloSeleccionado] = useState('');

  let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));
  const titulo_config = parametros_proyecto?.titulo_articulos;
  const label_select = titulo_config ? "Tipo de Propiedad" : "Tipo de Articulo";

  useState(() => {
    const tiposDeArticulos = async () => {
      if (articulos_tipo) {
        setDataDefaults(esArray(articulos_tipo));
      }else {
        const response = await getArticulosTipo()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    tiposDeArticulos()
  
  }, []);

  useEffect(() => {
    setTipoDeArticuloSeleccionado(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
    setTipoDeArticuloSeleccionado(newValue);
    props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
    }else{
      props.handleChange(props.path, "");
    }
  };
  
  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        
        <Autocomplete
        value={tipoDeArticuloSeleccionado ? tipoDeArticuloSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.detalle || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label={label_select}
          disabled={disabled} />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorArticulosTipoTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorArticulosTipo";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorArticulosTipoComponent = {
  renderer: withJsonFormsControlProps(SelectorArticulosTipo),
  tester: SelectorArticulosTipoTester,
};

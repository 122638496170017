import { esArray } from "src/general_components/functionEsArray";
import datos from "src/services/datos";

export const getCodigoCajasData = async () => {
  
  const objectCodigoCajas = new datos("codigo_caja");
  objectCodigoCajas.addCondition("codigo_caja","id",0,">");
  const response = await objectCodigoCajas.get();
  const codigoCajas = response.wsResponse.codigo_caja;
  const arrCodigoCajas = esArray(codigoCajas);

  return arrCodigoCajas;
};


import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';

const GlobalStyles = styled('div')({
    '@global': {
        '@keyframes spin': {
            from: {
                transform: 'rotate(0deg)',
            },
            to: {
                transform: 'rotate(360deg)',
            },
        },
    },
});

const CircularBackground = styled('div')(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[300],
    width: 32,
    height: 32,
}));

const RotatingSettingsIcon = styled(SettingsIcon)(({ duration }) => ({
    animation: `spin ${duration}s linear infinite`,
    width: 28,
    height: 28,
    color: '#0A395F',
}));

function ButtonConfig({ handleClick, openTooltip, spinDuration = 8 }) {
    return (
        <React.Fragment>
            <GlobalStyles />
            <Tooltip title="Configuración">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={openTooltip ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openTooltip ? 'true' : undefined}
                    sx={{ paddingRight: 2 }}
                >
                    <CircularBackground>
                        <RotatingSettingsIcon duration={spinDuration} />
                    </CircularBackground>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}

export default ButtonConfig;
export const uiSchemaDigitalizacionAgregar = {
  type: "VerticalLayout",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/grupo",
          
        },
        {
          type: "Control",
          scope: "#/properties/contrato",
        },
        {
          type: "Control",
          scope: "#/properties/titular",
          
        },
        {
          type: "Control",
          scope: "#/properties/dni",
          
        },
        {
          type: "Control",
          scope: "#/properties/fechaCompra",
          
        },
      ],
    },
  ],
};

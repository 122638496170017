export const schemaUsuariosForm = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Nombre",
      description: "Ingresar nombres",
      minLength: 3,
    },
    surname: {
      type: "string",
      title: "Apellido",
      minLength: 3,
      description: "Ingresar apellidos",
    },
    username: {
      type: "string",
      title: "Usuario",
      minLength: 4,
      pattern: "^[^\\s]+$", 
      description: "El nombre de Usuario debe contener al menos cuatro caracteres y no puede tener espacios en blanco.",
    },
    password: {
      type: "string",
      title: "Contraseña",
      minLength: 6,
      pattern: "^[^\\s]+$", 
      description: "La contraseña debe contener al menos un caracter y no puede tener espacios en blanco.",
      maxLength:72
    },
    email: {
      type: "string",
      title: "Correo electrónico",
      description: "Ingrese correo electronico",
      format: "email",
    },
    profile: {
      type: "string",
      customRender: "SelectorProfiles",
      description: "Seleccione perfil",
    },
    account: {
      type: "array",
      customRender: "SelectorAccounts",
    },

  },

  required: ["name", "surname", "username", "password", "email","profile"],
};

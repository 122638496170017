import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Box, Container, Grid } from "@mui/material";
import {  useState,useContext } from "react";
import { Buttons } from "src/general_components/Buttons";
import "src/services/styles/sweetAlert.css";
import { schemaCuentasContactosForm } from "src/projects/gestion_comercial/cuentas/components/forms/schemaCuentasContactosForms";
import { uiSchemaCuentasContactosForm } from "src/projects/gestion_comercial/cuentas/components/forms/uiSchemaCuentasContactosForm";
import { postCuentasContactos } from "../helpers/ContactosData";
import { CajasContext } from "src/context/CajasContext/CajasContext";

const localize = require("ajv-i18n");

/* Formulario de Cuentas Contactos */

export const CuentasContactosFormProcesosVarios = (props) => {

  const { idCuenta,updateSelectorContacto,setNewContacto } = props;


  const { setMessage, setSeverity,  setSnackbarVisible, } = useContext(CajasContext);

  const [dataForm, setDataForm] = useState({
    id: "",
    id_cuentas: "",
    descripcion:  "",
  });


  // eslint-disable-next-line no-unused-vars
  const [withErrors, setWithErrors] = useState(false);

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setDataForm(data);

    return { errors, data };
  };

  /* funcion para validar el formulario y  guardar los datos en el context */
  const handleSubmit = async () => {
    const response = await postCuentasContactos(dataForm.descripcion,idCuenta.id_cuentas);
    if(response.status === "true"){
      setMessage("El contacto se agrego exitosamente!");
      setSeverity("success");
      setSnackbarVisible(true);
      updateSelectorContacto();
      setNewContacto(response.wsResponse.cuentas_contactos)
      handleClose();
    }else{
      setMessage("Hubo un problema al agregar el contacto");
      setSeverity("warning");
      setSnackbarVisible(true);
    }
  };

  const handleClose = () => {
    props.handleClose(false);
  };


  return (
    <Container maxWidth="lg">

      <Box
        sx={{
          mx: "auto",
          width: "100%",
          p: 1,
          m: 1,
          textAlign: "center",
          flexGrow: 1,
        }}
      >
        <JsonForms
          onChange={({ errors, data }) => onChange && onChange(errors, data)}
          schema={schemaCuentasContactosForm}
          uischema={uiSchemaCuentasContactosForm}
          renderers={[...materialRenderers]}
          data={dataForm}
          cells={materialCells}
        />

        <Grid container justifyContent="flex-end">
          <Buttons
            onClickSubmit={() => {
              handleSubmit()
            }}
            labelButtonSave={"Guardar"}
            buttonCerrar={handleClose}
            disableButton={!dataForm.descripcion || dataForm.descripcion === "" || dataForm.descripcion?.length < 3? true : false}
          />
        </Grid>
      </Box>
    </Container>
  );
};

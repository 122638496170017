export const uiSchemaTipoUbicacionesForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/detalle",
        },
        {
          type: "Control",
          scope: "#/properties/ancho",
        },
      ],
    }, {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/alto",
        },
        {
          type: "Control",
          scope: "#/properties/profundidad",
        },
      ],
    }
      ],
    }

import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
const articulos_tipo = new datos("articulos_tipo");

export const getArticulos = async (auth) => {
  articulos_tipo.addCondition("articulos_tipo", "id", 0, ">");
  const response = await articulos_tipo.get();
  return esArray(response.wsResponse.articulos_tipo);
};

export const getArticulosPaginated = async (limit, offset) => {
  articulos_tipo.addCondition("articulos_tipo", "id", 0, ">");
  articulos_tipo.addLimitCondition(limit, offset);
  const response = await articulos_tipo.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: esArray(response.wsResponse.articulos_tipo),
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const deleteArticulosTipo = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este Articulo Tipo?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const articulos_tipo = new datos("articulos_tipo");
      let data = {
        id: id,
      };
      const response = await articulos_tipo.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El Articulo Tipo ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};
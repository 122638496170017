import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css";

const notas_internas = new datos("notas_internas");

export const getNotas = async (auth) => {
  notas_internas.addCondition("notas_internas", "id", 0, ">");
  notas_internas.join("usuarios", "id", "id_usuarios", "=");
  const response = await notas_internas.get();
  return esArray(response.wsResponse.notas_internas);
};

export const getNotasPaginated = async (limit, offset, idElementData) => {
  const notas_internas2 = new datos("notas_internas");
  notas_internas2.addCondition("notas_internas", "id", 0, ">");
  notas_internas2.addCondition(
    "notas_internas",
    "id_modelo_padre",
    idElementData,
    "="
  );
  notas_internas2.addLimitCondition(limit, offset);
  notas_internas2.join("usuarios", "id", "id_usuarios", "=");
  const response = await notas_internas2.get();
  let dataArray = esArray(response.wsResponse.notas_internas);
  const newArray = dataArray.map((obj) => {
    const newObject = { ...obj };
    newObject["username"] = obj.relations?.usuarios?.username
      ? obj.relations.usuarios.username
      : "";
    return newObject;
  });
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: newArray,
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const postNotas = async (params) => {  
  const response = notas_internas.insert(params);
  return response;
};

export const deleteNotas = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar esta Nota?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
    customClass: {
      container: "my-swal-delete",
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      const notasData = new datos("notas_internas");
      let data = {
        id: id,
      };
      const response = await notasData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire({
          title: "Eliminado!",
          text: "La nota ha sido eliminado exitosamente",
          icon: "success",
          customClass: {
            container: "my-swal-delete",
          },
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
          customClass: {
            container: "my-swal-delete",
          },
        });
      }
    }
  });
  return status;
};

export const postDigitalizacionesNotas = async (dataImagen) => {
  let filesImages = [];

  // eslint-disable-next-line array-callback-return
  dataImagen.map((item) => {
    //QUITAR data:image/png;base64, PARA QUE FUNCIONE EL POST
    item.image = item.image.replace(/^data:image\/[a-z]+;base64,/, "");
    filesImages.push(item);
  });

  const objetoImages = new datos("images");
  const response = await objetoImages.createImages(filesImages);

  return response;
};

import { config } from "src/configs/config";
import datos from "src/services/datos";
import { esArray } from "src/general_components/functionEsArray";
import Swal from "sweetalert2";
import "src/services/styles/sweetAlert.css";

var myHeaders = new Headers();
myHeaders.append("Authorization", config.authToken);
myHeaders.append("Content-Type", "application/json");


export const getDepositos = async (data) => {
    const deposito = new datos('owner_localizaciones')
    deposito.addCondition('owner_localizaciones', 'id', 0, '>');
    const response = await deposito.get()

    const res = response.wsResponse.owner_localizaciones;
    // Si es un array se mantiene como estaba
    if (Array.isArray(res)) {
        var arr = res;
        // Si no es un array... primero se verifica que tenga valores ... si no los tiene
    } else if (typeof res === 'undefined') { // se pasa un array vacio
        arr = [];
    } else { // si trae un solo elemento , es un objeto
        arr = [res]; // se lo convierte a array
    }


    return arr
};

export const getCajas = async (data, sector, datosUrl, cajasUbicadas) => {
    const cajasDisponibles = new datos('cajas')
    const response = await cajasDisponibles.getCajasDisponibles(data, sector, datosUrl, cajasUbicadas)

    return response;
};

export const getCajasDisponiblesPaginated = async (limit, offset, props) => {
    const { ubicacionesSearch, sectorIdNumber, datosUrl, cajasUbicadas, clienteName } = props
    const cajasDisponibles = new datos('cajas')
    const response = await cajasDisponibles.getCajasDisponibles(ubicacionesSearch, sectorIdNumber, datosUrl, cajasUbicadas, limit, offset)

    const datosObtenidos = response.wsResponse.caja ? response.wsResponse.caja : [];
    var datosObtenidosArr = esArray(datosObtenidos);

    if (datosObtenidosArr.length > 0) {
        for (let i = 0; i < datosObtenidosArr.length; i++) {
            datosObtenidosArr[i].tieneContenido = JSON.stringify(datosObtenidosArr[i].contenidos) === "{}" ? "No" : "Si";
            let referencia = typeof datosObtenidosArr[i].referencia === "object" ? "" : " | REF: " + datosObtenidosArr[i].referencia;
            datosObtenidosArr[i].concatCaja = "ID: " + datosObtenidosArr[i].id + referencia;
            datosObtenidosArr[i].hierarchy = ['cliente0', 'sector1', datosObtenidosArr[i].id];
            datosObtenidosArr[i].isRowSelectable = true;
            datosObtenidosArr[i].contenidos = false;
        }
        datosObtenidosArr.push({ id: 'cliente0', clienteName: clienteName, hierarchy: ['cliente0'] });
        datosObtenidosArr.push({ id: 'sector1', sectorName: datosObtenidosArr[0]?.sector, hierarchy: ['cliente0', 'sector1'] });

    }
    let result = {
        data: datosObtenidosArr,
        total_records: response.wsResponse.total_records ? response.wsResponse.total_records : datosObtenidosArr.length,
        response: response
    };

    return result;
};

export const postCajasProcesosVarios = async (data, cajas, datosUrl, cajasUbicadas, ubicacionTemporal, ajuste,
    baja, definitivo, setModalStateUbicaciones, detalleConcat, contactoSelect, direccionSelect, nodoExtra) => {

    const ubicacionesCajas = new datos('cajas_ubicaciones')
    const contactoSeleccionado = contactoSelect && contactoSelect !== "" ? contactoSelect.id : "";
    const direccionSeleccionado = direccionSelect && direccionSelect !== "" ? direccionSelect.id : "";

    const response = await ubicacionesCajas.cajasUbicacionesSetProcesosVarios(data, cajas, datosUrl, cajasUbicadas, ubicacionTemporal, ajuste,
        baja, definitivo, detalleConcat, contactoSeleccionado, direccionSeleccionado, nodoExtra)

    if (response) {
        if (response.status === 'false') {
            setModalStateUbicaciones && setModalStateUbicaciones(false);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.message,
                customClass: {
                    container: 'my-swal-delete'
                },
            });
        }
    } else {
        setModalStateUbicaciones && setModalStateUbicaciones(false);
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Hubo un error en el servidor",
            customClass: {
                container: 'my-swal-delete'
            },
        });
        const response = {
            status: false,
            message: "Error en el servidor"
        };
        return response;
    }


    return response;
};

export const postCajasUbicaciones = async (data, cajas, datosUrl, cajasUbicadas, ubicacionTemporal, ajuste, baja, definitivo, setModalStateUbicaciones) => {

    const ubicacionesCajas = new datos('cajas_ubicaciones')
    const response = await ubicacionesCajas.cajasUbicacionesSet(data, cajas, datosUrl, cajasUbicadas, ubicacionTemporal, ajuste, baja, definitivo)

    if (response.status === 'false') {
        setModalStateUbicaciones && setModalStateUbicaciones(false);
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message
        });
    } else if (datosUrl.datos.valorRetiroCaja === '1') {
        setModalStateUbicaciones && setModalStateUbicaciones(false);
        Swal.fire({
            icon: "success",
            title: "Genial!!!",
            text: "Las cajas seleccionadas han sido retiradas, quedan a la espera de su devolucion"
        });
    } else if (datosUrl.datos.valorDevolucionCaja === '1') {
        setModalStateUbicaciones && setModalStateUbicaciones(false);
        Swal.fire({
            icon: "success",
            title: "Genial!!!",
            text: "Las cajas fueron devueltas a su ubicacion"
        });
    }
    else {
        setModalStateUbicaciones && setModalStateUbicaciones(false);
        Swal.fire({
            icon: "success",
            title: "Genial!!!",
            text: "Se ha asignado correctamente todas las cajas a las coordenadas ingresadas"
        });
    }

    return response;
};

import React, { useEffect, useState } from "react";
import { useCuentasContext } from "src/context/CuentasContext";
import { Container, Box, Grid } from "@mui/material";
import { JsonForms } from "@jsonforms/react";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { schemaTelefonosForm } from "./forms/schemaCuentasTelefonosForm";
import { uiSchemaTelefonosForm } from "./forms/uiSchemaCuentasTelefonosForm";
// import { SelectorTipoTelefonoComponent } from "./forms/customRenders/selectorTipoTelefono";
import { SelectorTipoTelefonoComponent } from "src/forms/customRenders/selectorTipoTelefono";
import { Buttons } from "src/general_components/Buttons";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import Swal from 'sweetalert2'
import "src/services/styles/sweetAlert.css";
const localize = require("ajv-i18n");


/* Formulario de Tipo de Servicios de Internet */

export const CuentasTelefonosForm = (props) => {
  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [selected_telefono, setSelected_telefono] = useState([]);

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [sendDisabledButton, setSendDisabledButton] = useState(false);
  const { loadTelefonos, selectedLocalizacion, telefonosEdit, cuenta, setUpdateTelefonos } =
    useCuentasContext();

  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [updater, setUpdater] = useState(Date.now());

  useEffect(() => {
    setUpdater(Date.now());
  }, [telefonosEdit]);

  const [dataForm, setDataForm] = useState({
    id: props.data?.id || "",
    id_cuentas: props.data?.id_cuentas || "",
    id_cuentas_localizaciones:
      props.data?.id_cuentas_localizaciones || selectedLocalizacion.id || "",
    id_tipo_telefonos: props.data?.id_tipo_telefonos || "",
    numero: props.data?.numero || "",
    selectedTipoTelefono: "",
  });

  useEffect(() => {
    if (telefonosEdit)
      setDataForm({
        id: telefonosEdit?.id || "",
        id_cuentas: telefonosEdit?.id_cuentas || "",
        id_cuentas_localizaciones:
          telefonosEdit?.id_cuentas_localizaciones ||
          selectedLocalizacion.id ||
          "",
        id_tipo_telefonos: telefonosEdit?.id_tipo_telefonos || "",
        numero: parseInt(telefonosEdit?.numero) || "",
        selectedTipoTelefono:  telefonosEdit.id_tipo_telefonos ,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updater]);

  const [withErrors, setWithErrors] = useState(false);

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    setDataForm(data);
    if (err) {
      setSendDisabledButton(true);
    } else setSendDisabledButton(false);

    return { errors, data };
  };

  /* funciones para guardar telefonos en el context y validacion del formulario */

  const sendInfo = (send) => {
    if (withErrors || dataForm.selectedTipoTelefono === "") {
      setMessage("Error en el formulario. Revise los Campos. Algunos son obligatorios.");
      setSeverity("error");
      setSnackbarVisible(true);
    } else if (!Number.isInteger(dataForm.numero) || dataForm.numero < 0) {
      setMessage("Solo se aceptan numeros enteros iguales o mayores a 0.");
      setSeverity("warning");
      setSnackbarVisible(true);
    } else if ((dataForm.numero).toString().length < 7) {
      setMessage("El numero de telefono tiene que ser minimo de 7 caracteres.");
      setSeverity("warning");
      setSnackbarVisible(true);
    } 
    else if (send) {
      const telefonosToSend = { ...dataForm };
      cuenta.id_cuentas && (telefonosToSend.id_cuentas = cuenta.id_cuentas);
      telefonosToSend.id_tipo_telefonos = telefonosToSend.selectedTipoTelefono;
      delete telefonosToSend.selectedTipoTelefono;
      loadTelefonos({ ...telefonosToSend });

      //if (!props.data) {
      if (cuenta.id_cuentas > 0) {setMessage("Telefono editado exitosamente");}
      else {setMessage("Telefono agregado exitosamente")};
      clearInputs();
      Swal.fire({
        title: 'Atencion!!!',
        text: "El telefono que se agregue o modifique no impactara en la base de datos hasta que no confirme los mismos mediante"
        +" el boton Guardar que se encuentra debajo de la grilla de Telefonos",
        icon: 'warning',
        customClass: {
          container: 'my-swal-delete'
        },
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar'
      })
      setUpdateTelefonos(true);
      props.handleClose(false);
      setSeverity("success");
      setSnackbarVisible(true);
      //}
    }
  };

  const clearInputs = () => {
    setDataForm({
      id: props.data?.id || "",
    id_cuentas: props.data?.id_cuentas || "",
    id_cuentas_localizaciones:
      props.data?.id_cuentas_localizaciones || selectedLocalizacion.id || "",
    id_tipo_telefonos: props.data?.id_tipo_telefonos || "",
    numero: props.data?.numero || "",
    selectedTipoTelefono: "",
    });
  };

  const handleClose = () => {
    props.handleClose(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        
        <Box
          sx={{
            mx: "auto",
            width: "100%",
            p: 1,
            m: 1,
            textAlign: "center",
            flexGrow: 1,
          }}
        >
          <JsonForms
            onChange={({ errors, data }) => onChange && onChange(errors, data)}
            schema={schemaTelefonosForm}
            uischema={uiSchemaTelefonosForm}
            renderers={[...materialRenderers, SelectorTipoTelefonoComponent]}
            data={dataForm}
            cells={materialCells}
          />
          <Grid container justifyContent="flex-end">
            <Buttons
              onClickSubmit={() => {
                sendInfo(true)
              }}
              labelButtonSave={"Guardar"}
              buttonCerrar={handleClose}
            />
            {/* <Button
              size="small"
              variant="contained"
              onClick={() => sendInfo(true)}
            >
              <CheckCircleOutline /> Cargar
            </Button> */}
          </Grid>

          <SnackbarMessenger
            open={snackbarVisible}
            message={message}
            severity={severity}
            close={() => {
              setSnackbarVisible(false);
            }}
          />
        </Box>
      </Container>
    </>
  );
};

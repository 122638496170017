import React, { useContext } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid"; // Importa Grid de Material-UI
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { v4 as uuidv4 } from 'uuid';
// import FormLabel from '@mui/material/FormLabel';

export const SwitchCombinarFiltros = () => {
  const { combinarFiltro, setCombinarFiltro, setCombinarFiltroContenidos } = useContext(CajasContext);
  const idSwitchCombinarFiltros = uuidv4();
  // const idSwitchFiltroContenidos = uuidv4();

  const handleCombinarFiltrosChange = (event) => {
    setCombinarFiltro(event.target.checked);
    if (event.target.checked) {
      setCombinarFiltroContenidos(false);
    }
  };

  // const handleCombinarFiltroContenidosChange = (event) => {
  //   setCombinarFiltroContenidos(event.target.checked);
  //   if (event.target.checked) {
  //     setCombinarFiltro(false);
  //   }
  // };

  return (
    <FormGroup>
      {/* <FormLabel component="legend" style={{fontSize: '14px'}}>
        Combinar Filtros por caja teniendo en cuenta
      </FormLabel> */}
      <Grid container alignItems="center" spacing={2}> {/* Usamos Grid con alignItems para alinear los elementos verticalmente */}
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                id={idSwitchCombinarFiltros}
                checked={combinarFiltro}
                onChange={handleCombinarFiltrosChange}
                inputProps={{ "aria-label": "combinar-filtros" }}
              />
            }
            label="Combinar Filtro"
            sx={{
              '&  .MuiTypography-root': {
                fontSize: '14px'
              },
            }}
          />
        </Grid>
        {/* <Grid item>
          <FormControlLabel
            control={
              <Switch
                id={idSwitchFiltroContenidos}
                checked={combinarFiltroContenidos}
                onChange={handleCombinarFiltroContenidosChange}
                inputProps={{ "aria-label": "combinar-filtro-contenidos" }}
              />
            }
            label="Varios contenidos"
            sx={{
              '&  .MuiTypography-root': {
                fontSize: '14px'
              },
            }}
          />
        </Grid> */}
      </Grid>
    </FormGroup>
  );
};
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import { getTipoMoneda } from "../../projects/articulos/helpers/TipoMonedaData";
import { esArray } from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";
import { Autocomplete, TextField } from "@mui/material";

const SelectorTipoMoneda = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.selectedTipoMoneda;

  const tipo_moneda = getFromLS("siam-tipo_moneda").tipo_moneda;
  const [tipoDeMonedaSeleccionado, setTipoDeMonedaSeleccionado] = useState("");
  let proyecto = JSON.parse(localStorage.getItem("parametros_proyecto")).id === 30 ? false : true;


  useState(() => {
    const tiposDeMonedas = async () => {
      if (tipo_moneda) {
        setDataDefaults(esArray(tipo_moneda));
      } else {
        const response = await getTipoMoneda()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    tiposDeMonedas()

  }, []);

  useEffect(() => {
    setTipoDeMonedaSeleccionado(dataDefaults.find((item) => item.id === tipo));

    if (proyecto && tipo === undefined) {
      setTipoDeMonedaSeleccionado(dataDefaults.find((item) => item.id === '1'))
      handleChange('', dataDefaults.find((item) => item.id === '1'))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDefaults, tipo]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      console.log(newValue, 'new')
      setTipoDeMonedaSeleccionado(newValue);
      props.handleChange(props.path, newValue.id); // Obtén el ID del objeto seleccionado
    } else {
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
          value={tipoDeMonedaSeleccionado || null}
          onChange={handleChange}
          options={dataDefaults || []}
          getOptionLabel={(option) => option.simbolo + " " + option.unidad_monetaria || ""}
          renderInput={(params) => (
            <TextField {...params}
              label="Tipo de Moneda"
              disabled={disabled} />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorTipoMonedaTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorTipoMoneda";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorTipoMonedaComponent = {
  renderer: withJsonFormsControlProps(SelectorTipoMoneda),
  tester: SelectorTipoMonedaTester,
};

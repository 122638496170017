import {
    materialCells,
    materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import Grid from "@mui/material/Grid";
import { useContext, useState, useEffect } from "react";
import { ImageList } from "@mui/material";
import { Buttons } from "src/general_components/Buttons";
import { CajasContext } from "../../../context/CajasContext/CajasContext";
import { FechaComprobanteComponent } from "../../../forms/customRenders/fechaComprobantesForm";
import { SelectorComprobanteEstadoComponent } from "../../../forms/customRenders/selectorComprobanteEditEstado";
import { schemaComprobantesEdit } from "../../../forms/schemaComprobantesEdit";
import { uiSchemaComprobantesEdit } from "../../../forms/uiSchemaComprobantesEdit";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CircularProgress from "@mui/material/CircularProgress";
import { SelectorComprobantesCodigoComponent } from "../../../forms/customRenders/selectorComprobantesCodigo";
import {
    postDigitalizacionesCaratulas,
    deleteImagesPrincipales,
} from "src/projects/gestion_documental/helpers/DigitalizacionData";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Zoom from "react-medium-image-zoom";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { esArray } from "src/general_components/functionEsArray"
import { SelectorClienteComponent } from "../../../forms/customRenders/selectorClientes";
import TablaPropiedadesComprobantesForm from 'src/general_components//TablaPropiedadesComprobantesForm';
import FormControl from '@mui/material/FormControl';
import { actualizarComprobante } from "src/projects/gestion_documental/helpers/ComprobantesData";
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

export const FormEditarComprobante = (props = null) => {
    const {
        setSnackbarVisible,
        setSeverity,
        setMessage,
    } = useContext(CajasContext);

    const [index, setIndex] = useState(false);
    const [file, setFile] = useState([]);
    const [base64String, setBase64String] = useState("");
    const [loading, setLoading] = useState(false);
    const [newImage, setNewImage] = useState(false);
    const [deleteTrue, setDeleteTrue] = useState(false);
    const [deleteStoredImage, setDeleteStoredImage] = useState(false);
    const [idsImagenesDeleted, setIdsImagenesDeleted] = useState([]);


    const [propiedad, setPropiedad] = useState([]);
    const [importePorPropiedad, setImportePorPropiedad] = useState(0);
    const [propiedadesTable, setPropiedadesTable] = useState([]);


    let numeroFormateado = props?.row?.total.slice(1); // Esto eliminará el primer carácter, que es "$"

    // Convertir la cadena en un número
    const valor = parseFloat(numeroFormateado);

    const [importe, setImporte] = useState(0);
    const [importeBD, setImporteBD] = useState(valor);


    useEffect(() => {
        const formatPropiedades = async () => {
            let propiedades = esArray(props?.row?.item)
            if (propiedades && propiedades.length > 0) {
                let propiedadesFormat = propiedades.map(item => ({
                    articulo: item.articulo,
                    id: item.id,
                    propiedad: item.descripcion, // Cambiamos "descripcion" a "propiedad"
                    importe: parseFloat(item.total.slice(1)) // Eliminamos el símbolo "$" y convertimos a número
                }));
                setPropiedadesTable(propiedadesFormat)
                setPropiedad(propiedadesFormat)
            }

        };
        formatPropiedades()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (propiedad && propiedad.length > 0) {

            const importePropiedad = (importe / propiedad.length).toFixed(2);

            // // Añadir las nuevas propiedades a cada objeto
            const objetosConPropiedades = propiedad.map(objeto => ({
                ...objeto,
                importe: importePropiedad
            }));

            setImportePorPropiedad(importePropiedad);
            setPropiedadesTable(objetosConPropiedades);
            setImporteBD(importe)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importe]);

    /// Contiene los datos del Jsonform
    const [dataForm, setDataForm] = useState({
        comprobante_codigo: props?.row?.id_codigo,
        fecha_creacion: props?.row?.fecha_comprobante,
        fecha_trabajo: props?.row?.fecha_trabajo,
        estadoNombre: props?.row?.estado,
        comprobante_id_estado: props?.row?.id_comprobantes_estado,
        cliente: props?.row?.id_cuenta,
        editar: true
    });

    const FormatDate = (date) => {
        let day, month, year;

        // Detectar si el delimitador es '/' o '-'
        if (date.includes('-')) {
            [day, month, year] = date.split('-');
        } else if (date.includes('/')) {
            [day, month, year] = date.split('/');
        }

        // Reorganizamos la fecha en el formato deseado
        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    };

    //NOTE FUNCION PARA ELIMINAR UNA IMAGEN
    if (deleteTrue) {
        if (file[index].save) {
            setDeleteStoredImage(true);
            setIdsImagenesDeleted((prevIds) => [...prevIds, file[index]?.id]);

            if (file.length === 1) {
                setFile([])
            } else {

                let newFile = [...file];
                newFile.splice(index, 1);
                setFile(newFile);
            }

            //NOTE FUNCION PARA SABER SI SE SACAN TODAS LAS IMAGENES PARA ALMACENAR
            const imagesNews = file.filter((item) => item.save === false);

            if (imagesNews.length === 0) {
                setNewImage(false);
            }

            setIndex("");
        } else {
            let newFile = [...file];
            newFile.splice(index, 1);
            setFile(newFile);

            //NOTE FUNCION PARA SABER SI SE SACAN TODAS LAS IMAGENES PARA ALMACENAR
            const imagesNews = newFile.filter((item) => item.save === false);
            if (imagesNews.length === 0) {
                setNewImage(false);
            }

        }
        setDeleteTrue(!deleteTrue);
    }

    const handleSubmit = async () => {
        let modAppy = false
        let response = ''
        //Creamos variables de las fechas, solo se usan para las condiciones
        let estadoIdBD = props?.row?.id_comprobantes_estado
        let estadoIdForm = dataForm?.comprobante_id_estado;
        let fechaCreacionForm = dataForm.fecha_creacion.includes('/') ? FormatDate(dataForm.fecha_creacion) : dataForm.fecha_creacion;
        let fechaCreacionBD = FormatDate(props?.row?.fecha_comprobante);

        let fechaTrabajoForm = dataForm.fecha_trabajo.includes('/') ? FormatDate(dataForm.fecha_trabajo) : dataForm.fecha_trabajo;
        let fechaTrabajoBD = FormatDate(props?.row?.fecha_trabajo);

        let totalForm = importe;
        let totalBD = valor;
        let codigoComprobante = dataForm?.comprobante_codigo


        if (importeBD <= 0) {
            setMessage("El importe debe ser mayor a 0")
            setSeverity("warning");
            setSnackbarVisible(true);
            return;
        }

        // Calcular la suma de todos los importes en propiedadesTable
        const sumaImportes = propiedadesTable.reduce((total, objeto) => total + parseFloat(objeto.importe), 0);

        // Verificar si la suma de importes es menor o igual a importe
        if (sumaImportes > importeBD) {
            setMessage("La suma de los importes de cada propiedad no puede ser mayor que el importe general.")
            setSeverity("warning");
            setSnackbarVisible(true);
            return;
        }

        const datePattern = /^\d{4}-\d{2}-\d{2}$/;

        if (!datePattern.test(fechaCreacionForm)) {
            setSnackbarVisible(true);
            setSeverity("warning");
            setMessage("Formato de fecha incorrecto");
            return;
        }

        if (estadoIdForm !== estadoIdBD || fechaCreacionBD !== fechaCreacionForm || fechaTrabajoBD !== fechaTrabajoForm || totalBD !== totalForm) {
            let estado = estadoIdForm;
            if (estado <= 0) {
                setMessage("Debe ingresar un estado")
                setSeverity("warning");
                setSnackbarVisible(true);
                return;
            }

            response = await actualizarComprobante(props?.row?.id, fechaCreacionForm, estado, fechaTrabajoForm, totalForm, codigoComprobante, propiedadesTable)
            modAppy = true

        }
        if (newImage) {
            response = await postDigitalizacionesCaratulas(file);
            modAppy = true
        }
        if (deleteStoredImage) {
            for (const id of idsImagenesDeleted) {
                response = await deleteImagesPrincipales(id);
            }
            modAppy = true
        }
        if (modAppy) {
            let message = "Se realizo correctamente la modificacion"
            let statusMessage = 'success'

            if (response?.status === 'false') {

                message = response.message
                statusMessage = 'error'

            }

            setMessage(message);
            setSeverity(statusMessage);
            setSnackbarVisible(true);
            props.setRefresh(!props.refresh)

        }

        handleClose();
    };
    const handleClose = () => {
        props.cerrarModal(false);
    };

    const uploadImages = async (event) => {
        let fileObj = [];
        fileObj.push(event.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            setLoading(true);
            let imageBase64 = await convertImageToBase64(
                URL.createObjectURL(fileObj[0][i]),
                event.target.files[0].name
            );
            setBase64String(imageBase64);
        }

    };

    const convertImageToBase64 = async (url, name) => {

        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    useEffect(() => {
        const convertImageToBase64 = async () => {
            if (props?.row.imagenes && file.length === 0) {
                const arrImg = esArray(props?.row.imagenes);
                const result = await getImagesSave(arrImg);

                setFile(result);
            }

            if (base64String !== '') {
                setFile([
                    {
                        modelo_padre: 'comprobantes',
                        id_modelo_padre: props?.row?.id,
                        image: base64String,
                        save: false,
                        checked: false,
                    },
                    ...file,
                ]);
                setNewImage(true);
                setBase64String('');
                setLoading(false);
            }
        };

        convertImageToBase64();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base64String]);



    const getImagesSave = async (imagenes) => {

        const formattedData = imagenes.map((img) => ({
            id: img.id,
            image: img.archivo_ruta || "",
            model: img.modelo_padre || "",
            save: true,
            checked: false,
            id_modelo_padre: img.id_modelo_padre || "",
        }));

        return formattedData;
    };


    return (
        <div>
            <JsonForms
                schema={schemaComprobantesEdit}
                uischema={uiSchemaComprobantesEdit}
                data={dataForm}
                renderers={[
                    ...materialRenderers,
                    SelectorComprobanteEstadoComponent,
                    FechaComprobanteComponent,
                    SelectorClienteComponent,
                    SelectorComprobantesCodigoComponent
                ]}
                cells={materialCells}
                onChange={({ data, _errors }) => setDataForm(data)}
            />
            <Grid container style={{ textAlign: "left" }}>
                <Grid item xs={6} sm={6} style={{ textAlign: "left", paddingTop: 20 }}>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="importe">Importe</InputLabel>
                        <OutlinedInput
                            id="importe1"
                            size="small"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Importe"
                            onChange={(e) => setImporte(e.target.value)}
                            type="number"
                            value={importeBD}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ textAlign: "left", paddingTop: 25 }}>
                    <Button variant="contained" component="label" endIcon={<PhotoCamera style={{ color: "#FFFFFF" }} />}>
                        Agregar Imagenes
                        <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={uploadImages}
                        />
                    </Button>
                </Grid>
            </Grid>
            <Grid style={{ textAlign: "left", paddingTop: "15px" }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{
                        textAlign: "center",
                        display: "block",
                    }}
                >
                    {loading ? (
                        <Grid style={{ textAlign: "center" }}>
                            <CircularProgress style={{ width: "50px", height: "50px" }} />
                        </Grid>
                    ) : (
                        <ImageList
                            sx={{
                                gridAutoFlow: "column",
                                gridTemplateColumns:
                                    "repeat(auto-fill,minmax(160px,1fr)) !important",
                                gridAutoColumns: "minmax(160px, 1fr)",
                                gap: "70px !important",
                                padding: "15px",
                            }}
                        >
                            {
                                file.map((img, key) => {
                                    let updatedLink = img.image;
                                    if (updatedLink.includes("var")) {
                                        updatedLink = updatedLink.replace("/var/www/", "https://");
                                    }
                                    return (
                                        <Box key={key}>
                                            <Zoom isZoomed={true}>
                                                <img src={updatedLink} alt={img.descripcion} className="image" />
                                                <Grid item xs={12}>
                                                    <IconButton
                                                        className="iconButton"
                                                        aria-label="delete"
                                                        onClick={() => {
                                                            setDeleteTrue(true);
                                                            setIndex(key);
                                                        }}
                                                    >
                                                        <DeleteIcon className="icon" />
                                                    </IconButton>
                                                </Grid>
                                            </Zoom>
                                        </Box>
                                    );
                                })
                            }
                        </ImageList>
                    )}
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TablaPropiedadesComprobantesForm
                    importePorPropiedad={importePorPropiedad}
                    importe={importe}
                    propiedadesTable={propiedadesTable}
                    setPropiedadesTable={setPropiedadesTable}
                />
            </Grid>
            <Grid container style={{ paddingTop: "15px", justifyContent: "center" }}>
                <Buttons
                    variant="contained"
                    color="success"
                    onClickSubmit={handleSubmit}
                    labelButtonSave={"Guardar"}
                    buttonCerrar={handleClose}
                />
            </Grid>
        </div >
    );
};

let paramsProyect = JSON.parse(localStorage.getItem("parametros_proyecto"))
let mostrar = paramsProyect?.id === 30 ? true : false;

export const uiSchemaArticulosForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/nombre",
        },
        {
          type: "Control",
          scope: "#/properties/selectedCategoria",
        },
      ],
    }, {
      type: "HorizontalLayout",
      elements: mostrar
        ? [
          {
            type: "Control",
            scope: "#/properties/nombre_reducido",
          },
          {
            type: "Control",
            scope: "#/properties/selectedArticulosTipo",
          },
        ]
        : [
          {
            type: "Control",
            scope: "#/properties/selectedArticulosTipo",
          },
        ],
    }, {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/alias",
        },
        {
          type: "Control",
          scope: "#/properties/selectedTipoMoneda",
        },
      ],
    }, {
      type: "HorizontalLayout",
      elements: mostrar
        ? [
          {
            type: "Control",
            scope: "#/properties/selectedAlicuotasIVA",
          }
        ] : [],
    },
  ],
}

export const uiSchemaArticulosFormSinAlicuotasIVA = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/nombre",
        },
        {
          type: "Control",
          scope: "#/properties/selectedCategoria",
        },
      ],
    }, {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/nombre_reducido",
        },
        {
          type: "Control",
          scope: "#/properties/selectedArticulosTipo",
        },
      ],
    }, {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/alias",
        },
        {
          type: "Control",
          scope: "#/properties/selectedTipoMoneda",
        },
      ],
    },
  ],
}


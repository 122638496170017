import React, { useEffect, useReducer } from "react";
import { AuthContext } from "./context/AuthContext";
import { authReducer } from "./context/authReducer";
import { AppRouter } from "./routers/AppRouter";
import { LicenseInfoDataGrid } from "./configs/config";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import "./scss/style.scss";
import { ThemeContextProvider } from "./context/ThemeContext";


const init = () => {
  return (
    JSON.parse(localStorage.getItem("user")) || { isLogged: false, token: "" }
  );
};

export const App = () => {
  const [user, dispatch] = useReducer(authReducer, {}, init);
  LicenseInfo.setLicenseKey(LicenseInfoDataGrid.license);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <ThemeContextProvider>
      <AuthContext.Provider value={{ user, dispatch }}>
        <AppRouter />
      </AuthContext.Provider>
    </ThemeContextProvider>

  );
};

export default App;



import FormControl from "@mui/material/FormControl";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { getTipoContenidosYmetadatos } from "../helpers/TipoContenidosData";
import { getMetadatosWhereIdTipoContenidos } from "../helpers/MetadatoData";
import { ContenidosFormDinamico } from "./ContenidosFormDinamico";
import { postContenidos, updateContenidos } from "../helpers/ContenidosData";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Autocomplete, TextField } from "@mui/material";
import LoadingOverlay from "src/general_components/LoadingOverlay";
import { Button, Grid } from "@mui/material";
import { TipoContenidosForm } from "./TipoContenidosForm";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import AddIcon from "@mui/icons-material/Add";
import { getTipoContenidosLocalizaciones } from 'src/projects/gestion_comercial/cuentas/helpers/TipoContenidosData';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import moment from "moment";
import { getLoteMaximo } from "../helpers/ContenidosData";

export const TipoContenidosSelect = (data) => {
  const {
    setTipoContenido, setShowForm, tipoContenidoSeleccionado, setTipoContenidoSeleccionado,
    retiroContenido, disabled, setModalStateCont,
    modalStateCont, verTodosTContenidos, setVerTodosTContenidos, setSelectedLocalizacion, setIdCuentaLocalizacionesSelected
  } = useContext(CajasContext);

  //Variables para el formulario ya sea edicion o creacion
  const {
    modalStateContList,
    setModalStateContList,
    cajaId,
    setActualizarListadoContenidos,
    // digitalizado,
    id_cuentas_localizaciones,
    habilitarLote
  } = data;

  const [dialogChild, setDialogChild] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [modalName, setModalName] = useState("Localizaciones");
  const [loteMaximo, setLoteMaximo] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [buttonEnviar, setButtonEnviar] = useState(false);
  const [actualizarOptions, setActualizarOptions] = useState(false);

  useEffect(() => {
    const setSwitchVerTodos = async () => {

      setVerTodosTContenidos(false)
    };

    setSwitchVerTodos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  let url = window.location.href;
  let procesoGenerica = false;
  const arrUrl = url.split("/");
  const proceso = arrUrl[arrUrl.length - 1]; // retorna el ultimo valor de la url
  if (proceso === "contenidos") {
    // eslint-disable-next-line no-unused-vars
    procesoGenerica = true;
  }

  //NOTE tipoContenidoSelect VIENE DESDE DIGITALIZACIONES, SE UTILIZA PARA DIRECTAMENTE QUE APAREZCA EL FORMULARIO, 
  //PORQUE EN DIGITALIZACIONES PRIMERO SE CARGA EL TIPO DE CONTENIDO Y LUEGO EL FORMULARIO
  if (data.tipoContenidoSelect) {
    if (tipoContenidoSeleccionado === "") {
      setTipoContenidoSeleccionado(data.tipoContenidoSelect);
    }
  }

  const [tiposContenidos, setTiposContenidos] = useState([]);
  const [metadatosList, setMetadatosList] = useState([]);
  const [inputsValues, setInputsValues] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [contenidosInfo, setContenidosInfo] = useState({
    id: "",
    id_cajas: cajaId,
    id_tipo_contenidos: tipoContenidoSeleccionado,
    meta_datos: [
      { id: "", id_metadatos: "1", valor: "" },
      { id: "", id_metadatos: "2", valor: "" },
      { id: "", id_metadatos: "3", valor: "" },
      { id: "", id_metadatos: "4", valor: "" },
      { id: "", id_metadatos: "5", valor: "" },
    ],
  });
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [tipoContenidoSelectt, setTtipoContenidoSelect] = useState("");
  const idTipoContenido = data?.data?.id_tipo_contenidos;
  const objTipoContenido = data?.data?.relations?.tipo_contenidos;

  const seleccionarContenido = (preferencias) => {
    let seleccionado = null;
    for (const contenido of preferencias) {
      if (seleccionado === null || contenido.detalle !== 'Estandar') {
        seleccionado = contenido;
      } else if (contenido.detalle === 'Estandar' && seleccionado.detalle === 'Estandar') {
        if (parseInt(contenido.id) < parseInt(seleccionado.id)) {
          seleccionado = contenido;
        }
      }
    }
    return seleccionado;
  };


  useEffect(() => {
    const getTipoContenido = async () => {
      setIsLoadingData(true);
      // se obtiene url para saber que proceso esta usando el selector
      // dependiendo el proceso se aplica un filtro a la consulta
      let filtroDigitalizado = false;
      let todosTipoContendios = JSON.parse(localStorage.getItem("siam-tipo_contenidos"))?.tipo_contenidos;
      let cuentas_localizaciones_tipo_contenidos = []
      if (actualizarOptions) {
        let data = await getTipoContenidosLocalizaciones();
        let jsonActualizado = { cuentas_localizaciones_tipo_contenidos: data }
        localStorage.setItem("siam-cuentas_localizaciones_tipo_contenidos", JSON.stringify(jsonActualizado));
      }
      cuentas_localizaciones_tipo_contenidos = JSON.parse(localStorage.getItem("siam-cuentas_localizaciones_tipo_contenidos"))?.cuentas_localizaciones_tipo_contenidos || [];
      cuentas_localizaciones_tipo_contenidos = cuentas_localizaciones_tipo_contenidos.filter(objeto => objeto.id_cuentas_localizaciones === id_cuentas_localizaciones);
      let tipoContendios = '';
      if (cuentas_localizaciones_tipo_contenidos && !verTodosTContenidos) {
        if (!todosTipoContendios) {
          todosTipoContendios = await getTipoContenidosYmetadatos(filtroDigitalizado);
        }
        tipoContendios = todosTipoContendios.filter((tipoContenido) =>
          cuentas_localizaciones_tipo_contenidos?.some((obj) => obj.id_tipo_contenidos === tipoContenido.id));
      }
      if (objTipoContenido) {
        tipoContendios = [...tipoContendios, objTipoContenido];
      }
      if (verTodosTContenidos) {
        tipoContendios = todosTipoContendios
      }
      setTiposContenidos(FiltrarPorDigitalizado(tipoContendios));
      if (idTipoContenido) {
        setTipoContenidoSeleccionado(idTipoContenido ? idTipoContenido : "");
        setTtipoContenidoSelect(objTipoContenido ? objTipoContenido : '')
      } else {
        const contenidoPreseleccionado = seleccionarContenido(tipoContendios);
        setTipoContenidoSeleccionado(contenidoPreseleccionado ? contenidoPreseleccionado.id : "");
        setTtipoContenidoSelect(contenidoPreseleccionado ? contenidoPreseleccionado : '')
      }
      setIsLoadingData(false);
      setActualizarOptions(false)
    };


    getTipoContenido();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_cuentas_localizaciones, verTodosTContenidos, actualizarOptions]);


  const FiltrarPorDigitalizado = (arrayDeObjetos) => {
    return arrayDeObjetos
    /*
    let objetosFiltrados;
    // Utilizamos el método filter para obtener un nuevo array con solo los objetos que cumplen la condición
    if (digitalizado) {
      objetosFiltrados = arrayDeObjetos.filter(objeto => objeto.digitalizado === '1');
    } else {
      objetosFiltrados = arrayDeObjetos.filter(objeto => objeto.digitalizado === '0');
    }

    // Devolvemos el nuevo array (puedes ajustar esto según tus necesidades)
    return objetosFiltrados;
    */
  }

  useEffect(() => {
    const getLote = async () => {
      const lote = await getLoteMaximo(cajaId);
      setLoteMaximo(lote);
      setLote(lote);
    }
    !data?.data?.lote && habilitarLote &&  getLote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getMetadatos = async () => {
      setIsLoadingData(true);

      let response = await getMetadatosWhereIdTipoContenidos(
        tipoContenidoSeleccionado
      );
      let valuesMetadatosEdit = data.data?.contenidos_metadatos
        ? data.data.contenidos_metadatos
        : undefined;
      const responseMetadatos = response.map((metadato) => {
        let valor = "";
        let id_metadato = "";
        if (valuesMetadatosEdit) {
          for (var i = 0; i < valuesMetadatosEdit.length; i++) {
            if (valuesMetadatosEdit[i].id_metadatos === metadato.id) {
              valor = valuesMetadatosEdit[i].valor;
              id_metadato = valuesMetadatosEdit[i].id;
              break;
            }
          }
        }
        return { ...metadato, valor: valor, id_metadato: id_metadato };
      });
      setMetadatosList(responseMetadatos);
      data.setMetadatos && data.setMetadatos(responseMetadatos);
      setInputsValues(responseMetadatos);
      setIsLoadingData(false);
    };

    if (tipoContenidoSeleccionado && tipoContenidoSeleccionado !== "" && !Array.isArray(tipoContenidoSeleccionado)) {
      getMetadatos();
      setContenidosInfo({
        ...contenidosInfo,
        id_tipo_contenidos: tipoContenidoSeleccionado,
      });
    } else {
      setMetadatosList([]);
      data.setMetadatos && data.setMetadatos([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoContenidoSeleccionado]);

  useEffect(() => {
    const metadatosValues = inputsValues
      .filter(input => input.valor !== "" || input.id_metadato !== "")
      .map(input => ({
        id: input.id_metadato,
        id_metadatos: input.id,
        valor: moment(input.valor, 'DD-MM-YYYY', true).isValid() ? moment(input.valor, 'DD-MM-YYYY').format('YYYY-MM-DD') : input.valor,
      }));

    setContenidosInfo({ ...contenidosInfo, meta_datos: metadatosValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsValues]);

  const toggleModal = () => {
    setModalStateCont(!modalStateCont);
    if (setModalStateContList) {
      setModalStateContList(!modalStateContList)
    }
    if (modalStateCont || modalStateContList) {
      data.setLabelModal("Alta de Contenidos");
      data.setData(false);
    }
    setTipoContenidoSeleccionado(null);
    setTtipoContenidoSelect(null);
  };

  const updateList = () => {
    data.updateList();
  };

  const handleSubmit = async (e) => {
    setLoadingSubmit(true)
    let validado = true;
    let responseServicio = "";
    if (validado !== false) {
      if (data.data) {
        e.preventDefault();
        let id = data.data.id;
        let datos = contenidosInfo;
        datos.id_cajas = cajaId;
        datos.id = id;
        datos.lote = isNaN(lote) || parseInt(lote) <= 0 ? '1' : lote.toString();
        responseServicio = await updateContenidos(datos);
        if (responseServicio.status === "false") {
          setLoadingSubmit(false);
          setMessage(responseServicio.message);
          setSeverity("error");
          setSnackbarVisible(true);
        } else {
          setMessage("Se ha modificado exitosamente el contenido");
          setSeverity("success");
          setSnackbarVisible(true);
          const ejecutarServicios = async () => {
            setLoadingSubmit(false);
            updateList();
            toggleModal();
            setActualizarListadoContenidos(true);
          };

          setTimeout(ejecutarServicios, 1000);
        }
      } else {
        e.preventDefault();
        let data = contenidosInfo;
        data.id_cajas = cajaId;
        console.log("lote", lote);
        data.lote = isNaN(lote) || parseInt(lote) <= 0 ? '1' : lote.toString();
        responseServicio = await postContenidos(data);
        if (responseServicio.status === "false") {
          setLoadingSubmit(false);
          setMessage(responseServicio.message);
          setSeverity("error");
          setSnackbarVisible(true);

        } else {
          setMessage("Se ha añadido exitosamente el contenido");
          setSeverity("success");
          setSnackbarVisible(true);
          const ejecutarServicios = async () => {
            setLoadingSubmit(false);
            updateList();
            toggleModal();
            setActualizarListadoContenidos(true);
          };

          setTimeout(ejecutarServicios, 1000);
        }
      }
    }
  };

  const handleChange = (event, newValue) => {
    setTipoContenido(newValue.id);
    setShowForm(true);
    setTipoContenidoSeleccionado(newValue.id);
    setTtipoContenidoSelect(newValue)
  };

  const estilosSelect = {
    fontSize: "1em", color: "#646464", fontFamily: 'Roboto, sans-serif', backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  const handleClear = () => {

    setTtipoContenidoSelect(null);
    setTipoContenidoSeleccionado(null);
  };

  const [lote, setLote] = useState(data?.data?.lote || loteMaximo || '1');

  const handleLoteChange = (event) => {
    const value = event.target.value;
    // Verificar si el valor es vacío, un número positivo mayor a 0 o una cadena vacía
    if (value === '' || (!isNaN(value) && parseInt(value) > 0)) {
      setLote(value);
    }
  };

  if (isLoadingData && !data.ImportFile) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <div>
        <LoadingOverlay open={loadingSubmit} />
        <Grid container>
          {habilitarLote && (
            <Grid item xs={3} sx={{ mt: data.ImportFile ? 2 : 1 }} >
              <TextField
                label="Lote"
                size="small"
                type="number"
                value={lote}
                onChange={handleLoteChange}
                style={estilosSelect}
              />
            </Grid>
          )}

          <Grid item xs={habilitarLote ? 7 : 10}>
            <FormControl sx={{ mt: data.ImportFile ? 2 : 1, minWidth: "100%" }} size="small">
              <Autocomplete
                size="small"
                value={tipoContenidoSelectt || null}
                onChange={handleChange}
                options={tiposContenidos || []}
                // disableClearable
                disabled={objTipoContenido ? true : false}
                style={data.ImportFile ? estilosSelect : null}
                getOptionLabel={(option) => option.id + ' - ' + option.detalle || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipos de Contenido"
                    disabled={disabled}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        tipoContenidoSelectt && !habilitarLote && (
                          <IconButton
                            size="small"
                            onClick={handleClear}
                            style={{
                              marginLeft: '-25px', // Ajusta este valor según sea necesario
                              color: 'rgba(0, 0, 0, 0.54)',
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        )
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                let cuentas_localizaciones_tipo_contenidos = JSON.parse(localStorage.getItem("siam-cuentas_localizaciones_tipo_contenidos"))?.cuentas_localizaciones_tipo_contenidos || [];
                let cuenta_localizaciones_filtradas = cuentas_localizaciones_tipo_contenidos.filter(objeto => objeto.id_cuentas_localizaciones === id_cuentas_localizaciones)
                setIdCuentaLocalizacionesSelected(id_cuentas_localizaciones)
                setSelectedLocalizacion(cuenta_localizaciones_filtradas);
                setDialogChild(<TipoContenidosForm setActualizarOptions={setActualizarOptions} handleClose={setModalState} />);
                setModalName("Tipo Contenidos");
                setModalState(!modalState);
              }}
              color="success"
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
                margin: '11px'
              }}
            >
              <div>Agregar</div>
            </Button>

          </Grid>
        </Grid>
        {
          !data.ImportFile && (metadatosList.length > 0 &&
            tipoContenidoSeleccionado !== "" &&
            retiroContenido === false) ? (
            <ContenidosFormDinamico
              data={data}
              inputsListValues={metadatosList}
              toggleModal={toggleModal}
              setInputsValues={setInputsValues}
              handleSubmit={handleSubmit}
              contenidoSeleccionado={data.contenidoSeleccionado}
              selectorCajas={data.selectorCajas}
            />
          ) : undefined
        }
        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          close={() => {
            setSnackbarVisible(false);
          }}
        />
        <SimpleDialogComponent
          open={modalState}
          modalName={modalName}
          child={dialogChild}
          handleClose={() =>
            setModalState(false)
          }

          buttonEnviar={buttonEnviar}
          buttonCancelar={true}
        />
      </div >
    );
  }
};

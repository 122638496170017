import Grid from "@mui/material/Grid";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
//import { getCuentasJuridicas } from "src/projects/gestion_comercial/helpers/CuentasJuridicasData";
import { esArray } from "src/general_components/functionEsArray";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import { getCuentasLocalizaciones } from "src/projects/gestion_comercial/helpers/CuentasLocalizaciones";

export const ClienteSector = (data = null) => {
  const {
    clientesList,
    setClientesList,
    clienteId,
    setClienteId,
    sectoresList,
    setSectoresList,
    sectorId,
    setSectorId,
    disabled,
    altaGenerica,
    setClienteName,
    setTipoContenidoListCuentaSector
  } = useContext(CajasContext);

  const {
    useGetDisponibles,
    dataEdit
  } = data;

  const [inputValueCliente, setInputValueCliente] = useState('');
  const [inputValueSector, setInputValueSector] = useState('');
  const [primerRender, setPrimerRender] = useState(false);

  useEffect(() => {
    setSectorId([]);
    setClienteId([]);
    const getClientes = async () => {

      var clientes = [];
      let cuentas = esArray(JSON.parse(localStorage.getItem("siam-service-getCuentasGenericas")).cuentas);
      let condicionIN = false;

      if (data.procesoGenerica || altaGenerica) {

        condicionIN = true;
      }
      // clientes = await getCuentasJuridicas(cuentas,condicionIN);

      let clients = esArray(JSON.parse(localStorage.getItem("siam-cuentas_personas_juridicas")).cuentas_personas_juridicas);
      clientes = clients.filter((cliente) =>
        cuentas.find(
          (cuenta) => {
            if (condicionIN === true) {
              return cuenta.id_cuentas === cliente.id_cuentas

            } else {
              return cuenta.id_cuentas !== cliente.id_cuentas
            }
          }
        )
      );

      const user_cuentas = JSON.parse(localStorage.getItem("siam-user-cuentas"));
      let userCuentasStorage = esArray(user_cuentas);
      if (userCuentasStorage.length < 2) {
        let usersCuentasModificado = [{ usuarios_cuentas: userCuentasStorage[0] }]
        userCuentasStorage = usersCuentasModificado;
      }
      if (user_cuentas.length > 0 && (!altaGenerica || !data.procesoGenerica)) {
        let clientesFilter = clientes.filter((cliente) => userCuentasStorage.find((obj) => obj.usuarios_cuentas.id_cuentas === cliente.id_cuentas))
        let newClientes = Object.entries(clientesFilter).map(([key, value]) => {
          let objeto = value;
          objeto["label"] = value.razon_social;
          return objeto;
        });

        setClientesList(newClientes);

        // Logica para edicion
        if (dataEdit) {
          const idCuentaBuscado = dataEdit.id_cuentas; // Puedes cambiar esto por el ID que estás buscando

          const objetoEncontrado = newClientes.find(item => item.id_cuentas === idCuentaBuscado);

          if (objetoEncontrado) {
            console.log("Objeto encontrado:", objetoEncontrado);
            setClienteId(objetoEncontrado);
          } else {
            console.log("No se encontró ningún objeto con el id_cuentas especificado.");
          }
        }




      } else {
        let newClientes = Object.entries(clientes).map(([key, value]) => {
          let objeto = value;
          objeto["label"] = value.razon_social;
          return objeto;
        });

        setClientesList(newClientes);

        // Logica para edicion
        if (dataEdit) {
          const idCuentaBuscado = dataEdit.id_cuentas; // Puedes cambiar esto por el ID que estás buscando

          const objetoEncontrado = newClientes.find(item => item.id_cuentas === idCuentaBuscado);

          if (objetoEncontrado) {
            console.log("Objeto encontrado:", objetoEncontrado);
            setPrimerRender(true);
            setClienteId(objetoEncontrado);
          } else {
            console.log("No se encontró ningún objeto con el id_cuentas especificado.");
          }
        }
      }
    };
    getClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [altaGenerica]);

  useEffect(() => {
    const getSectores = async () => {
      const Sec = await getCuentasLocalizaciones(clienteId.id_cuentas);

      // let cuentas_localizaciones_tipo_contenidos = JSON.parse(localStorage.getItem("siam-cuentas_localizaciones_tipo_contenidos")).cuentas_localizaciones_tipo_contenidos;
      // let cuenta_localizaciones = JSON.parse(localStorage.getItem("siam-cuentas_localizaciones")).cuentas_localizaciones;

      // const joinedArray = cuenta_localizaciones.map(cuenta_localizacion => {
      //   const relatedItem = cuentas_localizaciones_tipo_contenidos.filter(cuenta_localizacion_tipo_contenido => cuenta_localizacion.id === cuenta_localizacion_tipo_contenido.id_cuentas_localizaciones);
      //   return { ...cuenta_localizacion, relations: { cuentas_localizaciones_tipo_contenidos: { ...Object.values(relatedItem)['0'] } } };
      // });

      // const Sec = joinedArray.filter(array => array.id_cuentas === clienteId.id_cuentas);

      const cliente = clientesList.find(clienteFind => clienteFind.id_cuentas === clienteId.id_cuentas);
      setClienteName(cliente["nombre_fantasia"])
      let newSectores = Object.entries(Sec).map(([key, value]) => {
        let objeto = value;
        objeto["label"] = value.descripcion;
        return objeto;
      });
      // Logica para edicion
      const idSectorBuscado = dataEdit?.id_cuentas_localizaciones; // Puedes cambiar esto por el ID que estás buscando
      const objetoEncontrado = newSectores?.find(item => item.id === idSectorBuscado);

      if (objetoEncontrado) {
        console.log("Objeto encontrado:", objetoEncontrado);
      } else {
        console.log("No se encontró ningún objeto con el id_cuentas especificado.");
      }
      setSectoresList(newSectores);
      if (primerRender) {
        setSectorId(objetoEncontrado)
        setPrimerRender(false);
      }
    };
    if (clienteId && !Array.isArray(clienteId) && clienteId !== "" && clienteId !== null) {
      getSectores();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteId]);

  console.log("input value", inputValueCliente)

  // useEffect(() => {
  //   const getSector = async () => {
  //     const secId = await getCuentasLocalizaciones(clienteId.id_cuentas);
  //     setSectorId(secId);
  //   };
  //   if (sectorId && !Array.isArray(sectorId) && sectorId !== "") {
  //     getSector(sectorId.id);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const estilosSelect = {
    fontSize: "1em", color: "#646464", fontFamily: 'Roboto, sans-serif', backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };


  // Función personalizada de filtrado para buscar dentro del objeto cliente por id y razon_social
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => {
      const label = option.label.toLowerCase();
      const inputValueLowerCase = inputValue.toLowerCase();
      const idString = option.id_cuentas.toString().toLowerCase();
      return label.includes(inputValueLowerCase) || idString.includes(inputValueLowerCase);
    });
  };

  const handleChangeSectores = (event, newValue) => {
    setSectorId(newValue);
    //setTipoContenidoSeleccionado(newValue[0]?.relations?.cuentas_localizaciones_tipo_contenidos?.id_tipo_contenidos)
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={data.ImportFile ? 12 : 6} >
          <Autocomplete
            {...(disabled ? { disabled } : {})}
            size="small"
            value={Array.isArray(clienteId) || clienteId === "" ? null : clienteId}
            onChange={(event, newValue) => {
              setClienteId(newValue);
              setSectorId("");
            }}
            inputValue={inputValueCliente}
            onInputChange={(event, newInputValue) => {
              setInputValueCliente(newInputValue);
            }}
            id="controllable-states-demo1"
            options={clientesList}
            filterOptions={filterOptions} // Aquí proporcionamos la función de filtrado personalizada
            style={{ ...estilosSelect, marginRight: data.ImportFile ? 0 : 10 }}
            disableClearable={true}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.id_cuentas + ' - ' + option.label}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} label="Cliente" InputProps={{
              ...params.InputProps,
              type: 'search',
            }} />}
          />
        </Grid>
        <Grid item xs={data.ImportFile ? 12 : 6} style={{ marginTop: data.ImportFile ? '3%' : 0, }}>

          {!useGetDisponibles && (<Autocomplete
            {...(disabled ? { disabled } : {})}
            size="small"
            value={Array.isArray(sectorId) || sectorId === "" ? null : sectorId}
            onChange={(event, newValue) => {
              setSectorId(newValue);
              if (newValue.relations) {

                // setTipoContenidoSeleccionado(esArray(newValue?.relations?.cuentas_localizaciones_tipo_contenidos))
                setTipoContenidoListCuentaSector(esArray(newValue?.relations?.cuentas_localizaciones_tipo_contenidos))
              } else {
                //  setTipoContenidoSeleccionado([])
                setTipoContenidoListCuentaSector([])
              }
            }}
            inputValue={inputValueSector}
            onInputChange={(event, newInputValue) => {
              setInputValueSector(newInputValue);
            }}
            id="controllable-states-demo2"
            options={sectoresList}
            style={estilosSelect}
            disableClearable={true}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.id + ' - ' + option.label}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} label="Sector" InputProps={{
              ...params.InputProps,
              type: 'search',
            }} />}
          />)}

          {useGetDisponibles && (<Autocomplete
            {...(disabled ? { disabled } : {})}
            size="small"
            multiple // Permite selección múltiple
            value={Array.isArray(sectorId) ? sectorId : []}
            onChange={handleChangeSectores}
            inputValue={inputValueSector}
            onInputChange={(event, newInputValue) => {
              setInputValueSector(newInputValue);
            }}
            id="controllable-states-demo"
            options={sectoresList}
            style={estilosSelect}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.id + ' - ' + option.label}
              </Box>
            )}
            disableClearable={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sector"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />

            )}
          />)}
        </Grid>
      </Grid>
    </div>
  );
};

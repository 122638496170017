import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import { CajasContext } from "../../../context/CajasContext/CajasContext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export const FormMovimientos = (props = null) => {
  const { dataSearchMovimientos, setDataSearchMovimientos, setGrupoBusquedaMovimiento } =
    useContext(CajasContext);
  const [valorDesde, setValorDesde] = React.useState(
    dataSearchMovimientos.fecha_desde || ""
  );
  const [valorHasta, setValorHasta] = React.useState(
    dataSearchMovimientos.fecha_hasta || ""
  );
  const [tipoMovimientosSeleccionado, setTipoMovimientosSeleccionado] =
    useState("");


  const [detalle, setDetalle] = useState(dataSearchMovimientos.detalle || "");

  const [
    movimientosContenidosSeleccionado,
    setMovimientosContenidosSeleccionado,
  ] = useState("");

  const [checkCajas, setCheckCajas] = useState(
    dataSearchMovimientos.cajas === "1" ? true : false || false
  );
  const [checkContenidos, setCheckContenidos] = useState(
    dataSearchMovimientos.contenidos === "1" ? true : false || false
  );
  const [checkDigitalizaciones, setCheckDigitalizaciones] = useState(
    dataSearchMovimientos.digitalizaciones === "1" ? true : false || false
  );

  // eslint-disable-next-line no-unused-vars
  const [tiposMovimientosList, setTiposMovimientosList] = useState(
    props.tiposMovimientosList
  );
  // eslint-disable-next-line no-unused-vars
  const [movimientosContenidosList, setMovimientosContenidosList] = useState(
    props.movimientosContenidosList
  );

  const handleChange = (newValue, datePicker) => {
    if (datePicker === "valorDesde") {
      var dateValueDesde = moment(newValue?.$d).format("YYYY-MM-DD");
      setValorDesde(dateValueDesde);
      setGrupoBusquedaMovimiento(true)
    } else {
      var dateValueHasta = moment(newValue?.$d).format("YYYY-MM-DD");
      setValorHasta(dateValueHasta);
      setGrupoBusquedaMovimiento(true)
    }
  };

  const limpiar = (e) => {
    setDataSearchMovimientos({});
    setValorDesde("");
    setValorHasta("");
    setGrupoBusquedaMovimiento(false)
  };

  useEffect(() => {
    let data = {};
    setDataSearchMovimientos({});
    if (valorDesde !== "") {
      data.fecha_desde = valorDesde;
    }
    if (valorHasta !== "") {
      data.fecha_hasta = valorHasta;
    }
    if (movimientosContenidosSeleccionado !== "") {
      data.tipo_movimiento_contenidos = movimientosContenidosSeleccionado;
    }

    if (tipoMovimientosSeleccionado !== "") {
      data.tipo_movimiento_cajas = tipoMovimientosSeleccionado;
    }

    if (detalle !== "") {
      data.detalle = detalle;
    }
    checkCajas ? (data.cajas = "1") : (data.cajas = "0");
    checkContenidos ? (data.contenidos = "1") : (data.contenidos = "0");
    checkDigitalizaciones
      ? (data.digitalizaciones = "1")
      : (data.digitalizaciones = "0");

    setDataSearchMovimientos({ ...data });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valorDesde,
    valorHasta,
    checkCajas,
    checkContenidos,
    checkDigitalizaciones,
    tipoMovimientosSeleccionado,
    movimientosContenidosSeleccionado, detalle
  ]);

  const handleChangeCajas = (event) => {
    setCheckCajas(event.target.checked);
    if (event.target.checked) {
      setGrupoBusquedaMovimiento(true);
    }
  };

  const handleChangeDetalle = (event) => {
    setDetalle(event.target.value)
    console.log(event.target.value, 'asd')
    setGrupoBusquedaMovimiento(true);
    // setCheckCajas(event.target.checked);
  };


  const handleChangeContenidos = (event) => {
    setCheckContenidos(event.target.checked);
    if (event.target.checked) {
      setGrupoBusquedaMovimiento(true);
    }
  };

  const handleChangeDigitalizaciones = (event) => {
    setCheckDigitalizaciones(event.target.checked);
    if (event.target.checked) {
      setGrupoBusquedaMovimiento(true);
    }
  };

  return (
    <div>
      <Grid container justifyContent="end" style={{ marginBottom: '3px' }}>

        <Button
          variant="contained"
          onClick={limpiar}
          color="secondary"

          sx={{
            padding: '5px',
            minWidth: 'auto',
          }}
        >
          <ReplayIcon style={{
            padding: '0px',      // Ajusta el relleno (padding) para hacer el botón más pequeño
            position: 'center',

          }} />
        </Button>

      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <>
          <DatePicker
            name="valorDesde"
            label="Fecha desde"
            inputFormat="DD/MM/YYYY"
            value={valorDesde === "" ? null : valorDesde}
            variant="outlined"
            onChange={(date) => handleChange(date, "valorDesde")}
            renderInput={(params) => (
              <TextField
                name="valor"
                size="small"
                sx={{ m: 1, minWidth: 380 }}
                {...params}
              />
            )}
          />
          <FormControl
            sx={{ m: 1, minWidth: 380, background: "#E9E9E9" }}
            size="small"
          >
            <InputLabel id="selectTipoMovimiento">
              Tipo de Movimientos
            </InputLabel>
            <Select
              labelId="selectTipoMovimiento"
              value={tipoMovimientosSeleccionado}
              name="tipoMovimiento"
              label="Tipos de Movimientos"
              onChange={(event) => {
                setTipoMovimientosSeleccionado(event.target.value);
                setGrupoBusquedaMovimiento(true);
              }}
            >
              <MenuItem value="">Ninguno</MenuItem>
              {tiposMovimientosList &&
                tiposMovimientosList.map((tipoMovimiento, index) => {
                  return (
                    <MenuItem key={index} value={tipoMovimiento}>
                      {tipoMovimiento}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <DatePicker
            name="valorHasta"
            label="Fecha hasta"
            inputFormat="DD/MM/YYYY"
            value={valorHasta === "" ? null : valorHasta}
            variant="outlined"
            onChange={(date) => handleChange(date, "valorHasta")}
            renderInput={(params) => (
              <TextField
                name="valor"
                size="small"
                sx={{ m: 1, minWidth: 380 }}
                {...params}
              />
            )}
          />
          <FormControl
            sx={{ m: 1, minWidth: 380, background: "#E9E9E9" }}
            size="small"
          >
            <InputLabel id="selectTipoMovimientoContenido">
              Contenido Movimientos
            </InputLabel>
            <Select
              labelId="selectTipoMovimientoContenido"
              value={movimientosContenidosSeleccionado}
              name="tipoMovimientoContenido"
              label="Tipos de Movimientos Contenidos"
              onChange={(event) => {
                setMovimientosContenidosSeleccionado(event.target.value)
                setGrupoBusquedaMovimiento(true);
              }}
            >
              <MenuItem value="">Ninguno</MenuItem>
              {movimientosContenidosList &&
                movimientosContenidosList.map((tipoMovimiento, index) => {
                  return (
                    <MenuItem key={index} value={tipoMovimiento}>
                      {tipoMovimiento}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </>
      </LocalizationProvider>
      <Grid item xs={12}>
        <TextField id="outlined-basic2" label="Detalle" variant="outlined"
          fullWidth size="small"
          helperText="Indique el detalle que desea buscar."
          // error={!!errorReferencia}
          value={detalle}
          sx={{ m: 1, minWidth: 400 }}
          onChange={(e) => { handleChangeDetalle(e) }} />
      </Grid>
      <div style={{ textAlign: "center" }}>
        <FormControlLabel
          control={<Switch checked={checkCajas} onChange={handleChangeCajas} />}
          label="Cajas"
        />
        <FormControlLabel
          control={
            <Switch
              checked={checkContenidos}
              onChange={handleChangeContenidos}
            />
          }
          label="Contenidos"
        />
        <FormControlLabel
          control={
            <Switch
              checked={checkDigitalizaciones}
              onChange={handleChangeDigitalizaciones}
            />
          }
          label="Digitalizaciones"
        />
      </div>
    </div >
  );
};

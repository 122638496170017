export const uiSchemaCuentasSearchForm = {
    type: "VerticalLayout",
    elements: [
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/id",
          },
          {
            type: "Control",
            scope: "#/properties/razon_social",
          }]
        },
        {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/localidad",
          },
          {
            type: "Control",
            scope: "#/properties/cuit",
          },
        ],
      },
    ]
}
import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";

const InputNumber = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario


  const [cantidadDefault, setCantidadDefault] = useState(props.data || "");

  //funcion para devolver al formulario la data seleccionada y para setearla en el selector
  const handleChange = (event) => {
    setCantidadDefault(event.target.value);
    props.handleChange(props.path, event.target.value);
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <TextField
          id="outlined-number"
          label={props.label}
          type="number"
          defaultValue={cantidadDefault}
          onChange={handleChange}
          variant="outlined"
          inputProps={{ min: 0 }}
        />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const InputNumberTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "InputNumber";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const InputNumberComponent = {
  renderer: withJsonFormsControlProps(InputNumber),
  tester: InputNumberTester,
};

const InputString = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario

  const habilitar = props.enabled;

  const [dataDefaults, setDataDefaults] = useState(props.data || "");

  //funcion para devolver al formulario la data seleccionada y para setearla en el selector
  const handleChange = (event) => {
    setDataDefaults(event.target.value.toUpperCase());
    props.handleChange(props.path, event.target.value.toUpperCase());
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <TextField
          id="outlined-string"
          label={props.label}
          type="string"
          defaultValue={dataDefaults}
          onChange={handleChange}
          variant="outlined"
          {...(!habilitar ? { disabled: true } : {})}
        />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const InputStringTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "InputString";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const InputStringComponent = {
  renderer: withJsonFormsControlProps(InputString),
  tester: InputStringTester,
};

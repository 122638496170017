import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "src/context/AuthContext";
import { ComponentDialog } from "src/general_components/ComponentDialog";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { schemaFiltroCuentasServiciosPreciosForm } from "../../components/forms/schemaFiltroCuentasServiciosPreciosForm";
import { uiSchemaFiltroCuentasServiciosPreciosForm } from "../../components/forms/uiSchemaFiltroCuentasServiciosPreciosForm";
import { DateFormComponent } from "src/forms/customRenders/dateForm";
import { SelectorCuentasTipoComponent } from "../../components/forms/customRenders/selectorCuentasTipo";
import { SelectorModeloArticuloComponent } from "../../components/forms/customRenders/selectorModeloArticulo";

const localize = require("ajv-i18n");
const CuentasServiciosPrecioForm = (props) => {
  const auth = useContext(AuthContext);
  const { data, createButton, setCreateButton, postData, setOptions, options } =
    props;
  const name = "Cliente - Devolucion";

  //Definición de estados
  const [snackbarVisible, setSnackbarVisible] = useState(false); //Mensaje emergencia
  const [message, setMessage] = useState(""); //Representa el mensaje
  const [severity, setSeverity] = useState("success"); //Indica nivel de error

  // Almacena la información relacionada con la Cuenta Servicio Precio
  const [dataInfo, setDataInfo] = useState({
    id: data.id || "",
    activo: data.activo === "1" ? true : false,
    cantidad: Number(data.cantidad) || 0,
    importe: Number(data.importe) || 0,
    activoFechaInicio: data?.tiene_fecha_inicio === "1" ? true : false,
    fechaActivoInicio:
      typeof data?.fecha_inicio === "object" ? "" : data?.fecha_inicio,
    activoFechaFin: data?.tiene_fecha_finalizacion === "1" ? true : false,
    fechaActivoFin:
      typeof data?.fecha_finalizacion === "object"
        ? ""
        : data?.fecha_finalizacion,
    descripcion: typeof data?.descripcion === "object" ? "" : data?.descripcion,
    selectedTipoCuenta: data.id_tipo_cuentas || "",
    selectedModeloArticulo: data.id_articulos || "",
  });

  //PARA QUE SE ACTUALICE EL SELECTOR CUANDO SE EDITA
  if (data.id && dataInfo.id === "") {
    setDataInfo({
      id: data.id || "",
      activo: data.activo === "1" ? true : false,
      cantidad: Number(data.cantidad) || 0,
      importe: Number(data.importe) || 0,
      activoFechaInicio: data?.tiene_fecha_inicio === "1" ? true : false,
      fechaActivoInicio:
        typeof data?.fecha_inicio === "object" ? "" : data?.fecha_inicio,
      activoFechaFin: data?.tiene_fecha_finalizacion === "1" ? true : false,
      fechaActivoFin:
        typeof data?.fecha_finalizacion === "object"
          ? ""
          : data?.fecha_finalizacion,
      descripcion:
        typeof data?.descripcion === "object" ? "" : data?.descripcion,
      selectedTipoCuenta: data.id_tipo_cuentas || "",
      selectedModeloArticulo: data.id_articulos || "",
      modificar: true,
    });
  }
  //Estados booleanos usados para diferentes lógicas
  const [withErrors, setWithErrors] = useState(false);
  const [pushData, setPushData] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [redirect, setRedirect] = useState(false);

  //Se encarga de reiniciar el estado dataInfo a sus valores iniciales cuando se desactiva el botón de creación
  useEffect(() => {
    if (!createButton) {
      setDataInfo({
        id: "",
        activo: true,
        cantidad: undefined,
        importe: undefined,
        activoFechaInicio: false,
        fechaActivoInicio: null,
        activoFechaFin: false,
        fechaActivoFin: null,
        descripcion: "",
        selectedTipoCuenta: "",
        selectedModeloArticulo: "",
        modificar: true,
      });
    }
  }, [createButton]);

  //Se encarga de enviar los datos del formulario
  useEffect(() => {
    //Se activa cuando cambia el estado pushData
    const submitPush = async (dataPost, auth) => {
      let responsePost = await postData(dataPost, auth);
      if (responsePost.status === "true") {
        setPushData(false);
        setOptions({ ...options, refresh: true });
        setDataInfo({
          id: "",
          activo: true,
          cantidad: undefined,
          importe: undefined,
          activoFechaInicio: false,
          fechaActivoInicio: null,
          activoFechaFin: false,
          fechaActivoFin: null,
          descripcion: "",
          selectedTipoCuenta: "",
          selectedModeloArticulo: "",
        });
        setRedirect(true);
        setCreateButton(false);
      } else {
        setSnackbarVisible(true);
        setMessage(responsePost.message);
        setSeverity("warning");
      }
    };
    //Se caran los datos de el formulario a dataPost
    if (pushData) {
      let dataPost = {
        id: dataInfo.id,
        id_tipo_cuentas: dataInfo.selectedTipoCuenta,
        id_articulos: dataInfo.selectedModeloArticulo,
        activo: dataInfo.activo ? "1" : "0",
        cantidad: dataInfo.cantidad.toString(),
        tiene_fecha_inicio: dataInfo.activoFechaInicio ? "1" : "0",
        fecha_inicio: dataInfo.fechaActivoInicio,
        tiene_fecha_finalizacion: dataInfo.activoFechaFin ? "1" : "0",
        fecha_finalizacion: dataInfo.fechaActivoFin,
        importe: dataInfo.importe.toString(),
        descripcion: dataInfo.descripcion,
      };
      //Realiza las validaciones correspondientes de campos requeridos para poder hacer el POST
      if (dataInfo.selectedTipoCuenta === "") {
        setPushData(false);
        setSnackbarVisible(true);
        setMessage("Es obligatorio seleccionar un Tipo de cuenta");
        setSeverity("warning");
      } else if (dataInfo.selectedModeloArticulo === "") {
        setPushData(false);
        setSnackbarVisible(true);
        setMessage("Es obligatorio seleccionar un Articulo-Servicio");
        setSeverity("warning");
      } else if (
        dataInfo.cantidad === 0 ||
        dataInfo.cantidad === undefined ||
        dataInfo.cantidad === null ||
        dataInfo.cantidad === ""
      ) {
        setPushData(false);
        setSnackbarVisible(true);
        setMessage("Es obligatorio ingresar cantidad");
        setSeverity("warning");
      } else if (
        dataInfo.importe === 0 ||
        dataInfo.importe === undefined ||
        dataInfo.importe === null ||
        dataInfo.importe === ""
      ) {
        setPushData(false);
        setSnackbarVisible(true);
        setMessage("Es obligatorio ingresar un importe");
        setSeverity("warning");
      } else {
        //Envía los datos para hacer el insert
        submitPush(dataPost, auth);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushData]);

  //Maneja los campos y error formulario con las restricciones del JsonFomrs, Actualiza DataInfo y WithErrors
  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);

    setDataInfo(data);

    return { errors, data };
  };

  return (
    <>
      <ComponentDialog
        listName={name}
        buttonClicked={createButton}
        setButtonClicked={setCreateButton}
        withErrors={withErrors} //Sirve para controlar el botón de crear y los errores del formulario
        setPushData={setPushData} //Sirve para enviar los datos al servidor
        child={
          <Grid
            style={{
              background: "white",
              padding: "30px",
              marginBottom: "50px",
            }}
          >
            <JsonForms
              schema={schemaFiltroCuentasServiciosPreciosForm}
              uischema={uiSchemaFiltroCuentasServiciosPreciosForm}
              renderers={[
                ...materialRenderers,
                SelectorCuentasTipoComponent,
                SelectorModeloArticuloComponent,
                DateFormComponent,
              ]}
              data={dataInfo}
              cells={materialCells}
              onChange={({ errors, data }) =>
                onChange && onChange(errors, data)
              }
            />
          </Grid>
        }
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
        hide={8000}
      />
    </>
  );
};

export default CuentasServiciosPrecioForm;

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export const SelectExcedidas = (props) => {

  const {
    setExcedidoSeleccionado,
    excedidoSeleccionado,
    setGrupoBusquedaCajas
  } = props;

  const estilosSelect = { fontSize: "1em", color: "#646464" };
  const ITEM_HEIGHT = 45;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };


  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
        <InputLabel id="labelTipoContenido">Excedidas</InputLabel>
        <Select
          sx={{ backgroundColor: "#FFFFFF" }}
          style={{ ...estilosSelect, marginBottom: "20px" }}
          MenuProps={MenuProps}
          labelId="labelExcedido"
          value={excedidoSeleccionado}
          id="selectExcedido"
          label="Excedidas"
          onChange={(e) => {
            // setTipoRetiroSeleccionado(e.target.value);
            setExcedidoSeleccionado(e.target.value);
            if(setGrupoBusquedaCajas){
              setGrupoBusquedaCajas(true)
            }
            
          }}
        >
          <MenuItem style={estilosSelect} value="2" selected>
            Todas
          </MenuItem>
          <MenuItem style={estilosSelect} value="1">
            Si
          </MenuItem>
          <MenuItem style={estilosSelect} value="0">
            No
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

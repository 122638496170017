import { esArray } from "src/general_components/functionEsArray";
import datos from "src/services/datos";

export const getComprobantesCodigoData = async () => {
  
  const objectComprobantesCodigo = new datos("comprobantes_codigos");
  objectComprobantesCodigo.addCondition("comprobantes_codigos","id",0,">");
  const response = await objectComprobantesCodigo.get();
  const comprobantesCodigo = response.wsResponse.comprobantes_codigos;
  const arrComprobantesCodigo = esArray(comprobantesCodigo);

  return arrComprobantesCodigo;
};


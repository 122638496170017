import React, { useState } from 'react';
import json2csv from 'json2csv';
import FileSaver from 'file-saver';
// import { Button } from "@mui/material";
import TransferList from './TransferList'; // Importa el componente TransferList desde tu ruta correcta
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Buttons } from './Buttons';
import { SnackbarMessenger } from './SnackbarMessenger';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import imagen from "src/assets/img/dlys.jpeg";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const flattenJSON = (data, fields, reportTitle, armadoPor) => {
  function ordenarPorTipoMetadato(array) {
    return array.sort((a, b) => {
      const orden = {
        'texto': 0,
        'fecha_desde': 1,
        'fecha_hasta': 2,
        'numero_desde': 3,
        'numero_hasta': 4
      };
      return orden[a.metadato_label_grilla] - orden[b.metadato_label_grilla];
    });
  }

  function ordenarPorLote(arr) {
    return arr.sort((a, b) => a.lote - b.lote);
  }

  const existingFieldContenido = fields.indexOf("Tipo de contenido");
  const existingFieldMetadato = fields.indexOf("Metadatos");
  const flattenData = [];

  const esArray = (value) => (Array.isArray(value) ? value : [value]);

  var flattenTitle = {
    Titulo: reportTitle,
    Armado_por: armadoPor,

  };
  flattenData.push(flattenTitle);

  esArray(data).forEach((cuenta) => {
    const cliente = cuenta.detalle;
    const sectores = cuenta.sectores.sector;
    esArray(sectores).forEach((sector) => {
      const cajas = sector.cajas.caja;
      const sectorDetalle = sector.detalle;

      esArray(cajas).forEach((caja) => {
        const { id, estado, ubicacion, contenidos, referencia, id_tipo_caja } = caja;

        let referenciaValidado = referencia;
        if (typeof referencia === 'object') {
          referenciaValidado = ""; // Asigna un valor vacío si es un objeto
        }
        const ubicacionValidado = ubicacion === "Sin ubicación"
          ? ubicacion
          : ubicacion.replace(/[^0-9]+/g, "-").replace(/^-+/, '').replace(/-+/g, '-');
        var flattenContenido = {
          'Cliente': cliente,
          'Sector': sectorDetalle,
          'ID caja': id,
          'Referencia/Nro Anterior': referenciaValidado,
          'Tipo de caja(id)': id_tipo_caja,
          'Ubicacion': ubicacionValidado,
          caja_estado: estado,
        };
        if (contenidos && existingFieldContenido !== -1) {
          const flattenContenidoOriginal = flattenContenido;
          ordenarPorLote(esArray(contenidos)).forEach((contenido) => {
            flattenContenido = {
              ...flattenContenidoOriginal,
              'ID contenido': contenido.id,
              'Lote': contenido.lote,
              contenido_estado: contenido.estado,
              'Tipo de contenido': contenido.detalle_tipo_contenidos,
              ...contenido,
            };

            if (contenido.contenidos_metadatos && existingFieldMetadato !== -1) {
              let metadatosAñadidos = [];
              const metadatosOrdenados = ordenarPorTipoMetadato(esArray(contenido.contenidos_metadatos))
              metadatosOrdenados.forEach((metadato) => {

                const { metadato_label_grilla, metadato_valor, metadato_label_form } = metadato;

                flattenContenido = {
                  ...flattenContenidoOriginal,
                  'ID contenido': contenido.id,
                  'Lote': contenido.lote,
                  contenido_estado: contenido.estado,
                  'Tipo de contenido': contenido.detalle_tipo_contenidos,
                  ...contenido,
                };

                const existingFieldIndex = fields.indexOf(metadato_label_grilla);
                if (existingFieldIndex !== -1) {
                  flattenContenido[metadato_label_grilla] = metadato_valor;
                } else {
                  fields.push(metadato_label_grilla);
                  flattenContenido[metadato.metadato_label_grilla] = metadato.metadato_valor;
                }
                flattenContenido['Metadatos'] = metadato_label_form;
                metadatosAñadidos.push(metadato_label_grilla);
                flattenData.push(flattenContenido);
              })


            } else {
              flattenData.push(flattenContenido);
            }
          })
        } else {
          flattenData.push(flattenContenido);
        };

      });
    });
  });
  return flattenData;
};

const obtenerFechaConFormato = () => {
  const fechaActual = new Date();
  const dia = String(fechaActual.getDate()).padStart(2, '0');
  const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');
  const año = fechaActual.getFullYear();

  return `${dia}/${mes}/${año}`;
};

const getDynamicColumns = (data) => {

  const uniqueColumns = new Set();

  const esArray = (value) => (Array.isArray(value) ? value : [value]);

  esArray(data).forEach((item) => {
    esArray(item.sectores.sector).forEach((sector) => {
      const { cajas } = sector;

      esArray(cajas.caja).forEach((caja) => {
        if (caja.contenidos) {
          esArray(caja.contenidos).forEach((contenido) => {
            if (contenido.contenidos_metadatos) {
              esArray(contenido.contenidos_metadatos).forEach((metadato) => {
                if (metadato?.metadato_label_grilla) {
                  uniqueColumns.add(metadato.metadato_label_grilla);
                }

              });
            }

          });
        }

      });
    });
  });

  return Array.from(uniqueColumns);
};

const ButtonsExportJsonCSV = (props) => {
  const { totalPages, limit, idElementData, data } = props.props;
  let usuarioValues = JSON.parse(
    localStorage.getItem("siam-user-data")
  )
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [fileType, setFileType] = useState('CSV');
  const [reportTitle, setReportTitle] = useState('');
  const [armadoPor, setArmadoPor] = useState(usuarioValues.username || '');
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [mergeCells, setMergeCells] = useState(true);

  const mergeObjects = (obj1, obj2) => {
    // Verificar si alguno de los objetos es un objeto
    if (typeof obj1 === 'object' && typeof obj2 === 'object') {
      // Verificar si alguno de los objetos es un array
      if (Array.isArray(obj1) && Array.isArray(obj2)) {
        // Fusionar los arrays en caso de que se repitan
        return obj1.concat(obj2);
      }

      // Crear un nuevo objeto fusionado
      const mergedObj = { ...obj1 };

      // Recorrer las claves del segundo objeto
      for (let key in obj2) {
        if (obj2.hasOwnProperty(key)) {
          // Verificar si la clave existe en el primer objeto
          if (obj1.hasOwnProperty(key)) {
            // Fusionar los datos si la clave se repite
            mergedObj[key] = mergeObjects(obj1[key], obj2[key]);
          } else {
            // Agregar la clave y valor al nuevo objeto fusionado
            mergedObj[key] = obj2[key];
          }
        }
      }

      return mergedObj;
    }

    // Si no son objetos, devolver el segundo valor
    return obj2;
  };

  const obtenerJsonReporte = async () => {
    // Lógica para obtener y establecer el objeto JSON en el estado jsonData

    let mergedObj = {}
    let response
    for (var i = 0; i < totalPages; i++) {
      let offsetReporte = limit * i;
      if (idElementData !== undefined) {
        const isRequiredContenido = selectedColumns.includes("Tipo de contenido");
        let dataBusqueda;
        if (isRequiredContenido) {
          dataBusqueda = { ...idElementData, soloCajas: "1", devuelveContenidos: "1", exportarCajasContenidos: "1" }
        } else {
          dataBusqueda = { ...idElementData, soloCajas: "1" }
        }


        response = await data?.options?.getPaginated(
          limit,
          offsetReporte,
          dataBusqueda
        ); // se trae los records mediante un id de algun tipo de elemento que sea necesario
      } else {
        response = await data?.options?.getPaginated(limit, offsetReporte); //probelma esta cuando el offfsett (limit*page supera la cantidad de registros)
      }

      if (response.response) {
        response = response.response;
      }
      if (response.status === 'true') {
        mergedObj = mergeObjects(mergedObj, response.reporte);
      }


    }
    return mergedObj;

  };

  function ordenarArray(originalArray, order) {
    // Crear un nuevo array vacío para almacenar los elementos ordenados
    const newArray = [];

    // Recorrer el orden deseado y agregar los elementos existentes al nuevo array
    for (const element of order) {
      const foundElement = originalArray.find(item => item === element);

      if (foundElement) {
        newArray.push(foundElement);
      }
    }

    // Recorrer el array original y agregar los elementos que no estén en el nuevo array
    for (const element of originalArray) {
      if (!newArray.includes(element)) {
        newArray.push(element);
      }
    }

    return newArray;
  }


  const handleClick = (jsonData) => {
    let data = jsonData

    const existingFieldMetadato = selectedColumns.indexOf("Metadatos");

    const ordenDeseado = ['ID caja', 'Cliente', 'Sector', 'Referencia/Nro Anterior', 'Tipo de caja(id)', 'Ubicacion', 'Tipo de contenido', 'ID contenido', 'Lote', 'Metadatos'];
    const arrayOrdenado = ordenarArray(selectedColumns, ordenDeseado);

    const columns = arrayOrdenado;
    const dynamicColumns = existingFieldMetadato !== -1 ? getDynamicColumns(data.cuentas.cuenta) : [];
    const ordenDeseadoDynamicColumns = ['texto', 'fecha_desde', 'fecha_hasta', 'numero_desde', 'numero_hasta']
    const dynamicColumnsOrdenados = existingFieldMetadato !== -1 ? ordenarArray(dynamicColumns, ordenDeseadoDynamicColumns) : [];
    // columnas completas
    const fields = [...columns, ...dynamicColumnsOrdenados];
    // datos
    const flattenData = flattenJSON(data.cuentas.cuenta, fields, reportTitle, armadoPor);

    // Convierte cada parte a CSV
    const csvDataFirstLine = json2csv.parse(flattenData, { fields: ['Titulo', 'Armado_por'] });
    const csvDataSecondLine = json2csv.parse(flattenData, { fields: fields });


    // Combina las dos líneas
    const csvData = `${csvDataFirstLine}\n\n${csvDataSecondLine}`;

    // const csvData = json2csv.parse(flattenData, { fields });
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

    const fileName = `export_${usuarioValues.username}_${obtenerFechaConFormato()}.csv`;
    FileSaver.saveAs(blob, fileName);
  };

  const exportToJsonFile = async () => {
    try {
      let data = await obtenerJsonReporte();
      // Convertir el objeto en formato JSON
      const jsonData = JSON.stringify({ cuentas: data });
      // Generar un nombre de archivo único basado en la fecha y hora actual
      const fileName = `export_${usuarioValues.username}_${obtenerFechaConFormato()}.json`;

      // Crear un Blob con el contenido JSON
      const blob = new Blob([jsonData], { type: 'application/json' });

      // Generar la URL del archivo Blob
      const url = URL.createObjectURL(blob);

      // Crear un enlace (<a>) para descargar el archivo
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      // Agregar un event listener para ejecutar props.toggleModal() después de la descarga
      link.addEventListener('click', () => {
        props.toggleModal();
        // Limpiar la URL del objeto Blob después de un tiempo para liberar recursos
        setTimeout(() => URL.revokeObjectURL(url), 100);
      });

      // Simular un clic en el enlace
      link.click();
    } catch (error) {
      // Manejar errores aquí
      console.error('Error al exportar a archivo JSON:', error);
    }
  };

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  const handleDownload = async () => {
    // Lógica para obtener y establecer el objeto JSON en el estado jsonData
    let jsonReporte = await obtenerJsonReporte();
    let jsonReporteFinal = { cuentas: jsonReporte }
    if (!isEmptyObject(jsonReporte)) {
      // Llama a la función de conversión y descarga
      handleClick(jsonReporteFinal)
    } else {
      setMessage("No se encontraron registros como para armar un reporte");
      setSeverity("warning");
      setSnackbarVisible(true);
    }
  };


  const handleDownloadExcel = async () => {
    // Lógica para obtener y establecer el objeto JSON en el estado jsonData
    let jsonReporte = await obtenerJsonReporte();
    let jsonReporteFinal = { cuentas: jsonReporte }
    if (!isEmptyObject(jsonReporte)) {
      // Llama a la función de conversión y descarga
      exportToExcel(jsonReporteFinal,mergeCells)
    } else {
      setMessage("No se encontraron registros como para armar un reporte");
      setSeverity("warning");
      setSnackbarVisible(true);
    }
  };

  const validateFields = () => {
    // Obtiene los valores actuales de los campos
    const titleValue = reportTitle.trim();
    const armadoPorValue = armadoPor.trim();

    // Verifica si los campos cumplen con los requisitos
    const isTitleValid = titleValue.length >= 3;
    const isArmadoPorValid = armadoPorValue.length >= 3;

    if (isTitleValid && isArmadoPorValid) {
      return false;
    } else {
      return true;
    }

  };

  // Función para convertir una imagen a base64
  const getBase64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };


  const exportToExcel = async (jsonData, shouldMerge = true) => {
    const data1 = [
      {
        "Titulo": reportTitle,
        "Armado_por": armadoPor
      }
    ];

    let data = jsonData;

    const existingFieldMetadato = selectedColumns.indexOf("Metadatos");

    const ordenDeseado = ['Cliente', 'Sector', 'ID caja', 'Referencia/Nro Anterior', 'Tipo de caja(id)', 'Ubicacion', 'Tipo de contenido', 'ID contenido', 'Lote', 'Metadatos'];
    const arrayOrdenado = ordenarArray(selectedColumns, ordenDeseado);

    const columns = arrayOrdenado;
    const dynamicColumns = existingFieldMetadato !== -1 ? getDynamicColumns(data.cuentas.cuenta) : [];
    const ordenDeseadoDynamicColumns = ['texto', 'fecha_desde', 'fecha_hasta', 'numero_desde', 'numero_hasta'];
    const dynamicColumnsOrdenados = existingFieldMetadato !== -1 ? ordenarArray(dynamicColumns, ordenDeseadoDynamicColumns) : [];

    // Columnas completas
    const fields = [...columns, ...dynamicColumnsOrdenados];

    // Datos
    const data2 = flattenJSON(data.cuentas.cuenta, fields, reportTitle, armadoPor);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    // Convertir la imagen a base64
    const base64Image = await getBase64FromUrl(imagen);
    const imageId = workbook.addImage({
      base64: base64Image,
      extension: 'jpeg', // Cambia esto si tu imagen no es JPEG
    });

    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 200, height: 100 } // Ajusta el tamaño según tus necesidades
    });

    // Crear la primera tabla
    const headers1 = ["Titulo", "Armado_por"];
    worksheet.addRow([]); // Espacio para la imagen
    worksheet.addRow([]); // Espacio para la imagen
    worksheet.addRow([]); // Espacio para la imagen
    worksheet.addRow([]); // Espacio para la imagen
    worksheet.addRow([]); // Espacio para la imagen
    worksheet.addRow(headers1);
    data1.forEach(item => {
      worksheet.addRow([
        item.Titulo,
        item.Armado_por
      ]);
    });

    // Aplicar estilo a los encabezados de la primera tabla
    const headerRow1 = worksheet.getRow(7); // Ajusta esto según la posición de tu imagen
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: 'FF4F4F4F' }, size: 10 }; // gris oscuro, negrita, tamaño 10
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } }; // gris claro
      cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });

    // Aplicar bordes y ajustar tamaño de fuente a las celdas de la primera tabla
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.font = { size: 10 }; // tamaño 10
      });
    });

    // Dejar una fila en blanco
    const blankRowNumber = worksheet.lastRow.number + 1;
    worksheet.addRow([]);

    // Crear la segunda tabla
    const headers2 = fields;
    worksheet.addRow(headers2);

    if (shouldMerge) {
      // Columnas a fusionar
      const columnsToMerge = ['Cliente', 'Sector', 'ID caja', 'Referencia/Nro Anterior', 'Tipo de caja(id)', 'Ubicacion', 'Tipo de contenido', 'Lote'];

      // Convertir nombres de columnas a letras
      const columnLetters = columnsToMerge.map(colName => {
        const colIndex = fields.indexOf(colName);
        return colIndex !== -1 ? String.fromCharCode(65 + colIndex) : null;
      }).filter(col => col !== null);

      // Agrupar datos por "ID caja" y "Lote" y unificar celdas
      let startRow = worksheet.lastRow.number + 1;
      let mergeStartRow = startRow;
      let previousIdCaja = null;
      let previousLote = null;

      let mergeEndRow;

      data2.forEach((item, index) => {
        const row = fields.map(field => item[field] || "");
        worksheet.addRow(row);

        const currentRow = startRow + index;
        const idCaja = item['ID caja'];
        const lote = item['Lote'];

        if (idCaja === previousIdCaja && lote === previousLote) {
          // Actualiza el final del rango de fusión
          mergeEndRow = currentRow;
        } else {
          // Si cambia el "ID caja" o "Lote", fusiona el rango previo si es más de una fila
          if (mergeEndRow > mergeStartRow) {
            columnLetters.forEach(col => {
              const mergeRange = `${col}${mergeStartRow}:${col}${mergeEndRow}`;
              worksheet.mergeCells(mergeRange);
              // Centrar el contenido de las celdas fusionadas
              worksheet.getCell(`${col}${mergeStartRow}`).alignment = { vertical: 'middle', horizontal: 'center' };
            });
          }
          // Inicia un nuevo rango de fusión
          mergeStartRow = currentRow;
          mergeEndRow = currentRow;
        }
        previousIdCaja = idCaja;
        previousLote = lote;
      });

      // Fusión final si el último grupo tenía filas repetidas
      if (mergeEndRow > mergeStartRow) {
        columnLetters.forEach(col => {
          const mergeRange = `${col}${mergeStartRow}:${col}${mergeEndRow}`;
          worksheet.mergeCells(mergeRange);
          // Centrar el contenido de las celdas fusionadas
          worksheet.getCell(`${col}${mergeStartRow}`).alignment = { vertical: 'middle', horizontal: 'center' };
        });
      }
    } else {
      // Añadir los datos sin fusionar
      data2.forEach((item) => {
        const row = fields.map(field => item[field] || "");
        worksheet.addRow(row);
      });
    }

    // Aplicar estilo a los encabezados de la segunda tabla
    const headerRow2 = worksheet.getRow(blankRowNumber + 1);
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: 'FF4F4F4F' }, size: 10 }; // gris oscuro, negrita, tamaño 10
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } }; // gris claro
      cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });

    // Aplicar bordes y ajustar tamaño de fuente a las celdas de la segunda tabla
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      if (rowNumber > blankRowNumber + 1) {
        row.eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.font = { size: 10 }; // tamaño 10
        });
      }
    });

    // Ajustar el ancho de las columnas
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        maxLength = Math.max(maxLength, (cell.value || '').toString().length);
      });
      column.width = maxLength < 10 ? 10 : maxLength; // Ajusta el ancho mínimo a 10 caracteres
    });

    // Generar el archivo Excel y guardarlo
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, `${reportTitle}.xlsx`);
  };






  return (
    <div style={{ textAlign: "center" }}>
      {/* Select para elegir el tipo de archivo */}
      <FormControl fullWidth size="small" style={{ marginBottom: 20 }}>
        <InputLabel id="id_tipo_archivo">Tipo de Archivo</InputLabel>
        <Select
          labelId="id_tipo_archivo"
          value={fileType}
          label="Tipo de Archivo"
          onChange={(e) => setFileType(e.target.value)}>
          <MenuItem value="CSV">CSV</MenuItem>
          <MenuItem value="JSON">JSON</MenuItem>
          <MenuItem value="Excel">Excel</MenuItem>
        </Select>
      </FormControl>

      {/* Campos adicionales para el tipo de archivo CSV */}
      {(fileType === 'CSV' || fileType === 'Excel') && (
        <>
          {/* Componente TransferList para seleccionar las columnas */}
          <TransferList
            availableColumns={['ID caja', 'Cliente', 'Sector', 'Referencia/Nro Anterior', 'Tipo de caja(id)', 'Ubicacion', 'Lote', 'Tipo de contenido', 'Metadatos']}
            selectedColumns={selectedColumns}
            onChange={(columns) => setSelectedColumns(columns)}
          />
          <TextField size="small" style={{ marginTop: 10 }} label="Título del Reporte" fullWidth value={reportTitle} onChange={(e) => setReportTitle(e.target.value)} required />
          <TextField size="small" style={{ marginTop: 10 }} label="Armado Por" fullWidth value={armadoPor} onChange={(e) => setArmadoPor(e.target.value)} required />
        </>
      )}

      {(fileType === 'Excel') && (
        <div>
          <FormControlLabel
            sx={{ fontSize: 4, fontFamily: 'Roboto, sans-serif', color: '#0A395F', }}
            control={
              <Switch
                checked={mergeCells}
                onChange={() => setMergeCells(!mergeCells)}
                color="primary"
              />
            }
            label="Agrupar filas por lote"
          />
        </div>)}


      <Grid sx={{ mt: 1, marginTop: 5 }}>
        <Buttons
          disableButton={fileType === "CSV" ? validateFields() : false}
          onClickSubmit={fileType === "CSV" ? handleDownload : fileType === "Excel" ? handleDownloadExcel : exportToJsonFile}
          labelButtonSave={"Confirmar"}
          buttonCerrar={props.toggleModal}
        />
      </Grid>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </div>
  );
};

export default ButtonsExportJsonCSV;



// function eliminarPalabrasDesdeHasta(inputWord) {
//   // Convertir la palabra a minúsculas para una comparación insensible a mayúsculas y minúsculas
//   const lowerCaseWord = inputWord.toLowerCase();

//   // Verificar si la palabra contiene "desde" o "hasta" y eliminarla si es así
//   if (lowerCaseWord.includes('desde') || lowerCaseWord.includes('hasta') || lowerCaseWord.includes('Desde') || lowerCaseWord.includes('Hasta')) {
//     const palabras = inputWord.split(' ');
//     return palabras[0]; // Devuelve solo la primera palabra
//   } else {
//     return inputWord; // Devuelve la palabra original si no contiene "desde" ni "hasta"
//   }
// }

// function verificarMetadatoDesdeHasta(metadato) {
//   // Convertir la palabra a minúsculas para una comparación insensible a mayúsculas y minúsculas
//   const lowerCaseWord = metadato.toLowerCase();

//   // Verificar si la palabra contiene "desde" o "hasta" y eliminarla si es así
//   if (lowerCaseWord.includes('desde') || lowerCaseWord.includes('hasta') || lowerCaseWord.includes('Desde') || lowerCaseWord.includes('Hasta')) {
//     return true;
//   } else {
//     return false;
//   }
// }
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import React, { useState } from "react";

export const SwitchAjuste = (props) => {
  const [ajuste, setAjuste] = useState(false);

  const handleChange = (event) => {
    setAjuste(event.target.checked);
    props.setAjuste(event.target.checked)
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            {...(props.disabledCheckAjuste ? { disabled: true } : {})}
            checked={ajuste}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Ajuste"
      />
    </FormGroup>
  );
};

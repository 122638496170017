import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  esES,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES as pickersEsES } from "@mui/x-date-pickers";
import { esES as coreEsES } from "@mui/material/locale";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from "@mui/material/Button";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Sin Filas</Box>
    </StyledGridOverlay>
  );
}

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  esES, // x-data-grid translations
  pickersEsES, // x-date-pickers translations
  coreEsES // core translations
);

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <a
        type="button"
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify({ 'prueba': 'algo' })
        )}`}
        download={"reporte_cajas.JSON"}
      >
        <Button
          startIcon={<FileDownloadIcon />}
        >
          Exportar
        </Button>
      </a>
    </GridToolbarContainer>
  );
}

function DetailPanelContent({ row: rowProp }) {
  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Grid
            style={{
              borderBottomStyle: "dashed",
              textAlign: "left",
              display: "inline-flex",
            }}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ fontWeight: "bold", paddingRight: "1%" }}
            >
              Deposito:
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
              {rowProp.deposito}
            </Typography>
          </Grid>

          {
            <DataGridPro
              sx={{
                // FORMATO DE LAS CABECERAS // TODO: CSS
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#3c4b64",
                  color: "#FFF",
                  fontSize: 14,
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                },
              }}
              density="compact"
              columns={[
                {
                  field: "contenidoConcat",
                  headerName: "Contenidos",
                  flex: "1",
                  width: "100%",
                  disableColumnMenu: true,

                  headerAlign: "center",
                },
              ]}
              rows={rowProp.contenidos}
              hideFooter
            />
          }
        </Stack>
      </Paper>
    </Stack>
  );
}

DetailPanelContent.propTypes = {
  row: PropTypes.object.isRequired,
};

export const ListadoInformes = (props) => {
  const { cols, getPaginated, idElementData, sinFilas } = props.options;
  const { setMessage, setSeverity, setSnackbarVisible } =
    React.useContext(CajasContext);
  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    const getData = async () => {
      if (!sinFilas) {
        if (idElementData !== undefined) {
          setRecords(await getPaginated(10, 0, idElementData));
        }
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idElementData, sinFilas]);

  React.useEffect(() => {
    if (records.total_records > 0) {
      setMessage("Se han encontrado " + records.total_records + " registros");
      setSeverity("success");
      setSnackbarVisible(true);
    } else if (records.total_records === 0) {
      setMessage("No se han encontrado registros");
      setSeverity("error");
      setSnackbarVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 400, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%", height: 600 }}>
        <DataGridPro
          sx={{
            // FORMATO DE LAS CABECERAS // TODO: CSS
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#3c4b64",
              color: "#FFF",
              fontSize: 16,
            },
          }}
          style={{ height: "100%" }}
          columns={cols}
          rows={records.data || {}}
          rowThreshold={0}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
    </ThemeProvider>
  );
};

import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { useContext, useState } from "react";
import { AuthContext } from "src/context/AuthContext";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { schemaMiPerfilUsuarioForm } from "src/forms/schemaMiPerfilUsuarioForm";
import { uiSchemaMiPerfilUsuarioForm } from "src/forms/uiSchemaMiPerfilUsuarioForm";
import {
  postUserMiPerfil,
} from "../general_helpers/UsersData";
import { Buttons } from "./Buttons";
import { SimpleDialogComponent } from "./SimpleDialogComponent";
import { PasswordInput } from "./PasswordInput";

const localize = require("ajv-i18n");

export const MiPerfilForm = (props) => {
  const auth = useContext(AuthContext);
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [withErrors, setWithErrors] = useState([]);
  const [modalState, setmodalState] = useState(false);


  //busca todos los perfiles disponibles para el usuario que se encuantra logeado
  let usuarioLogeado = JSON.parse(
    localStorage.getItem("siam-user-data")
  );

  // eslint-disable-next-line no-unused-vars
  const [userNameOrigi, setUserNameOrigi] = useState(usuarioLogeado?.username);
  // eslint-disable-next-line no-unused-vars
  const [userMailOrigi, setUserMailOrigi] = useState(usuarioLogeado?.email);

  const [dataForm, setDataForm] = useState({
    id: usuarioLogeado?.id || "",
    name: usuarioLogeado?.nombre || "",
    surname: usuarioLogeado?.apellido || "",
    username: usuarioLogeado?.username || "",
    password: "",
    passwordCoincidencia: "",
    email: usuarioLogeado?.email || "",
    id_perfiles: usuarioLogeado?.id_perfiles || "",
  });

  const handleSubmit = async (e) => {
    let encodedPassword = "";
    if (dataForm.password !== "" && dataForm.password !== undefined) {
      encodedPassword = btoa(dataForm.password);
    }
    const user = {
      id: dataForm.id,
      nombre: dataForm.name,
      apellido: dataForm.surname,
      username: dataForm.username,
      password: encodedPassword,
      email: dataForm.email,
      userNameOrigi: userNameOrigi,
      userMailOrig: userMailOrigi,
      id_perfiles: dataForm.id_perfiles,
    };
    const response = await postUserMiPerfil(user, auth)
    if (response.status === 'true') {
      let newDatosUser = {
        ...usuarioLogeado,
        nombre: dataForm.name,
        apellido: dataForm.surname,
        username: dataForm.username,
        email: dataForm.email,
      };
      localStorage.setItem("siam-user-data", JSON.stringify(newDatosUser));
      if (dataForm.password && dataForm.password !== "") {
        localStorage.setItem(
          "passUser", dataForm.password
        );
      }
      props.setSnackbarVisible(true);
      props.setMessage("Datos del usuario modificados con exito!!!")
      props.setSeverity("success")
      props.setCreateButton(false);
      // setRedirect(true);
    } else {
      setSnackbarVisible(true);
      setMessage('Hubo un error al intentar modificar los datos')
      setSeverity("warning")
    }
  };


  const onChange = (errors, data) => {

    localize.es(errors);
    //localize error se utiliza para pasar en español los errores del jsonform
    const contieneEspacios = /\s/.test(data.password);

    if (contieneEspacios) {
      errors.forEach((error) => {
        var keyword = error.keyword;

        if (keyword === 'pattern') {
          error.message = 'NO puede contener espacios vacios.'
        }

      });
    }
    // Compara las contraseñas y agrega un error si no coinciden
    if (data.password !== data.passwordCoincidencia) {
      errors.push(
        {
          "instancePath": "/passwordCoincidencia",
          "schemaPath": "#/properties/passwordCoincidencia/pattern",
          "keyword": "pattern",
          "params": {
            "pattern": "^[^\\s]+$"
          },
          "message": "Las contraseñas no coinciden.",
          "schema": "^[^\\s]+$",
          "parentSchema": {
            "type": "string",
            "title": "Confirme la Contraseña",
            "minLength": 6,
            "pattern": "^[^\\s]+$",
            "description": "La contraseña debe contener al menos un caracter y no puede tener espacios en blanco.",
            "maxLength": 72
          },
          "data": data.passwordCoincidencia
        }
      );
    } else if (data.password === "" && (data.password === data.passwordCoincidencia)) {
      const nuevoArrayErrors = errors.filter(objeto => objeto.instancePath === "password" && objeto.instancePath === "passwordCoincidencia");
      setWithErrors(nuevoArrayErrors);
      setDataForm(data);

      return { nuevoArrayErrors, data };
    }

    setWithErrors(errors);
    setDataForm(data);

    return { errors, data };
  };

  const toggleModal = () => {
    setmodalState(!modalState)
  };

  const dialogChild = (
    <PasswordInput
      handleSubmit={handleSubmit}
      dataForm={dataForm}
      toggleModal={toggleModal}
    />)

  return (
    <div>
      <JsonForms
        onChange={({ errors, data }) => onChange && onChange(errors, data)}
        schema={schemaMiPerfilUsuarioForm}
        uischema={uiSchemaMiPerfilUsuarioForm}
        renderers={[
          ...materialRenderers,
        ]}
        data={dataForm}
        cells={materialCells}

      />
      <div style={{ marginTop: 20 }}>
        <Buttons
          style={{ marginTop: 10 }}
          onClickSubmit={toggleModal}
          disableButton={withErrors && withErrors.length > 0 ? true : false}

          buttonCerrar={props.setCreateButton} />
      </div>

      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={8000}
        close={() => { setSnackbarVisible(false) }} />

      <SimpleDialogComponent
        open={modalState}
        modalName={"Confirmar"}
        child={dialogChild}
        handleClose={toggleModal}
        buttonEnviar={false}
        buttonCancelar={false}
        maxWidth="sm"
      />
    </div>
  );
};

import { useState ,useEffect} from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import { Grid } from "@mui/material";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { getRemitosByIdPaginated, getRemitosByIdCuenta } from "../helpers/RemitosData";
import Tooltip from "@mui/material/Tooltip";
import { GridActionsCellItem } from "@mui/x-data-grid";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ComprobantePrint from "./ComprobantePrint";
import { comprobantesPrintData } from "../helpers/ComprobantesData";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponentRC";
import { Buttons } from "src/general_components/Buttons";

export const RemitosList = ({ id_cuentas,handleClose,setValue }) => {
  const [childModal, setChildModal] = useState("");
  const [labelModal, setLabelModal] = useState("Items");
  const [modalState, setModalState] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null); // Estado para almacenar el ID del registro seleccionado
  const [primerRender, setPrimerRender] = useState(true); // Estado para almacenar el ID del registro seleccionado

  const obtenerComprobanteAimprimir = async (idComprobante) => {
    let paramsPrint = {
      id_comprobantes: idComprobante
    }
    let response = await comprobantesPrintData(paramsPrint)
    return response;
  };

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    {
      field: "fecha_comprobante",
      headerName: "Fecha",
      flex: 1,
    },
    { field: "numero_comprobante", headerName: "Numero Comprobante", flex: 1 },
    {
      field: "observaciones_accesorias",
      headerName: "Observaciones",
      flex: 1,
      renderCell: (params) => {
        const observaciones = params.value;
        // Verificar si observaciones es un objeto
        if (observaciones && typeof observaciones === 'object') {
          return "Sin observaciones";
        } else {
          return observaciones;
        }
      }
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      width: 200, // Ajusta el ancho de acuerdo a tus necesidades
      minWidth: 150,
      getActions: (params) => [
        // Botón para imprimir el comprobante
        <GridActionsCellItem
          icon={
            <Tooltip title="Imprimir">
              <span>
                <LocalPrintshopIcon color="info" />
              </span>
            </Tooltip>
          }
          onClick={async () => {
            let contenidoChild = await obtenerComprobanteAimprimir(params.id);
            setChildModal(<ComprobantePrint contenidoChild={contenidoChild} />)
            setLabelModal("Impresión de Comprobante")
            setModalState(true);
          }}
          label="Imprimir"
        />,
      ],
    },
  ];

  console.log("selectedRowId",selectedRowId);

  const [options, setOptions] = useState({
    cols: columns,
    get: getRemitosByIdCuenta,
    getPaginated: getRemitosByIdPaginated,
    pageSize: [5, 10, 25],
    edit: true,
    limit: 100,
    filter: true,
    exportData: true,
    server: false,
    idElementData: id_cuentas,
    selectCheckbox:  true,
    valuesSelected: setSelectedRowId,
    oneSelect: true,
    refreshCheckBoxSelectCustom: false,
    useSelectionSinContext: true
  });

  useEffect(() => {
    if(primerRender){
      setOptions({...options,refreshCheckBoxSelectCustom: true})
      setPrimerRender(false);
    }
  }, [options, primerRender]); // La dependencia vacía [] significa que este efecto se ejecutará solo una vez, cuando el componente se monte por primera vez


  return (
    <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
      <CajasProvider>
        <>
          <ListTablePaginated options={options} />
          <SimpleDialogComponent
            open={modalState}
            modalName={labelModal}
            child={childModal}
            handleClose={toggleModal}
            buttonEnviar={false}
            buttonCancelar={true}
            maxWidth={'xl'}
          />
        </>
      </CajasProvider>
      <Buttons
              onClickSubmit={() => {
                if (selectedRowId && selectedRowId.length > 0) {
                  setValue(selectedRowId[0]);
                } else {
                  setValue(null); // o cualquier otro valor predeterminado que desees establecer
                }
                handleClose();
              }}
              labelButtonSave={"Guardar"}
              buttonCerrar={handleClose}
            />
    </Grid>
  );
};


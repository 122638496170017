import React from 'react';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

// Estilos personalizados para las celdas
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '5px',
    fontSize: '0.75rem',
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#44787D",
        color: theme.palette.common.white,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    width: '50%', // Ajusta el ancho de las celdas aquí
}));

const TablaCotizacionesComprobantesForm = ({ tipoCambioTable}) => {
    // Estado para almacenar los datos de la tabla
    // const data = [
    //     { cotizacion: '21/12/2023 - Dolar Estadounidense - 0.20', importe: "U$D 10.952,64"},
    //     { cotizacion: '01/01/2023 - Peso Argentino - 167.82', importe: "AR$ 9.105.055,78" },
    // ];

    const estilosFilas = {fontSize: '0.85rem', fontFamily: 'Calibri, sans-serif'}


    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Cotizaciones</StyledTableCell>
                        <StyledTableCell>Totales</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tipoCambioTable.map((row, index) => (
                        <TableRow key={index}>
                            <StyledTableCell style={estilosFilas}>{row.cotizacion}</StyledTableCell>
                            <StyledTableCell style={estilosFilas}>{row.importe}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TablaCotizacionesComprobantesForm;
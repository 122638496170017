import * as React from 'react';
import { useEffect, useContext, useState } from "react";
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGridPro,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import Zoom from "react-medium-image-zoom";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { Tooltip } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import ImageIcon from '@mui/icons-material/Image';
import { SimpleDialogComponent } from "../../../../general_components/SimpleDialogComponent";
import { Grid } from "@mui/material";
import { Autocomplete, TextField } from '@mui/material';

export const FullFeaturedCrudGrid = (props) => {
  const {
    tipoContenidoList,
    setUpdateImageSave,
    updateImageSave,
    setAlertDelete,
    setIndex
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [guardado, setGuardado] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  // eslint-disable-next-line no-unused-vars
  const [initialLoadingState, setInitialLoadingState] = React.useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(initialLoadingState);
  const [funcionChangeCheckbox, setFuncionChangeCheckbox] = React.useState(false);

  const [desplegarImagen, setDesplegarImagen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const {
    rows, setRows, checkedTotal, digitalizacionesSeleccionadas, digitalizacionesSeleccionadasCompleto, setDigitalizacionesSeleccionadas,
    checkedTotalCaratula, setDigitalizacionesSeleccionadasCompleto, setCheckedTotal, checkedCaratula, setActualizacionIndividualCheckbox
  } = useContext(CajasContext);



  const handleImageClick = (imageUrl) => {
    setDesplegarImagen(true);
    setSelectedImage(imageUrl);
  };


  const handleCloseImage = () => {
    setDesplegarImagen(false);

  };


  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setGuardado(true);
  };

  const handleDeleteClick = (id) => () => {
    setAlertDelete(true);
    let index = rows.findIndex(function (row) {
      // eslint-disable-next-line eqeqeq
      return row.id == id; // or el.nombre=='T NORTE';
    });
    setIndex(index);

  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    if (newRow.save) {
      setUpdateImageSave({
        ...updateImageSave,
        [newRow.id_digitalizaciones]: {
          ...updateImageSave[
          newRow.id_digitalizaciones
          ],
          id_tipo_contenidos: newRow.id_tipo_contenidos.toString(),
          valor: newRow.descripcion,
          id: newRow.id_digitalizaciones,
        },
      });
    }
    return updatedRow;
  };

  function updateRowPosition(initialIndex, newIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        row.folio = newIndex;
        rowsClone.splice(newIndex, 0, row);
        for (var i = 0; i < rowsClone.length; i++) {
          // eslint-disable-next-line no-loop-func
          let index = rowsClone.findIndex(function (row) {
            // eslint-disable-next-line eqeqeq
            return row.id == rowsClone[i].id; // or el.nombre=='T NORTE';
          });
          rowsClone[i].folio = index;
          rowsClone[i].model_data.folio = index;
        }
        resolve(rowsClone);
      }, Math.random() * 500 + 100); // simulate network latency
    });
  }

  React.useEffect(() => {
    setLoading(!initialLoadingState);
  }, [initialLoadingState]);

  const handleRowOrderChange = async (params) => {
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );
    let rowsOrderEdit = updateImageSave;
    for (var i = 0; i < newRows.length; i++) {
      if (newRows[i].save) {

        rowsOrderEdit = {
          ...rowsOrderEdit,
          [newRows[i].id_digitalizaciones]: {
            ...rowsOrderEdit[
            newRows[i].id_digitalizaciones
            ],
            folio: newRows[i].folio.toString(),
            id: newRows[i].id_digitalizaciones,
          },
        };

      }
    }
    setUpdateImageSave(rowsOrderEdit);
    setRows(newRows);
    setLoading(false);
  };

  // Funcion Checkbox
  const handleOnChangeCheckbox = (id, e, row) => {
    const newROws = rows.map((valorActual, indice, arr) => {
      let rowConvertido = valorActual.id === id ? { ...valorActual, checked: e.target.checked } : valorActual
      return rowConvertido;
    });
    let digitalizacionesContenidosSeleccionadasFiltradas = newROws.filter((item) => item.id_digitalizaciones && item.checked !== false);
    let digitalizacionesContenidosSeleccionadasTotal = digitalizacionesContenidosSeleccionadasFiltradas.map(function (obj) {
      return obj.id_digitalizaciones;
    });
    let digitalizacionContPrevio = [...digitalizacionesSeleccionadasCompleto.digitalizaciones_contenidos];
    if (!e.target.checked) {
      digitalizacionContPrevio = digitalizacionContPrevio.filter((item) => item !== row.id_digitalizaciones)
    }

    let digitalizacionContFinal = digitalizacionContPrevio.concat(digitalizacionesContenidosSeleccionadasTotal);
    let contenidoDigitalizacionesUnicos = Array.from(new Set(digitalizacionContFinal))
    setDigitalizacionesSeleccionadas({ ...digitalizacionesSeleccionadasCompleto, digitalizaciones_contenidos: contenidoDigitalizacionesUnicos })
    setDigitalizacionesSeleccionadasCompleto({ ...digitalizacionesSeleccionadasCompleto, digitalizaciones_contenidos: contenidoDigitalizacionesUnicos })
    setFuncionChangeCheckbox(true);

    if (rows.length > 0) {
      setActualizacionIndividualCheckbox(true);
      let cantRow = rows.length;
      let cantRowChecked = newROws.filter((item) => item.id_digitalizaciones && item.checked !== false).length;
      setCheckedTotal(cantRow === cantRowChecked && checkedCaratula ? true : false);
    }

    setRows(newROws)
  }


  useEffect(() => {
    setFuncionChangeCheckbox(true);
    const newROws = rows.map((valorActual, indice, arr) => {
      let rowConvertido = { ...valorActual, checked: checkedTotal }
      return rowConvertido;
    });
    let digitalizacionesContenidosSeleccionadasFiltradas = newROws.filter((item) => item.id_digitalizaciones && item.checked !== false);
    let digitalizacionesContenidosSeleccionadasTotal = digitalizacionesContenidosSeleccionadasFiltradas.map(function (obj) {
      return obj.id_digitalizaciones;
    });
    let digitalizacionContPrevio = [...digitalizacionesSeleccionadasCompleto.digitalizaciones_contenidos];
    if (digitalizacionesContenidosSeleccionadasFiltradas.length < 1) {
      for (var i = 0; i < rows.length; i++) {
        // eslint-disable-next-line no-loop-func
        digitalizacionContPrevio = digitalizacionContPrevio.filter((item) => item !== rows[i].id_digitalizaciones)
      }
    }
    let digitalizacionContFinal = digitalizacionContPrevio.concat(digitalizacionesContenidosSeleccionadasTotal);
    let contenidoDigitalizacionesUnicos = Array.from(new Set(digitalizacionContFinal))
    setDigitalizacionesSeleccionadas({ ...digitalizacionesSeleccionadas, contenidos: contenidoDigitalizacionesUnicos })
    // Convertimos el arreglo en string
    const str = JSON.stringify(digitalizacionesSeleccionadas);
    // Parseamos el string a un arreglo
    const digitalizacionesSel = JSON.parse(str);
    digitalizacionesSel['digitalizaciones_contenidos'] = contenidoDigitalizacionesUnicos;
    setDigitalizacionesSeleccionadas(digitalizacionesSel)
    setDigitalizacionesSeleccionadasCompleto(digitalizacionesSel)
    setRows(newROws)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTotalCaratula]);


  useEffect(() => {
    if (rows.length > 0 && !funcionChangeCheckbox) {
      const newROws = rows.map((valorActual, indice, arr) => {
        const idDigitalizacionEncontrado = digitalizacionesSeleccionadasCompleto.digitalizaciones_contenidos.find(element => element === valorActual.id_digitalizaciones);
        if (idDigitalizacionEncontrado) {
          let rowConvertido = { ...valorActual, checked: true }
          return rowConvertido;
        } else {
          let rowConvertido = { ...valorActual }
          return rowConvertido;
        }

      });
      // selectedDigitalizaciones(newROws)
      let digitalizacionesContenidosSeleccionadasFiltradas = newROws.filter((item) => item.id_digitalizaciones && item.checked !== false);
      let digitalizacionesContenidosSeleccionadasTotal = digitalizacionesContenidosSeleccionadasFiltradas.map(function (obj) {
        return obj.id_digitalizaciones;
      });
      //         // Convertimos el arreglo en string
      const str = JSON.stringify(digitalizacionesSeleccionadas);
      // // Parseamos el string a un arreglo
      const digitalizacionesSel = JSON.parse(str);
      digitalizacionesSel['digitalizaciones_contenidos'] = digitalizacionesContenidosSeleccionadasTotal;
      setDigitalizacionesSeleccionadas(digitalizacionesSel)
      // setRows(newROws)
    }
    setFuncionChangeCheckbox(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const columns = [
    {
      field: "id_digitalizaciones",
      headerName: "ID Digitalizacion",
      hide: true,
      sortable: false
    },
    {
      field: "image",
      headerName: "Imagen",
      width: 170,
      height: 750,
      sortable: false,
      editable: true,
      renderEditCell: (params) => (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          minHeight: 150 // Ajusta la altura mínima de la celda según sea necesario
        }}><Zoom isZoomed={true} key={params.id}>
        <img src={params.value} style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '5px' }} alt="Full Size" />
      </Zoom></div>),
      renderCell: (params) => (
        <Zoom isZoomed={true} key={params.id}>
          <div style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%'
          }} onClick={() => handleImageClick(params.value)}>
            <ImageIcon style={{ width: '30%', height: '30%' }} />
          </div>
        </Zoom>
      )
      // eslint-disable-next-line jsx-a11y/alt-text
      // renderCell: (params) => (<Zoom isZoomed={true} key={params.id}> <img src={params.value} /> </Zoom>)
    }, {
      field: "folio",
      headerName: "Folio",
      hide: false,
      flex: 0.5
    },
    {
      field: 'id_tipo_contenidos',
      headerName: 'Tipo Contenido',
      flex: 1,
      editable: true,
      renderEditCell: (params) => {
        let value = tipoContenidoList.find(objeto => objeto.id === params.value);
        return (
          <Autocomplete
            options={tipoContenidoList}
            getOptionLabel={(option) => option.id + " - " + option.label}
            size='small'
            variant="outlined"
            renderInput={(params) => <TextField {...params} />}
            value={value || null} // Asegúrate de que value sea null si no se encuentra en la lista
            onChange={(event, newValue) => {
              params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue ? newValue.id : null });
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '1vw', // Tamaño de fuente relativo al ancho de la ventana
              fontFamily: 'Public Sans, sans-serif',
              color: "#0A395F",
              width: '100%'
            }}
          />
        );
      },
      renderCell: (params) => {
        let value = tipoContenidoList.find(objeto => objeto.id === params.value);
        value = value?.id ? value.id + " - " + value.label : null;
        return value || null;
      }
    },
    {
      field: "descripcion",
      headerName: "Descripcion",
      hide: false,
      flex: 0.5,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 150,
      cellClassName: 'actions',
      getActions: (params) => {
        let id = params.id;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Tooltip title="Guardar">
                <SaveIcon color="secondary" />
              </Tooltip>}
              label="Save"
              onClick={handleSaveClick(id)}
              color="secondary"
            />,
            <GridActionsCellItem
              icon={<Tooltip title="Cancelar"><CancelIcon color="error" /></Tooltip>}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="error"
            />
          ];
        }
        return [
          <GridActionsCellItem
            icon={<Tooltip title="Editar">
              <EditIcon color="primary" />
            </Tooltip>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
          />,
          <GridActionsCellItem
            icon={<Tooltip title="ELiminar"><DeleteIcon color="error" /></Tooltip>}
            label="Delete"
            onClick={handleDeleteClick(id)}
          />,
          <GridActionsCellItem
            icon={<Tooltip title="Incluir a facturar">

              <Checkbox
                checked={params.row.checked}
                onChange={e => {
                  handleOnChangeCheckbox(id, e, params.row)
                }} />
            </Tooltip>}
            label="Incluir a facturar"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        minHeight: 300,
        height: 550, // Establece la altura en auto para que se ajuste al contenido
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        getRowHeight={() => 'auto'} 
      />
      {desplegarImagen && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={desplegarImagen}
          modalName={"Imagen"}
          child={
            <Grid sx={{
              mt: 1, minWidth: "100%", textAlign: "center",
              wordBreak: "break-all"
            }}>
              <img src={selectedImage} alt="Imagen" />
            </Grid>
          }
          // setPushData={onClickImagenesFacturas}
          handleClose={handleCloseImage}
          buttonEnviar={false}
          buttonCancelar={false}
          fullscreen={true}
        ></SimpleDialogComponent>
      )}
    </Box>
  );
}
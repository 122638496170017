import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";
import Swal from "sweetalert2";


export const getTablaEstructura = async (model) => {
    const tablaEstructura = new datos(model);

    const data = {
        "model": model
    }
    const response = await tablaEstructura.getTableStructure(data);
    return response;
};


export const getDataSelect = async (modelo) => {
    const object = new datos(modelo);
    object.addCondition(modelo, "id", 0, ">");

    try {
        const response = await object.get();
        const arrResponse = esArray(response?.wsResponse?.[modelo]);
        return arrResponse || [];
    } catch (error) {
        console.error('Error en getDataSelect:', error);
        return [];
    }
};

export const getDataWhereId = async (modelo, colum, id) => {
    const palabraExtraida = colum.replace('id_', ''); // Cortar "id_"

    const columna = modelo !== palabraExtraida ? colum : "id";
    const object = new datos(modelo);
    object.addCondition(modelo, columna, id, "=");

    try {
        const response = await object.get();
        const arrResponse = response?.wsResponse?.[modelo];
        return arrResponse;
    } catch (error) {
        console.error('Error en getDataSelect:', error);
        return false;
    }
};


export const getPaginatedData = async (limit, offset, filtros) => {
    const { modelo, condiciones } = filtros;
    const datosModelo = new datos(modelo);
    datosModelo.addCondition(modelo, "id", 0, ">");

    if (condiciones) {
        // Agregar condiciones dinámicamente
        condiciones.forEach(condicion => {
            datosModelo.addCondition(modelo, condicion.propiedad, condicion.valor, condicion.operador);
        });
    }


    datosModelo.addLimitCondition(limit, offset);
    datosModelo.addOrderCondition(modelo, "id", "ASC");

    const response = await datosModelo.get();
    const data = esArray(response.wsResponse[modelo]);

    let result = {};
    if (!response.wsResponse.empty) {
        result = {
            data: data,
            total_records: response.wsResponse.total_records,
        };
    }
    return result;
};



export const getDataByCondition = async (modelo) => {
    const datosModelo = new datos(modelo);
    datosModelo.addCondition(modelo, "id", 0, ">");
    const response = await datosModelo.get();
    return esArray(response.wsResponse[modelo]);
};

export const deleteDataById = async (modelo, id) => {
    let status = false;
    await Swal.fire({
        title: `¿Está seguro de que desea eliminar este ${modelo}?`,
        text: "¡Si sigue adelante, no se podrán revertir los cambios!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, quiero eliminarlo!",
        cancelButtonText: "No, no estoy seguro!",
    }).then(async (result) => {
        if (result.isConfirmed) {
            const modelData = new datos(modelo);
            const data = { id: id };
            const response = await modelData.delete(data);

            if (response.status === "true") {
                status = true;
                await Swal.fire(
                    "¡Eliminado!",
                    `El ${modelo} ha sido eliminado exitosamente.`,
                    "success"
                );
            } else {
                await Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response.message,
                });
            }
        }
    });
    return status;
};

export const schemaTipoUbicacionesForm = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "Id",
      description: "Id",
      hidden: true,
    },
    detalle: {
      type: "string",
      title: "Detalle",
      description: "Detalle",
      minLength: 3 
    },
    ancho: {
      type: "number",
      title: "Ancho",
      description: " Ancho",
    },
    alto: {
      type: "number",
      title: "Alto",
      description: "Alto",
    },
    profundidad: {
      type: "number",
      title: "Profundidad",
      description: "Profundidad",
    }
    
  },
  required: [ "id","detalle","ancho","alto","profundidad"],
};
 




import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { esArray } from "src/general_components/functionEsArray";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { getContenidosSearch } from "../helpers/ContenidosData";
import { imprimirEtiquetas } from "../helpers/EtiquetasData";
import { ListTablePaginated } from "./ListTablePaginatedImpresionCajas";
import { SearchFormGeneral } from "./SearchFormGeneral";

export const AdministracionContenidosList = () => {
  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const [dataList, setDataList] = useState([]);
  const {
    selectionModel,
    disabled,
    setDisabled,
    setDataInfoBusqueda,
    snackbarVisible,
    setSnackbarVisible,
    message,
    setMessage,
    severity,
    setSeverity,
    refresh,
    setRefresh,
    responseSearchFormGeneral,
    dataSearchFormGeneral,
    setResponseSearchFormGeneral,
  } = useContext(CajasContext);

  let url = window.location.href;
  const arrUrl = url.split("/");
  const proceso = arrUrl[arrUrl.length - 1]; // retorna el ultimo valor de la url

  useEffect(() => {
    var cantContenidos = 0;
    var cont = responseSearchFormGeneral;
    var listCajasCont = [];
    if (cont) {
      for (let i = 0; i < cont.length; i++) {
        // Si es un array se mantiene como estaba
        if (Array.isArray(cont[i].caja)) {
          // eslint-disable-next-line no-self-assign
          cont[i].caja = cont[i].caja;
          // Si no es un array... primero se verifica que tenga valores ... si no los tiene
        } else if (!cont[i].caja) {
          // se pasa un array vacio
          cont[i].caja = [];
        } else {
          // si trae un solo elemento , es un objeto
          cont[i].caja = [cont[i].caja]; // se lo convierte a array
        }

        for (let i2 = 0; i2 < cont[i].caja.length; i2++) {
          cont[i].cajaConcat =
            "ID: " +
            cont[i].caja[i2].id +
            " | " +
            "DETALLE: " +
            cont[i].caja[i2].detalle +
            " | " +
            "REFERENCIA: " +
            cont[i].caja[i2].referencia;
          cont[i].caja[i2].contenidos2 = cont[i].caja[i2].contenidos;

          listCajasCont.push({
            id: cont[i].caja[i2].id,
            cajaConcat: cont[i].cajaConcat,
            hierarchy: [cont[i].caja[i2].id],
            estado: cont[i].caja[i2].estado,
            cliente: cont[i].caja[i2].cliente,
          });

          // Si es un array se mantiene como estaba
          if (Array.isArray(cont[i].caja[i2].contenidos)) {
            // eslint-disable-next-line no-self-assign
            cont[i].caja[i2].contenidos = cont[i].caja[i2].contenidos;
            // Si no es un array... primero se verifica que tenga valores ... si no los tiene
          } else if (!cont[i].caja[i2].contenidos) {
            // se pasa un array vacio
            cont[i].caja[i2].contenidos = [];
          } else {
            // si trae un solo elemento , es un objeto
            cont[i].caja[i2].contenidos = [cont[i].caja[i2].contenidos]; // se lo convierte a array
          }

          if (cont[i].caja[i2].contenidos) {
            for (let i3 = 0; i3 < cont[i].caja[i2].contenidos.length; i3++) {
              var datos = cont[i].caja[i2].contenidos[i3].contenidos_metadatos;
              var contenidosConcatenados = concatenarContenidos(datos);
              cont[i].caja[i2].contenidosConcat = contenidosConcatenados;
              listCajasCont.push({
                id: "CONTENIDO" + cont[i].caja[i2].contenidos[i3].id,
                contenidoConcat: cont[i].caja[i2].contenidosConcat,
                hierarchy: [
                  cont[i].caja[i2].id,
                  cont[i].caja[i2].contenidos[i3].id,
                ],
                estado: cont[i].caja[i2].contenidos[i3].estado,
                idCaja: cont[i].caja[i2].id,
                idContenido: cont[i].caja[i2].contenidos[i3].id,
                meta_datos: datos,
                idTipoContenido:
                  cont[i].caja[i2].contenidos[i3].id_tipo_contenidos,
              });
              cantContenidos = cantContenidos + 1;
            }
          }
        }
      }
      if (cantContenidos > 0) {
        setSnackbarVisible(true);
        setSeverity("success");
        setMessage("Se encontraron " + cantContenidos + " contenidos");
        setDisabled(!disabled);
      }
      setDataList(listCajasCont);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseSearchFormGeneral]);

  /// Funcion que concatena el valor de los contenidos de cada caja
  const concatenarContenidos = (datos) => {
    var arrMetaDatos = [];
    if (Array.isArray(datos)) {
      arrMetaDatos = datos;
      // Si no es un array... primero se verifica que tenga valores ... si no los tiene
    } else if (!datos) {
      // se pasa un array vacio
      arrMetaDatos = [];
    } else {
      // si trae un solo elemento , es un objeto
      arrMetaDatos = [datos]; // se lo convierte a array
    }

    var contenidosConcatenados = "";
    for (let i4 = 0; i4 < arrMetaDatos.length; i4++) {
      if (contenidosConcatenados === "") {
        contenidosConcatenados =
          contenidosConcatenados +
          arrMetaDatos[i4].metadato_label_form +
          ": " +
          arrMetaDatos[i4].metadato_valor;
      } else {
        contenidosConcatenados =
          contenidosConcatenados +
          " | " +
          arrMetaDatos[i4].metadato_label_form +
          ": " +
          arrMetaDatos[i4].metadato_valor;
      }
    }
    return contenidosConcatenados;
  };

  /// Ejecuta las funciones que consumen el servicio search y cierra el modal de busqueda
  const ObtenerDatos = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };

  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };


  /// Despliega el buscador cuando se presiona F2
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setDataInfoBusqueda({});
        setAbrirBusqueda(!abrirBusqueda);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  // Se obtienen los id de los registros que se vayan seleccionando en la grilla
  const registrosSeleccionados = selectionModel.map((data) => {
    return { id: data };
  });

  // Funcion para el button imprimir
  const imprimir = async () => {
    let idCajasParaEtiquetas = registrosSeleccionados
      .map((registro) => registro.id)
      .join(",");
    let URI_aux = imprimirEtiquetas(idCajasParaEtiquetas);
    if (URI_aux) window.open(URI_aux, "_blank");
  };

  useEffect(() => {
    const obtenerContenidos = async () => {
      let response = await getContenidosSearch(dataSearchFormGeneral);
      if (response.status === "true") {
        if (response.wsResponse.cajas) {
          const cajasCont = esArray(response.wsResponse.cajas);
          setResponseSearchFormGeneral(cajasCont);
        }
      }
    };
    if (refresh) {
      obtenerContenidos();
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  // columnas del Listtable
  const columns = [
    { field: "cliente", headerName: "Cliente", flex: 0.3 },
    { field: "cajaConcat", headerName: "Caja", flex: 0.3 },
    { field: "contenidoConcat", headerName: "Contenido", flex: 1 },
    { field: "estado", headerName: "Estado", flex: 0.3 },
  ];

  // Opciones del Listtable
  var options = {
    cols: columns,
    datos: dataList,
    pageSize: 5,
    listName: "Cajas y Contenidos",
    limit: 20,
    filter: true,
    exportData: true,
    server: false,
    selectCheckbox: true,
  };
  if (proceso === "edit") {
    options = { ...options, edit: true, selectCheckbox: false };
  } else if (proceso === "list") {
    options = { ...options, edit: false, selectCheckbox: false };
  }

  return (
    <Grid>
      <Grid container spacing={3}>
        <Button
          endIcon={<SearchIcon />}
          onClick={() => {
            setDataInfoBusqueda({});
            setAbrirBusqueda(!abrirBusqueda);
          }}
          // disabled= {Number(clienteId) && Number(sectorId) && tipoContenido !== "" ? false : true}
          variant="contained"
          style={{
            marginTop: "20px",
            marginLeft: "70%",
            width: "25%",
            height: "30px",
            marginBottom: "20px",
          }}
        >
          Buscar
        </Button>
      </Grid>
      <Grid>
        <Grid>
          {" "}
          <ListTablePaginated options={options} />
        </Grid>

        {proceso === "impresion" && (
          <Fab
            variant="medium"
            color="primary"
            aria-label="add"
            style={{ position: "fixed", bottom: "10%", right: "5%" }}
            disabled={selectionModel.length > 0 ? false : true}
            onClick={imprimir}
          >
            <PrintIcon />
          </Fab>
        )}
        {/* NOTE BOTON BUSCAR CONTENIDO QUE ABRE EL FORMULARIO */}
        {abrirBusqueda && (
          <SimpleDialogComponent
            open={abrirBusqueda}
            modalName={"Busqueda de contenidos"}
            child={
              <SearchFormGeneral toggleModal={toggleModal} proceso={proceso} />
            }
            setPushData={ObtenerDatos}
            handleClose={toggleModal}
            buttonEnviar={false}
            buttonCancelar={true}
          />
        )}
        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          hide={8000}
          close={() => {
            setSnackbarVisible(false);
          }}
        />
      </Grid>
    </Grid>
  );
};

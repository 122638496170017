
import { Button, Container, Grid } from "@mui/material";
import { esES } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import "src/services/styles/sweetAlert.css";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { CustomNoRowsOverlay } from "src/general_components/Overlay";
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,

} from "@mui/x-data-grid";
import { esArray } from "src/general_components/functionEsArray";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { estiloGrilla } from "src/general_components/FunctionsListTablePaginated";
import PrintIcon from '@mui/icons-material/Print';
import { parametros_proyecto, id_proyecto } from "src/configs/config";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { v4 as uuidv4 } from 'uuid';


export const ListTableFichas = (data) => {

    const {
        buttonExtraOn,
        ButtonExtra,
    } = data.options;

    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [message, setMessage] = useState(false);
    const [severity, setSeverity] = useState(false);
    const [records, setRecords] = useState([]);
    const [records1, setRecords1] = useState([]);
    const [columns, setColumns] = useState(data.options.cols);
    const [loading, setLoading] = useState(false);
    const viewCustomToolbar = data.options.viewCustomToolbar || true;
    const [criterial, setCriterial] = useState({});

    let totalRowCount = 0;

    function buscarPorIdProyecto(id) {
        return parametros_proyecto?.find(objeto => objeto.id === id);
    }
    const proyecto_encontrado = buscarPorIdProyecto(id_proyecto);

    const logoUrl = proyecto_encontrado?.url_imagen;

    const handlePrintClick = () => {
        let clients = esArray(JSON.parse(localStorage.getItem("siam-cuentas_personas_juridicas")).cuentas_personas_juridicas);
        const id_cuentas_filtrar = data.options.clienteSeleccionado;
        const clientesFiltrados = clients.filter(cliente => cliente.id_cuentas === id_cuentas_filtrar);
    
        const columnsWithoutExtraData = columns.filter(column => column.field !== 'extraData'); // Eliminar la columna extraData
    
        const recordsWithoutExtraData = records1.map(row => { // Eliminar el campo extraData de cada registro
            const { extraData, ...rest } = row;
            return rest;
        });
    
        const doc = new jsPDF();
    
        // Agregar imagen (ajustando tamaño automáticamente manteniendo la proporción)
        if (logoUrl) {
            const imgWidth = 42; // Ancho deseado de la imagen
            const imgHeight = 18; // Alto deseado de la imagen
    
            const logoImg = new Image();
            logoImg.onload = function () {
                const widthRatio = logoImg.width / imgWidth;
                const heightRatio = logoImg.height / imgHeight;
    
                // Calcula el tamaño manteniendo la proporción original
                const width = logoImg.width / Math.max(widthRatio, heightRatio);
                const height = logoImg.height / Math.max(widthRatio, heightRatio);
    
                // Calcula la posición horizontal centrada
                const startX = (doc.internal.pageSize.getWidth() - width) / 2;
    
                // Agrega la imagen al documento PDF
                doc.addImage(logoUrl, 'PNG', startX, 10, width, height);
    
                // Configurar texto centrado horizontalmente
                const text = "Ficha Individual de " + clientesFiltrados[0].razon_social;
                const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const textStartX = (doc.internal.pageSize.getWidth() - textWidth) / 2;
                const textStartY = 30; // Puedes ajustar la posición vertical según sea necesario
    
                // Agregar texto centrado horizontalmente
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text(text, textStartX, textStartY);
    
                // Resto del código para añadir tabla
                const spaceBetween = 5;
                const tableStartY = textStartY + spaceBetween;
    
                // Configurar estilos de columnas
                const columnStyles = {
                    
                    3: { // cuerta columna
                        columnWidth: 30, // Ancho de la segunda columna en puntos
                    },
                    4: { // quinta columna
                        columnWidth: 30, // Ancho de la segunda columna en puntos
                    },
                    5: { // sexta columna
                        columnWidth: 30, // Ancho de la segunda columna en puntos
                    },
                    // Puedes añadir más columnas según sea necesario
                };
    
                doc.autoTable({
                    head: [columnsWithoutExtraData.map(column => ({ content: column.headerName, styles: { fillColor: [255, 0, 0] } }))],
                    body: recordsWithoutExtraData.map(row => columnsWithoutExtraData.map(column => row[column.field])),
                    startY: tableStartY,
                    styles: {
                        fontSize: 8,
                    },
                    columnStyles: columnStyles, // Aplicar los estilos de las columnas
                });
    
                // Guardar y descargar el documento PDF
                doc.save('Ficha_' + clientesFiltrados[0].razon_social.replace(/ /g, '_') + '.pdf');
            };
    
            logoImg.src = logoUrl;
        } else {
            // En caso de que logoUrl no esté definido, manejar el error o la falta de imagen.
            console.error('URL de imagen no válida');
        }
    };
    

    useEffect(() => {

        if (!loading && data.options.idElementData !== false) {
            const getDataListTableCuentas = async () => {
                setRecords([]);
                setLoading(true);
                const response = await data.options.getPaginated(data.options.idElementData);


                let message = "La búsqueda ha sido exitosa."
                let statusMessage = 'success'

                if (response) {
                    setCriterial(data.options.idElementData);


                    const dataConId = response.data.map((obj, index) => ({
                        ...obj,
                        id: uuidv4()
                    }));


                    setRecords(dataConId);
                    let cols_aux = [...data.options.cols];
                    setColumns(cols_aux);
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    totalRowCount = parseInt(response.total_records)

                    if (response.data.length === 0) {
                        message = "No se encontraron registros!"
                        statusMessage = 'warning'
                        totalRowCount = 0;

                    }

                } else {
                    message = "Error en la busqueda!"
                    statusMessage = 'error'
                }

                setMessage(message);
                setSeverity(statusMessage);
                setSnackbarVisible(true);

                setLoading(false);
            };

            getDataListTableCuentas();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.options.idElementData, data.options.refresh]);

    const obtenerFechaActual = () => {
        const fecha = new Date();
        const dia = fecha.getDate();
        const mes = fecha.getMonth() + 1; // Los meses en JavaScript son indexados desde 0
        const anio = fecha.getFullYear();

        // Formatear la fecha
        return `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${anio}`;
    };


    const formatearFecha = (fecha) => {
        const partesFechaTrabajo = fecha.split("-");
        const fechaFormateadaTrabajo = `${partesFechaTrabajo[2]}/${partesFechaTrabajo[1]}/${partesFechaTrabajo[0]}`

        // Formatear la fecha
        return fechaFormateadaTrabajo;
    };



    useEffect(() => {

        if (records.length > 0) {
            const indexMayorQue = Object.keys(criterial).find(key => criterial[key].filter === ">=") ? true : false;
            const indexMenorQue = Object.keys(criterial).find(key => criterial[key].filter === "<=") ? true : false;

            let valueMenorQue;

            criterial.forEach(item => {
                if (item.filter === '<=') {
                    valueMenorQue = item.value;
                }
            });

            if (indexMayorQue) {

                const newCriterial = criterial.map(objeto => {
                    if (objeto.filter === '<=') {
                        return null;
                    } else if (objeto.filter === '>=') {
                        return { ...objeto, filter: '<' };
                    }
                    return objeto;
                }).filter(Boolean);


                totalesIniciales(indexMenorQue ? formatearFecha(valueMenorQue) : obtenerFechaActual(), newCriterial)

            } else {

                totalesFinales(indexMenorQue ? formatearFecha(valueMenorQue) : obtenerFechaActual(), records, false, false)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records])

    const formatearFechaDescuentaUnDia = (fecha) => {
        const fechaFormateada = new Date(fecha);
        fechaFormateada.setDate(fechaFormateada.getDate());
        const dia = fechaFormateada.getDate();
        const mes = fechaFormateada.getMonth() + 1; // Sumamos 1 porque los meses en JavaScript son indexados desde 0
        const año = fechaFormateada.getFullYear();

        return `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${año}`;
    }

    const extraeNumDeMoneda = (str) => {
        const numberString = str.replace(/[^\d.-]/g, '');
        return parseFloat(numberString);
    };

    const totalesIniciales = async (fechaHasta, newCriterial) => {
        const indexMayorQue = Object.keys(newCriterial).find(key => newCriterial[key].filter === "<") ? true : false;
        let simmboloMoneda = esArray(JSON.parse(localStorage.getItem("siam-tipo_moneda")).tipo_moneda)[0].simbolo;

        if (indexMayorQue) {

            const response = await data.options.getPaginated(newCriterial);
            const objFechaDesde = newCriterial.find(condicion => condicion.filter === "<")
            const fechaDesdeValue = objFechaDesde.value;

            let totalIngresoTemp = 0;
            let totalEgresoTemp = 0;
            let saldoRow = {};
            response.data.forEach((item) => {

                if (!item.tachar) {
                    totalIngresoTemp += parseFloat(extraeNumDeMoneda(item.ingreso)) || 0;
                    totalEgresoTemp += parseFloat(extraeNumDeMoneda(item.egreso)) || 0;
                }

            });
            let saldo = totalIngresoTemp - totalEgresoTemp;
            if (saldo < 0) {
                saldoRow = {
                    id: 'saldoInicial',
                    fecha_comprobante: `Saldo hasta ${formatearFechaDescuentaUnDia(fechaDesdeValue)}`,
                    descripcion: '',
                    ingreso: '',
                    egreso: simmboloMoneda + ' ' + Math.abs(saldo).toFixed(2),
                    observaciones: '',
                };
            } else {
                saldoRow = {
                    id: 'saldoInicial',
                    fecha_comprobante: `Saldo hasta ${formatearFechaDescuentaUnDia(fechaDesdeValue)}`,
                    descripcion: '',
                    ingreso: simmboloMoneda + ' ' + Math.abs(saldo).toFixed(2),
                    egreso: '',
                    observaciones: '',
                };
            }

            const newDataCopy = [...records];

            newDataCopy.unshift(saldoRow);
            let saldoItem = saldo
            newDataCopy.forEach((item, index) => {
                if (item.id !== "saldoInicial") {
                    const ingreso = item.ingreso ? extraeNumDeMoneda(item.ingreso) : 0;
                    const egreso = item.egreso ? extraeNumDeMoneda(item.egreso) : 0;

                    // Calcular el saldo actual para el item
                    saldoItem = !item.pendiente_cuenta_corriente? saldoItem + ingreso : saldoItem;
                    saldoItem =  !item.pendiente_cuenta_corriente? saldoItem - egreso : saldoItem;

                    // Agregar el saldo calculado al objeto
                    newDataCopy[index].saldo = item.moneda + ' ' + saldoItem.toFixed(2);
                }
            });
            setRecords1(newDataCopy)
            totalesFinales(fechaHasta, newDataCopy, true, saldo)
        }
    }



    const totalesFinales = (fechaDesdeValue, newRecords, totalesIniciales, saldoInicial = false) => {

        let simmboloMoneda = esArray(JSON.parse(localStorage.getItem("siam-tipo_moneda")).tipo_moneda)[0].simbolo;

        if (records.length !== 0) {
            let totalIngresoTemp = 0;
            let totalEgresoTemp = 0;

            records.forEach((item) => {

                if (!item.tachar) {
                    totalIngresoTemp += parseFloat(extraeNumDeMoneda(item.ingreso)) || 0;
                    totalEgresoTemp += parseFloat(extraeNumDeMoneda(item.egreso)) || 0;
                }
            });

            const objInicial = newRecords.find(condicion => condicion.id === "saldoInicial")
            const saldoInicialIngreso = objInicial?.ingreso ? extraeNumDeMoneda(objInicial.ingreso) : 0;
            const saldoInicialEgreso = objInicial?.egreso ? extraeNumDeMoneda(objInicial.egreso) : 0;

            let totalIngreso = parseFloat(totalIngresoTemp) + parseFloat(saldoInicialIngreso);
            let totalEgreso = parseFloat(totalEgresoTemp) + parseFloat(saldoInicialEgreso);
            let saldo = totalIngreso - totalEgreso;


            const totalRow = {
                id: 'total',
                fecha_comprobante: 'Totales',
                descripcion: '',
                ingreso: simmboloMoneda + ' ' + totalIngreso.toFixed(2),
                egreso: simmboloMoneda + ' ' + totalEgreso.toFixed(2)
            };

            const saldoRow = {
                id: 'saldoHasta',
                fecha_comprobante: `Saldo hasta ${fechaDesdeValue}`,
                descripcion: '',
                ingreso: '',
                egreso: '',
                observaciones: simmboloMoneda + ' ' + saldo.toFixed(2),
            };
            (totalesIniciales === true) ?
                setRecords1([...newRecords, totalRow, saldoRow])
                :
                setRecords1([...records, totalRow, saldoRow]);


        }
    };

    const shouldHighlightRow = (row) => {
        if ((row && row.fecha_comprobante === 'Totales') || row.fecha_comprobante.includes('Saldo hasta')) {

            return true;
        }
        return false;
    };
    const getRowClassName = (params) => {
        // Llamar a shouldHighlightRow para decidir si aplicar la clase 'highlighted-row'
        return shouldHighlightRow(params.row) ? 'highlighted-row' : '';
    };


    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>

                <Grid>
                    {viewCustomToolbar !== "false" && (<><GridToolbarColumnsButton
                        style={{
                            color: '#FFFFFF',
                            fontFamily: 'Roboto, sans-serif',
                            background: "#0A395F",
                            margin: '4px',
                            minWidth: '120px',
                            width: '120px',
                            fontSize: '11px',
                            height: '30px',
                        }}
                    />
                        <GridToolbarFilterButton
                            style={{
                                color: '#FFFFFF',
                                fontFamily: 'Roboto, sans-serif',
                                background: "#0A395F",
                                margin: '4px',
                                minWidth: '120px',
                                width: '120px',
                                fontSize: '11px',
                                height: '30px',
                            }}
                        />
                        <GridToolbarDensitySelector
                            style={{
                                color: '#FFFFFF',
                                fontFamily: 'Roboto, sans-serif',
                                background: "#0A395F",
                                margin: '4px',
                                minWidth: '120px',
                                width: '120px',
                                fontSize: '11px',
                                height: '30px',
                            }}
                        />
                        <Button
                            startIcon={<PrintIcon />}
                            disabled={records.length > 0 ? false : true}
                            onClick={handlePrintClick}
                            style={{
                                color: '#FFFFFF',
                                fontFamily: 'Roboto, sans-serif',
                                margin: '4px',
                                minWidth: '120px',
                                width: '120px',
                                fontSize: '11px',
                                height: '30px',
                                background: records.length > 0 ? "#0A395F" : 'gray',
                            }}
                        >
                            Imprimir
                        </Button>
                    </>)}

                </Grid>
                <Grid>

                    {buttonExtraOn && (<ButtonExtra></ButtonExtra>)}

                </Grid>

            </GridToolbarContainer>
        );
    }

    return (
        <Container maxWidth="xxl" style={{ padding: "20px 0px 0px 0px" }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DataGridPro
                        disableColumnReorder={true}
                        autoHeight
                        autoPageSize
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            Toolbar: CustomToolbar,
                        }}
                        density="compact"
                        sx={{
                            ...estiloGrilla
                        }}
                        rows={records1}
                        columns={columns.map((column) => ({
                            ...column,
                            cellClassName: (params) => {

                                if ((column.field === 'ingreso' || column.field === 'egreso') && params.row.tachar) {

                                    return 'strike'; // Clase CSS para aplicar estilos de tachado
                                }
                                return '';
                            },
                            // cellStyle: getCellStyle,
                        }))}
                        rowCount={totalRowCount}
                        loading={loading}
                        pagination={false}
                        getRowId={(row) => row.id}
                        getRowClassName={getRowClassName}
                        {...data.options.props}
                    />
                    <style>{`
                .highlighted-row { font-weight: bold; }
                .strike { text-decoration: underline; }
            `}</style>

                </Grid>
            </Grid>

            <SnackbarMessenger
                open={snackbarVisible}
                message={message}
                severity={severity}
                close={() => {
                    setSnackbarVisible(false);
                }}
            />
        </Container>
    );
};

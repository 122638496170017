import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Autocomplete, Grid, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { FormularioDinamico } from "./FormularioDinamico";
import { getTablaEstructura } from "src/hooks/structureTable";
import { postDinamico } from "src/general_helpers/AgegarDinamicoData";
import { getDataSelect } from "src/hooks/structureTable";

export const SelectAgregarDinamico = (data) => {

  //Variables para el formulario ya sea edicion o creacion
  const {
    labelSelect,
    elementoSelect,
    setElementoSelect,
    disabledButtonAgregar,
    labelButtonAgregar,
    modalAgregarName,
    options,
    loading,
    modelo,
    valuesDefaultForm,
    setMessage,
    setSeverity,
    setSnackbarVisible,
    setNewElementAdd,
    inputsObligatorios,
    customComponents = {}
  } = data;

  const [modalState, setModalState] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formData, setFormData] = useState(valuesDefaultForm);


  const handleChange = (event, newValue) => {
    setElementoSelect(newValue)
  };


  const getEstructuraTabla = async () => {
    // setIsLoadingData(true);
    let response = await getTablaEstructura(modelo);

    if (response.status === "true") {
      const formDataResponse = response.wsResponse;
      const camposFiltrados = await filtrarCampos(formDataResponse);
      const resultado = combinarObjetos(camposFiltrados, customComponents);

      setFormData(resultado);
    }

  };

  const handleSubmit = async () => {
    const data = { ...formValues, ...valuesDefaultForm }
    const response = await postDinamico(modelo, data);
    if (response.status === "true") {
      setMessage("Se agrego exitosamente!");
      setSeverity("success");
      setSnackbarVisible(true);
      setNewElementAdd(response.wsResponse[modelo])
      setFormValues({});
      toggleModal();
    } else {
      setMessage("Hubo un problema al agregar el " + modelo);
      setSeverity("warning");
      setSnackbarVisible(true);
    }
  };

  const combinarObjetos = (objeto1, objeto2) => {
    const resultado = { ...objeto1 };

    for (const key in objeto2) {
      if (resultado.hasOwnProperty(key)) {
        resultado[key] = { ...resultado[key], ...objeto2[key] };
      } else {
        resultado[key] = objeto2[key];
      }
    }

    return resultado;
  }


  const filtrarCampos = async (formDataResponse) => {
    const camposFiltrados = {};
    for (const fieldName in formDataResponse) {
      if (!valuesDefaultForm[fieldName] && !customComponents[fieldName] && fieldName.includes('id_')) {
        const palabraExtraida = fieldName.replace('id_', ''); // Cortar "id_"
        const valorLocalStorage = JSON.parse(localStorage.getItem("siam-" + palabraExtraida))?.[palabraExtraida];

        if (valorLocalStorage) {
          const opcionesFiltradas = valorLocalStorage.map(({ id, nombre, descripcion, label, detalle }) => ({
            id,
            nombre: nombre || descripcion || detalle || label || 'Sin Nombre', // Prioridad: nombre > descripcion > label
          }));

          camposFiltrados[fieldName] = {
            ...formDataResponse[fieldName],
            options: opcionesFiltradas,
          };
        } else {
          const datosSelect = await getDataSelect(palabraExtraida);

          const opcionesFiltradas = datosSelect.map(({ id, nombre, descripcion, label, detalle }) => ({
            id,
            nombre: nombre || descripcion || detalle || label || 'Sin Nombre', // Prioridad: nombre > descripcion > label
          }));

          camposFiltrados[fieldName] = {
            ...formDataResponse[fieldName],
            options: opcionesFiltradas,
          };
        }
      }
    }

    // Combina formData con camposFiltrados
    let camposFinal = { ...formDataResponse, ...camposFiltrados };

    if (camposFinal.id) {
      delete camposFinal.id;
    }

    // Eliminar propiedades de camposFinal si coinciden con valuesDefaultForm
    Object.keys(valuesDefaultForm).forEach((key) => {
      delete camposFinal[key];
    });
    return camposFinal;
  };


  const toggleModal = () => {
    setModalState(!modalState)
  }


  const dialogChild = <FormularioDinamico
    formData={formData}
    formValuesPost={formValues}
    setFormValuesPost={setFormValues}
    toggleModalForm={toggleModal}
    handleSubmit={handleSubmit}
    inputsOblicatorios={inputsObligatorios || []} />

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid container spacing={2} style={{  alignItems: 'center',justifyContent: 'center' }}>
          <Grid item xs={10}>
            <FormControl sx={{ minWidth: "100%" }} size="small">
              <Autocomplete
                size="small"
                value={elementoSelect || null}
                onChange={handleChange}
                options={options || []}
                getOptionLabel={(option) => option.descripcion || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id} // Personaliza la comparación
                renderInput={(params) => (
                  <TextField {...params} label={labelSelect} />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              sx={{ minWidth: '100%', fontSize: '12px', fontFamily: 'Roboto, sans-serif' }}
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              disabled={disabledButtonAgregar}
              onClick={async () => {
                await getEstructuraTabla();
                toggleModal();
              }}
            >
              {labelButtonAgregar}
            </Button>
          </Grid>
        </Grid>

        <SimpleDialogComponent
          open={modalState}
          modalName={modalAgregarName}
          child={dialogChild}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      </div>
    );
  }
};

import datos from "src/services/datos";

export const getTipoMoneda = async (auth) => {
  const tipo_moneda = new datos("tipo_moneda");
  tipo_moneda.addCondition("tipo_moneda", "id", 0, ">");
  const response = await tipo_moneda.get();

  const res = response.wsResponse.tipo_moneda;
  // Si es un array se mantiene como estaba
  if (Array.isArray(res)) {
    var arr = res;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (typeof res === "undefined") {
    // se pasa un array vacio
    arr = [];
  } else {
    // si trae un solo elemento , es un objeto
    arr = [res]; // se lo convierte a array
  }
  return arr;
};

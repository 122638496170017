import React, { useContext } from "react";
import { ListTablePaginated } from "./ListTablePaginated";
import { ArticulosContext } from "src/context/ArticulosContext";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { getArticulos, getArticulosPaginated } from "../helpers/ArticulosData"
import { Button, Grid } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { ArticulosForm } from "./ArticulosForm";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { saveData } from "src/general_helpers/manageData";
import { obtenerProcesosPorPerfilYMenu } from "src/general_components/functionEsArray";
/* Listado de Articulos */

export const ArticulosList = (props) => {

  const {
    setArticulosFormData,
    message,
    severity,
    snackbarVisible,
    setSnackbarVisible,
    setModelSave, setArticulo, modalState, setModalState
  } = useContext(ArticulosContext);

  let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));
  const titulo_config = parametros_proyecto?.titulo_articulos;
  const titulo_proceso = titulo_config ? "Alta de " + titulo_config : "Alta de Articulos";

  const restricciones = obtenerProcesosPorPerfilYMenu();
  let alta = true;
  if (restricciones) {
    alta = restricciones.alta;
  }

  // Columnas del Listtable
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.1 },  // la propiedad flex permite setear responsivamente el tamaño de la columna 
    { field: 'nombre', headerName: 'Nombre', flex: 0.3 },
    { field: 'alias', headerName: 'Nombre reducido', flex: 0.3 },
  ];

  // Propiedades del Listtable
  const options = {
    cols: columns,
    get: getArticulos,
    getPaginated: getArticulosPaginated,
    limit: 50,
    server: false,
    pageSize: [50, 100, 150, 200],
    printButton: true
  }

  const dialogChild = <ArticulosForm />

  const toggleModal = () => {
    setModalState(!modalState)
    if (modalState) {
      setModelSave("")
      setArticulo("0")
    }
  }
  return (
    <>
      <Grid>
        <Grid container marginTop={'10px'} justifyContent="flex-end">
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setArticulosFormData({})
              toggleModal()
            }}
            hidden={!alta ? true : false}
          >
            <AddCircle />
            <div>
              Agregar {/* BOTON AGREGAR */}
            </div>
          </Button>
        </Grid>
        <ListTablePaginated options={options} />
        <SimpleDialogComponent
          open={modalState}
          modalName={titulo_proceso}
          child={dialogChild}
          setPushData={saveData}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          close={() => { setSnackbarVisible(false) }} />
      </Grid>
    </>
  );


};

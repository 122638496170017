import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";

export const getTipoMovimientos = async () => {
  const tipoMovimientos = new datos("tipo_movimientos");

  const response = await tipoMovimientos.getMovimientos();

  return response;
};

export const getTipoMovimientosData = async () => {
  const tipoMovimientos = new datos("tipo_movimiento");
  tipoMovimientos.addCondition("tipo_movimiento", "id", 0, ">");
  const response = await tipoMovimientos.get();
  return esArray(response.wsResponse.tipo_movimiento);
};

import { Grid, Typography, useTheme } from '@mui/material';
import CustomBreadcrumbs from 'src/general_components/BreadCrumbsCustom';

export const HeaderTitleBreadcrumbs = ({listName}) => {
    const theme = useTheme();
    const backgroundColor =
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900];
    return (
        <>
            <Grid
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontFamily: 'Roboto, sans-serif',
                    borderRadius: 10,
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    marginBottom: 20,
                    height: 50,
                    backgroundColor: backgroundColor,
                }}
            >
                <Typography
                    align="left"
                    variant="h2"
                    component="div"
                    sx={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                        paddingLeft: 3,
                        color: theme.palette.mode === 'light' ? '#0A395F' : '#FFFFFF',
                    }}
                >
                    {listName}
                </Typography>
                <CustomBreadcrumbs />
            </Grid>

        </>
    );
};


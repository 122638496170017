import React from 'react';

export const ComprobantePrint = (params) => {
  const { contenidoChild } = params;

  return (
    <iframe
      title="Vista Previa"
      srcDoc={contenidoChild}
      width="100%"
      height="1000px"
      id="comprobanteIframe"
      frameBorder="0"
      scrolling="no" />);
};

export default ComprobantePrint;
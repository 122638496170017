import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useContext, useState, useEffect } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { imprimirEtiquetas } from "../helpers/EtiquetasData";
import { getContenidosSearchPaginated, getContenidos } from "../helpers/ContenidosData";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { SearchFormGeneral } from "./SearchFormGeneral";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { estadoColores, estadoIconos } from "./estadosCajas";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


import { Style } from "@mui/icons-material";

export const CajasEtiquetasList = (datosUrl) => {
  const {
    selectionModel,
    snackbarVisible,
    setSnackbarVisible,
    setShowFormMovimientos,
    setRequiredFormMovimientos,
    setOmitirQuerySearch,
    message,
    setMessage,
    setSeverity,
    severity,
    setSelectionModel,
    dataSearchFormGeneral, setActualizarListado,setSoloCajas
  } = useContext(CajasContext);

  const [actualizarSelected, setActualizarSelected] = useState(false);
  const [responseService, setResponseService] = useState(false);

  let proceso = "impresion";

  const [abrirBusqueda, setAbrirBusqueda] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [pushData, setPushData] = useState(false);

  var columns = [];
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);
  const flex = 100;
  const minWidth = 100;
  const maxWidth = 200;

  ///////////////////


  columns = [
    {
      field: "clienteName",
      headerName: "Cliente",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 100,
      maxWidth: isMobileDevice ? 200 : 200,
      minWidth: isMobileDevice ? minWidth : 50,
    },
    {
      field: "sectorName",
      headerName: "Sector",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 2,
      maxWidth: isMobileDevice ? maxWidth : 200,
      minWidth: isMobileDevice ? minWidth : 50,
    },
    {
      field: "concatCaja",
      headerName: "Caja",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 2,
      maxWidth: isMobileDevice ? maxWidth : 300,
      minWidth: isMobileDevice ? minWidth : 150,
    },
    {
      field: "ubicacion",
      headerName: "Ubicacion",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 1,
      maxWidth: isMobileDevice ? maxWidth : 350,
      minWidth: isMobileDevice ? minWidth : 175,
    },
    {
      field: "tipo_cajas",
      headerName: "Tipo de Caja",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 1,
      maxWidth: isMobileDevice ? maxWidth : 350,
      minWidth: isMobileDevice ? minWidth : 175,
    },
    {
      field: "estados_cajas",
      headerName: "Estado",
      headerAlign: "left",
      flex: isMobileDevice ? flex : 2,
      maxWidth: isMobileDevice ? maxWidth : 150,
      minWidth: isMobileDevice ? minWidth : 100,
      renderCell: (params) => {
        if (params.value) {
          const color = estadoColores[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
          const icono = estadoIconos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
          return (
            <Grid sx={{}}>
              <Button
                size="small"  // Tamaño más pequeño
                variant="text"
                style={{

                  color: color,

                }}
                disabled  // Desactiva la interacción del botón
                startIcon={icono}  // Icono en el inicio del botón
              >
                {params.value}
              </Button>
            </Grid>
          );
        } else {
          return null;
        }
      },
    },
  ];

  // Se obtienen los id de los registros que se vayan seleccionando en la grilla
  const registrosSeleccionados = selectionModel.map((data) => {
    return { id: data };
  });

  // Consume el servicio de postCajasUbicaciones y devuelve los mensajes que retorna el servicio.
  const imprimir = async () => {
    let idCajasParaEtiquetas = registrosSeleccionados
      .map((registro) => registro.id)
      .join(",");
    let URI_aux = imprimirEtiquetas(idCajasParaEtiquetas);
    if (URI_aux) window.open(URI_aux, "_blank");
  };

  // Se utiliza para actualizar el array de id seleccionados en el listtable ya que el selectionModel 
  // al limpiar sigue trayendo los elementos seleccionados anteriores
  useEffect(() => {
    if (actualizarSelected) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: true,
        actualizarSelected: actualizarSelected,
        setActualizarSelected: setActualizarSelected
      });
    } else {
      setOptions({
        ...options,
        sinFilas: true,
        actualizarSelected: actualizarSelected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualizarSelected
  ]);

  // Se utiliza para mostrar los mensajes en base a la respuesta que retornan los servicios en el listtablePaginated
  useEffect(() => {
    if (responseService) {
      if (responseService.total_records) {
        let textSnackbar =
          "Se han encontrado " +
          responseService.total_records +
          " caja(s)";
        setMessage(textSnackbar);
        setSeverity('success')
        setSnackbarVisible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseService]);

  const buttonBuscar = () => (
    <Button
      endIcon={<SearchIcon />}
      onClick={() => {
        setShowFormMovimientos(false);
        setRequiredFormMovimientos(false);
        setAbrirBusqueda(!abrirBusqueda);
        setOmitirQuerySearch(true);
        setSoloCajas(true);
      }}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
      }}
    >
      Buscar
    </Button>
  );


  const [options, setOptions] = useState({
    cols: columns,
    getPaginated: getContenidosSearchPaginated,
    get: getContenidos,
    pageSize: [5, 10, 25],
    limit: 50,
    filter: true,
    exportData: true,
    notas: true,
    server: true,
    sinFilas: true,
    idElementData: dataSearchFormGeneral,
    hierarchyOn: true,
    detailsOn: true,
    selectCheckbox: true,
    valuesSelected: setSelectionModel,
    setResponseService: setResponseService,
    buttonExtraOn: true,
    ButtonExtra: buttonBuscar

  });
  useEffect(() => {
    if (!Array.isArray(dataSearchFormGeneral)) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: false,
      });
      setActualizarListado(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataSearchFormGeneral
  ]);

  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
    //setModalState(!modalState);
  };


  /// Despliega el buscador cuando se presiona F2
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setShowFormMovimientos(false);
        setRequiredFormMovimientos(false);
        setAbrirBusqueda(!abrirBusqueda);
        setOmitirQuerySearch(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  return (

    <Grid >
      {abrirBusqueda && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirBusqueda}
          modalName={"Busqueda de contenidos"}
          child={
            <SearchFormGeneral toggleModal={toggleModal} proceso={proceso} />
          }
          setPushData={setPushData}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}

      <Grid>
        {" "}
        <ListTablePaginated options={options} />
      </Grid>
      <Grid container marginBottom={'5px'} justifyContent="center" marginTop={'5%'}>
        <Button
          disabled={selectionModel.length > 0 ? false : true}
          onClick={
            imprimir
          }
          endIcon={<Style />}
          variant="contained"
          color="success"

        >
          Etiquetas
        </Button>
      </Grid>

      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={8000}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </Grid>
  );
};

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
    Typography,
    TextField,
} from '@mui/material';
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
import { useState } from "react";

export const ConfirmationModal = (props) => {
    const {
        modalName,
        open,
        isMobileDevice,
        setPushData,
        handleClose,
        buttonEnviar = true,
        maxWidth = "md",
        setSolicitNewCode,
        buttonNuevoCodigo
    } = props;

    let isMobile = isMobileDevice ? false : true;


    const [confirmationCode, setConfirmationCode] = useState('');
    const handleOnClose = (event, reason) => {
        console.log("handleOnClose", reason);
        if (reason === "backdropClick") {
            // Handle backdrop click if needed
        } else if (reason === "escapeKeyDown") {
            handleClose();
        } else {
            handleClose();
        }
    };

    const Title = styled(Typography)(({ theme }) => ({
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: '1rem',
        marginBottom: theme.spacing(0),
    }));

    const CloseButton = styled(IconButton)(({ theme }) => ({
        color: '#FFFFFF',
    }));

    const Header = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0),
    }));

    return (
        <>
            <Dialog
                open={open}
                onClose={handleOnClose}
                fullWidth={isMobile}
                maxWidth={maxWidth}
            >
                <DialogTitle style={{ background: "linear-gradient(69deg, #000000 0%, #44787D 100%)", }}>
                    <Header>
                        <div>
                            <Title>{modalName}</Title>
                        </div>
                        <CloseButton onClick={handleClose}>
                            <CloseIcon />
                        </CloseButton>
                    </Header>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        label={!buttonNuevoCodigo ? "Nueva Contraseña" : "Código"}
                        variant="outlined"
                        value={confirmationCode}
                        onChange={(e) => setConfirmationCode(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                {buttonEnviar && <Divider />}
                <DialogActions>
                    {buttonEnviar && (
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => setPushData(confirmationCode)}
                        >
                            Confirmar
                        </Button>

                    )}
                    {buttonNuevoCodigo && (
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={setSolicitNewCode} // Manejar la solicitud de un nuevo código
                        >
                            Solicitar Nuevo Código
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};


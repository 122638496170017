export const uiSchemaCajasForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/descripcionClienteSector",
      label: "Cliente/Sector",
      options: {
        readonly: true,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/cantidad",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/id_tipo_cajas",
        },
        {
          type: "Control",
          scope: "#/properties/referencia",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/detalle",
    },
  ],
};


export const uiSchemaCajasFormSinClienteSector = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/cantidad",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/id_tipo_cajas",
        },
        {
          type: "Control",
          scope: "#/properties/referencia",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/detalle",
    },
  ],
};

export const uiSchemaCajasFormEdit = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/id_tipo_cajas",
        },
        {
          type: "Control",
          scope: "#/properties/referencia",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/detalle",
    },
  ],
};

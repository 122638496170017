import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css";

const categorias = new datos("categorias");

export const getCategorias = async (auth) => {
  categorias.addCondition("categorias", "id", 0, ">");
  const response = await categorias.get();
  return response.wsResponse.categorias;
};

export const getCategoriasPaginated = async (limit, offset) => {
  categorias.addCondition("categorias", "id", 0, ">");
  categorias.addLimitCondition(limit, offset);
  const response = await categorias.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  
  if (!response.wsResponse.empty) {
    let records = esArray(response.wsResponse.categorias)
    result = {
      data: records,
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const postCategoria = async (categoria) => {
  if (categoria) {
    const data = {
      id: categoria.id,
      nombre: categoria.detalle,
    };
    const response = await categorias.insert(data)
    if (response) {
      return response.wsResponse.categorias;
    }
  }
  return false;
};

export const deleteCategoria = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar esta Categoria?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
    customClass: {
      container: 'my-swal-delete'
    },
  }).then(async (result) => {
    if (result.isConfirmed) {

      const userData = new datos('categorias');
      let data = {
          id: id
      }
      const response = await userData.delete(data);

      if (response.status === "true") {
        
          status = true;
          await Swal.fire(
            "Eliminado!",
            "La Categoria ha sido eliminada exitosamente.",
            "success"
            );
          }else{
              await Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: response.message
            });
          }
      }
  });
  return status;
};

import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { getCajas, getCajasPaginated } from "../helpers/CajasData";
import { CajasForm } from "./CajasFormMUI";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Tooltip, MenuItem } from "@mui/material";
import { Edit } from "@mui/icons-material";
import StyleIcon from "@mui/icons-material/Style";
import { imprimirEtiquetas } from "../helpers/EtiquetasData";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { ContenidosListM } from "./ContenidosListM";
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryIcon from "@mui/icons-material/History";
import { getContenidosSearchHistorico } from "../helpers/HistoricoCajasData";
import { MovimientosTimelineMUI } from "./MovimientosTimelineMUI";
import esArray from "src/general_components/functionEsArray";
import { estadoColores, estadoIconos } from "./estadosCajas";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";

export const CajasListM = (data = null) => {
  const { sectorId,
    altaGenerica,
    snackbarVisible,
    message,
    severity, setSeverity,
    setMessage,
    setSnackbarVisible, clienteName, contenidosList, modalState, setModalState,
    setIdsCajasCreadas,
    idsCajasCreadas,
    setDisabledButtonsCopy
  } = useContext(CajasContext);
  const [modalListCont, setModalListCont] = useState(false);
  const [labelModal, setLabelModal] = useState("Alta de Cajas");
  const [cajaEdit, setCajaEdit] = useState(undefined);
  const [updateList, setUpdateList] = useState(false);
  const [params, setParams] = useState(false);
  const [filtersPaginated, setFiltersPaginated] = useState({
    sectorId: sectorId.id,
    altaGenerica: altaGenerica,
    altaContenidosGenericas: data.procesoGenerica,
    clienteName: clienteName,
    cajasIds: ''
  });
  var columns = []
  const [modalStateHistorico, setMOdalStateHistorico] = useState(false);
  const [historicoCaja, setHistoricoCaja] = useState(false);
  const [hideDisabled, setHideDisabled] = useState(true);

  /*Almacena los detalles del dispositivo del usuario en una variable*/
  let details = navigator.userAgent;

  /*Creamos una expresión regular que contiene algunas palabras clave del dispositivo móvil
  para buscarlo en la cadena details*/
  let regexp = /android|iphone|kindle|ipad/i;

  /*Usamos el método test() para buscar expresiones regulares en details
  devolviendo un valor booleano*/
  let isMobileDevice = regexp.test(details);

  if (isMobileDevice) {
    columns = [
      { field: "cliente", headerName: "Cliente", flex: 150, minWidth: 150, maxWidth: 175 },
      { field: "sector", headerName: "Sector", flex: 150, minWidth: 150, maxWidth: 175 },
      { field: "caja", headerName: "Caja", flex: 150, minWidth: 150, maxWidth: 175 },
      { field: "estado", headerName: "Estado", width: 100, minWidth: 100, maxWidth: 125 },

    ];
  } else {
    columns = [

      {
        field: "cliente",
        headerName: "Cliente",
        headerAlign: "left",
        flex: 100,
        maxWidth: 200,
        minWidth: 50,
      },
      {
        field: "sector",
        headerName: "Sector",
        headerAlign: "left",
        flex: 2,
        maxWidth: 200,
        minWidth: 50,
      },
      { field: "caja", headerName: "Caja", flex: 0.5 },
      {
        field: "ubicacion",
        headerName: "Ubicacion",
        headerAlign: "left",
        flex: 1,
        maxWidth: 350,
        minWidth: 175,
      },
      {
        field: "tipo_cajas",
        headerName: "Tipo de Caja",
        headerAlign: "left",
        flex: 1,
        maxWidth: 350,
        minWidth: 175,
      },
      {
        field: "estado",
        headerName: "Estado",
        headerAlign: "left",
        flex: 2,
        maxWidth: 150,
        minWidth: 100,
        renderCell: (params) => {
          if (params.value) {
            const color = estadoColores[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
            const icono = estadoIconos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
            return (
              <Grid>
                <Button
                  size="small"  // Tamaño más pequeño
                  variant="text"
                  style={{

                    color: color,

                  }}
                  disabled  // Desactiva la interacción del botón
                  startIcon={icono}  // Icono en el inicio del botón
                >
                  {params.value}
                </Button>
              </Grid>
            );
          } else {
            return null;
          }
        },
      },

    ];
  }

  useEffect(() => {
    if (sectorId !== "" && !Array.isArray(sectorId) && sectorId !== null) {
      setFiltersPaginated({ ...filtersPaginated, sectorId: sectorId.id, clienteName: clienteName })
    } else {
      setUpdateList(!updateList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorId]);


  // Setea los filtros para la consulta en caso de que el switch de alta generica se active
  useEffect(() => {
    setFiltersPaginated({ ...filtersPaginated, altaGenerica: altaGenerica })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [altaGenerica]);

  const onClickUpdateList = () => {
    setUpdateList(!updateList);
  }

  useEffect(() => {
    if (sectorId !== "" && !Array.isArray(sectorId) && sectorId !== null) {
      setOptions({
        ...options,
        idElementData: filtersPaginated,
        sinFilas: false,
      });
    } else {
      setOptions({
        ...options,
        sinFilas: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersPaginated, updateList]);

  //Funcion para el boton imprimir
  const onClickEtiqueta = () => {
    handleCloseMenu()
    const URI_aux = imprimirEtiquetas(params.id);
    if (URI_aux) window.open(URI_aux, "_blank");
  }

  //NOTE REDECLARO LAS COLUMNAS DE ACCIONES DEL LISTTABLE
  const actionColumn = {
    field: "actions",
    headerName: "Acciones",
    type: "actions",
    width: 150,
    minWidth: 150,
    maxWidth: 175,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Tooltip title="Acciones">
            <span>
              <MoreVertIcon color="info" />
            </span>
          </Tooltip>
        }
        onClick={(e) => {
          handleOpenMenu(e);
          setParams(params)
        }}
        label="Acciones"
        {...((altaGenerica && !data.options.procesoGenerica) || params.id === "cliente0" || params.id === "sector1"
          ? { hidden: true }
          : {})}
      />,
      <GridActionsCellItem
        {...(params.id.includes("cliente") || params.id.includes("sector")
          ? { hidden: true }
          : {})}
        icon={
          <Tooltip title="Historico">
            <span>
              <HistoryIcon color="info" />
            </span>
          </Tooltip>
        }
        onClick={() => {
          toggleModalHistorico(params.id);
        }}
        label="Cajas"
      />
    ],
  };

  const toggleModalHistorico = async (id) => {
    const response = await getContenidosSearchHistorico(id, "caja")

    if (response) {
      setHistoricoCaja(esArray(response.wsResponse.movimientos?.historico));
      toggleModalMovimientos();
    } else {

    }
    console.log("Response", response);
  };

  const toggleModalMovimientos = async () => {
    setMOdalStateHistorico(!modalStateHistorico);

  };

  const validarFormularioAltaModificar = (state, idElementData) => {
    let idSector = idElementData?.sectorId;

    setIdsCajasCreadas('')
    setDisabledButtonsCopy(false)
    if (idSector && idSector !== "" && !Array.isArray(idSector) && idSector !== null) {
      setHideDisabled(true);
      setModalState(state)
    } else {
      setHideDisabled(false);
      setSnackbarVisible(true);
      setMessage("Debe seleccionar un cliente y un Sector para realizar el alta de cajas");
      setSeverity('warning');

    }


  };


  const [options, setOptions] = useState({
    cols: columns,
    get: getCajas,
    getPaginated: getCajasPaginated,
    delete: "deleteMetadatos",
    idElementData: filtersPaginated,
    pageSize: [50, 100, 150, 200],
    edit: true,
    limit: 50,
    sinFilas: true,
    filter: true,
    exportData: true,
    notas: true,
    server: true,
    actionColumnCustom: actionColumn,
    hierarchyOn: true,
    detailsOn: true,
    checkboxContenidos: "false",

    //DATOS PARA EL FORMULARIO
    createButton: modalState,
    setCreateButton: validarFormularioAltaModificar,
    setDataEdit: setCajaEdit,
    hiddenButtonAgregar: data.proceso === 'contenidos' ? 'true' : 'false'
  });



  const toggleModal = () => {
    setModalState(!modalState);
    setModalListCont(false);
    setCajaEdit(undefined)

    if (idsCajasCreadas) {
      const cajasIds = idsCajasCreadas.join(', ');
      setFiltersPaginated({ ...filtersPaginated, sectorId: sectorId.id, clienteName: clienteName, cajasIds: cajasIds })
    }
  };

  const toggleModalEdit = () => {
    setHideDisabled(false);
    setModalState(!modalState);
    setModalListCont(false);
  };

  // console.log("probando",data.proceso)

  // Parametros Popover
  const [open, setOpen] = useState(null); // variable popover
  // eslint-disable-next-line no-unused-vars
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEdit = () => {
    setCajaEdit(params.row)
    setLabelModal("Modificacion de Caja " + params.id);
    toggleModalEdit()
    handleCloseMenu()
  };


  return (
    <>
      {modalStateHistorico && (
        <SimpleDialogComponent
          open={modalStateHistorico}
          modalName={"Historico"}
          child={<MovimientosTimelineMUI data={historicoCaja} />}
          setPushData={toggleModalMovimientos}
          handleClose={toggleModalMovimientos}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}
      <ListTablePaginated options={options} />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      > <MenuItem onClick={handleEdit}>
          <Edit color="info" sx={{ mr: 2 }} />
          Editar
        </MenuItem>

        <MenuItem onClick={onClickEtiqueta}>
          <StyleIcon color="info" sx={{ mr: 2 }} />
          Imprimir
        </MenuItem>
      </Popover>

      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => { setSnackbarVisible(false) }}
        hide={8000}
        hideDisabled={hideDisabled}
      />
      {modalListCont === false && (
        <SimpleDialogComponent
          open={modalState}
          modalName={labelModal}
          child={<CajasForm data={cajaEdit} updateList={onClickUpdateList} toggleModal={toggleModal}></CajasForm>}
          setPushData={toggleModal}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}
      {modalListCont === true && (
        <SimpleDialogComponent
          open={modalState}
          modalName={labelModal}
          child={
            <ContenidosListM
              props={contenidosList}
              id={cajaEdit}
            />
          }
          setPushData={toggleModal}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}
    </>);
}

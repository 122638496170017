export const schemaUbicacionCajas = {
  type: "object",
  properties: {

    idDep: {
      type: "number",
      customRender: "SelectorDeposito",
      description: "Seleccione perfil",
    },
    idSector: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 1,

    },
    idModulo: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 1,

    },
    idEstante: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 1,

    },
    idPosicion: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 1,
    },
    idEstiba: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 1,
    },
    idProfundidad: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 1,
    },
    ubicacionTemporal: {
      type: "string",
      description: "Ingresar una ubicacion Temporal",
    },
    id_tipo_ubicaciones: {
      type: "number",
      customRender: "SelectorTipoUbicaciones",
      description: "Seleccione tipo ubicaciones",
    },
  },
  required: [

  ]
};
export const uiSchemaCuentasJuridicasForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/razon_social",
        },
        {
          type: "Control",
          scope: "#/properties/selectedCategoriaCuentas",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/nombre_fantasia",
        },
        {
          type: "Control",
          scope: "#/properties/selectedTipoDocumento",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/numero",
        },
        {
          type: "Control",
          scope: "#/properties/selectedPerfilFiscal",
        }
      ],
    }

  ],
};

export const uiSchemaCuentasFisicasForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/apellido",
        },
        {
          type: "Control",
          scope: "#/properties/selectedCategoriaCuentas",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/nombre",
        },
        {
          type: "Control",
          scope: "#/properties/selectedTipoDocumento",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/numero",
        },
        {
          type: "Control",
          scope: "#/properties/selectedPerfilFiscal",
        }
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/id_genero",
        },
        {
          type: "Control",
          scope: "#/properties/fecha_nacimiento",
        },
        {
          type: "Control",
          scope: "#/properties/id_estado_civil",
        },
      ],
    },

  ],
};


import React, { useContext, useEffect, useState } from "react";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import SearchIcon from "@mui/icons-material/Search";
import { SearchFormGeneral } from "./SearchFormGeneral";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { getProcesosGeneralesContenidosSearchPaginated } from "../helpers/ContenidosData";
import { SnackbarMessenger } from "../../../general_components/SnackbarMessenger";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import Divider from "@mui/material/Divider";
import { TheLayoutContext } from "src/context/TheLayoutContext";
import { SwitchAjuste } from "./SwitchAjuste";
import {
  movementContenidos,
} from "../helpers/ContenidosData";
import { SelectRetiroPrioridad } from "./SelectRetiroPrioridad";
import { ContenidosFormProcesosVarios } from "./ContenidosFormProcesosVarios";
import { colorMarcadoContenidos, estadoColoresContenidos, estadoIconosContenidos, iconosMarcadoContenidosList } from "./estadosCajas";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AWS from 'aws-sdk';
import { configS3 } from "src/configs/config";
import { Typography } from "@mui/material";

export const ContenidosProcesoList = (datos) => {

  const {
    retiro_contenido_parcial,
    retiro_contenido_total,
    quita_total_contenidos,
    devolucion_contenidos_parcial,
    procesoSeleccionado,
    devolucion_contenidos_total,
    tituloProceso,
    consulta_general,
    changeProceso
  } = datos.datos;

  const {
    dataSearchFormGeneral, snackbarVisible, message, severity, setOmitirQuerySearch, setAProcesar,
    setListRecords, setSnackbarVisible, setResponseSearchFormGeneral, setSoloCajas, limpiarSearchForm,
    actualizarListado, setActualizarListado, setSelectionModel,
    tipoRetiroSeleccionado, selectionModel, setClienteId, setSectorId, setTipoRetiroSeleccionado, setTipoContenidoSeleccionado,
    disabled, setListDatosEstadosCajas, setDisabled, setMessage, setSeverity, setRequiredFormCuentaSector,
    setRequiredFormEstadosCajas, setActualizarListadoContenidos, setBusquedaSearchOn, setTipoRetiroMovimientoSeleccionado,
    tipoRetiroMovimientoSeleccionado, setDataSearchFormGeneral, desmarcar,
  } = useContext(CajasContext);

  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const [pushData, setPushData] = useState(false);
  const { setChildFooter, setExpand, subProcesoLocal, clienteIdLocal, s3Json, subProceso, setS3Json } = useContext(TheLayoutContext);
  const [ajuste, setAjuste] = useState(false);
  const [abrirDetalle, setAbrirDetalle] = useState(false);


  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };

  /// Ejecuta las funciones que consumen el servicio search y cierra el modal de busqueda
  const ObtenerDatos = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };


  //Se asignan valores a localstorage para utilizarlos en renderIconBasedOnId ya que no los toma si no se hace de esta forma
  localStorage.setItem("siam-procesoSeleccionado", JSON.stringify(procesoSeleccionado));
  localStorage.setItem("siam-subProcesoSeleccionado", JSON.stringify(subProceso));

  const renderIconBasedOnId = (idrow) => {
    // Obtener el ID del usuario actual desde el almacenamiento local
    const userId = JSON.parse(localStorage.getItem("siam-user-data")).id;

    // Obtener los datos del almacenamiento local y parsearlos si existen
    const localStorageData = localStorage.getItem("siam-datos3");
    let data = localStorageData ? JSON.parse(localStorageData) : undefined;

    // Objeto para almacenar los IDs por proceso
    const idsPorProceso = {};

    // Verificar si existen datos en el almacenamiento local
    if (data) {
      // Iterar sobre los procesos y usuarios en los datos
      for (const proceso in data) {
        for (const usuario in data[proceso]) {
          // Verificar si el usuario actual es el usuario deseado (userId)
          if (usuario === userId) {
            // Inicializar el array de IDs para el proceso actual si aún no existe
            if (!idsPorProceso[proceso]) {
              idsPorProceso[proceso] = [];
            }

            // Iterar sobre los subprocesos y clientes en los datos
            for (const subProcesos in data[proceso][usuario]) {
              for (const cliente in data[proceso][usuario][subProcesos]) {
                // Obtener los IDs y agregarlos al array de IDs para el proceso actual
                const ids = data[proceso][usuario][subProcesos][cliente].id;
                if (ids && Array.isArray(ids)) {
                  idsPorProceso[proceso] = [...idsPorProceso[proceso], ...(ids[0] || [])];
                }
              }
            }
          }
        }
      }

      // Mapeo de procesos a colores
      const procesoColorMapping = {
        devolucion_caja: 'devolucion_caja',
        devolucion_cajas: 'devolucion_cajas',
        retiro_cajas: 'retiro_cajas',
        retiro_contenidos: 'retiro_contenidos',
        mudanza_cajas: 'mudanza_cajas',
        ubicacion_cajas: 'ubicacion_cajas',
        devolucion_contenidos: 'devolucion_contenidos',
        ubicacion_temporal: 'ubicacion_temporal',
        retiro_definitivo: 'retiro_definitivo',
        baja_expurgue: 'baja_expurgue',
        retiro_contenido_parcial: 'retiro_contenido_parcial',
        retiro_contenido_total: 'retiro_contenido_total',
        quita_total_contenidos: 'quita_total_contenidos',
        devolucion_contenidos_parcial: 'devolucion_contenidos_parcial',
        devolucion_contenidos_total: 'devolucion_contenidos_total',
      };

      // Obtener el color seleccionado
      let selectedColor = obtenerColoresPorProceso(idsPorProceso, idrow, procesoColorMapping);
      return selectedColor;
    }

    // Retornar null si no hay datos en el almacenamiento local
    return null;
  };

  const convertirTexto = (texto) => {
    // Dividir el texto en palabras usando el guion bajo como delimitador
    const palabras = texto.split('_');
    // Convertir la primera letra de cada palabra a mayúscula
    const palabrasMayusculas = palabras.map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1));
    // Unir las palabras con espacios
    const textoConvertido = palabrasMayusculas.join(' ');
    return textoConvertido;
  }

  const obtenerColoresPorProceso = (idsPorProceso, idrow, procesoColorMapping) => {
    const colores = [];
    for (const proceso in idsPorProceso) {
      if (idsPorProceso.hasOwnProperty(proceso)) {
        const procesoIds = idsPorProceso[proceso];
        if (procesoIds && procesoIds.some(ids => ids && ids.includes && ids.includes(idrow.toString()))) {
          const selectedColor = procesoColorMapping[proceso];
          const title = convertirTexto(proceso);
          colores.push({ title, color: selectedColor, proceso: proceso });
        }
      }
    }
    return colores;
  }




  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);

  const listName = false;
  let proceso = "InformeGeneralCajas";
  const columns = [
    {
      field: "id_caja",
      headerName: "Caja",
      headerAlign: "left",
      flex: 0.5,
      renderCell: (params) => {
        const procesosYColores = renderIconBasedOnId(params.id); // Icono predeterminado si el estado no tiene un icono definido
        // console.log(params, 'params')
        if (params.value) {
          return (
            <Grid container alignItems="center">
              {procesosYColores && procesosYColores.map((item, index) => {
                const color = colorMarcadoContenidos[item.proceso] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
                const Icono = iconosMarcadoContenidosList[item.proceso] || null; // Obtiene el icono dinámicamente
                return (
                  <Tooltip key={index} title={`Marcada en ${item.title}`}>
                    <span>
                      {Icono && (
                        <Icono size="small" style={{ color: color, fontSize: 20 }} />
                      )}
                    </span>
                  </Tooltip>
                );
              })}
              <Typography variant="body1" style={{ fontSize: 13, fontFamily: 'Roboto, sans-serif', color: 'black' }}>
                {params.value}
              </Typography>
            </Grid>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "id_sector",
      headerName: "Sector",
      headerAlign: "left",
      flex: 0.5,
    },
    {
      field: "estado_contenido",
      headerName: "Estado",
      headerAlign: "left",
      flex: 0.5,
      renderCell: (params) => {
        if (params.value) {
          const color = estadoColoresContenidos[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
          const icono = estadoIconosContenidos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
          return (
            <Grid sx={{}}>

              <Tooltip title={params.value}>
                <IconButton style={{ color: color }}>
                  {icono}
                </IconButton>

              </Tooltip>
            </Grid>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "ubicacion_caja",
      headerName: "Ubicacion",
      headerAlign: "left",
      flex: 1,
    },
    {
      field: "numero",
      headerName: "Numero",
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        // Separar el string usando "|"
        const partes = params.value ? params.value.split(" | ") : "";

        // partes contendrá un array con dos elementos
        const metadato_label_form = partes !== "" ? partes[0].trim() : ""; // Elimina espacios en blanco alrededor
        const metadato_valor = partes !== "" ? partes[1].trim() : "";

        return (
          <div>
            {partes !== "" && (<><strong>{metadato_label_form}</strong>: {metadato_valor} </>)}
          </div>
        );
      },
    },
    {
      field: "numero_desde",
      headerName: "Num Desde",
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        // Separar el string usando "|"
        const partes = params.value ? params.value.split(" | ") : "";

        // partes contendrá un array con dos elementos
        const metadato_label_form = partes !== "" ? partes[0].trim() : ""; // Elimina espacios en blanco alrededor
        const metadato_valor = partes !== "" ? partes[1].trim() : "";

        return (
          <div>
            {partes !== "" && (<><strong>{metadato_label_form}</strong>: {metadato_valor} </>)}
          </div>
        );
      },
    },
    {
      field: "numero_hasta",
      headerName: "Num Hasta",
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        // Separar el string usando "|"
        const partes = params.value ? params.value.split(" | ") : "";

        // partes contendrá un array con dos elementos
        const metadato_label_form = partes !== "" ? partes[0].trim() : ""; // Elimina espacios en blanco alrededor
        const metadato_valor = partes !== "" ? partes[1].trim() : "";

        return (
          <div>
            {partes !== "" && (<><strong>{metadato_label_form}</strong>: {metadato_valor} </>)}
          </div>
        );
      },
    },
    {
      field: "fecha",
      headerName: "Fecha",
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        // Separar el string usando "|"
        const partes = params.value ? params.value.split(" | ") : "";

        // partes contendrá un array con dos elementos
        const metadato_label_form = partes !== "" ? partes[0].trim() : ""; // Elimina espacios en blanco alrededor
        const metadato_valor = partes !== "" ? partes[1].trim() : "";

        return (
          <div>
            {partes !== "" && (<><strong>{metadato_label_form}</strong>: {metadato_valor} </>)}
          </div>
        );
      },
    },
    {
      field: "fecha_desde",
      headerName: "Fecha Desde",
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        // Separar el string usando "|"
        const partes = params.value ? params.value.split(" | ") : "";

        // partes contendrá un array con dos elementos
        const metadato_label_form = partes !== "" ? partes[0].trim() : ""; // Elimina espacios en blanco alrededor
        const metadato_valor = partes !== "" ? partes[1].trim() : "";

        return (
          <div>
            {partes !== "" && (<><strong>{metadato_label_form}</strong>: {metadato_valor} </>)}
          </div>
        );
      },
    },
    {
      field: "fecha_hasta",
      headerName: "Fecha Hasta",
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        // Separar el string usando "|"
        const partes = params.value ? params.value.split(" | ") : "";

        // partes contendrá un array con dos elementos
        const metadato_label_form = partes !== "" ? partes[0].trim() : ""; // Elimina espacios en blanco alrededor
        const metadato_valor = partes !== "" ? partes[1].trim() : "";

        return (
          <div>
            {partes !== "" && (<><strong>{metadato_label_form}</strong>: {metadato_valor} </>)}
          </div>
        );
      },
    },
    {
      field: "texto",
      headerName: "Texto",
      headerAlign: "left",
      flex: 2,
      renderCell: (params) => {
        // Separar el string usando "|"
        const partes = params.value ? params.value.split(" | ") : "";

        // partes contendrá un array con dos elementos
        const metadato_label_form = partes !== "" ? partes[0].trim() : ""; // Elimina espacios en blanco alrededor
        const metadato_valor = partes !== "" ? partes[1].trim() : "";

        return (
          <div>
            {partes !== "" && (<><strong>{metadato_label_form}</strong>: {metadato_valor} </>)}
          </div>
        );
      },
    },
    {
      field: "texto_hasta",
      headerName: "Texto Hasta",
      headerAlign: "left",
      flex: 2,
      renderCell: (params) => {
        // Separar el string usando "|"
        const partes = params.value ? params.value.split(" | ") : "";

        // partes contendrá un array con dos elementos
        const metadato_label_form = partes !== "" ? partes[0].trim() : ""; // Elimina espacios en blanco alrededor
        const metadato_valor = partes !== "" ? partes[1].trim() : "";

        return (
          <div>
            {partes !== "" && (<><strong>{metadato_label_form}</strong>: {metadato_valor} </>)}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (changeProceso) {
      limpiarSearchForm();
      setOptions({
        ...options,
        selectCheckbox: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : true,
        valuesSelected: procesoSeleccionado?.id === 1 || procesoSeleccionado === null ? false : setSelectionModel,
        sinFilas: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procesoSeleccionado]);

  const handleDesmarcar = () => {
    // Lógica para desmarcar las cajas si el switch está activo
    if (desmarcar) {
      desmarcarChecks()
    }
  };


  const obtenerProceso = () => {
    let proceso = '';

    if (procesoSeleccionado.label !== 'Consulta General') {
      const palabra = procesoSeleccionado.label.split(/[ /]/);
      // Usamos una expresión regular para dividir por espacio o barra
      const filtro = palabra.filter(word => word.toLowerCase() !== 'de');
      proceso = filtro.join('_').toLowerCase();
    } else {
      let url = window.location.href;
      const arrUrl = url.split("/");
      proceso = arrUrl[arrUrl.length - 1];
      if (proceso.includes("?")) {

        const arrUrl = url.split("?");
        proceso = arrUrl[arrUrl.length - 1];

        const arrUrls = proceso.split("=");
        proceso = arrUrls[arrUrls.length - 2];

      } else {
        proceso = proceso === 'proceso' ? 'ubicacion_cajas' : proceso
      }
    }
    return proceso
  }



  const desmarcarChecks = async () => {
    let marcasAWS = { procesos: s3Json }

    let procesoActual = obtenerProceso();
    let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;


    if (procesoActual === 'devolucion') {
      //   tipo_id = 'id_contenidos'
      procesoActual = 'devolucion_contenidos'
    } else if (procesoActual === 'retiro') {
      //  tipo_id = 'id_contenidos'
      procesoActual = 'retiro_contenidos'
    }

    if (!marcasAWS) {
      marcasAWS = {}
    }

    // const cantidades = Object.keys(marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal]).reduce((total, key) => {
    //   return total + Object.keys(marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal][key]).length;
    // }, 0);

    const cantidades = Object.keys(marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal] ?? {}).reduce((total, key) => {
      const nestedObject = marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal]?.[key];

      if (nestedObject && typeof nestedObject === 'object') {
        total += Object.keys(nestedObject).length;
      }

      return total;
    }, 0);

    if (cantidades > 1) {
      delete marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal]?.[clienteIdLocal.id_cuentas];
    } else {
      delete marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoLocal];
    }

    AWS.config.update(configS3);
    const s3 = new AWS.S3();

    const jsonString = JSON.stringify(marcasAWS, null, 2);
    const file = new Blob([jsonString], { type: 'application/json' });

    const bucketName = configS3.bucketName;
    const ownerCode = JSON.parse(localStorage.getItem("clientData")).owner_code.toLowerCase();
    const key = ownerCode + '-marks.json';

    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
      ContentType: 'application/json',
    };

    try {
      await s3.upload(params).promise();
      localStorage.setItem("siam-datos3", JSON.stringify(marcasAWS.procesos));
      setS3Json(marcasAWS.procesos)
    } catch (error) {
      console.log("¡Error el proceso de Desmarcado no fue realizado con éxito!");
    }


  };


  /// Despliega el buscador cuando se presiona F2
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setOmitirQuerySearch(true);
        setActualizarListado(true);
        setAbrirBusqueda(!abrirBusqueda);
        setRequiredFormCuentaSector(true);
        setRequiredFormEstadosCajas(true);
        setListDatosEstadosCajas([
          { id: "6", detalle: "Alojada" },
          { id: "7", detalle: "Alojada Temporalmente" },
          { id: "11", detalle: "Alojada - Esperando devolución" },
        ]);
        setSoloCajas(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  const recargarPaginaProcesados = (registrosProcesados) => {

    const arrayIndexado = registrosProcesados.map(item => item.id);
    // const cadenaFormateada = registrosProcesados.join(',');
    const cadenaFormateada = arrayIndexado.join(',');

    const data = {
      coincidencias: '1',
      eliminados: '0',
      search_params: {
        cajas: {
          fisica: '1',
        },
        id_contenidos: cadenaFormateada,

      },
      soloCajas: '1'
    };
    setDataSearchFormGeneral(data);
    setBusquedaSearchOn(true);
  }

  const procesar = async (detalle, contactoId, direccionId, nodoExtra) => {
    setSnackbarVisible(false);

    let registrosFormateados = selectionModel.map((data) => {
      return { id: data };
    });

    let ret_total = retiro_contenido_total;
    let ret_partial = retiro_contenido_parcial;
    let dev_partial = devolucion_contenidos_parcial;
    let dev_total = devolucion_contenidos_total;
    let quita_total = quita_total_contenidos;
    let ret_preferencial = "0";
    let ret_urgente = "0";

    //Paranetros de prioridad
    if (ret_total === "1" || ret_partial === "1") {
      switch (tipoRetiroMovimientoSeleccionado) {
        case "1":
          break;
        case "2":
          ret_preferencial = "1";
          break;
        case "3":
          ret_urgente = "1";
          break;
        default:
          console.log("NO hay coincidencia");
      }
    }



    let valueAjuste = ajuste ? "1" : "0";
    let data = {
      detalle: detalle,
      ret_partial: ret_partial,
      ret_total: ret_total,
      dev_total: dev_total,
      ret_preferencial: ret_preferencial,
      ret_urgente: ret_urgente,
      dev_partial: dev_partial,
      quita_total: quita_total,
      contenidos: registrosFormateados,
      ajuste: valueAjuste,
      contacto: contactoId,
      direccion: direccionId,

    };

    if (nodoExtra) {
      // Agrega las propiedades adicionales a la estructura propiedades
      data.comprobantes_extra_data = nodoExtra.comprobantes_extra_data;
    }

    // var responseMovement = false
    let responseMovement = await movementContenidos(data);

    setAbrirDetalle(!abrirDetalle);
    if (responseMovement.status === "true") {
      recargarPaginaProcesados(registrosFormateados)
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage(responseMovement.message);
      limpiar();
      handleDesmarcar()
      // limpiarSearchForm();
      setDisabled(!disabled);
      setActualizarListado(true);
      setBusquedaSearchOn(true);
      setActualizarListadoContenidos(true);
    } else {
      setAProcesar(null);
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage(responseMovement.message);

    }
  };

  const limpiar = async () => {
    setSectorId([]);
    setClienteId([]);
    setTipoContenidoSeleccionado("");
    setTipoRetiroSeleccionado("");
    setChildFooter(null);
    setExpand(false);
    setSelectionModel([]);
    setActualizarListado(true);
  };

  const buttonBuscar = (params) => (
    <Button
      endIcon={<SearchIcon />}
      onClick={() => {
        // setAbrirBusqueda(!abrirBusqueda);
        // setActualizarListado(true);
        // setOmitirQuerySearch(true);
        // setSoloCajas(true);

        if (params?.procesoSeleccionado?.id !== 1) {
          if (params?.subProceso && params?.subProceso !== 0) {
            setAbrirBusqueda(!abrirBusqueda);
            setOmitirQuerySearch(true);
            setSoloCajas(true);
          } else {
            setMessage("Debe seleccionar un subproceso para poder continuar");
            setSeverity("warning");
            setSnackbarVisible(true);
          }
        } else {
          setAbrirBusqueda(!abrirBusqueda);
          setOmitirQuerySearch(true);
          setSoloCajas(true);
        }
      }}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
      }}
    >
      Buscar
    </Button>
  );


  const [options, setOptions] = useState({
    cols: columns,
    getPaginated: getProcesosGeneralesContenidosSearchPaginated,
    setListRecords: setListRecords,
    pageSize: [5, 10, 25],
    listName,
    limit: 50,
    filter: true,
    exportData: true,
    server: false,
    sinFilas: true,
    idElementData: dataSearchFormGeneral,
    hierarchyOn: false,
    detailsOn: false,
    abmContenidos: "false",
    checkboxContenidos: "false",
    setResponseService: setResponseSearchFormGeneral,
    soloContenidosSearch: true,
    exportarCajasContenidos: false,
    notas: false,
    totales: true,
    buttonExtraOn: true,
    ButtonExtra: buttonBuscar,
    ButtonExtraMarcar: true,
    ButtonExtraDesmarcar: true,
    marcadoYdesmarcado: true
  });

  useEffect(() => {
    if (!Array.isArray(dataSearchFormGeneral) && actualizarListado) {
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSearchFormGeneral, actualizarListado]);

  if (pushData) {
    if (abrirBusqueda) {
      //CIERRO EL DIALOGO DE BUSQUEDA
      setAbrirBusqueda(!abrirBusqueda);
      //SETEO EL ESTADO PARA QUE SE ACTUALICE EL LISTADO
      setActualizarListado(true);
    }
    setPushData(!pushData);
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setAbrirBusqueda(!abrirBusqueda);
        setActualizarListado(true);
        setOmitirQuerySearch(true);
        setSoloCajas(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectionModel.length > 0) {
      setExpand(true);
      setChildFooter(
        <Grid container spacing={0} alignItems="center" justifyContent="flex-end" style={{ display: 'flex' }}>
          <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>
            <SwitchAjuste
              setAjuste={setearAjuste}
              disabledCheckAjuste={selectionModel.length > 0 ? false : true}
            />
          </Grid>
          {(retiro_contenido_parcial === "1" || retiro_contenido_total === "1" || quita_total_contenidos === "1") && (
            <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>

              <SelectRetiroPrioridad
                disabledSelector={
                  !ajuste &&
                    (retiro_contenido_parcial === "1" ||
                      retiro_contenido_total === "1")
                    ? false
                    : true
                }
                setTipoRetiroMovimientoSeleccionado={
                  setTipoRetiroMovimientoSeleccionado
                }
                tipoRetiroMovimientoSeleccionado={
                  tipoRetiroMovimientoSeleccionado
                }
              />
            </Grid>
          )}


          <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>
            <Button
              disabled={
                selectionModel.length > 0 && consulta_general !== "1"
                  ? false
                  : true
              }
              variant="contained"
              onClick={() => {
                setAbrirDetalle(!abrirDetalle);
              }}
              style={{
                width: "98%",
                bottom: "6px",
              }}
            >
              Procesar
            </Button>
          </Grid>

        </Grid>
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectionModel,
    tipoRetiroSeleccionado,
    ajuste,
    tipoRetiroMovimientoSeleccionado,
  ]);

  const setearAjuste = async (data) => {
    setAjuste(data);
  };

  const toggleModalProcesar = async () => {
    setAbrirDetalle(!abrirDetalle);
  };

  return (
    <>
      {abrirBusqueda && (
        <SimpleDialogComponent
          open={abrirBusqueda}
          modalName={"Busqueda de contenidos"}
          child={
            <SearchFormGeneral toggleModal={toggleModal} proceso={proceso} />
          }
          setPushData={ObtenerDatos}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}

      {abrirDetalle && (
        <SimpleDialogComponent
          open={abrirDetalle}
          modalName={"Procesar - Proceso: " + tituloProceso}
          child={<ContenidosFormProcesosVarios
            ajuste={ajuste} toggleModal={toggleModalProcesar} proceso={procesoSeleccionado} procesar={procesar}
            registrosSeleccionados={selectionModel} />}
          setPushData={procesar}
          handleClose={toggleModalProcesar}
          buttonEnviar={false}
          buttonCancelar={false}
        />
      )}

      <Divider />
      <Grid>
        <ListTablePaginated options={options} />
      </Grid>

      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </>
  );
};

import React, { useRef, useEffect, memo } from 'react';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

// Estilos personalizados para las celdas
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '5px',
    fontSize: '0.75rem',
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.components.MuiDataGrid.styleOverrides.columnHeaders.background,
        color: "#637381",
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    width: '50%', // Ajusta el ancho de las celdas aquí
}));

const TablaPropiedadesComprobantesForm = ({ propiedadesTable, setPropiedadesTable, importe, importePorPropiedad }) => {

    // Función para manejar el cambio en el importe
    const handleImporteChange = (index) => (event) => {
        const newData = [...propiedadesTable];
        newData[index].importe = event.target.value;
        setPropiedadesTable(newData);
    };

    const estilosFilas = { fontSize: '0.85rem', fontFamily: 'Calibri, sans-serif' };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Propiedades</StyledTableCell>
                        <StyledTableCell>Importe</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {propiedadesTable.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography variant="body1" align="center" style={estilosFilas}>
                                    No seleccionó ninguna propiedad
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) : (
                        propiedadesTable.map((row, index) => (
                            <MemoizedTableRow
                                key={index}
                                index={index}
                                row={row}
                                handleImporteChange={handleImporteChange}
                                estilosFilas={estilosFilas}
                            />
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const MemoizedTableRow = memo(({ index, row, handleImporteChange, estilosFilas }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }

        // Limpieza del inputRef cuando el componente se desmonta
        return () => {
            if (inputRef.current) {
                inputRef.current.blur();
                inputRef.current = null;
            }
        };
    }, [row.importe]);

    return (
        <TableRow>
            <StyledTableCell style={{ width: "90%", ...estilosFilas }}>{row.propiedad}</StyledTableCell>
            <StyledTableCell style={{ width: "10%", ...estilosFilas }}>
                <FormControl fullWidth>
                    <OutlinedInput
                        id={`importe-${index}`}
                        size="small"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        type="number"
                        value={row.importe}
                        onChange={handleImporteChange(index)}
                        inputProps={{ step: '0.01' }} // Limita a dos decimales
                        inputRef={inputRef}
                    />
                </FormControl>
            </StyledTableCell>
        </TableRow>
    );
});

export default TablaPropiedadesComprobantesForm;

export const schema = {
  type: "object",
  properties: {
    idDep: {
      type: "number",
      customRender: "SelectorDeposito",
      description: "Ingresar una coordenada",

    },
    idSector: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
    },
    idModulo: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
    },
    idEstante: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
    },
    idPosicion: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
    },
    idEstiba: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
    },
    idProfundidad: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
    },
    cantDep: {
      type: "integer",
      description: "Ingresar una cantidad",
      "minimum": 0,
    },
    cantSector: {
      type: "integer",
      description: "Ingresar una cantidad",
      "minimum": 0,
    },
    cantModulo: {
      type: "integer",
      description: "Ingresar una cantidad",
      "minimum": 0,
    },
    cantEstante: {
      type: "integer",
      description: "Ingresar una cantidad",
      "minimum": 0,
     
    },
    cantPosicion: {
      type: "integer",
      description: "Ingresar una cantidad",
      "minimum": 0,

    },
    cantEstiba: {
      type: "integer",
      description: "Ingresar una cantidad",
      "minimum": 0,
    },
    cantProfundidad: {
      type: "integer",
      description: "Ingresar una cantidad",
      "minimum": 0,
    },
    id_tipo_ubicaciones: {
      type: "number",
      customRender: "SelectorTipoUbicaciones",
      description: "Seleccione tipo ubicaciones",
    },
    id_estado_ubicaciones: {
      type: "number",
      customRender: "SelectorEstadoUbicaciones",
      description: "Seleccione estado ubicaciones",
    },
  },
  required: [
    
  ]
};
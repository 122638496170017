import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, Paper } from '@mui/material';
import esArray from 'src/general_components/functionEsArray';

const InformeDetalle = ({ data }) => {
    const formatDate = (date) => new Date(date).toLocaleDateString();
    const informe = data;

    const headerCellStyle = {
        backgroundColor: 'rgb(244, 246, 248)',
        color: '#637381',
        fontSize: 14,
        fontWeight: 1200,
        borderRadius: 0,
        verticalAlign: 'inherit',
        border: '1px dashed rgb(241, 243, 244)'
    };

    return (
        <Box m={2}>

            <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>Número</TableCell>
                            <TableCell>{informe.numero}</TableCell>
                            <TableCell sx={headerCellStyle}>Fecha</TableCell>
                            <TableCell>{formatDate(informe.fecha)}</TableCell>
                            <TableCell sx={headerCellStyle}>Estado</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>Cliente</TableCell>
                            <TableCell>{`${informe.cliente.id} - ${informe.cliente.nombre}`}</TableCell>
                            <TableCell sx={headerCellStyle}>Máquina</TableCell>
                            <TableCell>{`${informe.maquina.id} - ${informe.maquina.nombre}`}</TableCell>
                            <TableCell>
                                <Chip
                                    label={informe.estado || 'No Sincronizado'}
                                    color={!informe.estado ? 'warning' : 'primary'}
                                    size="small"
                                    style={{ fontSize: '0.75rem', height: '24px' }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>Detalle</TableCell>
                            <TableCell colSpan={8}>
                                {typeof informe.detalle === 'string'
                                    ? informe.detalle
                                    : informe.detalle && Object.keys(informe.detalle).length > 0
                                        ? JSON.stringify(informe.detalle)
                                        : 'Sin detalle'}
                            </TableCell>
                        </TableRow>
                        {Array.isArray(informe.observaciones) ? (
                            informe.observaciones.map((obs, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={headerCellStyle}>{`Observación ${index + 1}`}</TableCell>
                                    <TableCell colSpan={8}>{obs.observaciones}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell sx={headerCellStyle}>Observación</TableCell>
                                <TableCell colSpan={8}>{informe.observaciones?.observaciones}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h3" component="h3">Artículos</Typography>
            {esArray(informe.articulos).map((articulo, index) => (
                <Box key={index} mb={4}>
                    <Typography variant="h3" component="h3">{articulo.articulo.nombre}</Typography>
                    <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Valor</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Producción</TableCell>
                                    <TableCell>{articulo.produccion?.cantidadIngresada || articulo.produccion?.armado} unidades</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cajas Aceptadas</TableCell>
                                    <TableCell>
                                        {articulo.produccion?.cantidadIngresada
                                            ? articulo.produccion?.cantidadIngresada - articulo.mermas.total
                                            : articulo.produccion?.armado - articulo.mermas.total}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Fecha de Producción</TableCell>
                                    <TableCell>{formatDate(articulo.produccion.fecha)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Hotmelt</TableCell>
                                    <TableCell>{articulo.hotmelt}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant="h3" component="h3">Detenciones</Typography>
                    <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Grupo</TableCell>
                                    <TableCell>Detalle</TableCell>
                                    <TableCell>Horas</TableCell>
                                    <TableCell>Minutos</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(articulo.detenciones).map(grupo => (
                                    grupo !== 'total' && Object.keys(articulo.detenciones[grupo]).map(detalle => (
                                        articulo.detenciones[grupo][detalle].horas > 0 || articulo.detenciones[grupo][detalle].minutos > 0
                                            ? (
                                                <TableRow key={`${grupo}-${detalle}`}>
                                                    <TableCell>{grupo}</TableCell>
                                                    <TableCell>{detalle}</TableCell>
                                                    <TableCell>{articulo.detenciones[grupo][detalle].horas}</TableCell>
                                                    <TableCell>{articulo.detenciones[grupo][detalle].minutos}</TableCell>
                                                </TableRow>
                                            )
                                            : null
                                    ))
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: 'center' }}><strong>Total</strong></TableCell>
                                    <TableCell>{articulo.detenciones.total.total_horas}</TableCell>
                                    <TableCell>{articulo.detenciones.total.total_minutos}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant="h3" component="h3">Mermas</Typography>
                    <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Proceso</TableCell>
                                    <TableCell>Detalle</TableCell>
                                    <TableCell>Valor</TableCell>
                                    <TableCell>Imagen</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(articulo.mermas).map(proceso => (
                                    proceso !== 'total' && Object.keys(articulo.mermas[proceso]).map(detalle => (
                                        articulo.mermas[proceso][detalle].val > 0
                                            ? (
                                                <TableRow key={`${proceso}-${detalle}`}>
                                                    <TableCell>{proceso}</TableCell>
                                                    <TableCell>{detalle}</TableCell>
                                                    <TableCell>{articulo.mermas[proceso][detalle].val}</TableCell>
                                                    <TableCell className="centered">
                                                        {articulo.mermas[proceso][detalle].pict && typeof articulo.mermas[proceso][detalle].pict === 'string' && articulo.mermas[proceso][detalle].pict !== ""
                                                            ? (
                                                                <img
                                                                    src={articulo.mermas[proceso][detalle].pict}
                                                                    alt={`Imagen de ${detalle}`}
                                                                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                                />
                                                            )
                                                            : 'No hay imagen disponible'}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null
                                    ))
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: 'center' }}><strong>Total</strong></TableCell>
                                    <TableCell>{articulo.mermas.total}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant="h3" component="h3">Control Desgarre</Typography>
                    <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Valor</TableCell>
                                    <TableCell>Imagen</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {articulo.control_desgarre && (
                                    <TableRow>
                                        <TableCell>{articulo.control_desgarre.val}</TableCell>
                                        <TableCell className="centered">
                                            {articulo.control_desgarre.pict && articulo.control_desgarre.pict !== ""
                                                ? (
                                                    <img
                                                        src={articulo.control_desgarre.pict}
                                                        alt="Imagen de Control Desgarre"
                                                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                    />
                                                )
                                                : 'No hay imagen disponible'}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {!articulo.control_desgarre && (
                                    <TableRow>
                                        <TableCell className="centered" colSpan={2}>No se encontraron datos.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant="h3" component="h3">Fruta</Typography>
                    <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {articulo.fruta && (
                                    <TableRow>
                                        <TableCell>{articulo.fruta.nombre}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant="h3" component="h3">Tipo Armado</Typography>
                    <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {articulo.tipo_armado && (
                                    <TableRow>
                                        <TableCell>{articulo.tipo_armado.nombre}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>
            ))}

            <Typography variant="h3" component="h3">Totales Generales</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            <TableCell>Valor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Total Detenciones</TableCell>
                            <TableCell>{`${informe.total_detenciones.horas} horas ${informe.total_detenciones.minutos} minutos`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total Hotmelt</TableCell>
                            <TableCell>{informe.total_hotmelt}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total Mermas</TableCell>
                            <TableCell>{informe.total_mermas}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total Producción</TableCell>
                            <TableCell>{informe.total_produccion}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total Cajas Aceptadas</TableCell>
                            <TableCell>{informe.total_produccion - informe.total_mermas}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default InformeDetalle;

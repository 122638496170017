/* eslint-disable no-unused-vars */
import { AddCircle, DeleteForever, Edit } from "@mui/icons-material";
import CommentIcon from '@mui/icons-material/Comment';
import { Button, Container, Grid, Tooltip, useTheme } from "@mui/material";
import { esArray } from "src/general_components/functionEsArray";
import { NotasList } from "./NotasList";
import {
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  esES,
} from "@mui/x-data-grid";
import {
  DataGridPro,
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "src/context/AuthContext";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import { ContenidosListM } from "src/projects/gestion_documental/components/ContenidosListM";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import Pagination from "@mui/material/Pagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ButtonsExportJsonCSV from "./ButtonsExportJsonCSV";
import Box from "@mui/material/Box";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { ImagesList } from "./ImageList";
import TotalizadoresSearch from "src/projects/gestion_documental/components/TotalizadoresSearch";
import { CustomNoRowsOverlay } from "./Overlay";
import { GlobalStyles } from '@mui/material';
import { TheLayoutContext } from "src/context/TheLayoutContext";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { StyledDataGrid, estiloGrilla, globalEstilos, estilosComponentGrilla } from "./FunctionsListTablePaginated";
import AWS from 'aws-sdk';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { CircularProgress } from '@mui/material';
import { ConfimDialogComponent } from "src/general_components/ConfirmDialogComponent";
import { configS3 } from "src/configs/config";
import ButtonsExportJsonCSVComprobantes from "src/projects/gestion_documental/components/ButtonsExportJsonCSVComprobantes";


/**
 **       cols: define las columnas que se van a mostrar | required
 *       get: funcion con las que se obtienen los datos para mostrar (get()),
 *       getPaginated: get pero con paginacion, | required
 *       pageSize: tamaño de la pagina/quizas deprecado | required aunque por defecto usa 10
 *       listName: nombre a mostrarse en la lista | required
 *       redirectURLForm: formulario donde redirecciona la lista (en caso de usarse)// con modales, deprecado,
 *       redirectURLList: lista donde retorna o muestra luego del la creacion/edicion de los datos, deprecado
 *       create: funcion que muestra el modal|boton (se usa el mismo formulario) para agregar|editar datos,
 *       delete: funcion para eliminar datos, false por defecto
 *       edit: por defecto, false. permite editar los datos de la columna en el form de create. combinado en false con delete, no muestra la colmuan de acciones
 *       limit: limite para la paginacion,
 *       server: false por defecto | indica si la paginacion se hace desde el servidor usando el limit. Ademas, es flag de paginacion. FUNDAMENTAL PARA PAGINACION SERVERSIDE
 *       filter: false por defecto | muestra los filtros del datagrid para los listados
 *       exportData: false por defecto | muestra el boton export del datagrid para los listados
 *       density: false por defecto | muestra la densidad del datagrid
 *       columnsTool: false por defecto | muestra la tool para las columnas del datagrid
 *       idElementData: esta variable sirve para mostrar los datos de un elemento seleccionado (ver ejemplo MetadatosList.js)
 *       sinFilas: false por defecto | muestra un mensaje cuando no hay datos (sin filas)
 *       actionColumnCustom: permite agregar una columna de acciones personalizada, se debe redefinir las ya existentes pero se pueden agregar mas
 *       selectCheckbox: Permite habilitar los checkbox en la grilla para poder seleccionar los registros.
 *       valueSelected: Se le pasa una funcion la cual recibe como parametro los valores que se van seleccionando en la grilla.
 *       oneSelect: false por defecto | limita la seleccion de los registros a uno solo
 *       actualizarSelected: Sirve para limpiar la variable selection en caso de ser requerido ya que quedan los ids seleccionados en el caso de la paginacion del lado del server.
 *       setActualizarSelected: Sirve para setear en false la variable y que esta no vuelva a ejecutarse.
 *       abmContenidos: Habilita o desabilita los botones de agregar,editar y eliminar de la jerarquia contenidos.
 *       checkboxContenidos: Habilita o desabilita los checkbox de la jerarquia contenidos - si se desea desabilitar se manda "false" entre comillas
 *       digitalizacionesOn: Habilita o desabilita el boton de administracion de imagenes en la jerarquia contenidos.
 *       setResponseService: Setea la respuesta del servicio a consumir.
 *       hiddenButtonAgregar: oculta el boton agregar
 *       soloContenidosSearch: manda una propiedad al componente contenidos list para que ejecute el search para buscar solo contenidos
 *       totales: Habilita el componente totalizadoresSearch para mostrar el total de cajas por distintos criterios haci como la posibilidad de añadir filtros extras al search.
 *
 *      DATOS PARA USAR EL FORMULARIO, las tres propiedades son opcionales y se llaman desde el list
 *      No es necesario definir ninguno de los 3 si es que no se llega a tener que usar el formulario (tener cuidado con el edit)
 *       createButton: createButton,  // boton para crear un nuevo registro
 *       setCreateButton: setCreateButton, // funcion para setear el boton de crear un nuevo registro
 *       setDataEdit: setEstadosCajasSeleccionado,  // funcion para setear los datos del registro a editar
 *
 *
 * Example function valueSelected:
 *      const obtenerValuesSelected = (newValueSelected) => {
 *            setSelection(newValueSelected);
 *       };
 *
 * Example:
 **       const options = {
        cols: columns,
        get: getGeneros,
        getPaginated: getGenerosPaginated,
        pageSize: 5,
        listName: "Generos",
        redirectURLForm: "/generales/generos-form",
        redirectURLList: "/generales/generos-list",
        create: GenerosForm,
        edit: true,
        delete: deleteGenero,
        limit: 10,
        filter: true,
        exportData: true,
        server: true

        createButton: createButton,
        setCreateButton: setCreateButton,
        setDataEdit: setEstadosCajasSeleccionado,
    }
 */

// Se definen las clases para el manejop de colores en la grilla


export const ListTablePaginated = (data) => {
  const auth = useContext(AuthContext);

  //Variables para el formulario ya sea edicion o creacion
  //console.log("dataoptions: ",data.options);
  const {
    setDataEdit,
    createButton,
    setCreateButton,
    idElementData,
    sinFilas,
    actionColumnCustom,
    setResponseService,
    buttonExtraOn,
    ButtonExtra,
    ButtonExtraMarcar,
    ButtonExtraDesmarcar,
    modelo,
    buttonExtraViewOn,
    ButtonExtraView,
    marcadoYdesmarcado,
    ButtonExtraMarcarTodo,
    exportarComprobante,
    setLoteMaximo,
    refreshCheckBoxSelectCustom = false,  // Se añade este parametro para poder menejar las cargas de datos al usar los checkbox . Si no ponemos esto en true cada vez que hacemos click en el check vuelve a hacer la busqueda con el get
    useSelectionSinContext
  } = data.options;

  let buttomCreate = createButton;
  if (createButton === undefined) {
    buttomCreate = true;
  }


  let s3ValuesLocal = []
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');
  const [snackbarVisible, setSnackbarVisible] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [hierarchyOn, setHierarchyOn] = useState(
    data.options.hierarchyOn || false
  );
  // eslint-disable-next-line no-unused-vars
  const [detailsOn, setDetailsOn] = useState(data.options.detailsOn || false);
  const [records, setRecords] = useState([]); // filas de la lista
  const [columns, setColumns] = useState(data.options.cols); // columnas que se muestran
  // eslint-disable-next-line no-unused-vars
  const [abmContenidos, setAbmContenidos] = useState(
    data.options.abmContenidos === "false" ? false : true
  );
  // eslint-disable-next-line no-unused-vars
  const [checkboxContenidos, setCheckboxContenidos] = useState(
    data.options.checkboxContenidos === "false" ? false : true
  );
  const [notas, setNotas] = useState(data.options.notas || false);
  const [imagenes, setImagenes] = useState(data.options.imagenes || false);
  const [digitalizacionesOn, setDigitalizacionesOn] = useState(
    data.options.digitalizacionesOn || false
  );
  const [pageSize, setPageSize] = useState(20);
  //Bandera si entro a la última página
  const [lastPage, setLastPage] = useState(false);
  //Guarda limit de la página anterior
  const [pageLimit, setpageLimit] = useState(0);
  const [responseSet, setResponseSet] = useState(false);
  const [paginar, setPaginar] = useState(data.options.paginar || true);
  const [pageChangeButton, setPageChangeButton] = useState(false);
  const [hiddenButtonAgregar, setHiddenButtonAgregar] = useState(
    data.options.hiddenButtonAgregar || false
  ); // Se agrega este parametro para desabilitar el boton agregar
  const [modalState, setModalState] = useState(false);
  const [modalStateNotas, setModalStateNotas] = useState(false);
  const [modalStateImagenes, setModalStateImagenes] = useState(false);
  const [changeLimit, setChangeLimit] = useState(false);
  let totales = data.options.totales || false;
  const [notasAdd, setNotasAdd] = useState(false);

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [deleteButton, setDeleteButton] = useState(
    data.options.delete ? true : false
  );

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [editButton, setEditButton] = useState(
    data.options.edit ? true : false
  );

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [server, setServer] = useState(
    data.options.server ? "server" : "client"
  );

  /* los siguientes estados son para controlar la paginacion */

  const [page, setPage] = useState(0); // PAGINA INICIAL
  const [limit, setLimit] = useState(data.options.limit || 50); // LIMITE DEL FETCH. POR DEFECTO ES 10
  //const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0); // OFFSET DEL FETCH. POR DEFECTO ES 0
  const [loading, setLoading] = useState(false); // ESTADO DE LA TABLA
  const [loadingMarca, setLoadingMarca] = useState(false); // ESTADO DEL BOTON MARCA EN GESTION DOCUMENTAL
  const [loadingMarcaTodo, setLoadingMarcaTodo] = useState(false); // ESTADO DEL BOTON MARCA EN GESTION DOCUMENTAL
  const [loadingDesmarca, setLoadingDesmarca] = useState(false); // ESTADO DEL BOTON DESMARCA EN GESTION DOCUMENTAL
  const [totalRowCount, setTotalRowCount] = useState(0); // FILAS TOTALES QUE TRAE EL FECTCH SIN PAGINACION EL
  const [refresh, setRefresh] = useState(data.options.refresh || false); // REFRESCAR LA TABLA
  // eslint-disable-next-line no-unused-vars
  const [oneSelect, setOneSelect] = useState(data.options.oneSelect || false); // Habilita la seleccion de un solo registro con el checkbox
  //calcular la cantidad de paginas totales
  const totalPages = paginar && paginar !== "false" ? Math.ceil(totalRowCount / limit) : 1;
  const viewCustomToolbar = data.options.viewCustomToolbar || true;
  const soloContenidosSearch = data.options.soloContenidosSearch || false;
  const exportarPDF = data.options.exportarPDF || false;
  const exportarCajasContenidos = data.options.exportarCajasContenidos || false;
  const [notRefresh, setNotRefresh] = useState(false); // REFRESCAR LA TABLA
  const [paramsNotas, setParamsNotas] = useState({});
  const [paramsImagenes, setParamsImagenes] = useState({});
  const desabilitarSeleccion = data.options.desabilitarSeleccion || false;
  const desabilitarSeleccionContenidos = data.options.desabilitarSeleccionContenidos || false;
  const [modalStateConfirmDesmarcar, setModalStateConfirmDesmarcar] = useState(false);
  const [mensajeConfirmDesmarcar, setMensajeConfirmDesmarcar] = useState("");
  const [ejecutarEfectoMarcarTodo, setEjecutarEfectoMarcarTodo] = useState(false);
  const [idsSearch, setIdsSearch] = useState('');
  const [existeCoincidenciaClienteSubproceso, setExisteCoincidenciaClienteSubproceso] = useState(false);
  const [selectionManual, setSelectionManual] = useState([]);


  const [ordenamiento, setOrdenamiento] = useState('');
  const { setIdContenidos, setS3Values, s3Values, s3Json, setS3Json,
    procesoSeleccionado, cambioSelectProceso, setRecordsDesplegados, recordsDesplegados, buttonMarcaDisabled,
    setButtonMarcaDisabled, buttonDesmarcaDisabled, setButtonDesmarcaDisabled,
    clienteId, setClienteId, selection, setSelection, subProceso, valueSubproceso,
    subProcesoChange, setSubProcesoChange, useAwsBucket, buttonMarcaTodoDisabled, setButtonMarcarTodoDisabled, procesandoMarcas,
    setProcesandoMarcas, setSubProceso, setValueSubproceso } = useContext(TheLayoutContext)

  const ButtonMarcar = () => (
    <LoadingButton
      onClick={() => {
        marcarDesmarcar(false)
      }}
      disabled={buttonMarcaDisabled}
      loading={loadingMarca}
      loadingPosition="start"
      startIcon={<CheckCircleIcon />}
      loadingIndicator={<CircularProgress size={18} disableShrink />}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
        marginRight: '10px'
      }}
    >
      <span>Marca</span>
    </LoadingButton>
  );
  const ButtonDesmarcar = () => (
    <LoadingButton
      onClick={() => {
        setMensajeConfirmDesmarcar("¿Esta seguro que quiere desmarcar?")
        toggleModalConfirmDesmarcar();
        // marcarDesmarcar(true)
      }}
      disabled={buttonDesmarcaDisabled}
      loading={loadingDesmarca}
      loadingPosition="start"
      startIcon={<UnpublishedIcon />}
      loadingIndicator={<CircularProgress size={18} disableShrink />}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
        marginRight: '10px'
      }}
    >
      <span>Desmarcar</span>
    </LoadingButton>
  );

  const ButtonMarcarTodo = () => (
    <LoadingButton
      onClick={() => {
        marcarTodo()
        setLoadingMarcaTodo(true)
      }}
      disabled={buttonMarcaTodoDisabled}
      loading={loadingMarcaTodo}
      loadingPosition="start"
      startIcon={<CheckCircleIcon />}
      loadingIndicator={<CircularProgress size={18} disableShrink />}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '150px',
        fontSize: '11px',
        height: '30px',
        marginRight: '10px'
      }}
    >
      <span>Marcar Todo</span>
    </LoadingButton>
  );

  const obtenerProceso = () => {
    let proceso = '';

    if (procesoSeleccionado.label !== 'Consulta General') {
      const palabra = procesoSeleccionado.label.split(/[ /]/);
      // Usamos una expresión regular para dividir por espacio o barra

      const filtro = palabra.filter(word => word.toLowerCase() !== 'de');
      proceso = filtro.join('_').toLowerCase();

    } else {
      let url = window.location.href;
      const arrUrl = url.split("/");
      proceso = arrUrl[arrUrl.length - 1];
      if (proceso.includes("?")) {

        const arrUrl = url.split("?");
        proceso = arrUrl[arrUrl.length - 1];

        const arrUrls = proceso.split("=");
        proceso = arrUrls[arrUrls.length - 2];

        // console.log(proceso,'22222222')
        proceso = proceso === 'mudanza' ? 'mudanza_cajas' : proceso
      } else {
        //console.log(proceso,'prossssss')
        proceso = proceso === 'proceso' ? 'ubicacion_cajas' : proceso
      }
    }

    //console.log(proceso,'procesoprocesoproceso')
    return proceso
  }

  useEffect(() => {
    if (ejecutarEfectoMarcarTodo) {
      setEjecutarEfectoMarcarTodo(false);

      marcarDesmarcar(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection, ejecutarEfectoMarcarTodo]);

  const marcarTodo = async () => {
    const arrayDeIds = idsSearch.split(',');

    await setSelection(arrayDeIds);
    setEjecutarEfectoMarcarTodo(true); // Establece la bandera para que el useEffect se ejecute
  }

  const comprobarCoincidencias = async (seleccion) => {
    if (existeCoincidenciaClienteSubproceso && seleccion) {
      setButtonMarcaDisabled(false)
    }

    const arrayDeCadenas = subProceso.split('-');
    const cantidadDeGuiones = arrayDeCadenas.length - 1;

    if (cantidadDeGuiones === 2 && seleccion) {

      setButtonMarcaDisabled(false)
    }
  }

  const marcarDesmarcar = async (esDesmarcar) => {

    let desmarcarEliminaSubproceso = false
    const arrayDeCadenas = subProceso.split('-');
    const cantidadDeGuiones = arrayDeCadenas.length - 1;

    let subProcesoFormated = subProceso
    if (cantidadDeGuiones === 2) {
      subProcesoFormated = subProceso + '-' + clienteId
    }
    setSubProceso(subProcesoFormated)

    setLoading(true)
    if (esDesmarcar) {
      setLoadingDesmarca(true);
      toggleModalConfirmDesmarcar();
    } else {
      setLoadingMarca(true);
    }

    let marcasAWS = { procesos: s3Json };

    let procesoActual = obtenerProceso();
    // console.log("procesoActual",procesoActual)

    let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;
    let tipo_id = procesoActual === 'devolucion' || procesoActual === 'retiro' || procesoActual.includes("retiro") ||
      procesoActual.includes("devolucion") || procesoActual.includes("quita") ? 'id' : 'id';

    if (procesoActual === 'devolucion' || procesoActual === 'retiro') {
      procesoActual += '_contenidos';
    }

    if (!marcasAWS) {
      marcasAWS = {};
    }
    marcasAWS.procesos = marcasAWS.procesos || {};
    const proceso = marcasAWS.procesos[procesoActual] || [];
    const idUserArray = proceso[id_user] || [];
    let noPoseeDatos = false
    const subprocesoArray = idUserArray[subProceso] || [];
    let tipoIdArray = subprocesoArray[clienteId] || [];
    let cuentaIdArray = tipoIdArray[tipo_id] || [];

    let arrayIdCliente = []
    let dataDesmarcar = []
    let nuevoTipoIdArray = []
    if (clienteId === undefined && clienteId === null) {
      const errorMessage = esDesmarcar ? "¡Error en el proceso de Desmarcado!" : "¡Error en el proceso de Marcado!";
      setMessage(errorMessage);
      setSeverity("warning");
      setSnackbarVisible(true);
      return
    }
    if (esDesmarcar) {

      if (selection.length > 0) {
        const objetosFiltrados = records.filter(objeto => selection.includes(objeto.id));
        arrayIdCliente = objetosFiltrados.map(objeto => objeto.id_cuentas);
        //SE TOMA EN CUENTA SI SE SELECCIONARON MAS DE UN CLIENTE PARA DESMARCAR
        if (arrayIdCliente.length > 1) {

          arrayIdCliente.forEach((currentClientId, index) => {

            const subprocesoArrayForClientId = subprocesoArray[parseInt(currentClientId, 10)] || {};

            let tipoIdArrayDesmarcar = subprocesoArrayForClientId[tipo_id] || [];
            let cuentaIdArrayDesmarcar = tipoIdArrayDesmarcar[0] || [];

            let dataDesmarcar = [];

            dataDesmarcar[0] = cuentaIdArrayDesmarcar.filter(item => !selection.includes(item));
            nuevoTipoIdArray = dataDesmarcar;

            marcasAWS.procesos[procesoActual] = {
              ...marcasAWS.procesos[procesoActual],
              [id_user]: {
                ...marcasAWS.procesos[procesoActual]?.[id_user],
                [subProcesoFormated]: {
                  ...marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoFormated],
                  [parseInt(currentClientId, 10)]: {
                    ...marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoFormated]?.[parseInt(currentClientId, 10)],
                    [tipo_id]: nuevoTipoIdArray,
                  },
                },
              },
            };
          });

        } else {

          dataDesmarcar[0] = cuentaIdArray[0].filter(item => !selection.includes(item));
        }

      }
    }

    if (arrayIdCliente.length <= 1) {

      if (cuentaIdArray.length) {

        let registrosFiltrados = selection.map((data, index) => {

          cuentaIdArray[0].push(selection[index]);
          return data;
        });
      }

      if (s3Json === undefined) {
        noPoseeDatos = true
      }

      // console.log(dataDesmarcar, 'dataDesmarcar');
      nuevoTipoIdArray = esDesmarcar ? dataDesmarcar : cuentaIdArray.length ? cuentaIdArray : [selection];

      if (nuevoTipoIdArray && nuevoTipoIdArray[0] && nuevoTipoIdArray[0].length > 0) {
        // Convertir el array anidado a un conjunto para eliminar duplicados
        const conjuntoSinDuplicados = new Set(nuevoTipoIdArray.flat());

        // Convertir el conjunto de nuevo a un array
        nuevoTipoIdArray = [Array.from(conjuntoSinDuplicados)];
      }

      if (nuevoTipoIdArray && nuevoTipoIdArray[0] && nuevoTipoIdArray[0].length > 0) {
        // Actualizar la estructura solo si nuevoTipoIdArray no está vacío
        marcasAWS.procesos[procesoActual] = {
          ...marcasAWS.procesos[procesoActual],
          [id_user]: {
            ...marcasAWS.procesos[procesoActual]?.[id_user],
            [subProcesoFormated]: {
              ...marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoFormated],
              [clienteId]: {
                ...marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoFormated]?.[clienteId],
                [tipo_id]: nuevoTipoIdArray,
              },
            },
          },
        };
      } else {


        const arrayIdCliente2 = records.map(objeto => objeto.id_cuentas);
        const filteredArray = arrayIdCliente2.filter(value => value !== undefined && value !== null);
        const uniqueArray = [...new Set(filteredArray)];

        //SE TOMA EN CUENTA SI SE SELECCIONARON MAS DE UN CLIENTE PARA DESMARCAR Y SI NO SE TILDARON DATOS
        if (uniqueArray.length > 1) {

          uniqueArray.forEach((currentClientId, index) => {

            delete marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoFormated];

          });
        } else {

          // Si nuevoTipoIdArray está vacío, elimina el cliente del JSonS3 y todo lo que contiene
          delete marcasAWS.procesos[procesoActual]?.[id_user]?.[subProcesoFormated];
          desmarcarEliminaSubproceso = true
        }
      }
    }

    //marcasAWS = []
    AWS.config.update(configS3);
    const s3 = new AWS.S3();

    const jsonString = JSON.stringify(marcasAWS, null, 2);
    const file = new Blob([jsonString], { type: 'application/json' });

    const bucketName = configS3.bucketName;
    const ownerCode = JSON.parse(localStorage.getItem("clientData")).owner_code.toLowerCase();
    const key = ownerCode + '-marks.json';

    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
      ContentType: 'application/json',
    };

    try {
      const data = await s3.upload(params).promise();

      // console.log(marcasAWS, 'marcasAWS');
      const successMessage = esDesmarcar ? "¡Proceso de Desmarcado fue realizado con éxito!" : "¡Proceso de Marcado fue realizado con éxito!";
      // setButtonDesmarcaDisabled(esDesmarcar && nuevoTipoIdArray && nuevoTipoIdArray[0].length < 1 ? esDesmarcar : false);
      setButtonDesmarcaDisabled(esDesmarcar && nuevoTipoIdArray && nuevoTipoIdArray[0] && nuevoTipoIdArray[0].length > 0 ? false : esDesmarcar);
      setMessage(successMessage);
      setSeverity("success");
      setSnackbarVisible(true);
      setSelection([])
      !esDesmarcar ? setButtonMarcaDisabled(true) : setButtonMarcaDisabled(false);
      localStorage.setItem("siam-datos3", JSON.stringify(marcasAWS.procesos));

      setS3Json(marcasAWS.procesos)

      if (noPoseeDatos) {
        setS3Json(marcasAWS)
        setRefresh(true)
        setProcesandoMarcas(true)
      }

      const valueSubprocesoFormated = { ...valueSubproceso };

      if (cantidadDeGuiones === 2) {
        valueSubprocesoFormated.label = valueSubprocesoFormated.label + '-' + clienteId;
        setValueSubproceso(valueSubprocesoFormated);
      }

      if (desmarcarEliminaSubproceso) {
        // Buscar la posición del tercer guion
        const tercerGuionIndex = valueSubprocesoFormated.label.indexOf('-', valueSubprocesoFormated.label.indexOf('-', valueSubprocesoFormated.label.indexOf('-') + 1) + 1);

        // Verificar si se encontró el tercer guion y eliminarlo junto con lo que le sigue
        if (tercerGuionIndex !== -1) {
          valueSubprocesoFormated.label = valueSubprocesoFormated.label.substring(0, tercerGuionIndex);
          setValueSubproceso(valueSubprocesoFormated);
        }
      }

      setProcesandoMarcas(true)

    } catch (error) {
      const errorMessage = esDesmarcar ? "¡Error en el proceso de Desmarcado!" : "¡Error en el proceso de Marcado!";
      setMessage(errorMessage);
      setSeverity("warning");
      setSnackbarVisible(true);
    }
    setLoading(false)
    setLoadingMarca(false)
    setLoadingMarcaTodo(false)
    setLoadingDesmarca(false)
    setProcesandoMarcas(false)
  };


  //TODO: Revisar , se esta ejecutando lo del marcado en otros procesos que no involucran la gestion de cajas 
  useEffect(() => {
    const marcadoInicial = async () => {
      let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;
      let procesoActual = obtenerProceso();

      // console.log("records", records)
      let cliente = records?.[0]?.id_cuentas ?? records?.[2]?.id_cuentas;


      setClienteId(cliente)
      AWS.config.update(configS3);

      const bucketName = configS3.bucketName;
      const ownerCode = JSON.parse(localStorage.getItem("clientData")).owner_code.toLowerCase();
      const key = ownerCode + '-marks.json';

      const s3 = new AWS.S3();
      const params = {
        Bucket: bucketName,
        Key: key,
      };
      try {
        if (s3Values.length === 0 || subProcesoChange) {
          const dataS3 = await s3.getObject(params).promise();
          const jsonString = dataS3.Body.toString('utf-8');
          const datos = JSON.parse(jsonString).procesos;

          setS3Json(datos)

          //let subproceso = procesoActual
          let tipo_id = 'id'

          if (procesoActual === 'devolucion') {
            tipo_id = 'id'
            procesoActual = 'devolucion_contenidos'
          } else if (procesoActual === 'retiro') {
            tipo_id = 'id'
            procesoActual = 'retiro_contenidos'
          }



          if (datos && datos[procesoActual]?.[id_user]?.[subProceso]?.[cliente]?.[tipo_id]) {
            const clienteRelacionado = datos[procesoActual][id_user][subProceso]
            if (Object.keys(clienteRelacionado)[0] === cliente) {
              setExisteCoincidenciaClienteSubproceso(true)

            }
            const tipoProceso = datos[procesoActual][id_user][subProceso][cliente][tipo_id];

            if (Object.keys(tipoProceso).length > 0) {
              const [key, value] = Object.entries(tipoProceso)[0];
              // Guardo valores provenientes de S3
              setS3Values(value);
              // eslint-disable-next-line react-hooks/exhaustive-deps
              s3ValuesLocal = value
            }
          } else {
            setS3Values([]);
          }

          if (existeCoincidenciaClienteSubproceso && procesoSeleccionado.label !== 'Consulta General') {
            setButtonMarcarTodoDisabled(false)
          }

          const arrayDeCadenas = subProceso.split('-');
          const cantidadDeGuiones = arrayDeCadenas.length - 1;
          if (procesoSeleccionado.label !== 'Consulta General' && cantidadDeGuiones === 2) {
            setButtonMarcarTodoDisabled(false)
          }
        }

      } catch (error) {
        console.error('Error al descargar o parsear el archivo JSON desde S3:', error);
      }
      //console.log(procesoActual,'proceso')
    };
    if (records.length > 0) {
      if (marcadoYdesmarcado) {

        marcadoInicial()
      }


    } else {

      //setSelection([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records, subProceso]);

  useEffect(() => {
    const marcadoInicial = async () => {
      setButtonDesmarcaDisabled(true)
      if (Object.keys(s3Values).length > 0 || Object.keys(s3ValuesLocal).length > 0) {

        let selectionValues = []
        if (Object.keys(s3ValuesLocal).length > 0) {

          selectionValues = s3ValuesLocal
        } else {
          selectionValues = s3Values;

        }

        if (selectionValues && existeCoincidenciaClienteSubproceso) {
          setButtonDesmarcaDisabled(false)
        }

        if (data.options.valuesSelected) {
          data.options.valuesSelected(s3Values);
        } else {
          // console.error("El objeto tipoProceso está vacío");
        }
      }
      setSubProcesoChange(false)
    };
    marcadoInicial()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Values]);

  /* Se arma la columna "Notas que va a tener ABM de Notas " */

  let notasColumn = {
    field: "notas",
    headerName: "Notas",
    type: "actions",
    flex: 0.5,
    getActions: (params) => [
      //console.log("params.id: ",params.id.includes("cliente")),

      <GridActionsCellItem
        icon={
          <Tooltip title="Notas">
            <span>
              <CommentIcon color="info" />
            </span>
          </Tooltip>
        }
        onClick={() => {
          notasForm(params);
        }}
        label="Notas"
        {...(params.id.includes("cliente") || params.id.includes("sector")
          ? { hidden: true }
          : {})}
      />
    ],
  };
  /* Se arma la columna "Imagenes que va a tener edicion de imagenes " */

  let imagesColumn = {
    field: "image",
    headerName: "Imagenes",
    type: "actions",
    flex: 0.5,
    getActions: (params) => [
      //console.log("params.id: ",params.id.includes("cliente")),

      <GridActionsCellItem
        icon={
          <Tooltip title="Imagenes">
            <span>
              <AddPhotoAlternateIcon color="info" />
            </span>
          </Tooltip>
        }
        onClick={() => {
          imagesForm(params);
        }}
        label="Imagenes"
        {...(params.id.includes("cliente") || params.id.includes("sector")
          ? { hidden: true }
          : {})}
      />
    ],
  };
  /* Se arma la columna "Acciones que va a tener editar y borrar " */

  let actionColumn = {
    field: "actions",
    headerName: "Acciones",
    type: "actions",
    flex: 1,
    getActions: (params) => [
        editButton && (
            <GridActionsCellItem
                icon={
                    <Tooltip title="Editar">
                        <span>
                            <Edit color="info" />
                        </span>
                    </Tooltip>
                }
                onClick={() => {
                    handleForm(params);
                }}
                label="Editar"
            />
        ),
        deleteButton && (
            <GridActionsCellItem
                icon={
                    <Tooltip title="Eliminar">
                        <span>
                            <DeleteForever color="warning" />
                        </span>
                    </Tooltip>
                }
                onClick={() => {
                    handleDelete(params.id, params);
                }}
                label="Delete"
            />
        ),
    ].filter(Boolean),
};


  if (actionColumnCustom) {
    actionColumn = actionColumnCustom;
  }

  let reporte = { cuentas: responseSet.reporte };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>

        <Grid>
          {viewCustomToolbar !== "false" && (<><GridToolbarColumnsButton
            style={{
              color: '#FFFFFF',
              fontFamily: 'Roboto, sans-serif',
              background: "#0A395F",
              margin: '4px',
              minWidth: '120px',
              width: '120px',
              fontSize: '11px',
              height: '30px',
            }}
          />
            <GridToolbarFilterButton
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                background: "#0A395F",
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            />
            <GridToolbarDensitySelector
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                background: "#0A395F",
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            />
            {exportarPDF && (
              <GridToolbarExport style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                background: "#0A395F",
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }} />
            )}

            {exportarCajasContenidos && (

              <Button
                startIcon={<FileDownloadIcon />}
                disabled={records.length > 0 && exportarCajasContenidos ? false : true}
                onClick={toggleModal}
                style={{
                  color: '#FFFFFF',
                  fontFamily: 'Roboto, sans-serif',
                  margin: '4px',
                  minWidth: '120px',
                  width: '120px',
                  fontSize: '11px',
                  height: '30px',
                  background: records.length > 0 && exportarCajasContenidos ? "#0A395F" : 'gray',
                }}
              >
                Exportar
              </Button>

            )} </>)}
          {buttonExtraViewOn && (<ButtonExtraView procesoSeleccionado={procesoSeleccionado} subProceso={valueSubproceso}></ButtonExtraView>)}

        </Grid>
        <Grid>
          {!buttomCreate && (
            <Button
              {...(hiddenButtonAgregar === "true" ? { hidden: true } : null)}
              variant="contained"
              size="small"
              onClick={handleForm}
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            >
              <AddCircle />
              <div>Agregar</div>
            </Button>
          )}
          {useAwsBucket === '1' && ButtonExtraDesmarcar && (<ButtonDesmarcar></ButtonDesmarcar>)}
          {useAwsBucket === '1' && ButtonExtraMarcar && (<ButtonMarcar></ButtonMarcar>)}
          {useAwsBucket === '1' && ButtonExtraMarcarTodo && (<ButtonMarcarTodo></ButtonMarcarTodo>)}

          {buttonExtraOn && (<ButtonExtra procesoSeleccionado={procesoSeleccionado} subProceso={valueSubproceso}></ButtonExtra>)}
        </Grid>

      </GridToolbarContainer>
    );
  }

  const hideEmptyColumns = (column) => {
    // No ocultar la columna de tipo acción
  if (column.type === 'actions' || hierarchyOn) {
    return false;
  }
    return !records.every((row) => !!row[column.field]);
  };

  /* funcion que trae los records a mostrar en la tabla. Se ejecuta cada vez que se cambia la pagina o se
    modifica la cantidad de rows a mostart */

  useEffect(() => {
    // setRecords([]);
    if (page === 1 && !changeLimit && notRefresh && !refresh) {
      setNotRefresh(false);
      return;
    }
    setNotRefresh(false);
    setChangeLimit(false);
    const getData = async () => {
      if (data?.options?.getPaginated) {
        setLoading(true);

        //llama a la consulta para traer los datos a mostrar
        let response = undefined;
        if (!sinFilas) {
          let offsetModificado = offset;
          if (!pageChangeButton) {
            setPage(1);
            setOffset(0);
            offsetModificado = 0;
            setNotRefresh(true);
          } else {
            setPageChangeButton(false);
          }
          if (idElementData !== undefined) {
            if (data.options.setElementId) {
              if (idElementData.id_caja) {
                data.options.setElementId(idElementData.id_caja);
              }
            }
            response = await data?.options?.getPaginated(
              limit,
              offsetModificado,
              idElementData, ordenamiento
            ); // se trae los records mediante un id de algun tipo de elemento que sea necesario
            if (response === undefined) {
              setRecords([]);
              setTotalRowCount(parseInt(0));
            }
            let idsContenidos = response ? response.id_contenidos : [];
            let loteMaximo = response ? response.data[0]?.lote_mayor ? response.data[0].lote_mayor : false : false;
            if (detailsOn) {
              if (idsContenidos?.length !== 0) {
                setIdContenidos(idsContenidos)
              }
            }

            if (setLoteMaximo) {
              if (loteMaximo) {
                setLoteMaximo(loteMaximo);
              }
            }

          } else {
            response = await data?.options?.getPaginated(limit, offset); //probelma esta cuando el offfsett (limit*page supera la cantidad de registros)           
          }
        } else {
          setRecords([]);
          setTotalRowCount(parseInt(0));
          response = undefined;
        }
        if (response) {
          if (response.response) {
            if (setResponseService) {
              setResponseService(response.response);
            }
            setResponseSet(response.response);
          } else {
            if (setResponseService) {
              setResponseService(response);
            }
            setResponseSet(response);
          }

          if (data?.options?.setListRecords) {
            data.options.setListRecords(response.data);
          }
          setIdsSearch(response.id_cajas)
          setRecords(response.data); // EL RESPONSE SE DESARMA EN EL CONTROLADOR DEL MODELO PARA TRAER POR SEPARADO LOS DATOS Y LA CANTIDAD DE RESULTADOS
          const user_cuentas = JSON.parse(
            localStorage.getItem("siam-user-cuentas")
          );
          let userCuentasStorage = esArray(user_cuentas);
          let cols_aux = [...data.options.cols];
          if (editButton || deleteButton) {
            cols_aux.push(actionColumn);
          }
          if (imagenes) {
            cols_aux.push(imagesColumn);
          }
          if (userCuentasStorage.length === 0 && data.options.notas && !notasAdd) {
            const actionsColumnIndex = cols_aux.findIndex(column => column.field === "actions");
            if (actionsColumnIndex !== -1) {
              const actionsColumn = cols_aux[actionsColumnIndex];
              const existingActions = actionsColumn.getActions;
              actionsColumn.getActions = (params) => [
                ...existingActions(params), // Mantener las acciones existentes
                <GridActionsCellItem
                  icon={
                    <Tooltip title="Notas">
                      <span>
                        <CommentIcon color="info" />
                      </span>
                    </Tooltip>
                  }
                  onClick={() => {
                    notasForm(params);
                  }}
                  label="Notas"
                  {...(params.id.includes("cliente") || params.id.includes("sector")
                    ? { hidden: true }
                    : {})}
                />
              ];
              setNotasAdd(true);
            } else {
              cols_aux.push(notasColumn);
            }
          }
          setColumns(cols_aux);
          setTotalRowCount(parseInt(response.total_records)); // SE ASIGNA LA CANTIDAD TOTAL DE RESULTADOS QUE EXISTEN EN LA DB CON RESPECTO A ESE FETCH
        }
        setRefresh(false);
        setNotRefresh(false);
        setLoading(false);
      }
    };
    !data.options?.res && server && !refreshCheckBoxSelectCustom && getData(); // SOLO SE REALIZA EL FETCH SI SE USA LA PAGINACION, POR LO QUE SE DEBE ASIGNAR LA FUNCION DEL CONTROLADOR
    // SI SE QUIERE USAR SIN PAGINACION, SE PUEDE PASAR DIRECTAMENTE EL ARREGLO A MOSTRAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refresh, data.options, limit, lastPage, pageLimit, sinFilas, ordenamiento]);

  /* ESTADO DEL CONTEO DE PAGINAS */
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);


  /* actualiza el estado de la pagina y donde se encuentra el paginador */

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [totalRowCount]);

  // Se agrega este useEffect debido a un bug generado con el getPaginated y la demora en la consulta
  useEffect(() => {
    if (sinFilas && records.length > 0) {
      setRecords([]);
      setRowCountState(parseInt(0));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  useEffect(() => {
    const getData = async () => {
      const response = await data.options.get(auth); //hay que ver como lo manejamos con la paginacion
      if (response) {
        if (setResponseService) {
          if (response.response) {
            setResponseService(response.response);
            setResponseSet(response.response);
          } else {
            setResponseService(response);
            setResponseSet(response);
          }
        }
        if (data?.options?.setListRecords) {
          data.options.setListRecords(response.data);
        }
        setRecords([...response]);
        const user_cuentas = JSON.parse(
          localStorage.getItem("siam-user-cuentas")
        );
        let userCuentasStorage = esArray(user_cuentas);
        let cols_aux = [...data.options.cols];
        if (userCuentasStorage.length === 0 && data.options.notas && !notasAdd) {
          const actionsColumnIndex = cols_aux.findIndex(column => column.field === "actions");
          if (actionsColumnIndex !== -1) {
            const actionsColumn = cols_aux[actionsColumnIndex];
            const existingActions = actionsColumn.getActions;
            actionsColumn.getActions = (params) => [
              ...existingActions(params), // Mantener las acciones existentes
              <GridActionsCellItem
                icon={
                  <Tooltip title="Notas">
                    <span>
                      <CommentIcon color="info" />
                    </span>
                  </Tooltip>
                }
                onClick={() => {
                  notasForm(params);
                }}
                label="Notas"
                {...(params.id.includes("cliente") || params.id.includes("sector")
                  ? { hidden: true }
                  : {})}
              />
            ];
            setNotasAdd(true);
          } else {
            cols_aux.push(notasColumn);
          }
        }
        if (editButton && deleteButton) {
          cols_aux.push(actionColumn);
        }
        if (imagenes) {
          cols_aux.push(imagesColumn);
        }
        setColumns(cols_aux);
      }
    };
    !server && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Actualiza el estado de la variable que contiene los id rows seleccionados
  useEffect(() => {
    if (data.options.actualizarSelected || sinFilas) {
      setSelection([]);
      setSelectionManual([]);
      if (data.options.actualizarSelected) {
        data.options.setActualizarSelected(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.options.actualizarSelected, sinFilas]);

  useEffect(() => {
    if (data?.options?.res) {
      setLoading(true);
      setRecords(data.options.res);
      const user_cuentas = JSON.parse(
        localStorage.getItem("siam-user-cuentas")
      );
      let userCuentasStorage = esArray(user_cuentas);
      let cols_aux = [...data.options.cols];
      if (userCuentasStorage.length === 0 && data.options.notas && !notasAdd) {
        const actionsColumnIndex = cols_aux.findIndex(column => column.field === "actions");
        if (actionsColumnIndex !== -1) {
          const actionsColumn = cols_aux[actionsColumnIndex];
          const existingActions = actionsColumn.getActions;
          actionsColumn.getActions = (params) => [
            ...existingActions(params), // Mantener las acciones existentes
            <GridActionsCellItem
              icon={
                <Tooltip title="Notas">
                  <span>
                    <CommentIcon color="info" />
                  </span>
                </Tooltip>
              }
              onClick={() => {
                notasForm(params);
              }}
              label="Notas"
              {...(params.id.includes("cliente") || params.id.includes("sector")
                ? { hidden: true }
                : {})}
            />
          ];
          setNotasAdd(true);
        } else {
          cols_aux.push(notasColumn);
        }
      }
      if (editButton && deleteButton) {
        cols_aux.push(actionColumn);
      }
      if (imagenes) {
        cols_aux.push(imagesColumn);
      }
      setColumns(cols_aux);
      setTotalRowCount(data.options.res.length);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.options.res]);

  //Maneja el delete del item seleccionado--
  const handleDelete = async (id, params) => {
    const resultado = await data.options.delete(id, params, idElementData);

    if (page > 0) {
      setPage(1);
    }
    if (resultado) {
      setRefresh(true);
    }
  };
  // Funcion que jerarquiza la grilla
  const getTreeDataPath = (row) => row.hierarchy;
  const groupingColDef = {
    headerName: "",
    hideDescendantCount: true,
    valueFormatter: () => "",
    width: 50,
  };

  //Funcion para poder abrir el popup o para que te lleve al formulario de edicion o creacion
  const handleForm = (dataSelect) => {
    if (dataSelect.row) setDataEdit(dataSelect.row);
    if (idElementData?.sectorId) {
      setCreateButton(!createButton, idElementData);
    } else {
      setCreateButton(!createButton);
    }

  };

  const notasForm = (dataSelect) => {
    if (dataSelect.id.includes("caja") || dataSelect.id.includes("contenido")) {
      let paramsNew = { id_modelo_padre: dataSelect.row.cajas }
      paramsNew.modelo_padre = dataSelect.id.includes("caja") ? "cajas" : "contenido";
      setParamsNotas(paramsNew);
    } else {
      setParamsNotas({ modelo_padre: dataSelect.row.caja ? "cajas" : "contenido", id_modelo_padre: dataSelect.id });
    }

    toggleModalNotas();
  };

  const imagesForm = (dataSelect) => {
    if (dataSelect.row) setDataEdit(dataSelect.row);
    setParamsImagenes({ id: dataSelect.id });
    toggleModalImagenes();
  };

  const theme = useTheme();

  // Funciones de detalle del gridTable
  function DetailPanelContent({ row: rowProp }) {
    //console.log(rowProp, 'rows')
    return (
      <Box>
        <Stack sx={{ py: 1, boxSizing: "border-box" }} direction="column">
          <Paper sx={{ flex: 1, mx: "auto", width: "100%", p: 3 }}>
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <Grid
                style={{
                  borderBottomStyle: "dashed",
                  textAlign: "left",
                  display: "inline-flex",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ fontWeight: "bold", paddingRight: "1%" }}
                >
                  Fecha Alta:
                </Typography>

                <Typography variant="subtitle1" color="textSecondary">
                  {rowProp.fecha_creacion}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{
                    fontWeight: "bold",
                    paddingRight: "1%",
                    paddingLeft: "1%",
                  }}
                >
                  Tipo Caja:
                </Typography>

                <Typography variant="subtitle1" color="textSecondary">
                  {rowProp.relations
                    ? rowProp.relations.tipo_cajas.detalle
                    : rowProp.tipo_cajas}
                </Typography>
                {rowProp.deposito && (
                  <>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      style={{
                        fontWeight: "bold",
                        paddingRight: "1%",
                        paddingLeft: "1%",
                      }}
                    >
                      Deposito:
                    </Typography>

                    <Typography variant="subtitle1" color="textSecondary">
                      {rowProp.deposito}
                    </Typography>
                  </>
                )}
                {rowProp.ubicacion && (
                  <>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      style={{
                        fontWeight: "bold",
                        paddingRight: "1%",
                        paddingLeft: "1%",
                      }}
                    >
                      Ubicación:
                    </Typography>

                    <Typography variant="subtitle1" color="textSecondary">
                      {rowProp.ubicacion}
                    </Typography>
                  </>
                )}
              </Grid>

              {
                <ContenidosListM
                  props={[]}
                  id_cuentas_localizaciones={rowProp.id_cuentas_localizaciones}
                  id={rowProp.id}
                  cuentas_localizaciones={rowProp.relations?.cuentas_localizaciones}
                  abmContenidos={abmContenidos}
                  checkboxContenidos={checkboxContenidos}
                  contenidosList={
                    rowProp.contenidos?.length !== 0 ? rowProp.contenidos : false
                  }
                  digitalizacionesOn={digitalizacionesOn}
                  soloContenidosSearch={soloContenidosSearch}
                  desabilitarSeleccionContenidos={
                    desabilitarSeleccionContenidos
                  }
                  notas={notas}
                />
              }
            </Stack>
          </Paper>
        </Stack>
      </Box>
    );
  }

  DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
  };

  function CustomDetailPanelToggle(props) {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowsContentCacheSelector
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
      <Tooltip title="Mostrar Contenidos" placement="right">
        <span>
          <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            hidden={!hasDetail}
            aria-label={isExpanded ? "Close" : "Open"}
          >
            <ExpandMoreIcon
              sx={{
                transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                transition: (theme) =>
                  theme.transitions.create("transform", {
                    duration: theme.transitions.duration.shortest,
                  }),
              }}
              fontSize="inherit"
            />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const getDetailPanelContent = React.useCallback(
    ({ row }) =>
      row.id.includes("cliente") || row.id.includes("sector") ? null : (
        <DetailPanelContent row={row} />
      ),
    []
  );
  const getDetailPanelHeight = React.useCallback(() => 500, []);

  // Parametros de Jerarquia
  const hierarchyProps = {
    treeData: true,
    groupingColDef: groupingColDef,
    getTreeDataPath: getTreeDataPath,
    defaultGroupingExpansionDepth: -1,
  };

  // Parametros de detalle
  const detailsProps = {
    rowThreshold: 0,
    getDetailPanelHeight: getDetailPanelHeight,
    getDetailPanelContent: getDetailPanelContent,
  };

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const toggleModalNotas = () => {
    setModalStateNotas(!modalStateNotas);
  };

  const toggleModalConfirmDesmarcar = () => {
    setModalStateConfirmDesmarcar(!modalStateConfirmDesmarcar);
  };

  const toggleModalImagenes = () => {
    setModalStateImagenes(!modalStateImagenes);
  };

  const dialogChild = (
    exportarComprobante ? (
      <ButtonsExportJsonCSVComprobantes
        props={{
          totalPages: totalPages,
          limit: limit,
          idElementData: idElementData,
          data: data,
        }}
        toggleModal={toggleModal}
      />
    ) : (
      <ButtonsExportJsonCSV
        props={{
          totalPages: totalPages,
          limit: limit,
          idElementData: idElementData,
          data: data,
        }}
        toggleModal={toggleModal}
      />
    )
  );


  const dialogChildNotas = (
    <NotasList
      params={paramsNotas}
    />
  );

  const dialogChildImagenes = (
    <ImagesList
      params={paramsImagenes}
      toggleModal={toggleModalImagenes}
    />
  );

  const handleFirstPageClick = () => {
    handlePageChange(1);
  };

  // Función para manejar el cambio de página
  const handlePageChange = (newPage) => {
    setPageChangeButton(true);
    setPage(newPage);

    if (newPage !== totalPages) {
      if (lastPage) {
        setLimit(pageLimit);
        setOffset(pageLimit * (newPage - 1));
        setLastPage(false);
      } else {
        limit !== data.options.limit && setLimit(limit);
        setOffset(limit * (newPage - 1));
      }
    } else {
      setpageLimit(limit);
      setLastPage(true);
      setLimit(limit);
      setOffset(limit * (newPage - 1));
    }
  };

  const handleChangeLimit = (event) => {
    setChangeLimit(true);
    const newLimit = parseInt(event.target.value, 10);
    setpageLimit(newLimit);
    setLimit(newLimit);
  };

  const rowsPerPageOptions = [50, 100, 150, 200];

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  const handleSortModelChange = (params) => {
    // params contiene el nuevo modelo de ordenamiento
    //console.log('Nuevo modelo de ordenamiento:', params);
    let value = '';
    let valorBusqueda = params[0]
    if (valorBusqueda) {
      switch (valorBusqueda?.field) {
        case 'clienteName':
          value = 'cuentas_personas_juridicas.razon_social'
          break;
        case 'sectorName':
          value = 'cuentas_localizaciones.descripcion'
          break;
        case 'estados_cajas':
          value = 'estados_cajas.detalle'
          break;
        case 'concatCaja':
          value = 'cajas.id'
          break;
        default:
          value = ''
          break;
      }
      if (value) {
        valorBusqueda = value + '-' + valorBusqueda.sort
        setOrdenamiento(valorBusqueda)
      }
    }

    // Puedes realizar acciones adicionales aquí según tus necesidades
  };
  const stylesDatagrid = (row) => {
    const userId = JSON.parse(localStorage.getItem("siam-user-data")).id;

    const idsPorProceso = {};

    // Itera sobre cada proceso en s3Json.
    for (const proceso in s3Json) {

      // Itera sobre cada usuario en el proceso actual.
      for (const usuario in s3Json[proceso]) {

        // Verifica si el usuario actual es el usuario deseado (userId).
        if (usuario === userId) {

          // Inicializa el array de IDs para el proceso actual si aún no existe.
          if (!idsPorProceso[proceso]) {
            idsPorProceso[proceso] = [];
          }

          // Itera sobre cada proceso en s3Json.
          for (const subProcesos in s3Json[proceso][usuario]) {
            if (subProcesos === subProceso) {
              // Itera sobre cada cliente en el usuario actual.
              for (const cliente in s3Json[proceso][usuario][subProcesos]) {

                // Obtiene los IDs (ya sea id_cajas o id_contenidos) asociados con el cliente actual.
                const ids = s3Json[proceso][usuario][subProcesos][cliente].id;
                //console.log(ids,'idsids')
                if (ids && Array.isArray(ids)) {
                  idsPorProceso[proceso] = [...idsPorProceso[proceso], ...(ids[0] || [])];
                }
              }
            }
          }
        }
      }
    }

    // Se definen que clase pertenece a que proceso
    const procesoColorMapping = {
      devolucion_caja: 'Open',
      devolucion_cajas: 'Open',
      retiro_cajas: 'PartiallyFilled',
      retiro_contenidos: 'Filled',
      mudanza_cajas: 'Rejected',
      ubicacion_cajas: 'Ubicacion',
      devolucion_contenidos: 'DevolucionContenidos',
      ubicacion_temporal: 'UbicacionTemporal',
      retiro_definitivo: 'RetiroDefinitivo',
      baja_expurgue: 'BajaExpurgue',
      retiro_contenido_parcial: 'Filled',
      retiro_contenido_total: 'Filled',
      quita_total_contenidos: 'Filled',
      devolucion_contenidos_parcial: 'DevolucionContenidos',
      devolucion_contenidos_total: 'DevolucionContenidos',

    };


    let selectedColor = null;

    for (const proceso in idsPorProceso) {
      if (idsPorProceso.hasOwnProperty(proceso)) {
        const procesoIds = idsPorProceso[proceso];
        if (procesoIds && procesoIds.some(ids => ids && ids.includes && ids.includes(row.id.toString()))) {
          selectedColor = procesoColorMapping[proceso];
          return selectedColor;
        }
      }
    }
  };

  const handleConfirmDesmarcar = async () => {
    await marcarDesmarcar(true);

  };


  let isMobileDevice = regexp.test(details);
  return (
    <Container maxWidth="xxl" style={{ padding: "0px 0px 50px 0px" }}>
      <Grid container spacing={2}>
        {data.options.listName ? (
          <Grid item xs={12} md={12}>
            <Typography align="left" variant="h5" gutterBottom component="div">
              {data.options.listName}
            </Typography>
            <Divider />
          </Grid>
        ) : (
          <Divider style={{ display: "none" }} />
        )}
        <Grid /*height="800px"*/ height='600' item xs={12}>
          {totales && records.length > 0 && (
            <TotalizadoresSearch responseSearch={responseSet}></TotalizadoresSearch>

          )}
          <GlobalStyles
            styles={globalEstilos}
          />
          <StyledDataGrid
            getRowClassName={(params) => {
              // Lógica para cambiar el color de la fila basándote en los datos
              let color = params.row.color;
              if (color && modelo && modelo === "contenidos") {
                return `super-app-theme--${color}`
              } else {
                return ''
              }
            }}
            {...(data.options.selectCheckbox && { checkboxSelection: true })}
            onSelectionModelChange={(newSelectionModel) => {
              let seleccion = [];

              const selectionSet = new Set(selection);

              if (oneSelect && newSelectionModel.length > 1) {
                const selectionSet = useSelectionSinContext ? new Set(selectionManual) : new Set(selection);
                const result = newSelectionModel.filter(
                  (s) => !selectionSet.has(s)
                );
                seleccion = result;
                if (useSelectionSinContext) {
                  setSelectionManual(result);
                } else {
                  setSelection(result);
                }


              } else {
                seleccion = newSelectionModel;
                if (useSelectionSinContext) {
                  setSelectionManual(newSelectionModel);
                } else {
                  setSelection(newSelectionModel);
                }

              }

              if (data.options.valuesSelected) {
                data.options.valuesSelected(seleccion);
              }

              comprobarCoincidencias(true)
              if (newSelectionModel.length === 0) {
                setButtonMarcaDisabled(true)
              }
            }}
            selectionModel={useSelectionSinContext ? selectionManual : selection}
            onSortModelChange={handleSortModelChange}
            {...(records.length > 0 && { autoHeight: true })}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText} // hace que la cabecera de los filtros este español
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              NoResultsOverlay: CustomNoRowsOverlay,
              // las cabeceras de filtrado que van a aparecer
              Toolbar: CustomToolbar,

              Pagination: () => (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ display: "flex" }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <div style={{ display: "flex", alignItems: "center", paddingLeft: 10 }}>
                      <InputLabel
                        id="rows-per-page-label"
                        style={{ marginRight: "10px" }}
                      >
                        Filas por página:
                      </InputLabel>
                      <FormControl>
                        <Select
                          labelId="rows-per-page-label"
                          id="rows-per-page-select"
                          value={limit}
                          onChange={handleChangeLimit}
                          style={{ height: "30px" }}
                        >
                          {rowsPerPageOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={6}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        onClick={handleFirstPageClick}
                        disabled={page === 1}
                        style={{ top: 0, paddingTop: "4px" }}
                      >
                        <FirstPageIcon />
                      </IconButton>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, newPage) => handlePageChange(newPage)}
                        showLastButton
                        color="primary"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingRight: 5 }}>
                    <InputLabel
                      id="rows-per-page-label"
                      style={{ marginLeft: "10px", textAlign: "right" }}
                    >
                      Total: {totalRowCount}
                    </InputLabel>
                  </Grid>
                </Grid>
              ),
            }}
            density="compact"
            sx={{
              ...estiloGrilla,
              backgroundColor: theme.palette.background.paper,
              
              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                oneSelect
                  ? {
                    display: "none",
                  }
                  : { color: '#FFFFFF' }
              ,
            }}
            componentsProps={{
              panel: {
                sx: estilosComponentGrilla,
              },
            }}
            rows={records || []} // filas de la tabla OBTENIDAS EN EL FETCH
            columnTypes={{
              [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.type]: GRID_DETAIL_PANEL_TOGGLE_COL_DEF
            }}
            columns={[
              {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                  <CustomDetailPanelToggle
                    id={params.id}
                    value={params.value}
                  />
                ),
              },
              ...columns.map((column) => ({
                ...column,
                hide: hideEmptyColumns(column),
              })),
            ]}
            rowCount={rowCountState}
            loading={loading}
            rowsPerPageOptions={[50, 100, 150, 200]}
            onPageSizeChange={(newLimit) => {
              setLimit(newLimit);
              setOffset(newLimit * page);
            }}
            {...(paginar && paginar !== "false" && { pagination: true })}
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 200}
            isRowSelectable={(params) => params.row.isRowSelectable === true}
            pageSize={limit || 10} // TAMAÑO DE LA PAGINA A MOSTRAR (CANTIDAD DE RECORDS). SI NO SE SETEA EN LOS PROPS, USA POR DEFECTO 10
            paginationMode={server} // PAGINACION VOLCADA AL SERVIDOR
            keepNonExistentRowsSelected
            // Parametros de jerarquia - Revisar otros listados para que no afecte en los mismos
            {...(hierarchyOn && { ...hierarchyProps })} // jerarquia props
            {...(desabilitarSeleccion && { disableSelectionOnClick: true })}
            // Parametros de opcion de detalle
            {...(detailsOn && { ...detailsProps })} // jerarquia props
            // slots={{ noRowsOverlay: CustomNoRowsOverlay }}
            {...data.options.props}
          // se pueden hacer las celdas editables individualmente
          />
        </Grid>
      </Grid>
      <SimpleDialogComponent
        open={modalState}
        modalName={"Exportar"}
        child={dialogChild}
        handleClose={toggleModal}
        buttonEnviar={false}
        buttonCancelar={false}
        maxWidth="md"
      />

      <ConfimDialogComponent
        open={modalStateConfirmDesmarcar}
        modalName={"Confirmar Desmarcado"}
        mensaje={mensajeConfirmDesmarcar}
        handleConfirm={handleConfirmDesmarcar}
        handleClose={toggleModalConfirmDesmarcar}
      />

      <SimpleDialogComponent
        open={modalStateNotas}
        modalName={"Notas"}
        child={dialogChildNotas}
        handleClose={toggleModalNotas}
        buttonEnviar={false}
        buttonCancelar={false}
        maxWidth="lg"
      />
      <SimpleDialogComponent
        open={modalStateImagenes}
        modalName={"Imagenes"}
        child={dialogChildImagenes}
        handleClose={toggleModalImagenes}
        buttonEnviar={false}
        buttonCancelar={false}
        maxWidth="lg"
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={8000}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </Container>
  );
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { esArray } from "src/general_components/functionEsArray";
import { Autocomplete, TextField } from "@mui/material";
import { getFromLS } from "src/general_helpers/getFromLS";
import { getTerminalData } from "src/projects/gestion_documental/helpers/TerminalData";

const SelectorTerminal = (props) => {

  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const terminal = jsonForm.core.data.terminal;


  const getTerminales = getFromLS("siam-terminal").terminal;
  const [selectedTerminal, setSelectedTerminal] = useState("");

  useState(() => {
    const getTerminal = async () => {
      if (getTerminales) {
        setDataDefaults(esArray(getTerminales));
      }else {
        const response = await getTerminalData()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    getTerminal()
  
  }, []);

  useEffect(() => {
    setSelectedTerminal(dataDefaults.find((item) => item.id === terminal));
  }, [dataDefaults, terminal]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setSelectedTerminal(newValue);
      props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
    }else{
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{
          width: "100%",
          paddingBottom: "10px",
          borderStyle: "hidden double dashed",
        }}
      >
         <Autocomplete
        value={selectedTerminal  ? selectedTerminal : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.numero_terminal|| ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Terminal" 
          disabled={disabled}
          />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
const SelectorTerminalTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorTerminal";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorTerminalComponent = {
  renderer: withJsonFormsControlProps(SelectorTerminal),
  tester: SelectorTerminalTester,
};

import React, { useContext } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import { Login } from "../pages/Login";
import { Logout } from "src/pages/Logout";
import { MainRouter } from "./MainRouter";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import routes from '../routes'
// import { CuentasJuridicasRouter } from "src/projects/gestion_comercial/pages/CuentasJuridicasRouter";

export const AppRouter = () => {
    const { user } = useContext(AuthContext);
    const menu = (
        <>
            <BrowserRouter>
                <Switch>
                    <PublicRoute exact path="/login" component={Login} isLogged={user.isLogged} />
                    <PrivateRoute exact path="/logout" component={Logout} isLogged={user.isLogged} />
                    <PrivateRoute path="/" component={MainRouter} isLogged={user.isLogged} />
                    {
                        routes.forEach(function (action) {
                            if (typeof action.component === 'object') {
                                console.log(typeof action.component)
                                console.log(action.name)
                            }
                            if (action.component !== false && typeof action.component === 'function') {
                                try {
                                    <PublicRoute exact path={action.path} component={action.component} isLogged={user.isLogged} />
                                } catch (error) {
                                    console.log('Router Error')
                                    console.log(error)
                                }
                            }
                        })
                    }
                    {/* 
                        <PrivateRoute path="/seguridad" component={SeguridadRouter} isLogged={user.isLogged}/>
                        <PrivateRoute path="/digitalizacion" component={Digitalizacion} isLogged={user.isLogged}/>
                        <PrivateRoute path="/cuentas" component={CuentasJuridicasRouter} isLogged={user.isLogged}/>
                        <PrivateRoute path="/digitalizacion" component={Digitalizacion} isLogged={user.isLogged}/>
                    */}
                </Switch>
            </BrowserRouter>
        </>
    )

    return menu
};

export const schemaArticulosPrecioForm = {
  type: "object",
  properties: {
    costo: {
      type: "number",
      title: "Costo",
      description: "Ingrese costo",
      minimum: 0,
      multipleOf: 0.00001,
      maximum: 999999999999.99999,
    },
    impuestoInterno: {
      type: "number",
      title: "Impusto interno",
      description: "Ingrese impusto interno",
      minimum: 0,
      multipleOf: 0.00001,
      maximum: 999999999999.99999,
    },
    detalle: {
      type: "string",
      title: "Detalle",
      description: "Ingrese detalle",
    },
    selectedpropositoCalculoPrecio: {
      title: 'Proposito Calculo Precio',
      type: "object",
      customRender: "SelectorPropositoCalculoPrecio",
    },
  },
  required: [
    "selectedpropositoCalculoPrecio",
    "detalle",
    "costo",
  ],
};

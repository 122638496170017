import { useState, useEffect, useContext } from "react";
import { deleteTipoUbicaciones, postTipoUbicaciones } from "src/projects/gestion_documental/helpers/TipoUbicacionesData";
import { TipoUbicacionesForm } from "src/projects/gestion_documental/components/TipoUbicacionesForm";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { FormConsultaFicha } from "../components/FormConsultaFicha";
import { ListTableFichas } from "src/projects/gestion_comercial/components/ListTableFichas";

import { getFichaData, actualizarEstadoComprobante } from "src/projects/gestion_documental/helpers/ComprobantesData";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Typography from "@mui/material/Typography";
import { Button, Popover, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { ConfimDialogComponent } from "src/general_components/ConfirmDialogComponent";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { TheLayoutContext } from "src/context/TheLayoutContext";



export const CuentasFichasList = (props) => {

    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [message, setMessage] = useState(false);
    const [severity, setSeverity] = useState(false);
    const [createButton, setCreateButton] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [buttonMetadatos, setButtonMetadatos] = useState(false);
    const [abrirBusqueda, setAbrirBusqueda] = useState(false);
    const [modalState, setModalState] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [pushData, setPushData] = useState(false);
    const [criteriaFicha, setcriteriaFicha] = useState(false);
    const [clienteSeleccionado, setClienteSeleccionado] = useState("");
    const [modalStateConfirm, setModalStateConfirm] = useState(false);
    const [mensajeConfirm, setMensajeConfirm] = useState("");
    const [idEstadoSelected, setIdEstadoSelected] = useState("");
    const [idComprobanteSelected, setIdComprobanteSelected] = useState('');
    const [fechaDesdeSelected, setFechaDesdeSelected] = useState('');
    const [fechaHastaSelected, setFechaHastaSelected] = useState('');

    const { clienteFichaSelected } = useContext(TheLayoutContext)


    const [refresh, setRefresh] = useState(false);

    let nombreEstadoSelect = ''

    const toggleModal = () => {
        setModalState(!modalState);
        setAbrirBusqueda(!abrirBusqueda)
    };
    useEffect(() => {
        if (criteriaFicha) {
            setOptions({
                ...options,
                getPaginated: getFichaData,
                idElementData: criteriaFicha,
                sinFilas: false,
                clienteSeleccionado: clienteSeleccionado
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteriaFicha]);

    const getLocalUserCuentas = () => {
        let id_cuentas = "";
        const user_cuentas = JSON.parse(localStorage.getItem("siam-user-cuentas"));
        if (user_cuentas) {
            user_cuentas.forEach((user_cuenta) => {

                if (
                    user_cuenta &&
                    user_cuenta.id_cuentas
                ) {
                    id_cuentas += user_cuenta.id_cuentas + ",";
                }
            });
        }

        return id_cuentas === "" ? false : id_cuentas.replace(/(^,)|(,$)/g, "");
    };


    const cuenta = getLocalUserCuentas()

    useEffect(() => {

        setOptions({
            ...options,
            refresh: refresh
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const handleConfirm = async () => {

        let response = await actualizarEstadoComprobante(idComprobanteSelected, idEstadoSelected)

        let message = "Se realizo correctamente la modificacion"
        let statusMessage = 'success'

        if (!response?.status) {
            message = response.message
            statusMessage = 'error'

        }

        setMessage(message);
        setSeverity(statusMessage);
        setSnackbarVisible(true);
        setModalStateConfirm(!modalStateConfirm);
        setRefresh(!refresh)
    };

    const toggleModalConfirm = () => {
        setModalStateConfirm(!modalStateConfirm);
    };


    const CustomCellRenderer = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [options, setOptions] = useState([]);

        const handleIconClick = (event, row) => {
            setAnchorEl(event.currentTarget);
            nombreEstadoSelect = row?.estado
            let comprobantesEstados = JSON.parse(localStorage.getItem("siam-comprobantes_estados")).comprobantes_estados;
            const gridManipulabre = comprobantesEstados?.filter(comprobante => comprobante.id_comprobantes_codigos === row.id_codigo);
            setOptions(gridManipulabre)
            setIdComprobanteSelected(row.id)
        };

        const buildOptionsList = (params) => {
            let opcionesFiltradas = options.filter((opcion) => !opcion.nombre.includes(nombreEstadoSelect))
            return opcionesFiltradas.length > 0 ? (opcionesFiltradas.map(option => (
                <ListItem key={option.id} disablePadding>
                    <ListItemButton onClick={(event) => { event.stopPropagation(); handleClose(option); }}>
                        <ListItemText primary={option.nombre} />
                    </ListItemButton>
                </ListItem>
            ))
            ) : null;
        };

        const handleClose = (option) => {
            if (option?.nombre !== undefined) {
                setIdEstadoSelected(option.id)

                setMensajeConfirm(`El comprobante va a cambiar de estado ‘${nombreEstadoSelect}' al estado '${option?.nombre}’ - Confirma`)
                setModalStateConfirm(true)
            }
            setAnchorEl(false);

        };

        return params.value ? (
            <div>
                {params.value}
                <Button
                    onClick={(e) => {
                        handleIconClick(e, params.row);

                    }}>

                    <BorderColorIcon />
                </Button>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <List>
                        {buildOptionsList()}
                    </List>
                </Popover>
            </div>
        ) : null;
    };
    const buttonBuscar = (params) => (
        <Button
            endIcon={<SearchIcon />}
            onClick={() => {
                setAbrirBusqueda(!abrirBusqueda);

                if (params?.procesoSeleccionado?.id !== 1) {
                    if (params?.subProceso && params?.subProceso !== 0) {
                        setAbrirBusqueda(!abrirBusqueda);
                    }
                } else {
                    setAbrirBusqueda(!abrirBusqueda);
                }
            }}
            variant="contained"
            style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
            }}
        >
            Buscar
        </Button>
    );


    const [tipoCajas, setTipoCajas] = useState([]);
    const listName = clienteFichaSelected ? "Ficha Individual de : " + clienteFichaSelected : "Ficha Individual ";
    const columns = [
        { field: "fecha_comprobante", headerName: "Fecha", flex: 0.4, sortable: false },
        { field: "codigo", headerName: "Codigo", flex: 0.5, sortable: false },
        { field: "numero_comprobante", headerName: "Nro.", flex: 0.2, sortable: false },
        { field: "ingreso", headerName: "Ingreso", flex: 0.3, sortable: false },
        { field: "egreso", headerName: "Egreso", flex: 0.3, sortable: false },
        { field: "saldo", headerName: "Saldo", flex: 0.4, sortable: false },
        { field: "observaciones", headerName: "Observaciones", flex: 0.5, sortable: false },
        { field: "extraData", headerName: "Dato Extra", flex: 0.5, sortable: false, hide: !cuenta ? false : true },
        // { field: "tachar", headerName: "SeSubraya", flex: 0, sortable: false, hide: true },
        {
            field: "estado",
            headerName: "Estado",
            headerAlign: "center",
            flex: 0.5,
            sortable: false,
            renderCell: !cuenta ? CustomCellRenderer : null,
        },
    ];

    const [options, setOptions] = useState({
        cols: columns,
        getPaginated: getFichaData,
        listName,
        delete: deleteTipoUbicaciones,
        idElementData: criteriaFicha,
        buttonExtraOn: true,
        ButtonExtra: buttonBuscar
    });

    if (
        createButton === false &&
        tipoCajas.id !== undefined &&
        buttonMetadatos === false
    ) {
        setTipoCajas([]);
    }

    return (
        <>
            {abrirBusqueda && (
                <SimpleDialogComponent
                    open={abrirBusqueda}
                    modalName={"Busqueda de comprobantes"}
                    child={
                        <FormConsultaFicha
                            toggleModal={toggleModal}
                            setcriteriaFicha={setcriteriaFicha}
                            cerrarModal={setAbrirBusqueda}
                            setClienteSeleccionado={setClienteSeleccionado}
                            clienteSeleccionado={clienteSeleccionado}
                            fechaDesdeSelected={fechaDesdeSelected}
                            setFechaDesdeSelected={setFechaDesdeSelected}
                            fechaHastaSelected={fechaHastaSelected}
                            setFechaHastaSelected={setFechaHastaSelected}
                        />
                    }
                    setPushData={setPushData}
                    handleClose={toggleModal}
                    buttonEnviar={false}
                    buttonCancelar={true}
                />
            )}
            <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>

                <>

                    <Grid
                        style={{
                            background: "white",
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            fontFamily: 'Roboto, sans-serif',
                            borderRadius: 10,
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                            marginBottom: 20,
                            height: 50
                        }}
                    >
                        <Typography
                            align="left"
                            variant="h2"
                            component="div"
                            sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
                        >
                            {listName}
                        </Typography>
                        <CustomBreadcrumbs></CustomBreadcrumbs>
                    </Grid>
                    <ListTableFichas options={options} />
                    <TipoUbicacionesForm
                        data={tipoCajas}
                        createButton={createButton}
                        listName={listName}
                        setCreateButton={setCreateButton}
                        componentList={"/tipo_ubicaciones/admin"}
                        postData={postTipoUbicaciones}
                    />
                    <ConfimDialogComponent
                        open={modalStateConfirm}
                        modalName={"Confirmar Cambio de Estado"}
                        mensaje={mensajeConfirm}
                        handleConfirm={handleConfirm}
                        handleClose={toggleModalConfirm}
                    />
                    <SnackbarMessenger
                        open={snackbarVisible}
                        message={message}
                        severity={severity}
                        close={() => {
                            setSnackbarVisible(false);
                        }}
                    />

                </>
            </Grid>
        </>
    );
};


import datos from "src/services/datos";
import { postCuentasCategoriaCuentas } from "./CuentasCategoriaCuentas";
import { postCuentas } from "./CuentasData";
import { esArray } from "src/general_components/functionEsArray"

export const getCuentasFisicas = async () => {
    const objectCuentasFisicas = new datos("cuentas_personas_fisicas")

    objectCuentasFisicas.addCondition("cuentas_personas_fisicas", "id", 0, ">")
    //objectCuentasJuridicas.addOrderCondition("cuentas_personas_juridicas", "id","DESC")

    const response = await objectCuentasFisicas.get()

    return esArray(response.wsResponse.cuentas_personas_fisicas)
}

export const getCuentasFisicasPaginated = async (limit, offset, criterios) => {

    let id_cuentas = criterios.id;

    const objectCuentasFisicas = new datos("cuentas_personas_fisicas")

    objectCuentasFisicas.addCondition("cuentas_personas_fisicas", "id", 0, ">")
    //Cuando se ingresan criterios de Bsqueda
    Object.keys(criterios).length ? objectCuentasFisicas.innerJoinTables('cuentas_documentos_inscripciones', 'cuentas_personas_fisicas', "id_cuentas", "id_cuentas", "=") : criterios = {};
    Object.keys(criterios).length ? objectCuentasFisicas.innerJoinTables('cuentas_localizaciones', 'cuentas_personas_fisicas', "id_cuentas", "id_cuentas", "=") : criterios = {};
    objectCuentasFisicas.innerJoinTables('genero', 'cuentas_personas_fisicas', "id", "id_genero", "=");
    objectCuentasFisicas.innerJoinTables('estado_civil', 'cuentas_personas_fisicas', "id", "id_estado_civil", "=");

    id_cuentas ? objectCuentasFisicas.addCondition("cuentas_personas_fisicas", "id_cuentas", id_cuentas, "=") : id_cuentas = '';

    objectCuentasFisicas.addOrderCondition("cuentas_personas_fisicas", "id", "DESC")
    objectCuentasFisicas.addLimitCondition(limit, offset)

    const response = await objectCuentasFisicas.get()

    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

    let result = {}
    if (!response.wsResponse.empty) {
        const cuentas_personas_fisicas = esArray(response.wsResponse.cuentas_personas_fisicas)

        // Añadir las propiedades genero y estado_civil a cada objeto del array
        const datosConPropiedades = cuentas_personas_fisicas.map(item => ({
            ...item,
            genero: item.relations.genero.nombre,
            estado_civil: item.relations.estado_civil.nombre,
        }));

        result = {
            data: esArray(datosConPropiedades),
            total_records: response.wsResponse.total_records
        }
        return result

    }
};


export const postCuentasFisicas = async (CuentaFisica, CategoriaCuenta) => {
    const {
        id,
        razon_social,
        nombre_fantasia,
    } = CuentaFisica;


    if (/*!juridica_idcuenta ||*/ !razon_social || !nombre_fantasia) {
        return
    }

    const objectCuentasFisicas = new datos("cuentas_personas_fisicas")


    if (!id) {
        //const Cuentas = new datos("cuentas")
        const cuenta = {
            "es_owner": "0"
        }
        const response = await postCuentas(cuenta)
        if (response.wsResponse.cuentas > 0) {
            const cuenta_aux = { ...CuentaFisica }
            cuenta_aux.id_cuentas = response.wsResponse.cuentas
            const categoriaCuenta_aux = { ...CategoriaCuenta }
            categoriaCuenta_aux.id_cuentas = response.wsResponse.cuentas
            const response2 = await postCuentasCategoriaCuentas(categoriaCuenta_aux)
            if (response2.wsResponse.cuentas_categoria_cuentas > 0) {
                const response3 = await objectCuentasFisicas.insert(cuenta_aux)
                return response3
            }
            else return response2
        }
        else return response
    }
    await postCuentasCategoriaCuentas(CategoriaCuenta)
    return await objectCuentasFisicas.insert(CuentaFisica)
};

export const deleteCuentasJuridicas = async (id) => {
    console.log("Las cuentas no se borran!")
};

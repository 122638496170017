import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
const paises = new datos("pais");

export const getPais = async (auth) => {
    const paises = new datos('pais')
    paises.addCondition('pais', 'id', 0, '>')
    const response = await paises.get()

    return esArray(response.wsResponse.pais)
};

export const getPaisPaginated = async (limit, offset) => {
    paises.addCondition("pais", "id", 0, ">");
    paises.addLimitCondition(limit, offset);
    const response = await paises.get();
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

    let result = {};
    if (!response.wsResponse.empty) {
        result = {
            data: esArray(response.wsResponse.pais),
            total_records: response.wsResponse.total_records,
        };
        return result;
    }
};

export const deletePais = async (id) => {
    let status = false;
    await Swal.fire({
        title: "Esta seguro de que desea eliminar esta pais?",
        text: "Si sigue adelante, no se podran revertir los cambios!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, quiero eliminarlo!",
        cancelButtonText: "No, no estoy seguro!",
    }).then(async (result) => {
        if (result.isConfirmed) {
            const paises = new datos("pais");
            let data = {
                id: id,
            };
            const response = await paises.delete(data);

            if (response.status === "true") {
                status = true;
                await Swal.fire(
                    "Eliminado!",
                    "El pais ha sido eliminado exitosamente",
                    "success"
                );
            } else {
                await Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response.message,
                });
            }
        }
    });
    return status;
};

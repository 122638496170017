import { Grid, Tooltip, Box } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import {
  getCajasGenericasDisponibles,
  postCajasGenericasAlojadas,
} from "../helpers/CajasGenericasData";
import { ClienteSector } from "../components/ClienteSector";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import StyleIcon from "@mui/icons-material/Style";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { imprimirEtiquetas } from "../helpers/EtiquetasData";
import { SwitchAjuste } from "../components/SwitchAjuste";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MenuItem } from "@mui/material";

export const AlojamientoCajasGenericasList = () => {
  const { sectorId, clienteName, setSectorId, setClienteId, clienteId, actualizarListado, setActualizarListado } = useContext(CajasContext);
  const [selectionModel, setSelectionModel] = useState([]);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [actualizarSelected, setActualizarSelected] = useState("");
  const [ajuste, setAjuste] = useState(false);
  let data = {
    id_cuentas_localizaciones: sectorId,
    alojamiento: "1",
    clienteName: clienteName,
  };

  var columns = [];
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);

  if (isMobileDevice) {
    columns = [
      // { field: "id", headerName: "ID", flex: 0.3 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
      // { field: "referencia", headerName: "Referencia", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
      // { field: "detalle", headerName: "Detalle", flex: 1 },
      // { field: "fecha_creacion", headerName: "Fecha de Creacion", flex: 1 },
      // { field: "cliente", headerName: "Cliente", flex: 1 },
      {
        field: "clienteName",
        headerName: "Cliente",
        flex: 150,
        minWidth: 150,
        maxWidth: 175,
      }, // la propiedad flex permite setear responsivamente el tamaño de la columna
      {
        field: "sectorName",
        headerName: "Sector",
        flex: 150,
        minWidth: 150,
        maxWidth: 175,
      }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
      {
        field: "concatCaja",
        headerName: "Caja",
        flex: 150,
        minWidth: 150,
        maxWidth: 175,
      },
      {
        field: "estado",
        headerName: "Estado",
        flex: 150,
        minWidth: 150,
        maxWidth: 175,
      },
      {
        //Boton Imprimir
        field: "actions",
        headerName: "Acciones",
        type: "actions",
        width: 100,
        minWidth: 100,
        maxWidth: 125,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip title="Acciones">
                <span>
                  <MoreVertIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={(e) => {
              handleOpenMenu(e);
              setParams(params);
            }}
            label="Acciones"
            {...(params.id === "cliente0" || params.id === "sector1"
              ? { hidden: true }
              : {})}
          />,
        ],
      },
    ];
  } else {
    columns = [
      { field: "clienteName", headerName: "Cliente", flex: 2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
      { field: "sectorName", headerName: "Sector", flex: 2 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
      { field: "concatCaja", headerName: "Caja", flex: 1 },
      { field: "estado", headerName: "Estado", flex: 1 },
      {
        //Boton Imprimir
        field: "actions",
        headerName: "Acciones",
        type: "actions",
        width: 100,
        minWidth: 100,
        maxWidth: 125,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip title="Acciones">
                <span>
                  <MoreVertIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={(e) => {
              handleOpenMenu(e);
              setParams(params);
            }}
            label="Acciones"
            {...(params.id === "cliente0" || params.id === "sector1"
              ? { hidden: true }
              : {})}
          />,
        ],
      },
    ];
  }

  //Funcion para el boton imprimir
  const onClickEtiqueta = (params) => {
    const URI_aux = imprimirEtiquetas(params.id);
    if (URI_aux) window.open(URI_aux, "_blank");
    handleCloseMenu();
  };

  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(false);

  useEffect(() => {
    if (actualizarListado) {
      let data2 = {
        id_cuentas_localizaciones: sectorId.id,
        alojamiento: "1",
        clienteName: clienteName,
      };
      setOptions({
        ...options,
        idElementData: data2,
        sinFilas: false,
      });
      setActualizarListado(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualizarListado]);

  useEffect(() => {
    if (clienteName !== "" && sectorId) {
      let data2 = {
        id_cuentas_localizaciones: sectorId.id,
        alojamiento: "1",
        clienteName: clienteName,
        count: 0,
        page: 0,
      };
      setOptions({
        ...options,
        idElementData: data2,
        sinFilas: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteName]);

  const handleSubmit = async () => {
    // Se obtienen los id de los registros que se vayan seleccionando en la grilla
    const registrosSeleccionados = selectionModel.map((data) => {
      return { id: data };
    });
    let dataPost = {
      cajas: registrosSeleccionados,
      alojamiento: "1",
      ajuste: ajuste ? "1" : "0",
    };

    const response = await postCajasGenericasAlojadas(dataPost);

    if (response.status === "true") {
      setSnackbarVisible(true);
      setMessage("Las cajas seleccionadas se han alojado exitosamente");
      setSeverity("success");
      setAjuste(false);
      setSelectionModel([]);
      setSectorId(sectorId);
      setClienteId(clienteId);
      setActualizarSelected(true);
      setActualizarListado(true);
    } else {
      setSnackbarVisible(true);
      setMessage(response.message);
      setSeverity("warning");
    }
    return response;
  };

  const obtenerValuesSelected = (newValueSelected) => {
    setSelectionModel(newValueSelected);
  };

  const [options, setOptions] = useState({
    cols: columns,
    get: getCajasGenericasDisponibles,
    getPaginated: getCajasGenericasDisponibles,
    idElementData: data,
    pageSize: [5, 10, 25],
    limit: 50,
    filter: true,
    exportData: true,
    server: true,
    notas: true,
    sinFilas: true,
    selectCheckbox: true,
    valuesSelected: obtenerValuesSelected,
    hierarchyOn: true,
    detailsOn: true,
    abmContenidos: "false",
    checkboxContenidos: "false",
  });

  const setearAjuste = async (data) => {
    setAjuste(data);
  };
  // Parametros Popover
  const [open, setOpen] = useState(null); // variable popover
  // eslint-disable-next-line no-unused-vars
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  // Se utiliza para actualizar el array de id seleccionados en el listtable ya que el selectionModel
  // al limpiar sigue trayendo los elementos seleccionados anteriores
  useEffect(() => {
    if (actualizarSelected) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      setOptions({
        ...options,
        idElementData: data,
        sinFilas: true,
        actualizarSelected: actualizarSelected,
        setActualizarSelected: setActualizarSelected,
      });
    } else {
      setOptions({
        ...options,
        sinFilas: true,
        actualizarSelected: actualizarSelected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualizarSelected]);

  return (
    <>
      <Grid style={{ background: "white" }}>
        <Grid container
          spacing={2} style={{ marginBottom: 20, }}>

          <Grid item xs={11} >
            <ClienteSector />
          </Grid>
          <Grid item xs={1}>
            <Grid container paddingTop={0.2} justifyContent="flex-end">
              <Tooltip title="Buscar">
                <span>
                  <Button
                    disabled={
                      Array.isArray(sectorId) || sectorId === "" || sectorId === null
                        ? true
                        : false
                    }
                    endIcon={<SearchIcon />}
                    onClick={() => {
                      setActualizarListado(true);
                    }}
                    variant="contained"
                  >
                    Buscar
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <ListTablePaginated options={options} />
        </Grid>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={onClickEtiqueta}>
            <StyleIcon color="info" sx={{ mr: 2 }} />
            Imprimir
          </MenuItem>
        </Popover>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <SwitchAjuste
            setAjuste={setearAjuste}
            disabledCheckAjuste={selectionModel.length > 0 ? false : true}
          />

          <Tooltip title="Alojar">
            <span>
              <Button
                disabled={selectionModel.length > 0 ? false : true}
                endIcon={<Inventory2Icon />}
                onClick={() => {
                  handleSubmit();
                }}
                variant="contained"
              >
                Alojar
              </Button>
            </span>
          </Tooltip>
        </Box>
        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          close={() => {
            setSnackbarVisible(false);
          }}
        />
      </Grid>
    </>
  );
};

import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { schemaBasicIdNombre } from "../forms/schemaBasicIdDetalle";
import { uischemaBasicIdDetalle } from "../forms/uischemaBasicIdDetalle";
import { ComponentDialog } from "./ComponentDialog";

const localize = require("ajv-i18n");
export const BasicFormIdDetalle = (props) => {
  const auth = useContext(AuthContext);
  const {
    data,
    listName,
    componentList,
    createButton,
    setCreateButton,
    postData,
  } = props;

  const [dataInfo, setDataInfo] = useState({
    id: data.id || "",
    detalle: data.nombre || data.detalle || "",
  });

  //CONTROLA CUANDO SE SALE DE UN EDIT Y QUEDA CON BASURA LOS SELECTORES, COMPROBAR CUALQUIERA DE LOS DOS SELECTORES SI ES DISTINTO DE VACIO
  if (data.length === 0 && dataInfo.id !== "") {
    setDataInfo({
      id: "",
      detalle: "",
    });
  }

  //PARA QUE SE ACTUALICE EL SELECTOR CUANDO SE EDITA
  if (data.id && dataInfo.id === "") {
    setDataInfo({
      id: data.id || "",
      detalle: data.nombre || data.detalle || "",
    });
  }
  const [withErrors, setWithErrors] = useState(false);
  const [pushData, setPushData] = useState(false);
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect to={componentList} />; //Redirecciona a la pagina del listado dependiend del componente que se este usando
  }

  if (pushData) {
    postData(dataInfo, auth);
    setRedirect(true);
  }

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);

    setDataInfo(data);

    return { errors, data };
  };

  return (
    <>
      <ComponentDialog
        listName={listName}
        buttonClicked={createButton}
        setButtonClicked={setCreateButton}
        withErrors={withErrors} //Sirve para controlar el boton de crear y los errorres del formulario
        setPushData={setPushData} //Sirve para enviar los datos al servidor
        child={
          <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>
            <JsonForms
              schema={schemaBasicIdNombre}
              uischema={uischemaBasicIdDetalle}
              renderers={materialRenderers}
              data={dataInfo}
              cells={materialCells}
              onChange={({ errors, data }) =>
                onChange && onChange(errors, data)
              }
            />
          </Grid>
        }
      />
    </>
  );
};

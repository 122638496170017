export const uiSchemaBuscarCajas= {
    type: "VerticalLayout",
    elements: [
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/id",
            
          },
          {
            type: "Control",
            scope: "#/properties/id_tipo_caja",
            
          },
        ],
      },
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/referencia",
            
          },
          {
            type: "Control",
            scope: "#/properties/detalle",
            
          },
        ],
      },
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/id_tipo_estado",
            
          },
          {
            type: "Control",
            scope: "#/properties/fecha_creacion",
            
          },
        ],
      },
    ]
  }
  
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import { Buttons } from "./Buttons";
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

export const ComponentDialog = (props) => {
  const {
    listName,
    buttonClicked,
    withErrors,
    child,
    setPushData,
    setButtonClicked,
    nameButton,
  } = props;

  let errores = false;
  if (withErrors !== undefined) {
    errores = withErrors;
  }

  const Title = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF', // Color blanco para el título
    fontWeight: 'bold', // Puedes ajustar el peso de la fuente según tus preferencias
    fontSize: '1rem', // Tamaño de la fuente del título
    marginBottom: theme.spacing(0), // Espacio inferior para separar del contenido
  }));

  const CloseButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
  }));

  const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0),

  }));

  return (
    <div>
      <Dialog
        open={buttonClicked}
        keepMounted
        onClose={() => setButtonClicked(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle  >
          <Header>
            <div>
              <Title>{"Formulario " + listName}</Title>
            </div>
            <CloseButton onClick={() => setButtonClicked(false)}>
              <CloseIcon />
            </CloseButton>
          </Header>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <>{child}</>
        </DialogContent>
        <Divider />
        <DialogActions>

          <Buttons
            disableButton={errores}
            onClickSubmit={() => {
              setPushData(true);
            }}
            labelButtonSave={nameButton}
            buttonCerrar={() => setButtonClicked(false)}
            centrar={true}
          />
        </DialogActions>

      </Dialog>
    </div>
  );
};

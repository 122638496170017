import datos from "src/services/datos";
import Swal from "sweetalert2";
import { esArray } from "src/general_components/functionEsArray";

const estados = new datos("tipo_contenidos");

export const getTipoContenidos = async (tipoContenido) => {
  const objectTipoContenidos = new datos(
    "tipo_contenidos",
    "id",
    `${tipoContenido}`
  );
  objectTipoContenidos.addCondition("tipo_contenidos", "id", "id");
  const response = await objectTipoContenidos.get();
  if (response) {
    return response.wsResponse.tipo_contenidos;
  }
  return response;
};

export const getTipoContenidosCaja = async (digitalizado) => {
  const tipoContenidos = new datos('tipo_contenidos')
  tipoContenidos.addCondition('tipo_contenidos', 'id', 0, '>');

  if (digitalizado) {
    tipoContenidos.addCondition("tipo_contenidos", "digitalizado", digitalizado, "=");
  }
  const response = await tipoContenidos.get()
  const res = response.wsResponse.tipo_contenidos;
  // Si es un array se mantiene como estaba
  if (Array.isArray(res)) {
    var arr = res;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (typeof res === 'undefined') { // se pasa un array vacio
    arr = [];
  } else { // si trae un solo elemento , es un objeto
    arr = [res]; // se lo convierte a array
  }
  return arr;
};

export const getTipoContenidosYmetadatos = async (digitalizado) => {
  const tipoContenidos = new datos('tipo_contenidos')
  tipoContenidos.addCondition('tipo_contenidos', 'id', 0, '>');

  if (digitalizado) {
    tipoContenidos.addCondition("tipo_contenidos", "digitalizado", digitalizado, "=");
  }
  tipoContenidos.join('metadatos', "id_tipo_contenidos", "id", "=")
  const response = await tipoContenidos.get()
  const res = response.wsResponse.tipo_contenidos;
  // Si es un array se mantiene como estaba
  if (Array.isArray(res)) {
    var arr = res;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (typeof res === 'undefined') { // se pasa un array vacio
    arr = [];
  } else { // si trae un solo elemento , es un objeto
    arr = [res]; // se lo convierte a array
  }
  const objetosConRelations = arr.filter(objeto => objeto.hasOwnProperty('relations'));
  return objetosConRelations;
};

export const getTiposContenidos = async (auth) => {
  estados.addCondition("tipo_contenidos", "id", 0, ">");
  if (auth) {
    estados.addCondition("tipo_contenidos", "digitalizado", 1, "=");
  }
  const response = await estados.get();
  return response.wsResponse.tipo_contenidos;
};

export const getTipoContenidoLegajo = async (auth) => {
  const query = new datos("tipo_contenidos");
  query.addCondition("tipo_contenidos", "id", 2, "=");
  const response = await query.get();
  return response.wsResponse.tipo_contenidos;
};

export const getTipoContenidosPaginated = async (limit, offset,filtros) => {
  const tipoContenidos = new datos("tipo_contenidos");
  tipoContenidos.addCondition("tipo_contenidos", "id", 0, ">");
  if(filtros){
    if(filtros.id){
      tipoContenidos.addCondition("tipo_contenidos", "id", filtros.id, "=");
    }else if (filtros.detalle){
      tipoContenidos.addCondition("tipo_contenidos", "detalle",`'${filtros.detalle}'`, "=");
    }
  }
  tipoContenidos.addLimitCondition(limit, offset);
  const response = await tipoContenidos.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    let dataResponse = esArray(response.wsResponse.tipo_contenidos)
    result = {
      data: dataResponse,
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const postTipoContenidos = async (estado) => {
  const response = await estados.insert(estado);
  return response;
};

export const postTipoContenidosCuentasLocalizaciones = async (tipos_contenidos) => {
  const cuentas_localizaciones_tipo_contenidos_post = new datos("cuentas_localizaciones_tipo_contenidos");
  const response = await cuentas_localizaciones_tipo_contenidos_post.insert(tipos_contenidos);
  return response;
};
export const deleteTipoContenidos = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este tipo de contenido?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos('tipo_contenidos');
      let data = {
        id: id
      }
      const response = await userData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El tipo de contenido ha sido ha sido eliminada exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message
        });
      }
    }
  });

  return status;
};

export const getCuentasLocalizacionesTiposContenidos = async () => {
  const cuentas_localizaciones_tipo_contenidos = new datos("cuentas_localizaciones_tipo_contenidos");
  cuentas_localizaciones_tipo_contenidos.addCondition("cuentas_localizaciones_tipo_contenidos", "id", 0, ">");
  const response = await cuentas_localizaciones_tipo_contenidos.get();
  if(response.status === 'true'){
    return esArray(response.wsResponse.cuentas_localizaciones_tipo_contenidos)
  }else{
    return [];
  }
};



export const deleteTipoContenidosCuentaLocalizacion = async (id_cuenta_localizaciones) => {
  const cuentas_localizaciones_tipo_contenidos_delete = new datos('cuentas_localizaciones_tipo_contenidos');
  let data = {
    id_cuentas_localizaciones: id_cuenta_localizaciones
  }
  const response = await cuentas_localizaciones_tipo_contenidos_delete.delete(data);

  return response;
};
import React from "react";
import { useState } from "react";
import {
  deleteGenero,
  getGeneros,
  getGenerosPaginated,
  postGenero,
} from "../general_helpers/GenerosData";
import { ListTablePaginated } from "./ListTablePaginated";
import { BasicFormIdDetalle } from "./BasicFormIdDetalle";

export const GenerosList = (data) => {
  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "nombre", headerName: "Nombre", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
  ];

  const listName = "Generos";

  const [generos, setGeneros] = useState([]);
  const [createButton, setCreateButton] = useState(false);

  const options = {
    cols: columns,
    get: getGeneros,
    getPaginated: getGenerosPaginated,
    pageSize: [5, 10, 25],
    edit: true,
    listName,
    delete: deleteGenero,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setGeneros,
  };

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  if (createButton === false && generos.id !== undefined) {
    setGeneros([]);
  }

  return (
    <>
      <BasicFormIdDetalle
        data={generos}
        createButton={createButton}
        listName={listName}
        setCreateButton={setCreateButton}
        componentList={"/generales/generos/admin"}
        postData={postGenero}
      />
      <ListTablePaginated options={options} />
    </>
  );
};

import FormControl from "@mui/material/FormControl";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { getContactos } from "../helpers/ContactosData";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { CuentasContactosFormProcesosVarios } from "./CuentasContactosForm";

export const SelectCuentaContacto = (data) => {
  const {
    disabled,
  } = useContext(CajasContext);

  //Variables para el formulario ya sea edicion o creacion
  const {
    contactoSelect,
    setContactoSelect,
    idCuenta
  } = data;

  const [tiposContactos, setTiposContactos] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [actualizarSelectorContacto, setActualizarSelectorContacto] = useState(false);
  const [newContacto, setNewContacto] = useState(false);

  useEffect(() => {
    const getTipoContactos = async () => {
      setIsLoadingData(true);
      let response = idCuenta?.id_cuentas? await getContactos(idCuenta?.id_cuentas) : [];
      if(response.length < 1){
        setContactoSelect(null)
      }
      if(newContacto){
        const idBuscado = newContacto; // Puedes cambiar esto al id que estás buscando
        const objetoEncontrado = response.find(objeto => objeto.id === idBuscado);
        if (objetoEncontrado) {
          setContactoSelect(objetoEncontrado);
        } else {
          console.log("Objeto no encontrado");
        }
      }
      
      setTiposContactos(response);
      setIsLoadingData(false);
    };
    getTipoContactos();

  }, [idCuenta, newContacto, setContactoSelect]);


  const handleChange = (event, newValue) => {
    setContactoSelect(newValue)
  };

  const estilosSelect = {
    fontSize: "1em", color: "#646464", fontFamily: 'Roboto, sans-serif', backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  const toggleModal = () => {
    setModalState(!modalState)
  }

  const updateSelectorContacto = () => {
    setActualizarSelectorContacto(!actualizarSelectorContacto)
  }

  const dialogChild = <CuentasContactosFormProcesosVarios setNewContacto={setNewContacto} updateSelectorContacto={updateSelectorContacto} handleClose={toggleModal} idCuenta={idCuenta}/>

  if (isLoadingData && !data.ImportFile) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <FormControl sx={{ mt: data.ImportFile ? 2 : 1, minWidth: "100%" }} size="small">
              <Autocomplete
                size="small"
                value={contactoSelect || null}
                onChange={handleChange}
                options={tiposContactos || []}
                style={data.ImportFile ? estilosSelect : null}
                getOptionLabel={(option) => option.id + ' - ' + option.descripcion || ""}
                renderInput={(params) => (
                  <TextField {...params}
                    label="Contactos"
                    disabled={disabled} />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button sx={{ mt: 1.3, minWidth: "100%", fontSize: '12px', fontFamily: 'Roboto, sans-serif' }} 
              variant="contained" color="success" startIcon={<AddIcon />} disabled={idCuenta?.id_cuentas? false : true} onClick={toggleModal}>
              Contacto
            </Button>
          </Grid>
        </Grid>

        <SimpleDialogComponent
          open={modalState}
          modalName={"Cuentas Contactos"}
          child={dialogChild}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      </div>
    );
  }
};

import { AddCircle, Edit } from "@mui/icons-material";
import { Button, Container, Grid, Tooltip } from "@mui/material";
import { GridActionsCellItem, esES } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { SearchContenidosFormEdit } from "./SearchContenidosFormEdit";
import { CustomToolbar } from "../../gestion_documental/components/ListadoInformeCajas"

/**
 **       cols: define las columnas que se van a mostrar | required 
 *       get: funcion con las que se obtienen los datos para mostrar (get()), 
 *       getPaginated: get pero con paginacion, | required
 *       pageSize: tamaño de la pagina/quizas deprecado | required aunque por defecto usa 10
 *       listName: nombre a mostrarse en la lista | required
 *       redirectURLForm: formulario donde redirecciona la lista (en caso de usarse)// con modales, deprecado,
 *       redirectURLList: lista donde retorna o muestra luego del la creacion/edicion de los datos, deprecado
 *       create: funcion que muestra el modal|boton (se usa el mismo formulario) para agregar|editar datos,
 *       delete: funcion para eliminar datos, false por defecto
 *       edit: por defecto, false. permite editar los datos de la columna en el form de create. combinado en false con delete, no muestra la colmuan de acciones   
 *       limit: limite para la paginacion,
 *       server: false por defecto | indica si la paginacion se hace desde el servidor usando el limit. Ademas, es flag de paginacion. FUNDAMENTAL PARA PAGINACION SERVERSIDE  
 *       filter: false por defecto | muestra los filtros del datagrid para los listados
 *       exportData: false por defecto | muestra el boton export del datagrid para los listados
 *       density: false por defecto | muestra la densidad del datagrid
 *       columnsTool: false por defecto | muestra la tool para las columnas del datagrid
 * 
 *      DATOS PARA USAR EL FORMULARIO, las tres propiedades son opcionales y se llaman desde el list 
 *      No es necesario definir ninguno de los 3 si es que no se llega a tener que usar el formulario (tener cuidado con el edit)
 *       createButton: createButton,  // boton para crear un nuevo registro
 *       setCreateButton: setCreateButton, // funcion para setear el boton de crear un nuevo registro
 *       setDataEdit: setEstadosCajasSeleccionado,  // funcion para setear los datos del registro a editar
 * 
 * 
 * Example:
 **       const options = {
        cols: columns,
        get: getGeneros,
        getPaginated: getGenerosPaginated,
        pageSize: 5,
        listName: "Generos",
        redirectURLForm: "/generales/generos-form",
        redirectURLList: "/generales/generos-list",
        create: GenerosForm,
        edit: true,
        delete: deleteGenero,
        limit: 10,
        filter: true,
        exportData: true,
        server: true

        createButton: createButton,
        setCreateButton: setCreateButton,
        setDataEdit: setEstadosCajasSeleccionado,
    }
 */

export const ListTablePaginated = (data) => {
  const {
    selectionModel,
    setSelectionModel,
    modalState,
    setModalState,
    setTipoContenido,
  } = useContext(CajasContext);

  //Variables para el formulario ya sea edicion o creacion
  const { setDataEdit, createButton, setCreateButton } = data.options;

  let buttomCreate = createButton;
  if (createButton === undefined) {
    buttomCreate = true;
  }

  const [columns, setColumns] = useState(data.options.cols); // columnas que se muestran

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [editButton, setEditButton] = useState(
    data.options.edit ? true : false
  );

  /* los siguientes estados son para controlar la paginacion */

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(data.options.limit || 10); // LIMITE DEL FETCH. POR DEFECTO ES 10

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [totalRowCount, setTotalRowCount] = useState(0); // FILAS TOTALES QUE TRAE EL FECTCH SIN PAGINACION EL

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [refresh, setRefresh] = useState(false); // REFRESCAR LA TABLA
  const [selectedForm, setSelectedForm] = useState(false);
  const [modalName, setModalName] = useState(false);

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [savedData, setSavedData] = useState(false);
  /* Se arma la columna "Acciones que va a tener editar y borrar " */

  /* se actualiza el limite de la tabla en funcion de si se trae un limite para haver el fetch */
  useEffect(() => {
    setLimit(data.options.limit || 10);
  }, [data]);

  const verBotonesColumn = {
    field: "Acciones",
    headerName: "Acciones",
    type: "actions",
    flex: 0.7,
    getActions: (params) => [
      <GridActionsCellItem
        hidden={params.row.cajaConcat ? true : false}
        icon={
          <Tooltip title="Editar">
            <span>
              <Edit color="info" />
            </span>
          </Tooltip>
        }
        onClick={() => {
          setTipoContenido(params.row.idTipoContenido);
          setSelectedForm(<SearchContenidosFormEdit data={params.row} />);
          setModalName("Modificacion de Contenido");
          toggleModal();
        }}
        label="MenuBook"
      />,
    ],
  };

  /* funcion que trae los records a mostrar en la tabla. Se ejecuta cada vez que se cambia la pagina o se 
    modifica la cantidad de rows a mostart */

  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true);
  //     const response = await data.options.getPaginated(limit, limit * page); //probelma esta cuando el offfsett (limit*page supera la cantidad de registros)
  //     if (response) {
  //       setRecords(response.data); // EL RESPONSE SE DESARMA EN EL CONTROLADOR DEL MODELO PARA TRAER POR SEPARADO LOS DATOS Y LA CANTIDAD DE RESULTADOS
  //       if (editButton && deleteButton) {
  //         let cols_aux = [...data.options.cols];
  //         cols_aux.push(actionColumn);
  //         setColumns(cols_aux);
  //       }
  //       setTotalRowCount(parseInt(response.total_records)); // SE ASIGNA LA CANTIDAD TOTAL DE RESULTADOS QUE EXISTEN EN LA DB CON RESPECTO A ESE FETCH
  //     }
  //     setRefresh(false);
  //     setLoading(false);
  //   };

  //   !data.options?.res && server && getData(); // SOLO SE REALIZA EL FETCH SI SE USA LA PAGINACION, POR LO QUE SE DEBE ASIGNAR LA FUNCION DEL CONTROLADOR
  //   // SI SE QUIERE USAR SIN PAGINACION, SE PUEDE PASAR DIRECTAMENTE EL ARREGLO A MOSTRAR
  // }, [page, pageSize, refresh]);

  /* ESTADO DEL CONTEO DE PAGINAS */

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);

  /* actualiza el estado de la pagina y donde se encuentra el paginador */

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [totalRowCount, setRowCountState]);

  useEffect(() => {
    if (editButton) {
      let cols_aux = [...data.options.cols];
      cols_aux.push(verBotonesColumn);
      setColumns(cols_aux);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  //Funcion para poder abrir el popup o para que te lleve al formulario de edicion o creacion
  const handleForm = (dataSelect) => {
    if (dataSelect.row) setDataEdit(dataSelect.row);

    setCreateButton(!createButton);
  };

  const getTreeDataPath = (row) => row.hierarchy;
  const groupingColDef = {
    headerName: "",
    hideDescendantCount: true,
    valueFormatter: () => "",
    width: 50,
  };

  return (
    <Container
      maxWidth="xxl"
      sx={{
        ".cajaStyle": {
          backgroundColor: "#CECECE",
          color: "#1a3e72",
        },
        "& .contenidoStyle": {
          backgroundColor: "#ff943975",
          color: "#1a3e72",
        },
        "& .colorCajaEstadoAlojada": {
          backgroundColor: "#CECECE",
          color: "#0058CF",
          fontWeight: "bolder",
        },
        "& .colorCajaEstadoEnCliente": {
          backgroundColor: "#CECECE",
          color: "#CF0000",
          fontWeight: "bolder",
        },
        "& .colorContenidosEstadoActivo": {
          color: "#61AC33",
          fontWeight: "bolder",
        },
        "& .colorContenidosEstadoActivoRetiradoTotalmente": {
          color: "#AC3833",
          fontWeight: "bolder",
        },
        "& .colorContenidosEstadoActivoRetiradoParcialmente": {
          color: "#FFA600",
          fontWeight: "bolder",
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4>Listado de {data.options.listName}</h4>
        </Grid>
        {!buttomCreate && (
          <Grid item xs={1}>
            <Button variant="contained" size="small" onClick={handleForm}>
              <AddCircle />
              <div>Agregar {/* BOTON AGREGAR */}</div>
            </Button>
          </Grid>
        )}
        <Grid /*height="800px"*/ item xs={12}>
          <DataGridPro
            {...(data.options.selectCheckbox && { checkboxSelection: true })}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            isRowSelectable={(params) =>
              params.row.contenidoConcat === undefined
            }
            autoHeight
            autoPageSize
            getRowHeight={() => "auto"}
            getCellClassName={(params) => {
              if (
                params.field === "estado" &&
                params.row.cajaConcat !== undefined
              ) {
                return "colorCajaEstadoAlojada";
              }
              if (params.row.cajaConcat !== undefined) {
                return "cajaStyle";
              }
              if (
                params.row.cajaConcat === undefined &&
                params.field === "estado"
              ) {
                switch (params.row.estado) {
                  case "Activo":
                    return "colorContenidosEstadoActivo";

                  case "Activo Retirado Totalmente":
                    return "colorContenidosEstadoActivoRetiradoTotalmente";

                  case "Activo Retirado Parcialmente":
                    return "colorContenidosEstadoActivoRetiradoParcialmente";

                  default:
                    break;
                }
              }
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText} // hace que la cabecera de los filtros este español
            components={{ Toolbar: CustomToolbar }}
            sx={{
              // FORMATO DE LAS CABECERAS // TODO: CSS
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#3c4b64",
                color: "#FFF",
                fontSize: 16,
              },
            }}
            treeData
            rows={data.options.datos} // filas de la tabla OBTENIDAS EN EL FETCH
            columns={columns} // columnas que se van a mostrar || DEBEN CORRESPONDERSE CON LAS QUE TRAE EL FECTH EN EL RECORD
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            defaultGroupingExpansionDepth={1}
            //getRowId={(row) => row.fecha_creacion}
            rowCount={data.options.datos.length}
          // loading={loading}
          // rowsPerPageOptions={[10, 30, 50, 75, 100]}

          // pageSize={pageSize}
          //onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          // rowsPerPageOptions={[10]}
          // pagination

          // pagination
          // page={page}
          // pageSize={limit || 10}
          // rowsPerPageOptions={[5,10,20]}

          // // paginationMode="server"// PAGINACION VOLCADA AL SERVIDOR

          // onPageSizeChange={(newPageSize) => {
          //    newPageSize !== 0 && setLimit(newPageSize); //en el primer renderizado setea a 0 el limit y por esto no funcionaba el cambiar de paginas

          //   setPageSize(newPageSize);
          // }}

          // onPageChange={(newPage) => {
          //   setPage(newPage);
          // }}
          // {...data.options.props}
          // se pueden hacer las celdas editables individualmente
          />
          <SimpleDialogComponent
            open={modalState}
            modalName={modalName}
            child={selectedForm}
            handleClose={toggleModal}
            buttonEnviar={false}
            setPushData={savedData}
            buttonCancelar={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

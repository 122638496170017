import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import { ComponentDialog } from "src/general_components/ComponentDialog";
import { SelectorCategoriaCuentasComponent } from "./forms/customRenders/selectorCategoriaCuentas";
import { schemaTipoCuentasForm } from "./forms/schemaTipoCuentasForm";
import { uiSchemaTipoCuentasForm } from "./forms/uiSchemaTipoCuentasForm";

const localize = require("ajv-i18n");
export const TipoCuentasForm = (props) => {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false)


  const {
    listName,
    componentList,
    createButton,
    setCreateButton,
    postData,
  } = props;


  const [withErrors, setWithErrors] = useState(false);
  const [pushData, setPushData] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const initialData = ({
    id: "",
    detalle: "",
    id_categoria_cuentas: "",
    selectedCategoriaCuentas: "",
  });

  const [dataInfo, setDataInfo] = useState({
    ...initialData
  });

  useEffect(() => {
    if (props.data) {

      setLoading(true)

      setDataInfo({
        id: props?.data?.id,
        detalle: props?.data?.detalle,
        id_categoria_cuentas: props?.data?.id_categoria_cuentas,
        selectedCategoriaCuentas: { id: props?.data?.id_categoria_cuentas || null }
      });

    }
    setLoading(false)
  }, [props?.data])

  //CONTROLA CUANDO SE SALE DE UN EDIT Y QUEDA CON BASURA LOS SELECTORES, COMPROBAR CUALQUIERA DE LOS DOS SELECTORES SI ES DISTINTO DE VACIO
  if (redirect) {
    return <Redirect to={componentList} />; //Redirecciona a la pagina del listado dependiend del componente que se este usando
  }


  if (pushData) {
    let dataToSend = { ...dataInfo };
    dataToSend.id_categoria_cuentas = dataInfo.selectedCategoriaCuentas.id;
    setDataInfo({ ...dataToSend });
    postData(dataToSend, auth);
    setRedirect(true);
  }

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);

    setDataInfo(data);
    return { errors, data };
  };

  return (
    <>
      <ComponentDialog
        listName={listName}
        buttonClicked={createButton}
        setButtonClicked={setCreateButton}
        withErrors={withErrors} //Sirve para controlar el boton de crear y los errorres del formulario
        setPushData={setPushData} //Sirve para enviar los datos al servidor
        child={
          <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>
            {!loading &&
              <JsonForms
                schema={schemaTipoCuentasForm}
                uischema={uiSchemaTipoCuentasForm}
                renderers={[
                  ...materialRenderers,
                  SelectorCategoriaCuentasComponent,
                ]}
                data={dataInfo}
                cells={materialCells}
                onChange={({ errors, data }) =>
                  onChange && onChange(errors, data)
                }
              />}
          </Grid>
        }
      />
    </>
  );
};

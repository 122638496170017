import datos from "src/services/datos";
import { esArray } from "src/general_components/functionEsArray";


export const getLocalidad = async (id_departamento) => {
    const localidades = new datos('localidad')
    localidades.addCondition('localidad', 'id', 0, '>')

    if (id_departamento) {
        localidades.addCondition('localidad', 'id_departamento', id_departamento, '=');
    }

    const response = await localidades.get()
    return esArray(response?.wsResponse?.localidad)
};
// TODO: useEffect no se esta usando, revisar la necesidad de importarlo
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import {  getArticulosCategorias } from "../../projects/articulos/helpers/ArticulosCategoriaData";
import { Autocomplete, TextField } from "@mui/material";
import {esArray} from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";



const SelectorArticulosCategorias = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo= jsonForm.core.data.selectedCategoria;

  const categorias = getFromLS("siam-categorias").categorias;
  const [categoriaDeArticuloSeleccionado, setCategoriaDeArticuloSeleccionado] = useState('');

  useState(() => {
    const categoriasDeArticulos = async () => {
      if (categorias) {
        setDataDefaults(esArray(categorias));
      }else {
        const response = await getArticulosCategorias()
        if (response) {
          setDataDefaults(esArray(response));
        }
      }
    }
    categoriasDeArticulos()
  }, []);

  useEffect(() => {
    setCategoriaDeArticuloSeleccionado(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
    setCategoriaDeArticuloSeleccionado(newValue);
    props.handleChange(props.path, newValue.id );
    }else{
      props.handleChange(props.path, "");
    }
    
  };
  
  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
         <Autocomplete
        value={categoriaDeArticuloSeleccionado  ? categoriaDeArticuloSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.nombre || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Categoria" 
          disabled={disabled} />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorArticulosCategoriaTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorArticulosCategorias";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform 
export const SelectorArticulosCategoriaComponent = {
  renderer: withJsonFormsControlProps(SelectorArticulosCategorias),
  tester: SelectorArticulosCategoriaTester,
};
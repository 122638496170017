import datos from "src/services/datos";

//Para el selector de tipo cuentas
export const getModeloArticulos = async (auth) => {
    const articulos = new datos("articulos");
    articulos.addCondition("articulos", "id", 0, ">");
    const response = await articulos.get();
    const res = response.wsResponse.articulos;
  
    // Si es un array se mantiene como estaba
    if (Array.isArray(res)) {
      var arr = res;
      // Si no es un array... primero se verifica que tenga valores ... si no los tiene
    } else if (typeof res === "undefined") {
      // se pasa un array vacio
      arr = [];
    } else {
      // si trae un solo elemento , es un objeto
      arr = [res]; // se lo convierte a array
    }
    return arr;
  };
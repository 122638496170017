
import React from 'react';
import { Grid } from '@mui/material';
import { DataGridPro } from "@mui/x-data-grid-pro";
import { v4 as uuidv4 } from 'uuid';

export const ComprobantesDashboard = (data) => {
    const columns = [
        { field: "numero_comprobante", headerName: "Numero", flex: 0.3 },
        { field: "estado", headerName: "Estado", flex: 0.4 },
        { field: "caja", headerName: "Caja", flex: 0.6 },
        { field: "fecha", headerName: "Fecha", flex: 0.6 },
        { field: "observaciones_accesorias", headerName: "Observaciones", flex: 0.6 },
        { field: "total", headerName: "Total", flex: 0.6 },
    ];
    const dataConId = data.comprobantes.map((obj, index) => ({
        ...obj,
        id: uuidv4(),
        detalle: typeof obj.detalle === 'object' && Object.keys(obj.detalle).length === 0
            ? ''  // Asigna un string vacío si 'detalle' es un objeto vacío
            : obj.detalle,
        total: "R$ " + (typeof obj.total === 'string' ? (Number(obj.total)).toFixed(2) : obj.total)
    }));


    return (
        <Grid container display={'block'} spacing={2} marginTop={2} justifyContent={'flex-start'} >
            <Grid item style={{ height: 550, width: "100%", padding: 10 }}>
                <DataGridPro
                    density="compact"
                    rows={dataConId}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pagination={false}
                    showToolbar={false}
                    hideFooterRowCount
                    hideFooter={true}
                />
            </Grid>
        </Grid>

    );
}
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { TheLayout } from "src/containers";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

export const MainRouter = () => {
    return (
        <>
            <React.Suspense fallback={loading}>
                <Switch>
                    <Route
                        path="/"
                        name="Home"
                        render={(props) => <TheLayout {...props} />}
                    />
                    <Redirect to="/" />
                </Switch>
            </React.Suspense>
        </>
    );
};

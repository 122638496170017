export const schemaFiltroServiciosFullForm = {
    type: "object",
    properties: 
    {
        "activo": {
          type: 'boolean',
            title: "Activo",
            description: "Activo",
            default: true
          },
          "cantidad": {
            type: "integer",
            title: "Cantidad",
            description: "Cantidad",
            "minimum": 0,            
          },
          "importe": {
            type: "number",
            title: "Importe",
            description: "Importe",
            "minimum": 0,
          },
          "activoFechaInicio": {
            type: 'boolean',
            title: "Activo Inicio",
            description: "Activo Inicio",
          },
          "fechaActivoInicio": {
            type: "string",
            customRender: "DateForm"
          },
          "activoFechaFin": {
            type: 'boolean',
            title: "Activo Fin",
            description: "Activo Fin",
          },
          "fechaActivoFin": {
            type: "string",
            customRender: "DateForm",
          },
          "descripcion": {
            type: "string",
            title: "Descripcion",
            description: "Descripcion",
          },
          "selectedCuentaJuridica": {
            type: "object",
            customRender: "SelectorCuentasJuridicas",
        },
        "selectedServicio": {
            type: "object",
            customRender: "SelectorServicios",
        },        
        
    },
    required: [ "selectedCuentaJuridica","selectedServicio", "cantidad", "importe"],
}
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export const SelectRetiroContenido = (props) => {
  // const { tipoRetiroSeleccionado, setTipoRetiroSeleccionado } =
  //   useContext(CajasContext);
  const {
    setTipoRetiroSeleccionado,
    tipoRetiroSeleccionado
  } = props;

  const estilosSelect = { fontSize: "1em", color: "#646464" };
  const ITEM_HEIGHT = 45;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };


  return (
    <div>
      <FormControl sx={{ mt: 1, minWidth: "100%" }} size="small">
        <InputLabel id="labelTipoContenido">Tipos de Retiro</InputLabel>
        <Select
          sx={{ backgroundColor: "#FFFFFF" }}
          style={{ ...estilosSelect, marginBottom: "20px" }}
          MenuProps={MenuProps}
          labelId="labelTipoRetiro"
          value={tipoRetiroSeleccionado}
          id="selectTipoRetiro"
          label="Tipo de Retiro"
          onChange={(e) => {
            // setTipoRetiroSeleccionado(e.target.value);
            setTipoRetiroSeleccionado(e.target.value);
          }}
        >
          <MenuItem style={estilosSelect} value="">
            Ninguno
          </MenuItem>
          <MenuItem style={estilosSelect} value="1" selected>
            Retiro Total
          </MenuItem>
          <MenuItem style={estilosSelect} value="2" selected>
            Retiro Parcial
          </MenuItem>
          <MenuItem style={estilosSelect} value="5" selected>
            Quita Definitiva
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

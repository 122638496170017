/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { JsonForms } from "@jsonforms/react";
import { schemaBuscarCajas } from "src/forms/schemaBuscarCajas";
import { uiSchemaBuscarCajas } from "src/forms/uiSchemaBuscarCajas";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { SelectorTipoCajasComponent } from "src/forms/customRenders/selectorTipoCajas";
import { SelectorEstadoCajasComponent } from "src/forms/customRenders/selectorEstadoCaja";
import { DateFormComponent } from "src/forms/customRenders/dateForm";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { getCajasByIDEstadoTipoReferenciaDetalleFecha,getCajasByIDEstadoTipoReferenciaDetalleFechaPaginated } from "../../helpers/CajasData";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";

const BuscarCajas = (props) => {
  const { snackbarVisible,
    setSnackbarVisible,
    message,
    setMessage,
    severity,
    setSeverity, setSelected, setListRecords, setCajaId} = useContext(CajasContext);
  const initialDataForm = {
    id: undefined,
    id_cuentas_localizaciones: props?.sector?.localizacion,
    id_tipo_caja: undefined,
    id_tipo_estado: undefined,
    referencia: undefined,
    detalle: undefined,
    fecha_creacion: undefined,
  };
  
  const [dataForm, setDataForm] = useState(initialDataForm);
  // eslint-disable-next-line no-unused-vars
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [searchParams, setSearchParams] = useState({...dataForm}); 
  const [actualizarListado, setActualizarListado] = useState(false);

  
  const columns = ([
    { field: "IDandRef", headerName: "Caja", flex: 0.5 }, 
    { field: "cuenta", headerName: "Cuenta", align: "center", flex: 1 },
    { field: "estado", headerName: "Estado", align: "center", flex: 0.5 },
  ])  


  const obtenerValuesSelected = (newValueSelected) => {
    setSelectionModel(newValueSelected);
  };


  const [options, setOptions] = useState({
    cols: columns,
    get: getCajasByIDEstadoTipoReferenciaDetalleFecha,
    getPaginated: getCajasByIDEstadoTipoReferenciaDetalleFechaPaginated,
    setListRecords: setListRecords,
    idElementData: searchParams,
    pageSize: [5, 10, 25],
    listName: "Listado de Cajas",
    limit: 10,
    filter: true,
    exportData: true,
    server: true,
    sinFilas: true,
    selectCheckbox: true,
    valuesSelected: obtenerValuesSelected,
    oneSelect: true
  });

  useEffect(() => {
    if(actualizarListado){
      setOptions({
        ...options,
        idElementData: searchParams,
        sinFilas: false,
      });
      setActualizarListado(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualizarListado]);

  const searchByParams = async () => {
    
    if ((dataForm.id === undefined)             &&
        (dataForm.id_tipo_caja === undefined)   &&
        (dataForm.id_tipo_estado === undefined) &&
        (dataForm.referencia === undefined)     &&
        (dataForm.detalle === undefined)        &&
        (dataForm.fecha_creacion === undefined) ) {
      setSeverity("warning");
      setMessage("Se debe completar al menos un campo para Buscar")
      setSnackbarVisible(true);
    } else {
      let regexId = /^[0-9,]+$/;
      let trimedIds = dataForm?.id && dataForm.id.replace(/\s/g,"");      
      if (!regexId.test(trimedIds) && trimedIds != undefined) {
        setSeverity("warning");
        setMessage("Por favor, use solo valores numericos separados por coma como ID")
        setSnackbarVisible(true);
      } else {
        setSearchParams({...dataForm,ids: trimedIds})
        setActualizarListado(true);
      }
    }
  };

  const handleSelected = async () => {
    setCajaId(selectionModel[0]);
    setSelected(selectionModel);
    setMessage("Caja seleccionada con exito!!!");
    setSeverity('success');
    setSnackbarVisible(true);
    props.onClose(false);
  };

  return (
    <>
      <div style={{ textAlign: "center", paddingTop: "20px" }}>
        <JsonForms
          schema={schemaBuscarCajas}
          uischema={uiSchemaBuscarCajas}
          data={dataForm}
          renderers={[
            ...materialRenderers,
            SelectorTipoCajasComponent,
            SelectorEstadoCajasComponent,
            DateFormComponent,
          ]}
          cells={materialCells}
          onChange={({ data, _errors }) => setDataForm(data)}
        />
        <div style={{ textAlign: "center", paddingTop: "20px" }}>
          <Button variant="contained" onClick={searchByParams}>
            Buscar Cajas <Search />
          </Button>
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <h5>{rows.length > 0 && "Cajas Encontradas"}</h5>
        </Grid>
  
        <Grid style={{ marginTop: "15px" }} item xs={12}>
          <ListTablePaginated options={options} />
        </Grid>
      </Grid>
      <div style={{paddingTop: "25px", textAlign:"right"}}>
        <Button variant="contained" disabled={selectionModel.length == 0}  onClick={handleSelected} >Seleccionar Cajas</Button>
      </div>
      


      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </>
  );
};
export default BuscarCajas;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { useContext, useState, useEffect } from "react";
import { CajasContext } from "../../../context/CajasContext/CajasContext";
import { DateFormComponent } from "../../../forms/customRenders/dateForm";
import {
  InputNumberComponent,
  InputStringComponent,
} from "../../../forms/customRenders/dobleInput";
import { SelectorTipoCajasComponent } from "../../../forms/customRenders/selectorTipoCajas";
import { schemaCajasForm } from "../../../forms/schemaCajasForm";
import { uiSchemaCajasForm, uiSchemaCajasFormSinClienteSector, uiSchemaCajasFormEdit } from "../../../forms/uiSchemaCajasForm";
import { postCajas } from "../helpers/CajasData";
import esArray from "src/general_components/functionEsArray";
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './styles/JsonForm.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';  // Nota el cambio aquí
import CssBaseline from '@mui/material/CssBaseline';  // Nota el cambio aquí
import { ClienteSector } from "./ClienteSector";
import { Grid } from "@mui/material";

export const FormCajasProcesosVarios = (data = null) => {
  const [cantidadIdsCajasCreadas, setCantidadIdsCajasCreadas] = useState(0);
  const [idMasAltos, setIdMasAlto] = useState(0);
  const [idMasBajos, setIdMasBajo] = useState(0);

  const date = new Date();
  const localize = require("ajv-i18n");
  const dateConvertido = moment(date).local().format("YYYY-MM-DD HH:MM:SS");
  const {
    setSeverity,
    setMessage,
    setSnackbarVisible,
    sectorId,
    idsCajasCreadas,
    setIdsCajasCreadas,
    disabledButtonsCopy,
    setDisabledButtonsCopy
  } = useContext(CajasContext);

  const {
    descripcionCuentaSectorFormCajas,
    toggleModal,
    sectorIdFormCajas,
    clienteSectorFormCajas,
    dataCajasFormEdit
  } = data;

  const {
    id_caja_edit,
    id_tipo_caja,
    id_cuentas_localizaciones,
    detalle,
    referencia,
    ubicacion_temporal
  } = dataCajasFormEdit;


  /// Contiene los datos del Jsonform
  const [dataForm, setDataForm] = useState({
    descripcionClienteSector: descripcionCuentaSectorFormCajas,
    id: id_caja_edit || "",
    id_tipo_cajas: id_tipo_caja || "",
    id_cuentas_localizaciones: id_cuentas_localizaciones || "",
    fecha_creacion: `${dateConvertido}`,
    detalle: detalle && typeof (detalle) !== "object" ? detalle : "",
    referencia: referencia || "",
    ubicacion_temporal: ubicacion_temporal || "",
  });


  const { cantidad, id } = dataForm;

  const mensajeErrorResponse = (responseStatus) => {
    let mensaje = "";
    if (responseStatus.message.error) {
      mensaje = responseStatus.message.error;
    } else {
      if (responseStatus.message.error_fields) {
        if (responseStatus.message.error_fields.ubicacion_temporal) {
          mensaje = responseStatus.message.error_fields.ubicacion_temporal;
        } else if (responseStatus.message.error_fields.referencia) {
          mensaje = responseStatus.message.error_fields.referencia;
        } else if (responseStatus.message.error_fields.detalle) {
          mensaje = responseStatus.message.error_fields.detalle;
        }
      }
    }
    return mensaje;
  };

  const copiarRango = (idMasBajo, idMasAlto) => {
    let rango = idMasBajo;
    if (idMasBajo !== idMasAlto) {
      rango = `${idMasBajo}-${idMasAlto}`;
    }
    copiarAlPortapapeles(rango);
  };

  const copiarTodos = (ids) => {
    const idsSeparadosPorComa = ids.join(',');
    copiarAlPortapapeles(idsSeparadosPorComa);
  };

  const copiarAlPortapapeles = (texto) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(texto).then(
        () => {
          console.log('Texto copiado al portapapeles');
          setSnackbarVisible(false);
        },
        (err) => {
          console.error('Error al copiar al portapapeles: ', err);
        }
      );
    }
  };


  useEffect(() => {
    // Verificar si idsCajasCreadas tiene valores
    if (idsCajasCreadas.length > 0) {
      const idMasBajo = Math.min(...idsCajasCreadas);
      const idMasAlto = Math.max(...idsCajasCreadas);
      setIdMasBajo(idMasBajo);
      setIdMasAlto(idMasAlto);

      setDisabledButtonsCopy(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idsCajasCreadas]);

  useEffect(() => {
    // Verificar si idsCajasCreadas tiene valores
    if (idMasBajos > 0 || idMasAltos > 0) {
      const rangoIds = idMasBajos !== idMasAltos ? (
        <span style={{ fontWeight: 'bold' }}>{idMasBajos} - {idMasAltos}</span>
      ) : (
        <span style={{ fontWeight: 'bold' }}>{idMasBajos}</span>
      );
      setMessage(
        <div style={{ textAlign: 'center' }}>
          <p>
            Se han añadido exitosamente {cantidadIdsCajasCreadas} cajas.
            IDs: {rangoIds}
          </p>
        </div>
      )

      setSeverity("success");
      setSnackbarVisible(true);
    }
  }, [cantidadIdsCajasCreadas]);

  const handleSubmit = async (e) => {
    let responseServicio = "";
    if (cantidad) {
      if (cantidad > 0 && cantidad < 1001) {
        if (clienteSectorFormCajas) {
          if (!sectorId || Array.isArray(sectorId) || sectorId === "") {
            setMessage(
              "Debe Seleccionar un sector valido"
            );
            setSeverity("error");
            setSnackbarVisible(true);
            return;
          }
        }
        //NOTE SE RECORRE LA CANTIDAD Y SE CREA UN ARRAY, ESE ES EL QUE SE ENVIA AL SERVICIO POST
        let data = [];
        for (var i = 0; i < cantidad; i++) {
          e.preventDefault();
          data.push({ ...dataForm, id_cuentas_localizaciones: clienteSectorFormCajas ? sectorId.id : sectorIdFormCajas });
        }
        responseServicio = await postCajas(data);

        if (responseServicio.status === "false") {
          let mensaje = mensajeErrorResponse(responseServicio);
          setMessage(mensaje);
          setSeverity("error");
          setSnackbarVisible(true);
        } else {

          setMessage(
            <div style={{ textAlign: 'center' }}>
              <p>
                Se han añadido exitosamente.

              </p>
            </div>)
        }

        const idCajas = esArray(responseServicio.wsResponse.cajas);
        setIdsCajasCreadas((prevIds) => [...prevIds, ...idCajas]);

        setCantidadIdsCajasCreadas((prevCantidad) => prevCantidad + parseInt(cantidad, 10));


      } else {
        setMessage(
          "El valor de la cantidad debe ser mayor a 0 y menor o igual a 1000"
        );
        setSeverity("error");
        setSnackbarVisible(true);
      }
    } else {
      if (id) {
        if (!sectorId || Array.isArray(sectorId) || sectorId === "") {
          setMessage(
            "Debe Seleccionar un sector"
          );
          setSeverity("warning");
          setSnackbarVisible(true);
          return;
        }
        e.preventDefault();
        let data = dataForm;
        data.id_cuentas_localizaciones = sectorId.id;
        delete data.fecha_creacion;
        responseServicio = await postCajas(data);
        if (responseServicio.status === "false") {
          let mensaje = mensajeErrorResponse(responseServicio);
          setMessage(mensaje);
          setSeverity("error");
          setSnackbarVisible(true);
        } else {
          setMessage("Se ha modificado exitosamente!!!");
          setSeverity("success");
          setSnackbarVisible(true);
          toggleModal(responseServicio,id);
        }
      } else {
        setMessage("Tiene que ingresar una cantidad");
        setSeverity("error");
        setSnackbarVisible(true);
      }
    }
  };


  const upperCaseTransformer = (value) => (value && typeof (value) !== "object" ? value.toUpperCase() : "");

  const onChange = (errors, data) => {
    localize.es(errors);
    // Convierte a mayúsculas el valor del campo 'detalle' si existe
    data.detalle = upperCaseTransformer(data.detalle);
    setDataForm(data);
    return { errors, data };
  };

  const customTheme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            textTransform: 'uppercase',
          },
        },
      },
    },
  });


  return (
    <div>
      <>
        {clienteSectorFormCajas && (<Grid style={{ marginBottom: 15 }}><ClienteSector dataEdit={dataCajasFormEdit} /></Grid>)}
        <ThemeProvider theme={customTheme}>
          <CssBaseline />
          <JsonForms
            schema={schemaCajasForm}
            uischema={clienteSectorFormCajas && !id ? uiSchemaCajasFormSinClienteSector : id ? uiSchemaCajasFormEdit : uiSchemaCajasForm}
            data={dataForm}
            renderers={[
              ...materialRenderers,
              SelectorTipoCajasComponent,
              DateFormComponent,
              InputNumberComponent,
              InputStringComponent,
            ]}
            cells={materialCells}
            onChange={({ errors, data }) =>
              onChange && onChange(errors, data)
            }
          />
        </ThemeProvider>
        <Stack
          justifyContent="flex-end"
          direction="row"
          spacing={2}
          style={{ marginTop: "5%" }}
        >
          {disabledButtonsCopy && (
            <div>
              <Button
                style={{ width: '150px', margin: '5px' }}
                color="primary"
                variant="contained"
                size="small"
                onClick={() => copiarRango(idMasBajos, idMasAltos)}
                startIcon={<FlipToFrontIcon />}
              >
                Copiar Rango
              </Button>
              <Button
                style={{ width: '150px', margin: '5px' }}
                color="primary"
                variant="contained"
                size="small"
                onClick={() => copiarTodos(idsCajasCreadas)}
                startIcon={<ContentCopyIcon />}  // Icono en el inicio del botón
              >
                Copiar IDs
              </Button>
            </div>
          )}
          <Button
            onClick={() => toggleModal()}
            variant="contained"
            color="error"
            startIcon={<CloseIcon />}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="success"
            disabled={dataForm.id_tipo_cajas === "" ? true : false}
            startIcon={<CheckIcon />}
          >
            Procesar
          </Button>
        </Stack>
      </>
    </div>
  );
};

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import React from "react";
import { TheLayoutContext} from "src/context/TheLayoutContext";
import { useContext } from "react";
import Grid from "@mui/material/Grid";
import { Box } from '@mui/material';

const TheFooter = () => {
  const { childFooter,expand, setExpand,childFooterLeft} = useContext(TheLayoutContext);
  const toggleAcordion = () => {
    setExpand(!expand);
  };
  return (  
    <>
      <div style={{zIndex: "301"}}>
      <Accordion
        expanded={expand}
        sx={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0)",
          pr: "0px", // keep right padding when drawer closed
        }}
      >
        <AccordionSummary
            style={{
              minHeight: "64px",
              marginRight: "0px",
              right: "0px",
              position: "absolute",
              bottom: "0px",
            }} 
            sx={{backgroundColor: "rgba(0, 0, 0, 0)",}} 
            expandIcon={<ExpandCircleDownIcon color="primary" fontSize="large" onClick={() => toggleAcordion()}/> }>
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor: "rgba(255,255,255,0.9)",}}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <div className="mfs-auto">
                {childFooterLeft}
              </div>
            </Grid>
            <Grid item xs={12} justifyContent= 'flex-end'>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {childFooter}         
            </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
    </>
  );
};

export default React.memo(TheFooter);

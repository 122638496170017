import { ListTablePaginated } from "../general_components/ListTablePaginated";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";
import { Grid } from "@mui/material";
import { getInformes, getInformesPaginated,getInformeDetalle } from "src/general_helpers/InformesData";
import Tooltip from "@mui/material/Tooltip";
import { GridActionsCellItem } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { useState } from "react";
import { Chip } from '@mui/material';
import InformeDetalle from "./InformeDetalle";

export const Informes = () => {

    const [modalState, setModalState] = useState(false);
    const [childModal, setChildModal] = useState("");

    const columns = [
        { field: "id", headerName: "ID", flex: 0.3 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
        { field: "numero", headerName: "Numero", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
        { field: "tecnico", headerName: "Tecnico", flex: 1 },
        { field: "fecha", headerName: "Fecha", flex: 1 },
        { field: "maquina", headerName: "Maquina", flex: 1 },
        { field: "cliente", headerName: "Cliente", flex: 1 },
        {
            field: "estado", headerName: "Estado", flex: 1, align: 'center', headerAlign: "center",
            renderCell: (params) => (
                <Chip
                    label={params.value}
                    color="primary"
                    size="small"  // Tamaño pequeño
                    sx={{ fontSize: '0.75rem', height: '24px' }}  // Ajuste de tamaño y altura
                />
            )
        },
        {
            field: "actions",
            headerName: "Acciones",
            type: "actions",
            flex: 0.5,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Detalle">
                            <span>
                                <VisibilityIcon color="info" />
                            </span>
                        </Tooltip>
                    }
                    onClick={async () => {
                        const datos = await obtenerDetalle(params.row.id);
                        console.log("datps",datos)
                        setChildModal(<InformeDetalle data={datos}/>)
                        setModalState(true);
                    }}
                    label="Detalle"
                />,
            ],
        },

    ];

    const toggleModalDetalle = () => {
        setModalState(!modalState);
    };

    const obtenerDetalle = async (id_informe) => {
        const response = await getInformeDetalle(id_informe);
        return response;
    };

    // eslint-disable-next-line no-unused-vars
    const [options, setOptions] = useState({
        cols: columns,
        get: getInformes,
        getPaginated: getInformesPaginated,
        pageSize: [5, 10, 25],
        limit: 50,
        filter: true,
        exportData: true,
        server: true,
    });


    return (
        <>
            <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
                <Grid
                    style={{
                        background: "white",
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        fontFamily: 'Roboto, sans-serif',
                        borderRadius: 10,
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                        marginBottom: 20,
                        height: 50
                    }}
                >
                    <Typography
                        align="left"
                        variant="h2"
                        component="div"
                        sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F" }}
                    >
                        Informes
                    </Typography>
                    <CustomBreadcrumbs />
                </Grid>
                <SimpleDialogComponent
                    open={modalState}
                    modalName={"Detalles del Informe"}
                    child={childModal}
                    handleClose={toggleModalDetalle}
                    buttonEnviar={false}
                    buttonCancelar={true}
                    
                />
                <ListTablePaginated options={options} />
            </Grid>
        </>
    );
};

export const uiSchemaFiltroServiciosForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/selectedCuentaJuridica",
        },
        {
          type: "Control",
          scope: "#/properties/selectedServicio",
        },
      ],
    },
  ],
};
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState, useContext } from "react";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";
import { SnackbarMessenger } from "../../../general_components/SnackbarMessenger";
import { SearchFormGeneral } from "./SearchFormGeneral"
import SearchIcon from '@mui/icons-material/Search';
import { postCajas } from "../helpers/CajasData";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { ClienteSector } from "./ClienteSector";
import { Buttons } from "src/general_components/Buttons";
import Typography from "@mui/material/Typography";
import InfoIcon from '@mui/icons-material/Info';
import { getContenidosSearchPaginated } from "../helpers/ContenidosData";
import { estadoColores, estadoIconos } from "./estadosCajas";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const CajasGenericasAsignacionList = () => {
  const {
    sectorId, snackbarVisible, setSnackbarVisible, message, setMessage, severity, setSeverity,
    setRequiredFormCuentaSector, setShowFormIdCajas, setShowFormMetadatos, setShowFormFechaAltaCajas,
    setRequiredFormEstadosCajas, sectoresListSearch, actualizarListado, setActualizarListado,
    setSoloCajas, clienteId, clientesListSearch, dataSearchFormGeneral, setOmitirQuerySearch,
  } = useContext(CajasContext);
  const [abrirModalAsignarCajas, setAbrirModalAsignarCajas] = useState(false);
  const [abrirAviso, setAbrirAviso] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [pushData, setPushData] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const [mensajeAviso, setMensajeAviso] = useState("");
  let proceso = "CajasGenericasAsignacion";
  const [actualizarSelected, setActualizarSelected] = useState(false);

  const [buttonDisabledCLienteSector, setButtonDisabledCLienteSector] = useState(true);

  const columns = [
    // { field: "id", headerName: "ID", flex: 0.3 }, 
    // { field: "referencia", headerName: "Referencia", flex: 1 },
    // { field: "detalle", headerName: "Detalle", flex: 0.3 }, 
    // { field: "fecha_creacion", headerName: "Fecha Creacion", flex: 0.3 }, 
    // { field: "cliente", headerName: "Cliente", flex: 0.3 }, 
    {
      field: "clienteName",
      headerName: "Cliente",
      headerAlign: "left",
      flex: 100,
      maxWidth: 200,
      minWidth: 50,
    },
    {
      field: "sectorName",
      headerName: "Sector",
      headerAlign: "left",
      flex: 100,
      maxWidth: 200,
      minWidth: 50,
    },
    {
      field: "concatCaja",
      headerName: "Caja",
      headerAlign: "left",
      flex: 1,
    },
    {
      field: "ubicacion",
      headerName: "Ubicacion",
      headerAlign: "left",
      flex: 1,
      maxWidth: 350,
      minWidth: 175,
    },
    {
      field: "tipo_cajas",
      headerName: "Tipo de Caja",
      headerAlign: "left",
      flex: 1,
      maxWidth: 350,
      minWidth: 175,
    },
    {
      field: "estados_cajas",
      headerName: "Estado",
      headerAlign: "left",
      flex: 2,
      maxWidth: 150,
      minWidth: 100,
      renderCell: (params) => {
        if (params.value) {
          const color = estadoColores[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
          const icono = estadoIconos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
          return (
            <Grid>
              <Button
                size="small"  // Tamaño más pequeño
                variant="text"
                style={{

                  color: color,

                }}
                disabled  // Desactiva la interacción del botón
                startIcon={icono}  // Icono en el inicio del botón
              >
                {params.value}
              </Button>
            </Grid>
          );
        } else {
          return null;
        }
      },
    },

  ];


  const obtenerValuesSelected = (newValueSelected) => {
    setSelectionModel(newValueSelected);

  };

  const buttonBuscar = () => (
    <Button
      endIcon={<SearchIcon />}
      onClick={() => {
        setOmitirQuerySearch(true);
        setRequiredFormCuentaSector(true);
        setAbrirBusqueda(!abrirBusqueda);
        setShowFormFechaAltaCajas(false);
        setShowFormIdCajas(false);
        setShowFormMetadatos(false);
        setSoloCajas(true);
        setRequiredFormEstadosCajas(true);
        setActualizarListado(true);
      }}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
      }}
    >
      Buscar
    </Button>
  );

  const [options, setOptions] = useState({
    cols: columns,
    getPaginated: getContenidosSearchPaginated,
    pageSize: [5, 10, 25],
    edit: false,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,
    notas: true,
    sinFilas: true,
    idElementData: dataSearchFormGeneral,
    selectCheckbox: true,
    valuesSelected: obtenerValuesSelected,
    hierarchyOn: true,
    detailsOn: true,
    abmContenidos: "false",
    checkboxContenidos: "false",
    totales: true,
    buttonExtraOn: true,
    ButtonExtra: buttonBuscar
  });

  useEffect(() => {
    console.log("ENTRO")
    if (!Array.isArray(dataSearchFormGeneral)) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      console.log("ENTRO2")
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: false,
      });
      setActualizarListado(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataSearchFormGeneral,
  ]);

  useEffect(() => {

    if (!Array.isArray(sectorId) && sectorId !== "" && sectorId !== null) {
      setButtonDisabledCLienteSector(false);
    } else if (Array.isArray(sectorId) || sectorId === "" || sectorId === null) {
      setButtonDisabledCLienteSector(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sectorId
  ]);

  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);

    //SETEO EL ESTADO PARA QUE SE ACTUALICE EL LISTADO
    setActualizarListado(true);
  };


  /// Despliega el buscador cuando se presiona F2
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setOmitirQuerySearch(true);
        setRequiredFormCuentaSector(true);
        setAbrirBusqueda(!abrirBusqueda);
        setShowFormFechaAltaCajas(false);
        setShowFormIdCajas(false);
        setShowFormMetadatos(false);
        setSoloCajas(true);
        setRequiredFormEstadosCajas(true);
        setActualizarListado(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  const toggleModalAsignarCajas = () => {
    setAbrirModalAsignarCajas(!abrirModalAsignarCajas);
  };

  const toggleModalAviso = () => {
    setAbrirAviso(!abrirAviso);
    if (!abrirAviso) {
      const clienteObjeto = clientesListSearch.find(cliente => cliente.id = clienteId.id_cuentas);
      const sectorObjeto = sectoresListSearch.find(sector => sector.id = sectorId.id);
      let labelCliente = clienteObjeto.nombre_fantasia;
      let labelSector = sectorObjeto.descripcion;
      let cantidadCajas = selectionModel.length;
      let avisoMensaje = "Se van a asignar " + cantidadCajas + " cajas al cliente " + labelCliente + ", Sector " + labelSector;
      setMensajeAviso(avisoMensaje);
    }
  };

  const handleSubmit = async () => {
    let dataCajas = {};
    for (var i = 0; i < selectionModel.length; i++) {
      let propiedad = "caja" + i;
      dataCajas[propiedad] = { id: selectionModel[i], id_cuentas_localizaciones: sectorId.id };
    }

    const items = Object.values(dataCajas);
    let response = await postCajas(items);
    // En base al status que devuelve el servicio se muestra un mensaje
    // Si es true se refresca la grilla y se cierra el modal
    // De lo contrario se muestra el mensaje de validacion del servicio
    if (response.status === "true") {
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage("Se han asignado correctamente todas las cajas al cliente seleccionado!!!");
      toggleModalAviso();
      toggleModalAsignarCajas();
      setSelectionModel([]);
      setActualizarSelected(true)
      setActualizarListado(true);
    } else {
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage(response.message);
    }
  };

  // Se utiliza para actualizar el array de id seleccionados en el listtable ya que el selectionModel 
  // al limpiar sigue trayendo los elementos seleccionados anteriores
  useEffect(() => {
    if (actualizarSelected) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: true,
        actualizarSelected: actualizarSelected,
        setActualizarSelected: setActualizarSelected
      });
    } else {
      setOptions({
        ...options,
        sinFilas: true,
        actualizarSelected: actualizarSelected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualizarSelected
  ]);
  console.log("actualizarListado", actualizarListado)

  return (
    <Grid style={{ background: "white", paddingBottom: "2%" }}>
      {/* NOTE BOTON BUSCAR CONTENIDO QUE ABRE EL FORMULARIO */}
      {abrirBusqueda && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirBusqueda}
          modalName={"Busqueda de contenidos"}
          child={
            <SearchFormGeneral toggleModal={toggleModal} proceso={proceso} procesoGenerica={true} />
          }
          setPushData={setPushData}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        ></SimpleDialogComponent>
      )}
      {abrirModalAsignarCajas && (
        //Aca va el componente de asignacion de cajas
        <SimpleDialogComponent
          open={abrirModalAsignarCajas}
          modalName={"Asignar Cajas"}
          child={
            <Grid sx={{ mt: 1, minWidth: "100%" }}>
              <ClienteSector />
              <Grid sx={{ mt: 1 }}>
                <Buttons
                  disableButton={buttonDisabledCLienteSector}
                  onClickSubmit={
                    toggleModalAviso
                  }
                  labelButtonSave={"Asignar"}
                  buttonCerrar={toggleModalAsignarCajas}
                />
              </Grid>
            </Grid>
          }

          setPushData={setPushData}
          handleClose={toggleModalAsignarCajas}
          buttonEnviar={false}
          buttonCancelar={true}
        ></SimpleDialogComponent>
      )}
      {abrirAviso && (
        //Aca va el componente de mensaje de aviso
        <SimpleDialogComponent
          open={abrirAviso}
          modalName={"Aviso"}
          child={
            <Grid sx={{ mt: 1, minWidth: "100%", textAlign: "center" }}>
              <InfoIcon sx={{ fontSize: "400%", color: "#2669F0" }} />
              <Typography variant="h6" gutterBottom component="div" sx={{ mt: 1, textAlign: "center" }}>
                {mensajeAviso}
              </Typography>
              <Grid sx={{ paddingTop: "30px" }}>
                <Buttons
                  onClickSubmit={
                    handleSubmit
                  }
                  labelButtonSave={"Procesar"}
                  buttonCerrar={toggleModalAviso}
                />
              </Grid>
            </Grid>
          }

          setPushData={setPushData}
          handleClose={toggleModalAviso}
          buttonEnviar={false}
          buttonCancelar={true}
        ></SimpleDialogComponent>
      )}

      {/* NOTE LIST TABLE DE CAJAS */}
      <Grid >
        <ListTablePaginated options={options} />
      </Grid>
      <Grid container marginTop={'10px'} marginBottom={'5px'} justifyContent="flex-end">
        <Button
          disabled={selectionModel.length > 0 ? false : true}
          endIcon={<AddToPhotosIcon />}
          onClick={() => {
            setAbrirModalAsignarCajas(!abrirModalAsignarCajas);
          }}
          variant="contained"

        >
          Asignar Cajas
        </Button>
      </Grid>

      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </Grid>
  );
};

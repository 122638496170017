import datos from "src/services/datos";
import "src/services/styles/sweetAlert.css";


const notas_internas = new datos("notas_internas");

const imagenes = new datos("imagenes");

export const getImages = async (data) => {
  
  const response = await notas_internas.getImages(data);
  return response;
};

export const deleteImages = async (data)=>{
  const response = await imagenes.delete(data);
  return response;
}
import {
    and,
    rankWith,
    schemaMatches,
    schemaTypeIs,
    uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { esArray } from "src/general_components/functionEsArray";
import { Autocomplete, TextField } from "@mui/material";
import { getFromLS } from "src/general_helpers/getFromLS";
import { getComprobantesEstados } from "src/projects/gestion_documental/helpers/ComprobantesData";

const SelectorComprobanteEditEstado = (props) => {

    //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
    const jsonForm = useJsonForms();
    const [dataDefaults, setDataDefaults] = useState([]);
    const [disabledEstado, setDisabledEstado] = useState(false);
    const disabled = jsonForm.core.data.disabled;
    const valueComprobanteEstado = jsonForm.core.data.comprobantes_estados;
    const editar = jsonForm.core.data.editar;

    const comprobantes_estados = getFromLS("siam-comprobantes_estados").comprobantes_estados;

    const comprobante_codigo = jsonForm.core.data.comprobante_codigo;
    const comprobante_id_estado = jsonForm.core.data.comprobante_id_estado;
    const [selectedComprobanteEstado, setSelectedComprobanteEstado] = useState("");

    useEffect(() => {

        const existeEstado = dataDefaults.find((item) => item.id === comprobante_id_estado);
        setSelectedComprobanteEstado(dataDefaults.find((item) => item.id === comprobante_id_estado));
        //Se limpia el valor id_estado proveniente del data
        if (existeEstado === undefined && dataDefaults.length > 0) {
            props.handleChange(props.path, "");
        }

        const gridManipulabre = comprobantes_estados?.filter(comprobante => comprobante.id_comprobantes_codigos === comprobante_codigo);

        const opcionesFiltradas = gridManipulabre.filter((opcion) => !opcion.nombre.includes(jsonForm.core.data.estadoNombre))
        let disabledEst = opcionesFiltradas.length > 0 ? false : true
        setDisabledEstado(disabledEst)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataDefaults, valueComprobanteEstado]);


    useEffect(() => {
        const getComprobanteEstado = async () => {
            if (comprobantes_estados) {

                const datosFiltrados = comprobantes_estados.filter(item => item.id_comprobantes_codigos === comprobante_codigo);

                setDataDefaults(esArray(datosFiltrados));
            } else {
                const response = await getComprobantesEstados()

                const datosFiltrados = response.filter(item => item.id_comprobantes_codigos === comprobante_codigo);

                if (datosFiltrados) {
                    setDataDefaults(datosFiltrados);
                }
            }
        }

        getComprobanteEstado()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comprobante_codigo]);

    const handleChange = (event, newValue) => {
        if (newValue && newValue !== "") {
            setSelectedComprobanteEstado(newValue);
            props.handleChange(props.path, newValue.id); // Obtén el id del objeto seleccionado
        } else {
            props.handleChange(props.path, "");
        }
    };

    return (
        <div>
            <FormControl
                variant="standard"
                sx={{ minWidth: "100%", width: "100%", paddingBottom: "10px" }}
            >
                <Autocomplete
                    value={selectedComprobanteEstado ? selectedComprobanteEstado : null}
                    onChange={handleChange}
                    options={dataDefaults || []}
                    disabled={disabledEstado || editar? true : false}
                    getOptionLabel={(option) => option.id + ' - ' + option.nombre || ""}
                    renderInput={(params) => (
                        <TextField {...params}
                            label="Estado"
                            disabled={disabled|| editar? true : false}
                        />
                    )}
                />
            </FormControl>
        </div>
    );
};

/*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
const SelectorComprobanteEstadoTester = rankWith(
    100,
    and(
        uiTypeIs("Control"),
        schemaTypeIs("number"),
        schemaMatches((schema) => {
            if (schema.hasOwnProperty("customRender")) {
                let cellschema = schema;
                return cellschema["customRender"] === "SelectorComprobanteEditEstado";
            }
            return false;
        })
    )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorComprobanteEstadoComponent = {
    renderer: withJsonFormsControlProps(SelectorComprobanteEditEstado),
    tester: SelectorComprobanteEstadoTester,
};

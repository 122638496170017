import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { AdministracionContenidosList } from "../components/AdministracionContenidosList";



export function AdministracionContenidos() {
  
  let url = window.location.href;
    const arrUrl = url.split('/')
    const proceso = arrUrl[arrUrl.length-1] // retorna el ultimo valor de la url
    var titulo = "";
    if(proceso === "impresion"){
        titulo = "Impresion de etiquetas"
    }else if(proceso === "edit"){
        titulo = "Edicion de Contenidos"
    }else if(proceso === "list"){
      titulo = "Consulta de Contenidos"
    }

  return (
    <Grid style={{ background: "white"}}>
        <Typography variant="h5" gutterBottom component="div">
        {titulo}
        </Typography>
        <CajasProvider>
            
            <Grid>
              <AdministracionContenidosList />
            </Grid>
        </CajasProvider>
     
    </Grid>
  );
}

import datos from "src/services/datos";
import { postCuentasCategoriaCuentas } from "./CuentasCategoriaCuentas";
import { postCuentas } from "./CuentasData";
import esArray, {   } from "src/general_components/functionEsArray"

export const getCuentasJuridicas = async () => {
    const objectCuentasJuridicas = new datos("cuentas_personas_juridicas")

    objectCuentasJuridicas.addCondition("cuentas_personas_juridicas", "id", 0, ">")
    //objectCuentasJuridicas.addOrderCondition("cuentas_personas_juridicas", "id","DESC")

    const response = await objectCuentasJuridicas.get()

    return esArray(response.wsResponse.cuentas_personas_juridicas)
}

export const getCuentasJuridicasPaginated = async (limit, offset,criterios) => {

    let id_cuentas=criterios.id;
    let razon_social=criterios?.razon_social;
    let localidad=criterios?.localidad;
    let cuit=criterios?.cuit;

    const objectCuentasJuridicas = new datos("cuentas_personas_juridicas")

    objectCuentasJuridicas.addCondition("cuentas_personas_juridicas", "id", 0, ">")
    //Cuando se ingresan criterios de Bsqueda
    Object.keys(criterios).length?  objectCuentasJuridicas.innerJoinTables('cuentas_documentos_inscripciones','cuentas_personas_juridicas',"id_cuentas","id_cuentas", "="):criterios={};
    Object.keys(criterios).length?  objectCuentasJuridicas.innerJoinTables('cuentas_localizaciones','cuentas_personas_juridicas',"id_cuentas","id_cuentas", "="):criterios={};

    id_cuentas? objectCuentasJuridicas.addCondition("cuentas_personas_juridicas", "id_cuentas",  id_cuentas, "="):id_cuentas='';
    razon_social? objectCuentasJuridicas.addLikeCondition("cuentas_personas_juridicas", "razon_social",razon_social):razon_social='';
    localidad?  objectCuentasJuridicas.addCondition("cuentas_localizaciones", "id_localidad", localidad, "="):localidad='';
    cuit? objectCuentasJuridicas.addCondition("cuentas_documentos_inscripciones", "numero", cuit, "="):cuit='';
    
    objectCuentasJuridicas.addOrderCondition("cuentas_personas_juridicas", "id","DESC")
    objectCuentasJuridicas.addLimitCondition(limit, offset)
    
    const response = await objectCuentasJuridicas.get()
    
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/
    
    let result = {}
    if (!response.wsResponse.empty) {
     const cuentas_personas_juridicas = esArray(response.wsResponse.cuentas_personas_juridicas)
        const respuesta =cuentas_personas_juridicas.map(cuentasPersonasJuridicas => {
            let id_cuentas = ''
            let nombre_fantasia = ''
            let razon_social = ''
            if(!cuentasPersonasJuridicas.id_cuentas){
                id_cuentas = cuentasPersonasJuridicas.relations.id_cuentas
                nombre_fantasia =cuentasPersonasJuridicas.relations.nombre_fantasia
                razon_social =cuentasPersonasJuridicas.relations.razon_social
            }
    
    
            return {
                ...cuentasPersonasJuridicas, 
                ...(id_cuentas !== '' && { id_cuentas: id_cuentas }),
                ...(nombre_fantasia !== '' && { nombre_fantasia: nombre_fantasia }),
                ...(razon_social !== '' && { razon_social: razon_social }),
            };
        });

        result = {
            data: [...esArray(respuesta)],
            total_records: response.wsResponse.total_records
        }
        return result

    }
};

export const getCuentasJuridicasByIdCuenta = async (id) => {
    const objectCuentasJuridicas = new datos("cuentas_personas_juridicas")

    objectCuentasJuridicas.addCondition("cuentas_personas_juridicas", "id_cuentas", id, "=")

    const response = await objectCuentasJuridicas.get()

    if (response) {
        return [...response.wsResponse.cuentas_personas_juridicas]
    }
    return response
}


export const postCuentasJuridicas = async (CuentaJuridica, CategoriaCuenta) => {
    const {
        id,
        razon_social,
        nombre_fantasia,
    } = CuentaJuridica;


    if (/*!juridica_idcuenta ||*/ !razon_social || !nombre_fantasia) {
        return
    }

    const objectCuentasJuridicas = new datos("cuentas_personas_juridicas")


    if (!id) {
        //const Cuentas = new datos("cuentas")
        const cuenta = {
            "es_owner": "0"
        }
        const response = await postCuentas(cuenta)
        if (response.wsResponse.cuentas > 0) {
            const cuenta_aux = { ...CuentaJuridica }
            cuenta_aux.id_cuentas = response.wsResponse.cuentas
            const categoriaCuenta_aux = { ...CategoriaCuenta }
            categoriaCuenta_aux.id_cuentas = response.wsResponse.cuentas
            const response2 = await postCuentasCategoriaCuentas(categoriaCuenta_aux)
            if (response2.wsResponse.cuentas_categoria_cuentas > 0) {
                const response3 = await objectCuentasJuridicas.insert(cuenta_aux)
                return response3
            }
            else return response2
        }
        else return response
    }
    await postCuentasCategoriaCuentas(CategoriaCuenta)
    return await objectCuentasJuridicas.insert(CuentaJuridica)
};

export const deleteCuentasJuridicas = async (id) => {
    console.log("Las cuentas no se borran!")
};

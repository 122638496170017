
export const schemaCuentasJuridicasForm = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "id",
      description: "Id",
      hidden: true,
    },
    razon_social: {
      type: "string",
      title: "Razon Social",
      description: "Razon Social",
      minLength: 3
    },
    nombre_fantasia: {
      type: "string",
      title: "Nombre Fantasia",
      description: "Nombre Fantasia",
      minLength: 3
    },
    selectedTipoDocumento: {
      type: "string",
      customRender: "SelectorTipoDocumentos",
    },
    selectedCategoriaCuentas: {
      type: "string",
      customRender: "SelectorCategoriaCuentas",
    },
    numero: {
      type: "integer",
      title: "Numero",
      description: "Numero",
      "minimum": 0,
    },
    selectedPerfilFiscal: {
      type: "string",
      customRender: "SelectorPerfilFiscal",
    },
    empty: {
      type: "boolean",
      hidden: true
    }

  },
  required: ["razon_social", "nombre_fantasia", "selectedTipoDocumento", "selectedCategoriaCuentas", "numero"],

}


export const schemaCuentasFisicasForm = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "id",
      description: "Id",
      hidden: true,
    },
    apellido: {
      type: "string",
      title: "Apellido",
      description: "Apellido",
      minLength: 3
    },
    nombre: {
      type: "string",
      title: "Nombre",
      description: "Nombre",
      minLength: 3
    },
    fecha_nacimiento: {
      type: "string",
      title: "Fecha de Nacimiento",
      customRender: "DateForm",
      description: "Seleccione Fecha de Nacimiento",
    },
    id_genero: {
      type: "string",
      customRender: "SelectorGenero",
    },
    id_estado_civil: {
      type: "string",
      customRender: "SelectorEstadoCivil",
    },
    selectedTipoDocumento: {
      type: "string",
      customRender: "SelectorTipoDocumentos",
    },
    selectedCategoriaCuentas: {
      type: "string",
      customRender: "SelectorCategoriaCuentas",
    },
    numero: {
      type: "integer",
      title: "Numero",
      description: "Numero",
      "minimum": 0,
    },
    selectedPerfilFiscal: {
      type: "string",
      customRender: "SelectorPerfilFiscal",
    },
    empty: {
      type: "boolean",
      hidden: true
    }

  },
  required: ["apellido", "nombre","id_genero","fecha_nacimiento", "id_estado_civil", "selectedTipoDocumento", "selectedCategoriaCuentas", "numero"],

}





import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { Grid } from "@mui/material";

export const LoadingOverlay = (data) => {
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 500 }}
                open={data.open}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: '100vh', // Ajusta la altura según tus necesidades
                    }}
                >
                    <CircularProgress color="primary" disableShrink />
                    <Typography variant="h6" sx={{ marginTop: 2, color: '#fff' }}>
                        Aguarde un momento...
                    </Typography>
                </Grid>
            </Backdrop>
        </div>
    );
};

export default LoadingOverlay;
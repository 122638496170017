import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { esArray } from "src/general_components/functionEsArray";
import { Autocomplete, TextField } from "@mui/material";
import { getFromLS } from "src/general_helpers/getFromLS";
import { getComprobantesCodigoData } from "src/projects/gestion_documental/helpers/ComprobantesCogidoData";

const SelectorPuntoVenta = (props) => {

  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const valuePuntoVenta = jsonForm.core.data.punto_venta;

  const puntos_venta = getFromLS("siam-puntos_venta").puntos_venta;
  const [selectedPuntoVenta, setSelectedPuntoVenta] = useState("");

  useState(() => {
    const getPuntoVenta = async () => {
      if (puntos_venta) {
        setDataDefaults(esArray(puntos_venta));
      }else {
        const response = await getComprobantesCodigoData()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    getPuntoVenta()
  
  }, []);

  useEffect(() => {
    setSelectedPuntoVenta(dataDefaults.find((item) => item.id === valuePuntoVenta));
  }, [dataDefaults, valuePuntoVenta]);

  const handleChange = (event, newValue) => {
    setSelectedPuntoVenta(newValue);
    props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
  };
  
  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
      
        <Autocomplete
        value={selectedPuntoVenta || null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.punto_venta_fiscal+"-"+ option.modalidad_emision || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Punto de venta" 
          disabled={disabled}
          />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
const SelectorPuntoVentaTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorPuntoVenta";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorPuntoVentaComponent = {
  renderer: withJsonFormsControlProps(SelectorPuntoVenta),
  tester: SelectorPuntoVentaTester,
};

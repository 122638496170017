import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";


export const getContactos = async (idCuenta) => {
  const contactos = new datos("cuentas_contactos");
  contactos.addCondition("cuentas_contactos", "id", 0, ">");
  contactos.addCondition(
    "cuentas_contactos",
    "id_cuentas",
    idCuenta,
    "="
  );
  const response = await contactos.get();
  return esArray(response.wsResponse.cuentas_contactos);
};

export const getCuentasLocalizacion = async (idCuenta) => {
  const cuentas_localizaciones = new datos("cuentas_localizaciones");
  cuentas_localizaciones.join("localidad", "id", "id_localidad", "=");
  cuentas_localizaciones.addCondition("cuentas_localizaciones", "id", 0, ">");
  cuentas_localizaciones.addCondition(
    "cuentas_localizaciones",
    "id_cuentas",
    idCuenta,
    "="
  );
  const response = await cuentas_localizaciones.get();
  return esArray(response.wsResponse.cuentas_localizaciones);
};

export const postCuentasContactos = async (descripcion,idCuenta) => {
  const contactos = new datos("cuentas_contactos");

  const data = {
    descripcion: descripcion,
    id_cuentas: idCuenta
  };
  const response = await contactos.insert(data);

  return response;


};
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { SnackbarMessenger } from 'src/general_components/SnackbarMessenger';
import {
  deleteUser,
  getUsers2,
  getUsersPaginated,
} from '../general_helpers/UsersData';
import { ListTablePaginated } from './ListTablePaginated';
import { SimpleDialogComponent } from './SimpleDialogComponent';
import { UserForm } from './UsersForm';
import { HeaderTitleBreadcrumbs } from './HeaderTitle&Breadcrumbs';
import { obtenerProcesosPorPerfilYMenu } from './functionEsArray';


export const UsersList = () => {

  // eslint-disable-next-line no-unused-vars
  const [buttonClicked, setButtonClicked] = useState(false);
  const [userSelect, setUserSelect] = useState({});
  const [createButton, setCreateButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [savedData, setSavedData] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const restricciones = obtenerProcesosPorPerfilYMenu();
  let alta = true;
  let baja = true;
  let modificacion = true;
  if (restricciones) {
    alta = restricciones.alta;
    baja = restricciones.baja;
    modificacion = restricciones.modificacion;
  }


  const listName = 'Usuarios';

  const columns = [
    { field: 'nombre', headerName: 'Nombre', flex: 1 },
    { field: 'apellido', headerName: 'Apellido', flex: 1 },
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
  ];

  const options = {
    cols: columns,
    get: getUsers2,
    getPaginated: getUsersPaginated,
    pageSize: [5, 10, 25],
    edit: modificacion,
    delete: baja ? deleteUser : false,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setUserSelect,
    hiddenButtonAgregar: !alta ? "true" : false
  };

  const toggleModal = () => {
    setCreateButton(!createButton);
    setUserSelect({});
  };

  const dialogChild = (
    <UserForm
      data={userSelect}
      setButtonClicked={setButtonClicked}
      setCreateButton={toggleModal}
      setUserSelect={setUserSelect}
      setSnackbarVisible={setSnackbarVisible}
      setMessage={setMessage}
      setSeverity={setSeverity}
    />
  );

  useEffect(() => {
    if (!createButton) {
      setUserSelect({});
    }
  }, [createButton]);

  return (
    <>
      <Grid style={{ marginBottom: '50px', paddingTop: 10 }}>
        <SimpleDialogComponent
          open={createButton}
          modalName={'Usuarios'}
          child={dialogChild}
          setPushData={savedData}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
        <HeaderTitleBreadcrumbs listName={listName} />

        <ListTablePaginated options={options} />
        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          close={() => {
            setSnackbarVisible(false);
          }}
        />
      </Grid>
    </>
  );
};


import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
// const comprobantes_codigos = new datos("comprobantes_codigos");

export const getModel = async (modelo) => {
  const model = new datos(modelo);
  model.addCondition(modelo, "id", 0, ">");
  const response = await model.get();
  return esArray(response.wsResponse[modelo]);
};

export const getModelPaginated = async (limit, offset,modelo) => {
  const model = new datos(modelo);
  model.addCondition(modelo, "id", 0, ">");
  model.addLimitCondition(limit, offset);
  const response = await model.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: esArray(response.wsResponse[modelo]),
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const deleteModel = async (id,params,modelo) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este Registro?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const model = new datos(modelo);
      let data = {
        id: id,
      };
      const response = await model.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El registro ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};

export const actualizarLocalStorageModel = async (model) => {
  const localStorageKey = `siam-${model}`;
  const storedData = localStorage.getItem(localStorageKey);

  if (storedData) {
      // Realiza la consulta y obtén los nuevos datos
      const arrayModelObjetos = await getModel(model);

      // Actualiza el localStorage con los nuevos datos
      localStorage.setItem(
          localStorageKey,
          JSON.stringify({ 'total_records': arrayModelObjetos.length.toString(), [model]: arrayModelObjetos })
      );
  } else {
      console.log(`No data found for key ${localStorageKey} in localStorage.`);
  }
};
export const schemaComprobantesEdit = {
    type: "object",
    properties: {

        cliente: {
            type: "number",
            customRender: "SelectorClientes",
            description: "Ingrese cliente",
        },
        comprobante_codigo: {
            type: "number",
            customRender: "SelectorComprobantesCodigo",
            description: "Seleccione una caja",
        },
        comprobante_id_estado: {
            type: "number",
            customRender: "SelectorComprobanteEditEstado",
            description: "Seleccione un Estadoss",
        },
        fecha_creacion: {
            type: "string",
            customRender: "DateForm",
            description: "Seleccione una fecha",
        },
        fecha_trabajo: {
            type: "string",
            customRender: "DateForm",
            description: "Seleccione una fecha",
        },
    },
};


// Metodo creada unicamente para el login que es un caso especial.
// Recibe dos parametros username y password
// encoda el password
// retorna el json parametros con params armado. 

export const paramsLogin = (params) => {

  const parametros = {
    'params': params
  }

  return parametros;
}

export default paramsLogin


export const uiSchemaMetadatos= {
  type: "VerticalLayout",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/id_tipo_contenidos",
          options: {
            readonly: true,
          },
        },
        {
          type: "Control",
          scope: "#/properties/detalleContenido",
          options: {
            readonly: true,
          },
        },
        {
          type: "Control",
          scope: "#/properties/id",
          options: {
            readonly: true,
          },
        },
        {
          type: "Control",
          scope: "#/properties/label_form",
        },
        {
          type: "Control",
          scope: "#/properties/label_grilla",
        },
        {
          type: "Control",
          scope: "#/properties/tipo_dato",
        },
        
      ],
    },
  ],
};

/* eslint-disable react-hooks/exhaustive-deps */
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { useEffect, useState, useContext } from "react";
import {
  postDigitalizacion,
  deleteImagesDigitalizaciones,
  actualizarDigitalizaciones,
} from "../../helpers/DigitalizacionData";
import { getTiposContenidos } from "../../helpers/TipoContenidosData";
import { DigitalizacionesCaratula } from "./DigitalizacionesCaratula";
import AlertDialog from "./AlertDialogDelete";
import "../styles/Digitalizaciones.css";
import "react-medium-image-zoom/dist/styles.css";
import { SnackbarMessenger } from "../../../../general_components/SnackbarMessenger";
import { Buttons } from "../../../../general_components/Buttons";
import { FullFeaturedCrudGrid } from "./ListTableDigitalizaciones";
import { SimpleBackdrop } from "../../../../general_components/Backdrop";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { v4 as uuidv4 } from 'uuid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import WarningIcon from '@mui/icons-material/Warning';
import { SimpleDialogComponent } from "../../../../general_components/SimpleDialogComponent";
import esArray from "src/general_components/functionEsArray";
import CloseIcon from '@mui/icons-material/Close';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const DigitalizacionesContenido = (props) => {
  const {
    openDialogImage,
    contenidoSeleccionado,
    setOpenDialogImage,
    setAlertImagesSave,
    setActualizarListado,
    setEliminar,
    tipoContenidoList1,
    metadatosContenidoSeleccionado
  } = props;
  const {
    rows, setRows, checkedTotal, setCheckedTotal, digitalizacionesSeleccionadas, setClickCheckedTotal,
    digitalizacionesSeleccionadasCompleto, setDigitalizacionesSeleccionadasCompleto, setDigitalizacionesAfacturar, checkedCaratula
  } = useContext(CajasContext);
  const [file, setFile] = useState([]);
  const [fileListPrimeraCarga, setFileListPrimeraCarga] = useState(true);
  const [tipoContenidoList, setTipoContenidoList] = useState(JSON.parse(localStorage.getItem("siam-tipo_contenidos"))?.tipo_contenidos || []);
  const [base64String, setBase64String] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [buttonSaveCaratula, setButtonSaveCaratula] = useState(true);
  const [nroFolio, setNroFolio] = useState(0);
  const [saveImagesCaratula, setSaveImagesCaratula] = useState(false);
  const [saveImagesDigitalizaciones, setSaveImagesDigitalizaciones] = useState(false);
  const [getDataSave, setGetDataSave] = useState(true);
  const [updateImageSave, setUpdateImageSave] = useState({});
  const [primerRender, setPrimerRender] = useState(true);
  const [abrirPreguntaConsumoImagenes, setAbrirPreguntaConsumoImagenes] = useState(false);
  //NOTE ESTADO PARA SABER SI HAY IMAGENES NUEVAS PARA ALMACENAR
  const [newImage, setNewImage] = useState(false);
  //NOTE STATE PARA DELETE
  const [alertDelete, setAlertDelete] = useState(false);
  const [index, setIndex] = useState(false);
  const [deleteTrue, setDeleteTrue] = useState(false);
  const [deleteStoredImage, setDeleteStoredImage] = useState(false);
  const [guardado, setGuardado] = useState(false);
  const [eliminando, setEliminando] = useState(false);
  //NOTE STATE PARA LAS ALERTAS
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");



  useEffect(() => {
    const fetchData = async () => {
      let tipoContenidos = tipoContenidoList;

      if (tipoContenidoList.length === 0) {
        tipoContenidos = await getTiposContenidos(true);
        setTipoContenidoList(tipoContenidos);
      }

      if (base64String !== "") {
        const newImageRow = {
          model: "digitalizaciones",
          model_data: {
            id_tipo_contenidos: "",
            id_contenidos: contenidoSeleccionado.id,
            folio: nroFolio,
            valor: "",
          },
          save: false,
          image: base64String,
          folio: nroFolio,
          id: uuidv4(),
          descripcion: "",
          id_tipo_contenidos: "",
          checked: false
        };

        setRows([newImageRow, ...rows]);
        setNewImage(true);
        setNroFolio(nroFolio + 1);
        setBase64String("");
        setLoading(false);
      }

      if (contenidoSeleccionado.digitalizaciones && file.length === 0 && getDataSave) {
        const result = await getImagesSave(contenidoSeleccionado.id, contenidoSeleccionado.digitalizaciones, tipoContenidos);
        setNroFolio(result.cantFolio);
        setFile(result.saveImages);

        if (fileListPrimeraCarga) {
          let arrayImagenesGuardadas = result.saveImages.sort((x, y) => x.model_data.folio - y.model_data.folio);

          if (arrayImagenesGuardadas) {
            let dataRows = arrayImagenesGuardadas.map((image, index) => {
              let idDigitalizacionEncontrado = digitalizacionesSeleccionadasCompleto.digitalizaciones_contenidos.find(element => element === image.id_digitalizaciones);
              let checked = idDigitalizacionEncontrado ? true : false;

              return {
                ...image,
                folio: image.model_data.folio,
                id_tipo_contenidos: image.model_data.id_tipo_contenidos.id ? image.model_data.id_tipo_contenidos.id : image.model_data.id_tipo_contenidos,
                descripcion: image.model_data.valor,
                id: image.model_data.folio ? uuidv4() : "A" + index,
                checked: checked
              };
            });

            dataRows = dataRows.filter(item => item.image !== "");

            setNroFolio(dataRows.length);
            console.log("datarows",dataRows);
            setRows(dataRows);
          }
         
          setFileListPrimeraCarga(false);
        }
      }

      setGetDataSave(false);

      let tipoContenido = 0;
      let descripcion = 0;

      rows.forEach(row => {
        if (row.id_tipo_contenidos !== "" && newImage) {
          tipoContenido++;
        }
        if (row.descripcion !== "" && newImage) {
          descripcion++;
        }
      });

      if (buttonSaveCaratula === false && newImage === false) {
        setDisableButton(false);
      } else if (rows.length !== 0 && rows.length === tipoContenido && rows.length === descripcion) {
        setDisableButton(false);
      } else if (Object.entries(updateImageSave).length > 0 && !eliminando) {
        rows.forEach(row => {
          if (row.id_tipo_contenidos !== "") {
            tipoContenido++;
          }
          if (row.descripcion !== "") {
            descripcion++;
          }
        });

        if (rows.length !== 0 && rows.length === tipoContenido && rows.length === descripcion) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
      } else {
        setDisableButton(true);
      }

      if (saveImagesDigitalizaciones && newImage && !guardado) {
        setGuardado(true);
        await postDigitalizacionContenido();
      } else if (saveImagesDigitalizaciones && !newImage) {
        setOpenDialogImage(!openDialogImage);
        setSaveImagesDigitalizaciones(!saveImagesDigitalizaciones);
      }
    };

    fetchData();
  }, [
    base64String,
    file,
    rows,
    saveImagesDigitalizaciones,
    disableButton,
    contenidoSeleccionado,
    buttonSaveCaratula,
    deleteStoredImage,
    getDataSave,
    tipoContenidoList,
    fileListPrimeraCarga,
    digitalizacionesSeleccionadasCompleto,
    guardado,
    newImage,
    nroFolio,
    updateImageSave,
    eliminando,
  ]);


  //NOTE CUANDO SE SELECCIONA UNA IMAGEN SE PASA DIRECTAMENTE A BASE 64
  const postDigitalizacionContenido = async () => {
    const rowsUpdate = rows.map((row) => {
      row.model_data.id_tipo_contenidos = row.id_tipo_contenidos;
      row.model_data.folio = row.folio.toString();
      row.model_data.valor = row.descripcion;
      return row;
    });
    const respuesta = await postDigitalizacion(rowsUpdate);
    setOpenDialogImage(!openDialogImage);
    setNewImage(!newImage);
    setSaveImagesDigitalizaciones(!saveImagesDigitalizaciones);
    if (respuesta.status === "true") {
      setGuardado(false);
      setAlertImagesSave(true);
    } else {
      setAlertImagesSave(false);
      setGuardado(false);
    }
  };


  //NOTE CUANDO SE SELECCIONA UNA IMAGEN SE PASA DIRECTAMENTE A BASE 64
  const deleteDigitalizacionContenido = async (event) => {
    setEliminando(true);
    let newRows = [...rows];
    if (rows[index].save) {
      const respuesta = await deleteImagesDigitalizaciones(
        rows[index]?.id_digitalizaciones
      );
      // Se muestra los mensajes corresponientes al status del servicio
      // Se retorna un mensaje con la cantidad de contenidos encontrados
      // Sino se muestra un mensaje de que no se encontraron contenidos
      if (respuesta === "true") {
        setDeleteStoredImage(false);
        setEliminar(true);
        setActualizarListado(true);
        setSnackbarVisible(true);
        setSeverity("success");
        setMessage("Se elimino la digitalizacion del contenido correctamente");
        newRows.splice(index, 1);
        /// Actualizar orden
        newRows = newRows.sort((x, y) => x.model_data.folio - y.model_data.folio);
        for (var ind = 0; ind < newRows.length; ind++) {
          // eslint-disable-next-line no-loop-func
          let indexRow = newRows.findIndex(function (newRow) {
            // eslint-disable-next-line eqeqeq
            return newRow.id == newRows[ind].id; // or el.nombre=='T NORTE';
          });
          newRows[ind].folio = indexRow;
          newRows[ind].model_data.folio = indexRow;
          newRows[ind].checked = false;
        }
        let rowsOrderEdit = updateImageSave;
        for (var i2 = 0; i2 < newRows.length; i2++) {
          if (newRows[i2].save) {

            rowsOrderEdit = {
              ...rowsOrderEdit,
              [newRows[i2].id_digitalizaciones]: {
                ...rowsOrderEdit[
                newRows[i2].id_digitalizaciones
                ],
                folio: newRows[i2].folio.toString(),
                id: newRows[i2].id_digitalizaciones,
              },
            };
          }
        }
        await saveFolio(rowsOrderEdit);
      } else {
        setDeleteStoredImage(false);
        setSnackbarVisible(true);
        setSeverity("warning");
        setMessage("Hubo un error al eliminar la digitalizacion del contenido");
      }
      setIndex("");
    } else {
      newRows.splice(index, 1);
      newRows = newRows.sort((x, y) => x.model_data.folio - y.model_data.folio);
      for (var i = 0; i < newRows.length; i++) {
        // eslint-disable-next-line no-loop-func
        let indexRow = newRows.findIndex(function (newRow) {
          return newRow.id === newRows[i].id; // or el.nombre=='T NORTE';
        });
        newRows[i].folio = indexRow;
        newRows[i].model_data.folio = indexRow;
        newRows[i].checked = false;
      }
      //NOTE FUNCION PARA SABER SI SE SACAN TODAS LAS IMAGENES PARA ALMACENAR
      const imagesNews = newRows.filter((item) => item.save === false);
      if (imagesNews.length === 0) {
        setNewImage(false);
      }
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage("Se elimino la digitalizacion del contenido correctamente");
      setIndex("");
    }
    let rowsOrdenado = newRows.sort((x, y) => x.model_data.folio - y.model_data.folio);
    setRows(rowsOrdenado);
    setNroFolio(nroFolio - 1);
    setDeleteTrue(false);
    setEliminando(false);
  };


  //NOTE CUANDO SE SELECCIONA UNA IMAGEN SE PASA DIRECTAMENTE A BASE 64
  const uploadImages = async (event) => {
    let fileObj = [];
    fileObj.push(event.target.files);
    // let files =  fileObj.sort((x, y) => x.name - y.z);
    let files = [...event.target.files].sort((a, b) => a.name.localeCompare(b.name));
    // let files = fileObj[0].sort((unArchivo, otroArchivo) => unArchivo.name.localeCompare(otroArchivo.name));
    for (let i = 0; i < files.length; i++) {
      setLoading(true);
      let imageBase64 = await convertImageToBase64(
        URL.createObjectURL(fileObj[0][i]),
        event.target.files[0].name
      );
      setBase64String(imageBase64);
    }
  };


  //NOTE FUNCION PARA PASAR LAS IMAGENES A BASE 64, PARA PODER ALMACENARLAS EN LA BASE DE DATOS
  const convertImageToBase64 = async (url, name) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }


  //NOTE FUNCION PARA GUARDAR LOS FOLIOS AL ELIMINAR REGISTRO GUARDADO
  const saveFolio = async (rowsOrderEdit) => {
    if (Object.entries(rowsOrderEdit).length > 0) {
      // TODO: Analizar respuesta y mostrar mensaje en caso de error
      const respuesta = await actualizarDigitalizaciones(rowsOrderEdit);
      return respuesta;
    }


  };

  //NOTE FUNCION PARA GUARDAR LAS IMAGENES
  const onClickSubmit = async () => {
    setLoading(true);
    if (Object.entries(updateImageSave).length > 0) {
      const respuesta = await actualizarDigitalizaciones(updateImageSave);
      if (respuesta.status === "true") {
        setAlertImagesSave(true);
      } else {
        setAlertImagesSave(false);
      }
    }
    setSaveImagesCaratula(!saveImagesCaratula);
  };


  //NOTE FUNCION PARA FACTURAR
  const onClickFacturar = async () => {
    let filesImages = [];
    rows.forEach((item) => {
      if (item.save === false && item.checked) {
        filesImages.push(item);
      }
    });
    if (filesImages.length > 0) {
      // setDigitaliazacionesNuevosChecked(filesImages)
      setAbrirPreguntaConsumoImagenes(true);
    } else {
      if (digitalizacionesSeleccionadas.digitalizaciones_contenidos) {
        setDigitalizacionesSeleccionadasCompleto(digitalizacionesSeleccionadas);
        setDigitalizacionesAfacturar(digitalizacionesSeleccionadas)
        handleClose(false);
      }
    }
  };


  //NOTE FUNCION PARA FACTURAR sin guardar nuevas digitalizaciones
  const onClickFacturarSinGuardar = async () => {
    if (digitalizacionesSeleccionadas.digitalizaciones_contenidos) {
      setDigitalizacionesSeleccionadasCompleto(digitalizacionesSeleccionadas);
      setDigitalizacionesAfacturar(digitalizacionesSeleccionadas)
      handleClose(false);
    }
  };


  useEffect(() => {
    if (deleteTrue) {
      deleteDigitalizacionContenido();
    }
  }, [deleteTrue]);


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgb(244, 246, 248)",
      color: "#637381",
      fontSize: 14,
      fontWeight: 'bold',
      borderRadius: 0,
      verticalAlign: 'inherit',
      border: '1px dashed rgb(241, 243, 244)',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));


  // Funcion Checkbox
  const handleChange = (event) => {
    setCheckedTotal(event.target.checked);
    setClickCheckedTotal(true);
  };


  // Funcion Que cieera los modales
  const handleClose = (value) => {
    setOpenDialogImage(false)
    setCheckedTotal(false);
    setAbrirPreguntaConsumoImagenes(false);
    setClickCheckedTotal(false);
  };


  // Funcion Que cieera el modal aviso
  const handleCloseAviso = (value) => {
    setAbrirPreguntaConsumoImagenes(false);
    setClickCheckedTotal(false);
  };


  useEffect(() => {
    if (rows.length > 0 && primerRender) {
      let cantRow = rows.length;
      let cantRowChecked = rows.filter((item) => item.id_digitalizaciones && item.checked !== false).length;
      setCheckedTotal(cantRow === cantRowChecked && checkedCaratula ? true : false);
      setPrimerRender(false);
    }
  }, [rows]);


  //NOTE FUNCION PARA consumir servicio de imagenes 
  const onClickImagenesFacturas = async () => {
    const rowsUpdate = rows.map((row) => {
      row.model_data.id_tipo_contenidos = row.id_tipo_contenidos;
      row.model_data.folio = row.folio.toString();
      row.model_data.valor = row.descripcion;
      return row;
    });
    const respuesta = await postDigitalizacion(rowsUpdate);
    if (respuesta.status === "true") {
      if (respuesta.wsResponse.images) {
        let newDigitalizaciones = esArray(respuesta.wsResponse.images.image);
        newDigitalizaciones = newDigitalizaciones.map((row) => {
          return row.id_modelo_padre;
        });
        if (digitalizacionesSeleccionadas.digitalizaciones_contenidos) {
          let digitalizacionContPrevio = [...digitalizacionesSeleccionadas.digitalizaciones_contenidos];
          let digitalizacionContFinal = digitalizacionContPrevio.concat(newDigitalizaciones);
          setDigitalizacionesSeleccionadasCompleto({ ...digitalizacionesSeleccionadasCompleto, digitalizaciones_contenidos: digitalizacionContFinal });
          setDigitalizacionesAfacturar({ ...digitalizacionesSeleccionadasCompleto, digitalizaciones_contenidos: digitalizacionContFinal })
          setActualizarListado(true);
          handleClose(false);
        }
      }
    } else {
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage(respuesta.message);
    }
  };


  const Title = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF', // Color blanco para el título
    fontWeight: 'bold', // Puedes ajustar el peso de la fuente según tus preferencias
    fontSize: '1rem', // Tamaño de la fuente del título
    marginBottom: theme.spacing(0), // Espacio inferior para separar del contenido
  }));


  const CloseButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
  }));


  const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0),
  }));

   // Establece el estado inicial del acordeón como expandido
   const [expanded, setExpanded] = useState(true);

   const handleChangeAccordeon = () => {
     setExpanded((prevExpanded) => !prevExpanded);
   };

  return (
    <>
      {alertDelete && (
        <AlertDialog
          alertDelete={alertDelete}
          setDeleteTrue={setDeleteTrue}
          setAlertDelete={setAlertDelete}
        />
      )}
      <Dialog fullWidth={true} maxWidth={"xl"} open={openDialogImage} fullScreen={true}>
        <DialogTitle>
          <Header>
            <div>
              <Title>Listado Digitalizaciones</Title>
            </div>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
          </Header>
        </DialogTitle>
        {/*NOTE DATOS DEL LEGAJO SELECCIONADO */}
        {guardado ? (<SimpleBackdrop open={true}></SimpleBackdrop>) : (<></>)}
        <DialogContent>

          <Accordion expanded={expanded} onChange={handleChangeAccordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Caratulas
            </AccordionSummary>
            <AccordionDetails>
              {/* Aquí se coloca el contenido del acordeón */}
              <Grid container spacing={2} style={{ paddingTop: "20px" }}>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead >
                        <TableRow>
                          <StyledTableCell align="left">Metadatos</StyledTableCell>
                          <StyledTableCell align="left">Valor</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {metadatosContenidoSeleccionado.length > 0 ? (metadatosContenidoSeleccionado.map((metadato, key) => (
                          metadato.label !== "id_metadatos" && metadato.label !== "id_contenidos" && metadato.label !== "id_caja" ?
                            <TableRow key={metadato.label}  >
                              <TableCell sx={{fontSize: 12 , fontWeight: 'bold'}} variant="head">{metadato.label.replace(/[_]/g, ' ').toUpperCase()}</TableCell>
                              <TableCell sx={{fontSize: 11}}>{metadato.valor}</TableCell>
                            </TableRow> : null)))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {/*NOTE IMAGENES PRINCIPALES O CARATULAS */}
                <Grid item xs={9} sm={9} md={9} lg={9}>
                  <DigitalizacionesCaratula
                    contenidoSeleccionado={contenidoSeleccionado}
                    saveImagesCaratula={saveImagesCaratula}
                    setSaveImagesCaratula={setSaveImagesCaratula}
                    setSaveImagesDigitalizaciones={setSaveImagesDigitalizaciones}
                    setButtonSaveCaratula={setButtonSaveCaratula}
                    setActualizarListado={setActualizarListado}
                    setAlertImagesSave={setAlertImagesSave}
                    setEliminar={setEliminar}
                    guardado={guardado}
                    setLoadingContenido={setLoading}
                    loadingContenido={loading}
                  />
                </Grid>
              </Grid>

            </AccordionDetails>
          </Accordion>

          {/*NOTE BOTTON PARA SELECCIONAR LAS IMAGENES */}
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            style={{
              borderTop: "groove",
              borderTopColor: "black",
              borderTopStyle: "solid",
              paddingTop: "15px",
              paddingBottom: "15px",
              textAlign: "center",
              display: "block",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={checkedTotal} onChange={handleChange} name="gilad" />}
                    label={<Typography sx={{ fontSize: 14 }}>Incluir todas las imagenes a Facturar</Typography>} />
                </FormGroup>
              </Grid>
              <Grid item xs={8} style={{ textAlign: "left" }}>
                <Button variant="contained" component="label" endIcon={<PhotoCamera style={{ color: "#FFFFF" }} />}>
                  Elegir Digitalizaciones
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={uploadImages}
                  />
                </Button>
              </Grid>
            </Grid>
          </Stack>
          {/*NOTE LISTADO DE IMAGENES */}
          {loading || getDataSave || guardado ? (
            <Grid style={{ textAlign: "center" }}>
              <CircularProgress style={{ width: "50px", height: "50px" }} />
            </Grid>
          ) : (
            <Grid >
              <FullFeaturedCrudGrid
                tipoContenidoList={tipoContenidoList1}
                updateImageSave={updateImageSave}
                setUpdateImageSave={setUpdateImageSave}
                setNroFolio={setNroFolio}
                nroFolio={nroFolio}
                setAlertDelete={setAlertDelete}
                setIndex={setIndex}
              >
              </FullFeaturedCrudGrid>
            </Grid>
          )}
        </DialogContent>
        {/*NOTE BUTTONS PARA CERRAR O GUARDAR, EDITADOS COMO SON NECESARIOS */}
        <Grid sx={{ margin: 5 }}>
          <Buttons
            disableButton={disableButton}
            onClickSubmit={onClickSubmit}
            buttonCerrar={handleClose}
            buttonFacturar={true}
            onClickFacturar={onClickFacturar}
            disableButtonFacturar={
              rows.find(element => element.checked === true) ? false : true}
          />
        </Grid>
      </Dialog>
      {abrirPreguntaConsumoImagenes && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirPreguntaConsumoImagenes}
          modalName={"Aviso"}
          child={
            <Grid sx={{
              mt: 1, minWidth: "100%", textAlign: "center",
              wordBreak: "break-all"
            }}>
              <WarningIcon sx={{ fontSize: "400%", color: "yellow" }} />
              <Typography align="center" variant="h4" gutterBottom component="div">
                ATENCION
              </Typography>
              <Grid>
                <Typography align="center" variant="h5">
                  "Se ha seleccionado digitalizaciones que aun no estan almacenadas en la base de datos, Si desea guardarlas haga click en el boton guardar y confirmar , sino en descartar y confirmar"
                </Typography>
              </Grid>
              <Grid sx={{ paddingTop: "30px" }}>
                <Buttons
                  onClickSubmit={
                    onClickImagenesFacturas
                  }
                  disableButton={disableButton}
                  labelButtonSave={"Guardar y Confirmar"}
                  buttonCerrar={handleCloseAviso}
                  buttonFacturar={true}
                  onClickFacturar={onClickFacturarSinGuardar}
                  labelButtonFacturar={"Descartar y Confirmar"}
                  iconFacturarDisabled={true}
                />
              </Grid>
            </Grid>
          }
          setPushData={onClickImagenesFacturas}
          handleClose={handleCloseAviso}
          buttonEnviar={false}
          buttonCancelar={true}
        ></SimpleDialogComponent>
      )}
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </>
  );
};

const getImagesSave = async (
  idContenido,
  imagenesDigitalizaciones,
  tipoContenidoList
) => {
  let saveImages = [];
  let cantFolio = 0;
  /**
   * ORDENO LA DATA PARA PODER DEJARLA EN UN SOLO ARRAY DE OBJETOS, PARA PODER MOSTRARLA MAS FACIL
   *
   *
   * ID
   * IMAGEN
   * ID CONTENIDO
   * TIPO CONTENIDO
   * FOLIO
   * DESCRIPCION = VALOR
   * SAVE = TRUE (SIRVE PARA PODER SABER SI LA IMAGEN QUE MUESTRO ESTA GUARDADA YA O NO)
   *
   */

  if (
    imagenesDigitalizaciones &&
    imagenesDigitalizaciones.length === undefined
  ) {
    if (imagenesDigitalizaciones?.imagenes) {
      //NOTE MANDO EL ID DE TIPO DE CONTENIDO PARA QUE ME RETORNE EL OBJETO COMPLETO PARA PODER MOSTRARLO EN EL SELECT.
      const dataTipoContenido = getDatosTipoContenidos(
        imagenesDigitalizaciones.digitalizaciones_metadatos.id_tipo_contenidos,
        tipoContenidoList
      );
      cantFolio = imagenesDigitalizaciones.digitalizaciones_metadatos.folio;
      saveImages.push({
        id_digitalizaciones:
          imagenesDigitalizaciones.digitalizaciones_metadatos
            .id_digitalizaciones,
        id_digitalizaciones_metadatos:
          imagenesDigitalizaciones.digitalizaciones_metadatos.id,
        image: imagenesDigitalizaciones.imagenes.link || "",
        model: imagenesDigitalizaciones.imagenes.modelo_padre || "",
        model_data: {
          id_tipo_contenidos: dataTipoContenido || "",
          id_contenidos: idContenido || "",
          folio:
            imagenesDigitalizaciones.digitalizaciones_metadatos.folio || "",
          valor:
            imagenesDigitalizaciones.digitalizaciones_metadatos.valor || "",
        },
        save: true,
      });
    }
  } else {
    imagenesDigitalizaciones.forEach(
      (digitalizacion) => {
        if (digitalizacion?.imagenes) {
          const dataTipoContenido = getDatosTipoContenidos(
            digitalizacion.digitalizaciones_metadatos.id_tipo_contenidos,
            tipoContenidoList
          );
          if (cantFolio < digitalizacion.digitalizaciones_metadatos.folio) {
            cantFolio = digitalizacion.digitalizaciones_metadatos.folio;
          }
          saveImages.push({
            id_digitalizaciones:
              digitalizacion.digitalizaciones_metadatos.id_digitalizaciones,
            id_digitalizaciones_metadatos:
              digitalizacion.digitalizaciones_metadatos.id,
            image: digitalizacion.imagenes.link || "",
            model: digitalizacion.imagenes.modelo_padre || "",
            model_data: {
              id_tipo_contenidos: dataTipoContenido || "",
              id_contenidos: idContenido || "",
              folio: digitalizacion.digitalizaciones_metadatos.folio || "",
              valor: digitalizacion.digitalizaciones_metadatos.valor || "",
            },
            save: true,
          });
        }
      }
    );
  }
  return { saveImages, cantFolio };
};

//NOTE FUNCION PARA PODER OBTNER EL OBJETO COMPLETO DE UN TIPO DE CONTENIDO, MEDIANTE UN ID
const getDatosTipoContenidos = (id, tipoContenidoList) => {
  let listTipoContenido = [];
  if (tipoContenidoList.length > 0) {
    listTipoContenido = tipoContenidoList;
  }
  let tipoContenido = listTipoContenido.find((item) => item.id === id);
  return tipoContenido;
};

export const schemaBuscarCajas = {
    type: "object",
    properties: {
      id: {
        type: "string",
        title: "ID",
        description: "Seleccione ID (separados por coma)",
      },
      referencia: {
        type: "string",
        title: "Referencia",
        description: "Ingresar Referencia",
      },
      detalle: {
        type: "string",
        title: "Detalle",
        description: "Ingrese Detalle",
      },
      fecha_creacion: {
        type: "string",
        title: "Fecha de Creacion",
        customRender: "DateForm",
        description: "Seleccione Fecha de Creacion",
      },
      id_tipo_caja : {
        type: "number",
        customRender: "SelectorTipoCajas",
      },
      id_tipo_estado : {
        type: "number",
        customRender: "SelectorEstadoCajas",
      }
    },
    };
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useContext, useState, useEffect } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { getCuentasLocalizaciones } from "src/projects/gestion_comercial/helpers/CuentasLocalizaciones";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { SwitchCajasUbicadas } from "./SwitchCajasUbicadas";
import { SwitchCuentaGenerica } from "../components/SwitchCuentaGenerica";
import { TheLayoutContext } from "src/context/TheLayoutContext";
export const FormCuentaSector = (data = null) => {
  //NOTE VALUE TRAIDO DE SEARCH FORM GENERAL DE CLIENTES SIRVE PARA QUE NO SE VUELVA A EJECUTAR LA QUERY DE SECTORES
  const { valueIdCliente, valorUbicacionTemporal, setCambioPestania, cambioPestania } = data;

  const { setClienteId } = useContext(TheLayoutContext)
  const {
    clientesListSearch,
    sectoresListSearch,
    setSectoresListSearch,
    setClienteSeleccionado,
    setSectorSeleccionado,
    setListDatosFormCuentaSector,
    formCuentaSectorDisabled,
    setFormCuentaSectorDisabled,
    clienteIdSearch,
    setClienteIdSearch,
    sectorIdSearch,
    setSectorIdSearch,
    setClienteName,
    setTipoContenidoListCuentaSector,
    setDisabledVerTodosTContenidos,
    setVerTodosTContenidos,
    setGrupoBusquedaSectorCliente
  } = useContext(CajasContext);

  const [inputValueCliente, setInputValueCliente] = useState("");
  const [inputValueSector, setInputValueSector] = useState("");

  useEffect(() => {
    setCambioPestania(false);
    const getSectores = async () => {
      let Sec = sectoresListSearch;

      if (valueIdCliente !== clienteIdSearch.id_cuentas && !cambioPestania) {
        Sec = await getCuentasLocalizaciones(clienteIdSearch.id_cuentas);

        // let cuentas_localizaciones_tipo_contenidos = JSON.parse(localStorage.getItem("siam-cuentas_localizaciones_tipo_contenidos")).cuentas_localizaciones_tipo_contenidos;
        // let cuenta_localizaciones = JSON.parse(localStorage.getItem("siam-cuentas_localizaciones")).cuentas_localizaciones;

        // const joinedArray = cuenta_localizaciones.map(cuenta_localizacion => {
        //   const relatedItem = cuentas_localizaciones_tipo_contenidos.filter(cuenta_localizacion_tipo_contenido => cuenta_localizacion.id === cuenta_localizacion_tipo_contenido.id_cuentas_localizaciones);
        //   return { ...cuenta_localizacion, relations: { cuentas_localizaciones_tipo_contenidos: relatedItem} };
        // });


        // Sec = joinedArray.filter(array => array.id_cuentas === clienteIdSearch.id_cuentas);
      }

      const cliente = clientesListSearch.find(
        (clienteFind) => clienteFind.id_cuentas === clienteIdSearch.id_cuentas
      );
      let clienteNameObtenido = cliente?.nombre_fantasia
        ? cliente["nombre_fantasia"]
        : "";
      setClienteName(clienteNameObtenido);
      let newSectores = Object.entries(Sec).map(([key, value]) => {
        let objeto = value;
        objeto["label"] = value.descripcion;
        return objeto;
      });
      setSectoresListSearch(newSectores);
    };

    if (
      !Array.isArray(clienteIdSearch) &&
      clienteIdSearch !== "" &&
      clienteIdSearch !== null
    ) {
      getSectores();
    } else {
      setSectoresListSearch([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteIdSearch]);

  useEffect(() => {
    setListDatosFormCuentaSector({});
    const formatSectores = () => {
      return sectorIdSearch.map((sector) => sector.id).join(",");
    };
    if (
      clienteIdSearch &&
      clienteIdSearch !== "" &&
      clienteIdSearch !== null &&
      !Array.isArray(clienteIdSearch) &&
      !formCuentaSectorDisabled
    ) {
      let datosCuentaSector = { cuenta: clienteIdSearch.id_cuentas };
      if (sectorIdSearch && sectorIdSearch !== "" && sectorIdSearch !== null) {
        let sectores = formatSectores();
        setSectorSeleccionado(sectorIdSearch.map((sector) => sector.descripcion).join(","));
        datosCuentaSector = { ...datosCuentaSector, sector: sectores };
      }
      setListDatosFormCuentaSector(datosCuentaSector);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteIdSearch, sectorIdSearch]);

  const estilosSelect = {
    fontSize: 14,
    fontFamily: "calibri",
    color: "#646464",
  };

  const limpiar = (e) => {
    setListDatosFormCuentaSector({});
    setClienteIdSearch(null);
    setSectorIdSearch([]);
    setFormCuentaSectorDisabled(false);
    setGrupoBusquedaSectorCliente(false);
  };

  const handleChangeSectores = (event, newValue) => {
    console.log("newValue", newValue)
    const datosExtraidos = newValue.reduce((acumulador, objeto) => {
      if (objeto.relations && Array.isArray(objeto.relations.cuentas_localizaciones_tipo_contenidos)) {
        // Si relations existe y cuentas_localizaciones_tipo_contenidos es un array
        const datos = objeto.relations.cuentas_localizaciones_tipo_contenidos;
        return acumulador.concat(datos);
      } else if (objeto.relations && typeof objeto.relations.cuentas_localizaciones_tipo_contenidos === 'object') {
        // Si relations existe y cuentas_localizaciones_tipo_contenidos es un objeto (un solo objeto)
        const datos = [objeto.relations.cuentas_localizaciones_tipo_contenidos];
        return acumulador.concat(datos);
      }
      // Si relations no existe o cuentas_localizaciones_tipo_contenidos no es un array ni un objeto, no hacemos nada
      return acumulador;
    }, []);

    setVerTodosTContenidos(true)
    setDisabledVerTodosTContenidos(false)
    setSectorIdSearch(newValue);
    setTipoContenidoListCuentaSector(datosExtraidos)
  };


  // Función personalizada de filtrado para buscar dentro del objeto cliente por id y razon_social
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => {
      const label = option.label.toLowerCase();
      const inputValueLowerCase = inputValue.toLowerCase();
      const idString = option.id_cuentas.toString().toLowerCase();
      return label.includes(inputValueLowerCase) || idString.includes(inputValueLowerCase);
    });
  };


  let url = window.location.href;
  let procesoGenerica = false;
  const arrUrl = url.split('/')
  const proceso = arrUrl[arrUrl.length - 1] // retorna el ultimo valor de la url

  if (proceso === "contenidos") {
    procesoGenerica = true;
    //titulo = "Alta de contenidos para Cajas Genéricas"
  }

  return (
    <div>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '3px' }}>
        <Grid item xs>
          {valorUbicacionTemporal === "1" && <SwitchCajasUbicadas />}
          {!procesoGenerica && <SwitchCuentaGenerica procesoGenerica={procesoGenerica} />}
        </Grid>
        <Grid item style={{ justifyContent: "right" }}>
          <Button
            variant="contained"
            onClick={limpiar}
            color="secondary"
            sx={{
              padding: '5px',
              minWidth: 'auto',
            }}>
            <ReplayIcon style={{
              padding: '0px',      // Ajusta el relleno (padding) para hacer el botón más pequeño
              position: 'center',
            }} />
          </Button>
        </Grid>
      </Grid>
      <Grid marginTop={2}>
        <Autocomplete
          size="small"
          value={clienteIdSearch ? clienteIdSearch : null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            setClienteIdSearch(newValue);
            setGrupoBusquedaSectorCliente(true);
            let valorCliente = newValue;
            if (valorCliente && valorCliente !== "") {
              const resultadoCliente = clientesListSearch.find(
                (cliente) => cliente.id_cuentas === valorCliente.id_cuentas
              );
              setClienteSeleccionado(resultadoCliente.razon_social);
              setClienteId(resultadoCliente.id_cuentas)
            }
            setSectorIdSearch([]);
          }}
          inputValue={inputValueCliente}
          onInputChange={(event, newInputValue) => {
            setInputValueCliente(newInputValue);
          }}
          id="controllable-states-demo1"
          options={clientesListSearch}
          filterOptions={filterOptions} // Aquí proporcionamos la función de filtrado personalizada
          style={estilosSelect}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.id_cuentas + ' - ' + option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cliente"
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        />
        <Autocomplete
          sx={{ mt: 1, minWidth: "100%", maxWidth: "100%" }}
          size="small"
          multiple // Permite selección múltiple
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={sectorIdSearch}
          onChange={handleChangeSectores}
          inputValue={inputValueSector}
          onInputChange={(event, newInputValue) => {
            setInputValueSector(newInputValue);
            setGrupoBusquedaSectorCliente(true)
          }}
          id="controllable-states-demo2"
          options={sectoresListSearch}
          style={estilosSelect}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.id + ' - ' + option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sector"
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        />
      </Grid>
    </div>
  );
};
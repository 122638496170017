import datos from "src/services/datos";
import Swal from "sweetalert2";

const objectTipoParametros = new datos("parametros_cuentas");

export const getTipoParametros = async () => {
  objectTipoParametros.addCondition("parametros_cuentas", "id", 0, ">");

  const response = await objectTipoParametros.get();

  if (response) {
    return response.wsResponse.parametros_cuentas;
  }
  return response;
};

export const deleteTipoTelefonos = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este tipo de telefono?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos('tipo_parametros');
      let data = {
        id: id
      }
      const response = await userData.delete(data);

      if (response.status === "true") {

        status = true;
        await Swal.fire(
          "Eliminado!",
          "El tipo de parametro ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message
        });
      }
    }
  });
  return status;
};

import React, { useEffect, useContext, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Container from '@mui/material/Container';
import { v4 as uuidv4 } from 'uuid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import ReplayIcon from '@mui/icons-material/Replay';
import { Autocomplete } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { SwitchCombinarFiltros } from "../components/SwitchCombinarFiltros";
import Box from "@mui/material/Box";

export const FormMetadatos = (datos = null) => {
  const {
    tipoContenidoSeleccionado,
    listDatosFormMetadatos, setListDatosFormMetadatos,
    metadatosList, setDataSearchFormMetadatos,
    formMetadatosDisabled, setFormMetadatosDisabled,
    tipoContenidoSeleccionadoinSearch, setTipoContenidoSeleccionadoinSearch,
    verTodosTContenidos, setVerTodosTContenidos, tipoContenidoListCuentaSector, disabledVerTodosTContenidos
    , inputFields, setInputFields, setGrupoBusquedaMetadato
  } = useContext(CajasContext);


  // eslint-disable-next-line no-unused-vars
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [tipoContenidoList, setTipoContenidoList] = useState([]);

  useEffect(() => {
    if (!verTodosTContenidos) {
      const tiposContendioListados = datos.tiposContenidosList.filter((tipoContenido) =>
        tipoContenidoListCuentaSector?.some((obj) => obj.id_tipo_contenidos === tipoContenido.id));
      setTipoContenidoList(tiposContendioListados)
    } else {
      setTipoContenidoList(datos.tiposContenidosList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoContenidoSeleccionado, verTodosTContenidos]);


  ///******************* Reemplazo de los buttons de confirmar


  useEffect(() => {

    if (tipoContenidoList !== "") {
      let data = [];
      setListDatosFormMetadatos(inputFields);
      for (var i = 0; i < inputFields.length; i++) {
        let filaAñadir = false;
        let idMetadato = inputFields[i].metadato;
        let idOperador = inputFields[i].operador;
        let filaMetadato = metadatosList.find(element => element.id === idMetadato);
        let filaOperador = inputFields[i].listOperadores.find(element => element.id === idOperador);
        if (inputFields[i].metadato !== "" && inputFields[i].operador !== "") {
          if (inputFields[i].valor !== "") {
            filaAñadir = {
              alias: filaMetadato.label_grilla, operadorMetadato: filaOperador.valor,
              valor: inputFields[i].valor,id_metadatos: idMetadato
            };
          } else if (inputFields[i].valorDesde !== "" && inputFields[i].valorHasta !== "") {
            filaAñadir = {
              alias: filaMetadato.label_grilla, operadorMetadato: filaOperador.valor,
              valor_desde: inputFields[i].valorDesde, valor_hasta: inputFields[i].valorHasta,id_metadatos: idMetadato
            }
          }
        }
        if (filaAñadir) {
          data = [...data, filaAñadir]
        }
      }
      setDataSearchFormMetadatos(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoContenidoList, listDatosFormMetadatos]);

  const limpiar = (e) => {
    setDataSearchFormMetadatos([]);
    setFormMetadatosDisabled(false);
    setInputFields([{
      id: uuidv4(), metadato: '', operador: '', valor: '',
      valorDesde: '', valorHasta: '', listOperadores: [], listMetadatos: metadatosList, tipoDato: 'text',
      valorDesdeHasta: false
    }])
    setTipoContenidoSeleccionadoinSearch([]);
    setListDatosFormMetadatos([{
      id: uuidv4(), metadato: '', operador: '', valor: '',
      valorDesde: '', valorHasta: '', listOperadores: [], listMetadatos: metadatosList, tipoDato: 'text',
      valorDesdeHasta: false
    }]);
    setGrupoBusquedaMetadato(false)
  };

  const handleChangeInput = (id, event, name) => {
    var metadatoRow = "";
    var tipoDato = metadatoRow.tipo_dato;

    const newInputFields = inputFields.map(i => {
      if (id === i.id) {
        if (name) {
          var dateValue = moment(event?.$d).format("YYYY-MM-DD")
          if (name === "valor") {
            i["valorDesde"] = "";
            i["valorHasta"] = "";
          } else {
            i["valor"] = "";
          }
          i[name] = dateValue; // el event en el datepicker es el valor.
        } else {
          if (event.target.name === "valor") {
            i["valorDesde"] = "";
            i["valorHasta"] = "";
          } else {
            i["valor"] = "";
          }
          i[event.target.name] = event.target.value.toUpperCase();
          if (event.target.name === "metadato") {
            i["valorDesdeHasta"] = false;
            i["tipoDato"] = "text";
            i["operador"] = "";
            i["valor"] = "";
            i["valorDesde"] = "";
            i["valorHasta"] = "";
            i["listMetadatos"] = metadatosList;
            if (event.target.value !== "") {
              metadatoRow = metadatosList.find(md => md.id === event.target.value);
              tipoDato = metadatoRow.tipo_dato;
            }
            if (tipoDato && event.target.value !== "") {
              switch (tipoDato) {
                case "CHAR":
                  i["listOperadores"] = operadoresChar;
                  i["tipoDato"] = "text";
                  break;
                case "TEXT":
                  i["listOperadores"] = operadoresChar;
                  i["tipoDato"] = "text";
                  break;
                case "INT":
                  i["listOperadores"] = operadoresInt;
                  i["tipoDato"] = "number";
                  break;
                case "DATE":
                  i["listOperadores"] = operadoresDate;
                  i["tipoDato"] = "date";
                  break;
                default:
                  break;
              }
            }
          } else if (event.target.name === "operador") {
            if (event.target.value === '8' || event.target.value === '14') {
              i["valorDesdeHasta"] = true;
            } else {
              i["valorDesdeHasta"] = false;
            }

          }

        }
      }
      return i;
    })
    setInputFields(newInputFields);
    setListDatosFormMetadatos(newInputFields);
    setGrupoBusquedaMetadato(true)
  }

  const handleAddFields = () => {
    setInputFields([...inputFields, {
      id: uuidv4(), metadato: '', operador: '', valor: '',
      valorDesde: '', valorHasta: '', listOperadores: [], listMetadatos: metadatosList, tipoDato: 'text',
      valorDesdeHasta: false
    }])
  }

  const handleRemoveFields = id => {
    const values = [...inputFields];
    values.splice(values.findIndex(value => value.id === id), 1);
    setInputFields(values);
    setGrupoBusquedaMetadato(false)
  }

  const operadoresChar =
    [{ id: "1", nombre: "Contiene", valor: "LIKE" },
    { id: "2", nombre: "Es igual a", valor: "=" }
    ];

  const operadoresInt =
    [
      { id: "3", nombre: "Es igual a", valor: "=" },
      { id: "4", nombre: "Es mayor a", valor: ">" },
      { id: "5", nombre: "Es mayor o igual a", valor: ">=" },
      { id: "6", nombre: "Es menor a", valor: "<" },
      { id: "7", nombre: "Es menor o igual a", valor: "<=" },
      { id: "8", nombre: "Entre", valor: "BETWEEN" }
    ];

  const operadoresDate =
    [
      { id: "9", nombre: "Es igual a", valor: "=" },
      { id: "10", nombre: "Es mayor a", valor: ">" },
      { id: "11", nombre: "Es mayor o igual a", valor: ">=" },
      { id: "12", nombre: "Es menor a", valor: "<" },
      { id: "13", nombre: "Es menor o igual a", valor: "<=" },
      { id: "14", nombre: "Entre", valor: "BETWEEN" }
    ];

  // Se utiliza para bloquear el boton confirmar del formulario si los campos estan vacios
  useEffect(() => {
    if (inputFields[0].metadato !== "" && inputFields[0].operador !== ""
      && (inputFields[0].valor !== "" ||
        (inputFields[0].valorDesde !== "" && inputFields[0].valorHasta !== ""))) {
      setButtonDisabled(false);
    } else if (tipoContenidoSeleccionado !== "") {
      setButtonDisabled(false);
    }
    else {
      setButtonDisabled(true);
    }
  }, [inputFields, tipoContenidoSeleccionado]);

  const handleChange = (event, newValue) => {
    setTipoContenidoSeleccionadoinSearch(newValue)
    setGrupoBusquedaMetadato(true)
  };

  const handleChangeSwitch = (event) => {
    setVerTodosTContenidos(event.target.checked);
    //setGrupoBusquedaMetadato(true)
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '3px' }}>
        <Grid item xs={6}>
          <SwitchCombinarFiltros />
        </Grid>
        <Grid item xs={3} >
          <FormControlLabel
            control={
              <Switch
                disabled={(disabledVerTodosTContenidos ? true : false)}
                checked={verTodosTContenidos}
                onChange={handleChangeSwitch}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Ver Todos"
          />
        </Grid>
        <Grid item style={{ justifyContent: "right" }} >
          <Button
            variant="contained"
            onClick={limpiar}
            color="secondary"
            sx={{
              padding: '5px',
              minWidth: 'auto',
            }}
          >
            <ReplayIcon style={{
              padding: '0px',      // Ajusta el relleno (padding) para hacer el botón más pequeño
              position: 'center',

            }} />
          </Button>
        </Grid>
      </Grid>
      <FormControl sx={{ m: 1 }} size="small" fullWidth>
        <Autocomplete
          value={Array.isArray(tipoContenidoSeleccionadoinSearch)? null : tipoContenidoSeleccionadoinSearch || []}
          onChange={handleChange}
          options={tipoContenidoList || []}
          // multiple
          getOptionLabel={(option) => {
            if (typeof option.detalle === 'string') {
              return option.detalle;
            } else {
              return '';
            }
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.id + ' - ' + option.detalle}
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params}
              label="Tipos de Contenido"
            />
          )}
        />
      </FormControl>
      <Container>
        {inputFields.map(inputField => (
          <div key={inputField.id}>
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <InputLabel id="selectMetadato">Metadato</InputLabel>
              <Select
                {...formMetadatosDisabled ? { disabled: true } : {}}
                labelId="selectMetadato"
                value={inputField.metadato}
                name="metadato"
                label="Metadato"
                onChange={event => handleChangeInput(inputField.id, event)}
              >
                <MenuItem
                  value="">Ninguno</MenuItem>
                {metadatosList &&
                  metadatosList.map((metadato) => {
                    return (
                      <MenuItem key={metadato.id} value={metadato.id}>
                        {metadato.label_form}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <InputLabel id="selectOperador">Operador</InputLabel>
              <Select
                {...formMetadatosDisabled ? { disabled: true } : {}}
                labelId="selectOperador"
                value={inputField.operador}
                label="Operador"
                name="operador"
                onChange={event => handleChangeInput(inputField.id, event)}
              >
                <MenuItem
                  value="">Ninguno</MenuItem>

                {inputField.listOperadores &&
                  inputField.listOperadores.map((operador) => {
                    return (
                      <MenuItem key={operador.id} value={operador.id}>
                        {operador.nombre}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {(inputField.tipoDato === "text" || inputField.tipoDato === "number")
              && !inputField.valorDesdeHasta &&
              <>
                <TextField
                  {...formMetadatosDisabled ? { disabled: true } : {}}
                  name="valor"
                  label="Valor"
                  variant="outlined"
                  size="small"
                  type={inputField.tipoDato}
                  inputProps={{ maxLength: 150 }}
                  sx={{ m: 1, minWidth: 100 }}
                  value={inputField.valor}
                  onChange={event => handleChangeInput(inputField.id, event)}
                />
              </>
            }
            {(inputField.tipoDato === "text" || inputField.tipoDato === "number")
              && inputField.valorDesdeHasta &&
              <>
                <TextField
                  {...formMetadatosDisabled ? { disabled: true } : {}}
                  name="valorDesde"
                  label="Desde"
                  variant="outlined"
                  size="small"
                  type={inputField.tipoDato}

                  sx={{ m: 1, maxWidth: 110 }}
                  value={inputField.valorDesde}
                  onChange={event => handleChangeInput(inputField.id, event)}
                />
                <TextField
                  {...formMetadatosDisabled ? { disabled: true } : {}}
                  name="valorHasta"
                  label="Hasta"
                  variant="outlined"
                  size="small"
                  type={inputField.tipoDato}

                  sx={{ m: 1, maxWidth: 110 }}
                  value={inputField.valorHasta}
                  onChange={event => handleChangeInput(inputField.id, event)}
                />
              </>}
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {inputField.tipoDato === "date" && !inputField.valorDesdeHasta &&
                  <DatePicker
                    {...formMetadatosDisabled ? { disabled: true } : {}}
                    name="valor"
                    label="Valor"
                    inputFormat="DD/MM/YYYY"
                    value={inputField.valor === "" ? null : inputField.valor}
                    variant="outlined"
                    onChange={date => handleChangeInput(inputField.id, date, "valor")}
                    renderInput={(params) => <TextField name="valor" size="small" sx={{ m: 1, maxWidth: 235 }}{...params} />}
                  />}
                {inputField.tipoDato === "date" && inputField.valorDesdeHasta &&
                  <>
                    <DatePicker
                      {...formMetadatosDisabled ? { disabled: true } : {}}
                      name="valorDesde"
                      label="Desde"
                      inputFormat="DD/MM/YYYY"
                      value={inputField.valorDesde === "" ? null : inputField.valorDesde}
                      variant="outlined"
                      onChange={date => handleChangeInput(inputField.id, date, "valorDesde")}
                      renderInput={(params) => <TextField name="valor" size="small" sx={{ m: 1, maxWidth: 110 }}{...params} />}
                    />
                    <DatePicker
                      {...formMetadatosDisabled ? { disabled: true } : {}}
                      name="valorHasta"
                      label="Hasta"
                      inputFormat="DD/MM/YYYY"
                      value={inputField.valorHasta === "" ? null : inputField.valorHasta}
                      variant="outlined"
                      onChange={date => handleChangeInput(inputField.id, date, "valorHasta")}
                      renderInput={(params) => <TextField name="valor" size="small" sx={{ m: 1, maxWidth: 110 }}{...params} />}
                    />
                  </>}
              </LocalizationProvider>
            </>
            <IconButton sx={{ m: 1 }} disabled={formMetadatosDisabled || inputFields.length === 1}
              onClick={() => handleRemoveFields(inputField.id)}>
              <RemoveIcon />
            </IconButton>
            <IconButton
              disabled={formMetadatosDisabled}
              onClick={handleAddFields}
            >
              <AddIcon />
            </IconButton>
          </div>
        ))}
      </Container>
    </div>
  );
};

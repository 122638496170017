let paramsProyect = JSON.parse(localStorage.getItem("parametros_proyecto"))
let mostrar = paramsProyect?.id === 30 ? true : false;

export const uiSchemaComprobantesForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/cliente",
      label: "Cliente/Sector",
      options: {
        readonly: true,
      },
    },
    {
      type: "HorizontalLayout",
      elements: mostrar
        ? [
          {
            type: "Control",
            scope: "#/properties/tipo",
          },
          {
            type: "Control",
            scope: "#/properties/comprobante_codigo",
          },

        ]
        : [
          {
            type: "Control",
            scope: "#/properties/comprobante_codigo",
          },
          {
            type: "Control",
            scope: "#/properties/comprobante_estado",
          },

        ],
    },
    {
      type: "HorizontalLayout",
      elements: !mostrar ? [
        {
          type: "Control",
          scope: "#/properties/tipo",
        },
      ] : [],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/codigo_cajas",
        },
        {
          type: "Control",
          scope: "#/properties/punto_venta",
        },
        {
          type: "Control",
          scope: "#/properties/terminal",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/tipo_fecha",
        },
        {
          type: "Control",
          scope: "#/properties/fecha_desde",
        },
        {
          type: "Control",
          scope: "#/properties/fecha_hasta",
        },
      ],
    },
  ],
};

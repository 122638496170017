import * as React from "react";
import { useContext } from "react";
import { CajasContext } from "../../../context/CajasContext/CajasContext";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import OutputIcon from "@mui/icons-material/Output";
import InputIcon from "@mui/icons-material/Input";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Grid from "@mui/material/Grid";
import PrintIcon from '@mui/icons-material/Print';
import {
  Button
} from "@mui/material";
import { comprobantesPrintData } from "../helpers/ComprobantesData";
// import { ContenidosProcesoList } from "./ContenidosProcesoList";

export const MovimientosTimelineMUI = (data) => {
  const { tipoHistorico, valorContenido } = useContext(CajasContext);
  console.log(localStorage, 'datahistorioco');

  const handleConfirmImpresionComprobantes = async (id_comprobante) => {
    // const id_comprobante = responseProcesar?.wsResponse?.id_comprobantes || false;

    if (id_comprobante) {
      try {
        // Obtén el contenido HTML
        const contenidoChild = await obtenerComprobanteAimprimir(id_comprobante);
        // Abre una nueva pestaña con el contenido HTML
        const nuevaVentana = window.open();
        nuevaVentana.document.write(contenidoChild);
      } catch (error) {
        console.error("Error al obtener el contenido HTML:", error);
        // Maneja el error según sea necesario
      }
    }
  };

  const obtenerComprobanteAimprimir = async (idComprobante) => {
    let paramsPrint = {
      id_comprobantes: idComprobante
    }
    let response = await comprobantesPrintData(paramsPrint)
    return response;
  };

  return (
    <>

      {data.data.length > 0 ?
        (<Timeline position="alternate" pivot="vertical" direction="left" tooltip={"true"} data-text-limit="none">
          {tipoHistorico &&
            data.data.map((historico, index) => (
              <TimelineItem key={index + 1} >
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                >
                  {typeof historico.fecha === "object"
                    ? ""
                    : historico.fecha.slice(0, -8)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot
                    style={{ background: "white", border: "2px solid #000000" }}
                  >
                    {historico.tipo_movimiento === "Mudanza" && (
                      <MoveUpIcon sx={{ color: "black", fontSize: "235%" }} />
                    )}
                    {historico.tipo_movimiento === "Ubicación inicial" && (
                      <WhereToVoteIcon sx={{ color: "black", fontSize: "235%" }} />
                    )}
                    {historico.tipo_movimiento === "Retiro Parcial" && (
                      <OutputIcon sx={{ color: "black", fontSize: "235%" }} />
                    )}
                    {historico.tipo_movimiento === "Retiro Total" && (
                      <OutputIcon sx={{ color: "black", fontSize: "235%" }} />
                    )}
                    {historico.tipo_movimiento === "Retiro de caja" && (
                      <OutputIcon sx={{ color: "black", fontSize: "235%" }} />
                    )}
                    {historico.tipo_movimiento === "Devolución Total" && (
                      <InputIcon sx={{ color: "black", fontSize: "235%" }} />
                    )}
                    {historico.tipo_movimiento === "Devolución Parcial" && (
                      <InputIcon sx={{ color: "black", fontSize: "235%" }} />
                    )}
                    {historico.tipo_movimiento === "Creación de ubicación" && (
                      <ShareLocationIcon
                        sx={{ color: "black", fontSize: "235%" }}
                      />
                    )}
                    {historico.tipo_movimiento ===
                      "Alojamiento de caja generica" && (
                        <GpsFixedIcon sx={{ color: "black", fontSize: "235%" }} />
                      )}
                    {historico.tipo_movimiento === "Ubicación tempral" && (
                      <SaveAltIcon sx={{ color: "black", fontSize: "235%" }} />
                    )}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "2.5%", px: 2 }}>
                  <Typography variant="h6" component="span">
                    <strong>
                      {typeof historico.tipo_movimiento === "object"
                        ? ""
                        : historico.tipo_movimiento}
                    </strong>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Ubicación: </strong>
                    {typeof historico.ubicacion === "object"
                      ? ""
                      : historico.ubicacion}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Usuario: </strong>
                    {typeof historico?.usuario === "object" ? "" : historico?.usuario}
                  </Typography>
                  {typeof historico?.comprobante_detalle_emision !== "object" &&
                    (<Typography variant="body2" color="text.secondary">
                      <strong>Prioridad: </strong>
                      {typeof historico?.comprobante_detalle_emision === "object" ? "" : historico?.comprobante_detalle_emision}
                    </Typography>)}
                  {typeof historico?.comprobante_nombre !== "object" && (<Typography variant="body2" color="text.secondary">
                    <strong>Nombre: </strong>
                    {typeof historico?.comprobante_nombre === "object" ? "" : historico?.comprobante_nombre}
                  </Typography>)}


                  {
                    // (historico.tipo_movimiento.includes("Devolución") || historico.tipo_movimiento.includes("Retiro")) && (

                    historico.extra_data?.length > 0 ? (
                      (() => {
                        const correctedStringData = historico.extra_data.replace(/'/g, '"');
                        const jsonArrayString = '[' + correctedStringData.replace(/;/g, ',') + ']';
                        const objects = JSON.parse(jsonArrayString);

                        const capitalizeFirstLetter = (string) => {
                          return string.charAt(0).toUpperCase() + string.slice(1);
                        };

                        // Crear un array de componentes JSX para cada propiedad
                        const extraDataComponents = objects.map((obj, index) => {
                          const properties = [];
                          for (const key in obj) {
                            if (key !== "field") {
                              const propValue = obj[key].value;
                              const formattedKey = key.replace(/_/g, ' ');
                              const capitalizedKey = capitalizeFirstLetter(formattedKey);
                              properties.push(
                                <Typography variant="body2" color="text.secondary" key={key}>
                                  <strong>{capitalizedKey}:</strong> {propValue}
                                </Typography>
                              );
                            }
                          }
                          // Retorna un div con las propiedades de cada objeto
                          return <div key={index}>{properties}</div>;
                        });

                        return extraDataComponents;
                      })()
                    ) : <span></span>

                    // )
                  }
                  <Grid item xs={5}>
                    <Button
                      onClick={() => handleConfirmImpresionComprobantes(historico?.comprobante_id)}
                      variant="contained"
                      color="primary"
                      fullWidth
                      startIcon={<PrintIcon />}
                      disabled={Object.keys(historico?.comprobante_id).length === 0 ? true : false}
                      style={{ color: 'white', fontSize: '13px', fontFamily: 'Roboto, sans-serif', }}
                    >
                      Comprobante
                    </Button>
                  </Grid>
                </TimelineContent>
              </TimelineItem>
            ))}

          {!tipoHistorico && (
            <Grid>
              <Typography sx={{ mt: -3.5, ml: -4.3, fontSize: 12 }} style={{ marginBottom: "2%" }}>
                <font color="Gray">
                  {typeof valorContenido === "object" ? "" : valorContenido}
                </font>
              </Typography>
              {data.data.map((contenido, index) => (
                <>
                  <TimelineItem key={index + 1}>
                    <TimelineOppositeContent
                      sx={{ m: "auto 0" }}
                      align="right"
                      variant="body2"
                      color="text.secondary"
                    >
                      {typeof contenido.fecha === "object"
                        ? ""
                        : contenido.fecha.slice(0, -8)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot
                        style={{ background: "white", border: "2px solid #000000" }}
                      >
                        {contenido.tipo_movimiento === "Quita Total" && (
                          <DeleteForeverIcon
                            sx={{ color: "black", fontSize: "235%" }}
                          />
                        )}
                        {contenido.tipo_movimiento === "Retiro Total" && (
                          <OutputIcon sx={{ color: "black", fontSize: "235%" }} />
                        )}
                        {contenido.tipo_movimiento === "Retiro Parcial" && (
                          <OutputIcon sx={{ color: "black", fontSize: "235%" }} />
                        )}
                        {contenido.tipo_movimiento === "Retiro de caja" && (
                          <OutputIcon sx={{ color: "black", fontSize: "235%" }} />
                        )}
                        {contenido.tipo_movimiento === "Devolución Total" && (
                          <InputIcon sx={{ color: "black", fontSize: "235%" }} />
                        )}
                        {contenido.tipo_movimiento === "Devolución Parcial" && (
                          <InputIcon sx={{ color: "black", fontSize: "235%" }} />
                        )}
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "3%", px: 2 }}>
                      <Typography variant="h6" component="span">
                        <strong>
                          {typeof contenido.tipo_movimiento === "object"
                            ? ""
                            : contenido.tipo_movimiento}
                        </strong>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Detalle: </strong>
                        {typeof contenido.detalle === "object"
                          ? ""
                          : contenido.detalle}
                      </Typography>
                      {typeof contenido?.comprobante_detalle_emision !== "object" &&
                        (<Typography variant="body2" color="text.secondary">
                          <strong>Prioridad: </strong>
                          {typeof contenido?.comprobante_detalle_emision === "object" ? "" : contenido?.comprobante_detalle_emision}
                        </Typography>)}
                      {typeof contenido?.comprobante_nombre !== "object" && (<Typography variant="body2" color="text.secondary">
                        <strong>Nombre: </strong>
                        {typeof contenido?.comprobante_nombre === "object" ? "" : contenido?.comprobante_nombre}
                      </Typography>)}
                      {(contenido.tipo_movimiento.includes("Devolución") || contenido.tipo_movimiento.includes("Retiro")) && (

                        contenido.extra_data?.length > 0 ? (
                          (() => {
                            const correctedStringData = contenido.extra_data.replace(/'/g, '"');
                            const jsonArrayString = '[' + correctedStringData.replace(/;/g, ',') + ']';
                            const objects = JSON.parse(jsonArrayString);

                            const capitalizeFirstLetter = (string) => {
                              return string.charAt(0).toUpperCase() + string.slice(1);
                            };

                            // Crear un array de componentes JSX para cada propiedad
                            const extraDataComponents = objects.map((obj, index) => {
                              const properties = [];
                              for (const key in obj) {
                                if (key !== "field") {
                                  const propValue = obj[key].value;
                                  const formattedKey = key.replace(/_/g, ' ');
                                  const capitalizedKey = capitalizeFirstLetter(formattedKey);
                                  properties.push(
                                    <Typography variant="body2" color="text.secondary" key={key}>
                                      <strong>{capitalizedKey}:</strong> {propValue}
                                    </Typography>
                                  );
                                }
                              }
                              // Retorna un div con las propiedades de cada objeto
                              return <div key={index}>{properties}</div>;
                            });

                            return extraDataComponents;
                          })()
                        ) : <span></span>

                      )}
                    </TimelineContent>
                  </TimelineItem>
                </>
              ))}
            </Grid>
          )}
        </Timeline>) : (
          <Grid style={{ textAlign: 'center', alignContent: 'center' }}>
            <Typography variant="h5" gutterBottom style={{ color: '#3c4b64' }}>
              Aun no posee un historico.
            </Typography>
          </Grid>

        )}

    </>
  );
};

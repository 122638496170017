export const uischema = {
  type: "HorizontalLayout",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          label: "",
          scope: "#/properties/idDep",
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          label: "Sector",
          scope: "#/properties/idSector",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/cantSector",
              schema: { type: "integer" },
            },
          },
        },
        {
          type: "Control",
          label: "",
          scope: "#/properties/cantSector",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/idSector",
              schema: { type: "integer" },
            },
          },
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          label: "Modulo",
          scope: "#/properties/idModulo",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/cantModulo",
              schema: { type: "integer" },
            },
          },
        },
        {
          type: "Control",
          label: "",
          scope: "#/properties/cantModulo",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/idModulo",
              schema: { type: "integer" },
            },
          },
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          label: "Estante",
          scope: "#/properties/idEstante",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/cantEstante",
              schema: { type: "integer" },
            },
          },
        },
        {
          type: "Control",
          label: "",
          scope: "#/properties/cantEstante",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/idEstante",
              schema: { type: "integer" },
            },
          },
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          label: "Posicion",
          scope: "#/properties/idPosicion",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/cantPosicion",
              schema: { type: "integer" },
            },
          },
        },
        {
          type: "Control",
          label: "",
          scope: "#/properties/cantPosicion",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/idPosicion",
              schema: { type: "integer" },
            },
          },
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          label: "Estibe",
          scope: "#/properties/idEstiba",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/cantEstiba",
              schema: { type: "integer" },
            },
          },
        },
        {
          type: "Control",
          label: "",
          scope: "#/properties/cantEstiba",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/idEstiba",
              schema: { type: "integer" },
            },
          },
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          label: "Profundidad",
          scope: "#/properties/idProfundidad",
          // rule: {
          //   effect: "DISABLE",
          //   condition: {
          //     scope: "#",
          //     schema: {},
          //   },
          // },
        },
        {
          type: "Control",
          label: "",
          scope: "#/properties/cantProfundidad",
          rule: {
            effect: "DISABLE",
            condition: {
              scope: "#/properties/idProfundidad",
              schema: { type: "integer" },
            },
          },
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [

        {
          type: "Control",
          scope: "#/properties/id_estado_ubicaciones",
        },
        {
          type: "Control",
          scope: "#/properties/id_tipo_ubicaciones",
        },
      ],
    },
   
  ],
};

import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "src/context/AuthContext";
import { useForm } from "src/hooks/useForm";
import { CajasContext } from "../../../context/CajasContext/CajasContext";
import { DateFormComponent } from "../../../forms/customRenders/dateForm";
import {
  InputNumberComponent,
  InputStringComponent,
} from "../../../forms/customRenders/dobleInput";
import { SelectorTipoCajasComponent } from "../../../forms/customRenders/selectorTipoCajas";
import { schemaCajasForm } from "../../../forms/schemaCajasForm";
import { uiSchemaCajasForm } from "../../../forms/uiSchemaCajasForm";
import { postCajas } from "../helpers/CajasData";
import esArray from "src/general_components/functionEsArray";
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './styles/JsonForm.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';  // Nota el cambio aquí
import CssBaseline from '@mui/material/CssBaseline';  // Nota el cambio aquí
//import { getTipoCaja } from "../helpers/CajasTipos";

export const CajasForm = (data = null) => {
  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [showInput, setShowInput] = useState(true);
  // const [disabledButtonsCopy, setDisabledButtonsCopy] = useState(false);
  //const [idsCajasCreadas, setIdsCajasCreadas] = useState('');
  const [cantidadIdsCajasCreadas, setCantidadIdsCajasCreadas] = useState(0);
  const [idMasAltos, setIdMasAlto] = useState(0);
  const [idMasBajos, setIdMasBajo] = useState(0);

  const auth = useContext(AuthContext);
  const date = new Date();
  let caja_aux1 = data.data;


  const localize = require("ajv-i18n");

  const dateConvertido = moment(date).local().format("YYYY-MM-DD HH:MM:SS");

  const {
    clienteId,
    sectorId,
    tipoCaja,
    sectoresList,
    setSeverity,
    setMessage,
    setSnackbarVisible,
    idsCajasCreadas,
    setIdsCajasCreadas,
    disabledButtonsCopy,
    setDisabledButtonsCopy
  } = useContext(CajasContext);

  const toggleModal = () => {
    data.toggleModal();
    updateList();
  };

  const updateList = () => {
    data.updateList();
  };

  const [CajaInfo, setCajaInfo] = useForm({
    cantidad: "",
    id: "",
    id_tipo_cajas: `${tipoCaja}`,
    id_cuentas_localizaciones: `${sectorId.id}`,
    fecha_creacion: `${dateConvertido}`,
    detalle: caja_aux1?.detalle || "",
    referencia: caja_aux1?.referencia || "",
    ubicacion_temporal: caja_aux1?.ubicacion_temporal || "",
  });

  const mostrarDescripcionSector = sectoresList
    .filter(
      (sector) => sector.id_cuentas === clienteId.id_cuentas && sector.id === sectorId.id
    )
    .map((sector) => sector.descripcion);

  /// Contiene los datos del Jsonform
  const [dataForm, setDataForm] = useState({
    ...CajaInfo,
    descripcionClienteSector: clienteId.id_cuentas + "-" + mostrarDescripcionSector[0],
  });

  useEffect(() => {
    if (data.data) {
      setShowInput();
      let caja_aux = data.data;
      setCajaInfo.set({
        id: caja_aux.id,
        id_tipo_cajas: caja_aux.id_tipo_cajas,
        id_cuentas_localizaciones: caja_aux.id_cuentas_localizaciones,
        fecha_creacion: caja_aux.fecha_creacion,
        detalle: upperCaseTransformer(caja_aux.detalle),
        referencia: caja_aux.referencia,
        ubicacion_temporal: caja_aux.ubicacion_temporal,
      });
      if (typeof caja_aux.referencia === "object") {
        caja_aux.referencia = "";
      }
      setDataForm({
        id: caja_aux.id,
        id_tipo_cajas: caja_aux.id_tipo_cajas,
        id_cuentas_localizaciones: caja_aux.id_cuentas_localizaciones,
        fecha_creacion: caja_aux.fecha_creacion,
        detalle: upperCaseTransformer(caja_aux.detalle),
        referencia: caja_aux.referencia,
        ubicacion_temporal: caja_aux.ubicacion_temporal,
        modificar: true,
        descripcionClienteSector: clienteId.id_cuentas + "-" + mostrarDescripcionSector[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { cantidad, id } = dataForm;

  const mensajeErrorResponse = (responseStatus) => {
    let mensaje = "";
    if (responseStatus.message.error) {
      mensaje = responseStatus.message.error;
    } else {
      if (responseStatus.message.error_fields) {
        if (responseStatus.message.error_fields.ubicacion_temporal) {
          mensaje = responseStatus.message.error_fields.ubicacion_temporal;
        } else if (responseStatus.message.error_fields.referencia) {
          mensaje = responseStatus.message.error_fields.referencia;
        } else if (responseStatus.message.error_fields.detalle) {
          mensaje = responseStatus.message.error_fields.detalle;
        }
      }
    }
    return mensaje;
  };
  const copiarRango = (idMasBajo, idMasAlto) => {
    let rango = idMasBajo;
    if (idMasBajo !== idMasAlto) {
      rango = `${idMasBajo}-${idMasAlto}`;
    }

    copiarAlPortapapeles(rango);

  };

  const copiarTodos = (ids) => {
    const idsSeparadosPorComa = ids.join(',');
    copiarAlPortapapeles(idsSeparadosPorComa);
  };

  const copiarAlPortapapeles = (texto) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(texto).then(
        () => {
          console.log('Texto copiado al portapapeles');
          setSnackbarVisible(false);
        },
        (err) => {
          console.error('Error al copiar al portapapeles: ', err);
        }
      );
    }
  };

  useEffect(() => {
    // Verificar si idsCajasCreadas tiene valores
    if (idsCajasCreadas.length > 0) {
      const idMasBajo = Math.min(...idsCajasCreadas);
      const idMasAlto = Math.max(...idsCajasCreadas);
      setIdMasBajo(idMasBajo);
      setIdMasAlto(idMasAlto);

      setDisabledButtonsCopy(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idsCajasCreadas]);

  useEffect(() => {
    // Verificar si idsCajasCreadas tiene valores
    if (idMasBajos > 0 || idMasAltos > 0) {
      const rangoIds = idMasBajos !== idMasAltos ? (
        <span style={{ fontWeight: 'bold' }}>{idMasBajos} - {idMasAltos}</span>
      ) : (
        <span style={{ fontWeight: 'bold' }}>{idMasBajos}</span>
      );
      setMessage(
        <div style={{ textAlign: 'center' }}>
          <p>
            Se han añadido exitosamente {cantidadIdsCajasCreadas} cajas.
            IDs: {rangoIds}
          </p>
        </div>
      )

      setSeverity("success");
      setSnackbarVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cantidadIdsCajasCreadas]);

  const handleSubmit = async (e) => {
    let responseServicio = "";
    if (cantidad) {
      if (cantidad > 0 && cantidad < 1001) {
        //NOTE SE RECORRE LA CANTIDAD Y SE CREA UN ARRAY, ESE ES EL QUE SE ENVIA AL SERVICIO POST
        let data = [];
        for (var i = 0; i < cantidad; i++) {
          e.preventDefault();
          data.push({ ...dataForm, id_cuentas_localizaciones: sectorId.id });
        }
        responseServicio = await postCajas(data, auth);
        if (responseServicio.status === "false") {
          let mensaje = mensajeErrorResponse(responseServicio);
          setMessage(mensaje);
          setSeverity("error");
          setSnackbarVisible(true);
        } else {
          setMessage(
            <div style={{ textAlign: 'center' }}>
              <p>
                Se han añadido exitosamente.
              </p>
            </div>)
        }
        const idCajas = esArray(responseServicio.wsResponse.cajas);
        setIdsCajasCreadas((prevIds) => [...prevIds, ...idCajas]);
        setCantidadIdsCajasCreadas((prevCantidad) => prevCantidad + parseInt(cantidad, 10));
      } else {
        setMessage(
          "El valor de la cantidad debe ser mayor a 0 y menor o igual a 1000"
        );
        setSeverity("error");
        setSnackbarVisible(true);
      }
    } else {
      if (id) {
        e.preventDefault();
        let data = dataForm;
        data.id_cuentas_localizaciones = sectorId.id;
        responseServicio = await postCajas(data, auth);
        if (responseServicio.status === "false") {
          let mensaje = mensajeErrorResponse(responseServicio);
          setMessage(mensaje);
          setSeverity("error");
          setSnackbarVisible(true);
        } else {
          setMessage("Se ha modificado exitosamente!!!");
          setSeverity("success");
          setSnackbarVisible(true);
          // let cajas = await getCajas(sectorId, altaGenerica);
          // await setListCajas(cajas);
          updateList();
          toggleModal();
        }
      } else {
        setMessage("Tiene que ingresar una cantidad");
        setSeverity("error");
        setSnackbarVisible(true);
      }
    }
  };


  const upperCaseTransformer = (value) => (value ? value.toUpperCase() : "");

  // eslint-disable-next-line no-unused-vars
  const [withErrors, setWithErrors] = useState(false);

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);

    // Convierte a mayúsculas el valor del campo 'detalle' si existe
    data.detalle = upperCaseTransformer(data.detalle);

    setDataForm(data);

    return { errors, data };
  };


  const customTheme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            textTransform: 'uppercase',
          },
        },
      },
    },
  });


  return (
    <div>
      <>
        <ThemeProvider theme={customTheme}>
          <CssBaseline />
          <JsonForms
            schema={schemaCajasForm}
            uischema={uiSchemaCajasForm}
            data={dataForm}
            renderers={[
              ...materialRenderers,
              SelectorTipoCajasComponent,
              DateFormComponent,
              InputNumberComponent,
              InputStringComponent,
            ]}
            cells={materialCells}
            onChange={({ errors, data }) =>
              onChange && onChange(errors, data)
            }
          />

        </ThemeProvider>

        <Stack
          justifyContent="flex-end"
          direction="row"
          spacing={2}
          style={{ marginTop: "5%" }}
        >
          {disabledButtonsCopy && (
            <div>
              <Button
                style={{ width: '150px', margin: '5px' }}
                color="primary"
                variant="contained"
                size="small"
                onClick={() => copiarRango(idMasBajos, idMasAltos)}
                startIcon={<FlipToFrontIcon />}
              >
                Copiar Rango
              </Button>
              <Button
                style={{ width: '150px', margin: '5px' }}
                color="primary"
                variant="contained"
                size="small"
                onClick={() => copiarTodos(idsCajasCreadas)}
                startIcon={<ContentCopyIcon />}  // Icono en el inicio del botón
              >
                Copiar IDs
              </Button>
            </div>
          )}
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="success"
            startIcon={<CheckCircleOutlineIcon />}
          >
            Procesar
          </Button>
          <Button
            onClick={() => toggleModal()}
            variant="contained"
            color="error"
            startIcon={<HighlightOffIcon />}
          >
            Cancelar
          </Button>
        </Stack>
      </>
    </div>
  );
};

import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
const comprobantes_codigos = new datos("comprobantes_codigos");

export const getComprobantes = async (auth) => {
  comprobantes_codigos.addCondition("comprobantes_codigos", "id", 0, ">");
  const response = await comprobantes_codigos.get();
  return esArray(response.wsResponse.comprobantes_codigos);
};

export const getComprobantesCodigos = async (auth) => {
  comprobantes_codigos.addCondition("comprobantes_codigos", "id", 0, ">");
  const response = await comprobantes_codigos.get();
  return esArray(response.wsResponse.comprobantes_codigos);
};

export const getComprobantesPaginated = async (limit, offset) => {
  comprobantes_codigos.addCondition("comprobantes_codigos", "id", 0, ">");
  comprobantes_codigos.addLimitCondition(limit, offset);
  const response = await comprobantes_codigos.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: esArray(response.wsResponse.comprobantes_codigos),
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const deleteComprobanteCodigo = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este Comprobante Codigo?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const comprobantes_codigos = new datos("comprobantes_codigos");
      let data = {
        id: id,
      };
      const response = await comprobantes_codigos.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El Comprobante Codigo ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};


export const postTipoCajas = async (estado) => {
  const response = comprobantes_codigos.insert(estado);
  return response;
};

export const deleteTipoCajas = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este tipo de cajas?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos("tipo_cajas");
      let data = {
        id: id,
      };
      const response = await userData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El tipo de caja ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};

import datos from "src/services/datos";

import esArray from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css"


export const getInformes = async (auth) => {
    const prd_orden_produccion = new datos("prd_orden_produccion");
    prd_orden_produccion.addCondition("prd_orden_produccion", "id", 0, ">");
    const response = await prd_orden_produccion.get();
    return esArray(response.wsResponse.prd_orden_produccion);
};

export const getInformesPaginated = async (limit, offset) => {
    const prd_orden_produccion = new datos("prd_orden_produccion");
    prd_orden_produccion.addCondition("prd_orden_produccion", "id", 0, ">");
    prd_orden_produccion.join("prd_equipos", "id_prd_orden_produccion", "id", "=");
    prd_orden_produccion.innerJoinTables('articulos', 'prd_equipos', "id", "id_articulos", "=")
    prd_orden_produccion.join("cuentas_localizaciones", "id", "id_cuentas_localizaciones", "=");
    prd_orden_produccion.innerJoinTables('cuentas_personas_juridicas', 'cuentas_localizaciones', "id_cuentas", "id_cuentas", "=")
    prd_orden_produccion.join("prd_estados", "id", "id_prd_estados", "=");
    prd_orden_produccion.join("prd_mano_obra", "id_prd_orden_produccion", "id", "=");
    prd_orden_produccion.innerJoinTables('cuentas_personas_fisicas', 'prd_mano_obra', "id_cuentas", "id_cuentas", "=")
    prd_orden_produccion.addOrderCondition("prd_orden_produccion", "id", "desc")
    prd_orden_produccion.addLimitCondition(limit, offset);
    const response = await prd_orden_produccion.get();
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

    let result = {};
    if (!response.wsResponse.empty) {
        result = {
            data: modificarEstructura(esArray(response.wsResponse.prd_orden_produccion)),
            total_records: response.wsResponse.total_records,
        };
        console.log("result", result)
        return result;
    }
};

const modificarEstructura = (data) => {
    return data.map(item => {
        const tecnico = `${item.relations.cuentas_personas_fisicas.nombre} ${item.relations.cuentas_personas_fisicas.apellido}`;
        const maquina = item.relations.articulos.nombre;
        const cliente = item.relations.cuentas_personas_juridicas.razon_social;
        const estado = item.relations.prd_estados.detalle;

        return {
            ...item,
            tecnico,
            maquina,
            cliente,
            estado
        };
    });
};

export const getInformeDetalle = async (id_informe) => {
    const prd_orden_produccion = new datos("prd_orden_produccion");
    const data = {
        "id": id_informe,
        "numero": false,
        "id_cuentas": false
    }
    const response = await prd_orden_produccion.produccionGet(data);
    return response.wsResponse.informes;
};
import { Container, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ArticulosContext } from "src/context/ArticulosContext";
import { Buttons } from "src/general_components/Buttons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// /* Formulario contenedor de los datos de las CUENTAS JURIDICAS */

export const ArticulosInfoAdicionalForm = (props) => {
  const {
    toggleModal
  } = props;
  const {
    articulosEspecificacion,
    setArticulosEspecificacionFormData,
    setArticulosFormData,
    saveData,
  } = useContext(ArticulosContext);


  const [editorData, setEditorData] = useState('');

  useEffect(() => {
    if (articulosEspecificacion?.detalle?.length > 0) {

      try {
        // Decodificar el contenido base64 usando la función base64ToUnicode
        const decodedEditorData = base64ToUnicode(articulosEspecificacion.detalle);

        // Establecer el contenido desformateado en el editor
        setEditorData(decodedEditorData);
      } catch (error) {
        setEditorData('<p>' + articulosEspecificacion.detalle + '</p>');

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articulosEspecificacion]);

  // Función para verificar si una cadena contiene caracteres no ASCII
  const containsNonASCII = (str) => {
    return /[^\u0020-\u007E]/.test(str);
  }


  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike']
    ],
  };

  // Función para codificar una cadena Unicode a base64
  const unicodeToBase64 = (str) => {
    // Codifica la cadena Unicode a una secuencia de bytes
    const bytes = new TextEncoder().encode(str);
    // Crea una cadena binaria a partir de la secuencia de bytes
    const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
    // Codifica la cadena binaria a base64
    return btoa(binary);
  }

  // Función para codificar a base64, manejando caracteres Unicode si es necesario
  const safeBase64Encode = (str) => {
    if (containsNonASCII(str)) {
      return unicodeToBase64(str);
    } else {
      return btoa(str);
    }
  }

  // Función para decodificar base64 a una cadena Unicode
  const base64ToUnicode = (str) => {
    const binary = atob(str);
    const bytes = Uint8Array.from(binary, char => char.charCodeAt(0));
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
  }

  useEffect(() => {

    if (editorData) {
      // const editorDataBase64 = btoa(editorData);
      const editorDataBase64 = safeBase64Encode(editorData);
      setArticulosEspecificacionFormData({
        ...articulosEspecificacion,
        detalle: editorDataBase64,
      });
    }

    if (props.data) {
      setArticulosFormData({
        id: props.data.id,
        nombre: props.data.nombre,
        nombre_reducido: props.data.nombre_reducido,
        alias: props.data.alias,
        selectedCategoria: props.data.id_categorias,
        selectedArticulosTipo: props.data.id_articulos_tipo,
        selectedTipoMoneda: props.data.id_tipo_moneda,
        selectedAlicuotasIVA: props.data.id_alicuotas_iva,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorData]);


  const toggleModalForm = () => {
    toggleModal();
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid>
          <ReactQuill
            theme="snow" // Selecciona el tema del editor (puedes cambiarlo según tus preferencias)
            value={editorData}
            onChange={(value) => setEditorData(value)}
            modules={modules}
          />
        </Grid>
        <Grid sx={{ mt: 1, marginTop: 5 }}>
          <Buttons
            // disableButton={withErrors ? true : false}
            onClickSubmit={saveData}
            labelButtonSave={"Confirmar"}
            buttonCerrar={toggleModalForm}
          />
        </Grid>
      </Container>
    </>
  );
};

import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";
import {
  getMetadatosWhereIdTipoContenidos,
  getMetadatosWhereIdTipoContenidosPaginated,
  deleteMetadatos,
} from "../helpers/MetadatoData";
import { MetadatosForm } from "./MetadatosForm";

export const MetadatosList = (props) => {
  const { tipoContenido } = props;
  const [createButton, setCreateButton] = useState(false);
  const [metadato, setMetadato] = useState([]);
  const listName = "";
  const [refreshList, setRefreshList] = useState(false);
  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "label_form", headerName: "Label del Formulario", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    { field: "label_grilla", headerName: "Label de Grilla", flex: 1 },
    { field: "tipo_dato", headerName: "Tipo de Dato ", flex: 1 },
  ];

  useEffect(() => {
    setOptions({ ...options, refresh: refreshList })
    setRefreshList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshList]);

  const [options, setOptions] = useState({
    cols: columns,
    get: getMetadatosWhereIdTipoContenidos,
    getPaginated: getMetadatosWhereIdTipoContenidosPaginated,
    delete: deleteMetadatos,
    pageSize: [5, 10, 25],
    edit: true,
    listName,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,
    idElementData: tipoContenido.id,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setMetadato,
  });

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  if (createButton === false && metadato.id !== undefined) {
    setMetadato([]);
  }

  return (
    <>
      <ListTablePaginated options={options} />
      <MetadatosForm
        data={metadato}
        setCreateButton={setCreateButton}
        createButton={createButton}
        listName={listName}
        tipoContenido={tipoContenido}
        setRefreshList={setRefreshList}
      />
      <Grid
        item
        xs={12}
        style={{
          marginTop: "1rem",
        }}
      >
        {/* <Button
          color="primary"
          size="large"
          variant="contained"
          style={{
            borderRadius: 5,
            backgroundColor: "#1565c0",
            color: "#FFFFFF",
            padding: "5px 20px",
            fontSize: "13px",
            marginLeft: "1rem",
          }}
          onClick={() => setModalListMetadatos(false)}
        >
          Volver
        </Button> */}
      </Grid>
    </>
  );
};

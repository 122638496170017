import { populateServiceData } from './initDataLoad';


export const saveData = async (response) => {
    /**
     * Esta funcion recibe como parametros un objeto proveniente de la respuesta del login.
     * Guarda la informacion obtenida y devuelve true o false para indicar si pudo recuperar los datos o no
     */
    const { wsResponse } = response;
    if (response.status === "true") {
        const module_menu = wsResponse.module_menu;
        const user_data = wsResponse.user_data;

        let user_cuenta = [];
        if (wsResponse.cuentas && wsResponse.cuentas.usuarios_cuentas) {
            user_cuenta.push(wsResponse.cuentas.usuarios_cuentas.usuarios_cuentas)
        }
        if (wsResponse.cuentas && wsResponse.cuentas[0]) {
            wsResponse.cuentas.forEach((cuentas) => {
                user_cuenta.push(cuentas.usuarios_cuentas)
            })
        }

        if (!Array.isArray(user_cuenta)) {
            user_cuenta = [user_cuenta]
        }
        // Quitamos la cotraseña ya que no es un dato que vayamos a necesitar
        delete user_data.password;

        localStorage.setItem("siam-module-menu", JSON.stringify(module_menu));
        localStorage.setItem("siam-user-data", JSON.stringify(user_data));
        localStorage.setItem("siam-user-cuentas", JSON.stringify(user_cuenta))

        populateServiceData();

        localStorage.setItem(`openSideMenu`, false);
    }
    return response;
};

export const getData = (data) => {
    return JSON.parse(localStorage.getItem(data));
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useState, useContext } from "react";
import { esArray } from "src/general_components/functionEsArray";
import { Autocomplete, TextField } from "@mui/material";
import { TheLayoutContext } from "src/context/TheLayoutContext";

const SelectorClientes = (props) => {
  const { setClienteFichaSelected } = useContext(TheLayoutContext)
  const [selectedClientes, setSelectedClientes] = useState("");
  const [clientesList, setClientesList] = useState("");

  const jsonForm = useJsonForms();
  const disabled = jsonForm.core.data.disabled;
  const clienteSelected = jsonForm.core.data.cliente;
  const editar = jsonForm.core.data.editar;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useState(() => {
    const getClientes = async () => {
      var clientes = [];
      let cuentas = esArray(JSON.parse(localStorage.getItem("siam-service-getCuentasGenericas")).cuentas);
      let condicionIn = false

      //clientes = await getCuentasJuridicas(cuentas,condicionIn);

      let clients = esArray(JSON.parse(localStorage.getItem("siam-cuentas_personas_juridicas"))?.cuentas_personas_juridicas);
      clientes = clients.filter((cliente) =>
        cuentas.find(
          (cuenta) => {
            if (condicionIn === true) {
              return cuenta.id_cuentas === cliente.id_cuentas

            } else {
              return cuenta.id_cuentas !== cliente.id_cuentas
            }
          }
        )
      );

      const user_cuentas = JSON.parse(
        localStorage.getItem("siam-user-cuentas")
      );
      let userCuentasStorage = esArray(user_cuentas);

      if (userCuentasStorage.length < 2) {
        let usersCuentasModificado = [
          { usuarios_cuentas: userCuentasStorage[0] },
        ];
        userCuentasStorage = usersCuentasModificado;
      }
      if (user_cuentas.length > 0) {
        let clientesFilter = clientes.filter((cliente) =>
          userCuentasStorage.find(
            (obj) => obj.usuarios_cuentas.id_cuentas === cliente.id_cuentas
          )
        );

        if (clientesFilter && clienteSelected) {
          let objClienteSelected = clientesFilter.filter(cliente => cliente.id_cuentas === clienteSelected)
          setSelectedClientes(objClienteSelected[0]);
        }
        setClientesList(esArray(clientesFilter));

      } else {
        if (clienteSelected) {

          let objClienteSelected = clientes.filter(cliente => cliente.id_cuentas === clienteSelected)
          setSelectedClientes(objClienteSelected[0]);
        }
        setClientesList(esArray(clientes));

      }
    }
    getClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setSelectedClientes(newValue);
      setClienteFichaSelected(newValue.razon_social)
      props.handleChange(props.path, newValue.id_cuentas); // Obtén el ID del objeto seleccionado
    } else {
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
          value={selectedClientes ? selectedClientes : null}
          onChange={handleChange}
          disabled={editar? true : false}
          options={clientesList || []}
          getOptionLabel={(option) => option.id_cuentas + ' - ' + option.razon_social || ""}
          renderInput={(params) => (
            <TextField {...params}
              label="Cuenta"
              disabled={ editar || disabled? true : false}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
const SelectorClienteTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorClientes";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorClienteComponent = {
  renderer: withJsonFormsControlProps(SelectorClientes),
  tester: SelectorClienteTester,
};

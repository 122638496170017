import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getDataDashboard } from "../general_helpers/getDataDashboard";
import { CardsDashboard } from "../general_components/CardsDashboard";
import { MovimientosCajasDashboard } from "../general_components/MovimientosCajasDashboard";
import { MovimientoDocDashboard } from "../general_components/MovimientoDocDashboard";
import { TipoCajasCharts } from "../general_components/TipoCajasCharts";
import { ClienteCajasDashboard } from "../general_components/ClienteCajasDashboard";
import { CajasEstadoDashboard } from "../general_components/CajasEstadoDashboard";
import { esArray } from "src/general_components/functionEsArray"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import SkeletonLayout from './Skeleton';

export const DashboardDlys = () => {
    const [responseData, setResponseData] = useState(false);
    const [cajasEstadosData, setCajasEstadosData] = useState(false);
    const [movimientosCajaData, setMovimientosCajaData] = useState([]);
    const [movimientosDocumentosData, setMovimientosDocumentos] = useState([]);
    const [tiposCajasData, setTiposCajaData] = useState(false);
    const [clienteCaja, setClienteCaja] = useState([]);
    let proyecto = JSON.parse(localStorage.getItem("parametros_proyecto")).id.toString();

    const handleAccordionChange = (vistaConsulta) => (event, isExpanded) => {

        if (isExpanded) {
            fetchDashboardData(vistaConsulta);
        }
    };

    const fetchDashboardData = async (vistaConsulta) => {
        try {
            let response = await getDataDashboard(vistaConsulta, proyecto);
            if (response.movimientos_cajas) {
                setMovimientosCajaData(esArray(response.movimientos_cajas.items))

            }
            if (response.movimientos_contenidos) {
                setMovimientosDocumentos(esArray(response.movimientos_contenidos.items))
            }
        } catch (error) {
            console.error('Error al obtener los datos del panel', vistaConsulta, error);
        }
    };

    useEffect(() => {
        const getData = async () => {
            let vistaConsulta = false
            let response = await getDataDashboard(vistaConsulta, proyecto);
            if (response) {
                setCajasEstadosData(esArray(response?.totalizador_estados_caja?.items))
                setTiposCajaData(esArray(response?.totalizador_tipos_caja?.items))
                setClienteCaja(esArray(response?.totalizador_cajas_cliente?.items))
                setResponseData(true);
            }
        };

        getData();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gridItemStyle = {
        borderRadius: '10px', // Establece los bordes redondeados
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Agrega sombra
        padding: '10px', // Añade espaciado interno
    };

    return (
        <>
            <div >

                {responseData ? (<>
                    <Grid container  >
                        <Grid item display={'flex'} xs={12} sm={12} md={12} >
                            {cajasEstadosData && <CardsDashboard data={cajasEstadosData} />}
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                            {tiposCajasData && (
                                <TipoCajasCharts tipoCajas={tiposCajasData} />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} marginLeft={3} sm={6} marginTop={5} md={12} sx={{ ...gridItemStyle }}>
                                    <CajasEstadoDashboard data={cajasEstadosData} />
                                </Grid>
                                <Grid item xs={12} marginLeft={3} sm={6} marginTop={"1vh"} md={12} sx={{ ...gridItemStyle }}>
                                    <ClienteCajasDashboard clienteCaja={clienteCaja} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h4"
                                style={{
                                    fontFamily: 'Roboto, sans-serif', textAlign: 'left', 
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    marginTop: 30,
                                    padding: 0
                                }}
                            >
                                Movimientos
                            </Typography>
                        </Grid>
                        <Grid item display={'flex'} xs={12} sm={12} md={12} style={{ paddingBottom: 30 }}>
                            <Grid item xs={6}>
                                <Accordion key='1' height={'50%'} onChange={handleAccordionChange('view_movimientos_cajas', setResponseData)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`content`}
                                        id={`movimientosCajas-header`}
                                    >
                                        <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center',  fontWeight: "bold" }}>
                                            Movimientos Cajas
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <MovimientosCajasDashboard cajas={movimientosCajaData} />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={6}>
                                <Accordion key='1' height={'50%'} onChange={handleAccordionChange('view_movimientos_contenidos', setResponseData)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon  />}
                                        aria-controls={`content`}
                                        id={`movimientosCajas-header`}
                                    >
                                        <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}>
                                            Movimientos Documentos
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <MovimientoDocDashboard documentos={movimientosDocumentosData} />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </>) : (
                    <SkeletonLayout />)}
            </div>
        </>
    );


}

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export const SelectRetiroPrioridad = (props) => {
  // const { tipoRetiroSeleccionado, setTipoRetiroSeleccionado } =
  //   useContext(CajasContext);
  const {
    setTipoRetiroMovimientoSeleccionado,
    tipoRetiroMovimientoSeleccionado,
    disabledSelector,
  } = props;

  const estilosSelect = { fontSize: "1em", color: "#646464" };
  const ITEM_HEIGHT = 45;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  let color = disabledSelector ? "#E9E9E9" : "#FFFFFF";

  return (
    <div>
      <FormControl sx={{ mt: 1, minWidth: "100%" }} size="small">
        <InputLabel id="labelTipoPrioridad">Prioridad</InputLabel>
        <Select
          sx={{ backgroundColor: color }}
          style={{ ...estilosSelect, marginBottom: "20px" }}
          MenuProps={MenuProps}
          labelId="selectTipoRetiroPrioridad"
          value={tipoRetiroMovimientoSeleccionado}
          id="selectTipoRetiroPrioridad"
          label="Prioridad"
          disabled={disabledSelector}
          onChange={(e) => {
            setTipoRetiroMovimientoSeleccionado(e.target.value);
          }}
        >
          <MenuItem style={estilosSelect} value="1" selected>
            Simple
          </MenuItem>
          <MenuItem style={estilosSelect} value="2" >
            Preferencial
          </MenuItem>
          <MenuItem style={estilosSelect} value="3">
            Urgente
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

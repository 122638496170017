import datos from "src/services/datos";
import "src/services/styles/sweetAlert.css";
import Swal from "sweetalert2";
import { esArray } from "src/general_components/functionEsArray";
import { getMetadatos } from "./MetadatoData";

const ordenarPorLoteYId = (array) => {
  // Obtener todos los lotes únicos
  const lotesUnicos = Array.from(new Set(array.map(obj => obj.lote)));

  // Asignar un color alternativo a cada lote único
  const coloresPorLote = {};
  lotesUnicos.forEach((lote, index) => {
    coloresPorLote[lote] = index % 2 === 0 ? 'blanco' : 'Contenidos'; // Aquí puedes ajustar los colores según tus necesidades
  });

  // Ordenar por lote y luego por id
  const arrayOrdenado = array.slice().sort((a, b) => {
    if (a.lote === b.lote) {
      return a.id - b.id; // Si los lotes son iguales, se ordena por id
    } else {
      return a.lote - b.lote; // Si los lotes son diferentes, se ordena por lote
    }
  });

  // Agregar la propiedad color a cada objeto en el array ordenado
  arrayOrdenado.forEach(obj => {
    obj.color = coloresPorLote[obj.lote];
  });

  return arrayOrdenado;
};

export const getLoteMaximo = async (id_caja) => {
  const objectContenidos = new datos("contenidos");
  objectContenidos.addCondition("contenidos", "id_cajas", id_caja, "=");
  objectContenidos.join("tipo_contenidos", "id", "id_tipo_contenidos");
  objectContenidos.join("contenidos_metadatos", "id_contenidos", "id", "=");
  // objectContenidos.join("metadatos", "id_tipo_contenidos", "id_tipo_contenidos", "=");
  objectContenidos.innerJoinTables("metadatos", "contenidos_metadatos", "id","id_metadatos", "=")
  objectContenidos.join(
    "estados_contenidos",
    "id",
    "id_estados_contenidos",
    "="
  );
  objectContenidos.addOrderCondition("contenidos", "lote", "DESC")
  objectContenidos.addLimitCondition('1', '0');
  const response = await objectContenidos.get();

  let lote = 1;
  if(response.status === 'true'){
    lote = response?.wsResponse?.contenidos?.lote;
    lote = parseInt(lote) + 1;
  }
  

  return lote;
  
};

export const getContenidos = async () => {
  await getLoteMaximo();
  const objectContenidos = new datos("contenidos");
  objectContenidos.addCondition("contenidos", "id_cajas", "id_cajas", "=");
  objectContenidos.join("tipo_contenidos", "id", "id_tipo_contenidos");
  objectContenidos.join("contenidos_metadatos", "id_contenidos", "id", "=");
  objectContenidos.join("metadatos", "id_tipo_contenidos", "id", "=");
  const response = await objectContenidos.get();
  const cont = response.wsResponse.contenidos;
  if (Array.isArray(cont)) {
    var arr = cont;
  } else if (typeof cont === "undefined") {
    arr = [];
  } else {
    arr = [cont];
  }
  return arr;
};

export const postContenidos = async (contenidos) => {
  const { id, id_cajas, id_tipo_contenidos, meta_datos, lote } = contenidos;
  var metaDatosFiltrado = meta_datos.filter(
    (item) =>
      item.valor !== undefined && item.valor !== "" && item.valor !== null
  );

  const data = {
    id: id,
    id_cajas: id_cajas,
    id_tipo_contenidos: id_tipo_contenidos,
    meta_datos: metaDatosFiltrado,
    lote: lote || "1",
  };

  const contenidosInsert = new datos("contenidos");
  const response = await contenidosInsert.contenidosCreate(data);

  return response;
};

export const deleteContenido = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este Contenido?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
    customClass: {
      container: "my-swal-delete",
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos("contenidos");
      let data = {
        id: id,
      };
      const response = await userData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire({
          title: "Eliminado!",
          text: "El contenido ha sido eliminado exitosamente",
          icon: "success",
          customClass: {
            container: "my-swal-delete",
          },
        });
      } else {
        await Swal.fire({
          customClass: {
            container: "my-swal-delete",
          },
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};

export const getContenidosPaginated = async (limit, offset, conditions) => {
  
  const { id_caja, clienteName } = conditions;
  const objectContenidos = new datos("contenidos");
  objectContenidos.addCondition("contenidos", "id_cajas", id_caja, "=");
  objectContenidos.join("tipo_contenidos", "id", "id_tipo_contenidos");
  objectContenidos.join("contenidos_metadatos", "id_contenidos", "id", "=");
  objectContenidos.join("cajas", "id", "id_cajas");
  // objectContenidos.join("metadatos", "id_tipo_contenidos", "id_tipo_contenidos", "=");
  objectContenidos.innerJoinTables("metadatos", "contenidos_metadatos", "id","id_metadatos", "=")
  objectContenidos.join(
    "estados_contenidos",
    "id",
    "id_estados_contenidos",
    "="
  );
  objectContenidos.addLimitCondition(limit, offset);
  const response = await objectContenidos.get();

  if (response.wsResponse.contenidos) {
    const contenidosData = esArray(response.wsResponse.contenidos);
    let maxLoteValue = 0; // Variable para almacenar el valor máximo del lote
    for (let i = 0; i < contenidosData.length; i++) {
      let texto = "";
      let esArrayContenidosMetadatos = Array.isArray(
        contenidosData[i].relations.contenidos_metadatos
      );
      contenidosData[i].tipo_contenidos =contenidosData[i]?.relations?.tipo_contenidos?.detalle;
      contenidosData[i].cliente = clienteName;
      contenidosData[i].isRowSelectable = true;
      contenidosData[i].id_cuentas_localizaciones =contenidosData[i]?.relations?.cajas?.id_cuentas_localizaciones;
      const loteValue = contenidosData[i].lote; // Obtén el valor del lote de cada objeto
      // Verifica si el valor del lote actual es mayor que el valor máximo encontrado hasta ahora
      if (loteValue > maxLoteValue) {
        maxLoteValue = loteValue; // Actualiza el valor máximo del lote
      }
      let listMetadatos = esArray(contenidosData[i]?.relations?.metadatos);
      if (esArrayContenidosMetadatos) {
        contenidosData[i].contenidos_metadatos =
          contenidosData[i]?.relations?.contenidos_metadatos &&
          contenidosData[i]?.relations?.contenidos_metadatos.map(
            (metadatos) => {
              let metadatoObtenido = listMetadatos.find((metadato) => metadato.id === metadatos.id_metadatos);
              let metadatoLabel = metadatoObtenido.label_form;
              texto = texto + " " + metadatoLabel + " : " + metadatos?.valor;
              return {
                id: metadatos.id,
                id_metadatos: metadatos.id_metadatos,
                valor: metadatos?.valor,
                label_grilla: metadatoObtenido.label_grilla,
                metadato_label_form: metadatoObtenido.label_form,
                metadato_valor: typeof metadatos?.valor === 'object' ? null : metadatos?.valor,
              };
            }
          );
      } else {
        let arrayMetadato = esArray(contenidosData[i].relations.contenidos_metadatos);

        contenidosData[i].contenidos_metadatos =
          contenidosData[i]?.relations?.contenidos_metadatos &&
          arrayMetadato.map((metadatos) => {
            let metadatoObtenido = listMetadatos.find((metadato) => metadato.id === metadatos.id_metadatos);
            let metadatoLabel = metadatoObtenido.label_form;
            texto = texto + " " + metadatoLabel + " : " + metadatos?.valor;
            return {
              id: metadatos.id,
              id_metadatos: metadatos.id_metadatos,
              valor: metadatos?.valor,
              label_grilla: metadatoObtenido.label_grilla,
              metadato_label_form: metadatoObtenido.label_form,
              metadato_valor: typeof metadatos?.valor === 'object' ? null : metadatos?.valor,
            };
          });
      }
      contenidosData[i].contenidos_metadatos_ordenados = texto;
      contenidosData[i].estado =contenidosData[i].relations?.estados_contenidos?.detalle;
    }

    contenidosData.forEach((objeto) => {
      objeto.lote_mayor = maxLoteValue; // Añade la propiedad lote_mayor con el valor máximo
    });

    const contenidosDataOrdenadoPorLote = ordenarPorLoteYId(contenidosData);
    let result = {
      data: contenidosDataOrdenadoPorLote,
      total_records: response.wsResponse.total_records,
    };
    return result;
  } else {
    let result = {
      data: [],
      total_records: 0,
    };
    return result;
  }
};

export const getContenidosNotPaginated = async (conditions) => {
  const { id_caja, clienteName } = conditions;
  const objectContenidos = new datos("contenidos");
  objectContenidos.addCondition("contenidos", "id_cajas", id_caja, "=");
  objectContenidos.join("tipo_contenidos", "id", "id_tipo_contenidos");
  objectContenidos.join("contenidos_metadatos", "id_contenidos", "id", "=");
  objectContenidos.join("metadatos", "id_tipo_contenidos", "id", "=");
  objectContenidos.join(
    "estados_contenidos",
    "id",
    "id_estados_contenidos",
    "="
  );
  const response = await objectContenidos.get();

  if (response.wsResponse.contenidos) {
    const contenidosData = esArray(response.wsResponse.contenidos);
    let listMetadatos = JSON.parse(localStorage.getItem("siam-metadatos"));
    if (!listMetadatos) {
      listMetadatos = await getMetadatos();
    }
    for (let i = 0; i < contenidosData.length; i++) {
      let texto = "";
      let esArray = Array.isArray(
        contenidosData[i].relations.contenidos_metadatos
      );
      contenidosData[i].tipo_contenidos =
        contenidosData[i]?.relations?.tipo_contenidos?.detalle;
      contenidosData[i].cliente = clienteName;
      contenidosData[i].isRowSelectable = true;
      if (esArray) {
        contenidosData[i].contenidos_metadatos =
          contenidosData[i]?.relations?.contenidos_metadatos &&
          contenidosData[i]?.relations?.contenidos_metadatos.map(
            (metadatos) => {
              let metadatoObtenido = listMetadatos.metadatos.find(
                (metadato) => metadato.id === metadatos.id_metadatos
              );
              let metadatoLabel = metadatoObtenido.label_grilla;
              texto = texto + " " + metadatoLabel + " : " + metadatos?.valor;
              return {
                id: metadatos.id,
                id_metadatos: metadatos.id_metadatos,
                valor: metadatos?.valor,
              };
            }
          );
      } else {
        let arrayMetadato = [contenidosData[i].relations.contenidos_metadatos];
        contenidosData[i].contenidos_metadatos =
          contenidosData[i]?.relations?.contenidos_metadatos &&
          arrayMetadato.map((metadatos) => {
            let metadatoObtenido = listMetadatos.metadatos.find(
              (metadato) => metadato.id === metadatos.id_metadatos
            );
            let metadatoLabel = metadatoObtenido.label_grilla;
            texto = texto + " " + metadatoLabel + " : " + metadatos?.valor;
            return {
              id: metadatos.id,
              id_metadatos: metadatos.id_metadatos,
              valor: metadatos?.valor,
            };
          });
      }
      contenidosData[i].contenidos_metadatos_ordenados = texto;
      contenidosData[i].estado =
        contenidosData[i].relations?.estados_contenidos?.detalle;
    }

    return contenidosData;
  } else {
    let result = {
      data: [],
      total_records: 0,
    };
    return result.data;
  }
};

export const getContenidosRetiro = async (data) => {
  const contenidosRetiro = new datos("contenidos");
  const response = await contenidosRetiro.searchContenidos(data);

  return response;
};

export const movementContenidos = async (data) => {
  const contenidosMovement = new datos("contenidos");
  const response = await contenidosMovement.movementContenidos(data);

  return response;
};

function convertirSectoresAArray(objeto) {
  const sectores = objeto?.cuenta?.sectores?.sector;
  if (!sectores) return objeto;

  // Si solo hay un sector, lo convertimos en un array
  if (!Array.isArray(sectores)) {
      objeto.cuenta.sectores.sector = esArray(sectores);
  }
  
  return objeto;
}

export const updateContenidos = async (contenidos) => {
  const { id, id_cajas, id_tipo_contenidos, meta_datos, lote } = contenidos;
  let metaDatosCompletos = [...meta_datos];

  var metaDatosFiltrado = meta_datos.filter(
    (item) =>
      (item.valor === "undefined" ||
        item.valor === undefined ||
        item.valor === null ||
        item.valor === "") &&
      item.id !== ""
  );

  metaDatosCompletos = metaDatosCompletos.filter(
    (item) =>
      item.valor !== undefined &&
      item.valor !== null &&
      item.valor !== "" &&
      item.id_metadatos !== ""
  );
  metaDatosFiltrado.forEach(function (metadato, index) {
    metaDatosCompletos.push({ id: metadato.id, delete: "1" });
  });

  let data = [];
  data.id = id;
  data.id_tipo_contenidos = id_tipo_contenidos;
  data.id_cajas = id_cajas;
  data.lote = lote || "1";
  data.meta_datos = metaDatosCompletos;

  const contenidosUpd = new datos("contenidos");
  const response = await contenidosUpd.contenidosUpdate(data);

  return response;
};

export const getContenidosSearch = async (data) => {
  const contenidos = new datos("contenidos");
  const response = await contenidos.searchContenidosPrincipal(data);

  return response;
};

const eliminarValoresEntreParentesis = (cadena) => cadena.includes('(') ? cadena.replace(/\([^)]*\)/g, '').trim() : cadena;


export const getContenidosSearchPaginated = async (limit, offset, data, ordenamiento) => {
  let proceso_seleccionado = JSON.parse(localStorage.getItem("proceso_seleccionado"));
  if (Array.isArray(data)) {
    // eslint-disable-next-line no-unused-vars
    let data = {
      coincidencias: "1",
      eliminados: "0",
      search_params: {},
      limit: limit,
      offset: offset,
    };
  }
  const contenidos = new datos("contenidos");
  if (data.jerarquia) {
    try {
      const response = await contenidos.searchContenidosPrincipal(
        data,
        limit,
        offset,
        ordenamiento
      );

      if (response.wsResponse.cuentas) {
        var cuentas = esArray(response.wsResponse.cuentas.cuenta);
        if (response.wsResponse.id_contenidos) {
          if (Object.keys(response.wsResponse.id_contenidos?.id).length === 0) {
            var contenidosId = [];
          } else {
            contenidosId = esArray(response.wsResponse.id_contenidos.id);
          }
        }

        localStorage.setItem('id_contenidos', contenidosId)
        if (cuentas) {
          var listCajasCont = [];
          // Bucle de cuentas
          for (let i = 0; i < cuentas.length; i++) {
            let id_cuenta = "cliente" + cuentas[i].id;
            let razon_social = cuentas[i].id + " - " + cuentas[i].razon_social;
            let sectores = cuentas[i].sectores
              ? esArray(cuentas[i].sectores.sector)
              : [];
            listCajasCont.push({
              id: id_cuenta,
              hierarchy: [id_cuenta],
              clienteName: razon_social,
            });
            // Bucle de sectores
            for (let i2 = 0; i2 < sectores.length; i2++) {
              let id_sector = "sector" + sectores[i2].id;
              let descripcion_sector = sectores[i2].id + " - " + sectores[i2].descripcion;
              let cajas = sectores[i2].cajas
                ? esArray(sectores[i2].cajas.caja)
                : [];
              listCajasCont.push({
                id: id_sector,
                hierarchy: [id_cuenta, id_sector],
                sectorName: descripcion_sector,
                cliente: eliminarValoresEntreParentesis(razon_social),
                sector: eliminarValoresEntreParentesis(descripcion_sector),
                id_sector: sectores[i2].id,
                proceso_seleccionado: proceso_seleccionado
              });

              //Bucle Cajas
              for (let i3 = 0; i3 < cajas.length; i3++) {
                const idCajaConcat = "ID: " + cajas[i3].id;
                const referenciaCajaConcat = typeof cajas[i3].referencia === 'object' ? '' : " | REF: " + cajas[i3].referencia;
                let cajaConcat = idCajaConcat + referenciaCajaConcat;
                let contenidosCaja = esArray(cajas[i3].contenidos);
                listCajasCont.push({
                  id_cuentas_localizaciones: sectores[i2].id,
                  modelo_padre: "caja",
                  id: cajas[i3].id,
                  id_cuentas: cajas[i3].id_cuentas,
                  concatCaja: cajaConcat,
                  referencia: typeof (cajas[i3].referencia) === 'object' ? '' : cajas[i3].referencia,
                  deposito: cajas[i3].deposito,
                  ubicacion: cajas[i3].ubicacion,
                  tipo_cajas: cajas[i3].tipo_cajas,
                  fecha_creacion: cajas[i3].fecha_creacion,
                  hierarchy: [id_cuenta, id_sector, cajas[i3].id],
                  estados_cajas: cajas[i3].estado,
                  isRowSelectable: true,
                  estado: cajas[i3].estado,
                  caja: cajas[i3].id,
                  contenidos: contenidosCaja,
                  proceso_seleccionado: proceso_seleccionado,
                  excedida: cajas[i3].excedida,
                  deformada: cajas[i3].deformada,
                  id_caja_edit: cajas[i3].id,
                  detalle: cajas[i3].detalle,
                  id_tipo_caja: cajas[i3].id_tipo_caja,
                  cliente: eliminarValoresEntreParentesis(razon_social),
                  sector: eliminarValoresEntreParentesis(descripcion_sector),
                });
              }
            }
          }

          const reporte = convertirSectoresAArray( response.wsResponse.cuentas);

          let result = {
            data: listCajasCont,
            total_records: response.wsResponse.total_records,
            reporte: reporte,
            status: response.status,
            id_cajas: response.wsResponse.id_cajas
          };
          if (response.wsResponse.custom_counters) {
            result["custom_counters"] = response.wsResponse.custom_counters;
            result["id_contenidos"] = contenidosId;
          }
          return result;
        }
      } else {
        let result = {
          data: [],
          total_records: 0,
          reporte: {},
          status: 'false',
          id_cajas: ''
        };

        return result;

      }
    } catch (error) {
      let result = {
        data: [],
        total_records: 0,
        reporte: {}
      };
      return result;
    }
    // ---------------------------------------------------------------------------------------------------------------
  } else {

    try {
      const response = await contenidos.searchContenidosPrincipal(
        data,
        limit,
        offset,
        ordenamiento
      );

      if (response.wsResponse.cuentas) {
        let cuentas = esArray(response.wsResponse.cuentas.cuenta);
        if (response.wsResponse.id_contenidos) {
          let contenidosId;
          if (Object.keys(response.wsResponse.id_contenidos?.id).length === 0) {
            contenidosId = [];
          } else {
            // eslint-disable-next-line no-unused-vars
            contenidosId = esArray(response.wsResponse.id_contenidos.id);
          }
        }
        if (cuentas) {
          let listCajasCont = [];
          // Bucle de cuentas
          for (let i = 0; i < cuentas.length; i++) {
            
            let razon_social = cuentas[i].id + " - " + cuentas[i].razon_social;
            let sectores = cuentas[i].sectores
              ? esArray(cuentas[i].sectores.sector)
              : [];
            // Bucle de sectores
            for (let i2 = 0; i2 < sectores.length; i2++) {

              let descripcion_sector = sectores[i2].id + " - " + sectores[i2].descripcion;
              let cajas = sectores[i2].cajas
                ? esArray(sectores[i2].cajas.caja)
                : [];

              //Bucle Cajas
              for (let i3 = 0; i3 < cajas.length; i3++) {
                const idCajaConcat = "ID: " + cajas[i3].id;
                const referenciaCajaConcat = typeof cajas[i3].referencia === 'object' ? '' : " | REF: " + cajas[i3].referencia;
                let cajaConcat = idCajaConcat + referenciaCajaConcat;
                let contenidosCaja = esArray(cajas[i3].contenidos);
                listCajasCont.push({
                  id_cuentas_localizaciones: sectores[i2].id,
                  modelo_padre: "caja",
                  id: cajas[i3].id,
                  id_cuentas: cajas[i3].id_cuentas,
                  concatCaja: cajaConcat,
                  referencia: typeof (cajas[i3].referencia) === 'object' ? '' : cajas[i3].referencia,
                  deposito: cajas[i3].deposito,
                  ubicacion: cajas[i3].ubicacion,
                  tipo_cajas: cajas[i3].tipo_cajas,
                  fecha_creacion: cajas[i3].fecha_creacion,
                  hierarchy: [cajas[i3].id],
                  estados_cajas: cajas[i3].estado,
                  isRowSelectable: true,
                  estado: cajas[i3].estado,
                  caja: cajas[i3].id,
                  contenidos: contenidosCaja,
                  proceso_seleccionado: proceso_seleccionado,
                  excedida: cajas[i3].excedida,
                  deformada: cajas[i3].deformada,
                  id_caja_edit: cajas[i3].id,
                  detalle: cajas[i3].detalle,
                  id_tipo_caja: cajas[i3].id_tipo_caja,
                  cliente: eliminarValoresEntreParentesis(razon_social),
                  sector: eliminarValoresEntreParentesis(descripcion_sector),
                  clienteName: razon_social,
                  sectorName: descripcion_sector,
                  

                });
              }
            }
          }

          const reporte = convertirSectoresAArray( response.wsResponse.cuentas);

          let result = {
            data: listCajasCont,
            total_records: response.wsResponse.total_records,
            reporte: reporte,
            status: response.status,
            id_cajas: response.wsResponse.id_cajas
          };
          if (response.wsResponse.custom_counters) {
            result["custom_counters"] = response.wsResponse.custom_counters;
            result["id_contenidos"] = contenidosId;
          }
          return result;
        }
      } else {
        let result = {
          data: [],
          total_records: 0,
          reporte: {},
          status: 'false',
          id_cajas: ''
        };

        return result;

      }
    } catch (error) {
      let result = {
        data: [],
        total_records: 0,
        reporte: {}
      };
      return result;
    }
    
  }
};

export const getProcesosGeneralesContenidosSearchPaginated = async (limit, offset, data, ordenamiento) => {
  if (Array.isArray(data)) {
    // eslint-disable-next-line no-unused-vars
    let data = {
      coincidencias: "1",
      eliminados: "0",
      search_params: {},
      limit: limit,
      offset: offset,
    };
  }
  const contenidos = new datos("contenidos");
  try {
    const devuelveContenidos = true;
    const response = await contenidos.searchContenidosPrincipal(
      data,
      limit,
      offset,
      ordenamiento,
      devuelveContenidos,
    );

    if (response.wsResponse.cuentas) {
      var cuentas = esArray(response.wsResponse.cuentas.cuenta);
      if (response.wsResponse.id_contenidos) {
        if (Object.keys(response.wsResponse.id_contenidos?.id).length === 0) {
          var contenidosId = [];
        } else {
          contenidosId = esArray(response.wsResponse.id_contenidos.id);
        }
      }

      localStorage.setItem('id_contenidos', contenidosId)
      if (cuentas) {
        var listCajasCont = [];
        // Bucle de cuentas
        for (let i = 0; i < cuentas.length; i++) {
          let sectores = cuentas[i].sectores
            ? esArray(cuentas[i].sectores.sector)
            : [];
          // Bucle de sectores
          for (let i2 = 0; i2 < sectores.length; i2++) {
            let cajas = sectores[i2].cajas
              ? esArray(sectores[i2].cajas.caja)
              : [];
            //Bucle Cajas
            for (let i3 = 0; i3 < cajas.length; i3++) {
              const idCajaConcat = "ID: " + cajas[i3].id;
              const referenciaCajaConcat = typeof cajas[i3].referencia === 'object' ? '' : " | REF: " + cajas[i3].referencia;
              let cajaConcat = idCajaConcat + referenciaCajaConcat;
              let contenidosCaja = esArray(cajas[i3].contenidos);
              for (let i4 = 0; i4 < contenidosCaja.length; i4++) {
                let contenidosMetadatos = contenidosCaja[i4]?.contenidos_metadatos ? esArray(contenidosCaja[i4].contenidos_metadatos) : [];
                const objetoResultado = {};
                for (let i5 = 0; i5 < contenidosMetadatos.length; i5++) {
                  contenidosMetadatos.forEach(dato => {
                    const propiedad = dato.metadato_label_grilla;
                    const valor = `${dato.metadato_label_form} | ${dato.metadato_valor}`;
                    objetoResultado[propiedad] = valor
                  });
                }
                listCajasCont.push({
                  // modelo_padre: "caja",
                  ...objetoResultado,
                  id_caja: cajas[i3].id,
                  id_cuentas: cajas[i3].id_cuentas,
                  id_sector: sectores[i2].id,
                  concatCaja: cajaConcat,
                  referencia_caja: typeof (cajas[i3].referencia) === 'object' ? '' : cajas[i3].referencia,
                  deposito_caja: cajas[i3].deposito,
                  ubicacion_caja: cajas[i3].ubicacion,
                  tipo_cajas: cajas[i3].tipo_cajas,
                  fecha_creacion_caja: cajas[i3].fecha_creacion,
                  estados_cajas: cajas[i3].estado,
                  isRowSelectable: true,
                  estado_caja: cajas[i3].estado,
                  estado_contenido: contenidosCaja[i4].estado,
                  id: contenidosCaja[i4].id
                });



              }
            }
          }
        }
        let result = {
          data: listCajasCont,
          total_records: listCajasCont.length === 0 ? 0 : response.wsResponse.total_records,
          reporte: response.wsResponse.cuentas,
        };
        if (response.wsResponse.custom_counters) {
          result["custom_counters"] = response.wsResponse.custom_counters;
          result["id_contenidos"] = contenidosId;
        }
        return result;
      }
    } else {
      let result = {
        data: [],
        total_records: 0,
        reporte: {}
      };

      return result;

    }
  } catch (error) {
    let result = {
      data: [],
      total_records: 0,
      reporte: {}
    };
    return result;
    // Aquí puedes manejar el error de manera adecuada, por ejemplo, mostrando un mensaje al usuario
  }
};

export const replaceObjectWithNull = function (array) {
  return array.map((item) => {
    if (typeof item.metadato_valor === 'object') {
      return {
        ...item,
        metadato_valor: "",
      };
    }
    return item;
  });
};


export const getSearchContenidos = async (data) => {
  if (Array.isArray(data)) {
    // eslint-disable-next-line no-unused-vars
    let data = {
      coincidencias: "1",
      eliminados: "0",
      search_params: {},
    };
  }
  const contenidos = new datos("contenidos");
  const response = await contenidos.searchContenidosPrincipal(data);
  if (response.status === "true") {
    let listContenidos = esArray(response.wsResponse.contenidos?.contenido);
    for (let i = 0; i < listContenidos.length; i++) {
      let arrMetaDatos = esArray(listContenidos[i].contenidos_metadatos);
      listContenidos[i].isRowSelectable = true;

      listContenidos[i].contenidos_metadatos_ordenados = concatenarContenidos(arrMetaDatos)
      listContenidos[i].arrayMetaDatos = replaceObjectWithNull(arrMetaDatos);

    }
    let result = {
      data: listContenidos,
      total_records: listContenidos.length,
    };
    return result;
  } else {
    let result = {
      data: [],
      total_records: [].length,
    };
    return result;
  }

}




export const getInformeMovimientosSearchPaginated = async (
  limit,
  offset,
  data
) => {
  if (Array.isArray(data)) {
    // eslint-disable-next-line no-unused-vars
    let data = { coincidencias: "1", eliminados: "0", search_params: {} };
  }
  const contenidos = new datos("contenidos");


  try {
    const response = await contenidos.searchContenidosPrincipal(data, limit, offset);

    if (response.wsResponse.cajas?.clientes) {
      var cont = esArray(response.wsResponse.cajas.clientes);
      cont = Object.values(cont);
      var listClientesCont = [];
      if (cont) {
        for (let i = 0; i < cont.length; i++) {
          listClientesCont.push({
            id: "cliente" + cont[i].id_cuentas,
            cliente: cont[i].id_cuentas + " - " + cont[i].nombre,
            hierarchy: ["cliente" + cont[i].id_cuentas],
          });

          var sec = esArray(cont[i].sectores);
          for (let i2 = 0; i2 < sec.length; i2++) {
            listClientesCont.push({
              id: "sector" + sec[i2].id_cuentas_localizaciones,
              sector:
                sec[i2].id_cuentas_localizaciones + " - " + sec[i2].descripcion,
              hierarchy: [
                "cliente" + cont[i].id_cuentas,
                "sector" + sec[i2].id_cuentas_localizaciones,
              ],
            });
            var caja = esArray(sec[i2].cajas);
            for (let i3 = 0; i3 < caja.length; i3++) {
              listClientesCont.push({
                id: "caja" + caja[i3].id,
                cajas: caja[i3].id,
                ubicacion: caja[i3].ubicacion,
                tipo_cajas: caja[i3].tipo_cajas,
                historico: caja[i3].historico,
                hierarchy: [
                  "cliente" + cont[i].id_cuentas,
                  "sector" + sec[i2].id_cuentas_localizaciones,
                  "caja" + caja[i3].id,
                ],
              });
              var contenido = esArray(caja[i3].contenidos);
              for (let i4 = 0; i4 < contenido.length; i4++) {
                var metadatos = contenido[i4].contenidos_metadatos;
                let meta_valor = concatenarContenidos(metadatos);

                listClientesCont.push({
                  id: "contendios" + contenido[i4].id,
                  contenidos: meta_valor,
                  HistoricoContenidos: contenido[i4].historico,
                  hierarchy: [
                    "cliente" + cont[i].id_cuentas,
                    "sector" + sec[i2].id_cuentas_localizaciones,
                    "caja" + caja[i3].id,
                    "contendios" + contenido[i4].id,
                  ],
                });
              }
            }
          }
        }
        let result = {
          data: listClientesCont,
          total_records: response.wsResponse.total_records,
        };
        return result;
      }
    }
    // Hacer algo con la respuesta, por ejemplo, actualizar el estado del componente
  } catch (error) {
    let result = {
      data: [],
      total_records: 0,
    };
    return result;
    // Aquí puedes manejar el error de manera adecuada, por ejemplo, mostrando un mensaje al usuario
  }


};

export const getInformeSearchPaginated = async (limit, offset, data) => {
  if (Array.isArray(data)) {
    // eslint-disable-next-line no-unused-vars
    let data = { coincidencias: "1", eliminados: "0", search_params: {} };
  }
  const contenidos = new datos("contenidos");

  const response = await contenidos.searchContenidosPrincipal(data);

  var cantContenidos = 0;
  if (response.wsResponse.cajas) {
    var cont = esArray(response.wsResponse.cajas);
    var listCajasCont = [];
    if (cont) {
      for (let i = 0; i < cont.length; i++) {
        for (let i2 = 0; i2 < cont[i].caja.length; i2++) {
          //NOTE PUSHEO LOS DATOS DE LA CAJA
          listCajasCont.push({
            id: cont[i].caja[i2].id,
            fecha_creacion: cont[i].caja[i2].fecha_creacion,
            cliente: cont[i].caja[i2].cliente,
            ubicacion: cont[i].caja[i2].ubicacion,
            deposito: cont[i].caja[i2].deposito,
            contenidos: [],
          });

          //NOTE ENTRA ACA CUANDO VIENEN CONTENIDOS EN UN ARRAY DATOS DE CONTENIDOS
          if (cont[i].caja[i2].contenidos.length > 0) {
            for (let i3 = 0; i3 < cont[i].caja[i2].contenidos.length; i3++) {
              var datosCont =
                cont[i].caja[i2].contenidos[i3].contenidos_metadatos;
              const contenidosConcatenados = concatenarContenidos(datosCont);
              cont[i].caja[i2].contenidosConcat = contenidosConcatenados;
              listCajasCont[i2].contenidos.push({
                id: cont[i].caja[i2].contenidos[i3].id,
                id_caja: cont[i].caja[i2].id,
                contenidoConcat:
                  cont[i].caja[i2].contenidosConcat +
                  "| ESTADO: " +
                  cont[i].caja[i2].contenidos[i3].estado,
              });
              cantContenidos = cantContenidos + 1;
            }
          } else {
            //NOTE ENTRA ACA CUANDO VIENEN CONTENIDOS EN UN OBJETO DATOS DE CONTENIDOS
            const contenidosConcatenados = concatenarContenidos(
              cont[i].caja[i2].contenidos.contenidos_metadatos
            );

            listCajasCont[i2].contenidos.push({
              id: 0,
              id_caja: cont[i].caja[i2].id,
              contenidoConcat:
                contenidosConcatenados +
                "| ESTADO: " +
                cont[i].caja[i2].contenidos.estado,
            });
          }
        }
      }
      let result = {
        data: listCajasCont,
        total_records: listCajasCont.length,
      };
      return result;
    }
  } else {
    return { data: [], total_records: 0 };
  }
};

/// Funcion que concatena el valor de los contenidos de cada caja
const concatenarContenidos = (datos) => {
  var arrMetaDatos = [];
  if (Array.isArray(datos)) {
    arrMetaDatos = datos;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (!datos) {
    // se pasa un array vacio
    arrMetaDatos = [];
  } else {
    // si trae un solo elemento , es un objeto
    arrMetaDatos = [datos]; // se lo convierte a array
  }

  var contenidosConcatenados = "";
  for (let i4 = 0; i4 < arrMetaDatos.length; i4++) {
    if (contenidosConcatenados === "") {
      contenidosConcatenados =
        contenidosConcatenados +
        arrMetaDatos[i4].metadato_label_form +
        ": " +
        arrMetaDatos[i4].metadato_valor;
    } else {
      contenidosConcatenados =
        contenidosConcatenados +
        " | " +
        arrMetaDatos[i4].metadato_label_form +
        ": " +
        arrMetaDatos[i4].metadato_valor;
    }
  }
  return contenidosConcatenados;
};

export const getCajasSearchPaginated = async (limit, offset, data) => {
  if (Array.isArray(data)) {
    // eslint-disable-next-line no-unused-vars
    let data = { coincidencias: "1", eliminados: "0", search_params: {} };
  }
  const contenidos = new datos("contenidos");
  const response = await contenidos.searchContenidosPrincipal(data);

  // eslint-disable-next-line no-unused-vars
  var cantContenidos = 0;
  if (response.wsResponse.cajas) {
    var cont = esArray(response.wsResponse.cajas);
    var listCajasCont = [];
    if (cont) {
      for (let i = 0; i < cont.length; i++) {
        // Si es un array se mantiene como estaba
        if (Array.isArray(cont[i].caja)) {
          // eslint-disable-next-line no-self-assign
          cont[i].caja = cont[i].caja;
          // Si no es un array... primero se verifica que tenga valores ... si no los tiene
        } else if (!cont[i].caja) {
          // se pasa un array vacio
          cont[i].caja = [];
        } else {
          // si trae un solo elemento , es un objeto
          cont[i].caja = [cont[i].caja]; // se lo convierte a array
        }
        for (let i2 = 0; i2 < cont[i].caja.length; i2++) {
          let ref = cont[i].caja[i2].referencia;
          cont[i].cajaConcat =
            "ID: " +
            cont[i].caja[i2].id +
            " | " +
            "DETALLE: " +
            cont[i].caja[i2].detalle;
          cont[i].caja[i2].contenidos2 = cont[i].caja[i2].contenidos;
          if (typeof ref !== "object" && ref !== "") {
            cont[i].cajaConcat =
              cont[i].cajaConcat +
              " | " +
              "REFERENCIA: " +
              cont[i].caja[i2].referencia;
          }
          listCajasCont.push({
            id: cont[i].caja[i2].id,
            referencia: typeof (cont[i].caja[i2].referencia) === 'object' ? '' : cont[i].caja[i2].referencia,
            detalle: cont[i].caja[i2].detalle,
            fecha_creacion: cont[i].caja[i2].fecha_creacion,
            cliente: cont[i].caja[i2].cliente,
          });
        }
      }
      let result = {
        data: listCajasCont,
        total_records: listCajasCont.length,
      };
      return result;
    }
  }
};


export const getDigitalizacionesContenidos = async (idContenido) => {
  const contenidos = new datos("digitalizaciones");
  contenidos.addCondition(
    "digitalizaciones",
    "id_contenidos",
    idContenido,
    "="
  );
  contenidos.join("digitalizaciones_metadatos", "id_digitalizaciones", "id", "=");
  contenidos.innerJoinTables('metadatos', 'digitalizaciones_metadatos', "id", "id_metadatos", "=")
  const response = await contenidos.get();
  if (response.status === "true") {
    return esArray(response.wsResponse.digitalizaciones);
  } else {
    return response;
  }
};

export const getImages = async (data) => {
  const contenidos = new datos("contenidos");
  const response = await contenidos.getImages(data);
  if (response.status === "true") {
    return esArray(response.wsResponse.imagenes);
  } else {
    return [];
  }
};

import { useState } from "react";
import {
  deleteTipoTelefonos,
  getTipoTelefonos,
  getTipoTelefonosPaginated,
  postTipoTelefonos
} from "../general_helpers/TipoTelefonosData";
import { BasicFormIdDetalle } from "../general_components/BasicFormIdDetalle";
import { ListTablePaginated } from "../general_components/ListTablePaginated";

export const TipoTelefonosList = () => {
  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "nombre", headerName: "Nombre", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
  ];
  const listName = "Tipo de Telefono";

  const [tipoTelefonos, setTipoTelefonos] = useState([]);
  const [createButton, setCreateButton] = useState(false);

  const options = {
    cols: columns,
    get: getTipoTelefonos,
    getPaginated: getTipoTelefonosPaginated,
    pageSize: [5, 10, 25],
    edit: true,
    listName,
    delete: deleteTipoTelefonos,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setTipoTelefonos,
  };

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  if (createButton === false && tipoTelefonos.id !== undefined) {
    setTipoTelefonos([]);
  }

  return (
    <>
      <BasicFormIdDetalle
        data={tipoTelefonos}
        createButton={createButton}
        listName={listName}
        setCreateButton={setCreateButton}
        componentList={"/generales/tipo_telefonos/admin"}
        postData={postTipoTelefonos}
      />
      <ListTablePaginated options={options} />
    </>
  );
};

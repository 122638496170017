export const uiSchemaFiltroServiciosFullForm = {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/selectedCuentaJuridica",
        },
        {
          type: "Control",
          scope: "#/properties/selectedServicio",
        },
        {
          type: "Control",
          scope: "#/properties/activo",
          options: {
            toggle: true,
            align: "start",
          },
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/cantidad",
            },
            {
              type: "Control",
              scope: "#/properties/importe",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/activoFechaInicio",
              options: {
                toggle: true,
                align: "center",
              },
            },
            {
              type: "Control",
              scope: "#/properties/fechaActivoInicio",
              rule: {
                effect: "ENABLE",
                condition: {
                    scope: "#/properties/activoFechaInicio",
                    schema: { "const": true }
                },
              },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/activoFechaFin",
              options: {
                toggle: true,
                align: "start",
              },
            },
            {
              type: "Control",
              scope: "#/properties/fechaActivoFin",
              rule: {
                  effect: "HIDE",
                  condition: {
                    scope: "#/properties/activoFechaFin",
                    //schema: { const : true  }
                  }
                }
            },
          ],
        },
        {
          type: "Control",
          scope: "#/properties/descripcion",
        },
      ],
    };
import {
    materialCells,
    materialRenderers,
  } from "@jsonforms/material-renderers";
  import { JsonForms } from "@jsonforms/react";
  import Grid from "@mui/material/Grid";
  import { useState } from "react";
  import { Buttons } from "src/general_components/Buttons";
  import { useCuentasContext } from "src/context/CuentasContext";
  import { schemaCuentasSearchForm } from "./forms/schemaCuentasSearchForm";
  import { uiSchemaCuentasSearchForm } from "./forms/uiSchemaCuentasSearchForm";
  import { SelectorLocalidadComponent } from "src/forms/customRenders/selectorLocalidad";
  
  export const FormConsultaCuentas = (props = null) => {
    const {
      setCriteriosSearchCuentasJuridicas,
      // setSnackbarVisible,
      // setSeverity,
      // setMessage,
    } = useCuentasContext();
  
    /// Contiene los datos del Jsonform
    const [dataForm, setDataForm] = useState({});
  
    const handleSubmit = async () => {
      console.log(dataForm,'?????')

      //TODO: TOMAR EN CUENTA PARA POSIBLE VALIDACION DE CAMPOS
      // //Creamos variables de las fechas, solo se usan para las condiciones
      // let fecha_desde = dataForm.fecha_desde;
      // let fecha_hasta = dataForm.fecha_hasta;
      // if (
      //   fecha_hasta &&
      //   fecha_desde &&
      //   (fecha_desde > fecha_hasta || fecha_desde === fecha_hasta)
      // ) {
      //   setSnackbarVisible(true);
      //   setSeverity("warning");
      //   setMessage("Fecha incorrecta");
      //   return;
      // }

      //Eliminamos las fechas del dataForm porque no la vamos a usar más
      // delete dataForm.fecha_desde;
      // delete dataForm.fecha_hasta;
      // if (!fecha_hasta && !fecha_desde && Object.entries(dataForm).length === 0) {
      //   setSnackbarVisible(true);
      //   setSeverity("warning");
      //   setMessage("Debe completar al menos un campo");
      //   return;
      // }
      // //Convertimos el objeto dataForm en un array
      // var array = Object.entries(dataForm);
      // //Creamos el JSON de la pegada al servicio 
      // let criteriaComprobantes = array.map(([key, value]) => {
      //   let objeto = {};
      //   let propiedaModel = "";
      //   let propiedadColum = "";
      //   let propiedadFilter = "";
      //   let propiedadValue = value;
      //   switch (key) {
      //     case "cliente":
      //       propiedaModel = "comprobantes_cuentas";
      //       propiedadColum = "id_cuentas";
      //       propiedadFilter = "equal";
      //       propiedadValue = value;
      //       break;
      //     case "comprobante_codigo":
      //       propiedaModel = "comprobantes_codigos";
      //       propiedadColum = "id";
      //       propiedadFilter = "equal";
      //       propiedadValue = value;
      //       break;
      //     case "tipo":
      //       propiedaModel = "comprobantes_tipo";
      //       propiedadColum = "id";
      //       propiedadFilter = "equal";
      //       propiedadValue = value;
      //       break;
      //     case "codigo_cajas":
      //       propiedaModel = "codigo_caja";
      //       propiedadColum = "id";
      //       propiedadFilter = "equal";
      //       propiedadValue = value;
      //       break;
      //     case "terminal":
      //       propiedaModel = "terminal";
      //       propiedadColum = "id";
      //       propiedadFilter = "equal";
      //       propiedadValue = value;
      //       break;
      //     case "punto_venta":
      //       propiedaModel = "puntos_venta";
      //       propiedadColum = "id";
      //       propiedadFilter = "equal";
      //       propiedadValue = value;
      //       break;
      //     case "tipo_fecha":
      //       propiedaModel = "comprobantes";
      //       propiedadColum = value === 1 ? "fecha_comprobante" : "fecha_trabajo";
      //       if (fecha_desde && fecha_hasta) {
      //         propiedadFilter = "between";
      //         propiedadValue = fecha_desde + "," + fecha_hasta;
      //       } else if (fecha_desde) {
      //         propiedadFilter = "greater";
      //         propiedadValue = fecha_desde;
      //       } else {
      //         propiedadFilter = "smaller";
      //         propiedadValue = fecha_hasta;
      //       }
      //       break;
      //     default:
      //       break;
      //   }
      //   objeto["model"] = propiedaModel;
      //   objeto["column"] = propiedadColum;
      //   objeto["value"] = propiedadValue;
      //   objeto["filter"] = propiedadFilter;
      //   return objeto;
      // });
      setCriteriosSearchCuentasJuridicas(dataForm);
      handleClose();
    };
    const handleClose = () => {
      props.cerrarModal(false);
    };
  
    return (
      <div>
        <JsonForms
          schema={schemaCuentasSearchForm}
          uischema={uiSchemaCuentasSearchForm}
          data={dataForm}
          renderers={[
             ...materialRenderers,
             SelectorLocalidadComponent,
          ]}
          cells={materialCells}
          onChange={({ data, _errors }) => setDataForm(data)}
        />
        <Grid container style={{ paddingTop: "15px", justifyContent: "center" }}>
          <Buttons
            variant="contained"
            color="success"
            onClickSubmit={handleSubmit}
            labelButtonSave={"Buscar"}
            buttonCerrar={handleClose}
          />
        </Grid>
      </div>
    );
  };
  
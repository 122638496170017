export const schemaFiltroServiciosForm = {
    type: "object",
    properties: 
    {
        "selectedCuentaJuridica": {
            type: "object",
            customRender: "SelectorCuentasJuridicas",
        },
        "selectedServicio": {
            type: "object",
            customRender: "SelectorServicios",
        },
    },
    required: [ "selectedCuentaJuridica","selectedServicio"],
}
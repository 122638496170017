import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "src/context/AuthContext";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { getFromLS } from "src/general_helpers/getFromLS";
import { SelectorAccountsComponent } from "../forms/customRenders/selectorAccounts";
import { SelectorProfilesComponent } from "../forms/customRenders/selectorProfiles";
import { schemaUsuariosForm } from "../forms/schemaUsuariosForm";
import { uiSchemaUsuariosForm } from "../forms/uiSchemaUsuariosForm";
import {
  deleteCuentasUsuario,
  postCuentasUsuario,
  postUser,
} from "../general_helpers/UsersData";
import { Buttons } from "./Buttons";

const localize = require("ajv-i18n");

export const UserForm = (props) => {
  const auth = useContext(AuthContext);
  const [seleccion_cuenta, setSeleccion_cuenta] = useState();
  const [opciones_cuentas, setOpciones_cuentas] = useState();
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [withErrors, setWithErrors] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [userNameOrigi, setUserNameOrigi] = useState(props.data?.username);
  // eslint-disable-next-line no-unused-vars
  const [userMailOrigi, setUserMailOrigi] = useState(props.data?.email);


  const {
    setCreateButton
  } = props;

  //busca todos los perfiles disponibles para el usuario que se encuantra logeado
  const profiles = getFromLS("siam-perfiles").perfiles;
  const [dataForm, setDataForm] = useState({
    id: props.data?.id || "",
    name: props.data?.nombre || "",
    surname: props.data?.apellido || "",
    username: props.data?.username || "",
    password: "",
    email: props.data?.email || "",
    profile: props.data?.id_perfiles || "",
    profiles: profiles,
    accounts: opciones_cuentas,
    account: "",
    accountAssigned: seleccion_cuenta,
  });

  /* Esto solo pasa si es para editar */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (props.data) {
      let user_aux = props.data;

      cargarOpciones();
      if (user_aux.relations) {
        let aux = [];
        aux.push(user_aux.relations.usuarios_cuentas);

        if (aux.length < 1) {
          return;
        } else {
          if (user_aux.relations.cuentas_personas_juridicas) {
            const cuentas_personas_juridicas_aux = user_aux.relations
              .cuentas_personas_juridicas.id_cuentas
              ? [user_aux.relations.cuentas_personas_juridicas]
              : user_aux.relations.cuentas_personas_juridicas;

            colocarOpciones(cuentas_personas_juridicas_aux);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargarOpciones = () => {
    const opc = cuentas_personas_juridicas.map((cuenta) => ({
      value: cuenta.id_cuentas,
      label: cuenta.nombre_fantasia,
    }));
    setOpciones_cuentas(opc);
    setDataForm({ ...dataForm, accounts: opc });
  };

  const colocarOpciones = (aux) => {
    if (aux) {
      const cuentas = aux.map((cuenta) => ({
        value: cuenta.id_cuentas,
        label: cuenta.nombre_fantasia,
      }));
      setSeleccion_cuenta(cuentas);
    }
  };

  const cuentas_personas_juridicas = getFromLS(
    "siam-cuentas_personas_juridicas"
  ).cuentas_personas_juridicas || [
      { id_cuentas: 0, nombre_fantasia: "No hay cuentas disponibles" },
    ];

  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataForm.accountAssigned]);

  //Solo entra cuando sea EDITAR y cuando el usuario ya tenga cuentas asociadas
  if (seleccion_cuenta && !dataForm.accountAssigned === true) {
    setDataForm({ ...dataForm, accountAssigned: seleccion_cuenta });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let encodedPassword = "";
    if (dataForm.password !== "" && dataForm.password !== undefined) {
      encodedPassword = btoa(dataForm.password);
    }
    const user = {
      id: dataForm.id,
      nombre: dataForm.name,
      apellido: dataForm.surname,
      username: dataForm.username,
      password: encodedPassword,
      email: dataForm.email,
      id_perfiles: dataForm.profile,
      id_cuentas: dataForm.account,
      userNameOrigi: userNameOrigi,
      userMailOrig: userMailOrigi
    };

    const response = await postUser(user, auth)

    if (response.status === 'true') {
      const responseDelete = await deleteCuentasUsuario(user.id, auth);

      if (user.id_cuentas && user.id) {
        user.id_cuentas.forEach((id) =>
          postCuentasUsuario(id, user.id)
        );
      } else {
        console.log(responseDelete.message)
        setSnackbarVisible(true);
        setMessage(responseDelete.message)
        setSeverity("warning")
      }

      props.setSnackbarVisible(true);
      if (dataForm.id) {
        props.setMessage("Usuario modificado con exito!!!")
      } else {
        props.setMessage("Usuario añadido con exito!!!")
      }
      props.setSeverity("success")
      props.setCreateButton(false);
      // setRedirect(true);
    } else {

      setSnackbarVisible(true);
      setMessage(Object.values(response.message.error_fields))
      setSeverity("warning")
    }
  };


  const onChange = (errors, data) => {

    localize.es(errors);
    //localize error se utiliza para pasar en español los errores del jsonform
    const contieneEspacios = /\s/.test(data.password);

    if (contieneEspacios) {
      errors.forEach((error) => {
        var keyword = error.keyword;

        if (keyword === 'pattern') {
          error.message = 'NO puede contener espacios vacios.'
        }

      });
    }

    setWithErrors(errors);
    setDataForm(data);

    return { errors, data };
  };



  return (
    <div>
      <JsonForms
        onChange={({ errors, data }) => onChange && onChange(errors, data)}
        schema={schemaUsuariosForm}
        uischema={uiSchemaUsuariosForm}
        renderers={[
          ...materialRenderers,
          SelectorProfilesComponent,
          SelectorAccountsComponent,
        ]}
        data={dataForm}
        cells={materialCells}
      />
      <Buttons
        onClickSubmit={handleSubmit}
        disableButton={withErrors && withErrors.length > 0 ? true : false}
        buttonCerrar={setCreateButton} />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={8000}
        close={() => { setSnackbarVisible(false) }} />
    </div>
  );
};

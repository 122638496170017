import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TablaPropiedadesComprobantesForm from './TablaPropiedadesComprobantesForm';
import TablaCotizacionesComprobantesForm from './TablaCotizacionesComprobantesForm';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getCuentasFisicas } from 'src/projects/gestion_comercial/cuentas/helpers/CuentasFisicasData';
import { getCuentasJuridicas } from 'src/projects/gestion_comercial/cuentas/helpers/CuentasJuridicasData';
import esArray from './functionEsArray';
import { getArticulosCuentas } from 'src/projects/articulos/helpers/ArticulosData';
import { getComprobantesCodigos } from 'src/general_helpers/ComprobantesCodigosData';
import { getTipoMoneda } from 'src/projects/articulos/helpers/TipoMonedaData';
import { getComprobantesEstados } from 'src/general_helpers/ComprobantesEstadosData';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { getTipoMovimientosData } from 'src/projects/gestion_documental/helpers/TipoMovimientosData';
import { createComprobantes } from 'src/general_helpers/ComprobantesData';
import { getOwnerLocalizaciones } from './OwnerLocalizacionesData';
import { getTerminalData } from 'src/projects/gestion_documental/helpers/TerminalData';
import { getCodigoCajasData } from 'src/projects/gestion_documental/helpers/CogidoCajasData';
import { getTipoCambio, getTipoCambioList } from '../general_helpers/TipoCambioData';
import { SnackbarMessenger } from './SnackbarMessenger';

export const ComprobantesCargaForm = () => {
    const [cliente, setCliente] = useState(null);
    const [clienteOptions, setClienteOptions] = useState([]);
    const [propiedad, setPropiedad] = useState([]);
    const [propiedadOptions, setPropiedadOptions] = useState([]);
    const [fechaTransaccion, setFechaTransaccion] = useState(new Date().toISOString().split('T')[0]);
    const [fechaComprobante, setFechaComprobante] = useState(new Date().toISOString().split('T')[0]);
    const [codigoComprobante, setCodigoComprobante] = useState(null);
    const [codigoComprobanteOptions, setCodigoComprobanteOptions] = useState([]);
    const [moneda, setMoneda] = useState(null);
    const [monedaOptions, setMonedaOptions] = useState([]);
    const [importe, setImporte] = useState(0);
    const [estado, setEstado] = useState(null);
    const [estadoOptions, setEstadoOptions] = useState([]);
    const [detalle, setDetalle] = useState('');
    const [datoExtra, setDatoExtra] = useState('');
    const [propiedadesTable, setPropiedadesTable] = useState([]);
    const [tipoMovimientosOptions, setTipoMovimientosOptions] = useState([]);
    const [tipoMovimiento, setTipoMovimiento] = useState(null);
    const [ownerLocalizacionesOptions, setOwnerLocalizacionesOptions] = useState([]);
    const [ownerLocalizacion, setOwnerLocalizacion] = useState(null);
    const [importePorPropiedad, setImportePorPropiedad] = useState(0);
    const [nroComprobante, setNroComprobante] = useState("");
    const [terminalOptions, setTerminalOptions] = useState([]);
    const [terminal, setTerminal] = useState(null);
    const [codigoCajasOptions, setCodigoCajasOptions] = useState([]);
    const [codigoCajas, setCodigoCajas] = useState(null);
    const [tipoCambioUtilizado, setTipoCambioUtilizado] = useState('');
    const [tipoCambioTable, setTipoCambioTable] = useState([]);
    const [responseTipoCambio, setResponseTipoCambio] = useState([]);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [cotizacionesView, setCotizacionesView] = useState(true);


    let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));

    let mostrar = parametros_proyecto?.id === 27 ? true : false;

    useEffect(() => {
        const getClientes = async () => {
            let responseJuridicas = esArray(await getCuentasJuridicas());
            let responseFisicas = esArray(await getCuentasFisicas());
            responseJuridicas = responseJuridicas.map(item => ({
                ...item,
                label: item.id_cuentas + " - " + item.razon_social,
                value: item.id_cuentas,
                category: "Cuentas Personas Juridicas"
            }));
            responseFisicas = responseFisicas.map(item => ({
                ...item,
                label: item.id_cuentas + " - " + item.apellido + " " + item.nombre,
                value: item.id_cuentas,
                category: "Cuentas Personas Fisicas"
            }));
            const concatClientes = responseJuridicas.concat(responseFisicas);

            setClienteOptions(concatClientes);

            if (concatClientes.length === 1) {
                setCliente(concatClientes[0])
            }
        }

        const getTipoMonedaList = async () => {
            let responseTipoMoneda = esArray(await getTipoMoneda());
            responseTipoMoneda = responseTipoMoneda.map(item => ({
                ...item,
                label: item.id + " - " + item.unidad_monetaria,
                value: item.id,
            }));

            setMonedaOptions(responseTipoMoneda);

            if (responseTipoMoneda.length === 1) {
                setMoneda(responseTipoMoneda[0])
                setCotizacionesView(false)
            }
        }

        const getTipoCambioLista = async () => {
            const responseTipoCambios = await getTipoCambioList();
            setResponseTipoCambio(responseTipoCambios);
        }

        const getComprobantesCodigosList = async () => {
            let responseComprobantesCodigos = esArray(await getComprobantesCodigos());
            responseComprobantesCodigos = responseComprobantesCodigos.map(item => ({
                ...item,
                label: item.id + " - " + item.descripcion,
                value: item.id,
            }));

            setCodigoComprobanteOptions(responseComprobantesCodigos);

            if (responseComprobantesCodigos.length === 1) {
                setCodigoComprobante(responseComprobantesCodigos[0])
            }
        }

        const getTipoMovimientosList = async () => {
            let responseTipoMovimientos = esArray(await getTipoMovimientosData());
            responseTipoMovimientos = responseTipoMovimientos.map(item => ({
                ...item,
                label: item.id + " - " + item.nombre,
                value: item.id,
            }));
            setTipoMovimientosOptions(responseTipoMovimientos);

            if (responseTipoMovimientos.length === 1) {
                setTipoMovimiento(responseTipoMovimientos[0])
            }
        }

        const getOwnerLocalizacionesList = async () => {
            let responseOwnerLocalizaciones = esArray(await getOwnerLocalizaciones());
            responseOwnerLocalizaciones = responseOwnerLocalizaciones.map(item => ({
                ...item,
                label: item.id + " - " + item.detalle,
                value: item.id,
            }));

            setOwnerLocalizacionesOptions(responseOwnerLocalizaciones);

            if (responseOwnerLocalizaciones.length === 1) {
                setOwnerLocalizacion(responseOwnerLocalizaciones[0])
            }
        }

        const getTerminalList = async () => {
            let responseTerminal = esArray(await getTerminalData());
            responseTerminal = responseTerminal.map(item => ({
                ...item,
                label: item.numero_terminal,
                value: item.id,
            }));

            setTerminalOptions(responseTerminal);

            if (responseTerminal.length === 1) {
                setTerminal(responseTerminal[0])
            }
        }

        const getCodigoCajaList = async () => {
            let responseCodigoCaja = esArray(await getCodigoCajasData());
            responseCodigoCaja = responseCodigoCaja.map(item => ({
                ...item,
                label: item.detalle,
                value: item.id,
            }));

            setCodigoCajasOptions(responseCodigoCaja);

            if (responseCodigoCaja.length === 2) {
                setCodigoCajas(responseCodigoCaja[0])
            }
        }

        getCodigoCajaList();
        getTerminalList();
        getOwnerLocalizacionesList();
        getTipoMovimientosList();
        getComprobantesCodigosList();
        getTipoCambioLista();
        getTipoMonedaList();
        getClientes();
    }, []);

    useEffect(() => {
        const getPropiedades = async () => {
            let responsePropiedad = esArray(await getArticulosCuentas(cliente?.value));
            responsePropiedad = responsePropiedad.map(item => ({
                ...item,
                label: item.id + " - " + item.nombre,
                value: item.id,
            }));

            responsePropiedad = esArray(responsePropiedad)
            setPropiedadOptions(responsePropiedad);

            if (responsePropiedad.length === 1) {
                setPropiedad(responsePropiedad)
            }
        }


        if (cliente) {
            getPropiedades();
        }
    }, [cliente]);

    useEffect(() => {
        const getComprobantesEstadosList = async () => {
            let responseComprobantesEstados = esArray(await getComprobantesEstados(codigoComprobante?.value));
            responseComprobantesEstados = responseComprobantesEstados.map(item => ({
                label: item.nombre,
                value: item.id,
            }));
            setEstadoOptions(responseComprobantesEstados);

            if (responseComprobantesEstados.length === 1) {
                setEstado(responseComprobantesEstados[0])
            }
        }

        if (codigoComprobante) {
            getComprobantesEstadosList();
        }
    }, [codigoComprobante]);

    useEffect(() => {
        if (propiedad && propiedad.length > 0) {
            const importePropiedad = (importe / propiedad.length).toFixed(2);

            // Añadir las nuevas propiedades a cada objeto
            const objetosConPropiedades = propiedad.map(objeto => ({
                ...objeto,
                propiedad: objeto.id + " - " + objeto.nombre,
                importe: importePropiedad // Puedes establecer el valor que desees
            }));

            setImportePorPropiedad(importePropiedad);
            setPropiedadesTable(objetosConPropiedades);
        }
    }, [propiedad, importe]);


    useEffect(() => {
        const getTipoDeCambio = async () => {

            // Objeto auxiliar para almacenar temporalmente los objetos agrupados por tipo de moneda y mantener solo el que tenga el ID más alto
            const objetosPorTipoMoneda = {};
            // Recorrer el array original y agregar los objetos al objeto auxiliar
            responseTipoCambio.data.forEach(objeto => {
                const tipoMonedaId = objeto.relations.tipo_moneda.id;
                if (!objetosPorTipoMoneda[tipoMonedaId] || objeto.id > objetosPorTipoMoneda[tipoMonedaId].id) {
                    objetosPorTipoMoneda[tipoMonedaId] = objeto;
                }
            });
            // Obtener el array final con un objeto por tipo de moneda
            const arrayFinal = Object.values(objetosPorTipoMoneda);
            const tipoCambioList = arrayFinal.map(objeto => ({
                cotizacion: objeto.fecha + ' - ' + objeto.relations.tipo_moneda.unidad_monetaria + ' - ' + objeto.cotizacion_referencia,
                importe: objeto.relations.tipo_moneda.simbolo + ' ' + parseFloat(importe / objeto.cotizacion_venta).toFixed(2)
            }));
            setTipoCambioTable(tipoCambioList);

        }

        if (responseTipoCambio?.length > 0) {

            getTipoDeCambio();
        }

    }, [importe, responseTipoCambio]);

    useEffect(() => {
        const getTipoDeCambio = async () => {
            if (moneda && moneda !== '') {
                // calculo la cotizacion y el tipo de cambio
                if (moneda.codigo_iso !== parametros_proyecto.codigo_iso_moneda) {
                    const responseTipoCambio = await getTipoCambio(moneda.value);

                    if (responseTipoCambio.length === 0) {
                        setMessage("No se encontraron tipos de cambios !")
                        setSeverity("warning");
                        setSnackbarVisible(true);
                        return;
                    }


                    const ultimoTipoCambio = responseTipoCambio.reduce((max, obj) => (obj.id > max.id ? obj : max), responseTipoCambio[0]);

                    setTipoCambioUtilizado(ultimoTipoCambio);
                }

            }
        }

        getTipoDeCambio();

    }, [moneda, parametros_proyecto.codigo_iso_moneda]);

    const handleSubmit = async (e) => {
        // Verificar que todos los datos necesarios estén definidos y no estén vacíos
        const validations = [
            { param: moneda, message: "Falta seleccionar la moneda." },
            { param: propiedadesTable, message: "Falta agregar propiedades para el comprobante." },
            { param: ownerLocalizacion, message: "Falta seleccionar la localización del propietario." },
            { param: terminal, message: "Falta seleccionar la terminal." },
            { param: codigoCajas, message: "Falta seleccionar el código de la caja." },
            { param: fechaComprobante, message: "Falta seleccionar la fecha del comprobante." },
            { param: fechaTransaccion, message: "Falta seleccionar la fecha de transacción." },
            { param: tipoMovimiento, message: "Falta seleccionar el tipo de movimiento." },
            { param: codigoComprobante, message: "Falta seleccionar el código del comprobante." },
            { param: nroComprobante, message: "Falta ingresar el número del comprobante." },
            { param: importe, message: "Falta ingresar el importe del comprobante." },
            { param: detalle, message: "Falta ingresar el detalle del comprobante." },
            { param: parametros_proyecto, message: "Falta definir los parámetros del proyecto." },
        ];


        for (const { param, message } of validations) {
            if (!param) {
                setMessage(message);
                setSeverity("warning");
                setSnackbarVisible(true);
                return;
            }
        }

        if (moneda.codigo_iso !== parametros_proyecto.codigo_iso_moneda) {
            if (!tipoCambioUtilizado) {
                setMessage("Falta definir el tipo de cambio utilizado.");
                setSeverity("warning");
                setSnackbarVisible(true);
                return;
            }
        }

        if (propiedadesTable.length === 0) {
            setMessage("Debe seleccionar almenos una propiedad")
            setSeverity("warning");
            setSnackbarVisible(true);
            return;
        }


        if (importe <= 0) {
            setMessage("El importe debe ser mayor a 0")
            setSeverity("warning");
            setSnackbarVisible(true);
            return;
        }

        // Calcular la suma de todos los importes en propiedadesTable
        const sumaImportes = propiedadesTable.reduce((total, objeto) => total + parseFloat(objeto.importe), 0);

        // Verificar si la suma de importes es menor o igual a importe
        if (sumaImportes > importe) {
            setMessage("La suma de los importes de cada propiedad no puede ser mayor que el importe general.")
            setSeverity("warning");
            setSnackbarVisible(true);
            return;
        }

        const comprobantes_valores_items = propiedadesTable.map((item, index) => ({
            id_articulos: item.id,
            nro_orden: parseInt(index + 1).toString(), // Convertir el índice a cadena de texto
            descripcion: item.nombre,
            id_alicuotas_iva: item.id_alicuotas_iva,
            total_neto_bruto: item.importe,
            total_neto: item.importe,
            total: item.importe
        }));
        const datos = {
            comprobantes: {
                id_ejercicios_contables: "1",
                id_owner_localizaciones: ownerLocalizacion?.value,
                id_terminal: terminal?.value,
                id_codigo_caja: codigoCajas?.value,
                fecha_comprobante: fechaComprobante,
                fecha_trabajo: fechaTransaccion,
                id_tipo_movimiento: tipoMovimiento?.value,
                id_comprobantes_codigos: codigoComprobante?.value,
                id_comprobantes_estados: estado?.value,
                punto_venta_comprobante: "1",
                numero_comprobante: nroComprobante,
                afecta_contabilidad: "0",
                total: importe,
                anulado: "0",
                anulacion_motivo: "",
                observaciones_accesorias: detalle,

            },
            comprobantes_valores_items: comprobantes_valores_items,
            comprobantes_moneda_extranjera: {
                id_tipo_moneda: moneda.value,
                saldo_moneda_extranjera: "0",
                total_moneda_extranjera: moneda.codigo_iso === parametros_proyecto.codigo_iso_moneda ? importe : (importe / tipoCambioUtilizado.cotizacion_referencia),
                tipo_cambio: moneda.codigo_iso === parametros_proyecto.codigo_iso_moneda ? 1 : tipoCambioUtilizado.cotizacion_referencia,
            },
            comprobantes_cuentas: {
                id_cuentas: cliente.value
            }
        }

        const response = await createComprobantes(datos, datoExtra);
        if (response.status === 'true') {
            setMessage("Operacion realizada con exito!")
            setSeverity("success");
            setSnackbarVisible(true);
            Limpiar();
        } else {
            setMessage("Hubo un error durante el proceso!")
            setSeverity("warning");
            setSnackbarVisible(true);
        }
    };

    const Limpiar = async (e) => {
        setPropiedadesTable([]);
        setCliente(null);
        // setOwnerLocalizacion(null);
        if (ownerLocalizacionesOptions.length === 1) {
            setOwnerLocalizacion(ownerLocalizacionesOptions[0])
        }
        setPropiedad([]);
        // setTipoMovimiento(null);

        if (tipoMovimientosOptions.length === 1) {
            setTipoMovimiento(tipoMovimientosOptions[0])
        }
        setCodigoComprobante(null);
        // setCodigoCajas(null);
        if (codigoCajasOptions.length === 2) {
            setCodigoCajas(codigoCajasOptions[0])
        }
        setFechaTransaccion(new Date().toISOString().split('T')[0]);
        setFechaComprobante(new Date().toISOString().split('T')[0]);
        // setTerminal(null);
        if (terminalOptions.length === 1) {
            setTerminal(terminalOptions[0])
        }
        setMoneda(null);
        setImporte(0);
        setEstado(null);
        setNroComprobante("");
        setDetalle('');
        setImportePorPropiedad(0);
        setDatoExtra('')

    };

    const estilosSelect = {
        fontSize: "1em", color: "#646464", fontFamily: 'Roboto, sans-serif', backgroundColor: 'white',
        borderRadius: 10,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    };

    return (
        <Grid
            style={{
                background: "white",
                display: 'flex',
                justifyContent: 'space-between', // Para alinear a los extremos
                alignItems: 'center', // Para alinear verticalmente
                backgroundColor: 'white',
                fontFamily: 'Roboto, sans-serif',
                borderRadius: 10,
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                padding: 10,

            }}
        >

            <Grid sx={{ padding: 15, paddingTop: 0 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            style={estilosSelect}
                            size="small"
                            options={clienteOptions}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Cliente" />}
                            onChange={(e, value) => setCliente(value || '')}
                            groupBy={(option) => option.category}
                            value={cliente}
                        />
                    </Grid>
                    {mostrar && (
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                style={estilosSelect}
                                size="small"
                                options={ownerLocalizacionesOptions}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Owner Localizaciones" />}
                                onChange={(e, value) => setOwnerLocalizacion(value || '')}
                                value={ownerLocalizacion}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            size="small"
                            multiple
                            limitTags={1} // Establece el número máximo de etiquetas visibles
                            options={propiedadOptions}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Propiedad" />}
                            onChange={(e, value) => setPropiedad(value.map((v) => v))}
                            value={propiedad}
                        />
                    </Grid>
                    {mostrar && (
                        <Grid item xs={12} sm={2}>
                            <Autocomplete
                                style={estilosSelect}
                                size="small"
                                options={tipoMovimientosOptions}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Tipo de movimiento" />}
                                onChange={(e, value) => setTipoMovimiento(value || '')}
                                value={tipoMovimiento}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={3}>
                        <Autocomplete
                            style={estilosSelect}
                            size="small"
                            options={codigoComprobanteOptions}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Código de comprobante" />}
                            onChange={(e, value) => setCodigoComprobante(value || '')}
                            value={codigoComprobante}
                        />
                    </Grid>
                    {mostrar && (
                        <Grid item xs={12} sm={2}>
                            <Autocomplete
                                style={estilosSelect}
                                size="small"
                                options={codigoCajasOptions}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Código de caja" />}
                                onChange={(e, value) => setCodigoCajas(value || '')}
                                value={codigoCajas}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={2}>
                        <TextField
                            style={estilosSelect}
                            size="small"
                            id="fecha-transaccion"
                            label="Fecha de transacción"
                            type="date"
                            value={fechaTransaccion}
                            onChange={(e) => setFechaTransaccion(e.target.value)}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            style={estilosSelect}
                            size="small"
                            id="fecha-comprobante"
                            label="Fecha de comprobante"
                            type="date"
                            value={fechaComprobante}
                            onChange={(e) => setFechaComprobante(e.target.value)}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    {mostrar && (
                        <Grid item xs={12} sm={1}>
                            <Autocomplete
                                style={estilosSelect}
                                size="small"
                                options={terminalOptions}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Terminal" />}
                                onChange={(e, value) => setTerminal(value || '')}
                                value={terminal}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={3}>
                        <Autocomplete
                            style={estilosSelect}
                            size="small"
                            options={monedaOptions}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Moneda" />}
                            onChange={(e, value) => setMoneda(value || '')}
                            value={moneda}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth style={estilosSelect}>
                            <InputLabel htmlFor="importe">Importe</InputLabel>
                            <OutlinedInput
                                id="importe"
                                size="small"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Importe"
                                onChange={(e) => setImporte(e.target.value)}
                                type="number"
                                value={importe}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            style={estilosSelect}
                            size="small"
                            options={estadoOptions}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Estados" />}
                            onChange={(e, value) => setEstado(value || '')}
                            value={estado}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            style={estilosSelect}
                            size="small"
                            id="nro_comprobante"
                            label="Numero de Comprobante"
                            type="number"
                            value={nroComprobante}
                            onChange={(e) => setNroComprobante(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            style={estilosSelect}
                            id="detalle"
                            label="Detalle"
                            multiline
                            rows={4}
                            value={detalle}
                            onChange={(e) => setDetalle(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    {!mostrar && (
                        <Grid item xs={12}>
                            <TextField
                                style={estilosSelect}
                                id="detalle"
                                label="Detalle del cliente"
                                multiline
                                rows={4}
                                value={datoExtra}
                                onChange={(e) => setDatoExtra(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                        <TablaPropiedadesComprobantesForm
                            importePorPropiedad={importePorPropiedad}
                            importe={importe}
                            propiedadesTable={propiedadesTable}
                            setPropiedadesTable={setPropiedadesTable}
                        />
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        {cotizacionesView && (

                            <TablaCotizacionesComprobantesForm
                                tipoCambioTable={tipoCambioTable}
                            />

                        )}
                    </Grid>

                    <Grid item container xs={12} justifyContent="center" spacing={2} sx={{ marginTop: "2%" }}>
                        <Grid item>
                            <Button variant="contained" color="error" startIcon={<HighlightOffIcon />} onClick={Limpiar}>
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" startIcon={<CheckCircleOutlineIcon />} onClick={handleSubmit}>
                                Procesar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SnackbarMessenger
                open={snackbarVisible}
                message={message}
                severity={severity}
                close={() => {
                    setSnackbarVisible(false);
                }}
            />
        </Grid>
    );
};

export default ComprobantesCargaForm;

import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";

export const getRemitosByIdCuenta = async (id_cuenta) => {
    const comprobantes = new datos("comprobantes")

    comprobantes.join('comprobantes_codigos', "id", "id_comprobantes_codigos", "=")
    comprobantes.join('comprobantes_cuentas', "id_cuentas", id_cuenta, "=")
    // comprobantes.addCondition("comprobantes", "id", 0, ">")
    // comprobantes.addCondition("comprobantes_codigos", "alias", "REM", "=")
    comprobantes.addOrderCondition("comprobantes", "fecha_comprobante", "DESC")

    const response = await comprobantes.get()
    return esArray(response.wsResponse.comprobantes);
}

export const getRemitosByIdPaginated = async (limit, offset, id_cuenta) => {

    console.log("id_cuentaRemito", id_cuenta)
    const comprobantes = new datos("comprobantes")

    comprobantes.join('comprobantes_codigos', "id", "id_comprobantes_codigos", "=")
    comprobantes.join('comprobantes_cuentas', "id_comprobantes", "id", "=")
    // comprobantes.addCondition("comprobantes", "id", 0, ">")
    comprobantes.addCondition("comprobantes_cuentas", "id_cuentas", id_cuenta, "=")
    comprobantes.addCondition("comprobantes_codigos", "id", "11", "=")
    comprobantes.addOrderCondition("comprobantes", "fecha_comprobante", "DESC")
    comprobantes.addLimitCondition(limit, offset);

    const response = await comprobantes.get()
   
    let result = {};
    
    if (!response.wsResponse.empty) {
        const responseDatosArray = esArray(response.wsResponse.comprobantes)
        const newArray = responseDatosArray.map(objeto => ({
            ...objeto, // Copia todas las propiedades existentes del objeto
            isRowSelectable: true // Agrega la nueva propiedad con el valor 'true'
        }));
        result = {
            data: newArray,
            total_records: response.wsResponse.total_records,
        };
        return result;
    }else {
        return result = {
            data: [],
            total_records: 0,
        };
    }
}
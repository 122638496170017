import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import { getDepositos } from "../../projects/gestion_documental/helpers/UbicacionCajasData";
import { Autocomplete, TextField } from "@mui/material";
import { esArray } from "src/general_components/functionEsArray";

const SelectorDeposito = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const dep = jsonForm.core.data.idDep;
  const [depositoSeleccionado, setDeposito] = useState("");

  //const dataSeleccionada=dataDefaults.find((item) => item.id === props.data);

  useEffect(() => {
    const obtenerDepositos = async () => {

      let depositos = esArray(JSON.parse(localStorage.getItem("siam-owner_localizaciones"))?.owner_localizaciones);

      if (!depositos) {
       
         depositos = await getDepositos();
         
      }

        setDataDefaults(depositos);
      
    };
    obtenerDepositos();
  }, []);

  useEffect(() => {
    setDeposito(dataDefaults.find((item) => item.id === dep));
  }, [dataDefaults, dep]);


  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setDeposito(newValue);
      props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
    }else{
      props.handleChange(props.path, "");
    }
  };
  
  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px",marginRight:'70px' }}
      >
        <Autocomplete
        value={depositoSeleccionado ? depositoSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.detalle || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Depositos" 
          disabled={disabled}/>
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorDepositosTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorDeposito";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorDepositoComponent = {
  renderer: withJsonFormsControlProps(SelectorDeposito),
  tester: SelectorDepositosTester,
};

import { Modal, Button } from "@mui/material";

export const LogoutModal = ({ handleLogout, setShowLogoutModal,resetCountdown,countdown }) => {
    // const [countdown, setCountdown] = useState(30);


    const cerrarModal = () => {
        setShowLogoutModal(false)
    };

    const handleClose = () => {
        handleLogout();
    };

    return (
        <Modal open={true} onClose={cerrarModal}>
            <div style={{ textAlign: "center", backgroundColor: "white", padding: "20px" }}>
                <h2>¿Sigues aquí?</h2>
                <p>Se cerrará la sesión en {countdown} segundos si no respondes.</p>
                <Button variant="contained" onClick={resetCountdown} style={{ marginRight: "10px" }}>
                    Sigo aquí
                </Button>
                <Button variant="contained" color="error" onClick={handleClose}>
                    Cerrar sesión
                </Button>
            </div>
        </Modal>
    );
};

export default LogoutModal;
import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";


export const getLocalidades = async (auth) => {
    const localidad = new datos("localidad");
    localidad.addCondition("localidad", "id", 0, ">");
    const response = await localidad.get();
    return esArray(response.wsResponse.localidad);

};

export const getLocalidadesWhereDepartamento = async (id_departamento) => {
    const localidad = new datos("localidad");
    localidad.addCondition("localidad", "id", 0, ">");
    localidad.addCondition('localidad', 'id_departamento', id_departamento, '=')
    const response = await localidad.get();
    return esArray(response.wsResponse.localidad);

};


export const getLocalidadesEditByIdLocalidad = async (id_localidad) => {
    const localidad = new datos("localidad");
    localidad.addCondition("localidad", "id", id_localidad, "=");
    // localidad.join("departamento", "id", "id_departamento", " = ");

    localidad.innerJoinTables('departamento', 'localidad', "id", "id_departamento", "=")
    localidad.innerJoinTables('provincia', 'departamento', "id", "id_provincia", "=")

    const response = await localidad.get();
    return response.wsResponse.localidad;

};

export const getLocalidadPaginated = async (limit, offset) => {
    const localidad = new datos("localidad");
    localidad.addCondition("localidad", "id", 0, ">");
    localidad.addLimitCondition(limit, offset);
    const response = await localidad.get();
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

    let result = {};
    if (!response.wsResponse.empty) {
        result = {
            data: esArray(response.wsResponse.localidad),
            total_records: response.wsResponse.total_records,
        };
        return result;
    }
};

export const deleteLocalidad = async (id) => {
    let status = false;
    await Swal.fire({
        title: "Esta seguro de que desea eliminar esta localidad?",
        text: "Si sigue adelante, no se podran revertir los cambios!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, quiero eliminarlo!",
        cancelButtonText: "No, no estoy seguro!",
    }).then(async (result) => {
        if (result.isConfirmed) {
            const localidad = new datos("localidad");
            let data = {
                id: id,
            };
            const response = await localidad.delete(data);

            if (response.status === "true") {
                status = true;
                await Swal.fire(
                    "Eliminado!",
                    "La Localidad ha sido eliminado exitosamente",
                    "success"
                );
            } else {
                await Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response.message,
                });
            }
        }
    });
    return status;
};

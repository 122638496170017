import {
    and,
    rankWith,
    schemaMatches,
    schemaTypeIs,
    uiTypeIs,
  } from "@jsonforms/core";
  import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
  import FormControl from "@mui/material/FormControl";
  import { useEffect, useState } from "react";
  import { getImpuestosSituaciones } from "src/general_helpers/PerfilFiscalData";
  import {esArray} from "src/general_components/functionEsArray";
  import { getFromLS } from "src/general_helpers/getFromLS";
  import { Autocomplete, TextField } from "@mui/material";
  
  const SelectorPerfilFiscal = (props) => {
    //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
    const jsonForm = useJsonForms();
    const [dataDefaults, setDataDefaults] = useState([]);
    const disabled = jsonForm.core.data.disabled;
    const perfilFiscal = jsonForm.core.data.selectedPerfilFiscal;
    
    const impuestos_situaciones= getFromLS("siam-impuestos_situaciones").impuestos_situaciones;
    const [impuestoSituacionSeleccionado, setImpuestoSituacionSeleccionado] = useState("");
  
    useState(() => {
      const getPerfilFiscal = async () => {
        if (impuestos_situaciones) {
          setDataDefaults(esArray(impuestos_situaciones));
        }else {
          const response = await getImpuestosSituaciones()
          if (response) {
            setDataDefaults(response);
          }
        }
      }
      getPerfilFiscal()
    
    }, []);

    useEffect(() => {
      setImpuestoSituacionSeleccionado(dataDefaults.find((item) => item.id === perfilFiscal));
    }, [dataDefaults, perfilFiscal]);

    const handleChange = (event, newValue) => {
      if (newValue && newValue !== "") {
        setImpuestoSituacionSeleccionado(newValue);
        props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
      }else{
        props.handleChange(props.path, "");
      }
    };
    return (
      <div>
        <FormControl
          variant="standard"
          sx={{ width: "100%", paddingBottom: "10px" }}
        >
          <Autocomplete
        value={impuestoSituacionSeleccionado  ? impuestoSituacionSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.detalle || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Perfil fiscal"
          disabled={disabled} />
        )}
      />
        </FormControl>
      </div>
    );
  };
  
  /*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
  const SelectorPerfilFiscalTester = rankWith(
    100,
    and(
      uiTypeIs("Control"),
      schemaTypeIs("string"),
      schemaMatches((schema) => {
        if (schema.hasOwnProperty("customRender")) {
          let cellschema = schema;
          return cellschema["customRender"] === "SelectorPerfilFiscal";
        }
        return false;
      })
    )
  );
  
  // se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
  export const SelectorPerfilFiscalComponent = {
    renderer: withJsonFormsControlProps(SelectorPerfilFiscal),
    tester: SelectorPerfilFiscalTester,
  };
  
import React from 'react';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography, Button, TextField, Grid, IconButton, Divider, Tooltip, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import AlignHorizontalRightRoundedIcon from '@mui/icons-material/AlignHorizontalRightRounded';
import AlignHorizontalLeftRoundedIcon from '@mui/icons-material/AlignHorizontalLeftRounded';
import ContrastIcon from '@mui/icons-material/Contrast';
import TonalityRoundedIcon from '@mui/icons-material/TonalityRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const DrawerContent = styled(Box)(({ theme }) => ({
    width: 300,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
}));

const defaultSettings = {
    mode: 'light',
    menuColor: '#0A395F',
    modalColor: '#0A395F',
    buttonColor: '#0A395F',
    contrast: 'normal',
    direction: 'left',
};

function ModalConfig({ open, onClose, onThemeChange, onColorChange }) {
    const theme = useTheme();
    const [menuColor, setMenuColor] = React.useState(defaultSettings.menuColor);
    const [modalColor, setModalColor] = React.useState(defaultSettings.modalColor);
    const [buttonColor, setButtonColor] = React.useState(defaultSettings.buttonColor);
    const [selectedPreset, setSelectedPreset] = React.useState(null);
    const [selectedMode, setSelectedMode] = React.useState(defaultSettings.mode);
    const [selectedContrast, setSelectedContrast] = React.useState(defaultSettings.contrast);
    const [selectedDirection, setSelectedDirection] = React.useState(defaultSettings.direction);

    React.useEffect(() => {
        const savedPreset = localStorage.getItem('selectedPreset');
        if (savedPreset) {
            const preset = JSON.parse(savedPreset);
            setSelectedPreset(preset);
            applyPreset(preset);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDarkModeChange = (mode) => {
        setSelectedMode(mode);
        onThemeChange(mode);
        localStorage.setItem('themeMode', mode);
    };

    const handleMenuColorChange = (event) => {
        const color = event.target.value;
        setMenuColor(color);
        onColorChange('menu', color);
        localStorage.setItem('menuColor', color);
    };

    const handleModalColorChange = (event) => {
        const color = event.target.value;
        setModalColor(color);
        onColorChange('modal', color);
        localStorage.setItem('modalColor', color);
    };

    const handleButtonColorChange = (event) => {
        const color = event.target.value;
        setButtonColor(color);
        onColorChange('button', color);
        localStorage.setItem('buttonColor', color);
    };

    const handlePresetSelect = (preset) => {
        setSelectedPreset(preset);
        localStorage.setItem('selectedPreset', JSON.stringify(preset));
        applyPreset(preset);
    };

    const applyPreset = (preset) => {
        onColorChange('menu', preset.menuColor);
        onColorChange('modal', preset.modalColor);
        onColorChange('button', preset.buttonColor);
        setMenuColor(preset.menuColor);
        setModalColor(preset.modalColor);
        setButtonColor(preset.buttonColor);
    };

    const handleContrastChange = (contrast) => {
        setSelectedContrast(contrast);
        // Add logic to update theme contrast if necessary
    };

    const handleDirectionChange = (direction) => {
        setSelectedDirection(direction);
        // Add logic to update menu direction if necessary
    };

    const resetToDefaults = () => {
        setSelectedMode(defaultSettings.mode);
        setMenuColor(defaultSettings.menuColor);
        setModalColor(defaultSettings.modalColor);
        setButtonColor(defaultSettings.buttonColor);
        setSelectedContrast(defaultSettings.contrast);
        setSelectedDirection(defaultSettings.direction);
        setSelectedPreset(null);
        localStorage.removeItem('selectedPreset');
        localStorage.setItem('themeMode', defaultSettings.mode);
        localStorage.setItem('menuColor', defaultSettings.menuColor);
        localStorage.setItem('modalColor', defaultSettings.modalColor);
        localStorage.setItem('buttonColor', defaultSettings.buttonColor);
        onThemeChange(defaultSettings.mode);
        onColorChange('menu', defaultSettings.menuColor);
        onColorChange('modal', defaultSettings.modalColor);
        onColorChange('button', defaultSettings.buttonColor);
    };

    const estilosTitulo = {
        fontSize: 16,
        fontFamily: 'Roboto, sans-serif',
        color: theme.palette.text.primary,
        fontWeight: "bold",
    };

    const estilosSubTitulo = {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: theme.palette.text.secondary,
        fontWeight: "bold",
        marginTop: 2,
    };

    const presets = [
        { id: 1, menuColor: '#00A76F', modalColor: '#E0F2F1', buttonColor: '#004D40' },
        { id: 2, menuColor: '#078DEE', modalColor: '#E3F2FD', buttonColor: '#01579B' },
        { id: 3, menuColor: '#7635DC', modalColor: '#F3E5F5', buttonColor: '#4A148C' },
        { id: 4, menuColor: '#0A395F', modalColor: '#0A395F', buttonColor: '#1A237E' },
        { id: 5, menuColor: '#FDA92D', modalColor: '#FFF3E0', buttonColor: '#E65100' },
        { id: 6, menuColor: '#FF3030', modalColor: '#FFEBEE', buttonColor: '#B71C1C' },
    ];

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <DrawerContent sx={{ height: '100%' }}>
                <Grid container justifyContent="space-between" alignItems="center" sx={{ marginTop: 8 }}>
                    <Typography variant="h6" sx={estilosTitulo}>Configuración</Typography>
                    <Grid item>
                        <Tooltip title="Reset">
                            <IconButton onClick={resetToDefaults}>
                                <RestoreIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cerrar">
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%' }} />
                <Typography variant="subtitle1" sx={estilosSubTitulo}>Modo</Typography>
                <Stack direction="row" spacing={2} sx={{ marginTop: 1 }}>
                    <Button
                        variant={selectedMode === 'light' ? 'contained' : 'outlined'}
                        size="large"
                        startIcon={<WbSunnyIcon />}
                        onClick={() => handleDarkModeChange('light')}
                        sx={{ height: '60px', borderRadius: '10px', width: '50%' }}
                    >

                    </Button>
                    <Button
                        variant={selectedMode === 'dark' ? 'contained' : 'outlined'}
                        size="large"
                        startIcon={<NightsStayIcon />}
                        onClick={() => handleDarkModeChange('dark')}
                        sx={{ height: '60px', borderRadius: '10px', width: '50%' }}
                    >

                    </Button>
                </Stack>
                <Typography variant="subtitle1" sx={estilosSubTitulo}>Contraste</Typography>
                <Stack direction="row" spacing={2} sx={{ marginTop: 1 }}>
                    <Button
                        variant={selectedContrast === 'normal' ? 'contained' : 'outlined'}
                        size="large"
                        startIcon={<ContrastIcon />}
                        onClick={() => handleContrastChange('normal')}
                        sx={{ height: '60px', borderRadius: '10px', width: '50%' }}
                    >

                    </Button>
                    <Button
                        variant={selectedContrast === 'high' ? 'contained' : 'outlined'}
                        size="large"
                        startIcon={<TonalityRoundedIcon />}
                        onClick={() => handleContrastChange('high')}
                        sx={{ height: '60px', borderRadius: '10px', width: '50%' }}
                    >

                    </Button>
                </Stack>
                <Typography variant="subtitle1" sx={estilosSubTitulo}>Dirección del Menú</Typography>
                <Stack direction="row" spacing={2} sx={{ marginTop: 1 }}>
                    <Button
                        variant={selectedDirection === 'left' ? 'contained' : 'outlined'}
                        size="large"
                        startIcon={<AlignHorizontalLeftRoundedIcon />}
                        onClick={() => handleDirectionChange('left')}
                        sx={{ height: '60px', borderRadius: '10px', width: '50%' }}
                    >

                    </Button>
                    <Button
                        variant={selectedDirection === 'right' ? 'contained' : 'outlined'}
                        size="large"
                        startIcon={<AlignHorizontalRightRoundedIcon />}
                        onClick={() => handleDirectionChange('right')}
                        sx={{ height: '60px', borderRadius: '10px', width: '50%' }}
                    >

                    </Button>
                </Stack>
                <Typography variant="subtitle1" sx={estilosSubTitulo}>Presets</Typography>
                <Grid container spacing={1} sx={{ marginTop: 2 }}>
                    {presets.map((preset) => (
                        <Grid item key={preset.id}>
                            <Tooltip title={`Preset ${preset.id}`} placement="top">
                                <IconButton
                                    sx={{
                                        color: preset.menuColor,
                                        backgroundColor: selectedPreset?.id === preset.id ? '#CCCCCC' : 'transparent',
                                        borderRadius: '50%',
                                    }}
                                    onClick={() => handlePresetSelect(preset)}
                                >
                                    <FiberManualRecordIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ))}
                </Grid>
                <Typography variant="subtitle1" sx={estilosSubTitulo}>Personalizado</Typography>
                <Stack spacing={2} sx={{ marginTop: 1 }}>
                    <TextField
                        label="Color del Menú"
                        type="color"
                        value={menuColor}
                        onChange={handleMenuColorChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Color del Modal"
                        type="color"
                        value={modalColor}
                        onChange={handleModalColorChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Color de las letras"
                        type="color"
                        value={buttonColor}
                        onChange={handleButtonColorChange}
                        fullWidth
                        margin="normal"
                    />
                </Stack>
                <Divider sx={{ width: '100%' }} />
            </DrawerContent>
        </Drawer>
    );
}

export default ModalConfig;

import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { Buttons } from "src/general_components/Buttons";
import { postNotas, postDigitalizacionesNotas } from "../helpers/NotasData";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";

export const NotasForm = (props) => {
  //console.log("dataEdit: ", props.dataEdit);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [base64String, setBase64String] = useState("");
  const upperCaseTransformer = (value) => (value ? value.toUpperCase() : "");

  const [valorDetalle, setValorDetalle] = useState(
    upperCaseTransformer(typeof (props.dataEdit.detalle) === 'object' ? "" : props.dataEdit.detalle || "")
  );

  let paramsInsert = props.params.params;
  let idNotaEdit = props.dataEdit?.id;



  //NOTE CUANDO SE SELECCIONA UNA IMAGEN SE PASA DIRECTAMENTE A BASE 64
  const uploadImages = async (event) => {
    let fileObj = [];
    fileObj.push(event.target.files);

    for (let i = 0; i < fileObj[0].length; i++) {
      setLoading(true);
      let imageBase64 = await convertImageToBase64(
        URL.createObjectURL(fileObj[0][i]),
        event.target.files[0].name
      );
      setBase64String(imageBase64);
    }
  };

  const convertImageToBase64 = async (url, name) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleChange = (event) => {
    setValorDetalle(upperCaseTransformer(event.target.value));
  };

  const onClickSubmit = async () => {
    let user = JSON.parse(localStorage.getItem("siam-user-data"));
    let dataPost = {
      id_modelo_padre: paramsInsert.id_modelo_padre,
      modelo_padre: paramsInsert.modelo_padre,
      detalle: valorDetalle,
      id_usuarios: user.id,
    };
    if (idNotaEdit) {
      dataPost["id"] = idNotaEdit;
    }
    var response = await postNotas(dataPost);
    //console.log("response: ",response);
    if (response.status === "true") {
      if (base64String !== "") {
        let paramsCreateImages = [
          {
            modelo_padre: "notas_internas",
            id_modelo_padre: response.wsResponse.notas_internas,
            image: base64String,
          },
        ];
        let responseImages = await postDigitalizacionesNotas(
          paramsCreateImages
        );
        if (responseImages.status !== "true") {
          setSnackbarVisible(true);
          setSeverity("warning");
          setMessage("Ocurrió un problema al insertar la imagen");
          return;
        }
      }
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage("Nota insertada con exito!!!");
      props.toggleModal();
      props.setOptions({ ...props.options, refresh: true });
    } else {
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage(response.message);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <TextField
          label="Detalle"
          variant="outlined"
          fullWidth
          margin="normal"
          value={valorDetalle}
          onChange={handleChange}
        />
      </Box>
      <Button variant="contained" component="label">
        Elegir Imagen
        <input hidden accept="image/*" type="file" onChange={uploadImages} />
      </Button>
      <Grid marginRight={"20%"}>
        <Buttons
          labelButtonSave={"Guardar"}
          labelButtonClose={"Cancelar"}
          onClickSubmit={onClickSubmit}
          buttonCerrar={props.toggleModal}
        />
      </Grid>
      <Grid>
        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          close={() => {
            setSnackbarVisible(false);
          }}
        />
      </Grid>
    </>
  );
};

import { ABMGenerico } from "src/general_components/ABMGenerico";

export const TiposMermas = () => {
    const modelo = "prd_tipo_mermas";


    return (
        <ABMGenerico modelo={modelo} titulo={"Tipos de Mermas"} />
    );
};

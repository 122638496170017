import {
    materialCells,
    materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { Buttons } from "src/general_components/Buttons";
import { DateFormComponent } from "../../../forms/customRenders/dateFormFicha";
import { SelectorClienteComponent } from "../../../forms/customRenders/selectorClientes";
import { schemaComprobantesForm } from "../../../forms/schemaComprobantesForm";
import { uiSchemaFichaForm } from "../../../forms/uiSchemaFichaForm";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";

export const FormConsultaFicha = (props = null) => {

    const [dataForm, setDataForm] = useState({ cliente: props?.clienteSeleccionado, fecha_desde: props?.fechaDesdeSelected, fecha_hasta: props?.fechaHastaSelected });
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    function obtenerPrimerDiaDelMes() {
        const year = new Date().getFullYear();
        // const month = new Date().getMonth() + 1;
        return `${year}-01-01`;
    }


    function obtenerUltimoDiaDelMes() {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const lastDay = new Date(year, month, 0).getDate();
        return `${year}-${month}-${lastDay}`;
    }

    useEffect(() => {


        if (!props.fechaDesdeSelected || !props.fechaHastaSelected) {
            setDataForm({
                ...dataForm,
                fecha_desde: !props.fechaDesdeSelected ? obtenerPrimerDiaDelMes() : dataForm.fecha_desde,
                fecha_hasta: !props.fechaHastaSelected ? obtenerUltimoDiaDelMes() : dataForm.fecha_hasta,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSubmit = async () => {
        console.log(props)
        if (!dataForm.cliente) {
            setSnackbarVisible(true);
            setSeverity("warning");
            setMessage("Debe seleccionar un cliente");
            return;
        }

        //Creamos variables de las fechas, solo se usan para las condiciones
        let fecha_desde = dataForm.fecha_desde;
        let fecha_hasta = dataForm.fecha_hasta;
        props.setFechaDesdeSelected(fecha_desde)
        props.setFechaHastaSelected(fecha_hasta)
        if (
            fecha_hasta &&
            fecha_desde &&
            (fecha_desde > fecha_hasta || fecha_desde === fecha_hasta)
        ) {
            setSnackbarVisible(true);
            setSeverity("warning");
            setMessage("Fecha incorrecta");
            return;
        }

        if (fecha_desde.length === 0) {
            delete dataForm["fecha_desde"];
        }

        if (fecha_hasta.length === 0) {
            delete dataForm["fecha_hasta"];
        }

        var array = Object.entries(dataForm);
        let criteriaComprobantes = array.map(([key, value]) => {
            let objeto = {};
            let propiedaModel = "";
            let propiedadColum = "";
            let propiedadFilter = "";
            let propiedadValue = value;
            switch (key) {
                case "cliente":
                    propiedaModel = "comprobantes_cuentas";
                    propiedadColum = "id_cuentas";
                    propiedadFilter = "equal";
                    propiedadValue = value;
                    props.setClienteSeleccionado(value)
                    break;
                case "fecha_desde":
                    propiedaModel = "comprobantes";
                    propiedadColum = "fecha_comprobante";
                    propiedadFilter = ">=";
                    propiedadValue = value;
                    break;
                case "fecha_hasta":
                    propiedaModel = "comprobantes";
                    propiedadColum = "fecha_comprobante";
                    propiedadFilter = "<=";
                    propiedadValue = value;
                    break;

                default:
                    break;
            }
            objeto["model"] = propiedaModel;
            objeto["column"] = propiedadColum;
            objeto["value"] = propiedadValue;
            objeto["filter"] = propiedadFilter;
            return objeto;
        });
        props.setcriteriaFicha(criteriaComprobantes);
        handleClose();
    };
    const handleClose = () => {
        props.cerrarModal(false);
    };

    return (
        <div>
            <JsonForms
                schema={schemaComprobantesForm}
                uischema={uiSchemaFichaForm}
                data={dataForm}
                renderers={[
                    ...materialRenderers,
                    DateFormComponent,
                    SelectorClienteComponent,
                ]}
                cells={materialCells}
                onChange={({ data, _errors }) => setDataForm(data)}
            />
            <Grid container style={{ paddingTop: "15px", justifyContent: "center" }}>
                <Buttons
                    variant="contained"
                    color="success"
                    onClickSubmit={handleSubmit}
                    labelButtonSave={"Buscar"}
                    buttonCerrar={handleClose}
                />
            </Grid>
            <SnackbarMessenger
                open={snackbarVisible}
                message={message}
                severity={severity}
                close={() => {
                    setSnackbarVisible(false);
                }}
            />
        </div>
    );
};

import datos from "src/services/datos";
import { esArray } from "src/general_components/functionEsArray"

export const getArticulos = async () => {
  const articulos = new datos("articulos");
  const response = await articulos.articulosGet();
  const resp = response.wsResponse.articulos;
  var data = [];
  for (let i = 0; i < resp.length; i++) {
    data.push(resp[i].articulos);
  }
  var listArticulos = {
    data: data,
    total_records: response.wsResponse.total,
  };

  return listArticulos;
};

export const getArticulosPaginated = async (limit, offset) => {
  const articulos = new datos("articulos");
  const response = await articulos.articulosGet(limit, offset);
  // const resp = response.wsResponse.articulos;
  // var data = [];
  // for (let i = 0; i < resp.length; i++) {
  //   data.push(resp[i].articulos);
  // }
  const resp = response.wsResponse.articulos;


  var data = [];
  for (let i = 0; i < resp.length; i++) {
    // data.push({ ...resp[i].articulos, detalle: atob(resp[i].articulos_especificacion.detalle) });
    if (resp[i].articulos_especificacion.detalle.length > 0) {
      try {
        let decodedDetail = atob(resp[i].articulos_especificacion.detalle);
        let cleanedDetail = decodedDetail.replace(/<[^>]+>/g, '');

        data.push({ ...resp[i].articulos, detalle: cleanedDetail });
      } catch (e) {
        data.push({ ...resp[i].articulos, detalle: resp[i]?.articulos_especificacion?.detalle });
      }
    } else {
      data.push({ ...resp[i].articulos, detalle: '' });
    }
  }
  var listArticulos = {
    data: data,
    total_records: response.wsResponse.total,
  };

  console.log(data)
  return listArticulos;
};

export const getArticulosPrinted = async (limit, offset, filters) => {
  const articulos = new datos("articulos");

  let criterial = filters.items.map((item, index) => ({
    model: "articulos", // Modifica esto según sea necesario
    column: item.columnField, // Utiliza el campo columnField como la columna
    value: item.value,
    filter: item.operatorValue === "contains" ? "like" : "equal" // Mapea el operadorValue a los filtros adecuados

  }));


  const response = await articulos.articulosGet(limit, offset, criterial);

  const resp = esArray(response.wsResponse.articulos);
  var data = [];


  for (let i = 0; i < resp.length; i++) {

    // data.push({ ...resp[i].articulos, detalle: atob(resp[i].articulos_especificacion.detalle) });
    if (resp[i].articulos_especificacion.detalle.length > 0) {
      try {

        let decodedDetail = base64ToUnicode(resp[i].articulos_especificacion.detalle);

        let cleanedDetail = decodedDetail.replace(/<[^>]+>/g, '');

        data.push({ ...resp[i].articulos, detalle: cleanedDetail });
      } catch (e) {

        data.push({ ...resp[i].articulos, detalle: resp[i]?.articulos_especificacion?.detalle });
      }
    } else {
      data.push({ ...resp[i].articulos, detalle: '' });
    }
  }


  return data;
};


export const createArticulos = async (datosCreate) => {
  const articulos = new datos("articulos");
  const response = await articulos.articulosCreate(datosCreate);

  return response;
};

export const updateArticulos = async (datosUpdate) => {
  const articulos = new datos("articulos");
  const response = await articulos.articulosUpdate(datosUpdate);

  return response;
};

export const deleteArticulos = async (datosDelete) => {
  const articulos = new datos("articulos");
  const response = await articulos.articulosDelete(datosDelete);

  return response;
};

export const getArticulosByIdArticulo = async (data) => {
  let limit = undefined;
  let offset = undefined;
  let criteria = {
    "column": "id",
    "filter": "equal",
    "model": "articulos",
    "value": `${data}`,
  };
  const articulos = new datos("articulos");
  const response = await articulos.articulosGet(limit, offset, criteria);

  return response;
};

const cleanUnicode = (str) => {
  const unicodeChars = /[☺☻♥♦♣♠•◘○◙♂♀♪♫☼►◄↕‼¶§▬↨↑↓→←∟↔▲▼]/g;
  return str.replace(unicodeChars, '');
};

const base64ToUnicode = (str) => {
  const binary = atob(str);
  const bytes = Uint8Array.from(binary, char => char.charCodeAt(0));
  const decoder = new TextDecoder();
  const decodedStr = decoder.decode(bytes);
  return cleanUnicode(decodedStr);
};


export const getArticulosCuentas = async (id_cuenta) => {
  const articulos = new datos("articulos");

  articulos.addCondition('articulos', 'id', 0, '>');
  articulos.join("cuentas_articulos", "id_articulos", "id", "=");
  articulos.addCondition('cuentas_articulos', 'id_cuentas', id_cuenta, '=')
  const response = await articulos.get();
  const resp = response.wsResponse.articulos;


  return resp;
};

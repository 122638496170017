import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Grid } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "src/context/AuthContext";
import { schemaBasicIdDetalle } from "src/forms/schemaBasicIdDetalle";
import { uischemaBasicIdDetalle } from "src/forms/uischemaBasicIdDetalle";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { ComponentDialog } from "src/general_components/ComponentDialog";

const localize = require("ajv-i18n");

export const EstadosCajasForm = (props) => {
  const auth = useContext(AuthContext);
  const { data,
    setCreateButton,
    listName,
    createButton,
    postData,
    setOptions,
    options } = props;
  const [estadosCajasInfo, setEstadosCajasInfo] = useState({
    id: data.id || "",
    detalle: data.detalle || "",
  });

  const [withErrors, setWithErrors] = useState(false);
  const [pushData, setPushData] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');


  //CONTROLA CUANDO SE SALE DE UN EDIT Y QUEDA CON BASURA LOS SELECTORES, COMPROBAR CUALQUIERA DE LOS DOS SELECTORES SI ES DISTINTO DE VACIO
  if (data.length === 0 && estadosCajasInfo.id !== "") {
    setEstadosCajasInfo({
      id: "",
      detalle: "",
    });
  }

  //PARA QUE SE ACTUALICE EL SELECTOR CUANDO SE EDITA
  if (data.id && estadosCajasInfo.id === "") {
    setEstadosCajasInfo({
      id: data.id || "",
      detalle: data.detalle || "",

    });
  }


  useEffect(() => {
    const submitPush = async (dataPost, auth) => {
      let responsePost = await postData(dataPost, auth);
      if (responsePost.status === 'true') {
        setCreateButton(false);
        setPushData(false);
        setOptions({ ...options, refresh: true })
        setEstadosCajasInfo({
          id: "",
          detalle: "",

        });
      } else {
        setSnackbarVisible(true);
        setMessage(responsePost.message);
        setSeverity('warning');
      }
    }
    if (pushData) {
      let dataPost = {
        id: estadosCajasInfo.id || "",
        detalle: estadosCajasInfo.detalle || "",
      }
      submitPush(dataPost, auth);
      // if(dataInfo.detalle !== ""){
      //   submitPush(dataPost, auth);

      // }else{
      //   setPushData(false);
      //   setSnackbarVisible(true);
      //   setMessage('EL campo detalle es obligatorio');
      //   setSeverity('warning');
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushData]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   postEstadosCajas(estadosCajasInfo, auth);
  // };

  //CONTROLA CUANDO SE SALE DE UN EDIT Y QUEDA CON BASURA LOS SELECTORES, COMPROBAR CUALQUIERA DE LOS DOS SELECTORES SI ES DISTINTO DE VACIO
  if (data.length === 0 && estadosCajasInfo.id !== "") {
    setEstadosCajasInfo({
      id: "",
      detalle: "",
    });
  }

  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);

    setEstadosCajasInfo(data);

    return { errors, data };
  };
  return (
    <>

      <ComponentDialog
        listName={listName}
        buttonClicked={createButton}
        setButtonClicked={setCreateButton}
        withErrors={withErrors} //Sirve para controlar el boton de crear y los errorres del formulario
        setPushData={setPushData} //Sirve para enviar los datos al servidor
        child={
          <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>
            <JsonForms
              onChange={({ errors, data }) => onChange && onChange(errors, data)}
              schema={schemaBasicIdDetalle}
              uischema={uischemaBasicIdDetalle}
              renderers={materialRenderers}
              data={estadosCajasInfo}
              cells={materialCells}
            />
          </Grid>
        }
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => { setSnackbarVisible(false) }}
        hide={8000}
      />
      {/* <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>
        <Typography variant="h4" gutterBottom component="div">
          Formulario de Estado de Cajas
        </Typography>

        <JsonForms
          onChange={({ errors, data }) => onChange && onChange(errors, data)}
          schema={schemaBasicIdDetalle}
          uischema={uischemaBasicIdDetalle}
          renderers={materialRenderers}
          data={estadosCajasInfo}
          cells={materialCells}
        />

        <Grid
          item
          xs={12}
          style={{
            marginTop: "1rem",
          }}
        >
          <Button
            color="primary"
            size="large"
            variant="contained"
            contained
            style={{
              borderRadius: 15,
              backgroundColor: "#303C54",
              color: "#C9CDD4",
              padding: "10px 20px",
              fontSize: "13px",
            }}
            onClick={handleSubmit}
            disabled={withErrors}
          >
            Enviar
          </Button>

          <Button
            color="primary"
            size="large"
            variant="contained"
            contained
            style={{
              borderRadius: 15,
              backgroundColor: "#303C54",
              color: "#C9CDD4",
              padding: "10px 20px",
              fontSize: "13px",
              marginLeft: "1rem",
            }}
            onClick={() => setButtonClicked(false)}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid> */}
    </>
  );
};

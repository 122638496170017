import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { CajasListM } from "../components/CajasListM";
import { ClienteSector } from "../components/ClienteSector";
import { SwitchCuentaGenerica } from "../components/SwitchCuentaGenerica";
import React from 'react';
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";

export function AltaCajas() {
  let url = window.location.href;
  let procesoGenerica = false;
  const arrUrl = url.split('/')
  const proceso = arrUrl[arrUrl.length - 1] // retorna el ultimo valor de la url
  var titulo = "Alta de Cajas ";
  if (proceso === "contenidos") {
    procesoGenerica = true;
    titulo = "Alta de contenidos para Cajas Genéricas"
  }

  return (

    <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 10,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          {titulo}
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <CajasProvider>
        <Grid container
          spacing={2}>
          <Grid item xs={2} >
            {!procesoGenerica && <SwitchCuentaGenerica procesoGenerica={procesoGenerica} />}
          </Grid>
          <Grid item xs={!procesoGenerica ? 10 : 12} >
            <ClienteSector />
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "15px" }}>
          <CajasListM procesoGenerica={procesoGenerica} proceso={proceso} />
        </Grid>
      </CajasProvider>
    </Grid>
  );
}

import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const TransferList = ({ availableColumns, selectedColumns, onChange }) => {
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(availableColumns);
    const [right, setRight] = React.useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value, side) => () => {
        let newChecked = [...checked];

        // valido que el tipo de contenido solo pueda seleccionarse si el id contenido ya esta tildado o en la otra lista
        if ((value === 'Lote' || value === 'Metadatos') && side === "left") {

            // Verifica si el elemento ya está en la otra lista
            const isElementInOtherList = right.includes("Tipo de contenido");

            // Verifica si se ha seleccionado el elemento específico requerido
            const isRequiredItemSelected = checked.includes("Tipo de contenido");

            // Si el elemento requerido no está seleccionado y estamos intentando seleccionar otro elemento,
            // no actualizamos el estado
            if (!isRequiredItemSelected && value !== "Tipo de contenido" && !isElementInOtherList) {
                return;
            }
        }

        if ((value === 'Lote' || value === 'Metadatos') && side === "right") {

            // // Verifica si el elemento ya está en la otra lista
            // const isElementInOtherList = right.includes("ID contenido");

            // Verifica si se ha seleccionado el elemento específico requerido
            const isRequiredItemSelected = newChecked.includes("Tipo de contenido");

            // Si el elemento requerido no está seleccionado y estamos intentando seleccionar otro elemento,
            // no actualizamos el estado
            if (isRequiredItemSelected) {
                return;
            }
        }

        if (value === 'Tipo de contenido' && side === 'right') {

            // Verificar si 'Tipo de contenido' y 'Metadatos' están en la lista
            const isMetadatosSelected = right.includes('Metadatos');
            const isLoteSelected = right.includes('Lote');
            const existeTipoContenido = newChecked.indexOf(value);


            if (isMetadatosSelected || isLoteSelected) {
                const currentIndex2 = newChecked.indexOf('Metadatos');


                // newChecked = [...checked];

                if (existeTipoContenido === -1) {
                    if (isMetadatosSelected) {
                        newChecked.push('Metadatos');
                    }
                    if (isLoteSelected) {
                        newChecked.push('Lote');
                    }

                } else {
                    if (isMetadatosSelected) {
                        newChecked.splice(currentIndex2, 1);
                    }
                    if (isLoteSelected) {
                        const currentIndex1 = newChecked.indexOf('Lote');
                        newChecked.splice(currentIndex1, 1);
                    }

                }

                // setChecked(newChecked);
            }

        }


        const currentIndex = newChecked.indexOf(value);

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        const newCheckedSinDuplicados = Array.from(new Set(newChecked));
        setChecked(newCheckedSinDuplicados);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
        onChange(right.concat(left));
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        onChange(right.concat(leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        onChange(not(right, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
        onChange([]);
    };

    function ordenarArray(originalArray, order) {
        // Crear un nuevo array vacío para almacenar los elementos ordenados
        const newArray = [];

        // Recorrer el orden deseado y agregar los elementos existentes al nuevo array
        for (const element of order) {
            const foundElement = originalArray.find(item => item === element);

            if (foundElement) {
                newArray.push(foundElement);
            }
        }

        // Recorrer el array original y agregar los elementos que no estén en el nuevo array
        for (const element of originalArray) {
            if (!newArray.includes(element)) {
                newArray.push(element);
            }
        }

        return newArray;
    }

    const customList = (items, side, label) => {
        // Definir el orden deseado
        const ordenDeseado = [
            'ID caja',
            'Cliente',
            'Sector',
            'Referencia/Nro Anterior',
            'Tipo de caja(id)',
            'Ubicacion',
            'ID contenido',
            'Tipo de contenido',
            'Metadatos',
        ];

        // Utilizar la función para ordenar los items
        const itemsOrdenados = ordenarArray(items, ordenDeseado);

        return (
            <Paper sx={{ width: 350, height: 330, overflow: 'auto' }}>
                <Typography variant="h6" sx={{ fontSize: 13, fontFamily: 'Roboto, sans-serif', color: '#0A395F', }}>{label}</Typography>
                <List dense component="div" role="list">
                    {itemsOrdenados.map((value) => {
                        const labelId = `transfer-list-item-${value}-label`;

                        return (
                            <ListItemButton
                                key={value}
                                role="listitem"
                                onClick={handleToggle(value, side)}
                            >

                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>

                                <ListItemText sx={{ fontSize: 10, fontFamily: 'Roboto, sans-serif', color: '#0A395F', }} id={labelId} primary={value} />
                            </ListItemButton>
                        );
                    })}
                </List>
            </Paper>
        )
    };

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList(left, 'left', "Filtros")}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Tooltip title="Seleccionar Todo">
                        <span>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllRight}
                                disabled={left.length === 0}
                                aria-label="move all right"
                            >
                                ≫
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title="Seleccionar Tildadas">
                        <span>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title="Deseleccionar Tildadas">
                        <span>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title="Deseleccionar Todo">
                        <span>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllLeft}
                                disabled={right.length === 0}
                                aria-label="move all left"
                            >
                                ≪
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item>{customList(right, 'right', "Seleccionadas")}</Grid>
        </Grid>
    );
};

export default TransferList;

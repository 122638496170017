import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from "@jsonforms/core";
import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const SelectorAccounts = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const dataDefaultsOptions = jsonForm.core.data.accounts.filter(option => typeof option.label === 'string');;
  const dataDefaultsAssigned = jsonForm.core.data.accountAssigned;

  let data = [];
 
  if(dataDefaultsAssigned){
    data =dataDefaultsOptions.filter((dataDefault) =>
    dataDefaultsAssigned.some((obj) => obj.value === dataDefault.value)
  )
  }
  const [accountsSelected, setAccountsSelected] = React.useState(
    data || []
  );

  const handleChanges = (event, selectedOptions) => {
    setAccountsSelected(selectedOptions);
  };
  // agrego las cuentas a la data general del formulario
  useEffect(() => {
    if (Array.isArray(accountsSelected)) {
      props.handleChange(
        props.path,
        accountsSelected.map((account) => account.value)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsSelected]);
  return (
    <>
      <FormControl
        sx={{ width: "100%", paddingBottom: "10px" }}
        disabled={!dataDefaultsOptions}
      >
        <Autocomplete
  multiple
  value={accountsSelected || []}
  onChange={handleChanges}
  options={dataDefaultsOptions || []}
  getOptionLabel={(option) => option.value+' - '+option.label || ""}
  renderInput={(params) => (
    <TextField {...params} label="Cuentas" variant="outlined" />
  )}
  renderTags={(value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        key={option.value}
        label={option.label}
        {...getTagProps({ index })}
      />
    ))
  }
/>
      </FormControl>
    </>
  );
};

const SelectorAccountsTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("array"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorAccounts";
      }
      return false;
    })
  )
);

// hand this over together with the provided `renderers` to the `JsonForms` component
export const SelectorAccountsComponent = {
  renderer: withJsonFormsControlProps(SelectorAccounts),
  tester: SelectorAccountsTester,
};

import { esArray } from "src/general_components/functionEsArray";
import datos from "src/services/datos";

export const getTerminalData = async () => {
  
  const objectTerminalData = new datos("terminal");
  objectTerminalData.addCondition("terminal","id",0,">");
  const response = await objectTerminalData.get();
  const terminal = response.wsResponse.terminal;
  const arrTerminal = esArray(terminal);

  return arrTerminal;
};


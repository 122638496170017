import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from "@mui/material";

export const SimpleBackdrop = (props = null) => {
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = React.useState(props.open || false);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h4" style={{ color: "white" }}>Aguarde un momento...</Typography>
      </Backdrop>
    </div>
  );
}
import datos from "src/services/datos";

export const getPropositoCalculoPrecio = async (auth) => {
    const proposito_calculo_precios = new datos("proposito_calculo_precios");
    proposito_calculo_precios.addCondition("proposito_calculo_precios", "id", 0, ">");
    const response = await proposito_calculo_precios.get();
  
    const res = response.wsResponse.proposito_calculo_precios;
    // Si es un array se mantiene como estaba
    if (Array.isArray(res)) {
      var arr = res;
      // Si no es un array... primero se verifica que tenga valores ... si no los tiene
    } else if (typeof res === "undefined") {
      // se pasa un array vacio
      arr = [];
    } else {
      // si trae un solo elemento , es un objeto
      arr = [res]; // se lo convierte a array
    }
    return arr;
  };
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { ContenidoRetiroDevoluciones } from '../components/ContenidoRetiroDevoluciones';
import React from 'react';
import CustomBreadcrumbs from 'src/general_components/BreadCrumbsCustom';


export const ContenidoRetiro = () => {
    let url = window.location.href;
    const arrUrl = url.split('/')
    const proceso = arrUrl[arrUrl.length - 1] // retorna el ultimo valor de la url
    var titulo = "";
    if (proceso === "retiro") {
        titulo = "Retiro de Contenidos"
    } else if (proceso === "devolucion") {
        titulo = "Devolucion de Contenidos"
    }

    return (
        <Grid style={{ marginBottom: "50px", paddingTop: 10 }}>
            <Grid
                style={{
                    background: "white",
                    display: 'flex',
                    justifyContent: 'space-between', // Para alinear a los extremos
                    alignItems: 'center', // Para alinear verticalmente
                    backgroundColor: 'white',
                    fontFamily: 'Roboto, sans-serif',
                    borderRadius: 10,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    marginBottom: 20,
                    height: 50,
                }}
            >
                <Typography
                    align="left"
                    variant="h2"
                    component="div"
                    sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
                >
                    {titulo}
                </Typography>
                <CustomBreadcrumbs></CustomBreadcrumbs>
            </Grid>
            <CajasProvider>
                <ContenidoRetiroDevoluciones style={{ padding: " 0px 0px 0px 0px" }} />


            </CajasProvider>


        </Grid>
    );
}
import React, { useState, useContext, useEffect } from 'react';
import {
  TextField,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Snackbar,
  Grid,
} from '@mui/material';
import { SelectRetiroPrioridad } from './SelectRetiroPrioridad';
import { CajasContext } from 'src/context/CajasContext/CajasContext';
import { CajasFormUbicacionTemporal } from './CajasFormUbicacionTemporal';
import { FormUbicaciones } from './FormUbicacionesCajas';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TheLayoutContext } from "src/context/TheLayoutContext";
import { SelectCuentaContacto } from './SelectCuentaContacto';
import { esArray } from "src/general_components/functionEsArray";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import AWS from 'aws-sdk';
import { configS3 } from "src/configs/config";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { SelectAgregarDinamico } from 'src/general_components/SelectAgregarDinamico';
import { getCuentasLocalizacion } from "../helpers/ContactosData";
import LocalidadSelector from 'src/general_components/SelectLocalidades';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getDepositos } from '../helpers/UbicacionCajasData';
import { getContactos } from '../helpers/ContactosData';
import moment from "moment";
import { SimpleDialogComponent } from 'src/general_components/SimpleDialogComponentRC';
import { RemitosList } from './RemitosList';
import { ConfimDialogComponent } from 'src/general_components/ConfirmDialogComponent';

export const CajasFormProcesosVarios = (data = null) => {
  const { proceso, procesar, toggleModal, ajuste } = data;
  const {
    setTipoRetiroMovimientoSeleccionado, tipoRetiroMovimientoSeleccionado, datosFormUbicaciones, setDatosFormUbicaciones
    , aProcesar, setAProcesar, selectionModel, setSelectionModel, desmarcar, setDesmarcar, setMessage, setSeverity,
    setSnackbarVisible } = useContext(CajasContext);

  const { setS3Json, s3Values, procesoSeleccionado,
    selection, s3Json, setButtonMarcaDisabled,
    setButtonDesmarcaDisabled, clienteId, subProceso, subProcesoLocal, setValueSubprocesoLocal,
    clienteIdLocal, setClienteIdLocal, setButtonMarcarTodoDisabled } = useContext(TheLayoutContext)

  // eslint-disable-next-line no-unused-vars
  const [solicitado, setSolicitado] = useState('');
  const [detalle, setDetalle] = useState('');
  const [contactoSelect, setContactoSelect] = useState(null);
  const [direccionSelect, setDireccionSelect] = useState(null);
  const [newDireccion, setNewDireccion] = useState(false);
  const [guardadoPorSelect, setGuardadoPorSelect] = useState(null);
  const [newCuentaContactoGuardadoPor, setNewCuentaContactoGuardadoPor] = useState(false);
  const [newCuentaContactoPersonaControl, setNewCuentaContactoPersonaControl] = useState(false);
  const [personaControlSelect, setPersonaControlSelect] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [keysCLiente, setKeysCLiente] = useState([]);
  const [options, setOptions] = useState([]);
  const [habilitarSelect, setHabilitarSelect] = useState(true);
  const [marcasClientesS3, setMarcasClientesS3] = useState([]);
  const [disabledBtnCopiar, setDisabledBtnCopiar] = useState(true);
  const [optionsDeposito, setOptionsDeposito] = useState([]);
  const [optionsContactosEsOwner, setOptionsContactosEsOwner] = useState([]);
  const [cuentaEsOwner, setCuentaEsOwner] = useState(false);
  const [fecha, setFecha] = useState(null);
  const [valueSubproceso, setValueSubproceso] = useState(0);
  const [subProcesoDisabled, setSubProcesoDisabled] = useState(false);
  const [optionsSubProceso, setOptionsSubProceso] = useState([]);
  const [nroRemito, setNroRemito] = useState("");
  const [deposito, setDeposito] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [cuentasLocalizaciones, setCuentasLocalizaciones] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [disabledConfirmar, setDisabledConfirmar] = useState(true);
  const [modalStateRemito, setModalStateRemito] = useState(false);
  const [nroRemitoDevolucion, setNroRemitoDevolucion] = useState("");
  const [modalStateConfirm, setModalStateConfirm] = useState(false);


  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regexp.test(details);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleDetalleChange = (event) => {
    setDetalle(event.target.value);
  };

  const handleDesmarcarChange = (event) => {
    setDesmarcar(event.target.checked);
  };

  const handleAProcesarChange = (event) => {
    setAProcesar(event.target.value);
    let valores = []

    switch (event.target.value) {
      case "1":
        if (marcasClientesS3) {
          valores = [...marcasClientesS3, ...selection];
        } else {
          valores = [...selection];
        }
        setDisabledBtnCopiar(false)
        break;
      case "2":
        valores = selection;
        setDisabledBtnCopiar(false)
        break;
      case "3":
        valores = marcasClientesS3
        if (valores) {
          setDisabledBtnCopiar(false)
        } else {
          setDisabledBtnCopiar(true)
        }

        break;
      default:
        valores = []
        break;
    }
    setSelectionModel(valores);
  };

  const handleCancelar = () => {
    toggleModal();
    // Lógica para manejar la acción de cancelar
  };
  const handleConfirmar = () => {
    let detalleConcat = "";
    let nodoExtra = {};
    if (proceso?.id === 3 || proceso?.id === 4) {
      detalleConcat = detalle;
    }
    if (proceso?.id === 5) {
      // Verificar y asignar nro_comprobante si existe
      if (nroRemito) {
        nodoExtra.comprobantes_extra_data = {
          ...nodoExtra.comprobantes_extra_data,
          nro_comprobante: {
            field: "id_comprobantes",
            value: nroRemito
          }
        };
      }

      if (nroRemitoDevolucion) {
        nodoExtra.comprobantes_extra_data = {
          ...nodoExtra.comprobantes_extra_data,
          remito_manual_devolucion: {
            field: "Remito manual devolucion",
            value: nroRemitoDevolucion
          }
        };
      }

      // Verificar y asignar persona_control si existe
      if (personaControlSelect?.descripcion) {
        nodoExtra.comprobantes_extra_data = {
          ...nodoExtra.comprobantes_extra_data,
          persona_control: {
            field: "id_cuentas_contactos",
            value: personaControlSelect.id
          }
        };
      }

      // Verificar y asignar deposito si existe
      if (deposito?.id) {
        nodoExtra.comprobantes_extra_data = {
          ...nodoExtra.comprobantes_extra_data,
          deposito: {
            field: "id_owner_localizaciones",
            value: deposito.id
          }
        };
      }

      // Verificar y asignar fecha si existe
      if (fecha?.$d) {
        const fechaFormat = moment(fecha?.$d).format("YYYY-MM-DD");
        nodoExtra.comprobantes_extra_data = {
          ...nodoExtra.comprobantes_extra_data,
          fecha: {
            field: "fecha",
            value: fechaFormat
          }
        };
      }

      // Verificar y asignar guardado_por si existe
      if (guardadoPorSelect?.descripcion) {
        nodoExtra.comprobantes_extra_data = {
          ...nodoExtra.comprobantes_extra_data,
          guardado_por: {
            field: "id_cuentas_contactos",
            value: guardadoPorSelect.id
          }
        };
      }

      // Verificar si se añadió algún dato al objeto nodoExtra
      nodoExtra = Object.keys(nodoExtra).length > 0 ? nodoExtra : null

    }

    if (proceso?.id === 2) {
      detalleConcat = detalle;
    }

    // Lógica para manejar la acción de confirmar
    procesar(detalleConcat, solicitado, detalle, contactoSelect, direccionSelect, nodoExtra);
    // handleDesmarcar();
  };


  const toggleModalRemito = () => {
    setModalStateRemito(!modalStateRemito);
    // Lógica para manejar la acción de cancelar
  };

  const obtenerProceso = () => {
    let proceso = '';
    if (procesoSeleccionado && procesoSeleccionado?.label !== 'Consulta General') {
      const palabra = procesoSeleccionado.label.split(/[ /]/);
      // Usamos una expresión regular para dividir por espacio o barra
      const filtro = palabra.filter(word => word.toLowerCase() !== 'de');
      proceso = filtro.join('_').toLowerCase();
    } else {
      let url = window.location.href;
      const arrUrl = url.split("/");
      proceso = arrUrl[arrUrl.length - 1];
      if (proceso.includes("?")) {

        const arrUrl = url.split("?");
        proceso = arrUrl[arrUrl.length - 1];

        const arrUrls = proceso.split("=");
        proceso = arrUrls[arrUrls.length - 2];

        proceso = proceso === 'mudanza' ? 'mudanza_cajas' : proceso
      } else {
        proceso = proceso === 'proceso' ? 'ubicacion_cajas' : proceso
      }
    }
    return proceso
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const cargaValoresS3 = async () => {
      try {
        AWS.config.update(configS3);
        const bucketName = configS3.bucketName;
        const ownerCode = JSON.parse(localStorage.getItem("clientData")).owner_code.toLowerCase();
        const key = ownerCode + '-marks.json';
        const s3 = new AWS.S3();
        const params = {
          Bucket: bucketName,
          Key: key,
        };
        let datos = {};
        const dataS3 = await s3.getObject(params).promise();
        const jsonString = dataS3.Body.toString('utf-8');
        datos = JSON.parse(jsonString).procesos;
        setS3Json(datos);
        generateOptionsSubProceso(procesoSeleccionado, datos)
      } catch (error) {
        console.log('Error al descargar o parsear el archivo JSON desde S3:', error);
        return Promise.reject(error);
      }
    };

    const obtenerDepositos = async () => {
      let depositos = esArray(JSON.parse(localStorage.getItem("siam-owner_localizaciones"))?.owner_localizaciones);
      if (!depositos) {
        depositos = await getDepositos();
      }
      setOptionsDeposito(depositos);
    };
    obtenerDepositos();
    cargaValoresS3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const obtenerCuentas = async () => {
      let cuentas = esArray(JSON.parse(localStorage.getItem("siam-cuentas"))?.cuentas);
      const objetoConIdOwnerUno = cuentas.find(objeto => objeto.es_owner === "1");
      setCuentaEsOwner(objetoConIdOwnerUno);
      let response = objetoConIdOwnerUno ? await getContactos(objetoConIdOwnerUno?.id) : [];
      if (response.length < 1) {
        setGuardadoPorSelect(null)
      }
      setOptionsContactosEsOwner(response);
      if (newCuentaContactoGuardadoPor) {
        const idBuscado = newCuentaContactoGuardadoPor; // Puedes cambiar esto al id que estás buscando
        const objetoEncontrado = response.find(objeto => objeto.id === idBuscado);
        if (objetoEncontrado) {
          setGuardadoPorSelect(objetoEncontrado);
        } else {
          console.log("Objeto no encontrado");
        }
      }
    };
    obtenerCuentas();
  }, [newCuentaContactoGuardadoPor]);

  useEffect(() => {
    const obtenerCuentas = async () => {
      let cuentas = esArray(JSON.parse(localStorage.getItem("siam-cuentas"))?.cuentas);
      const objetoConIdOwnerUno = cuentas.find(objeto => objeto.es_owner === "1");
      setCuentaEsOwner(objetoConIdOwnerUno);
      let response = objetoConIdOwnerUno ? await getContactos(objetoConIdOwnerUno?.id) : [];
      if (response.length < 1) {
        setPersonaControlSelect(null)
      }
      setOptionsContactosEsOwner(response);
      if (newCuentaContactoPersonaControl) {
        const idBuscado = newCuentaContactoPersonaControl; // Puedes cambiar esto al id que estás buscando
        const objetoEncontrado = response.find(objeto => objeto.id === idBuscado);
        if (objetoEncontrado) {
          setPersonaControlSelect(objetoEncontrado);
        } else {
          console.log("Objeto no encontrado");
        }
      }
    };
    obtenerCuentas();
  }, [newCuentaContactoPersonaControl]);

  const generateOptionsSubProceso = (proceso, datos) => {
    let nombre = JSON.parse(localStorage.getItem("siam-user-data")).nombre;
    let apellido = JSON.parse(localStorage.getItem("siam-user-data")).apellido;
    let userId = JSON.parse(localStorage.getItem("siam-user-data")).id;
    let userName = nombre + ' ' + apellido;
    let user = obtenerInicialesMayusculas(userName)
    let process = obtenerInicialesMayusculas(proceso.label)
    let procesoActual = obtenerProceso(proceso);
    let originalArray = [];
    if (datos && datos[procesoActual]?.[userId]) {
      originalArray = Object.keys(datos[procesoActual][userId]);
    }
    const newArray = originalArray?.map((label, index) => ({
      id: index + 1,
      label: label,
      value: index + 1,
      estado: '(Existe)',
    }));

    if (newArray.length < 2) {
      if (newArray.length === 0) {
        newArray.push({
          id: newArray.length + 1,
          label: user + '-' + process + '-' + (newArray.length + 1),
          value: newArray.length + 1,
          estado: '(Nuevo)',
        });
      }
      setSubProcesoDisabled(true)
      onChangeSuproceso(newArray[0])
    } else {
      const primerSubproceso = newArray.filter(objeto => objeto.label === subProceso);
      onChangeSuproceso(primerSubproceso[0])
      setOptionsSubProceso(newArray)
    }
  }

  const onChangeSuproceso = async (subproceso, value) => {
    setButtonMarcaDisabled(true)
    setButtonDesmarcaDisabled(true)
    setButtonMarcarTodoDisabled(true)
    setValueSubproceso(subproceso)
    setValueSubprocesoLocal(subproceso?.label)
    let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;
    let procesoActual = obtenerProceso();
    if (procesoActual === 'devolucion') {
      procesoActual = 'devolucion_contenidos';
    } else if (procesoActual === 'retiro') {
      procesoActual = 'retiro_contenidos';
    }
    let keysUnicas = []
    if (s3Json && s3Json[procesoActual]?.[id_user]?.[subproceso?.label]) {
      keysUnicas = Object.keys(s3Json[procesoActual][id_user][subproceso.label]);
    }
    setKeysCLiente(keysUnicas);
    setIsLoadingData(false)
  };

  const obtenerInicialesMayusculas = (palabra) => {
    // Dividir el nombre completo en palabras
    const palabras = palabra.split(' ');
    // Obtener las primeras letras de cada palabra y convertirlas a mayúsculas
    const inicialesMayusculas = palabras.map(palabra => palabra.charAt(0).toUpperCase());
    // Unir las iniciales para formar el resultado
    return inicialesMayusculas.join('');
  }

  useEffect(() => {
    let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;
    let procesoActual = obtenerProceso();
    let tipo_id = 'id';
    try {
      let newClientes = [];
      let clients = esArray(JSON.parse(localStorage.getItem("siam-cuentas_personas_juridicas")).cuentas_personas_juridicas);
      if (!keysCLiente.includes(clienteId.toString())) {
        // No existe, se agrega
        keysCLiente.push(clienteId.toString());
      } else {
        // Si existe se reemplaza
        const indiceExistente = keysCLiente.indexOf(clienteId.toString());
        keysCLiente[indiceExistente] = clienteId.toString();
      }
      const clientsFiltrados = clients.filter(objeto => keysCLiente.includes(objeto.id_cuentas));
      newClientes = Object.entries(clientsFiltrados).map(([key, value]) => {
        const lengthFromS3 = s3Json?.[procesoActual]?.[id_user]?.[subProcesoLocal]?.[value.id_cuentas]?.[tipo_id]?.[0]?.length || 0;
        const cantidad = lengthFromS3 + parseInt(selection.length, 10);
        let objeto = value;
        objeto["label"] = value.razon_social;
        objeto["cantidad"] = cantidad
        return objeto;
      });
      setClientes(newClientes);
      changeCLiente(newClientes[0]);
    } catch (error) {
      // Manejar errores si es necesario
      console.log('Error en getClientes:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysCLiente]);


  const changeCLiente = async (clienteSelect) => {
    setClienteIdLocal(clienteSelect);
    let id_user = JSON.parse(localStorage.getItem("siam-user-data")).id;
    let procesoActual = obtenerProceso();
    let cantidadMarcadas2 = 0;
    let cantidadTotal2 = 0;
    let tipo_id = 'id';
    let marcasCliente = 0
    if (procesoActual === 'devolucion') {
      procesoActual = 'devolucion_contenidos';
    } else if (procesoActual === 'retiro') {
      procesoActual = 'retiro_contenidos';
    }
    if (s3Json && s3Json[procesoActual]?.[id_user]?.[subProcesoLocal]?.[clienteSelect?.id_cuentas]?.[tipo_id]) {
      marcasCliente = s3Json[procesoActual]?.[id_user]?.[subProcesoLocal]?.[clienteSelect?.id_cuentas]?.[tipo_id]
    }
    cantidadMarcadas2 = marcasCliente ? marcasCliente[0]?.length : 0;
    setMarcasClientesS3(marcasCliente ? marcasCliente[0] : false)
    cantidadTotal2 = parseInt(cantidadMarcadas2, 10) + parseInt(selection.length, 10)
    setOptions([
      { id: '3', label: "Solo marcadas (" + cantidadMarcadas2 + ")" },
      { id: '2', label: "Solo tildadas (" + selection.length + ")" },
      { id: '1', label: "Todas (" + cantidadTotal2 + ")" },
    ])
    setHabilitarSelect(false)
  };

  const copiarTodos = async () => {
    let registrosSeleccionados = [];
    if (aProcesar === '2' || aProcesar === '3') {
      if (aProcesar === '2') {
        registrosSeleccionados = selectionModel.filter(item => !s3Values.includes(item));
      } else if (aProcesar === '3') {
        registrosSeleccionados = selectionModel.filter(item => s3Values.includes(item));
      }
    } else {
      registrosSeleccionados = selectionModel
    }
    const idsSeparadosPorComa = registrosSeleccionados.join(',');
    copiarAlPortapapeles(idsSeparadosPorComa);
  };

  const copiarAlPortapapeles = (texto) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(texto).then(
        () => {
          setOpenSnackbar(true);
          console.log('Texto copiado al portapapeles');
          // setSnackbarVisible(false);
        },
        (err) => {
          console.log('Error al copiar al portapapeles: ', err);
        }
      );
    }
  };

  // Función personalizada de filtrado para buscar dentro del objeto cliente por id y razon_social
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => {
      const label = option.label.toLowerCase();
      const inputValueLowerCase = inputValue.toLowerCase();
      const idString = option.id_cuentas.toString().toLowerCase();
      return label.includes(inputValueLowerCase) || idString.includes(inputValueLowerCase);
    });
  };

  const customComponents = {
    id_localidad: {
      component: LocalidadSelector,
    }
  };

  const modificarDescripcion = (obj) => {
    const newDescription = `${obj.id} - ${obj.domicilio} - ${obj.descripcion}`;
    return { ...obj, descripcion: newDescription };
  };


  useEffect(() => {
    const getCuentasLocalizaciones = async () => {
      setIsLoadingData(true);
      let response = clienteIdLocal?.id_cuentas ? await getCuentasLocalizacion(clienteIdLocal?.id_cuentas) : [];
      if (response.length < 1) {
        setDireccionSelect(null)
      }
      const arrayDescripcionModificado = response.map(obj => {
        const newDescription = `${obj.id} - ${obj.domicilio} - ${obj.descripcion}`;
        return { ...obj, descripcion: newDescription };
      });
      setCuentasLocalizaciones(arrayDescripcionModificado);
      if (newDireccion) {
        const idBuscado = newDireccion; // Puedes cambiar esto al id que estás buscando
        const objetoEncontrado = response.find(objeto => objeto.id === idBuscado);
        if (objetoEncontrado) {
          const objDescripcionConcant = modificarDescripcion(objetoEncontrado);
          setDireccionSelect(objDescripcionConcant);
        } else {
          console.log("Objeto no encontrado");
        }
      }
      setIsLoadingData(false);
    };
    getCuentasLocalizaciones();
  }, [clienteIdLocal, newDireccion, setDireccionSelect]);


  const handleNroRemitoChange = (event, newValue) => {
    setNroRemito(newValue);
  };

  const handleNroRemitoDevolucionChange = (event) => {
    setNroRemitoDevolucion(event.target.value);
  };

  const handleDateChange = (newValue) => {
    setFecha(newValue);
  };

  const handleDepositoChange = (newValue) => {
    setDeposito(newValue); // Aquí estableces el nuevo valor del depósito en el estado
  };

  const dialogChild = <RemitosList id_cuentas={clienteIdLocal?.id} handleClose={toggleModalRemito} setValue={setNroRemito} />;

  const toggleModalConfirm = () => {
    setModalStateConfirm(!modalStateConfirm);
};

  return (
    <Grid>
      <form>
        <Grid container spacing={2}>
          {isLoadingData ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>) : (

            <React.Fragment>
              {!subProcesoDisabled && (
                <Grid item xs={6}>
                  <Autocomplete
                    size='small'
                    id="checkboxes-tags-demo2"
                    options={optionsSubProceso}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={valueSubproceso ? valueSubproceso : null}
                    groupBy={(option) => option.category}
                    // disabled={subProcesoDisabled}
                    onChange={(e, selectedOption) => {
                      onChangeSuproceso(selectedOption);
                    }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, data) => (
                      <Typography key={data.id} {...props} variant="body1" style={{ backgroundColor: data.color }}>
                        {data.label}
                      </Typography>
                    )}
                    onClose={(event, reason) => {
                      if (reason === 'toggleInput') {
                        // Se activa cuando se hace clic en la "x" para limpiar el valor
                        onChangeSuproceso(null); // Limpia el valor
                      }
                    }}
                    style={{ marginTop: '8px' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Sub Proceso"
                        placeholder="Sub Proceso"
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={subProcesoDisabled ? 12 : 6}>
                <Autocomplete
                  size="small"
                  value={clienteIdLocal ? clienteIdLocal : null}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    changeCLiente(newValue);
                  }}
                  id="controllable-states-demo1"
                  options={clientes}
                  filterOptions={filterOptions} // Aquí proporcionamos la función de filtrado personalizada
                  style={{ marginTop: '8px' }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      {option.id_cuentas + ' - ' + option.razon_social + ' ( ' + option.cantidad + ' ) '}
                    </Box>
                  )}
                  onClose={(event, reason) => {
                    if (reason === 'toggleInput') {
                      // Se activa cuando se hace clic en la "x" para limpiar el valor
                      changeCLiente(null); // Limpia el valor
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cliente"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </Grid>
            </React.Fragment>
          )}

          {proceso?.id !== 6 && proceso?.id !== 7 && proceso?.id !== 8 && (<>
            <Grid item xs={12}>

              <SelectCuentaContacto idCuenta={clienteIdLocal} contactoSelect={contactoSelect} setContactoSelect={setContactoSelect} />


            </Grid>
            <Grid item xs={12}>
              <SelectAgregarDinamico
                labelSelect={"Direcciones"}
                elementoSelect={direccionSelect}
                setElementoSelect={setDireccionSelect}
                disabledButtonAgregar={false}
                labelButtonAgregar={"Direccion"}
                modalAgregarName={"Direcciones"}
                options={cuentasLocalizaciones || []}
                loading={false}
                handleSubmitAgregar={toggleModal}
                modelo="cuentas_localizaciones"
                valuesDefaultForm={{ id_cuentas: clienteIdLocal?.id_cuentas }}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnackbarVisible={setSnackbarVisible}
                setNewElementAdd={setNewDireccion}
                customComponents={customComponents}
                inputsObligatorios={["id_tipo_localizacion", "id_localidad", "descripcion"]}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Detalle"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={detalle}
                onChange={handleDetalleChange}
              />
            </Grid> </>)}

          {proceso?.id === 5 && (<>
            <Grid item xs={12}>
              <Grid container spacing={0.5} alignItems="center">
                <Grid item xs={4} >
                  <TextField label="Remito de salida" value={nroRemito} onChange={handleNroRemitoChange} size="small" disabled fullWidth />
                </Grid>
                <Grid item xs={1} > {/* Reduje el tamaño del Grid que contiene el botón */}
                  <Grid container> {/* Alinea el botón a la derecha */}
                    <Tooltip title="Buscar">
                      <Button variant="contained" onClick={() => toggleModalRemito()}> {/* Ajusta el ancho del botón */}
                        <SearchIcon /> {/* Icono de la lupa */}
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <SelectAgregarDinamico
                    labelSelect={"Persona Control"}
                    elementoSelect={personaControlSelect}
                    setElementoSelect={setPersonaControlSelect}
                    disabledButtonAgregar={false}
                    labelButtonAgregar={"Contacto"}
                    modalAgregarName={"Contacto"}
                    options={optionsContactosEsOwner || []}
                    loading={false}
                    handleSubmitAgregar={toggleModal}
                    modelo="cuentas_contactos"
                    valuesDefaultForm={{ id_cuentas: cuentaEsOwner?.id }}
                    setMessage={setMessage}
                    setSeverity={setSeverity}
                    setSnackbarVisible={setSnackbarVisible}
                    setNewElementAdd={setNewCuentaContactoPersonaControl}
                    inputsObligatorios={["descripcion"]}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={0.5} alignItems="center">

                <Grid />
                <Grid item xs={8} >
                  <FormControl sx={{ minWidth: "100%" }} size="small">
                    <Autocomplete
                      size="small"
                      value={deposito}
                      onChange={(event, newValue) => {
                        handleDepositoChange(newValue); // Llama a la función de manejo de cambio con el nuevo valor seleccionado
                      }}
                      options={optionsDeposito || []}
                      getOptionLabel={(option) => option.detalle || ""}
                      isOptionEqualToValue={(option, value) => option.id === value.id} // Personaliza la comparación
                      renderInput={(params) => (
                        <TextField {...params} label={"Deposito"} />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4} >
                  <TextField
                    label="Remito de devolución"
                    value={nroRemitoDevolucion}
                    onChange={handleNroRemitoDevolucionChange}
                    size="small"
                    fullWidth
                    type="number" // Agregar tipo numérico
                  />
                </Grid>
              </Grid>


            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={0.5} alignItems="center">
                <Grid item xs={5} >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl
                      variant="standard"
                      sx={{ width: "100%" }}
                    >
                      <DatePicker
                        label={"Fecha"}
                        inputFormat="DD-MM-YYYY"
                        value={fecha}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField size="small"
                          sx={{ minWidth: "100%" }} {...params} />}
                        disabled={false}
                      />
                    </FormControl>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <SelectAgregarDinamico
                    labelSelect={"Guardado Por"}
                    elementoSelect={guardadoPorSelect}
                    setElementoSelect={setGuardadoPorSelect}
                    disabledButtonAgregar={false}
                    labelButtonAgregar={"Contacto"}
                    modalAgregarName={"Contacto"}
                    options={optionsContactosEsOwner || []}
                    loading={false}
                    modelo="cuentas_contactos"
                    valuesDefaultForm={{ id_cuentas: cuentaEsOwner?.id }}
                    setMessage={setMessage}
                    setSeverity={setSeverity}
                    setSnackbarVisible={setSnackbarVisible}
                    setNewElementAdd={setNewCuentaContactoGuardadoPor}
                    inputsObligatorios={["descripcion"]}
                  />
                </Grid>
              </Grid>
            </Grid>



          </>)}



          <Grid item xs={4}>
            <FormControlLabel
              sx={{ mt: 1, minWidth: "100%" }}
              control={
                <Switch
                  checked={desmarcar}
                  onChange={handleDesmarcarChange}
                  color="primary"
                />
              }
              label="Desmarcar"
            />
          </Grid>
          {proceso?.id === 2 && (

            <Grid item xs={!isMobileDevice ? 4 : 4} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>
              <SelectRetiroPrioridad
                disabledSelector={ajuste ? true : false}
                setTipoRetiroMovimientoSeleccionado={
                  setTipoRetiroMovimientoSeleccionado
                }
                tipoRetiroMovimientoSeleccionado={
                  tipoRetiroMovimientoSeleccionado
                }
              />
            </Grid>
          )}
          <Grid item xs={4}>

            <FormControl sx={{ mt: 1, minWidth: "100%" }} fullWidth variant="outlined" size="small">
              <InputLabel id="aProcesarLabel">A procesar</InputLabel>
              <Select
                labelId="aProcesarLabel"
                value={aProcesar ? aProcesar : ''}
                onChange={handleAProcesarChange}
                label="A procesar"
                disabled={habilitarSelect}
              >

                {options && options.length > 0 ? (
                  options.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                    >
                      {option.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={0} value={1} >
                    {"Debe seleccionar un cliente"}
                  </MenuItem>)}
              </Select>
            </FormControl>
          </Grid>



          {(proceso?.id === 6 || proceso?.id === 8) && (
            <Grid item xs={12}>
              <FormUbicaciones buttonEnviar={false} data={datosFormUbicaciones} setData={setDatosFormUbicaciones} valorUbicacionTemporal={false} />
            </Grid>)}
          {proceso?.id === 7 && (
            <Grid item xs={12}>
              <CajasFormUbicacionTemporal />
            </Grid>)}

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={copiarTodos}
                  fullWidth
                  startIcon={<ContentCopyIcon />}
                  style={{ fontSize: '13px', fontFamily: 'Roboto, sans-serif', }}
                  disabled={disabledBtnCopiar}
                >
                  Copiar cajas
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={handleCancelar}
                  fullWidth
                  color='error'
                  startIcon={<CancelIcon />}
                  style={{ fontSize: '13px', fontFamily: 'Roboto, sans-serif', }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={desmarcar? handleConfirmar : toggleModalConfirm}
                  disabled={valueSubproceso != null && clienteIdLocal != null && aProcesar != null ? false : disabledConfirmar}
                  fullWidth
                  startIcon={<CheckCircleIcon />}
                  style={{ fontSize: '13px', fontFamily: 'Roboto, sans-serif', }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <SimpleDialogComponent
            open={modalStateRemito}
            modalName={"Remito"}
            child={dialogChild}
            handleClose={toggleModalRemito}
            buttonEnviar={false}
            buttonCancelar={true}
            maxWidth="lg"
          />

        </Grid>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message="IDs de cajas copiados al portapapeles"
        />
      </form>
      <ConfimDialogComponent
        maxWidth = {'xs'}
        open={modalStateConfirm}
        modalName={"Aviso"}
        mensaje={
        "La opción de desmarque NO esta activa, el subproceso NO se eliminará, desea continuar?"
        }
        handleConfirm={handleConfirmar}
        handleClose={toggleModalConfirm}
      />
    </Grid>

  );
};

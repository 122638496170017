export const schemaBasicIdDetalle = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "id",
      description: "Id",
    },
    detalle: {
      type: "string",
      title: "Detalle",
      description: "Ingresar detalle",
    },
  },
  required: [ "detalle"],
};


export const schemaBasicIdNombre = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "id",
      description: "Id",
    },
    detalle: {
      type: "string",
      title: "Nombre",
      description: "Ingresar nombre",
    },
  },
  required: [ "detalle"],
};

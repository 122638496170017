import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { schemaMetadatos } from "src/forms/schemaMetadatos";
import { uiSchemaMetadatos } from "src/forms/uiSchemaMetadatos";
import { ComponentDialog } from "../../../general_components/ComponentDialog";
import { postMetadatos } from "../helpers/MetadatoData";

const localize = require("ajv-i18n");
export const MetadatosForm = (props) => {
  const { data, listName, createButton, tipoContenido, setCreateButton, setRefreshList } =
    props;

  const [metadatosInfo, setMetadatosInfo] = useState({
    id_tipo_contenidos: tipoContenido.id || "",
    detalleContenido: tipoContenido.detalle,
    id: data.id_tipo_contenidos || "",
    label_form: data.label_form || "",
    label_grilla: data.label_grilla || "",
    tipo_dato: data.tipo_dato || "",
  });


  if (data.id && metadatosInfo.id === "") {
    setMetadatosInfo(data);
  }
  const [withErrors, setWithErrors] = useState(false);
  const [pushData, setPushData] = useState(false);

  //CONTROLA CUANDO SE SALE DE UN EDIT Y QUEDA CON BASURA LOS SELECTORES, COMPROBAR CUALQUIERA DE LOS DOS SELECTORES SI ES DISTINTO DE VACIO
  if (data.length === 0 && metadatosInfo.id !== "") {
    setMetadatosInfo({
      id_tipo_contenidos: tipoContenido.id || "",
      detalleContenido: "",
      id: "",
      label_form: "",
      label_grilla: "",
      tipo_dato: "",
    });
  }


  useEffect(() => {
    const servicePost = async () => {
      const newMeta = {
        id_tipo_contenidos: metadatosInfo.id_tipo_contenidos,
        id: metadatosInfo.id || "",
        label_form: metadatosInfo.label_form,
        label_grilla: metadatosInfo.label_grilla,
        tipo_dato: metadatosInfo.tipo_dato,
      };
      await postMetadatos(newMeta);
      setMetadatosInfo({
        id_tipo_contenidos: tipoContenido.id || "",
        detalleContenido: "",
        id: "",
        label_form: "",
        label_grilla: "",
        tipo_dato: "",
      });
      setPushData(false);
      setCreateButton(false);
      setRefreshList(true);
    };
    if (pushData) {
      servicePost()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushData]);

  const onChange = (errors, data) => {
    localize.es(errors);

    let err = errors && errors.length > 0;
    setWithErrors(err);

    setMetadatosInfo(data);

    return { errors, data };
  };

  return (
    <>
      <ComponentDialog
        listName={listName}
        buttonClicked={createButton}
        setButtonClicked={setCreateButton}
        setPushData={setPushData}
        withErrors={withErrors}
        child={
          <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>
            <JsonForms
              schema={schemaMetadatos}
              uischema={uiSchemaMetadatos}
              renderers={materialRenderers}
              data={metadatosInfo}
              cells={materialCells}
              onChange={({ errors, data }) =>
                onChange && onChange(errors, data)
              }
            />
          </Grid>
        }
      />
    </>
  );
};

import datos from "src/services/datos";

export const getArticulosTipo = async (auth) => {
  const articulos_tipo = new datos("articulos_tipo");
  articulos_tipo.addCondition("articulos_tipo", "id", 0, ">");
  const response = await articulos_tipo.get();

  const res = response.wsResponse.articulos_tipo;
  // Si es un array se mantiene como estaba
  if (Array.isArray(res)) {
    var arr = res;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (typeof res === "undefined") {
    // se pasa un array vacio
    arr = [];
  } else {
    // si trae un solo elemento , es un objeto
    arr = [res]; // se lo convierte a array
  }
  return arr;
};

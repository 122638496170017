import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';


export const DashboardCartocor = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const formatTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son base 0
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day} / ${month} / ${year} ${hours}:${minutes}:${seconds}`;
    };

    return (

        <div>
            <Grid container justifyContent="center" alignItems="center" style={{ height: '90vh' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <img src="https://www.ceer.org/wp-content/uploads/2015/05/04-cartocor.png" alt="Bienvenido" style={{ maxWidth: '100%', height: 'auto', marginBottom: 20 }} />
                    {/* <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
                                Bienvenido
                            </Typography> */}
                    <Typography variant="h4" align="center">
                        {formatTime(currentTime)}
                    </Typography>
                </Grid>
            </Grid>

        </div>
    );
};

export const schemaRestricciones = {
    "perfiles": {
        "9": {
            "descripcion": "cliente",
            "menus": {
                "02.03.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "false" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "false" },

                    ]
                },
                "04.01.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "false" },

                    ]
                },
                "04.01.02.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                        { "id": "4", "descripcion": "precio", "valor": "true" },
                        { "id": "5", "descripcion": "especificaciones", "valor": "true" },
                        { "id": "6", "descripcion": "imprimir", "valor": "true" },

                    ]
                },
                "05.01.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "05.01.02.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "05.01.03.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "05.02.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "05.02.02.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.01.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.01.03.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.01.04.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.01.05.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.02.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "procesar", "valor": "true" },
                    ]
                },
                "10.03.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                        { "id": "4", "descripcion": "altaBajaModificacionContenido", "valor": "true" },
                    ]
                },
                "10.03.02.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.06.03.00": {
                    "procesos": [
                        { "id": "1", "descripcion": 'Consulta General', "valor": "true" },
                        { "id": "2", "descripcion": 'Retiro de Cajas', "valor": "false" },
                        { "id": "3", "descripcion": 'Retiro Definitivo', "valor": "false" },
                        { "id": "4", "descripcion": 'Baja/Expurgue', "valor": "false" },
                        { "id": "5", "descripcion": 'Devolucion de Cajas', "valor": "false" },
                        { "id": "6", "descripcion": 'Ubicacion de Cajas', "valor": "false" },
                        { "id": "7", "descripcion": 'Ubicacion Temporal', "valor": "false" },
                        { "id": "8", "descripcion": 'Mudanza de Cajas', "valor": "false" },
                        { "id": "9", "descripcion": 'Imprimir', "valor": "false" },
                        { "id": "10", "descripcion": 'Historico', "valor": "false" },
                        { "id": "11", "descripcion": 'Editar', "valor": "false" },
                        { "id": "12", "descripcion": 'MarcarExcedida', "valor": "false" },
                        { "id": "13", "descripcion": 'MarcarDeformada', "valor": "false" },
                        { "id": "14", "descripcion": 'AgregarCajas', "valor": "false" },
                        { "id": "15", "descripcion": 'AgregarCajasGeneral', "valor": "false" },
                        { "id": "16", "descripcion": 'abmContenidos', "valor": "false" },
                    ]
                },
                "10.06.04.00": {
                    "procesos": [
                        { "id": "1", "descripcion": 'Consulta General', "valor": "true" },
                        { "id": "2", "descripcion": 'Retiro de Contenido Parcial', "valor": "false" },
                        { "id": "3", "descripcion": 'Retiro de Contenido Total', "valor": "false" },
                        { "id": "4", "descripcion": 'Quita Total de Contenidos', "valor": "false" },
                        { "id": "5", "descripcion": 'Devolucion de Contenidos Parcial', "valor": "false" },
                        { "id": "6", "descripcion": 'Devolucion de Contenido Total', "valor": "false" },
                    ]
                },
            }
        },
        "2": {
            "descripcion": "Admin",
            "menus": {
                "02.03.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },

                    ]
                },
                "04.01.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },

                    ]
                },
                "04.01.02.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                        { "id": "4", "descripcion": "precio", "valor": "true" },
                        { "id": "5", "descripcion": "especificaciones", "valor": "true" },
                        { "id": "6", "descripcion": "imprimir", "valor": "true" },

                    ]
                },
                "05.01.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "05.01.02.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "05.01.03.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "05.02.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "05.02.02.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.01.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.01.03.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.01.04.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.01.05.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.02.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "procesar", "valor": "true" },
                    ]
                },
                "10.03.01.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                        { "id": "4", "descripcion": "altaBajaModificacionContenido", "valor": "true" },
                    ]
                },
                "10.03.02.00": {
                    "procesos": [
                        { "id": "1", "descripcion": "alta", "valor": "true" },
                        { "id": "2", "descripcion": "baja", "valor": "true" },
                        { "id": "3", "descripcion": "modificacion", "valor": "true" },
                    ]
                },
                "10.06.03.00": {
                    "procesos": [
                        { "id": "1", "descripcion": 'Consulta General', "valor": "true" },
                        { "id": "2", "descripcion": 'Retiro de Cajas', "valor": "true" },
                        { "id": "3", "descripcion": 'Retiro Definitivo', "valor": "true" },
                        { "id": "4", "descripcion": 'Baja/Expurgue', "valor": "true" },
                        { "id": "5", "descripcion": 'Devolucion de Cajas', "valor": "true" },
                        { "id": "6", "descripcion": 'Ubicacion de Cajas', "valor": "true" },
                        { "id": "7", "descripcion": 'Ubicacion Temporal', "valor": "true" },
                        { "id": "8", "descripcion": 'Mudanza de Cajas', "valor": "true" },
                        { "id": "9", "descripcion": 'Imprimir', "valor": "true" },
                        { "id": "10", "descripcion": 'Historico', "valor": "true" },
                        { "id": "11", "descripcion": 'Editar', "valor": "true" },
                        { "id": "12", "descripcion": 'MarcarExcedida', "valor": "true" },
                        { "id": "13", "descripcion": 'MarcarDeformada', "valor": "true" },
                        { "id": "14", "descripcion": 'AgregarCajas', "valor": "true" },
                        { "id": "15", "descripcion": 'AgregarCajasGeneral', "valor": "true" },
                        { "id": "16", "descripcion": 'abmContenidos', "valor": "true" },
                    ]
                },
                "10.06.04.00": {
                    "procesos": [
                        { "id": "1", "descripcion": 'Consulta General', "valor": "true" },
                        { "id": "2", "descripcion": 'Retiro de Contenido Parcial', "valor": "true" },
                        { "id": "3", "descripcion": 'Retiro de Contenido Total', "valor": "true" },
                        { "id": "4", "descripcion": 'Quita Total de Contenidos', "valor": "true" },
                        { "id": "5", "descripcion": 'Devolucion de Contenidos Parcial', "valor": "true" },
                        { "id": "6", "descripcion": 'Devolucion de Contenido Total', "valor": "true" },
                    ]
                },
            }
        },
    }

}


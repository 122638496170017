import { schemaRestricciones } from "src/configs/restricciones";
// funcion que verifica si una variable es un array y lo convierte en caso de que no lo sea
export const esArray = (data) => {
  var arr = "";
  // Si es un array se mantiene como estaba
  if (Array.isArray(data)) {
    arr = data;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (typeof data === 'undefined') { // se pasa un array vacio
    arr = [];
  } else { // si trae un solo elemento , es un objeto
    arr = [data]; // se lo convierte a array
  }
  return arr;
};

export const obtenerProcesosPorPerfilYMenu = ()=> {
  let menuId = localStorage.getItem("levelMenuActual");
  let user_data = JSON.parse(localStorage.getItem("siam-user-data"));
  let idPerfil = user_data.id_perfiles;
  const perfil = schemaRestricciones?.perfiles[idPerfil];
  if (perfil) {
      const menu = perfil.menus[menuId];
      if (menu) {
          const procesos = obtenerPermisos(menu.procesos);
          return procesos;
      }
  }
  return null;
}
function obtenerPermisos(procesos) {
  return procesos.reduce((permisos, proceso) => {
      permisos[proceso.descripcion] = proceso.valor === "true";
      return permisos;
  }, {});
}

export default esArray




import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh', // Ocupar toda la pantalla vertical
    gap: '10px', // Espacio entre los Skeletons
};

const skeletonStyle = {
    width: '100%', // Ocupar toda la pantalla horizontal
};

function SkeletonLayout() {
    return (
        <Box sx={containerStyle}>
            {/* Cuatro Skeletons rectangulares uno al lado del otro */}
            <Grid container spacing={2}>
                {/* Cuatro Skeletons rectangulares uno al lado del otro */}
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={118} sx={skeletonStyle} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={118} sx={skeletonStyle} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={118} sx={skeletonStyle} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={118} sx={skeletonStyle} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={118} sx={skeletonStyle} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={118} sx={skeletonStyle} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Skeleton variant="rectangular" height={618} sx={skeletonStyle} />
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" height={98} sx={skeletonStyle} />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" height={98} sx={skeletonStyle} />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" height={390} sx={skeletonStyle} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Box>
    );
}

export default SkeletonLayout;

import { darken, lighten, styled } from '@mui/material/styles';
import { DataGridPro} from "@mui/x-data-grid-pro";
export const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

export const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

export const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

export const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    '& .super-app-theme--Open': {
        backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
        },
    },

    '& .super-app-theme--Contenidos': {
        backgroundColor: getBackgroundColor(
            '#44787D',
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                '#44787D',
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                '#44787D',
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    '#44787D',
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Filled': {
        backgroundColor: getBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--PartiallyFilled': {
        backgroundColor: getBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Rejected': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Ubicacion': {
        backgroundColor: getBackgroundColor(theme.palette.secondary.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.secondary.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.secondary.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.secondary.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--DevolucionContenidos': {
        backgroundColor: getBackgroundColor(
            '#ffc700', // Código de color rojo
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                '#ffc700', // Código de color rojo
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                '#ffc700', // Código de color rojo
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    '#ffc700', // Código de color rojo
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--UbicacionTemporal': {
        backgroundColor: getBackgroundColor(
            '#fa00ff', // Código de color rojo
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                '#fa00ff', // Código de color rojo
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                '#fa00ff', // Código de color rojo
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    '#fa00ff', // Código de color rojo
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--RetiroDefinitivo': {
        backgroundColor: getBackgroundColor(
            '#00ff11', // Código de color rojo
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                '#00ff11', // Código de color rojo
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                '#00ff11', // Código de color rojo
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    '#00ff11', // Código de color rojo
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--BajaExpurgue': {
        backgroundColor: getBackgroundColor(
            '#bbff00', // Código de color rojo
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                '#bbff00', // Código de color rojo
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                '#bbff00', // Código de color rojo
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    '#bbff00', // Código de color rojo
                    theme.palette.mode,
                ),
            },
        },
    },

}));

export const estiloGrilla = {
    '--DataGrid-overlayHeight': '50vh',
    backgroundColor: 'white',
    fontFamily: 'Roboto, sans-serif', // Cambia el tipo de letra
    borderRadius: 3, // Elimina los bordes redondeados del DataGridPro
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", /* Agrega sombra */
    height: '50vh',
    // FORMATO DE LAS CABECERAS // TODO: CSS
    ".MuiDataGrid-columnHeaderTitle": { fontWeight: "bold !important", overflow: "visible !important", },
    "& .MuiDataGrid-cell": {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
    }, '.MuiDataGrid-columnSeparator': {
        display: 'none',
    }
    , '& .MuiDataGrid-overlay': {
        height: '800px !important',
    },
}

export const globalEstilos = {
    '.MuiDataGrid-menuList': {
        color: '#0A395F',
        fontSize: 11,
        fontFamily: 'Roboto, sans-serif',

        '& .MuiMenuItem-root': {
            color: '#0A395F',
            fontSize: 11,
            fontFamily: 'Roboto, sans-serif',
        },
    },
}


export const estilosComponentGrilla = {
    '& .MuiTypography-root': {
        color: '#0A395F',
        fontSize: 11,
        fontFamily: 'Roboto, sans-serif',
    },
    '& .MuiDataGrid-filterForm': {
        color: '#0A395F',
        fontSize: 11,
        fontFamily: 'Roboto, sans-serif',
    }, '& .MuiFormControl-root': {
        color: 'red',
        fontSize: 11,
        fontFamily: 'Roboto, sans-serif',
    }, '& .MuiNativeSelect-select': {
        color: '#0A395F',
        fontSize: 11,
        fontFamily: 'Roboto, sans-serif',
    }, '& .MuiInputBase-input': {
        color: '#0A395F',
        fontSize: 11,
        fontFamily: 'Roboto, sans-serif',
    }, "& .MuiDataGrid-panelFooter": {
        display: 'flex', justifyContent: 'space-between'
    }, "& .MuiDataGrid-panelFooter button": {
        background: "#0A395F",
        width: 'auto',
        minWidth: '120px',
        fontSize: '11px',
        height: 'auto',
        color: '#FFFFFF',

    }, '& .MuiMenuItem-gutters': {
        color: '#0A395F',
        fontSize: 11,
        fontFamily: 'Roboto, sans-serif',
    }, '.MuiDataGrid-menuList': {
        backgroundColor: 'pink',

        '& .MuiMenuItem-root': {
            fontSize: 8,
        },
    },
}

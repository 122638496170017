import { useState } from "react";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";
import { getTipoUbicacionesPaginated, getTipoUbicaciones, deleteTipoUbicaciones, postTipoUbicaciones } from "../helpers/TipoUbicacionesData";
import { TipoUbicacionesForm } from "./TipoUbicacionesForm";
import { Grid } from "@mui/material";

export const TipoUbicacionesList = () => {
  const [createButton, setCreateButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [buttonMetadatos, setButtonMetadatos] = useState(false);

  const [tipoCajas, setTipoCajas] = useState([]);
  const listName = "Tipos de ubicaciones";
  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "detalle", headerName: "Detalle", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    { field: "alto", headerName: "Alto", flex: 0.3 },
    { field: "ancho", headerName: "Ancho", flex: 0.3 },
    { field: "profundidad", headerName: "Profundidad", flex: 0.3 },
  ];

  const options = {
    cols: columns,
    get: getTipoUbicaciones,
    getPaginated: getTipoUbicacionesPaginated,
    pageSize: [5, 10, 25],
    edit: true,
    listName,
    delete: deleteTipoUbicaciones,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setTipoCajas,
  };

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  //EL BUTTONMETADATOS SOLO ES PARA ESTE CASO, VERIFICAR CADA OCASION.
  if (
    createButton === false &&
    tipoCajas.id !== undefined &&
    buttonMetadatos === false
  ) {
    setTipoCajas([]);
  }

  return (
    <>
      <Grid style={{ background: "white", padding: "30px", marginBottom: "50px" }}>

        <>
          <ListTablePaginated options={options} />
          <TipoUbicacionesForm
            data={tipoCajas}
            createButton={createButton}
            listName={listName}
            setCreateButton={setCreateButton}
            componentList={"/tipo_ubicaciones/admin"}
            postData={postTipoUbicaciones}
          />
        </>
      </Grid>
    </>
  );
};


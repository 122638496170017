import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Grid, ImageList } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useEffect, useState, useContext } from "react";
import Zoom from "react-medium-image-zoom";
import AlertDialog from "./AlertDialogDelete";
import {
  postDigitalizacionesCaratulas,
  deleteImagesPrincipales,
} from "../../helpers/DigitalizacionData";
import "react-medium-image-zoom/dist/styles.css";
import "../styles/Digitalizaciones.css";
import { SnackbarMessenger } from "../../../../general_components/SnackbarMessenger";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CajasContext } from "src/context/CajasContext/CajasContext";

export const DigitalizacionesCaratula = (props) => {
  const {
    contenidoSeleccionado,
    saveImagesCaratula,
    setSaveImagesCaratula,
    setSaveImagesDigitalizaciones,
    setButtonSaveCaratula,
    setActualizarListado,
    setAlertImagesSave,
    setEliminar,
    guardado,
    setLoadingContenido
  } = props;

  const {
    checkedTotal, digitalizacionesSeleccionadas, setDigitalizacionesSeleccionadas, digitalizacionesSeleccionadasCompleto,
    setCheckedTotalCaratula, checkedCaratula, setCheckedCaratula, setDigitalizacionesSeleccionadasCompleto,
    actualizacionIndividualCheckbox, setActualizacionIndividualCheckbox, rows, setCheckedTotal, clickCheckedTotal
  } = useContext(CajasContext);

  const [file, setFile] = useState([]);
  const [base64String, setBase64String] = useState("");
  const [loading, setLoading] = useState(false);
  const [getDataSave, setGetDataSave] = useState(true);
  const [primerRender, setPrimerRender] = useState(true);
  const [digitalizacionesCaratulaSeleccionadas, setDigitalizacionesCaratulaSeleccionadas] = useState([]);
  //NOTE ESTADO PARA SABER SI HAY IMAGENES NUEVAS PARA ALMACENAR
  const [newImage, setNewImage] = useState(false);
  //NOTE STATE PARA DELETE
  const [alertDelete, setAlertDelete] = useState(false);
  const [index, setIndex] = useState(false);
  const [deleteTrue, setDeleteTrue] = useState(false);
  const [deleteStoredImage, setDeleteStoredImage] = useState(false);
  //NOTE STATE PARA LAS ALERTAS
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  //NOTE FUNCION PARA ELIMINAR UNA IMAGEN
  if (deleteTrue) {
    if (file[index].save) {
      setDeleteStoredImage(true);
    } else {
      let newFile = [...file];
      newFile.splice(index, 1);
      setFile(newFile);

      //NOTE FUNCION PARA SABER SI SE SACAN TODAS LAS IMAGENES PARA ALMACENAR
      const imagesNews = newFile.filter((item) => item.save === false);
      if (imagesNews.length === 0) {
        setNewImage(false);
      }
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage("Se elimino la digitalizacion del contenido correctamente");
      setIndex("");
    }
    setDeleteTrue(!deleteTrue);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (base64String !== "") {
      //NOTE AL PASAR A BASE64 TARDA UN RATITO POR ESO SE ESTA ACTUALIZANDO LA DATA EN UN USEEFFECT, LAS IMAGENES QUE SE MUESTRAN YA SE ENCUENTRAN EN BASE64
      setFile([
        {
          modelo_padre: "contenidos",
          id_modelo_padre: contenidoSeleccionado.id,
          image: base64String,
          save: false,
          checked: false
        },
        ...file,
      ]);
      setNewImage(true);
      setBase64String("");
      setLoading(false);
    }

    //NOTE TRAER TODOS LAS DIGITALIZACIONES GUARDADAS DE UN CONTENIDO
    if (contenidoSeleccionado.imagenes && file.length === 0 && getDataSave) {
      const result = await getImagesSave(contenidoSeleccionado.imagenes);

      setFile(result);
    }
    setGetDataSave(false);

    //NOTE CONTROLO EL BOTON DE GUARDAR PARA QUE NO SE PUEDA GUARDAR SI TIENE IMAGENES SELECCIONADAS EN LA CARATULA
    if (file.length > 0 && newImage) {
      setButtonSaveCaratula(false);
    } else {
      setButtonSaveCaratula(true);
    }

    //NOTE FUNCION PARA GUARDAR LAS DIGITALIZACIONES DE LA CARATULA
    if (saveImagesCaratula) {
      if (newImage) {
        let result = await postDigitalizacionesCaratulas(file);
        if (result.status === "true") {
          setAlertImagesSave(true);
          setSaveImagesDigitalizaciones(true);
        } else {
          setAlertImagesSave(false);
          setLoadingContenido(false);
        }
      } else {
        setSaveImagesDigitalizaciones(true);
      }
      setSaveImagesCaratula(!saveImagesCaratula);
    }

    //NOTE FUNCION PARA ELIMINAR IMAGENES ALMACENADAS
    if (deleteStoredImage) {
      const respuesta = await deleteImagesPrincipales(file[index]?.id);

      // Se muestra los mensajes corresponientes al status del servicio
      // Se retorna un mensaje con la cantidad de contenidos encontrados
      // Sino se muestra un mensaje de que no se encontraron contenidos
      if (respuesta === "true") {
        setDeleteStoredImage(false);
        setEliminar(true);
        setActualizarListado(true);
        setSnackbarVisible(true);
        setSeverity("success");
        setMessage("Se elimino la digitalizacion del contenido correctamente");
      } else {
        setSnackbarVisible(true);
        setDeleteStoredImage(false);
        setSeverity("warning");
        setMessage("Hubo un error al eliminar la digitalizacion del contenido");
      }

      let newFile = [...file];
      newFile.splice(index, 1);
      setFile(newFile);

      //NOTE FUNCION PARA SABER SI SE SACAN TODAS LAS IMAGENES PARA ALMACENAR
      const imagesNews = file.filter((item) => item.save === false);
      if (imagesNews.length === 0) {
        setNewImage(false);
      }

      setIndex("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base64String, file, saveImagesCaratula, deleteStoredImage]);

  //NOTE CUANDO SE SELECCIONA UNA IMAGEN SE PASA DIRECTAMENTE A BASE 64
  const uploadImages = async (event) => {
    let fileObj = [];
    fileObj.push(event.target.files);

    for (let i = 0; i < fileObj[0].length; i++) {
      setLoading(true);
      let imageBase64 = await convertImageToBase64(
        URL.createObjectURL(fileObj[0][i]),
        event.target.files[0].name
      );
      setBase64String(imageBase64);
    }
  };

  //NOTE FUNCION PARA PASAR LAS IMAGENES A BASE 64, PARA PODER ALMACENARLAS EN LA BASE DE DATOS
  const convertImageToBase64 = async (url, name) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  // Funcion Checkbox
  const handleOnChange = (e) => {
    // Convertimos el arreglo en string
    const str = JSON.stringify(file);
    // Parseamos el string a un arreglo
    const arrFile = JSON.parse(str);
    if (e) {
      for (var i = 0; i < arrFile.length; i++) {
        let contenidoCaratula = [];
        contenidoCaratula = [...digitalizacionesCaratulaSeleccionadas, arrFile[i].id_modelo_padre]
        setDigitalizacionesCaratulaSeleccionadas(contenidoCaratula)
        let contenidoCaratulaUnicos = Array.from(new Set([...digitalizacionesSeleccionadasCompleto.contenidos, arrFile[i].id_modelo_padre]))
        setDigitalizacionesSeleccionadas({ ...digitalizacionesSeleccionadasCompleto, contenidos: contenidoCaratulaUnicos })
        setDigitalizacionesSeleccionadasCompleto({ ...digitalizacionesSeleccionadasCompleto, contenidos: contenidoCaratulaUnicos })
        break;
      }

    } else {
      for (var i2 = 0; i2 < arrFile.length; i2++) {
        let contenidoCaratula = [];
        contenidoCaratula = [...digitalizacionesCaratulaSeleccionadas, arrFile[i2].id_modelo_padre]
        setDigitalizacionesCaratulaSeleccionadas(contenidoCaratula)
        let contenidoCaratulaUnicos = Array.from(new Set([...digitalizacionesSeleccionadasCompleto.contenidos, arrFile[i2].id_modelo_padre]))

        // eslint-disable-next-line no-loop-func
        const filterDigitalizacionesSeleccionadas = contenidoCaratulaUnicos.filter((item) => item !== arrFile[i2].id_modelo_padre)

        setDigitalizacionesSeleccionadas({ ...digitalizacionesSeleccionadasCompleto, contenidos: filterDigitalizacionesSeleccionadas })
        setDigitalizacionesSeleccionadasCompleto({ ...digitalizacionesSeleccionadasCompleto, contenidos: filterDigitalizacionesSeleccionadas })
        break;
      }
    }

    setActualizacionIndividualCheckbox(true);
    let cantRow = rows.length;
    let cantRowChecked = rows.filter((item) => item.id_digitalizaciones && item.checked !== false).length;
    setCheckedTotal(cantRow === cantRowChecked && e ? true : false);
  }


  useEffect(() => {
    if (!actualizacionIndividualCheckbox) {
      if (checkedTotal) {
        let contenidoCaratula = [];
        // Convertimos el arreglo en string
        const str = JSON.stringify(file);
        // Parseamos el string a un arreglo
        const arrFile = JSON.parse(str);
        for (var i = 0; i < arrFile.length; i++) {
          contenidoCaratula = [...digitalizacionesCaratulaSeleccionadas, arrFile[i].id_modelo_padre]
          setDigitalizacionesCaratulaSeleccionadas(contenidoCaratula)
          let contenidoCaratulaUnicos = Array.from(new Set([...digitalizacionesSeleccionadasCompleto.contenidos, arrFile[i].id_modelo_padre]))
          setDigitalizacionesSeleccionadas({ ...digitalizacionesSeleccionadas, contenidos: contenidoCaratulaUnicos })
          setDigitalizacionesSeleccionadasCompleto({ ...digitalizacionesSeleccionadasCompleto, contenidos: contenidoCaratulaUnicos })
          break;
        }
        setCheckedCaratula(checkedTotal);
        setCheckedTotalCaratula(checkedTotal);

      } else {
        // Convertimos el arreglo en string
        const str = JSON.stringify(file);
        // Parseamos el string a un arreglo
        const arrFile = JSON.parse(str);
        for (var i2 = 0; i2 < arrFile.length; i2++) {
          // eslint-disable-next-line no-loop-func
          const filterContenidoCaratula = [...digitalizacionesSeleccionadasCompleto.contenidos].filter((item) => item !== arrFile[i2].id_modelo_padre)
          setDigitalizacionesSeleccionadas({ ...digitalizacionesSeleccionadas, contenidos: filterContenidoCaratula })
          setDigitalizacionesSeleccionadasCompleto({ ...digitalizacionesSeleccionadasCompleto, contenidos: filterContenidoCaratula })
          console.log("DIGPRUEBA", { ...digitalizacionesSeleccionadas, contenidos: filterContenidoCaratula })
          break;
        }

        setCheckedCaratula(checkedTotal);
        setCheckedTotalCaratula(checkedTotal);
      }

      if (primerRender && !clickCheckedTotal) {
        let id_contenido = false;
        for (var i3 = 0; i3 < file.length; i3++) {
          id_contenido = file[i3].id_modelo_padre;
          break;
        }
        const idContenidoEncontrado = digitalizacionesSeleccionadasCompleto.contenidos.find(element => element === id_contenido);
        if (idContenidoEncontrado) {
          setCheckedCaratula(true);
          setPrimerRender(false)
        }

        setCheckedTotalCaratula(checkedTotal);

      }
    }
    setActualizacionIndividualCheckbox(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTotal, file]);

  return (
    <>
      {alertDelete && (
        <AlertDialog
          alertDelete={alertDelete}
          setDeleteTrue={setDeleteTrue}
          setAlertDelete={setAlertDelete}
        />
      )}
      <Grid
        style={{
          paddingLeft: "100px",
          paddingRight: "100px",
        }}
      >
        {/*NOTE BOTTON PARA SELECCIONAR LAS IMAGENES */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          style={{
            textAlign: "center",
            display: "block",
          }}
        >
          <Button variant="contained" component="label" endIcon={<PhotoCamera style={{ color: "#FFFFFF" }} />}>
            Elegir Imagenes Principales
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={uploadImages}
            />
          </Button>


        </Stack>
        <Grid >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedCaratula}
                  onChange={e => {
                    handleOnChange(e.target.checked)
                    setCheckedCaratula(e.target.checked)
                  }
                  } />}
              label={<Typography sx={{ fontSize: 14 }}>Incluir a Facturar</Typography>} />
          </FormGroup>
        </Grid>

        {/*NOTE LISTADO DE IMAGENES */}
        {loading || guardado ? (
          <Grid style={{ textAlign: "center" }}>
            <CircularProgress style={{ width: "50px", height: "50px" }} />
          </Grid>
        ) : (
          <ImageList
            sx={{
              gridAutoFlow: "column",
              gridTemplateColumns:
                "repeat(auto-fill,minmax(160px,1fr)) !important",
              gridAutoColumns: "minmax(160px, 1fr)",
              gap: "70px !important",
              padding: "15px",
            }}
          >
            {file.map((img, key) => (
              <Box key={key}>
                <Zoom isZoomed={true}>
                  <img src={img.image} alt={img.descripcion} className="image" />

                  {/*NOTE BOTON PARA ELIMINAR LAS IMAGENES */}
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel 
                        control={
                        <Checkbox 
                          checked={img.checked}
                          onChange={e => {
                            handleOnChange(key,e,img)
                        }} />} 
                        label={<Typography sx={{ fontSize: 14 }}>Incluir a Facturar</Typography>} />
                    </FormGroup>
                  </Grid> */}
                    <Grid item xs={12}>
                      <IconButton
                        className="iconButton"
                        aria-label="delete"
                        onClick={() => {
                          setAlertDelete(true);
                          setIndex(key);
                        }}
                      >
                        <DeleteIcon className="icon" />
                      </IconButton>
                    </Grid>


                  </Grid>

                </Zoom>


              </Box>
            ))}
          </ImageList>
        )}
      </Grid>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </>
  );
};

const getImagesSave = async (imagenesContenidos) => {
  let saveImages = [];
  /**
   * ORDENO LA DATA PARA PODER DEJARLA EN UN SOLO ARRAY DE OBJETOS, PARA PODER MOSTRARLA MAS FACIL
   *
   * ID
   * IMAGEN
   * MODELO
   * SAVE = TRUE (SIRVE PARA PODER SABER SI LA IMAGEN QUE MUESTRO ESTA GUARDADA YA O NO)
   *
   */
  if (imagenesContenidos && imagenesContenidos.length === undefined) {
    saveImages.push({
      id: imagenesContenidos.id,

      image: imagenesContenidos.link || "",

      model: imagenesContenidos.modelo_padre || "",

      save: true,

      id_modelo_padre: imagenesContenidos.id_modelo_padre || "",
    });
  } else {
    imagenesContenidos.map(
      // eslint-disable-next-line array-callback-return
      (img) => {
        saveImages.push({
          id: img.id,

          image: img.link || "",

          model: img.modelo_padre || "",

          save: true,

          checked: false,

          id_modelo_padre: img.id_modelo_padre || "",
        });
      }
    );
  }
  return saveImages;
};

import {
    materialCells,
    materialRenderers,
} from "@jsonforms/material-renderers";
import { useContext } from "react";
import { JsonForms } from "@jsonforms/react";
import { Box, Container, Grid } from "@mui/material";
import { useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { schemaTipoContenidosForm } from "../../gestion_comercial/cuentas/components/forms/schemaTipoContenidosForm"
import { uiSchemaTipoContenidosForm } from "../../gestion_comercial/cuentas/components/forms/uiSchemaTipoContenidosForm";
import { SelectorTipoContenidosComponent } from "src/forms/customRenders/selectorTipoContenidos";
import { Buttons } from "src/general_components/Buttons";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { postTipoContenidosLocalizaciones } from "src/projects/gestion_comercial/cuentas/helpers/TipoContenidosData";
/* Formulario contenedor de los datos de las CUENTAS JURIDICAS */
const localize = require("ajv-i18n");

export const TipoContenidosForm = (props) => {
    /* estados propios del context */
    const { selectedLocalizacion, idCuentaLocalizacionesSelected } = useContext(CajasContext);


    const [snackbarVisible, setSnackbarVisible] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [message, setMessage] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [severity, setSeverity] = useState("");

    /* definicion de constantes de estado para utiliar con sus respectivos formularios */
    const [dataForm, setDataForm] = useState(
        //selectedLocalizacion?.tipo_contenidos || []
        {
            tipoContenido: selectedLocalizacion?.tipo_contenidos || [],
            id_cuentas_localizaciones: idCuentaLocalizacionesSelected || "",
            selectedTipoContenidos: selectedLocalizacion || [],
        }
    );

    //FIXME VER QUE HACER CON ESTA VARIABLE
    // eslint-disable-next-line no-unused-vars
    const [withErrors, setWithErrors] = useState(false);
    const onChange = (errors, data) => {
        localize.es(errors);
        let err = errors && errors.length > 0;
        setWithErrors(err);
        setDataForm(data);

        return { errors, data };
    };


    /* funciones para guardar telefonos en el context y validacion del formulario */

    const sendInfo = async (send) => {
        if (send) {
            const response = await postTipoContenidosLocalizaciones(dataForm);
            if(response?.status === 'true'){
                props.setActualizarOptions(true)
                props.handleClose(false);
            }
        };

    };

    const handleClose = () => {
        props.handleClose(false);
    };



    return (
        <>
            <Container maxWidth="lg">

                <Box
                    sx={{
                        mx: "auto",
                        width: "100%",
                        p: 1,
                        m: 1,
                        textAlign: "center",
                        flexGrow: 1,
                    }}
                >
                    <JsonForms
                        onChange={({ errors, data }) =>
                            onChange && onChange(errors, data)
                        }
                        schema={schemaTipoContenidosForm}
                        uischema={uiSchemaTipoContenidosForm}
                        renderers={[...materialRenderers, SelectorTipoContenidosComponent]}
                        data={dataForm}
                        cells={materialCells}
                    />
                    <Grid container justifyContent="flex-end">
                        <Buttons
                            onClickSubmit={() => {
                                sendInfo(true)
                            }}
                            labelButtonSave={"Guardar"}
                            buttonCerrar={handleClose}
                        />
                    </Grid>
                    <SnackbarMessenger
                        open={snackbarVisible}
                        message={message}
                        severity={severity}
                        close={() => {
                            setSnackbarVisible(false);
                        }}
                    />
                </Box>
            </Container>
        </>
    );
};

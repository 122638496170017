/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  CssBaseline,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  IconButton,
  Container,
  Grid,
  Paper,
  Typography,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logout from "@mui/icons-material/Logout";
import HelpIcon from "@mui/icons-material/Help";
import CachedIcon from "@mui/icons-material/Cached";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import { populateServiceData } from "src/general_helpers/initDataLoad";
import { AuthContext } from "src/context/AuthContext";
import Swal from "sweetalert2";
import { types } from "src/context/types";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { MiPerfilForm } from "src/general_components/MiPerfilForm";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { SideMenu } from "./SideMenu2"; // Asegúrate de tener este archivo
import TheContent from "./TheContent"; // Asegúrate de tener este archivo
import { appName } from "src/configs/config";
import SearchComponentHeader from "src/general_components/SearchComponentHeader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "src/services/styles/sweetAlert.css";
import ButtonConfig from "src/general_components/ButtonConfig";
import ModalConfig from "src/general_components/ModalConfig";
import { createTheme } from '@mui/material/styles';
import { ThemeContextProvider, ThemeContext } from "src/context/ThemeContext";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth - 1}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "fullyHidden",
})(({ theme, open, fullyHidden }) => ({
  "& .MuiDrawer-paper": fullyHidden
    ? {
      position: "relative",
      whiteSpace: "nowrap",
      width: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxSizing: "border-box",
      overflowX: "hidden",
    }
    : {
      position: "relative",
      whiteSpace: "nowrap",
      width: open ? drawerWidth : theme.spacing(9),
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      overflowX: "hidden",
      scrollbars: "hidden",
    },
}));

const DashboardContent = () => {
  const { dispatch } = useContext(AuthContext);
  const [modalState, setModalState] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [buttonClicked, setButtonClicked] = useState(false);
  const [userSelect, setUserSelect] = useState({});
  const [createButton, setCreateButton] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const localStorageOpen = localStorage.getItem("openSideMenu");
  const [open, setOpen] = useState(localStorageOpen === "false" ? false : true);
  const [fullyHidden, setFullyHidden] = useState(false);

  // Variables Estilos
  const [themeMode, setThemeMode] = useState('light');
  const [menuColor, setMenuColor] = useState('#1976d2'); // Default primary color
  const [modalColor, setModalColor] = useState('#ffffff'); // Default background paper color
  const [buttonColor, setButtonColor] = useState('#f50057'); // Default secondary color
  const [isModalOpen, setIsModalOpen] = useState(false);

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      // Aquí puedes personalizar más propiedades del tema oscuro si lo deseas
    },
  });


  const handleThemeChange = (mode) => {
    setThemeMode(mode);
  };

  const handleColorChange = (component, color) => {
    if (component === 'menu') {
      setMenuColor(color);
    } else if (component === 'modal') {
      setModalColor(color);
    } else if (component === 'button') {
      setButtonColor(color);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (localStorageOpen === null) {
    localStorage.setItem("openSideMenu", true);
  }

  const toggleDrawer = () => {
    if (fullyHidden) {
      handleToggleFullyHidden();
    }
    const newState = !open;
    setOpen(newState);
    localStorage.setItem("openSideMenu", newState);
  };

  const handleToggleFullyHidden = () => {
    setFullyHidden(!fullyHidden);
  };

  const nombreApellidoUsuario = JSON.parse(localStorage.getItem("siam-user-data"));
  const ownersLocalStorage = JSON.parse(localStorage.getItem("clientData"));
  const owner = ownersLocalStorage?.owner_descripcion;

  const [anchorEl, setAnchorEl] = useState(null);
  const openTooltip = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const rechargeCache = () => {
    localStorage.setItem("estadoPeticionModelos", "false");
    populateServiceData();
  };

  const Logouts = () => {
    Swal.fire({
      title: "Desea cerrar sesion?",
      showDenyButton: true,
      confirmButtonText: `Si`,
      denyButtonText: `No`,
      customClass: {
        container: 'my-swal-delete'
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type: types.logout,
          payload: {},
        });
        localStorage.clear();
      }
    });
  };

  const details = navigator.userAgent;
  const regexp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regexp.test(details);

  const toggleModal = () => {
    setModalState(!modalState);
    setCreateButton(!createButton);
    setUserSelect({});
  };

  useEffect(() => {
    if (!createButton) {
      setUserSelect({});
    }
  }, [createButton]);

  const theme = useTheme();
  const backgroundDrawer = theme.backgroundDrawer?.default || 'dark';

  const dialogChild = (
    <MiPerfilForm
      data={userSelect}
      setButtonClicked={setButtonClicked}
      setCreateButton={toggleModal}
      setUserSelect={setUserSelect}
      setSnackbarVisible={setSnackbarVisible}
      setMessage={setMessage}
      setSeverity={setSeverity}
    />
  );

  return (

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open && !fullyHidden}>
          <Toolbar sx={{ pr: "24px" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{ marginRight: "0px", ...(open && !fullyHidden && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Tooltip title={fullyHidden ? "Mostrar Menú" : "Ocultar Menú"}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="toggle menu"
                onClick={handleToggleFullyHidden}
                sx={{ marginLeft: 2 }}
              >

                {fullyHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}

              </IconButton>
            </Tooltip>

            <SearchComponentHeader />
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              xs={12}
              item
              style={isMobileDevice ? { textAlign: "center" } : { textAlign: "center" }}
            >
              <ButtonConfig handleClick={handleOpenModal} />
              <ThemeContext.Consumer>
                {({ handleThemeChange, handleColorChange }) => (
                  <ModalConfig
                    open={isModalOpen}
                    onClose={handleOpenModal}
                    onThemeChange={handleThemeChange}
                    onColorChange={handleColorChange}
                  />
                )}
              </ThemeContext.Consumer>
              <Tooltip title="Perfil">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={openTooltip ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openTooltip ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}></Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openTooltip}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PersonIcon fontSize="small" style={{ color: "#3c4b64" }} />
                  </ListItemIcon>
                  <Typography variant="caption" style={{ fontWeight: "bold", fontSize: 13, color: "#3c4b64" }}>
                    {nombreApellidoUsuario?.nombre + ", " + nombreApellidoUsuario?.apellido}
                  </Typography>
                </MenuItem>
                {owner && (
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <BusinessIcon fontSize="small" style={{ color: "#3c4b64" }} />
                    </ListItemIcon>
                    <Typography variant="caption" style={{ fontWeight: "bold", fontSize: 13, color: "#3c4b64" }}>
                      {owner}
                    </Typography>
                  </MenuItem>
                )}
                <Divider>
                  <Typography variant="caption" style={{ fontSize: 10 }}>
                    Acciones
                  </Typography>
                </Divider>
                <MenuItem onClick={toggleModal}>
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  Mi perfil
                </MenuItem>
                <MenuItem component="a" href="/help">
                  <ListItemIcon>
                    <HelpIcon fontSize="small" />
                  </ListItemIcon>
                  Help
                </MenuItem>
                <MenuItem onClick={rechargeCache}>
                  <ListItemIcon>
                    <CachedIcon fontSize="small" />
                  </ListItemIcon>
                  Actualizar Cache
                </MenuItem>
                <Divider />
                <MenuItem onClick={Logouts}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} fullyHidden={fullyHidden}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: backgroundDrawer,
              px: [1],
            }}
          >
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                color: "#FFFFFF",
                fontFamily: '"Public Sans", sans-serif',
                fontSize: "0.875rem !important",
                fontWeight: "bold !important",
              }}
            >
              {appName}
            </Typography>
            <IconButton onClick={toggleDrawer}>
              <MenuOpenIcon style={{ color: "white" }} />
            </IconButton>
          </Toolbar>
          <List
            component="nav"
            sx={{
              height: "100%",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "3px" },
              "&::-webkit-scrollbar-thumb": { borderRadius: "3px", background: "gray" },
              maxHeight: "calc(100vh - 64px)",
            }}
          >
            <SideMenu open={open} Logouts={Logouts} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900]),
            flexGrow: 1,
            height: "100vh",
          }}
        >
          <Toolbar />
          <Container disableGutters={true} maxWidth="xxl" sx={{ mt: 0, mb: 0 }}>
            <Grid container spacing={1}>
              <Grid item={true} xs={12}>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TheContent />
                  <SimpleDialogComponent
                    open={modalState}
                    modalName={"Mi Perfil"}
                    child={dialogChild}
                    handleClose={toggleModal}
                    buttonEnviar={false}
                    buttonCancelar={false}
                    maxWidth="md"
                  />
                  <SnackbarMessenger
                    open={snackbarVisible}
                    message={message}
                    severity={severity}
                    close={() => {
                      setSnackbarVisible(false);
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

  );
};

export default React.memo(DashboardContent);

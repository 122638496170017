import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { useContext, useEffect } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";

export const SwitchCajasUbicadas = () => {
  const { cajasUbicadas, setCajasUbicadas, checked, setChecked, disabled } =
    useContext(CajasContext);

  useEffect(() => {
    if (cajasUbicadas === "0") {
      setCajasUbicadas("1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Evita que el clic se propague al FormGroup
  };

  const handleChange = (event) => {

    var evento = event.target.checked;
    setChecked(evento);
    if (evento === true) {
      setCajasUbicadas("2");
    } else {
      setCajasUbicadas("1");
    }
  };
  return (
    <FormGroup>
      <div onClick={handleContainerClick}>
        <FormControlLabel
          control={
            <Switch
              {...(disabled ? { disabled } : {})}
              checked={checked}
              onClick={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Cajas Ubicadas"
        />
      </div>
    </FormGroup>
  );
};

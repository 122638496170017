import React from 'react';
import { DashboardDlys } from './DashboardDlys';
import { DashboardFloripa } from './DashboardFloripa';
import { DashboardCartocor } from './DashboardCartocor';
import { useState, useEffect } from "react";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";

export const Dashboard = () => {
    let dashboardComponent;
    let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));


    // eslint-disable-next-line no-unused-vars
    const [encriptacion, setEncriptacion] = useState(localStorage.getItem("encriptacion"));

    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [snackbarVisible, setSnackbarVisible] = useState(false);

    if (parametros_proyecto.id === "27") {
        dashboardComponent = <DashboardDlys />;
    } else if (parametros_proyecto.id === "30") {
        dashboardComponent = <DashboardFloripa />;
    }else if (parametros_proyecto.id === "24") {
        dashboardComponent = <DashboardCartocor />;
    }
    ///Habilita o no el enciptado de datos
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.shiftKey && event.key === ' ') {
                setEncriptacion((prevEncriptacion) => {
                    if (prevEncriptacion === 'true') {
                        localStorage.setItem("encriptacion", 'false');
                        setSeverity('warning');
                        setMessage('Encriptado Deshabilitado');
                        setSnackbarVisible(true);
                        return 'false';
                    } else {
                        localStorage.setItem("encriptacion", 'true');
                        setSeverity('success');
                        setMessage('Encriptado Habilitado');
                        setSnackbarVisible(true);
                        return 'true';
                    }
                });

            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <main className="c-main">
                {dashboardComponent}
            </main>
            <SnackbarMessenger
                open={snackbarVisible}
                message={message}
                severity={severity}
                close={() => {
                    setSnackbarVisible(false);
                }}
            />
        </>

    );
}


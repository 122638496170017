/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from "@mui/icons-material/Search";
import StyleIcon from "@mui/icons-material/Style";
import { Grid, Tooltip, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { ListTablePaginated } from "../../../../general_components/ListTablePaginated";
import { SimpleDialogComponent } from "../../../../general_components/SimpleDialogComponent";
import { SnackbarMessenger } from "../../../../general_components/SnackbarMessenger";
import { getContenidosSearchPaginated } from "../../helpers/ContenidosData";
import { imprimirEtiquetas } from "../../helpers/EtiquetasData";
import { TheLayoutContext } from "src/context/TheLayoutContext";
import { Typography } from '@mui/material';
import { Buttons } from "src/general_components/Buttons";
import { setComprobantesDigitalizacionesData } from "../../helpers/ComprobantesDigitalizacionesData"
import { getTiposContenidos } from "../../helpers/TipoContenidosData";
import { SearchFormGeneral } from "../SearchFormGeneral";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DigitalizacionesContenido } from "./DigitalizacionesContenido";
import Link from '@mui/material/Link';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import HistoryIcon from "@mui/icons-material/History";
import { getContenidosSearchHistorico } from "../../helpers/HistoricoCajasData";
import { MovimientosTimelineMUI } from "../MovimientosTimelineMUI";
import esArray from "src/general_components/functionEsArray";
import { estadoColores, estadoIconos } from "../estadosCajas";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { getTipoContenidosLocalizaciones } from "src/projects/gestion_comercial/cuentas/helpers/TipoContenidosData";

export const DigitalizacionesList = () => {
  const { dataSearchFormGeneral,setSelectionModel, snackbarVisible, setSnackbarVisible, message, setMessage, severity, setSeverity, 
    setRequiredFormCuentaSector, setRequiredTipoContenido, setShowFormUbicaciones,setShowFormIdCajas,
    setShowFormEstadosCajas, setShowFormFechaAltaCajas, setRequiredSector, setSelected, setListRecords,
    setRows, contenidoSeleccionado, setContenidoSeleccionado, setOmitirQuerySearch,clienteName, metadatosContenidoSeleccionado,
    setDigitalizacionesSeleccionadas, setDigitalizacionesSeleccionadasCompleto,setShowFormMetadatos, 
    digitalizacionesAfacturar, setDigitalizacionesAfacturar, abrirAgregarContenido,openDialogImage, setOpenDialogImage, clienteIdSearch, 
    setSoloCajas, actualizarListado, setActualizarListado
  } = useContext(CajasContext);
  const [open, setOpen] = useState(null);
  const [pushData, setPushData] = useState(false);
  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const { setChildFooterLeft, setChildFooter, setExpand } = useContext(TheLayoutContext);
  let cajaSelect = "";
  // const [tipoContenidoSelect, setTipoContenidoSelect] = useState(tipoContenidoSeleccionado);
  const [eliminar, setEliminar] = useState(false);
  const [dataBusqueda, setDataBusqueda] = useState(false);
  const [tipoContenidoList, setTipoContenidoList] = useState([]);
  const [abrirRespuestaComprobante, setAbrirRespuestaComprobante] = useState(false);
  const [respuestaComprobante, setRespuestaComprobante] = useState("");
  let proceso = "Digitalizacion";
  const strDigitalizacionesSeleccionadasCompleto = digitalizacionesAfacturar.digitalizaciones_contenidos.length > 0 ?
    JSON.stringify(digitalizacionesAfacturar.digitalizaciones_contenidos) : "";
  const [alertImagesSave, setAlertImagesSave] = useState("");
  const [dataPostContenido, setDataPostContenido] = useState({});
  const [cuentaDigitalizaciones, setCuentaDigitalizaciones] = useState(false);
  const [modalStateHistorico, setMOdalStateHistorico] = useState(false);
  const [historicoCaja, setHistoricoCaja] = useState(false);
  const [params, setParams] = useState(false);

  //NOTE SIRVE PARA PODER GUARDAR EL ID DE TIPO DE CONTENIDO Y PASARSELO AL FORMULARIO DE AGREGAR CONTENIDO
  // useEffect(() => {
  //   setTipoContenidoSelect(tipoContenidoSeleccionado);
  // }, [tipoContenidoSeleccionado]);

  var columns = [];
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);
  if (isMobileDevice) {
    columns = [
      {
        field: "clienteName",
        headerName: "Cliente",
        headerAlign: "center",
        flex: 150,
        minWidth: 150,
        maxWidth: 175
      },
      {
        field: "sectorName",
        headerName: "Sector",
        headerAlign: "center",
        flex: 150,
        minWidth: 150,
        maxWidth: 175
      },
      {
        field: "concatCaja",
        headerName: "Caja",
        headerAlign: "center",
        flex: 150,
        minWidth: 150,
        maxWidth: 175
      },
      {
        field: "estados_cajas",
        headerName: "Estado",
        headerAlign: "center",
        flex: 1,
      },
      {
        //Boton Imprimir
        field: "actions",
        headerName: "Acciones",
        type: "actions",
        flex: 2,
        // width: 100,
        // minWidth: 100,
        // maxWidth: 125,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip title="Acciones">
                <span>
                  <MoreVertIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={(e) => {
              handleOpenMenu(e);
              setParams(params)
            }}
            label="Acciones"
            {...(params.id.includes("cliente") || params.id.includes("sector")
              ? { hidden: true }
              : {})}
          />,
          <GridActionsCellItem
            {...(params.id.includes("cliente") || params.id.includes("sector")
              ? { hidden: true }
              : {})}
            icon={
              <Tooltip title="Historico">
                <span>
                  <HistoryIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={() => {
              toggleModalHistorico(params.id);
            }}
            label="Cajas"
          />
        ],
      },
    ];
  } else {
    columns = [
      {
        field: "clienteName",
        headerName: "Cliente",
        headerAlign: "left",
        flex: 100,
        maxWidth: 200,
        minWidth: 50,
      },
      {
        field: "sectorName",
        headerName: "Sector",
        headerAlign: "left",
        flex: 2,
        maxWidth: 200,
        minWidth: 50,
      },
      {
        field: "concatCaja",
        headerName: "Caja",
        headerAlign: "left",
        flex: 1,
      },
      {
        field: "ubicacion",
        headerName: "Ubicacion",
        headerAlign: "left",
        flex: 1,
        maxWidth: 350,
        minWidth: 175,
      },
      {
        field: "tipo_cajas",
        headerName: "Tipo de Caja",
        headerAlign: "left",
        flex: 1,
        maxWidth: 350,
        minWidth: 175,
      },
      {
        field: "estados_cajas",
        headerName: "Estado",
        headerAlign: "left",
        flex: 1,
        maxWidth: 150,
        minWidth: 100,
        renderCell: (params) => {
          if (params.value) {
            const color = estadoColores[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
            const icono = estadoIconos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
            return (
              <Grid>
                <Button
                  size="small"  // Tamaño más pequeño
                  variant="text"
                  style={{

                    color: color,

                  }}
                  disabled  // Desactiva la interacción del botón
                  startIcon={icono}  // Icono en el inicio del botón
                >
                  {params.value}
                </Button>
              </Grid>
            );
          } else {
            return null;
          }
        },
      },
      {
        //Boton Imprimir
        field: "actions",
        headerName: "Acciones",
        type: "actions",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip title="Acciones">
                <span>
                  <MoreVertIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={(e) => {
              handleOpenMenu(e);
              setParams(params)
            }}
            label="Acciones"
            {...(params.id.includes("cliente") || params.id.includes("sector")
              ? { hidden: true }
              : {})}
          />,
          <GridActionsCellItem
            {...(params.id.includes("cliente") || params.id.includes("sector")
              ? { hidden: true }
              : {})}
            icon={
              <Tooltip title="Historico">
                <span>
                  <HistoryIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={() => {
              toggleModalHistorico(params.id);
            }}
            label="Cajas"
          />
        ],
      },
    ];
  }

  const toggleModalHistorico = async (id) => {
    const response = await getContenidosSearchHistorico(id, "caja")
    if (response) {
      setHistoricoCaja(esArray(response.wsResponse.movimientos?.historico));
      toggleModalMovimientos();
    } else {
    }
    console.log("Response", response);
  };


  const toggleModalMovimientos = async () => {
    setMOdalStateHistorico(!modalStateHistorico);
  };


  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };


  //Funcion para el boton imprimir
  const onClickEtiqueta = () => {
    //slice elimina los primer 4 carácter de un string(CAJA)
    const newId = params.id;
    const URI_aux = imprimirEtiquetas(newId);
    if (URI_aux) window.open(URI_aux, "_blank");
  };


  const buttonBuscar = () => (
    <Button
      endIcon={<SearchIcon />}
      onClick={() => {
        setOmitirQuerySearch(true);
        setDataInfoBusqueda({});
        setRequiredFormCuentaSector(true);
        setRequiredTipoContenido(true);
        setAbrirBusqueda(!abrirBusqueda);
        setShowFormUbicaciones(true);
        setShowFormEstadosCajas(true);
        setShowFormFechaAltaCajas(true);
        setShowFormMetadatos(true);
        setShowFormIdCajas(true);
        setRequiredSector(true);
        setSoloCajas(true);
      }}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
      }}
    >
      Buscar
    </Button>
  );

  const [options, setOptions] = useState({
    cols: columns,
    getPaginated: getContenidosSearchPaginated,
    setListRecords: setListRecords,
    pageSize: [5, 10, 25],
    limit: 50,
    filter: true,
    exportData: true,
    server: true,
    notas: true,
    sinFilas: true,
    idElementData: dataSearchFormGeneral,
    hierarchyOn: true,
    detailsOn: true,
    abmContenidos: "false",
    checkboxContenidos: "false",
    digitalizacionesOn: true,
    soloContenidosSearch: true,
    totales: true,
    buttonExtraOn: true,
    ButtonExtra: buttonBuscar
  });


  const obtenerTipoContenido = async () => {
    let tipoContenidos = [];
    let responseStorage = JSON.parse(localStorage.getItem("siam-tipo_contenidos"));
    if (responseStorage === null) {
      tipoContenidos = await getTiposContenidos(true);
    } else {
      let tipoContenidosStorage = esArray(responseStorage.tipo_contenidos);
      if (responseStorage !== null) {
        tipoContenidos = tipoContenidosStorage;
      }
    }
    // TODO: Se hace el filtro por tipo contenidos cuentas localizaciones
    // Tipo Contenidos cuentas localizaciones
    let data = await getTipoContenidosLocalizaciones();
    const id_sector = contenidoSeleccionado.id_cuentas_localizaciones;
    const cuentas_localizaciones_tipo_contenidos = esArray(data).filter(objeto => objeto.id_cuentas_localizaciones === id_sector);
    const tipoContenidosFiltradoCuentaLocalizacion = tipoContenidos.filter((tipoContenido) =>
          cuentas_localizaciones_tipo_contenidos?.some((obj) => obj.id_tipo_contenidos === tipoContenido.id));

    tipoContenidos = tipoContenidosFiltradoCuentaLocalizacion.filter(objeto => objeto.digitalizado === "1");
    if (tipoContenidoList.length === 0) {
      const listRowsTipoContenidos = tipoContenidos.map((tipo_contenido) => {
        tipo_contenido.value = tipo_contenido.id;
        tipo_contenido.label = tipo_contenido.detalle;
        return tipo_contenido;
      });
      setTipoContenidoList(listRowsTipoContenidos);
    }
  };


  useEffect(() => {
    obtenerTipoContenido();
    setRows([]);
  }, [openDialogImage]);
  

  useEffect(() => {
    if (!Array.isArray(dataSearchFormGeneral) && actualizarListado) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: false,
      });
      setActualizarListado(false);
    } else if (Object.entries(dataPostContenido).length > 0) {
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: false,
      });
      setDataPostContenido({});
    } else if (cajaSelect) {
      setOptions({
        ...options,
        sinFilas: true,
      });
    }

    if (abrirAgregarContenido === false && eliminar === false) {
      //SIRVE PARA RESTABLECER LOS VALORES DE LOS SELECTORES COMO VACIO
      setContenidoSeleccionado([]);
      setSelected([]);
    }

    if (alertImagesSave === true) {
      setActualizarListado(true);
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage(
        "Se almacenaron todas las digitalizaciones/actualizaciones correctamente"
      );
      setAlertImagesSave("");
    } else if (alertImagesSave === false) {
      setAlertImagesSave("");
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage(
        "Hubo un error al almacenar las digitalizaciones/actualizaciones"
      );
    }
  }, [
    actualizarListado,
    dataBusqueda,
    abrirAgregarContenido,
    cajaSelect,
    alertImagesSave,
    dataPostContenido,
    dataSearchFormGeneral,
  ]);
  

  //DATOS DEL JSONFORM DE BUSQUEDA
  const [dataInfoBusqueda, setDataInfoBusqueda] = useState({
    grupo: "",
    contrato: "",
    titular: "",
  });


  if (pushData) {
    if (abrirBusqueda) {
      //CIERRO EL DIALOGO DE BUSQUEDA
      setAbrirBusqueda(!abrirBusqueda);
      //SETEO LOS DATOS PARA QUE EL OTRO COMPONENTE PUEDA LEERLOS
      setDataBusqueda(dataInfoBusqueda);
      //SETEO EL ESTADO PARA QUE SE ACTUALICE EL LISTADO
      setActualizarListado(true);
    }
    setPushData(!pushData);
  }


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setOmitirQuerySearch(true);
        setDataInfoBusqueda({});
        setRequiredFormCuentaSector(true);
        setRequiredTipoContenido(true);
        setAbrirBusqueda(!abrirBusqueda);
        setShowFormUbicaciones(false);
        setShowFormEstadosCajas(false);
        setShowFormFechaAltaCajas(false);
        setRequiredSector(true);
        setSoloCajas(true);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  

  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
    setDataBusqueda(dataInfoBusqueda);
    //SETEO EL ESTADO PARA QUE SE ACTUALICE EL LISTADO
    setActualizarListado(true);
  };


  const toggleModalRespuestaComprobante = () => {
    setAbrirRespuestaComprobante(!abrirRespuestaComprobante);
    setRespuestaComprobante("");
    setDigitalizacionesAfacturar({ digitalizaciones_contenidos: [], contenidos: [] })
  };
  

  const limpiarFooter = (prop) => {
    setDigitalizacionesSeleccionadasCompleto({ digitalizaciones_contenidos: [], contenidos: [] });
    setExpand(false);
    setChildFooter(null);
    setChildFooterLeft(null);
    setSelectionModel([])
    setDigitalizacionesAfacturar({ digitalizaciones_contenidos: [], contenidos: [] })
  };


  useEffect(() => {
    if (digitalizacionesAfacturar.digitalizaciones_contenidos.length > 0) {
      setChildFooterLeft(
        <Grid container >
          <Grid item xs={10} textAlign='left' marginLeft={"30%"}>
            <Typography sx={{ fontSize: 14 }}>Cliente : {clienteName}</Typography>
            <Typography sx={{ fontSize: 14 }}>Digitalizaciones: {strDigitalizacionesSeleccionadasCompleto} </Typography>
            <Typography sx={{ fontSize: 14 }}>Total Contenidos: {digitalizacionesAfacturar.contenidos.length}</Typography>
            <Typography sx={{ fontSize: 14 }}>Total Digitalizaciones: {digitalizacionesAfacturar.digitalizaciones_contenidos.length}</Typography>

          </Grid>
        </Grid>
      );
      setChildFooter(
        <Grid marginRight={'20%'}>
          <Buttons
            labelButtonSave={"Generar"}
            labelButtonClose={"Cancelar"}
            buttonCerrar={limpiarFooter}
            onClickSubmit={onClickSubmit}
          />
        </Grid>
      );
      setExpand(true);
    } else {
      setChildFooterLeft(null);
      setChildFooter(null);
      setExpand(false);
    }
  }, [clienteName, digitalizacionesAfacturar]);


  useEffect(() => {
    if (digitalizacionesAfacturar.digitalizaciones_contenidos.length > 0) {
      setCuentaDigitalizaciones(clienteIdSearch.id_cuentas);
    } else {
      setCuentaDigitalizaciones(false);
    }
  }, [digitalizacionesAfacturar]);


  //NOTE FUNCION PARA CONSUMIR SERVICIO COMPROBANTES
  const onClickSubmit = async () => {
    let response = await setComprobantesDigitalizacionesData(digitalizacionesAfacturar)
    if (response.status === 'true') {
      setRespuestaComprobante(response);
      setAbrirRespuestaComprobante(true);
    } else {
      setMessage(response.message);
      setSeverity('warning');
      setSnackbarVisible(true);
    }
    setDigitalizacionesAfacturar({ digitalizaciones_contenidos: [], contenidos: [] })
    setDigitalizacionesSeleccionadas({ digitalizaciones_contenidos: [], contenidos: [] })
    setDigitalizacionesSeleccionadasCompleto({ digitalizaciones_contenidos: [], contenidos: [] })
  };

  //NOTE FUNCION PARA COPIAR URL
  const onClickCopy = async () => {
    navigator.clipboard.writeText(respuestaComprobante.wsResponse.link);
    setMessage("Se a copiado la URL correctamente");
    setSeverity('success');
    setSnackbarVisible(true);
  };

  return (
    <Grid style={{ background: "white", paddingBottom: "0%" }}>
      {/* NOTE BOTON BUSCAR CONTENIDO QUE ABRE EL FORMULARIO */}
      {abrirBusqueda && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirBusqueda}
          modalName={"Busqueda de contenidos"}
          child={
            <SearchFormGeneral
              toggleModal={toggleModal}
              proceso={proceso}
              dialogPreguntaDistintoCliente={true}
              onClickSubmitDistintoCliente={limpiarFooter}
              cuentaDigitalizaciones={cuentaDigitalizaciones}
              digitalizado={true} />
          }
          setPushData={setPushData}
          handleClose={toggleModal}
          buttonEnviar={false}
          buttonCancelar={true}
        ></SimpleDialogComponent>
      )}
      {/* NOTE LIST TABLE DE CONTENIDOS */}
      <Grid style={{ paddingTop: "10px" }}>
        <ListTablePaginated options={options} />
      </Grid>

      {/* NOTE AGREGAR CONTENIDOS DIGITALIZACIONES */}
      {/* <AgregarContenido
        tipoContenidoSelect={tipoContenidoSelect}
        contenidoSeleccionado={contenidoSeleccionado}
      /> */}

      {modalStateHistorico && (
        <SimpleDialogComponent
          open={modalStateHistorico}
          modalName={"Historico"}
          child={<MovimientosTimelineMUI data={historicoCaja} />}
          setPushData={toggleModalMovimientos}
          handleClose={toggleModalMovimientos}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}

      {/* NOTE DIALOGO PARA EDITAR LAS IMAGENES */}
      {openDialogImage && (
        <DigitalizacionesContenido
          openDialogImage={openDialogImage}
          contenidoSeleccionado={contenidoSeleccionado}
          setOpenDialogImage={setOpenDialogImage}
          setAlertImagesSave={setAlertImagesSave}
          setActualizarListado={setActualizarListado}
          setEliminar={setEliminar}
          tipoContenidoList1={tipoContenidoList}
          // setTipoContenidoList={setTipoContenidoList}
          metadatosContenidoSeleccionado={metadatosContenidoSeleccionado}
        />
      )}
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
      {abrirRespuestaComprobante && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirRespuestaComprobante}
          modalName={"Resultado de la Solicitud"}
          child={
            <Grid sx={{
              mt: 1, minWidth: "100%", textAlign: "center",
              wordBreak: "break-all"
            }}>
              <InfoIcon sx={{ fontSize: "400%", color: "#2669F0" }} />
              <Typography align="center" variant="h4" gutterBottom component="div">
                {respuestaComprobante.message}
              </Typography>
              <Grid wrap="nowrap">Link:
                <Link target="_blank" href={respuestaComprobante.wsResponse.link} variant="body2">
                  {respuestaComprobante.wsResponse.link}
                </Link>
              </Grid>
              <Grid sx={{ paddingTop: "30px" }}>
                <Buttons
                  onClickSubmit={
                    onClickCopy
                  }
                  labelButtonSave={"Copiar URL"}
                  buttonCerrar={toggleModalRespuestaComprobante}
                />
              </Grid>
            </Grid>
          }
          setPushData={setPushData}
          handleClose={toggleModalRespuestaComprobante}
          buttonEnviar={false}
          buttonCancelar={true}
        ></SimpleDialogComponent>
      )}
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={onClickEtiqueta}>
          <StyleIcon color="info" sx={{ mr: 2 }} />
          Imprimir
        </MenuItem>
      </Popover>
    </Grid>
  );
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { esArray } from "src/general_components/functionEsArray";
import { Autocomplete, TextField } from "@mui/material";
import { getFromLS } from "src/general_helpers/getFromLS";
import { getCodigoCajasData } from "src/projects/gestion_documental/helpers/CogidoCajasData";

const SelectorCodigoCajas = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const valueCodigoCajas = jsonForm.core.data.codigo_cajas;

  const codigo_caja = getFromLS("siam-codigo_caja").codigo_caja;
  const [selectedCodigoCajas, setSelectedCodigoCajas] = useState("");

  useState(() => {
    const getCodigoCajas = async () => {
      if (codigo_caja) {
        setDataDefaults(esArray(codigo_caja));
      } else {
        const response = await getCodigoCajasData()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    getCodigoCajas()

  }, []);

  useEffect(() => {
    setSelectedCodigoCajas(dataDefaults.find((item) => item.id === valueCodigoCajas));
  }, [dataDefaults, valueCodigoCajas]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setSelectedCodigoCajas(newValue);
      props.handleChange(props.path, newValue.id); // Obtén el ID del objeto seleccionado
    } else {
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ minWidth: "100%", width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
          value={selectedCodigoCajas ? selectedCodigoCajas : null}
          onChange={handleChange}
          options={dataDefaults || []}
          getOptionLabel={(option) => option.id + ' - ' + option.detalle || ""}
          renderInput={(params) => (
            <TextField {...params}
              label="Caja"
              disabled={disabled}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
const SelectorCodigoCajasTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorCodigoCajas";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorCodigoCajasComponent = {
  renderer: withJsonFormsControlProps(SelectorCodigoCajas),
  tester: SelectorCodigoCajasTester,
};

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { useContext } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";

export const SwitchCuentaGenerica = () => {
  const { altaGenerica, setAltaGenerica, disabled } = useContext(CajasContext);

  const handleChange = (event) => {
    setAltaGenerica(event.target.checked);
  };

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Evita que el clic se propague al FormGroup
  };


  return (
    <FormGroup>
      <div onClick={handleContainerClick}>
        <FormControlLabel
          control={
            <Switch
              {...(disabled ? { disabled } : {})}
              checked={altaGenerica}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Cuenta Generica"
          sx={{

            alignItems: 'center',
            fontSize: 11,
            fontFamily: 'Public Sans, sans-serif',
            fontWeight: 400,
            color: "#0A395F",
          }}
        />
      </div>
    </FormGroup>
  );
};

import { useState } from "react";
import { getCategorias, getCategoriasPaginated, postCategoria, deleteCategoria } from "../helpers/CategoriasData";
import { BasicFormIdDetalle } from "src/general_components/BasicFormIdDetalle";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import { Grid } from "@mui/material";
import { HeaderTitleBreadcrumbs } from "src/general_components/HeaderTitle&Breadcrumbs";
import { obtenerProcesosPorPerfilYMenu } from "src/general_components/functionEsArray";

export const CategoriasList = () => {
  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "nombre", headerName: "Nombre", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
  ];
  const listName = "Categorias";

  const [tipoServicio, setTipoServicio] = useState([]);
  const [createButton, setCreateButton] = useState(false);
  const restricciones = obtenerProcesosPorPerfilYMenu();
  let alta = true;
  let baja = true;
  let modificacion = true;
  if (restricciones) {
    alta = restricciones.alta;
    baja = restricciones.baja;
    modificacion = restricciones.modificacion;
  }

  const options = {
    cols: columns,
    get: getCategorias,
    getPaginated: getCategoriasPaginated,
    pageSize: [50, 100, 150, 200],
    edit: modificacion,
    delete: baja ? deleteCategoria : false,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setTipoServicio,
    hiddenButtonAgregar: !alta ? "true" : false
  };

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  if (createButton === false && tipoServicio.id !== undefined) {
    setTipoServicio([]);
  }

  return (
    <>
      <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
        <HeaderTitleBreadcrumbs listName={listName} />
        <ListTablePaginated options={options} />
        <BasicFormIdDetalle
          data={tipoServicio}
          createButton={createButton}
          listName={listName}
          setCreateButton={setCreateButton}
          componentList={"/categorias/admin"}
          postData={postCategoria}
        />
      </Grid>
    </>
  );
};

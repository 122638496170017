import React, { useEffect, useState } from 'react'; // Importa React, useEffect y useState
import { getDataWhereId } from 'src/hooks/structureTable';
import { TextField } from '@mui/material'; // Importa TextField desde Material-UI

export const ComprobantesExtraData = ({ data }) => {
    const [transformedData, setTransformedData] = useState([]); // Utiliza useState para manejar transformedData

    useEffect(() => {
        const transformData = async (data) => {
            try {
                const transformItem = async (item) => {
                    const extraData = JSON.parse(item.extra_data.replace(/'/g, '"'));
                    const transformedData = {};
                    const promises = [];

                    for (let key in extraData) {
                        const label = key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
                        const field = extraData[key].field;
                        let value = extraData[key].value;
                        const esNumber = parseInt(value);
                        if (field.startsWith('id_') && !isNaN(esNumber)) {
                            const fieldName = field.replace('id_', '');
                            const datosPromise = getDataWhereId(fieldName, field, extraData[key].value);
                            promises.push(
                                (async () => {
                                    try {
                                        const data = await datosPromise;
                                        value = data.nombre || data.descripcion || data.detalle || data.label || data.numero_comprobante || 'Sin datos';
                                        transformedData[label] = { field, value };
                                    } catch (error) {
                                        console.error('Error al obtener datos:', error);
                                    }
                                })()
                            );
                        } else {
                            transformedData[label] = { field, value };
                        }
                    }

                    await Promise.all(promises);
                    return transformedData;
                };

                const transformedDataArray = await Promise.all(data.map(transformItem));
                setTransformedData(transformedDataArray); // Actualiza el estado con los datos transformados
            } catch (error) {
                console.error('Error al transformar los datos:', error);
                throw error; // Propaga el error hacia arriba para que pueda ser capturado por el llamador
            }
        };

        // Llama a la función transformData cuando data cambia
        transformData(data);
    }, [data]); // Ejecuta el efecto solo cuando data cambia

    return (
        <div>
            {transformedData.map((item, index) => (
                <div key={index}>
                    {Object.entries(item).map(([label, { field, value }]) => (
                        <div key={field}>
                            <TextField
                                label={label}
                                value={value}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </div>
                    ))}
                    <hr />
                </div>
            ))}
        </div>
    );
};
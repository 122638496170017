import datos from "src/services/datos";

export const getCuentasServicios = async (id_cuenta, id_servicio) => {
    const objectCuentasJuridicas = new datos("cuentas_personas_juridicas")

    objectCuentasJuridicas.addCondition("cuentas_personas_juridicas", "id", 0, ">")

    const response = await objectCuentasJuridicas.get()

    if (response) {
        return [...response.wsResponse.cuentas_articulos]
    }
    return response
}

export const getCuentasServiciosPaginated = async (id_cuentas, id_articulos, limit, offset) => {
    const objectCuentasServicios = new datos("cuentas_articulos")
    objectCuentasServicios.addCondition("cuentas_articulos", "id", 0, ">")
    id_cuentas && objectCuentasServicios.addCondition("cuentas_articulos", "id_cuentas", id_cuentas, "=")
    id_articulos &&  objectCuentasServicios.addCondition("cuentas_articulos", "id_articulos", id_articulos, "=")
    objectCuentasServicios.addLimitCondition(limit, offset)
    const response = await objectCuentasServicios.get()
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/
    
    let result = {}
    if (!response.wsResponse.empty) {

        result = {
            data: [...response.wsResponse.cuentas_articulos],
            total_records: response.wsResponse.total_records
        }
        return result

    }
};

export const getCuentasServiciosPlain = async (id_cuentas, id_articulos) => {
    const objectCuentasServicios = new datos("cuentas_articulos")
    objectCuentasServicios.addCondition("cuentas_articulos", "id", 0, ">")

    id_cuentas && objectCuentasServicios.addCondition("cuentas_articulos", "id_cuentas", id_cuentas, "=")
    id_articulos &&  objectCuentasServicios.addCondition("cuentas_articulos", "id_articulos", id_articulos, "=")

    const response = await objectCuentasServicios.get()
    
    if (!response.wsResponse.empty) {
        return response.wsResponse.cuentas_articulos
    }
};

export const postCuentasServicios = async (cuenta_servicio) => {
    const objectCuentasServicios = new datos("cuentas_articulos")
    return objectCuentasServicios.insert(cuenta_servicio);
}

export const deleteCuentasServicios = async (id) =>{
    const objectCuentasServicios = new datos("cuentas_articulos")
    return objectCuentasServicios.delete({id: id});
}
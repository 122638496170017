import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getProvincia } from "src/general_helpers/ProvinciaData";

import { Autocomplete, TextField } from "@mui/material";
import { esArray } from "src/general_components/functionEsArray";

const SelectorProvincia = (props) => {
  
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  //const disabled = jsonForm.core.data.disabled;
  const id_provincia = jsonForm.core.data.id_provincia;
  const id_pais=jsonForm.core.data.id_pais;

  // const arrayFiltrado = data.filter(objeto => objeto.id_pais === pais);
  //     setProvinciasList(arrayFiltrado);



  //const [paises, setPaises] = useState([])
  var provinciasLocalStorage=JSON.parse(localStorage.getItem("siam-provincia"));

  useEffect(() => {
    const getProvincias = async () => {
      if(provinciasLocalStorage !== null){
          const arrProvincias=esArray(provinciasLocalStorage.provincia);
          const arrayFiltrado = arrProvincias.filter(objeto => objeto.id_pais === id_pais);
         
          setDataDefaults(arrayFiltrado);
      }else{
          const provinciasObtenidos = await getProvincia(id_pais);
          if (provinciasObtenidos) {
            setDataDefaults([...provinciasObtenidos]);
          }
      }
      // if (id_provincia) {
      //     setProvinciaSeleccionado(props.data);
      // }
      
      if(id_pais === ""){
        setDataDefaults([]);
        setProvinciaSeleccionado("");
        props.handleChange(props.path, "");
      }
    };
    getProvincias();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_pais]);

  useEffect(() => {

    if (dataDefaults) {

      const provinciaEncontrado = dataDefaults.find((provincia) => provincia.id === id_provincia);
      setProvinciaSeleccionado(provinciaEncontrado);
    }
    
}, [dataDefaults,id_provincia]);


  const [provinciaSeleccionado, setProvinciaSeleccionado] = useState("");
  //funcion para devolver al formulario la data seleccionada y para setearla en el selector
  const handleChange = (event,newValue) => {
    if (newValue && newValue !== "") {
      setProvinciaSeleccionado(newValue);
      props.handleChange(props.path, newValue.id);
    }else{
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
         <Autocomplete
        value={provinciaSeleccionado  ? provinciaSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.nombre || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Provincias" />
        )}
      />
      </FormControl>
    </div>
  );
};

 /*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
  const SelectorProvinciaTester = rankWith(
    100,
    and(
      uiTypeIs("Control"),
      schemaTypeIs("string"),
      schemaMatches((schema) => {
        if (schema.hasOwnProperty("customRender")) {
          let cellschema = schema;
          return cellschema["customRender"] === "SelectorProvincia";
        }
        return false;
      })
    )
  );
  
  // se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
  export const SelectorProvinciaComponent = {
    renderer: withJsonFormsControlProps(SelectorProvincia),
    tester: SelectorProvinciaTester,
  };


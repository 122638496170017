import { useEffect, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { Buttons } from "src/general_components/Buttons";
import { Grid, Input, Button } from "@mui/material";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import BuscarCajas from "../components/Digitalizaciones/BuscarCajas";
import { CajasContext } from "../../../context/CajasContext/CajasContext";
import { Search } from "@mui/icons-material";

export const ContenidosFormDinamico = (props = null) => {
  /**
   **       inputsListValues: Contiene todos los inputs que se van a crear en el formulario
   *        toggleModal: Funcion que sirve para cerrar el modal,
   *        setInputsValues: Funcion para setear los valores que se van agregando o modificando en el formulario.
   *        handleSubmit: Funcion para consumir el servicio del proceso en el que se este.
   */

  const {
    inputsListValues,
    toggleModal,
    setInputsValues,
    handleSubmit,
    contenidoSeleccionado,
    selectorCajas,
  } = props;

  const { clienteSeleccionado, sectorIdSearch } = useContext(CajasContext);
  // const upperCaseTransformer = (value) => (value ? value.toUpperCase() : "");
  const [abrirBusquedaCajas, setAbrirBusquedaCajas] = useState(false);
  const [inputFields, setInputFields] = useState(inputsListValues);
  let cantInputFields = inputFields.length;  //  inputFields.length - 1 : inputFields.length;

  const handleChangeInput = (id, event, name) => {
    const inputElement = event?.target;
    const cursorPos = inputElement?.selectionStart; // Guarda la posición actual del cursor
    let esTexto = false;

    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (event?.$d) {
          var dateValue = moment(event?.$d).format("DD-MM-YYYY");
          i["valor"] = dateValue;
        } else {
          if (i["label_form"] === "DNI") {
            let valor = event?.target?.value.toString();;
            const regex = /^[0-9\b]+$/;
            if (event?.target?.value === "" || regex.test(valor)) {
              i["valor"] = event.target.value;
            }
          } else {
            if (i["tipo_dato"] === "CHAR") {
              esTexto = event ? true : false;
            }

            i["valor"] = event ? event.target.value.toUpperCase() : "";
          }

        }
      }
      return i;
    });

    setInputFields(newInputFields);
    setInputsValues(newInputFields);

    // Restaura la posición del cursor después de actualizar el estado
    if (esTexto) {
      setTimeout(() => {
        inputElement.setSelectionRange(cursorPos, cursorPos);
      }, 0);
    }
  };

  useEffect(() => {
    setInputFields(inputsListValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsListValues]);

  let textoInput = "";
  const toggleModalForm = () => {
    toggleModal();
    setInputFields([]);
  };

  function esImpar(numero) {
    return numero % 2 !== 0;
  }

  return (
    <>
      {/* NOTE FORMULARIO DEL SELECTOR DE CAJA */}
      {abrirBusquedaCajas && (
        <SimpleDialogComponent
          open={abrirBusquedaCajas}
          modalName={"Buscar Cajas"}
          child={
            <BuscarCajas
              sector={{
                cuenta: clienteSeleccionado,
                localizacion: sectorIdSearch.id,
              }}
              onClose={setAbrirBusquedaCajas}
            />
          }
          handleClose={() => {
            setAbrirBusquedaCajas(false);
          }}
          buttonEnviar={false}
          maxWidth="xl"
        />
      )}

      {/* NOTE SE ABRE EL SELECTOR DE CAJAS SI SE LE PASA UN TRUE COMO PROP */}
      {selectorCajas && (
        <>
          <Grid>
            <Input
              placeholder="Seleccione las cajas ->"
              value={textoInput}
              disabled
            />
            <Button
              hidden={
                contenidoSeleccionado.id_contenidos &&
                  contenidoSeleccionado.id_contenidos !== ""
                  ? true
                  : false
              }
              variant="contained"
              onClick={() => {
                setAbrirBusquedaCajas(!abrirBusquedaCajas);
              }}
            >
              <Search />
            </Button>
          </Grid>
        </>
      )}

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {inputFields.map((inputField, index) =>
            inputField.tipo_dato === "DATE" ? (
              <DatePicker
                key={inputField.id}
                name={inputField.label_form}
                label={inputField.label_form}
                inputFormat="DD-MM-YYYY"
                value={inputField.valor === "" ? null : moment(inputField.valor, 'DD-MM-YYYY').toDate()}
                variant="outlined"
                onChange={(date) => {
                  //NOTE SE AGREGO UN DISTINTO DE NULL YA QUE CUANDO SE BORRA LA FECHA EL DATEPICKER SE CERRABA EL MODAL

                  handleChangeInput(inputField.id, date, "valor");

                }}
                renderInput={(params) => (
                  <TextField
                    name="valor"
                    size="small"
                    sx={{
                      m: 1,
                      minWidth: esImpar(cantInputFields) && index === cantInputFields ? "98%" : "48%",
                    }}
                    {...params}
                  />
                )}
              />
            ) : (
              <TextField
                name={inputField.label_form}
                label={inputField.label_form}
                variant="outlined"
                size="small"
                key={inputField.id}
                type={inputField.tipo_dato === "CHAR" ? "text" : "number"}
                inputProps={{ maxLength: 1023 }}
                sx={{
                  m: 1,
                  minWidth: esImpar(cantInputFields) && index === cantInputFields ? "98%" : "48%",
                }}
                value={inputField.valor}
                onChange={(event) => handleChangeInput(inputField.id, event)}
              />
            )
          )}
        </LocalizationProvider>
      </Box>
      <Grid sx={{ mt: 1 }}>
        <Buttons
          disableButton={false}
          onClickSubmit={handleSubmit}
          labelButtonSave={"Confirmar"}
          buttonCerrar={toggleModalForm}
        />
      </Grid>
    </>
  );
};

import * as React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export const SnackbarMessenger = (props) => {
  const {
    open, // setea para abrir el snackbar
    message, // setea el mensaje a mostrar
    severity, // setea la severity del mensaje (ver Enum mas arriba)
    hide, // setea el tiempo que se va a mostrar, por defecto, 2 seg
    close,
    hideDisabled,
  } = props; // setea la funcion que cierra el snackbar (en la llamada al componente, usar un setOpen(false) y listo)

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={close}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}

        autoHideDuration={hideDisabled ? null : (hide || 9000)}
        onClose={hideDisabled ? null : close}
        sx={{ zIndex: 400000 }}
      >
        <Alert severity={severity} sx={{ width: "100%", whiteSpace: 'pre-line', mb: 2 }} style={{ textAlign: 'left' }}
          action={hideDisabled ? action : null}>
          <AlertTitle>Info</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export const schemaCajasContenidos = {
  type: "object",
  properties: {
    idCaja: {
      type: "number",
      customRender: "InputNumber",
      description: "Ingrese una Cantidad",
    },
    detalle: {
      type: "string",
      description: "Ingrese el detalle",
    },
    fecha_desde: {
      type: "string",
      customRender: "DateForm",
      description: "Seleccione una fecha",
    },
    fecha_hasta: {
      type: "string",
      customRender: "DateForm",
      description: "Seleccione una fecha",
    },
    numero_desde: {
      type: "number",
      description: "Ingrese un numero",
    },
    numero_hasta: {
      type: "number",
      description: "Ingrese un numero",
    },
  },
};

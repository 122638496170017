import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { Typography, Container, Grid, Button } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { SnackbarMessenger } from 'src/general_components/SnackbarMessenger';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import './styles/Dropzone-styles.css';
import MyModal from "src/general_components/ModalNew";
import { getCajasExistence, postContenidos } from "../helpers/ImportFileData"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import esArray from "src/general_components/functionEsArray";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { getMetadatos } from "../helpers/MetadatoData";
import { postCajas } from "../helpers/CajasData";
import { getCuentasLocalizacionesAll } from "src/projects/gestion_comercial/helpers/CuentasLocalizaciones";
import Papa from 'papaparse';



export const ImportFile = () => {
  const ExcelJS = require('exceljs');
  const { snackbarVisible, message, severity, setSnackbarVisible,
    setSeverity, setMessage } = useContext(CajasContext);
  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successResponses, setSuccessResponses] = useState([]);
  const [errorResponses, setErrorResponses] = useState([]);
  const [tittle, setTittle] = useState("Errores de validación");
  const [clienteId, setClienteId] = useState(null);
  const [clientesList, setClientesList] = useState([]);
  const [crearCajas, setCrearCajas] = useState(false);
  // const [metadatosList, setMetadatosList] = useState([]);



  const handleFileChange = (files) => {
    const selectedFile = files[0];
    setFile(selectedFile);
  };

  const handleImport = async () => {
    if (!clienteId || Array.isArray(clienteId) || clienteId === null) {
      setMessage("Debe seleccionar un cliente");
      setSeverity("warning");
      setSnackbarVisible(true);
      return;
    } else if (!file) {
      setMessage("Debe seleccionar un archivo");
      setSeverity("warning");
      setSnackbarVisible(true);
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const contents = e.target.result;
        let parsedData = [];

        if (file.name.endsWith('.csv')) {
          parsedData = await parseCSV(contents);
        } else if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
          parsedData = await parseExcel(contents);
        }


        const metadatos = await getTodosMetadatos();
        validateDataStructure(parsedData, metadatos);
      };

      reader.readAsBinaryString(file);
    }
  };

  const parseCSV = (contents) => {
    return new Promise((resolve) => {
      Papa.parse(contents, {
        header: true,
        complete: (results) => {
          resolve(results.data);
        }
      });
    });
  };
  


  const getTodosMetadatos = async () => {
    // setIsLoadingData(true);

    try {
      const response = await getMetadatos();


      // setMetadatosList(response);
      return response;
    } catch (error) {
      // Manejar el error según sea necesario
      console.error("Error al obtener metadatos:", error);
    } finally {
      // setIsLoadingData(false);
    }
  };




const parseExcel = async (contents) => {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(contents);

  const worksheet = workbook.worksheets[0];
  const jsonData = [];

  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    jsonData.push(row.values);
  });

  // Eliminar el primer elemento de cada fila (porque ExcelJS incluye un valor vacío en el índice 0)
  const cleanedData = jsonData.map(row => row.slice(1));

  return cleanedData;
};

  const handleCloseModal = () => {
    setOpenModal(false);
    if (successResponses.length > 0 || errorResponses.length > 0) {
      setClienteId(null);
      setFile(null);
      setSuccessResponses([]);
      setErrorResponses([]);
    }
  };

  const validateDataStructure = async (data, metadatos) => {
    const validationErrors = [];

    const idTipoContenidoSet = new Set();
    const idTipoCajaSet = new Set();
    const idSectorSet = new Set();
    const idCajaSet = new Set();
    let noTieneIdCaja = false;
    let noTieneIdTipoCaja = false;
    let noTieneIdSector = false;
    let noTieneIdTipoContenido = false;

    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      const row = data[rowIndex];
      const {
        id_tipo_contenido,
        id_tipo_caja,
        id_sector,
        texto,
        numero,
        numero_desde,
        numero_hasta,
        fecha,
        fecha_desde,
        fecha_hasta,
        id_caja,
      } = row;

      // Validar si el Switch "Crea Cajas" está inactivo
      if (!crearCajas) {
        // Verificar que la columna ID caja de cada fila está completa
        if (!id_caja) {
          noTieneIdCaja = true;
          validationErrors.push(`Error: Al no crear cajas, debe ingresar el ID de caja en todas las filas. Fila ${rowIndex + 1} no tiene ID de caja`);
        }

        // Recopilar los ID para realizar consulta por el servicio getData
        idCajaSet.add(id_caja);
      }

      // Verificar que las columnas "id tipo caja" y "Id Sector" tengan datos si "Crea Cajas" está activo
      if (crearCajas) {

        if (!id_tipo_caja) {
          noTieneIdTipoCaja = true;
          validationErrors.push(`Error: Si crea cajas debe indicar el ID de tipo de caja. Fila ${rowIndex + 1}, no tiene ID de tipo de caja`);
        }

        if (!id_sector) {
          noTieneIdSector= true;
          validationErrors.push(`Error: Fila ${rowIndex + 1}, debe indicar el ID de sector`);
        }

        // Recopilar los datos para verificar que los ID's existan
        idTipoCajaSet.add(id_tipo_caja);
        idSectorSet.add(id_sector);
      }

      if (!id_tipo_contenido) {
        noTieneIdTipoContenido= true;
        validationErrors.push(`Error: Fila ${rowIndex + 1},debe indicar el ID de tipo de contenido`);
      }

      // Verificar que al menos una de las columnas de contenido tenga un valor
      if (!(texto || numero || numero_desde || numero_hasta || fecha || fecha_desde || fecha_hasta)) {
        validationErrors.push(`Error: Fila ${rowIndex + 1}, ninguna de las columnas de contenido tiene datos`);
      }

      // Recopilar todos los ID tipo de contenido del archivo
      idTipoContenidoSet.add(id_tipo_contenido);
    }

    // Verificar que los ID de tipo de contenido existan en local storage
    if (!noTieneIdTipoContenido) {
      for (const idTipoContenido of idTipoContenidoSet) {
        // array de todos los tipos de contenidos
        const todosTipoContenidos = JSON.parse(localStorage.getItem("siam-tipo_contenidos"))?.tipo_contenidos;

        const tipoContenidoExistente = todosTipoContenidos.find(tipoContenido => tipoContenido.id === idTipoContenido);

        if (!tipoContenidoExistente) {
          validationErrors.push(`Error: Fila con ID de tipo de contenido ${idTipoContenido} no existe, verifique`);
        }
      }
    }

    // Verificar que los ID de tipo de caja existan en local storage
    if (!noTieneIdTipoCaja) {
      for (const idTipoCaja of idTipoCajaSet) {
        const tiposCajasLocalStorage = JSON.parse(localStorage.getItem("siam-tipo_cajas"))?.tipo_cajas;

        // Verificar la existencia del ID de tipo de caja en local storage
        const tipoCajaExistente = tiposCajasLocalStorage.find(tipoCaja => tipoCaja.id === idTipoCaja);

        if (!tipoCajaExistente) {
          validationErrors.push(`Error: Fila con ID de tipo de caja ${idTipoCaja} no existe, verifique`);
        }
      }
    }


    // Verificar que los ID de sector existan en local storage
    if (!noTieneIdSector) {
      for (const idSector of idSectorSet) {
        // const cuentasLocalizaciones = JSON.parse(localStorage.getItem("siam-cuentas_localizaciones"))?.cuentas_localizaciones;
        const cuentasLocalizaciones =  await getCuentasLocalizacionesAll();

        // Verificar la existencia del ID de sector en local storage
        const sectorExistente = cuentasLocalizaciones.find(cuentaLocalizacion => cuentaLocalizacion.id === idSector);

        if (!sectorExistente) {
          validationErrors.push(`Error: Fila con ID de sector ${idSector} no existe, verifique`);
        }
      }
    }

    // Verificar que las cajas existan mediante consulta por el servicio getData
    if (!crearCajas && !noTieneIdCaja) {
      console.log("idCajaSet",idCajaSet)
      const cajasExisten = await checkCajasExistence(Array.from(idCajaSet));
      if (cajasExisten.length > 0) {
        validationErrors.push(`Error: No todas las cajas existen en la base de datos`);
        cajasExisten.forEach(idCaja => validationErrors.push(`ID Caja: ${idCaja} no existe, verifique`));
      }
    }

    // Si hay errores después de la consulta, mostrar mensajes y cortar el proceso
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      setOpenModal(true);
      return;
    }

    // Continuar con el proceso de importación
    executeProcess(data, metadatos);
  };


  // const extractLabels = (array) => {
  //   return array.map((obj) => obj.label_grilla);
  // };

  const checkCajasExistence = async (cajaIds) => {
    const responseCajasExistentes = await getCajasExistence(cajaIds);
    const cajasIdsResponse = responseCajasExistentes.map((row) => row.id);
    console.log("cajasIdsResponse", cajasIdsResponse)
    const missingCajas = cajaIds.filter((cajaId) => !cajasIdsResponse.includes(cajaId));
    return missingCajas;
  };


  const executeProcess = async (parsedData, listaMetadatos) => {
    // Mostrar mensajes en el modal
    setTittle("Resultado de la Importacion");
    setOpenModal(true);

    for (let rowIndex = 0; rowIndex < parsedData.length; rowIndex++) {
      const row = parsedData[rowIndex];
      const tipoContenidoId = row.id_tipo_contenido;
      const idTipoCaja = row.id_tipo_caja;
      const idSector = row.id_sector;
      const lote = row.lote;

      // Filtrar metadatos del archivo y comparar con metadatos list
      const metadataKeys = Object.keys(row).filter(
        (key) =>
          key !== "id" &&
          key !== "id_caja" &&
          key !== "id_tipo_caja" &&
          key !== "id_sector" &&
          key !== "id_tipo_contenido"
      );

      const metaDatos = [];

      for (const metadataKey of metadataKeys) {
        const value = row[metadataKey];
        if (value !== "") {
          const metadata = listaMetadatos.find(
            (meta) => meta.label_grilla === metadataKey
          );

          if (metadata) {
            const idMetadatos = metadata.id;
            metaDatos.push({ id: "", id_metadatos: idMetadatos, valor: value });
          }
        }
      }

      if (metaDatos.length === 0) {
        const errorMessage = `Fila ${rowIndex + 1} sin valores de metadatos`;
        setErrorResponses((prevResponses) => [...prevResponses, errorMessage]);
        continue;
      }

      let cajaId = row.id_caja;

      if (crearCajas) {
        // Crear la caja
        const cajaData = {
          id_tipo_cajas: idTipoCaja,
          id_cuentas_localizaciones: idSector,
          id_estados_cajas: 1,
          lote: lote? lote : "1"
        };

        const createCajaResponse = await postCajas(cajaData);

        if (createCajaResponse.status === "true") {
          cajaId = createCajaResponse.wsResponse.cajas;

          const successCajaMessage = `Se creó la caja con ID ${cajaId} (Fila ${rowIndex + 1})`;
          setSuccessResponses((prevResponses) => [...prevResponses, successCajaMessage]);
        } else {
          const errorCajaMessage = `La creación de la caja falló debido a "${createCajaResponse.message}" (Fila ${rowIndex + 1})`;
          setErrorResponses((prevResponses) => [...prevResponses, errorCajaMessage]);
          continue; // Saltar a la siguiente fila si la creación de la caja falla
        }
      } else {
        // Si no se crean cajas, el ID de caja se debe tomar de la fila directamente
        cajaId = row.id_caja;
      }

      const contenidos = {
        id: "",
        id_cajas: cajaId,
        id_tipo_contenidos: tipoContenidoId,
        meta_datos: metaDatos,
      };

      // Llamar al servicio de creación de contenidos
      const createContenidoResponse = await postContenidos(contenidos);

      if (createContenidoResponse.status === "false") {
        const errorContenidoMessage = `La creación del contenido para la caja ${cajaId} falló debido a "${createContenidoResponse.message}" (Fila ${rowIndex + 1})`;
        setErrorResponses((prevResponses) => [...prevResponses, errorContenidoMessage]);
      } else {
        const successContenidoMessage = `Se insertó el contenido con ID ${createContenidoResponse.wsResponse.contenidos.id} para la caja ${cajaId} (Fila ${rowIndex + 1})`;
        setSuccessResponses((prevResponses) => [...prevResponses, successContenidoMessage]);
      }
    }
  };

  useEffect(() => {
    // Actualizar los arrays de mensajes al cambiar successfulResponses y errorResponses
    if (successResponses.length > 0 || errorResponses.length > 0) {
      setOpenModal(true);
    }
  }, [successResponses, errorResponses]);



  useEffect(() => {
    setClienteId(null);
    const getClientes = async () => {
      var clientes = [];
      let cuentas = esArray(JSON.parse(localStorage.getItem("siam-service-getCuentasGenericas")).cuentas);
      let condicionIN = false;


      let clients = esArray(JSON.parse(localStorage.getItem("siam-cuentas_personas_juridicas")).cuentas_personas_juridicas);
      clientes = clients.filter((cliente) =>
        cuentas.find(
          (cuenta) => {
            if (condicionIN === true) {
              return cuenta.id_cuentas === cliente.id_cuentas

            } else {
              return cuenta.id_cuentas !== cliente.id_cuentas
            }
          }
        )
      );

      const user_cuentas = JSON.parse(localStorage.getItem("siam-user-cuentas"));
      let userCuentasStorage = esArray(user_cuentas);
      if (userCuentasStorage.length < 2) {
        let usersCuentasModificado = [{ usuarios_cuentas: userCuentasStorage[0] }]
        userCuentasStorage = usersCuentasModificado;
      }
      if (user_cuentas.length > 0) {
        let clientesFilter = clientes.filter((cliente) => userCuentasStorage.find((obj) => obj.usuarios_cuentas.id_cuentas === cliente.id_cuentas))
        let newClientes = Object.entries(clientesFilter).map(([key, value]) => {
          let objeto = value;
          objeto["label"] = value.razon_social;
          return objeto;
        });
        setClientesList(newClientes);
      } else {
        let newClientes = Object.entries(clientes).map(([key, value]) => {
          let objeto = value;
          objeto["label"] = value.razon_social;
          return objeto;
        });
        setClientesList(newClientes);
      }
    };
    getClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const estilosSelect = {
    fontSize: "1em", color: "#646464", fontFamily: 'Roboto, sans-serif', backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  // Función personalizada de filtrado para buscar dentro del objeto cliente por id y razon_social
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => {
      const label = option.label.toLowerCase();
      const inputValueLowerCase = inputValue.toLowerCase();
      const idString = option.id_cuentas.toString().toLowerCase();
      return label.includes(inputValueLowerCase) || idString.includes(inputValueLowerCase);
    });
  };

  const handleChangeCrearCajas = () => {
    // La función que se ejecutará al cambiar el estado del Switch
    setCrearCajas(!crearCajas);
  };

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          {/* <ClienteSector ImportFile={true} /> */}
          <Autocomplete
            size="small"
            value={clienteId}
            onChange={(event, newValue) => {
              setClienteId(newValue);
            }}
            id="controllable-states-demo1"
            options={clientesList}
            filterOptions={filterOptions} // Aquí proporcionamos la función de filtrado personalizada
            style={{ ...estilosSelect, }}
            disableClearable={true}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.id_cuentas + ' - ' + option.label}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} label="Cliente" InputProps={{
              ...params.InputProps,
              type: 'search',
            }} />}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                checked={crearCajas}
                onChange={handleChangeCrearCajas}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Crear cajas"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DropzoneArea
            acceptedFiles={['.csv', '.xls', '.xlsx']}
            dropzoneText="Haga clic o arrastre el archivo aquí"
            onChange={handleFileChange}
            filesLimit={1}
            maxFileSize={5000000}
            showAlerts={false}
            showPreviewsInDropzone={false}
            dropzoneClass="dropzone"
            Icon={CloudUploadIcon}
            showFileNames
            dropzoneParagraphClass="dropzone-paragraph"
          />
          <Stack sx={{ width: '100%' }} spacing={2}>
            {file && (
              <Alert variant="filled" severity="success">
                <Typography variant="subtitle2" align="center" gutterBottom>
                  Archivo seleccionado: {file.name}
                </Typography>
              </Alert>
            )}
            {!file && (
              <Alert variant="filled" severity="warning">
                <Typography variant="subtitle2" align="center" gutterBottom>
                  Debe seleccionar un archivo — formatos admitidos <strong>.csv- .xls - .xlsx </strong>
                </Typography>
              </Alert>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Button variant="contained" color="primary" onClick={handleImport} style={{ marginTop: "3%" }}>
        Importar
      </Button>
      <MyModal
        open={openModal}
        onClose={handleCloseModal}
        title={tittle}
        content={
          <>
            {errors.length > 0 && (
              <div>
                <ul>
                  {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                      {error}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {(successResponses.length > 0 || errorResponses.length > 0) && (
              <Stack spacing={2} sx={{ mt: 2 }}>
                {successResponses.length > 0 && (
                  <Alert severity="success">
                    Las siguientes cajas y contenidos fueron importadas exitosamente:
                  </Alert>
                )}
                {successResponses.map((response, index) => (
                  <Alert key={index} severity="success">
                    {response}
                  </Alert>
                ))}

                {errorResponses.length > 0 && (
                  <Alert severity="error">
                    Ocurrieron errores al importar las siguientes cajas:
                  </Alert>
                )}
                {errorResponses.map((response, index) => (
                  <Alert key={index} severity="error">
                    {response}
                  </Alert>
                ))}
              </Stack>
            )}
          </>
        }
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => { setSnackbarVisible(false) }}
        hide={8000}
      />
    </Container>

  );
};

export default ImportFile;
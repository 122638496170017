export const schemaCajasForm = {
  type: "object",
  properties: {
    descripcionClienteSector: {
      type: "string",
      customRender: "InputString",
    },
    cantidad: {
      type: "number",
      customRender: "InputNumber",
      description: "Ingrese una Cantidad",
    },
    id_tipo_cajas: {
      type: "number",
      customRender: "SelectorTipoCajas",
      description: "Seleccione perfil",
    },
    fecha_creacion: {
      type: "string",
      customRender: "DateForm",
      description: "Seleccione una fecha",
    },
    detalle: {
      type: "string",
      customRender: "InputString",
    },
    referencia: {
      type: "number",
      customRender: "InputNumber",
      description: "Ingrese una Referencia.",
    },
    ubicacion_temporal: {
      type: "string",
      customRender: "InputString",
      description: "Ingrese una Ubicacion",
    },
  },
};

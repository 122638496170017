
import React from 'react';
import { Grid } from '@mui/material';
import { DataGridPro } from "@mui/x-data-grid-pro";

export const ClientesDashboard = (data) => {
    const columns = [
        { field: "nombreCompleto", headerName: "Cliente", flex: 0.3 },
        { field: "articulos_nombre", headerName: "Propiedad", flex: 0.4 },
    ];

    let dataConId = data.clientes.map((obj) => ({
        ...obj,
        id: obj.id,
        nombreCompleto: `(${obj.id_cliente}) ${obj.nombre}`,
    }));

    const groupBy = (array, key) => {
        if (!Array.isArray(array)) {
            console.error("El argumento 'array' no es un array.");
            return {};
        }

        const result = array.reduce((result, obj) => {
            (result[obj[key]] = result[obj[key]] || []).push(obj);
            return result;
        }, {});

        return result;
    };

    const groupedData = groupBy(dataConId, 'id_cliente');

    // Función para crear nodos padres y añadir hierarchy
    const createNodes = (groupedData) => {
        const result = [];
        for (const id_cliente in groupedData) {
            const group = groupedData[id_cliente];
            const parentNode = { ...group[0], id: `${group[0].id}-parent`, hierarchy: [group[0].id] };

            group.forEach((node, index) => {
                if (index > 0) {
                    node.hierarchy = [parentNode.id];
                }
            });

            result.push(parentNode, ...group);
        }
        return result;
    };

    const resultData = createNodes(groupedData);

    resultData.forEach((obj) => {
        if (obj.hierarchy && obj.id.includes('-parent')) {

            obj.hierarchy = [];
            delete obj.articulos_nombre;
        } else {
            delete obj.apellido;
            delete obj.nombre;
            delete obj.nombreCompleto;
            delete obj.id_cliente;
        }
    });

    const groupingColDef = {
        headerName: "",
        hideDescendantCount: true,
        valueFormatter: () => "",
        width: 50,
    };

    const getTreeDataPath = (row) => {
        if (row.hierarchy && row.hierarchy.length > 0) {
            return [...row.hierarchy, row.id];
        } else {
            return [row.id];
        }
    };

    const hierarchyProps = {
        treeData: true,
        groupingColDef: groupingColDef,
        getTreeDataPath: getTreeDataPath,
        defaultGroupingExpansionDepth: -1,
    };



    return (
        <Grid container display={'block'} spacing={2} marginTop={2} justifyContent={'flex-start'} >
            <Grid item style={{ height: 550, width: "100%", padding: 10 }}>
                <DataGridPro
                    density="compact"
                    rows={resultData}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pagination={false}
                    showToolbar={false}
                    hideFooterRowCount
                    hideFooter={true}
                    //   groupBy={(rows) => rows.map((row) => row.nombreCompleto)}
                    {...hierarchyProps}

                />
            </Grid>
        </Grid>

    );
}
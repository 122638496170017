export const schemaCuentasLocalidades  = {
  type: "object",
  properties: {
    "id": {
      type: "string",
      title: "Id",
      description: "Id",
    },
    "id_tipo_localizacion":{
      type: "string",
      hidden: true,
      //default: "0"
    },
    "domicilio": {
      type: "string",
      title: "Domicilio",
      description: "Domicilio",
      minLength: 5
    },
    "descripcion": {
      type: "string",
      title: "Descripcion",
      description: "Descripcion",
      minLength: 5
    },
    "id_pais": {
      type: "string",
      customRender: "SelectorPais",
    },
    "id_provincia": {
      type: "string",
      customRender: "SelectorProvincia",
    },
    "id_departamento": {
      type: "string",
      customRender: "SelectorDepartamento",
    },
    "id_localidad": {
      type: "string",
      customRender: "SelectorLocalidad",
    },
    "selectedTipoLocalizacion": {
      type: "string",
      customRender: "SelectorTipoLocalizacion",
    },
    principal: {
      type: 'boolean',
      title: "Es Principal",
      description: "Es Principal",
      default: false
    }
  },
  required: [ "selectedTipoLocalizacion","domicilio","id_localidad","id_pais","id_provincia","id_departamento"],
};
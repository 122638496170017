import React, { useContext, useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import { CajasContext } from "src/context/CajasContext/CajasContext";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getTipoCajas } from "src/projects/gestion_documental/helpers/TipoCajasData";
import { Autocomplete } from "@mui/material";
import { esArray } from "src/general_components/functionEsArray";
import SelectorBasico from "src/forms/customRenders/SelectorBasico";
import { SelectExcedidas } from "./SelectExcedidas";
import { SelectDeformadas } from "./SelectDeformadas";

export const FormIdCajas = ({ rangoId, setRangoId, valueDesde, setValueDesde, valueHasta, setValueHasta,
  referencia, setReferencia, tiposCajasSeleccionadas, setTiposCajasSeleccionadas,
  referenciaDesde, referenciaHasta, setReferenciaDesde, setReferenciaHasta, selectOrdenamiento, setSelectOrdenamiento,
  excedidoSeleccionado, setExcedidoSeleccionado, deformadaSeleccionado, setDeformadaSeleccionado }) => {

  const { setListDatosFormIdCajas, formIdCajasDisabled, setFormIdCajasDisabled, checkFisica, setCheckFisica,
    checkVirtual, setCheckVirtual, setCheckReferencia, setGrupoBusquedaCajas } = useContext(CajasContext);
  const [dataDefaults, setDataDefaults] = useState([]);
  const [errorIdCajas, setErrorIdCajas] = useState('');
  const [errorReferencia, setErrorReferencia] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [pseudoId, setPseudoId] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [ordenamientoList, setOrdenamientoList] = useState([
    { id: 'cajas.id-asc', detalle: 'ID Ascendente' },
    { id: 'cajas.id-desc', detalle: 'ID Descendente' },
    { id: 'cajas.referencia-asc', detalle: 'Referencia Ascendente' },
    { id: 'cajas.referencia-desc', detalle: 'Referencia Descendente' }
  ]);

  useEffect(() => {
    if (selectOrdenamiento) {
      let valor = ''
      if (typeof selectOrdenamiento === 'object' && selectOrdenamiento !== null) {
        valor = selectOrdenamiento.id
      } else {
        valor = selectOrdenamiento
      }

      const index = ordenamientoList.findIndex((item) => item.id === valor);
      setSelectOrdenamiento(ordenamientoList[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var tiposCajasLocalStorage = JSON.parse(localStorage.getItem("siam-tipo_cajas"));

  useEffect(() => {
    const getTiposCajas = async () => {
      if (tiposCajasLocalStorage !== null) {
        setDataDefaults(esArray(tiposCajasLocalStorage.tipo_cajas));
      } else {
        const tiposCajasObtenidos = await getTipoCajas();
        if (tiposCajasObtenidos) {
          setDataDefaults([...tiposCajasObtenidos]);
        }
      }
    };
    getTiposCajas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const gettiposCajasSeleccionadas = async () => {
      if (tiposCajasSeleccionadas !== null) {
        const tipoCaja = dataDefaults.filter((objeto) => tiposCajasSeleccionadas.includes(objeto.id));
        if (tipoCaja.length > 0) {
          setTiposCajasSeleccionadas(tipoCaja);
        }
      };
    }
    gettiposCajasSeleccionadas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDefaults]);

  const handleChangeTipoCuenta = (event, newValue) => {
    if (newValue && newValue !== "") {
      setTiposCajasSeleccionadas(newValue);
      setGrupoBusquedaCajas(true)
    }
  };

  const handleChange = (event, nameInput) => {
    switch (nameInput) {
      case "rango":
        setRangoId(event.target.value);
        break;
      case "desde":
        setValueDesde(event.target.value);
        break;
      case "hasta":
        setValueHasta(event.target.value);
        break;
      case "referencia":
        setReferencia(event.target.value);
        break;
      case "referencia_desde":
        setReferenciaDesde(event.target.value);
        break;
      case "referencia_hasta":
        setReferenciaHasta(event.target.value);
        break;
      default:
        break;
    }

    setGrupoBusquedaCajas(true)
  };

  useEffect(() => {
    let data = {};
    let fisica = false;
    let virtual = false;
    setListDatosFormIdCajas({ id_cajas: "", referencia: "", idCajasRango: "", referenciasRango: "", excedida: "2" });
    if (rangoId !== "" || (referencia !== "" || tiposCajasSeleccionadas !== "" || selectOrdenamiento !== "" || excedidoSeleccionado !== "2" ||
      deformadaSeleccionado !== "2")) {
      const pattern = /^[0-9,-]*$/;
      if (rangoId !== "") {
        if (pattern.test(rangoId)) {
          setErrorIdCajas('')
          data.idCajasRango = rangoId
          setPseudoId(rangoId)
        } else {
          setErrorIdCajas('El valor no es válido');
          let valorFormated = rangoId.replace(/[^0-9,-]/g, '');
          setRangoId(valorFormated)
        }
        if (rangoId.includes('-') || rangoId.includes(',')) {
          const valores = rangoId.split(',');
          let desdeHasta = [];
          let ids = "";
          valores.forEach((value) => {
            if (value.includes('-')) {
              const range = value.split('-');
              const rangoObjeto = {
                desde: range[0],
                hasta: range[1],
              }
              desdeHasta.push(rangoObjeto);

            } else {
              if (ids !== "") {
                ids += ",";
              }
              ids += value;
            }
          });
          data.id_cajas = ids;
          data.cajaDesdeHasta = desdeHasta;
        } else {
          data.id_cajas = rangoId;
        }
      }
      if (checkFisica) {
        fisica = "1";
      }
      if (checkVirtual) {
        virtual = "1"
      }
      if (fisica) {
        data.fisica = fisica;
      }
      if (virtual) {
        data.virtual = virtual;
      }
      if (referencia !== "") {
        if (referencia.length <= 130 && pattern.test(referencia)) {
          setErrorReferencia('')
          data.referenciasRango = referencia
        } else {
          setErrorReferencia('El valor no es válido');
          let valorFormated = referencia.replace(/[^0-9,-]/g, '');
          setReferencia(valorFormated)
        }
        if (referencia.includes('-') || referencia.includes(',')) {
          const valores = referencia.split(',');
          let desdeHasta = [];
          let referencias = "";
          valores.forEach((value) => {
            if (value.includes('-')) {
              const range = value.split('-');
              const rangoObjeto = {
                desde: range[0],
                hasta: range[1],
              }
              desdeHasta.push(rangoObjeto);
            } else {
              if (referencias !== "") {
                referencias += ",";
              }
              referencias += value;
            }
          });
          data.referencia = referencia;
          data.referenciaDesdeHasta = desdeHasta;
        } else {
          data.referencia = referencia;
        }
      }
      if (tiposCajasSeleccionadas !== "") {
        let idsTiposCajasSeleccionadas = tiposCajasSeleccionadas.map((tipoCajaseleccionado) => tipoCajaseleccionado.id);
        data.tiposCajasSeleccionadas = idsTiposCajasSeleccionadas;
      }
      if (excedidoSeleccionado !== "2") {
        data.excedida = excedidoSeleccionado;
        setGrupoBusquedaCajas(true)
      }
      if (deformadaSeleccionado !== "2") {
        data.deformada = deformadaSeleccionado;
        setGrupoBusquedaCajas(true)
      }
      if (selectOrdenamiento !== "") {
        data.ordenamiento = selectOrdenamiento.id;
        setGrupoBusquedaCajas(true)
      }

      setListDatosFormIdCajas({ ...data });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiposCajasSeleccionadas, rangoId, valueDesde, valueHasta, referencia, referenciaDesde, referenciaHasta, selectOrdenamiento, excedidoSeleccionado, deformadaSeleccionado]);


  const limpiar = (e) => {
    setCheckFisica(true);
    setCheckVirtual(false);
    setListDatosFormIdCajas({ id_cajas: "", idCajasRango: "", tiposCajasSeleccionadas: [], referencia: "", ordenamiento: "", excedido: "1" });
    setSelectOrdenamiento("")
    setRangoId("");
    setFormIdCajasDisabled(false);
    setReferencia("");
    setCheckReferencia(false);
    setGrupoBusquedaCajas(false)
    setExcedidoSeleccionado("")
    setTiposCajasSeleccionadas("")
  };

  const handleChangeFisica = (event) => {
    setCheckFisica(event.target.checked);
    setGrupoBusquedaCajas(true)
  };

  const handleChangeVirtual = (event) => {
    setCheckVirtual(event.target.checked);
    setGrupoBusquedaCajas(true)
  };


  return (
    <div>
      <Grid container spacing={3} alignItems="center" style={{ marginBottom: '3px' }}>
        <Grid item xs>
          <FormControlLabel control={<Switch checked={checkFisica} onChange={handleChangeFisica} />} label="Cajas Fisicas" />
          <FormControlLabel control={<Switch checked={checkVirtual} onChange={handleChangeVirtual} />} label="Cajas Virtuales" />
        </Grid>
        <Grid item style={{ justifyContent: "left", width: '40%' }}>
          <SelectorBasico
            disabled={false}
            label="Ordenamiento de Resultados"
            listItems={ordenamientoList}
            data={selectOrdenamiento}
            setData={setSelectOrdenamiento}
          />
        </Grid>
        <Grid item style={{ justifyContent: "right" }}>
          <Button
            variant="contained"
            onClick={limpiar}
            color="secondary"
            sx={{
              padding: '5px',
              minWidth: 'auto',
            }}
          >
            <ReplayIcon style={{
              padding: '0px',      // Ajusta el relleno (padding) para hacer el botón más pequeño
              position: 'center',
            }} />
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField id="outlined-basic1" label="ID Cajas" variant="outlined"
            fullWidth size="small"
            helperText="Indique el ID de cajas a buscar separados por comas o separados por guines medios."
            error={!!errorIdCajas}
            value={rangoId}
            onChange={(e) => { handleChange(e, "rango") }}
            sx={{ m: 1, minWidth: 400 }}
            {...formIdCajasDisabled ? { disabled: true } : {}} />
        </Grid>
        <Grid item xs={12}>
          <TextField id="outlined-basic2" label="Referencia" variant="outlined"
            fullWidth size="small"
            helperText="Indique las referencias de cajas a buscar separados por comas o separados por guines medios."
            error={!!errorReferencia}
            value={referencia}
            sx={{ m: 1, minWidth: 400 }}
            onChange={(e) => { handleChange(e, "referencia") }}
            {...formIdCajasDisabled ? { disabled: true } : {}} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {checkFisica &&
          <Grid item xs={4}>
            <Autocomplete
              value={typeof tiposCajasSeleccionadas[0] === 'object' ? tiposCajasSeleccionadas : []}
              onChange={handleChangeTipoCuenta}
              options={dataDefaults || []}
              fullWidth
              sx={{ m: 1 }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              multiple
              size="small"
              getOptionLabel={(option) => option.id + ' - ' + option.detalle || ""}
              renderInput={(params) => (
                <TextField {...params}
                  label="Tipo de Caja" />
              )} />
          </Grid>
        }
        <Grid item xs={checkFisica ? 4 : 6}>
          <SelectExcedidas setExcedidoSeleccionado={setExcedidoSeleccionado} excedidoSeleccionado={excedidoSeleccionado}></SelectExcedidas>
        </Grid>
        <Grid item xs={checkFisica ? 4 : 6}>
          <SelectDeformadas setDeformadaSeleccionado={setDeformadaSeleccionado} deformadaSeleccionado={deformadaSeleccionado}></SelectDeformadas>
        </Grid>
      </Grid>
    </div>);
};

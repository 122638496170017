

export const uiSchemaFichaForm = {
    type: "VerticalLayout",
    elements: [
        {
            type: "Control",
            scope: "#/properties/cliente",
            label: "Cliente/Sector",
            options: {
                readonly: true,
            },
        },
        {
            type: "HorizontalLayout",
            elements: [
                {
                    type: "Control",
                    scope: "#/properties/fecha_desde",
                },
                {
                    type: "Control",
                    scope: "#/properties/fecha_hasta",
                },
            ],
        },
    ],
};

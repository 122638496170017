import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css"


export const getCuentasContactos = async (id_cuentas) => {
    const cuentas_contactos = new datos("cuentas_contactos");
    cuentas_contactos.addCondition("cuentas_contactos", "id", 0, ">");
    cuentas_contactos.addCondition("cuentas_contactos", "id_cuentas", parseInt(id_cuentas), "=")
    const response = await cuentas_contactos.get();
    return esArray(response.wsResponse.cuentas_contactos);
};

export const getCuentasContactosPaginated = async (limit, offset, id_cuentas) => {
    const cuentas_contactos = new datos("cuentas_contactos");
    cuentas_contactos.addCondition("cuentas_contactos", "id", 0, ">");
    cuentas_contactos.addCondition("cuentas_contactos", "id_cuentas", parseInt(id_cuentas), "=")
    cuentas_contactos.addLimitCondition(limit, offset);
    const response = await cuentas_contactos.get();
    /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

    let result = {};
    if (!response.wsResponse.empty) {
        result = {
            data: esArray(response.wsResponse.cuentas_contactos),
            total_records: response.wsResponse.total_records,
        };
        return result;
    }
};

export const deleteCuentasContactos = async (id) => {
    let status = false;
    await Swal.fire({
        title: "¿Está seguro de que desea eliminar este registro?",
        text: "Si sigue adelante, no se podrán revertir los cambios.",
        icon: "warning",
        showCancelButton: true,
        customClass: {
            container: 'my-swal-delete'
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, quiero eliminarlo",
        cancelButtonText: "No, no estoy seguro",
    }).then(async (result) => {
        if (result.isConfirmed) {
            const cuentas_contactos = new datos("cuentas_contactos");
            let data = {
                id: id,
            };
            const response = await cuentas_contactos.delete(data);

            if (response.status === "true") {
                status = true;
                await Swal.fire({
                    customClass: {
                        container: 'my-swal-delete'
                    },
                    title: "¡Eliminado!",
                    text: "El registro ha sido eliminado exitosamente",
                    icon: "success"
                });
            } else {
                await Swal.fire({
                    customClass: {
                        container: 'my-swal-delete'
                    },
                    icon: "error",
                    title: "¡Oops!",
                    text: response.message,
                });
            }
        }
    });
    return status;
};


import React, { useEffect, useState } from 'react';
import { List, ListItem, Grid } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { TextField, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LoadingOverlay from 'src/general_components/LoadingOverlay';

import { accessKey } from "src/configs/config";

export const Help = () => {

  const [files, setFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));
  let url = "";

  if (parametros_proyecto.id === "27") {
    url = 'https://www.googleapis.com/drive/v3/files?q=%271AB9vm04T3Ca6mhZzTAOqPMCxXmvOlho6%27%20in%20parents&key=';
  } else if (parametros_proyecto.id === "30") {
    url = 'https://drive.google.com/drive/folders/1pkfbmhswtiJPWhrSqWyi2UnrnS7GFsVj?usp=sharing';
  }


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFiles = files.filter((file) => {
    return file.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const fetchFiles = async () => {
    setLoading(true);
    const response = await fetch(
      url + accessKey
    );
    const data = await response.json();
    setFiles(data.files);
    setLoading(false);
  };

  useEffect(() => {
    fetchFiles();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Grid item xs={12} sx={{ padding: '30px', paddingBottom: '10px' }}>
        <Typography align="left" variant="h5" gutterBottom component="div">
          Lista de enlaces directo a Manuales de usuarios:
        </Typography>
        <Divider />
      </Grid>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField
          label="Buscar"
          variant="outlined"
          size="small"
          margin="normal"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>

      {
        loading && <LoadingOverlay open={loading} />
      }

      <List dense >
        {filteredFiles.map((file, index) => (
          <ListItem key={file.id}
            sx={{
              py: '0px',
              backgroundColor: index % 2 !== 0 ? '#f5f5f5' : '#c1c1c1'
            }} >
            <ListItemButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              component="a"
              href={`https://drive.google.com/file/d/${file.id}/view`}
              target="_blank"
              rel="noopener">
              <ListItemText primary={file.name} />
            </ListItemButton>

          </ListItem>
        ))}
      </List>
    </div>
  );
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { getTipoTelefonos} from "src/general_helpers/TipoTelefonosData";

const SelectorTipoTelefono = (props) => {
  const estilosSelect = { fontSize: "1em", color: "#646464" };

  const MenuProps = {
    PaperProps: {
      style: {
        //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //width: 100,
        maxHeight: 250,
      },
    },
  };
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.selectedTipoTelefono;
  const [selectedTipoTelefono, setSelectedTipoTelefono] = useState("");

  useEffect(() => {
    const getTipoTelefono = async () => {
              const response = await getTipoTelefonos() // funcion del data
              if (response) {
                setDataDefaults(response)
              }
          }
          getTipoTelefono() 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(dataDefaults.length > 0){
      setSelectedTipoTelefono(tipo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDefaults]);


  //funcion para devolver al formulario la data seleccionada y para setearla en el selector
  const handleChange = (event) => {
    setSelectedTipoTelefono(event.target.value);
    props.handleChange(props.path, event.target.value);
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <InputLabel>Tipo de Telefono *</InputLabel>
        <Select
          style={estilosSelect}
          inputProps={{ disabled: disabled }}
          MenuProps={MenuProps}
          value={selectedTipoTelefono}
          onChange={handleChange}
          label="Tipo de Telefono"
        >
          <MenuItem style={estilosSelect} value="">
            Ninguno
          </MenuItem>
          {dataDefaults && dataDefaults.length > 0 ? (
            dataDefaults.map((tipoTelefono) => (
              <MenuItem
                style={estilosSelect}
                key={tipoTelefono.id}
                value={tipoTelefono.id}
              >
                {tipoTelefono.nombre}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={0} value={0} style={estilosSelect}>
              {"No hay tipos de telefonos disponibles"}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorTipoTelefonoTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorTipoTelefono";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorTipoTelefonoComponent = {
  renderer: withJsonFormsControlProps(SelectorTipoTelefono),
  tester: SelectorTipoTelefonoTester,
};
import datos from "src/services/datos";
import "src/services/styles/sweetAlert.css";

export const getContenidosSearchHistorico = async (id, modelo) => {
    const contenidos = new datos("contenidos");
    let response;
    if(modelo === "caja"){
        response = await contenidos.searchContenidosHistoricoCaja(id);
    }else{
        response = await contenidos.searchContenidosHistoricoContenido(id);
    }

  
    return response;
  };
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
/**
 *
 * NOTE: Componente con los botones de guardar y cancelar
 *
 * @param {boolean} disableButton - Deshabilita el boton de guardar
 * @param {function} onClickSubmit - Funcion que se ejecuta al hacer click en guardar
 * @param {React.Dispatch<React.SetStateAction<{}>>} buttonCerrar - Es el set state que se ejecuta al hacer click en cancelar
 * @param {string} labelButtonSave - Texto que se muestra en el boton de guardar
 *
 */

export function Buttons(props) {
  const {
    disableButton,
    labelButtonSave,
    onClickSubmit,
    buttonCerrar,
    buttonFacturar,
    onClickFacturar,
    labelButtonClose,
    disableButtonFacturar,
    labelButtonFacturar,
    iconFacturarDisabled, 
    id,
    centrar = false
  } = props;
  const guardar = "Guardar";
  const cerrar = "Cerrar";
  const facturar = "A facturar";

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  const isMobileDevice = regexp.test(details);

  return (
    <Grid
      container
      spacing={2}
      justifyContent={!isMobileDevice && !centrar ? "end" : "center"}
    >
      {/*NOTE BOTONES DE GUARDAR Y CANCELAR */}
      {buttonFacturar && (
        <Grid item>
          <Button
            disabled={disableButtonFacturar}
            variant="contained"
            color="success"
            onClick={() => {
              onClickFacturar();
            }}
            {...(!iconFacturarDisabled
              ? { startIcon: <RequestQuoteIcon /> }
              : null)}
          >
            {labelButtonFacturar ? labelButtonFacturar : facturar}
          </Button>
        </Grid >
      )}
      <Grid item>
        <Button
          variant="contained"
          color="error"
          onClick={buttonCerrar}
          style={{fontWeight: 'bold'}}
          startIcon={<CancelRoundedIcon />}
        >
          {labelButtonClose ? labelButtonClose : cerrar}
        </Button>
      </Grid >
      <Grid item >
        <Button
          id={id}
          variant="contained"
          color="success"
          disabled={disableButton}
          style={{fontWeight: 'bold'}}
          onClick={onClickSubmit}
          startIcon={labelButtonSave && (labelButtonSave !== "Guardar" || labelButtonSave !== "GUARDAR")?  <CheckCircleOutlineIcon /> : <SaveRoundedIcon/> }
        >
          {labelButtonSave ? labelButtonSave : guardar}
        </Button>
      </Grid >
    </Grid>
  );
}

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getEstadoUbicaciones } from "../../projects/gestion_documental/helpers/UbicacionesEstados";
import { esArray } from "src/general_components/functionEsArray";

const SelectorEstadoUbicaciones = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const estado = jsonForm.core.data.id_estado_ubicaciones;
  
  useState(() => {
    const estadoDeCajas = async () => {

      let estadoUbicaciones = esArray(JSON.parse(localStorage.getItem("siam-estados_ubicaciones"))?.estados_ubicaciones);

      if (!estadoUbicaciones.length) {
        estadoUbicaciones = await getEstadoUbicaciones();        
      }

      setDataDefaults(estadoUbicaciones);
    };
    estadoDeCajas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    setEstadoDeUbicacion(dataDefaults.find((item) => item.id === estado));
  }, [dataDefaults, estado]);


  const [estadoDeUbicacionSeleccionado, setEstadoDeUbicacion] = useState("");
  //funcion para devolver al formulario la data seleccionada y para setearla en el selector
  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setEstadoDeUbicacion(newValue);
      props.handleChange(props.path, newValue.id);
    }else{
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
        value={estadoDeUbicacionSeleccionado ? estadoDeUbicacionSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.detalle || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Estado de Ubicaciones"
          disabled={disabled} />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorEstadoUbicacionesTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorEstadoUbicaciones";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorEstadoUbicacionesComponent = {
  renderer: withJsonFormsControlProps(SelectorEstadoUbicaciones),
  tester: SelectorEstadoUbicacionesTester,
};

export const uiSchemaCuentasLocalidadesForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/id",
          options: {
            readonly: true
          }
        },
        {
          type: "Control",
          scope: "#/properties/selectedTipoLocalizacion",
        },
        {
          type: "Control",
          scope: "#/properties/id_pais",
        },
        {
          type: "Control",
          scope: "#/properties/id_provincia",
        },
        {
          type: "Control",
          scope: "#/properties/id_departamento",
        },
        {
          type: "Control",
          scope: "#/properties/id_localidad",
        },
        {
          type: "Control",
          scope: "#/properties/domicilio",
        },
        {
          type: "Control",
          scope: "#/properties/descripcion",
        },
        {
          type: "Control",
          scope: "#/properties/principal",
          options: {
            toggle: true,
            align: "start"
          }
        },

      ],
    },
  ],
};
import { paramsLogin } from "./paramsLogin";
import { paramsGet } from "./paramsGet";
import { paramsInsert } from "./paramsInsert";
import { paramsCreateUbicaciones } from "./paramsCreateUbicaciones";
import { paramsDelete } from "./paramsDelete";
import { config, parametros_proyecto, id_proyecto } from "src/configs/config";
import CryptoJS from 'crypto-js';

// Función para buscar los parametros del proyecto
function buscarPorIdProyecto(id) {
  return parametros_proyecto?.find(objeto => objeto.id === id);
}
const proyecto_encontrado = buscarPorIdProyecto(id_proyecto);

const key = proyecto_encontrado?.key_encript;

export const bodyRequest = (props) => {
  let serviceName = '';
  const { services, structure } = props;
  let ws_structure = {};
  switch (services) {
    case "login":
      ws_structure = paramsLogin(structure);
      serviceName = services;
      break;
    case "get":
      ws_structure = paramsGet(structure);
      serviceName = services;
      break;
    case "insert":
      ws_structure = { params: paramsInsert(structure) };
      serviceName = services;
      break;
    case "ubicaciones":
      ws_structure = { params: paramsCreateUbicaciones(structure) };
      serviceName = services;
      break;
    case "cajasUbicacionesSet":
      ws_structure = { params: structure }
      serviceName = 'ubicaciones';
      break;
    case "getCajasDisponibles":
      ws_structure = { params: structure }
      serviceName = 'cajas';
      break;
    case "getCuentaGenerica":
      ws_structure = { params: structure }
      serviceName = 'cuentas';
      break;
    case "contenidos":
      ws_structure = { params: structure }
      serviceName = services;
      break;
    case "contenidosMovement":
      ws_structure = { params: structure }
      serviceName = 'contenidos';
      break;
    case "articulosGet":
      ws_structure = { params: structure }
      serviceName = 'Articulos';
      break;
    case "articulosCreate":
      ws_structure = { params: structure }
      serviceName = 'articulos';
      break;
    case "articulosUpdate":
      ws_structure = { params: structure }
      serviceName = 'articulos';
      break;
    case "articulosDelete":
      ws_structure = { params: structure }
      serviceName = 'Articulos';
      break;
    case "contenidosCreate":
      ws_structure = { params: structure }
      serviceName = 'contenidos';
      break;
    case "contenidosUpdate":
      ws_structure = { params: structure }
      serviceName = 'contenidos';
      break;
    case "contenidosSearch":
      ws_structure = { params: structure }
      serviceName = 'contenidos';
      break;
    case "delete":
      ws_structure = { params: paramsDelete(structure) };
      serviceName = services;
      break;
    case "cuentasCreate":
      ws_structure = { params: structure }
      serviceName = 'cuentas';
      break;
    case "cuentasUpdate":
      ws_structure = { params: structure }
      serviceName = 'cuentas';
      break;
    case "getImages":
      ws_structure = { params: structure }
      serviceName = 'images';
      break;
    case "createImages":
      ws_structure = { params: structure }
      serviceName = 'images';
      break;
    case "createImagesMultiple":
      ws_structure = { params: structure }
      serviceName = 'images';
      break;
    case "spUpdate":
      ws_structure = { params: structure }
      serviceName = 'sp';
      break;
    case "getMovimientos":
      ws_structure = { params: structure }
      serviceName = 'get';
      break;
    case "dboxDashboard":
      ws_structure = { params: structure }
      serviceName = 'get';
      break;
    case "getLoadData":
      ws_structure = { params: structure }
      serviceName = 'get';
      break;
    case "setComprobantesDigitalizaciones":
      ws_structure = { params: structure }
      serviceName = 'comprobantes';
      break;
    case "getComprobantes":
      ws_structure = { params: structure }
      serviceName = 'Comprobantes';
      break;
    case "comprobantesPrint":
      ws_structure = { params: structure }
      serviceName = 'comprobantes';
      break;
    case "getNewPassword":
      ws_structure = { params: structure }
      serviceName = 'usuarios';
      break;
    case "getTableStructure":
      ws_structure = { params: structure }
      serviceName = 'getTableStructure';
      break;
    case "comprobantesCreate":
      ws_structure = { params: structure }
      serviceName = 'Comprobantes';
      break;
    case "comprobantesDelete":
      ws_structure = { params: structure }
      serviceName = 'Comprobantes';
      break;
     case "produccionGet":
      ws_structure = { params: structure }
      serviceName = 'produccion';
      break;
    default:
      ws_structure = false
      break;
  }

  // TODO:ESTO SE DEVE APLICAR PARA EL ENCRIPTAR LOS DATOS QUE SE ENVIARAN AL SERVIDOR

  const encryptData = (data) => {

    const key_codificada = CryptoJS.enc.Hex.parse(key); // Clave en formato hexadecimal

    const iv = CryptoJS.lib.WordArray.random(16); // Generar un IV aleatorio

    const encrypted = CryptoJS.AES.encrypt(data, key_codificada, { iv: iv, padding: CryptoJS.pad.Pkcs7 });

    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);

    const encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    return ivBase64 + encryptedBase64;
  };



  const data = JSON.stringify({
    "@name": serviceName,
    ws_structure: ws_structure,
    authorization: {
      owner_code: config.credentials.authorization.owner_code,
      id_user: config.credentials.authorization.id_user,
      language: config.credentials.authorization.language,
    }
  })

  let encriptacion = localStorage.getItem("encriptacion")
  if (encriptacion === 'true') {
    return encryptData(data)
  } else {
    return data
  }



  // return JSON.stringify({
  //   "@name": serviceName,
  //   ws_structure: ws_structure,
  //   authorization: {
  //     owner_code: config.credentials.authorization.owner_code,
  //     id_user: config.credentials.authorization.id_user,
  //     language: config.credentials.authorization.language,
  //   }
  // });
};

export default bodyRequest;

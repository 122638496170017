import { DeleteForever } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Divider from '@mui/material/Divider';

export const DeleteModal = (props) => {

  const { open, close, handleDelete } = props;

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Borrar</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            mt={2}
          >
            <DeleteForever color="error" style={{ fontSize: 50 }} />
            <Typography variant="body1" mt={2}>
              ¿Está seguro que desea eliminar este registro?
            </Typography>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions style={{ justifyContent: "center" }}>

          <Button
            variant="contained"
            color="error"
            onClick={close}
            style={{ fontWeight: 'bold' }}
            startIcon={<CancelRoundedIcon />}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleDelete();
              close();
            }}
            style={{ fontWeight: 'bold' }}
            startIcon={<CheckCircleOutlineIcon />}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


export const schemaTipoCajasForm = {
  type: "object",
  properties: {
    id: {
      type: "string",
      title: "Id",
      description: "Id",
      hidden: true,
    },
    detalle: {
      type: "string",
      title: "Detalle",
      description: "Detalle",
      minLength: 3 
    },
    ancho: {
      type: "integer",
      title: "Ancho",
      description: " Ancho",
      minimum: 0,
    },
    alto: {
      type: "integer",
      title: "Alto",
      description: "Alto",
      minimum: 0,
    },
    profundidad: {
      type: "integer",
      title: "Profundidad",
      description: "Profundidad",
      minimum: 0,
    }
    
  }
  
}
 




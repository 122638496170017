import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import StyleIcon from "@mui/icons-material/Style";
import { Button, MenuItem, Tooltip,Select,
  FormControl,
  InputLabel, } from "@mui/material";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { TheLayoutContext } from "src/context/TheLayoutContext";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { ComponentDialog } from "../../../general_components/ComponentDialog";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { SwitchAjuste } from "../components/SwitchAjuste";
import {
  getContenidos,
  getContenidosSearchPaginated,
  movementContenidos,
} from "../helpers/ContenidosData";
import { imprimirEtiquetas } from "../helpers/EtiquetasData";
import { SearchFormGeneral } from "./SearchFormGeneral";
import { SelectContenidoDevolucion } from "./SelectContenidoDevolucion";
import { SelectRetiroContenido } from "./SelectRetiroContenido";
import { SelectRetiroPrioridad } from "./SelectRetiroPrioridad";
import HistoryIcon from "@mui/icons-material/History";
import { getContenidosSearchHistorico } from "../helpers/HistoricoCajasData";
import { MovimientosTimelineMUI } from "./MovimientosTimelineMUI";
import esArray from "src/general_components/functionEsArray";
import { estadoColores, estadoIconos } from "./estadosCajas";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const ContenidoRetiroDevoluciones = () => {
  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const [abrirDetalle, setAbrirDetalle] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dataList, setDataList] = useState([]);
  const {
    setRetiroContenido,
    tipoRetiroSeleccionado,
    selectionModel,
    setSelectionModel,
    setClienteId,
    setSectorId,
    setTipoRetiroSeleccionado,
    setTipoContenidoSeleccionado,
    disabled,
    setListDatosEstadosCajas,
    setDisabled,
    snackbarVisible,
    setSnackbarVisible,
    message,
    setMessage,
    severity,
    setSeverity,
    setRequiredFormCuentaSector,
    setRequiredFormEstadosCajas,
    dataSearchFormGeneral,
    setActualizarListadoContenidos,
    setOmitirQuerySearch,
    busquedaSearchOn,
    setBusquedaSearchOn,
    setTipoRetiroMovimientoSeleccionado,
    tipoRetiroMovimientoSeleccionado, setSoloCajas,
     actualizarListado, setActualizarListado,aProcesar,
    setDataSearchFormGeneral,setAProcesar
  } = useContext(CajasContext);
  const { setChildFooter, setExpand, s3Values,cantidadTildadas,cantidadMarcadas,cantidadTotal } = useContext(TheLayoutContext);
  //DATOS DEL JSONFORM DE BUSQUEDA
  //FIXME VER QUE HACER CON ESTA VARIABLE
  const [params, setParams] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dataInfoBusqueda, setDataInfoBusqueda] = useState({});
  const [ajuste, setAjuste] = useState(false);
  const [detalleProcesar, setDetalleProcesar] = useState("");
  const [newPestania, setNewPestania] = useState(window.actualizarListado || false);
  const [actualizarSelected, setActualizarSelected] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cajasYubicacionesEncontradas, setCajasYubicacionesEncontradas] =
    useState(false);
  let url = window.location.href;
  const arrUrl = url.split("/");
  const proceso = arrUrl[arrUrl.length - 1]; // retorna el ultimo valor de la url
  const [modalStateHistorico, setMOdalStateHistorico] = useState(false);
  const [historicoCaja, setHistoricoCaja] = useState(false);

  const toggleModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };

  /// Ejecuta las funciones que consumen el servicio search y cierra el modal de busqueda
  const ObtenerDatos = () => {
    setAbrirBusqueda(!abrirBusqueda);
  };

  const handleAProcesarChange = (event) => {
    setAProcesar(event.target.value);
  };

  const copiarTodos = async () => {
    let registrosSeleccionados = [];

    if (aProcesar === 'soloTildadas' || aProcesar === 'soloMarcadas') {
      registrosSeleccionados = selectionModel.filter(item => {
        const condition = (aProcesar === 'soloTildadas') ? !s3Values.includes(item) : s3Values.includes(item);
        return condition;
      }).concat(s3Values.filter(item => !selectionModel.includes(item)));
  
    } else {
      registrosSeleccionados = selectionModel
    }
  
    const idsSeparadosPorComa = registrosSeleccionados.join(',');
    copiarAlPortapapeles(idsSeparadosPorComa);
  };

  const verComprobante= async () => {
   console.log('Redirigir a la visualizacion del comprobante.')
  };


  const copiarAlPortapapeles = (texto) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(texto).then(
        () => {
          setSnackbarVisible(true);
          setSeverity("success");
          setMessage('Texto copiado al portapapeles');
        },
        (err) => {
          setSnackbarVisible(true);
          setSeverity("error");
          setMessage('Error al copiar al portapapeles');
        }
      );
    }
  };

  /// Despliega el buscador cuando se presiona F2
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setOmitirQuerySearch(true);
        setActualizarListado(true);
        setAbrirBusqueda(!abrirBusqueda);
        setRequiredFormCuentaSector(true);
        setRequiredFormEstadosCajas(true);
        setListDatosEstadosCajas([
          { id: "6", detalle: "Alojada" },
          { id: "7", detalle: "Alojada Temporalmente" },
          { id: "11", detalle: "Alojada - Esperando devolución" },
        ]);
        setSoloCajas(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  const recargarPaginaProcesados = (registrosProcesados) =>{

    const cadenaFormateada = registrosProcesados.join(',');

    const data = {
      coincidencias: '1',
      eliminados: '0',
      search_params: {
        cajas: {
          fisica: '1',
        }, 
        id_contenidos: cadenaFormateada,
        
      },
      soloCajas: '1'
    };
    setDataSearchFormGeneral(data);
    setBusquedaSearchOn(true);
  }

  /// Funcion para consumir el servicio movement
  const procesar = async () => {
    setSnackbarVisible(false);
    let registrosFiltrados = {}
    let registrosSeleccionados = [];

    if (aProcesar === 'soloTildadas' || aProcesar === 'soloMarcadas') {
      registrosSeleccionados = selectionModel.filter(item => {
        const condition = (aProcesar === 'soloTildadas') ? !s3Values.includes(item) : s3Values.includes(item);
        return condition;
      }).concat(s3Values.filter(item => !selectionModel.includes(item)));
  
      registrosFiltrados = registrosSeleccionados.map((data) => ({ id: data }));
    } else {
      registrosSeleccionados = selectionModel.map((value) => ({ id: value }));
      registrosFiltrados = registrosSeleccionados;
    }
    
    var ret_total = "0";
    var ret_partial = "0";
    var dev_partial = "0";
    var dev_total = "0";
    var quita_total = "0";

    var ret_preferencial = "0";
    var ret_urgente = "0";

    switch (tipoRetiroSeleccionado) {
      case "1":
        ret_total = "1";
        break;
      case "2":
        ret_partial = "1";
        break;
      case "3":
        dev_total = "1";
        break;
      case "4":
        dev_partial = "1";
        break;
      case "5":
        quita_total = "1";
        break;
      default:
        console.log("NO hay coincidencia");
    }
    
    //Paranetros de prioridad
    if (ret_total === "1" || ret_partial === "1") {
      switch (tipoRetiroMovimientoSeleccionado) {
        case "1":
          break;
        case "2":
          ret_preferencial = "1";
          break;
        case "3":
          ret_urgente = "1";
          break;
        default:
          console.log("NO hay coincidencia");
      }
    }

    

    let valueAjuste = ajuste ? "1" : "0";
    var data = {
      detalle: detalleProcesar,
      ret_partial: ret_partial,
      ret_total: ret_total,
      dev_total: dev_total,
      ret_preferencial: ret_preferencial,
      ret_urgente: ret_urgente,
      dev_partial: dev_partial,
      quita_total: quita_total,
      contenidos: registrosFiltrados,
      ajuste: valueAjuste,
    };
    // var responseMovement = false
    var responseMovement = await movementContenidos(data);
     
    setAbrirDetalle(!abrirDetalle);
    if (responseMovement.status === "true") {
      recargarPaginaProcesados(registrosSeleccionados)
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage(responseMovement.message);
      limpiar();
      // limpiarSearchForm();
      setDisabled(!disabled);
      setActualizarListado(true);
      setBusquedaSearchOn(true);
      setActualizarListadoContenidos(true);
    } else {
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage(responseMovement.message);
    }
  };

  //Restablece todos los valores
  const limpiar = async () => {
    setSectorId([]);
    setClienteId([]);
    setTipoContenidoSeleccionado("");
    setDataList([]);
    setTipoRetiroSeleccionado("");
    setDataInfoBusqueda({});
    setDetalleProcesar("");
    setChildFooter(null);
    setExpand(false);
    setSelectionModel([]);
    setActualizarListado(true);
  };
  var columns = [];

  /*Almacena los detalles del dispositivo del usuario en una variable*/
  let details = navigator.userAgent;

  /*Creamos una expresión regular que contiene algunas palabras clave del dispositivo móvil
  para buscarlo en la cadena details*/
  let regexp = /android|iphone|kindle|ipad/i;

  /*Usamos el método test() para buscar expresiones regulares en details
  devolviendo un valor booleano*/
  let isMobileDevice = regexp.test(details);

  const flex = 100;
  const minWidth = 100;
  const maxWidth = 200;

  // columnas del Listtable
  if (isMobileDevice) {
    columns = [
      {
        field: "clienteName",
        headerName: "Cliente",
        headerAlign: "center",
        flex: 150,
        minWidth: 150,
        maxWidth: 175
      },
      {
        field: "sectorName",
        headerName: "Sector",
        headerAlign: "center",
        flex: 150,
        minWidth: 150,
        maxWidth: 175
      },
      {
        field: "concatCaja",
        headerName: "Caja",
        headerAlign: "center",
        flex: 150,
        minWidth: 150,
        maxWidth: 175
      },
      {
        field: "ubicacion",
        headerName: "Ubicacion",
        headerAlign: "left",
        flex: isMobileDevice ? flex : 1,
        maxWidth: isMobileDevice ? maxWidth : 350,
        minWidth: isMobileDevice ? minWidth : 175,
      },
      {
        field: "tipo_cajas",
        headerName: "Tipo de Caja",
        headerAlign: "left",
        flex: isMobileDevice ? flex : 1,
        maxWidth: isMobileDevice ? maxWidth : 350,
        minWidth: isMobileDevice ? minWidth : 175,
      },
      {
        field: "estados_cajas",
        headerName: "Estado",
        headerAlign: "left",
        flex: isMobileDevice ? flex : 2,
        maxWidth: isMobileDevice ? maxWidth : 150,
        minWidth: isMobileDevice ? minWidth : 100,
        renderCell: (params) => {
          if (params.value) {
            const color = estadoColores[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
            const icono = estadoIconos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
            return (
              <Grid>
                <Button
                  size="small"  // Tamaño más pequeño
                  variant="text"
                  style={{

                    color: color,

                  }}
                  disabled  // Desactiva la interacción del botón
                  startIcon={icono}  // Icono en el inicio del botón
                >
                  {params.value}
                </Button>
              </Grid>
            );
          } else {
            return null;
          }
        },
      },
      {
        //Boton Imprimir
        field: "actions",
        headerName: "Acciones",
        type: "actions",
        width: 100,
        minWidth: 100,
        maxWidth: 125,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip title="Acciones">
                <span>
                  <MoreVertIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={(e) => {
              handleOpenMenu(e);
              setParams(params);
            }}
            label="Acciones"
            {...(params.id.includes("cliente") || params.id.includes("sector")
              ? { hidden: true }
              : {})}
          />,
          <GridActionsCellItem
            {...(params.id.includes("cliente") || params.id.includes("sector")
              ? { hidden: true }
              : {})}
            icon={
              <Tooltip title="Historico">
                <span>
                  <HistoryIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={() => {
              toggleModalHistorico(params.id);
            }}
            label="Cajas"
          />
        ],
      },
    ];

  } else {
    columns = [
      {
        field: "clienteName",
        headerName: "Cliente",
        headerAlign: "left",
        flex: isMobileDevice ? flex : 100,
        maxWidth: isMobileDevice ? 200 : 200,
        minWidth: isMobileDevice ? minWidth : 50,
      },
      {
        field: "sectorName",
        headerName: "Sector",
        headerAlign: "left",
        flex: isMobileDevice ? flex : 2,
        maxWidth: isMobileDevice ? maxWidth : 200,
        minWidth: isMobileDevice ? minWidth : 50,
      },
      {
        field: "concatCaja",
        headerName: "Caja",
        headerAlign: "left",
        flex: 1,
      },
      {
        field: "ubicacion",
        headerName: "Ubicacion",
        headerAlign: "left",
        flex: isMobileDevice ? flex : 1,
        maxWidth: isMobileDevice ? maxWidth : 350,
        minWidth: isMobileDevice ? minWidth : 175,
      },
      {
        field: "tipo_cajas",
        headerName: "Tipo de Caja",
        headerAlign: "left",
        flex: isMobileDevice ? flex : 1,
        maxWidth: isMobileDevice ? maxWidth : 350,
        minWidth: isMobileDevice ? minWidth : 175,
      },
      {
        field: "estados_cajas",
        headerName: "Estado",
        headerAlign: "left",
        flex: isMobileDevice ? flex : 2,
        maxWidth: isMobileDevice ? maxWidth : 150,
        minWidth: isMobileDevice ? minWidth : 100,
        renderCell: (params) => {
          if (params.value) {
            const color = estadoColores[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
            const icono = estadoIconos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
            return (
              <Grid>
                <Button
                  size="small"  // Tamaño más pequeño
                  variant="text"
                  style={{

                    color: color,

                  }}
                  disabled  // Desactiva la interacción del botón
                  startIcon={icono}  // Icono en el inicio del botón
                >
                  {params.value}
                </Button>
              </Grid>
            );
          } else {
            return null;
          }
        },
      },
      {
        //Boton Imprimir
        field: "actions",
        headerName: "Acciones",
        type: "actions",
        width: 100,
        minWidth: 100,
        maxWidth: 125,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip title="Acciones">
                <span>
                  <MoreVertIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={(e) => {
              handleOpenMenu(e);
              setParams(params);
            }}
            label="Acciones"
            {...(params.id.includes("cliente") || params.id.includes("sector")
              ? { hidden: true }
              : {})}
          />,
          <GridActionsCellItem
            {...(params.id.includes("cliente") || params.id.includes("sector")
              ? { hidden: true }
              : {})}
            icon={
              <Tooltip title="Historico">
                <span>
                  <HistoryIcon color="info" />
                </span>
              </Tooltip>
            }
            onClick={() => {
              toggleModalHistorico(params.id);
            }}
            label="Cajas"
          />
        ],
      },
    ];
  }

  //NOTE REDECLARO LAS COLUMNAS DE ACCIONES DEL LISTTABLE
  const actionColumn = {
    field: "actions",
    headerName: "Acciones",
    type: "actions",
    flex: 1,

    getActions: (params) => [
      //Funcion para poder abrir el popup o para que te lleve al formulario de edicion o creacion

      //Boton Imprimir
      <GridActionsCellItem
        hidden={params.id.includes("CAJA") ? false : true}
        icon={
          <Tooltip title="Imprimir">
            <span>
              <StyleIcon color="info" />
            </span>
          </Tooltip>
        }
        onClick={() => {
          onClickEtiqueta(params);
        }}
        label="Imprimir"
      />,
    ],
  };

  const buttonBuscar = () => (
    <Button
      endIcon={<SearchIcon />}
      onClick={() => {
        setOmitirQuerySearch(true);
        setActualizarListado(true);
        setAbrirBusqueda(!abrirBusqueda);
        setRequiredFormCuentaSector(true);
        setRequiredFormEstadosCajas(true);
        setListDatosEstadosCajas([
          { id: "6", detalle: "Alojada" },
          { id: "7", detalle: "Alojada Temporalmente" },
          { id: "11", detalle: "Alojada - Esperando devolución" },
        ]);
        setSoloCajas(true);
      }}
      variant="contained"
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto, sans-serif',
        minWidth: '120px',
        width: '120px',
        fontSize: '11px',
        height: '30px',
      }}
    >
      Buscar
    </Button>
  );

  const [options, setOptions] = useState({
    cols: columns,
    getPaginated: getContenidosSearchPaginated,
    get: getContenidos,
    pageSize: [5, 10, 25],
    limit: 50,
    filter: true,
    exportData: true,
    notas: true,
    server: true,
    sinFilas: true,
    idElementData: dataSearchFormGeneral,
    actionColumnCustom: actionColumn,
    hierarchyOn: true,
    detailsOn: true,
    abmContenidos: "false",
    soloContenidosSearch: true,
    totales: true,
    buttonExtraOn: true,
    ButtonExtra: buttonBuscar,
    ButtonExtraMarcar: true,
    ButtonExtraDesmarcar:true
  });

  useEffect(() => {
    if (
      !Array.isArray(dataSearchFormGeneral) &&
      actualizarListado &&
      busquedaSearchOn
    ) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      setOptions({
        ...options,
        idElementData: dataSearchFormGeneral,
        sinFilas: false,
      });
      setActualizarListado(false);
      setBusquedaSearchOn(false);
    }
    // else if(actualizarListado){
    //   setOptions({
    //     ...options,
    //     sinFilas: true,
    //   });
    //   setActualizarListado(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busquedaSearchOn, actualizarListado]);

  //Funcion para el boton imprimir
  const onClickEtiqueta = () => {
    const newId = params.id;
    const URI_aux = imprimirEtiquetas(newId);
    if (URI_aux) window.open(URI_aux, "_blank");
  };

  useEffect(() => {
    setRetiroContenido(true); // Para reutilizar el selector de retiro contenidos
    //y que no se muestre el formulario de metadatos
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setearAjuste = async (data) => {
    setAjuste(data);
  };

  // Se utiliza para actualizar el array de id seleccionados en el listtable ya que el selectionModel
  // al limpiar sigue trayendo los elementos seleccionados anteriores
  useEffect(() => {
    if (!newPestania) {
      if (actualizarSelected) {
        //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
        setOptions({
          ...options,
          idElementData: dataSearchFormGeneral,
          sinFilas: true,
          actualizarSelected: actualizarSelected,
          setActualizarSelected: setActualizarSelected,
        });
      } else {
        setOptions({
          ...options,
          sinFilas: true,
          actualizarSelected: actualizarSelected,
        });
      }
    } else {
      setNewPestania(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualizarSelected]);

  // eslint-disable-next-line no-undef
  useEffect(() => {
    if (selectionModel.length > 0) {
      setExpand(true);
      setChildFooter(
        <Grid container spacing={0} alignItems="center" justifyContent="flex-end" style={{ display: 'flex' }}>
          <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>
            <SwitchAjuste
              setAjuste={setearAjuste}
              disabledCheckAjuste={selectionModel.length > 0 ? false : true}
            />
          </Grid>
          {proceso === "retiro" && (
            <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>

              <SelectRetiroContenido
                setTipoRetiroSeleccionado={setTipoRetiroSeleccionado}
                tipoRetiroSeleccionado={tipoRetiroSeleccionado}
              />
            </Grid>
          )}
          {proceso === "devolucion" && (
            <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>

              <SelectContenidoDevolucion
                setTipoRetiroSeleccionado={setTipoRetiroSeleccionado}
                tipoRetiroSeleccionado={tipoRetiroSeleccionado}
              />
            </Grid>
          )}
          {proceso === "retiro" && (
            <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>

              <SelectRetiroPrioridad
                disabledSelector={
                  !ajuste &&
                    (tipoRetiroSeleccionado === "1" ||
                      tipoRetiroSeleccionado === "2")
                    ? false
                    : true
                }
                setTipoRetiroMovimientoSeleccionado={
                  setTipoRetiroMovimientoSeleccionado
                }
                tipoRetiroMovimientoSeleccionado={
                  tipoRetiroMovimientoSeleccionado
                }
              />
            </Grid>
          )}


          <Grid item xs={!isMobileDevice ? 3 : 6} style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>
            <Button
              disabled={
                selectionModel.length > 0 && tipoRetiroSeleccionado !== ""
                  ? false
                  : true
              }
              variant="contained"
              onClick={() => {
                setAbrirDetalle(!abrirDetalle);
              }}
              style={{
                width: "98%",
                bottom: "6px",
              }}
            >
              Procesar
            </Button>
          </Grid>

        </Grid>
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectionModel,
    tipoRetiroSeleccionado,
    ajuste,
    tipoRetiroMovimientoSeleccionado,
  ]);

  // Parametros Popover
  const [open, setOpen] = useState(null); // variable popover
  // eslint-disable-next-line no-unused-vars
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const toggleModalHistorico = async (id) => {
    const response = await getContenidosSearchHistorico(id, "caja")

    if (response) {
      setHistoricoCaja(esArray(response.wsResponse.movimientos?.historico));
      toggleModalMovimientos();
    } else {

    }
    console.log("Response", response);
  };

  const toggleModalMovimientos = async () => {
    setMOdalStateHistorico(!modalStateHistorico);

  };

  return (
    <Grid>

      <Grid>
        <Grid>
          {" "}
          <ListTablePaginated options={options} />
        </Grid>

        {modalStateHistorico && (
          <SimpleDialogComponent
            open={modalStateHistorico}
            modalName={"Historico"}
            child={<MovimientosTimelineMUI data={historicoCaja} />}
            setPushData={toggleModalMovimientos}
            handleClose={toggleModalMovimientos}
            buttonEnviar={false}
            buttonCancelar={true}
          />
        )}

        {/* NOTE BOTON BUSCAR CONTENIDO QUE ABRE EL FORMULARIO */}
        {abrirBusqueda && (
          <SimpleDialogComponent
            open={abrirBusqueda}
            modalName={"Busqueda de contenidos"}
            child={
              <SearchFormGeneral toggleModal={toggleModal} proceso={proceso} />
            }
            setPushData={ObtenerDatos}
            handleClose={toggleModal}
            buttonEnviar={false}
            buttonCancelar={true}
          />
        )}
        <ComponentDialog
          listName={"Detalle"}
          buttonClicked={abrirDetalle}
          setButtonClicked={setAbrirDetalle}
          withErrors={false} //Sirve para controlar el boton de crear y los errorres del formulario
          setPushData={procesar} //Sirve para enviar los datos al servidor
          child={
            <Grid container
              style={isMobileDevice ? { background: "white", padding: "10px", marginBottom: "10px" } : { background: "white", padding: "30px", marginBottom: "50px" }}>
              <TextField
                sx={isMobileDevice ? { width: 200 } : { width: 500 }}
                id="filled-multiline-static"
                label="Detalle"
                multiline
                rows={4}
                value={detalleProcesar}
                onChange={(e) => {
                  setDetalleProcesar(e.target.value);
                }}
              />
              <FormControl sx={{ mt: 1, minWidth: "100%" }} fullWidth variant="outlined" size="small">
                <InputLabel id="aProcesarLabel">A procesar</InputLabel>
                <Select
                  labelId="aProcesarLabel"
                  value={aProcesar}
                  onChange={handleAProcesarChange}
                  label="A procesar"
                >
               { s3Values && <MenuItem value="soloMarcadas">{"Solo marcadas ("+cantidadMarcadas+")"}</MenuItem>}
                <MenuItem value="soloTildadas">{"Solo tildadas ("+cantidadTildadas+")"}</MenuItem>
                <MenuItem value="todas">{"Todas ("+cantidadTotal+")"}</MenuItem>
                </Select>
              </FormControl>
              <Grid item style={{marginTop:'5px'}}> 
                <Button
                variant="contained"
                onClick={copiarTodos}
                fullWidth
                startIcon={<ContentCopyIcon />}
              >
                Copiar cajas
                </Button>
                
              </Grid>
              <Grid item style={{marginTop:'5px',marginLeft:'15px'}}> 
                <Button
                variant="contained"
                onClick={verComprobante}
                fullWidth
                startIcon={<VisibilityIcon/>}
                style={{ backgroundColor: 'green', color: 'white' }}
              >
                Ver Comprobante
                </Button>
                
              </Grid>
            </Grid>
          }
        />
        <SnackbarMessenger
          open={snackbarVisible}
          message={message}
          severity={severity}
          hide={8000}
          close={() => {
            setSnackbarVisible(false);
          }}
        />

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={onClickEtiqueta}>
            <StyleIcon color="info" sx={{ mr: 2 }} />
            Imprimir
          </MenuItem>
        </Popover>
      </Grid>
    </Grid>
  );
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getEstadoCivil } from "src/general_helpers/EstadoCivilData";
import { esArray } from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";
import { Autocomplete, TextField } from "@mui/material";

const SelectorEstadoCivil = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.id_estado_civil;
  const [selectedEstadoCivil, setSelectedEstadoCivil] = useState("");

  const [dataDefaults, setDataDefaults] = useState([]);

  const estado_civil = getFromLS("siam-estado_civil").estado_civil;

  useState(() => {
    const getEstadosCivil = async () => {
      if (estado_civil) {
        setDataDefaults(esArray(estado_civil));
      } else {
        const response = await getEstadoCivil()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    getEstadosCivil()

  }, []);


  useEffect(() => {
    setSelectedEstadoCivil(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);


  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setSelectedEstadoCivil(newValue);
      props.handleChange(props.path, newValue.id); // Obtén el ID del objeto seleccionado
    } else {
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        size="small"
        sx={{ width: "100%", paddingTop: "20px" }}
      >
        <Autocomplete
          size="small"
          value={selectedEstadoCivil ? selectedEstadoCivil : null}
          onChange={handleChange}
          options={dataDefaults || []}
          sx={{mt:-1, minWidth:"100%" }}
          getOptionLabel={(option) => option.id + ' - ' + option.nombre || ""}
          renderInput={(params) => (
            <TextField {...params}
              label="Estado Civil"
              disabled={disabled}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorEstadoCivilTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorEstadoCivil";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorEstadoCivilComponent = {
  renderer: withJsonFormsControlProps(SelectorEstadoCivil),
  tester: SelectorEstadoCivilTester,
};

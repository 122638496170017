/*Almacena los detalles del dispositivo del usuario en una variable*/
let details = navigator.userAgent;

/*Creamos una expresión regular que contiene algunas palabras clave del dispositivo móvil
para buscarlo en la cadena details*/
let regexp = /android|iphone|kindle|ipad/i;

/*Usamos el método test() para buscar expresiones regulares en details
devolviendo un valor booleano*/
let isMobileDevice = regexp.test(details);

const layout = (isMobileDevice) ? 'VerticalLayout' : 'HorizontalLayout'

export const uiSchemaCajasUbicacionSelectDeposito = {
  type: "Group",

  elements: [
    {
      type: layout,
      elements: [
        {
          type: "Control",
          label: "Deposito",
          scope: "#/properties/idDep",
        },
      ],
    },
  ],
};

export const uiSchemaServiciosInternetForm = {
  type: "VerticalLayout",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/id",
          options: {
            readonly: true
          }
        },
        {
          type: "Control",
          scope: "#/properties/selectedTipoServicio",
        },
        {
          type: "Control",
          scope: "#/properties/valor",
        },
      ],
    },
  ],
};
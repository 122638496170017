import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getCategoriaCuentas } from "src/projects/gestion_comercial/cuentas/helpers/CategoriaCuentasData";
import {esArray} from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";
import { Autocomplete, TextField } from "@mui/material";

const SelectorCategoriaCuentas = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.selectedCategoriaCuentas;
  const [selectedCategoriaCuenta, setSelectedCategoriaCuenta] = useState("");
  const [dataDefaults, setDataDefaults] = useState([]);

  const categoria_cuentas = getFromLS("siam-categoria_cuentas").categoria_cuentas;

  useState(() => {
    const getCategorias = async () => {
      if (categoria_cuentas) {
         setDataDefaults(esArray(categoria_cuentas));
      }else {
        const response = await getCategoriaCuentas()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    getCategorias()
  
  }, []);


  useEffect(() => {
    setSelectedCategoriaCuenta(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setSelectedCategoriaCuenta(newValue);
      props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
    }else{
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
         <Autocomplete
        value={selectedCategoriaCuenta  ? selectedCategoriaCuenta : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.nombre || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Categoria de Cuenta" 
          disabled={disabled}/>
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorCategoriaCuentasTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorCategoriaCuentas";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorCategoriaCuentasComponent = {
  renderer: withJsonFormsControlProps(SelectorCategoriaCuentas),
  tester: SelectorCategoriaCuentasTester,
};
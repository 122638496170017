import datos from "src/services/datos";

export const createComprobantes = async (datosParams, datoExtra) => {
    const comprobantes = new datos("comprobantes");
    const datosComprobante = datosParams
    let response = await comprobantes.comprobantesCreate(datosComprobante);

    let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));


    if (response.status === 'true' && parametros_proyecto?.id === '30') {

        // console.log(floripa, 'floripa')
        const comprobantes_extra_data = new datos("comprobantes_extra_data");

        const dataExt = JSON.stringify({ "detalle_externo": datoExtra });
        let idComprobante = response.wsResponse.id_comprobantes
        let data = {

            id_comprobantes: idComprobante,
            extra_data: dataExt

        }
        response = await comprobantes_extra_data.insert(data);

    }
    return response;
};


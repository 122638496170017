import React, { useState, useEffect, useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ThemeProvider,
  createTheme,
  Switch
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CajasContext } from 'src/context/CajasContext/CajasContext';
import esArray from 'src/general_components/functionEsArray';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

export const TotalizadoresSearch = (datos) => {
  const textStyle = {
    margin: '0px',
    fontSize: '0.875rem',
    // lineHeight: '1.5',
    letterSpacing: '0px',
    fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontWeight: 400,
    scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    display: 'block',
  };
  const [expanded, setExpanded] = useState(false);
  const [switchesState, setSwitchesState] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [primerRender, setPrimerRender] = useState(true);
  const [resetear, setResetear] = useState(false);

  const { dataSearchFormGeneral, setDataSearchFormGeneral, setBusquedaSearchOn, setActualizarListado } = useContext(CajasContext);

  const theme = createTheme({
    typography: {
      fontFamily: 'Quicksand, sans-serif',
    },
  });

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const data = datos.responseSearch.custom_counters || {};
  const renderCheckboxes = (items, sectionName) =>
    esArray(items).map((item) => {
      const { id, nombre, cant } = item;
      const isChecked = switchesState[sectionName] && switchesState[sectionName][id];

      return (
        <div key={id} style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
          <Switch
            color="primary"
            checked={isChecked || false}
            onChange={() => handleSwitchChange(id, item, sectionName)}
          />
          <Typography variant="body1" style={{ ...textStyle, color: '#ffffff' }}>
            {nombre} <span style={{ color: '#ffffff', fontWeight: "bold" }}>({cant})</span>
          </Typography>
        </div>
      );
    });

  const handleSwitchChange = (key, item, sectionName) => {
    const currentSwitchesState = { ...switchesState };

    if (!currentSwitchesState[sectionName]) {
      currentSwitchesState[sectionName] = {};
    }

    currentSwitchesState[sectionName][key] = !currentSwitchesState[sectionName][key];

    const updatedSelectedItems = {};

    for (const section in currentSwitchesState) {
      for (const id in currentSwitchesState[section]) {
        if (currentSwitchesState[section][id]) {
          if (!updatedSelectedItems[section]) {
            updatedSelectedItems[section] = [];
          }

          const itemsSelect = esArray(data[section].items);
          const selectedItem = itemsSelect.find((item) => item.id === id);
          if (selectedItem) {
            updatedSelectedItems[section].push({
              id,
              nombre: selectedItem.nombre,
              cant: selectedItem.cant,
            });
          }
        }
      }
    }

    setSwitchesState(currentSwitchesState);
    setSelectedItems(updatedSelectedItems);
  };

  useEffect(() => {
    let selecteItems = selectedItems

    if (selecteItems?.Flags?.length > 0) {
      selecteItems = { 'Flags': selecteItems?.Flags.map(item => ({ id: item.id, cant: 1 })) };
    }

    if (!primerRender && !resetear) {
      setDataSearchFormGeneral((prevData) => ({
        ...prevData,
        search_params: {
          ...prevData.search_params,
          custom_query: selecteItems,
          searchModal: false,
        },
      }));
      setBusquedaSearchOn(true);
      setActualizarListado(true);
    }
    setPrimerRender(false);
    setResetear(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    if (dataSearchFormGeneral.search_params?.searchModal) {
      setSwitchesState({});
      setResetear(true);
      setSelectedItems({});
    }
  }, [dataSearchFormGeneral]);

  let filteredExcedida = esArray(data?.excedida?.items).filter(item => item.id === 'excedida');
  let filteredDeformada = esArray(data?.deformada?.items).filter(item => item.id === 'deformada');
  if (filteredExcedida.length > 0 || filteredDeformada.length > 0) {

    const flags = {

      items: [
        ...filteredExcedida,
        ...filteredDeformada
      ]

    };
    data.condicion_cajas = flags
  }
  return (
    <div style={{ marginBottom: 10, marginTop: 10 }}>
      <ThemeProvider theme={theme}>
        <Accordion
          expanded={expanded}
          onChange={handleExpand}
          sx={{ borderRadius: 3 }} // Agrega el estilo de bordes redondeados
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <FormatListNumberedRtlIcon />
            <ZoomInIcon />
            <Typography style={{ ...textStyle, color: '#3c4b64', fontWeight: "bold", paddingLeft: '1%' }}>Totales | Filtros</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {Object.keys(data).map((sectionName) => {
                const section = data[sectionName];
                const displayName = namesSections[sectionName] || sectionName;
                if (displayName !== 'excedida' && displayName !== 'deformada') {
                  return (
                    <div key={sectionName} style={{ flex: 1, paddingLeft: '10px', justifyContent: 'center' }}>
                      <Accordion
                        key={sectionName}
                        sx={{ background: "#0A395F", borderRadius: 5, justifyContent: 'center' }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon style={{ color: '#ffffff' }} />}
                          aria-controls={`${sectionName}-content`}
                          id={`${sectionName}-header`}
                        >
                          <Typography variant="subtitle1"
                            style={{
                              ...textStyle, display: 'flex', alignItems: 'center',
                              color: '#ffffff', fontWeight: "bold",
                              fontSize: 14, fontFamily: 'Roboto, sans-serif',
                            }}>
                            {displayName}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {renderCheckboxes(section.items, sectionName)}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </ThemeProvider>
    </div>
  );
};

const namesSections = {
  cuentas_localizaciones: 'Sectores',
  tipo_cajas: 'Tipo de Cajas',
  estados_cajas: 'Estados de Cajas',
  activo: 'Activo',
  condicion_cajas: 'Condicion de Cajas',
};

export default TotalizadoresSearch;
import datos from "src/services/datos";
import Swal from "sweetalert2";
import esArray from "src/general_components/functionEsArray";
const maestros_bancos = new datos("maestros_bancos");

export const getMaestrosBancos= async (auth) => {
  maestros_bancos.addCondition("maestros_bancos", "id", 0, ">");
  const response = await maestros_bancos.get();
  return esArray(response.wsResponse.maestros_bancos);
};

export const getMaestrosBancosPaginated = async (limit, offset) => {
  maestros_bancos.addCondition("maestros_bancos", "id", 0, ">");
  maestros_bancos.addLimitCondition(limit, offset);
  const response = await maestros_bancos.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: esArray(response.wsResponse.maestros_bancos),
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const deleteMaestrosBancos = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este Registro?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const maestros_bancos = new datos("maestros_bancos");
      let data = {
        id: id,
      };
      const response = await maestros_bancos.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El registro ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });
  return status;
};
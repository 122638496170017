import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getArticulos } from "src/projects/articulos/helpers/ArticulosData";

const SelectorServicios = (props) => {
  const jsonForm = useJsonForms();
  const dataDefaultsAssigned = jsonForm.core.data.selectedServicio;
  const [servicios, setServicios] = useState([]);
  const [selectedServicio, setSelectedServicio] = useState(
    dataDefaultsAssigned || []
  );
  
  const getCuentas = async () => {
    let aux_storage = JSON.parse(localStorage.getItem("siam-cuentasservicios"));
    if (aux_storage) {
      setServicios(aux_storage);
    } else {
      const response = await getArticulos();
      if (response) {
        setServicios(response.data);
        localStorage.setItem(
          "siam-cuentasservicios",
          JSON.stringify(response.data)
        );
      }
    }
  };

  useEffect(() => {
    getCuentas();
  }, []);

  const handleChangeServicio = (selectedOption) => {
    setSelectedServicio({
      ...selectedOption,
    });
    handleChanges(selectedOption);
  };

  const handleChanges = (selectedOption) => {
    props.handleChange(props.path, selectedOption);
  };

  return (
    <>
      <FormControl sx={{ width: "100%", paddingBottom: "10px" }}>
        <Autocomplete
          options={servicios && servicios}
          value={
            selectedServicio?.id &&
            servicios.length > 0 &&
            servicios.filter((x) => x.id === selectedServicio.id)[0]
          }
          getOptionLabel={(e) => e.id + " - " + e.nombre}
          getOptionValue={(e) => e.id}
          renderInput={(params) =>
            servicios?.length > 0 ? (
              <TextField {...params} label="Servicio" size="medium" />
            ) : (
              <CircularProgress {...params} size={30} />
            )
          }
          onChange={(e, newValue) => handleChangeServicio(newValue)}
         fullWidth
          size="medium"
        />
      </FormControl>
    </>
  );
};

const SelectorServiciosTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("object"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorServicios";
      }
      return false;
    })
  )
);

// hand this over together with the provided `renderers` to the `JsonForms` component
export const SelectorServiciosComponent = {
  renderer: withJsonFormsControlProps(SelectorServicios),
  tester: SelectorServiciosTester,
};

import datos from "src/services/datos";
import { esArray } from "src/general_components/functionEsArray";


export const getDepartamento = async (id_provincia) => {
    const departamentos = new datos('departamento')
    departamentos.addCondition('departamento', 'id', 0, '>')
    departamentos.addCondition('departamento', 'id_provincia', id_provincia, '=')
    const response = await departamentos.get()
    return esArray(response.wsResponse.departamento)
}
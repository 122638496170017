export const schemaSearchUbicacion = {
  type: "object",
  properties: {
    activoUbicacionTemporal: {
      type: 'boolean',
      title: "Ubicacion Temporal",
      description: "Activo Ubicacion Temporal",
    },
    idDep: {
      type: "number",
      customRender: "SelectorDeposito",
      description: "Seleccione perfil",
    },
    sector: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
      
    },
    modulo: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
      
    },
    estante: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
     
    },
    posicion: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
    },
    estiba: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
    },
    profundidad: {
      type: "integer",
      description: "Ingresar una coordenada",
      "minimum": 0,
    },
    ubicacionTemporal: {
      type: "string",
      description: "Ingresar una ubicacion Temporal",
    },
    id_tipo_ubicaciones: {
      type: "number",
      customRender: "SelectorTipoUbicaciones",
      description: "Seleccione tipo ubicaciones",
    },
  },
  required: [
    
  ]
};
import { useState, useEffect } from "react";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import FormularioDinamico from "./FormularioDinamico";
import { SimpleDialogComponent } from "./SimpleDialogComponentRC";
import { postDinamico } from "src/general_helpers/AgegarDinamicoData";
import { actualizarLocalStorageModel } from "src/general_helpers/GetModelsDeleteModelsGeneral";

export const FormularioDinamicoModelos = (props) => {
  const {
    data, // datos de edicion
    createButton, // controla el estado del modal para mostrar o ocultar
    setCreateButton, // controla el estado del modal para mostrar o ocultar
    formData, // contiene las propiedades de los distintos campos a armar
    setOptions, // sirve para actualizar la grilla
    options, // sirve para actualizar la grilla
    modelo, // dato del tipo string, define sobre que modelo realizar la insercion
    dataDefault, // dato del tipo array, datos por defecto , sirve para no armar determinados inputs ,ya que su valor ya esta predefinido
    inputsObligatorios // dato del tipo array ,define la obligatoriedad de los inputs incluidos. Ej: ["campo1","campo2"]
  } = props;
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success');
  const [formValues, setFormValues] = useState({});
  const valuesDefaultForm =  {};


  useEffect(() => {
    // Verificar si hay datos
    if (data) {
      
      // Verificar si dataDefault tiene valores
      if (dataDefault && Object.keys(dataDefault).length > 0) {
        // Combinar los valores de dataDefault y data
        const combinedData = { ...dataDefault, ...data };
        
        // Establecer los valores combinados en formValues
        setFormValues(combinedData);
      } else {
        // Establecer los valores de data en formValues
        setFormValues(data);
      }
    }
  }, [data, dataDefault]);


  const handleSubmit = async () => {
    const data = { ...formValues, ...valuesDefaultForm }
    const response = await postDinamico(modelo, data);
    if (response.status === "true") {
      if (data.id) {
        setMessage("Se modifico exitosamente!");
      } else {
        setMessage("Se agrego exitosamente!");
      }
      await actualizarLocalStorageModel(modelo);
      setSeverity("success");
      setSnackbarVisible(true);
      // setNewElementAdd(response.wsResponse[modelo])
      setFormValues({});
      setOptions({ ...options, refresh: true });
      toggleModal();

    } else {
      setMessage("Hubo un problema al agregar el " + modelo);
      setSeverity("warning");
      setSnackbarVisible(true);
    }
  };

  const toggleModal = () => {
    setCreateButton(!createButton)
  }

  const dialogChild = <FormularioDinamico
    formData={formData}
    formValuesPost={formValues}
    setFormValuesPost={setFormValues}
    toggleModalForm={toggleModal}
    handleSubmit={handleSubmit}
    inputsOblicatorios={inputsObligatorios || []}
    dataEdit={data} />

  return (
    <>
      <SimpleDialogComponent
        open={createButton}
        modalName={data.id ? "Formulario de Modificacion" : "Formulario de Alta"}
        child={dialogChild}
        handleClose={toggleModal}
        buttonEnviar={false}
        buttonCancelar={false}
        maxWidth="lg"
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => { setSnackbarVisible(false) }}
        hide={8000}
      />
    </>
  );
};


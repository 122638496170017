import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CajasProvider } from "../../../context/CajasContext/CajasContext";
import { ConsultaComprobantesList } from "../components/ConsultaComprobantesList";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";

export function ConsultaComprobantes() {
  return (
    <Grid style={{ marginBottom: "50px", background: "#FFFFFFF", paddingTop: 10 }}>
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          Consulta de Comprobantes
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <CajasProvider>
        <ConsultaComprobantesList />
      </CajasProvider>
    </Grid>
  );
}
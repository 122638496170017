import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Container, } from "@mui/material";
import React, { useState } from "react";
import { schemaFiltroServiciosForm } from "src/projects/gestion_comercial/components/forms/schemaFiltroServiciosForm"
import { uiSchemaFiltroServiciosForm } from "../../components/forms/uiSchemaFiltroServiciosForm";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { SelectorCuentasJuridicasComponent } from "../../components/forms/customRenders/selectorCuentasJuridicas";
import { SelectorServiciosComponent } from "../../components/forms/customRenders/selectorServicios";
import { Buttons } from "src/general_components/Buttons";

const localize = require("ajv-i18n");

export const CuentasServiciosFiltroForm = (props) => {
    
    const [snackbarVisible, setSnackbarVisible] = useState(false)
    
     //FIXME VER QUE HACER CON ESTA VARIABLE
    // eslint-disable-next-line no-unused-vars
    const [message, setMessage] = useState('')

     //FIXME VER QUE HACER CON ESTA VARIABLE
    // eslint-disable-next-line no-unused-vars
    const [severity, setSeverity] = useState('');

     //FIXME VER QUE HACER CON ESTA VARIABLE
    // eslint-disable-next-line no-unused-vars
    const [withErrors, setWithErrors] = useState(false);

    const initialDataForm = ({
        selectedCuentaJuridica: "",
        selectedServicio: "",
    })
    const [dataForm, setDataForm] = useState({
        ...initialDataForm
    });

    const onChange = (errors, data) => {
        localize.es(errors);
        let err = errors && errors.length > 0;
        setWithErrors(err);
        setDataForm(data);

        return { errors, data };
    };

    const buscar = () => {
        props.props.filtros(dataForm)
        props.close()
    }

    return (
        <Container maxWidth="lg">
                <JsonForms
                    onChange={({ errors, data }) => onChange && onChange(errors, data)}
                    schema={schemaFiltroServiciosForm}
                    uischema={uiSchemaFiltroServiciosForm}
                    renderers={[
                        ...materialRenderers,
                        SelectorCuentasJuridicasComponent,
                        SelectorServiciosComponent,
                    ]}
                    data={dataForm}
                    cells={materialCells}
                />
                <Buttons 
                    labelButtonSave={"Buscar"}
                    onClickSubmit={buscar}
                    buttonCerrar={()=>{
                        setDataForm({initialDataForm});
                        props.close()
                    }
                    }
                />                
                <SnackbarMessenger
                    open={snackbarVisible}
                    message={message}
                    severity={severity}
                    close={() => { setSnackbarVisible(false) }} />
        </Container >
    )
}

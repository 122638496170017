import { useState } from "react";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";
import { deleteTipoCajas, getTipoCajas, getTipoCajasPaginated, postTipoCajas } from "../helpers/TipoCajasData";
import { TipoCajasForm } from "./TipoCajasForm";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";
import { CajasProvider } from "src/context/CajasContext/CajasContext";

export const TipoCajasList = () => {
  const [createButton, setCreateButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [buttonMetadatos, setButtonMetadatos] = useState(false);

  const [tipoCajas, setTipoCajas] = useState([]);
  const listName = "Tipos de Cajas";
  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "detalle", headerName: "Detalle", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero

  ];

  const [options, setOptions] = useState({
    cols: columns,
    get: getTipoCajas,
    getPaginated: getTipoCajasPaginated,
    pageSize: [5, 10, 25],
    edit: true,
    delete: deleteTipoCajas,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setTipoCajas,
  });

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  //EL BUTTONMETADATOS SOLO ES PARA ESTE CASO, VERIFICAR CADA OCASION.
  if (
    createButton === false &&
    tipoCajas.id !== undefined &&
    buttonMetadatos === false
  ) {
    setTipoCajas([]);
  }

  return (


    <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          {listName}
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <CajasProvider>
        <>

          <ListTablePaginated options={options} />
          <TipoCajasForm
            data={tipoCajas}
            createButton={createButton}
            listName={listName}
            setCreateButton={setCreateButton}
            componentList={"/tipo_cajas/admin"}
            postData={postTipoCajas}
            setOptions={setOptions}
            options={options}
          />
        </>
      </CajasProvider>
    </Grid>
  );
};


import {
  AlternateEmail,
  FmdGood,
  InfoOutlined,
  ListAltOutlined,
  MenuBook,
} from "@mui/icons-material";
import { Button, Container, Grid, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  esES,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useCuentasContext } from "src/context/CuentasContext";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import { CuentasInfoAdicionalForm } from "./CuentasInfoAdicionalForm";
import { CuentasJuridicasForm } from "./CuentasJuridicasForm";
// import { CuentasLocalizacionesList } from "./CuentasLocalizacionesList";
import { CuentasParametrosList } from "./CuentasParametrosList2";
import { CuentasServiciosInternetList } from "./CuentasServiciosInternetList2";
import { CuentasContactosList } from "./CuentasContactosList2";
import { CuentasLocalizacionesList } from "./CuentasLocalizacionesList2";
import Swal from 'sweetalert2'
import "src/services/styles/sweetAlert.css";
import Pagination from '@mui/material/Pagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { CustomNoRowsOverlay } from "src/general_components/Overlay";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,

} from "@mui/x-data-grid";
import ContactsIcon from '@mui/icons-material/Contacts';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { CuentasBancariasList } from "./CuentasBancariasList";
import { id_proyecto } from "src/configs/config";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { esArray } from "src/general_components/functionEsArray";
import PrintIcon from '@mui/icons-material/Print'; import datos from "src/services/datos";



export const ListTableCuentas = (data) => {
  const { cuenta, setCuenta, saveData, setEditData, savedData, setSavedData,
    updateLocalizaciones, setUpdateLocalizaciones, updateServiciosInternet,
    setUpdateServiciosInternet, closeModals, setCloseModals, updateParametros, setUpdateParametros,
    snackbarVisible, setSnackbarVisible, message, severity, criteriosSearchCuentasJuridicas } =
    useCuentasContext();
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState(data.options.cols); // columnas que se muestran
  let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));
  const {

    buttonExtraOn,
    ButtonExtra,
    esJuridica
  } = data.options;

  //FIXME VER QUE HACER CON ESTA VARIABLE
  // eslint-disable-next-line no-unused-vars
  const [redirect, setRedirect] = useState({
    ans: false,
    id: null,
  });

  //const [pageLimit,setpageLimit] =useState(0)
  const [page, setPage] = useState(0); // PAGINA INICIAL
  const pageSize = data.options.limit || 10; // TAMAÑO DE LA PAGINA A MOSTRAR (CANTIDAD DE RECORDS). SI NO SE SETEA EN LOS PROPS, USA POR DEFECTO 10
  const [limit, setLimit] = useState(data.options.limit || 10); // LIMITE DEL FETCH. POR DEFECTO ES 10
  const [loading, setLoading] = useState(false); // ESTADO DE LA TABLA
  const [totalRowCount, setTotalRowCount] = useState(0); // FILAS TOTALES QUE TRAE EL FECTCH SIN PAGINACION EL
  const totalPages = Math.ceil(totalRowCount / limit);
  const [offset, setOffset] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCuentasJuridicas, setOpenModalCuentasJuridicas] =
    useState(false);
  const [selectedForm, setSelectedForm] = useState(false);
  const [modalName, setModalName] = useState(false);
  const [buttonEnviar, setButtonEnviar] = useState(false);
  const [updater, setUpdater] = useState(false);
  const [lastPage, setLastPage] = useState(false)
  //Guarda limit de la página anterior
  const [pageLimit, setpageLimit] = useState(0)
  const viewCustomToolbar = data.options.viewCustomToolbar || true;
  // eslint-disable-next-line no-unused-vars
  const [hiddenButtonAgregar, setHiddenButtonAgregar] = useState(
    data.options.hiddenButtonAgregar || false
  ); // Se agrega este parametro para desabilitar el boton agregar

  const [filteredData, setFilteredData] = useState([]);
  const toggleModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  function editarCuenta(item) {
    let cuenta_aux = { ...cuenta };
    cuenta_aux.id_cuentas = item.row.id_cuentas;
    console.log("cuenta_aux", cuenta_aux)
    setCuenta(cuenta_aux);
  }


  const getCuentasJuridicas = async (filters) => {

    let id_cuentas = criteriosSearchCuentasJuridicas?.id;
    let razon_social = criteriosSearchCuentasJuridicas?.razon_social;
    let localidad = criteriosSearchCuentasJuridicas?.localidad;
    let cuit = criteriosSearchCuentasJuridicas?.cuit;

    // Obteniendo los valores de los filtros
    // eslint-disable-next-line no-unused-vars
    const { items, linkOperator } = filters;
    const objectCuentasJuridicas = new datos("cuentas_personas_juridicas")

    if (items && items.length > 0) {
      // Aplicar los filtros
      items.forEach(filter => {
        if (filter.columnField && filter.operatorValue) {
          // Verificar si se proporciona tanto la columna como el operador
          const { columnField, operatorValue, value } = filter;
          // Agregar la condición utilizando los valores dinámicos
          if (operatorValue === "contains") {
            objectCuentasJuridicas.addLikeCondition("cuentas_personas_juridicas", columnField, value);
          } else {
            // Agregar otras condiciones según sea necesario
          }
        }
      });
    }

    objectCuentasJuridicas.addCondition("cuentas_personas_juridicas", "id", 0, ">")
    //Cuando se ingresan criterios de Bsqueda
    objectCuentasJuridicas.innerJoinTables('cuentas_telefonos', 'cuentas_personas_juridicas', "id_cuentas", "id_cuentas", "=");
    objectCuentasJuridicas.innerJoinTables('cuentas_localizaciones', 'cuentas_personas_juridicas', "id_cuentas", "id_cuentas", "=");
    objectCuentasJuridicas.innerJoinTables('cuentas_servicios_internet', 'cuentas_personas_juridicas', "id_cuentas", "id_cuentas", "=");

    id_cuentas ? objectCuentasJuridicas.addCondition("cuentas_personas_juridicas", "id_cuentas", id_cuentas, "=") : id_cuentas = '';
    razon_social ? objectCuentasJuridicas.addLikeCondition("cuentas_personas_juridicas", "razon_social", razon_social) : razon_social = '';
    localidad ? objectCuentasJuridicas.addCondition("cuentas_localizaciones", "id_localidad", localidad, "=") : localidad = '';
    cuit ? objectCuentasJuridicas.addCondition("cuentas_documentos_inscripciones", "numero", cuit, "=") : cuit = '';

    objectCuentasJuridicas.addOrderCondition("cuentas_personas_juridicas", "id", "DESC")
    // objectCuentasJuridicas.addLimitCondition(limit, offset)

    const response = await objectCuentasJuridicas.get()
    const cuentasPersonas = esArray(response.wsResponse.cuentas_personas_juridicas)


    const datosTransformados = cuentasPersonas.map(obj => {
      if (obj.relations) {
        const arrDomicilio = esArray(obj.relations.cuentas_localizaciones);
        const arrEmail = esArray(obj.relations.cuentas_servicios_internet);
        const arrNumero = esArray(obj.relations.cuentas_telefonos);

        const arrDomicilioFilter = arrDomicilio.filter(obj => obj.principal === '1')
        const arrEmailFilter = arrEmail.filter(obj => obj.id_tipo_servicios_internet === '1')

        const domicilio = arrDomicilioFilter[0].domicilio || '';
        const email = arrEmailFilter[0].valor || '';
        const numero = arrNumero[0].numero || '';

        return {
          ...obj,
          domicilio,
          email,
          numero,
        };
      } else {
        return {
          ...obj,
          domicilio: '',
          email: '',
          numero: '',
        };
      }
    });
    return datosTransformados
  };

  function buscarPorIdProyecto(id) {
    return esArray(parametros_proyecto)?.find(objeto => objeto.id === id);
  }

  const handlePrintClick = async () => {
    const data = await getCuentasJuridicas(filteredData)
    const columnsWithoutInfo = columns.filter(column => column.headerName !== 'Info de Cuentas');
    const doc = new jsPDF();


    const proyecto_encontrado = buscarPorIdProyecto(id_proyecto);

    const logoUrl = proyecto_encontrado?.url_imagen;

    doc.addImage(logoUrl, 'PNG', 10, 10, 22, 18);
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text("Listado de Cuentas", 80, 20);
    doc.setFont('helvetica', 'normal');
    const startY = 30;
    const spaceBetween = 5;
    const tableStartY = startY + spaceBetween;

    let columna = columnsWithoutInfo
    console.log(columna, 'lls')
    columna.push(
      {
        field: "domicilio",
        headerName: "Direccion",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Mail",
        flex: 1,
      },
      {
        field: "numero",
        headerName: "Teléfono",
        flex: 1,
      },
    )

    console.log(columna, 'lls')
    doc.autoTable({
      head: [columna.map(column => ({ content: column.headerName, styles: { fillColor: [255, 0, 0] } }))],
      body: data.map(row => columna.map(column => row[column.field])),
      startY: tableStartY,
      styles: {
        fontSize: 8,
      },
    });
    doc.save('CuentasPersonasJuridicas.pdf');
  };


  const verCuentasColumn = {
    field: "Info de Cuentas",
    headerName: "Info de Cuentas",
    type: "actions",
    flex: 1,
    // maxWidth: 360,
    // minWidth: 250,
    getActions: (params) => {
      const actions = [
        <GridActionsCellItem
          icon={
            <Tooltip title="Datos Principales">
              <span>
                <MenuBook color="primary" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            //setRedirect({ ans: false, id: params.id });
            editarCuenta(params);
            let aux = { ...params.row, nodoPadre: esJuridica ? "juridicas" : "fisicas" };
            console.log("esJuridica", esJuridica)
            //console.log('AUXILIAR',params)
            setEditData(aux);
            //  setButtonEnviar(true);
            setModalName("Cuentas");
            setOpenModalCuentasJuridicas(true);
          }}
          label="MenuBook"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Localizaciones y Telefonos">
              <span>
                <FmdGood color="secondary" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            // editarCuenta(params);
            setSelectedForm(<CuentasLocalizacionesList params={params.row} />);
            setModalName("Localizaciones");
            setButtonEnviar(false);
            toggleModal();
          }}
          label="Ubicaciones"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Servicios de Internet">
              <span>
                <AlternateEmail color="success" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            // editarCuenta(params);
            setSelectedForm(<CuentasServiciosInternetList params={params.row} />);
            setModalName("Servicios de Internet");
            setButtonEnviar(false);
            toggleModal();
            //verServiciosInternetCuentas(params);
          }}
          label="Servicios de Internet"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Info Adicional">
              <span>
                <ListAltOutlined color="info" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            editarCuenta(params);
            setSelectedForm(<CuentasInfoAdicionalForm params={params.row} handleClose={handleClose} />);
            setModalName("Info Adicional");
            setButtonEnviar(false);
            toggleModal();

          }}
          label="Parametros"
        />,
        // <GridActionsCellItem
        //   icon={
        //     <Tooltip title="Parametros">
        //       <span>
        //         <InfoOutlined color="primary" />
        //       </span>
        //     </Tooltip>
        //   }
        //   onClick={() => {
        //     // editarCuenta(params);
        //     setSelectedForm(<CuentasParametrosList params={params.row} />);
        //     setModalName("Parametros");
        //     setButtonEnviar(false);
        //     toggleModal();
        //     //verServiciosInternetCuentas(params);
        //   }}
        //   label="Info"
        // />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Contactos">
              <span>
                <ContactsIcon color="primary" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            // editarCuenta(params);
            setSelectedForm(<CuentasContactosList params={params.row} />);
            setModalName("Contactos");
            setButtonEnviar(false);
            toggleModal();
            //verServiciosInternetCuentas(params);
          }}
          label="Info"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Cuentas Bancarias">
              <span>
                <AccountBalanceIcon color="primary" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            // editarCuenta(params);
            setSelectedForm(<CuentasBancariasList params={params.row} />);
            setModalName("Cuentas Bancarias");
            setButtonEnviar(false);
            toggleModal();
            //verServiciosInternetCuentas(params);
          }}
          label="Info"
        />,
      ];

      // Verificar si el id del proyecto es 29 para agregar la acción de precio
      if (parametros_proyecto?.id === "27") {
        actions.splice(1, 0,
          <GridActionsCellItem
            icon={
              <Tooltip title="Parametros">
                <span>
                  <InfoOutlined color="primary" />
                </span>
              </Tooltip>
            }
            onClick={() => {
              // editarCuenta(params);
              setSelectedForm(<CuentasParametrosList params={params.row} />);
              setModalName("Parametros");
              setButtonEnviar(false);
              toggleModal();
              //verServiciosInternetCuentas(params);
            }}
            label="Info"
          />
        );
      }

      return actions;
    }
  };

  /* se actualiza el limite de la tabla en funcion de si se trae un limite para haver el fetch */
  useEffect(() => {
    setLimit(data.options.limit || 10);
  }, [data]);

  useEffect(() => {
    if (closeModals) {
      setOpenModal(false);
      setCloseModals(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModals]);


  /* funcion que trae los records a mostrar en la tabla. Se ejecuta cada vez que se cambia la pagina o se 
    modifica la cantidad de rows a mostart */

  useEffect(() => {
    if (!loading) {
      const getDataListTableCuentas = async () => {
        if (data?.options?.getPaginated) {

        }
        setLoading(true);
        const response = await data.options.getPaginated(limit, offset, criteriosSearchCuentasJuridicas);

        if (response) {
          setRecords(response.data); // EL RESPONSE SE DESARMA EN EL CONTROLADOR DEL MODELO PARA TRAER POR SEPARADO LOS DATOS Y LA CANTIDAD DE RESULTADOS
          let cols_aux = [...data.options.cols];
          cols_aux.push(verCuentasColumn);
          //cols_aux.push(actionColumn)
          setColumns(cols_aux);
          setTotalRowCount(parseInt(response.total_records)); // SE ASIGNA LA CANTIDAD TOTAL DE RESULTADOS QUE EXISTEN EN LA DB CON RESPECTO A ESE FETCH
        }

        setLoading(false);
      };

      !data.options?.res && getDataListTableCuentas(); // SOLO SE REALIZA EL FETCH SI SE USA LA PAGINACION, POR LO QUE SE DEBE ASIGNAR LA FUNCION DEL CONTROLADOR
    }
    // SI SE QUIERE USAR SIN PAGINACION, SE PUEDE PASAR DIRECTAMENTE EL ARREGLO A MOSTRAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updater, limit, lastPage, pageLimit, criteriosSearchCuentasJuridicas]);

  useEffect(() => {
    setUpdater(Date.now());
  }, [page, pageSize]);

  useEffect(() => {
    if (savedData) {
      setUpdater(Date.now());
      setSavedData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData]);

  /* ESTADO DEL CONTEO DE PAGINAS */
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);

  /* actualiza el estado de la pagina y donde se encuentra el paginador */

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [totalRowCount, setRowCountState]);

  //Se prepara el campo de redireccion, para la edicion del item


  const handleFirstPageClick = () => {
    handlePageChange(1);
  };

  // Función para manejar el cambio de página
  const handlePageChange = (newPage) => {
    // setPageChangeButton(true);
    setPage(newPage);

    if (newPage !== totalPages) {
      if (lastPage) {
        setLimit(pageLimit);
        setOffset(pageLimit * (newPage - 1));
        setLastPage(false);
      } else {
        limit !== data.options.limit && setLimit(limit);
        setOffset(limit * (newPage - 1));
      }
    } else {
      setpageLimit(limit);
      setLastPage(true);
      setLimit(limit);
      setOffset(limit * (newPage - 1));
    }
  };

  const handleChangeLimit = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setpageLimit(newLimit);
    setLimit(newLimit);
  };


  const rowsPerPageOptions = [20, 50, 100, 150, 200];

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);

  const sendData = async () => {

    const response = await saveData();

    if (!response) {
      setSnackbarVisible(true);
      setOpenModal(true);
    } else {
      // Aquí puedes manejar el éxito, si es necesario
      setSnackbarVisible(true);
      setOpenModal(false);

      setUpdateLocalizaciones(false);
      setUpdateServiciosInternet(false);
      setUpdateParametros(false);
      setOpenModal(false);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>

        <Grid>
          {viewCustomToolbar !== "false" && (<><GridToolbarColumnsButton
            style={{
              color: '#FFFFFF',
              fontFamily: 'Roboto, sans-serif',
              background: "#0A395F",
              margin: '4px',
              minWidth: '120px',
              width: '120px',
              fontSize: '11px',
              height: '30px',
            }}
          />
            <GridToolbarFilterButton
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                background: "#0A395F",
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            />
            <GridToolbarDensitySelector
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                background: "#0A395F",
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            />
            <Button
              startIcon={<PrintIcon />}
              disabled={records.length > 0 ? false : true}
              onClick={handlePrintClick}
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                margin: '4px',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
                background: records.length > 0 ? "#0A395F" : 'gray',
              }}
            >
              Imprimir
            </Button>
          </>)}

        </Grid>
        <Grid>

          {buttonExtraOn && (<ButtonExtra></ButtonExtra>)}

        </Grid>

      </GridToolbarContainer>
    );
  }
  const handleFilterModelChange = (newFilterModel) => {
    // Aquí puedes interceptar los datos filtrados
    // y hacer cualquier manipulación adicional que necesites
    // antes de actualizar el estado
    console.log('Datos filtrados:', newFilterModel);

    setFilteredData(newFilterModel);
    // setFilteredData(newFilterModel);
  };

  return (
    <Container maxWidth="xxl" style={{ padding: "20px 0px 0px 0px" }}>
      <Grid container spacing={2}>
        <Grid >
          <h4>{data.options.listName}</h4>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            onFilterModelChange={handleFilterModelChange}
            autoHeight
            autoPageSize
            localeText={esES.components.MuiDataGrid.defaultProps.localeText} // hace que la cabecera de los filtros este español
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              // las cabeceras de filtrado que van a aparecer
              Toolbar: CustomToolbar,

              Pagination: () => (
                <Grid container alignItems="center" justifyContent="flex-end" style={{ display: 'flex' }}>
                  <Grid item style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>

                    <div style={{ display: 'flex', alignItems: 'center' }}>

                      <InputLabel id="rows-per-page-label">Filas por página: </InputLabel>
                      <FormControl>
                        <Select
                          labelId="rows-per-page-label"
                          id="rows-per-page-select"
                          value={limit}
                          onChange={handleChangeLimit}
                          style={{ height: '30px' }}
                        >
                          {rowsPerPageOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item style={{ marginLeft: isMobileDevice ? 'auto' : 0, marginRight: isMobileDevice ? 'auto' : 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                      <IconButton onClick={handleFirstPageClick} disabled={page === 1} >
                        <FirstPageIcon />
                      </IconButton>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, newPage) => handlePageChange(newPage)}
                        showLastButton
                      />
                    </div>
                  </Grid>
                </Grid>
              ),
            }}
            density="compact"
            sx={{
              '--DataGrid-overlayHeight': '50vh',
              backgroundColor: 'white',
              fontFamily: 'Roboto, sans-serif', // Cambia el tipo de letra
              borderRadius: 3, // Elimina los bordes redondeados del DataGridPro
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", /* Agrega sombra */
              height: '50vh',
              "& .MuiDataGrid-cell": {
                fontSize: 13,
                fontFamily: 'Roboto, sans-serif',
              }, '.MuiDataGrid-columnSeparator': {
                display: 'none',
              }

              , '& .MuiDataGrid-overlay': {
                height: '800px !important',
              },
            }}
            rows={records} // filas de la tabla OBTENIDAS EN EL FETCH
            columns={columns} // columnas que se van a mostrar || DEBEN CORRESPONDERSE CON LAS QUE TRAE EL FECTH EN EL RECORD
            rowCount={rowCountState}
            loading={loading}
            rowsPerPageOptions={[50, 100, 150, 200]}
            pageSize={pageSize}
            paginationMode="server" // PAGINACION VOLCADA AL SERVIDOR
            {...data.options.props}
          />
        </Grid>
      </Grid>
      <SimpleDialogComponent
        open={openModal}
        modalName={modalName}
        child={selectedForm}
        maxWidth={"lg"}
        handleClose={() => {
          if (updateLocalizaciones || updateServiciosInternet || updateParametros) {
            Swal.fire({
              title: 'Atencion!!!',
              text: "Si sale ahora ,los registros que se agregaron,editaron o eliminaron no impactara en la base de datos.",
              icon: 'warning',
              customClass: {
                container: 'my-swal-delete'
              },
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Guardar y Cerrar',
              cancelButtonText: 'Cerrar Sin Guardar'
            }).then((result) => {
              if (result.isConfirmed) {
                sendData();

              } else {
                setUpdateLocalizaciones(false);
                setUpdateServiciosInternet(false);
                setUpdateParametros(false);
                setOpenModal(false);
              }
            })
          } else {
            setOpenModal(false);
          }

        }}
        buttonEnviar={buttonEnviar}
        setPushData={sendData}
        buttonCancelar={true}
      />

      <SimpleDialogComponent
        open={openModalCuentasJuridicas}
        modalName={modalName}
        child={
          <CuentasJuridicasForm
            handleClose={() => {
              setOpenModalCuentasJuridicas(false);
            }}
          />
        }
        handleClose={() => {
          setOpenModalCuentasJuridicas(false);
        }}
        buttonEnviar={buttonEnviar}
        setPushData={sendData}
        buttonCancelar={true}
      />

      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </Container>
  );
};

export const endpoints = {
    urls: {
        get: `/get/getData`,
        delete: `/delete/deleteData`,
        insert: `/insert/insertData`,
        login: `/site/login`,
        create: `/contenidos/create`,
        search: `/contenidos/search`,
        update: `/contenidos/update`,
        ubicaciones: `/ubicaciones/ubicacionesCreate`,
        cajasUbicacionesSet: `/ubicaciones/cajasUbicacionesSet`,
        getCajasDisponibles: `/cajas/getDisponibles`,
        getCuentaGenerica: `/cuentas/getCuentaGenerica`,
        createImagesMultiple: `/images/createMultiple`,
        createImages: `/images/create`,
        contenidos: `/contenidos/search`,
        contenidosSearch: `/contenidos/search`,
        cuentasCreate: `/cuentas/cuentasCreate`,
        cuentasUpdate: `/cuentas/cuentasUpdate`,
        contenidosMovement: `/contenidos/movement`,
        impresionEtiquetas: `/etiquetas/create?`,
        articulosGet: `/articulos/articulosGet`,
        articulosCreate: `/articulos/articulosCreate`,
        articulosUpdate: `/articulos/articulosUpdate`,
        articulosDelete: `/articulos/articulosDelete`,
        contenidosUpdate: `/contenidos/update`,
        contenidosCreate: `/contenidos/create`,
        spUpdate: `/sp/update`,
        getMovimientos: `/get/getTipoMovimientos`,
        dboxDashboard: `/get/dboxDashboard`,
        getLoadData: `/get/getLoadData`,
        setComprobantesDigitalizaciones: `/comprobantes/digitalizaciones`,
        getImages: `/images/get`,
        getComprobantes: `/comprobantes/get`,
        comprobantesPrint: `/comprobantes/print`,
        getNewPassword: `/usuarios/getNewPassword`,
        getTableStructure: `/site/getTableStructure`,
        comprobantesCreate: `/comprobantes/create`,
        comprobantesDelete: `/comprobantes/delete`,
        produccionGet: `/produccion/produccionGet`,
    }
}
import { encode } from "base-64";
import { useContext, useState, useEffect } from "react";
import { config } from "src/configs/config";
import { AuthContext } from "src/context/AuthContext";
import { types } from "src/context/types";
import Swal from "sweetalert2";
import { loginFetch, getParams } from "../general_helpers/loginFetch";
import Box from "@mui/material/Box";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyIcon from "@mui/icons-material/Key";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";
import Video_Login from "src/assets/video/D-Box.mp4"; // url del video personalizable
import FilledInput from '@mui/material/OutlinedInput';
import { Login as LoginIcon } from "@mui/icons-material";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponentRC";
import { RecuperarContrasenia } from "src/general_components/RecuperarContrasenia";
import { parametros_proyecto, id_proyecto } from "src/configs/config";
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { ConfirmationModal } from './ConfirmationModal';
import { useTheme } from '@mui/material/styles';
import Cookies from 'js-cookie';


let credencialesNew = require("../configs/credentials.json");


export const Login = () => {
  const { dispatch } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [modalState, setmodalState] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [idTokenCognito, setIdtoken] = useState('');
  const theme = useTheme();
  const background = theme.palette.background.login;
  const backgroundInputs = theme.palette.mode === 'dark' ? 'black' : 'white';
  const colorInputs = theme.palette.mode === 'dark' ? 'white' : 'black';
  // eslint-disable-next-line no-unused-vars
  const [usuarioFaltaAuth, setUsuarioFaltaAuth] = useState('');
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);

  const [encriptacion, setEncriptacion] = useState(true);
  const [cognitoUse, setCognitoUse] = useState(false);


  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleCloseDialogNewPassword = () => {
    setIsPasswordChangeModalOpen(false);
  };


  // Función para buscar los parametros del proyecto
  function buscarPorIdProyecto(id) {
    return parametros_proyecto?.find(objeto => objeto.id === id);
  }
  const proyecto_encontrado = buscarPorIdProyecto(id_proyecto);

  const imgUrl = proyecto_encontrado?.url_imagen;
  const usarVideo = proyecto_encontrado?.usar_video_login;
  const usarImagen = proyecto_encontrado?.usar_imagen_login;
  const aws_id_cliente_aplicacion = proyecto_encontrado?.aws_id_cliente_aplicacion;
  const aws_id_grupo_user = proyecto_encontrado?.aws_id_grupo_user;
  const aws_url_clave_firma = proyecto_encontrado?.aws_url_clave_firma;

  localStorage.setItem(
    "parametros_proyecto",
    JSON.stringify(proyecto_encontrado)
  );

  // ---------------------------------------------

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Datos del Form
  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    cliente: "",
  });

  //NOTE FUNCION PARA CAMBIAR EL VALOR DEL LOCALSTORAGE DEL CLIENTE SELECCIONADO
  if (localStorage.getItem("clientData") !== null) {
    localStorage.setItem(
      "clientData",
      JSON.stringify({
        owner_code: userInfo.cliente.owner_code,
        api_pass: userInfo.cliente.api_pass,
        dominio: userInfo.cliente.dominio,
        owner_descripcion: userInfo.cliente.descripcion
      })
    );
  }

  const paramsGet = async (auth) => {

    function minutosAMilisegundos(minutos) {
      return minutos * 60000; // 1 minuto = 60 segundos = 60000 milisegundos
    }

    const response = await getParams();
    for (var i = 0; i < response.length; i++) {
      if (response[i].alias === "SESSION_TIME") {
        localStorage.setItem(
          "SESSION_TIME",
          JSON.stringify({
            time: minutosAMilisegundos(response[i].value),

          })
        )
      }
    }
    // eslint-disable-next-line no-unused-vars
    let data = await formatImagesParams(response)

  };



  const formatImagesParams = async (data) => {
    const parametrosImages = data.filter(objeto => objeto.group_field === 'Images');

    let parametrosImagenesFormateadas = new Map();

    for (const objeto of parametrosImages) {
      parametrosImagenesFormateadas.set(objeto.alias, objeto);
    }
    const objetoFormateado = Object.fromEntries(parametrosImagenesFormateadas);

    // Guardar en localStorage
    localStorage.setItem("siam-params-images", JSON.stringify(objetoFormateado));

  }

  const setHeader = async (e) => {
    //NOTE SETEO LOS VALORES DE OWNER CODE Y API PASS SELECCIONADOS EN UN LOCALSTORAGE PARA QUE EL HEADER PUEDA ACCEDER A ELLOS
    localStorage.setItem(
      "clientData",
      JSON.stringify({
        owner_code: userInfo.cliente.owner_code,
        api_pass: userInfo.cliente.api_pass,
        dominio: userInfo.cliente.dominio,
        owner_descripcion: userInfo.cliente.descripcion
      })
    );

    //NOTE SETEO LOS VALORES DE OWNER CODE SELECCIONADO EN EL CONFIG PARA QUE EL BODY PUEDA ACCEDER A ELLOS
    config.credentials.authorization.owner_code = userInfo.cliente.owner_code;
  }


  const handleConfirmationCodeEmail = (code) => {

    const poolData = {
      // UserPoolId: 'us-east-1_IicsmhSD0',
      // ClientId: '58lb2ugcj8ukmk2hl7q9sh4rbq'
      UserPoolId: aws_id_grupo_user,
      ClientId: aws_id_cliente_aplicacion
    };

    const userPool = new CognitoUserPool(poolData);

    const userData = {
      Username: userInfo.username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        console.error(err);

        setSeverity('error');
        setMessage('Error al validar el Usuario');
        setSnackbarVisible(true);
      } else {
        console.log('Usuario valido', result);
        handleCloseDialog()
        handleLogin()
      }
    });
  }

  const handleSolicitNewCode = () => {
    const poolData = {
      // UserPoolId: 'us-east-1_IicsmhSD0',
      // ClientId: '58lb2ugcj8ukmk2hl7q9sh4rbq'
      UserPoolId: aws_id_grupo_user,
      ClientId: aws_id_cliente_aplicacion
    };

    const userPool = new CognitoUserPool(poolData);

    // Crea una instancia de CognitoUser usando el nombre de usuario
    const username = userInfo.username;
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.error(err);
        setSeverity('error');
        setMessage('Error al enviar el codigo de confirmacion');
        setSnackbarVisible(true);
      } else {
        setSeverity('success');
        setMessage('Nuevo código de confirmación enviado correctamente a  ' + result.CodeDeliveryDetails.Destination);
        setSnackbarVisible(true);
      }
    });

  }

  ///Habilita o no el enciptado de datos
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.shiftKey && event.key === ' ') {
        setEncriptacion((prevEncriptacion) => {
          if (prevEncriptacion) {
            console.log(encriptacion, 'encriptacion')
            localStorage.setItem("encriptacion", 'false');
            setSeverity('warning');
            setMessage('Encriptado Deshabilitado');
            setSnackbarVisible(true);
            // encriptacion = false
            setEncriptacion(false)
          } else {

            console.log(encriptacion, '11')
            localStorage.setItem("encriptacion", 'true');
            setSeverity('success');
            setMessage('Encriptado Habilitado');
            setSnackbarVisible(true);
            // encriptacion = true
            setEncriptacion(true)
          }
          return !prevEncriptacion;
        })

      }

      if (event.shiftKey && event.key === 'C') {
        setCognitoUse((prevCognitoUse) => {
          if (prevCognitoUse) {
            localStorage.setItem("cognitoUse", false);
            setSeverity('warning');
            setMessage('Uso de Cognito Deshabilitado');
            setSnackbarVisible(true);
          } else {
            localStorage.setItem("cognitoUse", true);
            setSeverity('success');
            setMessage('Uso de Cognito Habilitado');
            setSnackbarVisible(true);
          }
          return !prevCognitoUse;
        });

        console.log(cognitoUse, 'cognitoUse')
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    localStorage.setItem("encriptacion", 'true');
    localStorage.setItem("cognitoUse", false);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);


  const handleNewPasswordSubmit = (newPassword) => {
    const pass = btoa(newPassword)
    cognitoUser.completeNewPasswordChallenge(pass, {
      profile: '1',
    }, {
      onSuccess: (session) => {
        console.log('Contraseña cambiada con éxito', session);
        setIsPasswordChangeModalOpen(false);
        // Vuelve a intentar el inicio de sesión con la nueva contraseña
        handleLogin({ preventDefault: () => { } });
      },
      onFailure: (err) => {
        console.error('Error al completar el desafío NEW_PASSWORD_REQUIRED', err);
        setSeverity('warning');
        setMessage('Error al cambiar la contraseña. Por favor, intenta nuevamente.');
        setSnackbarVisible(true);
      }
    });
  };
  // TODO:LOGIN USANDO COGNITO
  // eslint-disable-next-line
  const handleLogin = async (event) => {

    console.log(cognitoUse, 'cognitoUse')
    if (cognitoUse) {
      const poolData = {
        // UserPoolId: 'us-east-1_IicsmhSD0',
        // ClientId: '58lb2ugcj8ukmk2hl7q9sh4rbq'
        UserPoolId: aws_id_grupo_user,
        ClientId: aws_id_cliente_aplicacion
      };

      const userPool = new CognitoUserPool(poolData);

      const authenticationData = {
        Username: userInfo.username,
        Password: encode(userInfo.password),
      };

      const authenticationDetails = new AuthenticationDetails(authenticationData);

      const userData = {
        Username: userInfo.username,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (session) => {

          const authTokenCognito = await autenticacionTokenCognito(session.idToken.jwtToken)

          if (authTokenCognito) {
            loginSiam()
            setIdtoken(session.idToken.jwtToken)

            localStorage.setItem("cognitoToken", encode(session.idToken.jwtToken));
            localStorage.setItem("cognitoAccessToken", encode(session.accessToken.jwtToken));
          } else {
            setSeverity('warning');
            setMessage('Error al autenticar token cognito');
            setSnackbarVisible(true);
          }

          //  await setUserInfo(prevUserInfo => ({ ...prevUserInfo, token: 'session.idToken.jwtToken' }));
        },
        onFailure: (err) => {

          if (err.code === 'UserNotConfirmedException') {
            setSeverity('warning');
            setMessage('El usuario debe confirmar su mail');
            setSnackbarVisible(true);
            setUsuarioFaltaAuth(true)
            handleOpenDialog()
          } else if (err.message && err.message.includes('newPasswordRequired')) {
            // Manejo del desafío NEW_PASSWORD_REQUIRED Mate1725+
            setCognitoUser(cognitoUser);
            // setChallengeParameters(err.challengeParameters);
            setIsPasswordChangeModalOpen(true);
          } else {
            setSeverity('warning');
            setMessage('El usuario o la contrasenia es incorrecta');
            setSnackbarVisible(true);
          }
        },

      });
    } else {
      loginSiam()
    }

  };


  //LAMBDA AWS ENCARGADA DE LA AUTENTICACION DE TOKEN DE COGNITO

  const autenticacionTokenCognito = async (idTokenCognito) => {
    try {
      const response = await fetch('https://k52hkzhw40.execute-api.us-east-1.amazonaws.com/TEST/auth_lambda', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${idTokenCognito}`,
        },
        body: JSON.stringify({
          audience: aws_id_cliente_aplicacion,
          issuer: aws_url_clave_firma,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error al obtener datos: ${response.status}`);
      }

      // const responseData = await response.json();
      return true
      // setData(responseData);
    } catch (error) {
      console.error(error.message);
      return false
    }
  };


  const loginSiam = async () => {

    if (
      userInfo.username.length <= 0 ||
      userInfo.password.length <= 0 ||
      userInfo.cliente === ""
    ) {
      setSeverity('warning');
      setMessage('Tiene que completar todos los campos');
      setSnackbarVisible(true);
      return;
    }

    let response = { status: 'false', cancel: true }

    //NOTE SETEO LOS VALORES DE OWNER CODE Y API PASS SELECCIONADOS EN UN LOCALSTORAGE PARA QUE EL HEADER PUEDA ACCEDER A ELLOS
    localStorage.setItem(
      "clientData",
      JSON.stringify({
        owner_code: userInfo.cliente.owner_code,
        api_pass: userInfo.cliente.api_pass,
        dominio: userInfo.cliente.dominio,
        owner_descripcion: userInfo.cliente.descripcion
      })
    );

    // console.log(idTokenCognito, '333333333')
    //NOTE SETEO LOS VALORES DE OWNER CODE SELECCIONADO EN EL CONFIG PARA QUE EL BODY PUEDA ACCEDER A ELLOS
    config.credentials.authorization.owner_code = userInfo.cliente.owner_code;

    if (userInfo.username.length < 1) {
      Swal.fire({
        icon: "warning",
        title: "¡Atención!",
        text: "El campo usuario no puede estar vacio",
      });
    } else if (userInfo.password.length < 1 || /\s/.test(userInfo.password)) {
      var text = "El campo contraseña no puede estar vacio.";
      if (/\s/.test(userInfo.password)) {
        text = 'El campo contraseña no puede contener espacios en blanco.';
      }
      Swal.fire({
        icon: "warning",
        title: "¡Atención!",
        text: text,
      });
    } else if (userInfo.cliente === "") {
      Swal.fire({
        icon: "warning",
        title: "¡Atención!",
        text: "Debe seleccionar un cliente",
      });
    } else {
      //NOTE LLAMO EL FETCH PARA LOGUEARME
      await paramsGet();

      response = await loginFetch(userInfo);

    }

    if (response.status === "true") {
      sessionStorage.setItem("sessionLogin", true);
      dispatch({
        type: types.login,
        payload: {
          userInfo: userInfo,
          username: userInfo.username,
          token: encode(`${userInfo.username}:${userInfo.password}`),
        },
      });
      config.authToken = encode(
        `${userInfo.cliente.owner_code}:${userInfo.cliente.api_pass}`
      );
      localStorage.setItem(
        "authToken",
        encode(`${userInfo.cliente.owner_code}:${userInfo.cliente.api_pass}`)
      );

      localStorage.setItem(
        "passUser", userInfo.password
      );
      // Después de iniciar sesión, establece una cookie de sesión
      Cookies.set('session', 'loggedIn');

    } else {
      if (!response.cancel) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Algo salio mal - " + response.message,
        });
      }
    }
  };

  //NOTE SIRVE PARA QE SE ACTIVE LA FUNCION DE MOSTRAR CONTRASEÑA
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const toggleModal = () => {
    if (
      userInfo.cliente === ""
    ) {
      setSeverity('warning');
      setMessage('Tiene que seleccionar un cliente');
      setSnackbarVisible(true);
      return;
    }
    setmodalState(!modalState);
  };

  const dialogChild = (
    <RecuperarContrasenia
      setHeader={setHeader}
      // setButtonClicked={setButtonClicked}
      toggleModal={toggleModal}
      // setUserSelect={setUserSelect}
      setSnackbarVisible={setSnackbarVisible}
      setMessage={setMessage}
      setSeverity={setSeverity}
    />)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // Ajusta el alto para que toda la imagen sea visible
        backgroundImage: theme.backgroundImage.default, // Aquí utilizamos la imagen importada
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
      <SimpleDialogComponent
        open={modalState}
        modalName={"Recuperar contraseña"}
        child={dialogChild}
        handleClose={toggleModal}
        buttonEnviar={false}
        buttonCancelar={false}
        maxWidth="sm"
      />
      <Container maxWidth="lg">
        <Box

        >
          <Grid container>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              component={Paper}
              elevation={6}
              square
              sx={{
                backgroundColor: '#FFFFFF',
                borderTopLeftRadius: '12px',
                borderBottomLeftRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative', // Añadido para posicionar correctamente la imagen encima del video
              }}
            >
              {usarVideo ? (
                <video autoPlay loop muted style={{ width: "100%", height: "100%" }}>
                  <source src={Video_Login} type="video/mp4" />
                  Tu navegador no admite el elemento de video.
                </video>
              ) : null}

              {usarImagen ? (
                <img
                  src={imgUrl}
                  alt="Descripción de la imagen"
                  style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                />
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
              sx={{
                background: background,
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid
                  style={{

                    padding: "30px",
                    margin: "auto",
                    display: "grid",
                    justifyItems: "center",
                  }}
                >
                  <Typography variant="h2" gutterBottom style={{ color: 'white' }}>
                    Login
                  </Typography>

                  {/* CAMPO CLIENTE */}
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <PersonSearchIcon sx={{ fontSize: 40, color: 'white', mr: 1, my: 0.5 }} />
                    <FormControl variant="filled"
                      sx={{ width: "40ch" }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        Cliente
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={userInfo.cliente}
                        onChange={(e) =>
                          setUserInfo({ ...userInfo, cliente: e.target.value })
                        }
                        label="Cliente"
                        variant="filled"

                        style={{ color: colorInputs, backgroundColor: backgroundInputs }}
                      >
                        {credencialesNew && credencialesNew.length > 0 ? (
                          credencialesNew.map((owner) => (
                            <MenuItem
                              key={owner.owner_code}
                              value={owner}
                              style={{ display: "flex", textAlign: "left" }}
                            >
                              {owner.descripcion}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem key={0} value={0} style={{ display: "flex" }}>
                            {"No hay depositos disponibles"}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  <form autoComplete="off">
                    {/* CAMPO USUARIO */}
                    <Grid sx={{ display: "flex", alignItems: "flex-end", backgroundColor: 'transparent' }}>
                      <AccountCircle sx={{ fontSize: 40, color: 'white', mr: 1, my: 0.5 }} />
                      <FormControl sx={{ width: '40ch', marginTop: '2%' }} variant="filled">
                        <InputLabel htmlFor="filled-adornment-username" sx={{ marginTop: userInfo.username !== "" ? '-5px' : 'auto' }}>Usuario</InputLabel>
                        <FilledInput
                          id="filled-adornment-username"
                          type="text"
                          autoComplete="off"
                          onChange={(e) => setUserInfo({ ...userInfo, username: e.target.value })}
                          style={{
                            color: colorInputs,
                            backgroundColor: backgroundInputs,
                            fontFamily: 'Helvetica Neue, sans-serif',
                            fontSize: '16px',
                          }}
                        />
                      </FormControl>
                    </Grid>

                    {/* CAMPO PASSWORD */}
                    <Grid sx={{ display: "flex", alignItems: "flex-end", backgroundColor: 'transparent' }}>
                      <KeyIcon sx={{ fontSize: 40, color: 'white', mr: 1, my: 0.5 }} />
                      <FormControl sx={{ width: '40ch', marginTop: '2%' }} variant="filled">
                        <InputLabel htmlFor="filled-adornment-password" sx={{ marginTop: userInfo.password !== "" ? '-5px' : 'auto' }}>Password</InputLabel>
                        <FilledInput
                          id="filled-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          autoComplete="new-password" // Use new-password for password fields
                          onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
                          onKeyDown={(e) => e.key === "Enter" && handleLogin(e)}
                          style={{ color: colorInputs, backgroundColor: backgroundInputs }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                  </form>
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ paddingTop: "30px" }}
                  >
                    <Button
                      size="small"
                      style={{ textAlign: "left", color: 'white', }}
                      onClick={toggleModal}>

                      Olvidaste tu contraseña?
                    </Button>
                    <Button
                      size="large"
                      variant="outlined"
                      sx={{
                        color: 'white',
                        border: '2px solid white',
                        '&:hover': {
                          border: '2px solid black',
                          background: 'white',
                          color: 'black',
                        },
                      }}
                      onClick={handleLogin}
                      endIcon={<LoginIcon />}
                    >
                      Iniciar
                    </Button>
                  </Grid>

                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <ConfirmationModal
        modalName="Confirmar Email"
        open={isDialogOpen}
        isMobileDevice={false} // Ajusta según tus necesidades
        setPushData={handleConfirmationCodeEmail} // Función que manejará la acción de guardar
        setSolicitNewCode={handleSolicitNewCode}
        handleClose={handleCloseDialog}
        buttonEnviar={true} // Puedes ajustar según tus necesidades
        fullWidth={true} // Puedes ajustar según tus necesidades
        buttonNuevoCodigo={true}
        maxWidth="md" // Puedes ajustar según tus necesidades
      />

      <ConfirmationModal
        modalName="Cambio de contraseña por primer inicio"
        open={isPasswordChangeModalOpen}
        isMobileDevice={false} // Ajusta según tus necesidades
        setPushData={handleNewPasswordSubmit} // Función que manejará la acción de guardar
        // setSolicitNewCode={handleSolicitNewCode}
        handleClose={handleCloseDialogNewPassword}
        buttonEnviar={true} // Puedes ajustar según tus necesidades
        fullWidth={true} // Puedes ajustar según tus necesidades
        buttonNuevoCodigo={false}
        maxWidth="md" // Puedes ajustar según tus necesidades
      />

      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={8000}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </div >
  );
};

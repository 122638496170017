import datos from "src/services/datos";
import Swal from "sweetalert2";
import { esArray } from "src/general_components/functionEsArray";
import "src/services/styles/sweetAlert.css";

export const getMetadatos = async () => {
  const objectMetadatos = new datos("metadatos");
  objectMetadatos.addCondition("metadatos","id",0,">");
  const response = await objectMetadatos.get();
  console.log("response metadatos",response)
  if(response.status === "true"){
    return esArray(response.wsResponse.metadatos)
  }else{
    return [];
  }
  
};

export const getMetadatosWhereIdTipoContenidos = async (data) => {
  const objectMetadatos = new datos("metadatos");
  objectMetadatos.addCondition("metadatos", "id_tipo_contenidos", data, "=");
  const response = await objectMetadatos.get();
  const mdatos = esArray(response.wsResponse.metadatos);

  
  return mdatos;
};

export const getMetadatosWhereIdTipoContenidosPaginated = async (
  limit,
  offset,
  idTipoContenido
) => {
  const objectMetadatos = new datos("metadatos");
  objectMetadatos.addCondition(
    "metadatos",
    "id_tipo_contenidos",
    idTipoContenido,
    "="
  );
  objectMetadatos.addLimitCondition(limit, offset);
  const response = await objectMetadatos.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    let dataResponse = esArray(response.wsResponse.metadatos)
    result = {
      data: dataResponse,
      total_records: response.wsResponse.total_results,
    };
    return result;
  }else{
    result = {
      data: [],
      total_records: 0,
    };
  }
  return result;
};

export const postMetadatos = async (estado) => {
  const objectMetadatos = new datos("metadatos");
  const response = await objectMetadatos.insert(estado);
  return response;
};

export const deleteMetadatos = async (id) => {
  let status = false;
  await Swal.fire({
    title: "Esta seguro de que desea eliminar este metadato?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      container: 'my-swal-delete'
    },
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos("metadatos");
      let data = {
        id: id,
      };
      const response = await userData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire({
          title: "Eliminado!",
          text: "El metadatos ha sido eliminado exitosamente",
          icon: "success",
          customClass: {
            container: 'my-swal-delete'
          },

      });
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  });

  return status;
};

import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { getMenuModules } from "../general_helpers/getMenuModules";
import routes from "src/routes";
import { config } from "src/configs/config";

/**
 * Construye el menu en base
 * a los permisos y rutas definidos
 * @returns {Array} menuArr
 */
const navigation = () => {
    if (!getMenuModules()) {
        return [
            {
                _tag: "NavItem",
                name: config.appName,
                to: "/dashboard",
                icon: (
                    <MenuIcon
                        name="cil-speedometer"
                        customClasses="c-sidebar-nav-icon"
                    />
                ),
                badge: {
                    color: "info",
                },
            },
        ];
    }

    const menuArr = [];
    const modulesArr = [];

    // Busco y almaceno los modulos como cabecera del menu
    getMenuModules().forEach((module) => {
        if (!modulesArr.includes(module.modulo)) {
            modulesArr.push(module.modulo)
        }
    })

    // Recorro modulos y agrego submenu
    modulesArr.forEach((moduleParent) => {
        //Busco submenu y si existe agrego
        const mainMenu = getMainMenu(moduleParent)
        if (mainMenu.length > 0) {
            menuArr.push({ _tag: "NavDropdown", name: moduleParent, _children: mainMenu, badge: { color: "info" } })
        }
    })

    return menuArr.sort((a, b) => (a.level > b.level) ? 1 : ((b.level > a.level) ? -1 : 0))
}

/**
 * Busca los menu hijos de un modulo
 * @param {String} moduleParent 
 * @returns array moduleArr
 */
const getMainMenu = (moduleParent) => {
    let menuArr = []
    getMenuModules().forEach((module) => {
        const level = module.level.split(".")
        if (level[2] === "00" && moduleParent === module.modulo) {
            const childsLevel = getChilds(level, module.modulo);
            if (childsLevel.length > 0) {
                menuArr.push({ _tag: "CSidebarNavDropdown", name: module.name, _children: childsLevel, badge: { color: "info" } })
            }
        }
    })
    return menuArr
}

/**
 * 
 * @param {String} levelPadre 
 * @param {String} modelPadre 
 * @returns array subMenu
 */
const getChilds = (levelPadre, modelPadre) => {
    const subMenu = [];
    getMenuModules().forEach((module) => {
        const levelHijo = module.level.split(".")
        
        if ((levelPadre[1] === levelHijo[1]) && (JSON.stringify(levelPadre) !== JSON.stringify(levelHijo)) && (module.modulo === modelPadre)) {
            if (getAccess(module.level, module.accion)) {
                subMenu.push({ _tag: "CSidebarNavItem", name: module.name, level: module.level, to: getAccion(module.level), overlaid: "true" })
            }
            
        }
    })
    return subMenu
}

/**
 * 
 * @param {String} level 
 * @param {String} urlModulo 
 * @returns boolean access
 */
const getAccess = (level, urlModulo) => {
    let access = false
    for (let index = 0; index < routes.length; index++) {
        if ((routes[index].level === level) && (urlModulo === routes[index].path) && routes[index].component !== false) {
            access = true;
            break;
        }
    }
    return access;
}

/**
 * Obtiene el action de un menu
 * @param {String} level 
 * @returns String
 */
const getAccion = (level) => {
    for (let index = 0; index < routes.length; index++) {
        if ((routes[index].level === level)) {
            return routes[index].path;
        }
    }
}
export default navigation
import datos from "src/services/datos";
import Swal from "sweetalert2";

const objectTipoServiciosInternet = new datos("tipo_servicios_internet");

export const getTipoServiciosInternet = async () => {
  objectTipoServiciosInternet.addCondition(
    "tipo_servicios_internet",
    "id",
    0,
    ">"
  );

  const response = await objectTipoServiciosInternet.get();

  if (response) {
    return response.wsResponse.tipo_servicios_internet;
  }
  return response;
};

export const getTipoServiciosInternetPaginated = async (limit, offset) => {
  objectTipoServiciosInternet.addCondition(
    "tipo_servicios_internet",
    "id",
    0,
    ">"
  );
  objectTipoServiciosInternet.addLimitCondition(limit, offset);
  const response = await objectTipoServiciosInternet.get();
  /* devuelvo un objeto de respuesta generica  CON ELEMENTO DE "TOTAL_RECORDS" PARA USARSE CON PAGINACION*/

  let result = {};
  if (!response.wsResponse.empty) {
    result = {
      data: [...response.wsResponse.tipo_servicios_internet],
      total_records: response.wsResponse.total_records,
    };
    return result;
  }
};

export const postTipoServiciosInternet = async (estado) => {
  if (estado) {
    const data = {
      id: estado.id,
      nombre: estado.detalle,
    };
    const response = await objectTipoServiciosInternet.insert(data);
    if (response) {
      return response.wsResponse.tipo_servicios_internet;
    }
  }
  return false;
};

export const deleteTipoServiciosInternet = async (id) => {
  let status = false;
  await Swal.fire({
    title:
      "Esta seguro de que desea eliminar este Tipo de servicio de internet?",
    text: "Si sigue adelante, no se podran revertir los cambios!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quiero eliminarlo!",
    cancelButtonText: "No, no estoy seguro!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const userData = new datos('tipo_servicios_internet');
      let data = {
        id: id
      }
      const response = await userData.delete(data);

      if (response.status === "true") {
        status = true;
        await Swal.fire(
          "Eliminado!",
          "El Tipo de servicio de internet ha sido eliminado exitosamente",
          "success"
        );
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message
        });
      }
    }
  });

  return status;
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import esArray from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";
import { getTipoCuentas } from "src/projects/gestion_comercial/cuentas/helpers/CuentasServiciosPrecioData";

const SelectorCuentasTipo = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.selectedTipoCuenta;

  const tipo_cuentas = getFromLS("siam-tipo_cuentas").tipo_cuentas;
  const [tipoCuentasSeleccionado, setTipoCuentasSeleccionado] = useState("");

  useState(() => {
    const cuentasTipo = async () => {
      if (tipo_cuentas) {
        setDataDefaults(esArray(tipo_cuentas));
      } else {
        const response = await getTipoCuentas();
        if (response) {
          setDataDefaults(response);
        }
      }
    };
    cuentasTipo();
  }, []);

  useEffect(() => {
    setTipoCuentasSeleccionado(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setTipoCuentasSeleccionado(newValue);
      props.handleChange(props.path, newValue.id); // Obtén el ID del objeto seleccionado
    } else {
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
          value={tipoCuentasSeleccionado ? tipoCuentasSeleccionado : null}
          onChange={handleChange}
          options={dataDefaults || []}
          getOptionLabel={(option) => option.id + " - " + option.detalle || ""}
          renderInput={(params) => (
            <TextField {...params} label="Tipo cuentas" disabled={disabled} />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorCuentasTipoTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorCuentasTipo";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorCuentasTipoComponent = {
  renderer: withJsonFormsControlProps(SelectorCuentasTipo),
  tester: SelectorCuentasTipoTester,
};

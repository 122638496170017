class Getters {
  // eslint-disable-next-line no-useless-constructor
  constructor() { }

  getLocalUserCuentas = () => {
    let id_cuentas = "";
    const user_cuentas = JSON.parse(localStorage.getItem("siam-user-cuentas"));
    if (user_cuentas) {
      user_cuentas.forEach((user_cuenta) => {
        if (
          user_cuenta.usuarios_cuentas &&
          user_cuenta.usuarios_cuentas.id_cuentas
        ) {
          id_cuentas += user_cuenta.usuarios_cuentas.id_cuentas + ",";
        }
      });
    }

    return id_cuentas === "" ? false : id_cuentas.replace(/(^,)|(,$)/g, "");
  };

  getCuentasInUser = async (user_aux) => {
    let user_cuentas;
    try {
      user_cuentas = user_aux.relations.usuarios_cuentas.id_cuentas;
    } catch (e) {
      user_cuentas = false;
    }

    return user_cuentas;
  };
}

export default Getters;


import { requestService } from "../services/requestService";
import { saveData } from './manageData';
import { encode } from "base-64";
import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";


export const loginFetch = async (userInfo, idToken = '') => {
    const { username, password } = userInfo;
    const propiedades = { 'services': 'login', 'structure': { 'user': username, 'pass': encode(password), 'token': idToken } }
    const response = await requestService(propiedades);

    return await saveData(response);
};

export const getNewPassword = async (email) => {
    localStorage.setItem(`estadoPeticionModelos`, 'true');
    const propiedades = { 'services': 'getNewPassword', 'structure': { 'email': email } }
    const response = await requestService(propiedades);

    return response;
};

export const getParams = async (auth) => {
    const params = new datos("params");
    params.addCondition("params", "id", 0, ">");
    const response = await params.get();
    const result = response.wsResponse.params ? esArray(response.wsResponse.params) : [];
    return result;

};

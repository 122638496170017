import React, { useState } from 'react';
import { TextField, Button, Grid, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { getNewPassword } from 'src/general_helpers/loginFetch';
// import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import { parametros_proyecto, id_proyecto } from "src/configs/config";

export const RecuperarContrasenia = (props) => {
  const { setHeader, toggleModal, setSnackbarVisible, setMessage, setSeverity } = props;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const useCognito = localStorage.getItem("cognitoUse");



  // Función para buscar los parametros del proyecto
  function buscarPorIdProyecto(id) {
    return parametros_proyecto?.find(objeto => objeto.id === id);
  }
  const proyecto_encontrado = buscarPorIdProyecto(id_proyecto);

  const aws_id_cliente_aplicacion = proyecto_encontrado?.aws_id_cliente_aplicacion;
  const aws_id_grupo_user = proyecto_encontrado?.aws_id_grupo_user;
  const aws_iam_accessKeyId = proyecto_encontrado?.aws_iam_accessKeyId;
  const aws_iam_secretAccessKey = proyecto_encontrado?.aws_iam_secretAccessKey;

  const poolData = {
    UserPoolId: aws_id_grupo_user,
    ClientId: aws_id_cliente_aplicacion
  };

  const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
    region: 'us-east-1'
  });

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Validación de formato de correo electrónico
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setEmailError(!emailPattern.test(newEmail));
  };

  const handleSubmit = async () => {
    if (emailError || email === '') {
      setEmailError(true);
    } else {
      await setHeader();
      let response = await getNewPassword(email);
      if (response.status === 'true') {

        try {
          if (useCognito) {
            AWS.config.update({
              accessKeyId: aws_iam_accessKeyId,  // Reemplaza con tu Access Key ID
              secretAccessKey: aws_iam_secretAccessKey,  // Reemplaza con tu Secret Access Key
              region: 'us-east-1'
            });
            const params = {
              UserPoolId: poolData.UserPoolId,
              Username: response.wsResponse.user,
              Password: response.wsResponse.pass,
              Permanent: true // La contraseña se establece como permanente
            };

            console.log(params, 'params')
            await cognitoIdentityServiceProvider.adminSetUserPassword(params).promise();
          }
        } catch (error) {
          console.error('Error al cambiar la contraseña por el administrador:', error);
          return { status: 'false', message: error.message };
        }
        setSnackbarVisible(true);
        setSeverity('success');
        setMessage(response.message)
        toggleModal();
      } else {
        setSnackbarVisible(true);
        setSeverity('warning');
        setMessage(response.message)
      }
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography variant="h5" gutterBottom sx={{ fontSize: 16, color: 'grey' }}>
        Ingrese su correo electrónico
      </Typography>
      <TextField
        label="Correo Electrónico"
        variant="outlined"
        fullWidth
        margin="normal"
        value={email}
        onChange={handleEmailChange}
        error={emailError}
        helperText={emailError ? 'Por favor, ingresa un correo válido' : ''}
      />
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Button
          variant="outlined"
          color="success"
          onClick={handleSubmit}
          sx={{
            border: '1px solid',
            '&:hover': {
              border: '1px solid success',
            },
          }}
          endIcon={<SendIcon />}
        >
          Enviar Correo
        </Button>
      </Grid>
    </Grid>
  );
};

export default RecuperarContrasenia;

import { serviceQuest } from "./request";
import Swal from 'sweetalert2'
import { endpoints } from "src/configs/config_endpoints";
import "./styles/sweetAlert.css";
import CryptoJS from 'crypto-js';
import { parametros_proyecto, id_proyecto } from "src/configs/config";

// Función para buscar los parametros del proyecto
function buscarPorIdProyecto(id) {
    return parametros_proyecto?.find(objeto => objeto.id === id);
}
const proyecto_encontrado = buscarPorIdProyecto(id_proyecto);

const key = proyecto_encontrado?.key_encript;


export const requestService = async (props) => {
    const estadoCargaDataLocalStorage = localStorage.getItem(`estadoPeticionModelos`);
    let textoDescriptivo = '';
    if (estadoCargaDataLocalStorage === 'true') {
        textoDescriptivo = "<div class='typing'><h4>Un momento por favor…</h4><h5 id='mensajeExtra'></h5></div>";
    } else {
        textoDescriptivo = "<div class='typing'><h4></h4>Aguarde un momento, estamos cargando los datos del sistema</h4><h5 id='mensajeExtra'></h5></div>";
    }
    // eslint-disable-next-line no-unused-vars
    let timerInterval;
    if (!props.asincrono) {
        Swal.fire({
            html: textoDescriptivo
                + "<div class='center'><div id='loading' class='loading0'></div>"
                + "<div id='loading' class='loading1'></div>"
                + "<div id='loading' class='loading2'></div><div id='loading' class='loading3'></div>"
                + "<div id='loading' class='loading4'></div></div>",
            width: '100%',
            color: '#ffffff',
            background: 'rgba(0, 0, 0, 0)',
            backdrop: 'rgba(137, 137, 137, 0.9)',
            allowOutsideClick: false,
            showConfirmButton: false,
            customClass: {
                container: 'my-swal',
                popup: 'large-sa-popup'
            },
            didOpen: () => {
                const b = Swal.getHtmlContainer().querySelector('h5')
                timerInterval = setInterval(() => {
                    b.textContent = "Algunas consultas pueden tardar mas de lo habitual"
                }, 5000)

            },
        })
    }

    const { services } = props;
    let error = false;

    try {
        const responseHttp = await fetch(
            JSON.parse(localStorage.getItem("clientData")).dominio + endpoints.urls[services],
            serviceQuest(props)
        );

        if (!responseHttp.ok) {
            throw new Error(`Error en la solicitud: ${responseHttp.status}`);
        }

        let encriptacion = localStorage.getItem("encriptacion")
        let requestServ

        if (encriptacion === 'true') {
            // TODO:ESTO SE DEVE APLICAR PARA EL DESENCRIPTAR LOS DATOS
            // Obtener los datos cifrados desde la respuesta
            const encryptedData = await responseHttp.text(); // Obtener como texto
            const decryptedData = decryptData(encryptedData);
            requestServ = JSON.parse(decryptedData);
        } else {
            requestServ = await responseHttp.json();
        }





        // const requestServ = await responseHttp.json();

        if (estadoCargaDataLocalStorage === 'true' && !error) {
            Swal.close();
        }

        if (responseHttp.status === 403) {
            const requestAuthorizationService = await authorizationServices(responseHttp, requestServ);
            return requestAuthorizationService;
        } else {
            return requestServ;
        }

    } catch (error) {
        if (error.message !== "403") {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Hubo un error en el servidor"
            });
        }
    }
}

const authorizationServices = async (responseHttp, responseService) => {
    let response = responseService.wsResponse.replace(/[']/g, '"');
    let wsResponse1 = JSON.parse(response);
    let responseSwal = "";
    await Swal.fire({
        title: 'Ingrese el Codigo de Autenticacion',
        input: 'password',
        inputAttributes: {
            autocapitalize: 'off'
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
        preConfirm: async (login) => {

            if (login.length > 0) {
                let wsResponse = wsResponse1;
                wsResponse["ws_structure"]["params"]["auth_code"] = login;
                console.log("prueba", wsResponse["ws_structure"]["params"])
                const props = {
                    services: wsResponse["@name"],
                    structure: wsResponse["ws_structure"]["params"]
                }
                return await fetch(responseHttp.url, serviceQuest(props))
                    .then(response => {
                        if (!response.ok) {
                            throw new Error("Codigo de auntentificacion invalido , intente otravez!")
                        }
                        let responseJson = response.json();
                        return responseJson;

                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `${error}`
                        )
                    })
            } else {
                Swal.showValidationMessage(
                    'Es necesario ingresar al menos un caracter.'
                )
            }

        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            responseSwal = result.value
            return result.value;
        }
    })
    if (responseSwal === "") {
        responseSwal = { cancel: true }
    }
    return responseSwal;
};

// TODO:ESTO SE DEVE APLICAR PARA EL DESENCRIPTAR LOS DATOS RECIVIDOS
// eslint-disable-next-line
const decryptData = (encodedData) => {

    const [encodedIV, encodedEncryptedData] = encodedData.split(':');

    // Decodificar IV y datos cifrados desde base64
    const iv = CryptoJS.enc.Base64.parse(encodedIV);
    const ciphertext = CryptoJS.enc.Base64.parse(encodedEncryptedData);

    // Clave en formato hexadecimal
    const key_codificada = CryptoJS.enc.Hex.parse(key);

    try {
        // Descifrar utilizando AES-256-CBC y Pkcs7 padding
        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: ciphertext },
            key_codificada,
            { iv: iv, padding: CryptoJS.pad.Pkcs7 }
        );

        // Convertir a texto UTF-8
        const plaintext = decrypted.toString(CryptoJS.enc.Utf8);

        return plaintext;
    } catch (error) {
        console.error('Error al descifrar los datos:', error);
        return null;
    }
};


import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import SkeletonLayout from './Skeleton';
import { esArray } from "src/general_components/functionEsArray";
import { getDataDashboard } from "../../src/general_helpers/getDataDashboard";
import { ComprobantesDashboard } from "../general_components/ComprobantesDashboard";
import { ClientesDashboard } from "../general_components/ClientesDashboard";
import Getters from 'src/general_helpers/getters';
import { parametros_proyecto, id_proyecto } from "src/configs/config";

export const DashboardFloripa = () => {
    const [responseData, setResponseData] = useState(false);
    const [clientesData, setClientesData] = useState(false);
    const [comprobantesData, setComprobantesData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const proyecto = JSON.parse(localStorage.getItem("parametros_proyecto")).id.toString();
    const user_data = JSON.parse(localStorage.getItem("siam-user-data"));
    const id_perfil_usuario = user_data?.id_perfiles;

    // Función para buscar los parametros del proyecto
    function buscarPorIdProyecto(id) {
        return parametros_proyecto?.find(objeto => objeto.id === id);
    }
    const proyecto_encontrado = buscarPorIdProyecto(id_proyecto);
    const imgUrl = proyecto_encontrado?.url_imagen;
  
    // Filtro por cuentas vinculadas al usuario
    const getter = new Getters()
    const user_id_cuentas = getter.getLocalUserCuentas();

    const getLocalUserCuentas = () => {
        let id_cuentas = "";
        const user_cuentas = JSON.parse(localStorage.getItem("siam-user-cuentas"));
        if (user_cuentas) {
            user_cuentas.forEach((user_cuenta) => {
                if (user_cuenta && user_cuenta.id_cuentas) {
                    id_cuentas += user_cuenta.id_cuentas + ",";
                }
            });
        }
        return id_cuentas === "" ? false : id_cuentas.replace(/(^,)|(,$)/g, "");
    };

    useEffect(() => {
        const getData = async () => {
            let vistaConsulta = false;
            const cuenta = getLocalUserCuentas();
            let response = await getDataDashboard(vistaConsulta, proyecto);
            if (response) {
                if (cuenta) {
                    const clientesFiltrados = response?.clientes?.items.filter(objeto => objeto.id === cuenta);
                    const comprobantesFiltrados = response?.comprobantes?.items.filter(objeto => objeto.cuenta === cuenta);
                    setClientesData(esArray(clientesFiltrados));
                    setComprobantesData(esArray(comprobantesFiltrados));
                } else {
                    setClientesData(esArray(response?.clientes?.items));
                    setComprobantesData(esArray(response?.comprobantes?.items));
                }
                setResponseData(true);
            }
        };
        if (id_perfil_usuario !== 2) {
            getData();
        }

    }, [id_perfil_usuario, proyecto]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const formatTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son base 0
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day} / ${month} / ${year} ${hours}:${minutes}:${seconds}`;
    };

    return (
        <div>
            {
                user_id_cuentas ? (
                    <Grid container justifyContent="center" alignItems="center" style={{ height: '90vh' }}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <img src={imgUrl} alt="Bienvenido" style={{ maxWidth: '100%', height: 'auto', marginBottom: 20 }} />
                            {/* <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
                                Bienvenido
                            </Typography> */}
                            <Typography variant="h4" align="center">
                                {formatTime(currentTime)}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : responseData ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h4"
                                style={{
                                    fontFamily: 'Roboto, sans-serif', textAlign: 'left', color: "#0A395F",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    marginTop: 10,
                                    padding: 0
                                }}
                            >
                                Datos Generales
                            </Typography>
                        </Grid>
                        <Grid item display={'flex'} xs={12} sm={12} md={12} style={{ paddingBottom: 30 }}>
                            <Grid item xs={6}>
                                <Card sx={{ margin: 1, borderRadius: 3, boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)" }}>
                                    <CardContent>
                                        <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center', color: 'black', fontWeight: "bold" }}>
                                            Comprobantes
                                        </Typography>
                                        <ComprobantesDashboard comprobantes={comprobantesData} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card sx={{ margin: 1, borderRadius: 3, boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)" }}>
                                    <CardContent>
                                        <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center', color: 'black', fontWeight: "bold" }}>
                                            Clientes
                                        </Typography>
                                        <ClientesDashboard clientes={clientesData} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                ) : (
                    <SkeletonLayout />
                )}
        </div>
    );
};


import React from 'react';
import { Grid, Typography } from '@mui/material';
import Chart from "react-apexcharts";


export const TipoCajasCharts = (data) => {

  const gridItemStyle = {
    borderRadius: '10px', // Establece los bordes redondeados
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Agrega sombra
    padding: '10px', // Añade espaciado interno
    height: '65vh'
  };
  const descripcion = data.tipoCajas.map((objeto) => objeto.descripcion + ' <b>(' + objeto.cant + ')</b>');
  const cant = data.tipoCajas.map((objeto) => parseInt(objeto.cant, 10));
  const numerosOrdenadosDesc = [...cant].sort((a, b) => a - b);
  const options = {
    plotOptions: {
      bar: {
        distributed: true,
        position: "top",
        dataLabels: {
          position: "top",
          formatter: function (val) {
            return val.toFixed(2);
          },
          offsetY: -15,
        }
      }
    },
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: descripcion,
      labels: { show: false }
    }
  };

  const series = [
    {
      name: "Cantidad",
      data: numerosOrdenadosDesc,
    },
  ];
  return (

    <Grid marginTop={3} style={gridItemStyle} >
      <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', textAlign: 'left'}}>
        Tipos de Caja
      </Typography>
      <Chart options={options} series={series} type="bar" />
    </Grid>
  );
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import esArray from "src/general_components/functionEsArray";
import { getModeloArticulos } from "src/projects/articulos/helpers/ModeloArticulosData";

const SelectorModeloArticulo = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.selectedModeloArticulo;

  const articulos = JSON.parse(localStorage.getItem("siam-cuentasservicios"));
  const [ModeloArticuloSeleccionado, setModeloArticuloSeleccionado] =
    useState("");

  useEffect(() => {
    const modeloArticulo = async () => {
      if (articulos) {
        setDataDefaults(esArray(articulos));
      } else {
        const response = await getModeloArticulos();
        if (response) {
          setDataDefaults(response);
        }
      }
    };
    modeloArticulo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModeloArticuloSeleccionado(
      dataDefaults.find((item) => item.id === tipo)
    );
  }, [dataDefaults, tipo]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setModeloArticuloSeleccionado(newValue);
      props.handleChange(props.path, newValue.id); // Obtén el ID del objeto seleccionado
    } else {
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
          value={ModeloArticuloSeleccionado ? ModeloArticuloSeleccionado : null}
          onChange={handleChange}
          options={dataDefaults || []}
          getOptionLabel={(option) => option.id + " - " + option.nombre || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Articulos / Servicios"
              disabled={disabled}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorModeloArticuloTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorModeloArticulo";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorModeloArticuloComponent = {
  renderer: withJsonFormsControlProps(SelectorModeloArticulo),
  tester: SelectorModeloArticuloTester,
};

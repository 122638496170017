//Context especifio de Cuentas

import React, { useState, useEffect, useContext, createContext } from "react";
import { CuentasServiciosInternetForm } from "src/projects/gestion_comercial/cuentas/components/CuentasServicioInternetForm";
import { CuentasTelefonosForm } from "src/projects/gestion_comercial/cuentas/components/CuentasTelefonosForm";
import { CuentasLocalizacionesForm } from "src/projects/gestion_comercial/cuentas/components/CuentasLocalizacionesForm";
import { postCuentas, postCuentasFisicas } from "src/projects/gestion_comercial/cuentas/helpers/CuentasData";
import { getCuentasById, getCuentasFisicasById } from "src/projects/gestion_comercial/cuentas/helpers/CuentasData";
import { getTipoServiciosInternet } from "src/general_helpers/TipoServiciosInternetData";
import { getTipoTelefonos } from "src/general_helpers/TipoTelefonosData";
import { getTipoParametros } from "src/general_helpers/TipoParametrosData";
import "src/services/styles/sweetAlert.css";
import { esArray } from "src/general_components/functionEsArray"
import datos from "src/services/datos";

const CuentasContext = createContext();
export const useCuentasContext = () => useContext(CuentasContext);

function CuentasProvider({ children }) {
  const [cuenta, setCuenta] = useState({ id_cuentas: "0" });
  const [cuentaJuridica, setCuentaJuridica] = useState([]);
  const [cuentaFisica, setCuentaFisica] = useState([]);
  const [cuentaJuridicaEdit, setCuentaJuridicaEdit] = useState(undefined);
  const [cuentaFisicaEdit, setCuentaFisicaEdit] = useState(undefined);
  const [categoriaCuentas, setCategoriaCuentas] = useState([]);
  const [categoriaCuentasEdit, setCategoriaCuentasEdit] = useState(undefined);
  const [documentos, setDocumentos] = useState([]);
  const [documentosEdit, setDocumentosEdit] = useState(undefined);
  const [localizaciones, setLocalizaciones] = useState([]);
  const [localizacionesEdit, setLocalizacionesEdit] = useState(undefined);
  const [serviciosInternetEdit, setServiciosInternetEdit] = useState(undefined);
  const [telefonosEdit, setTelefonosEdit] = useState(undefined);
  const [infoAdicionalEdit, setInfoAdicionalEdit] = useState(undefined);
  const [parametrosEdit, setParametrosEdit] = useState(undefined);
  const [selectedLocalizacion, setSelectedLocalizacion] = useState([]); //setear la localizacion para usarla en los tlefonos
  const [serviciosInternet, setServiciosInternet] = useState([]);
  const [telefonos, setTelefonos] = useState([]);
  const [tipoContenidos, setTipoContenidos] = useState([]);
  // const [localidades, setLocalidades] = useState([]);
  const [tipoTelefonos, setTipoTelefonos] = useState([]);
  const [tiposServiciosInternet, setTiposServiciosInternet] = useState([]);
  const [tipoParametros, setTipoParametros] = useState([]);
  const [infoAdicional, setInfoAdicional] = useState([]);
  const [cuentasTipoContenidos, setCuentasTipoContenidos] = useState([]);
  const [cuentasTipoContenidosEdit, setCuentasTipoContenidosEdit] = useState(
    []
  );
  const [parametros, setParametros] = useState([]);
  const [addCuenta, setAddCuenta] = useState(false);
  const [editCuenta, setEditCuenta] = useState(false);
  const [endAsyncGetData, setEndAsyncGetData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [closeModal, setCloseModal] = useState(false);
  const [savedData, setSavedData] = useState(false);
  const [impuestoSituacionEdit, setImpuestoSituacionEdit] = useState(undefined);
  const [impuestoSituacion, setImpuestoSituacion] = useState(undefined);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [updateLocalizaciones, setUpdateLocalizaciones] = useState(false);
  const [updateTelefonos, setUpdateTelefonos] = useState(false);
  const [updateServiciosInternet, setUpdateServiciosInternet] = useState(false);
  const [closeModals, setCloseModals] = useState(false);
  const [updateParametros, setUpdateParametros] = useState(false);
  const [parametrosCuentas, setParametrosCuentas] = useState([]);
  const [criteriosSearchCuentasJuridicas, setCriteriosSearchCuentasJuridicas] = useState({});
  const [busquedaLocalidad, setBusquedaLocalidad] = useState(false);
  const [contactosCuentas, setContactosCuentas] = useState([]);
  const [contactos, setContactos] = useState([]);
  const [contactosEdit, setContactosEdit] = useState(undefined);
  const [updateContactos, setUpdateContactos] = useState(false);
  const [esJuridica, setEsJuridica] = useState(false);
  /*
  El siguiente useEffect se utiliza para traer todos los datos de las cuentas al Context
  */

  useEffect(() => {
    const getDataCuentas = async () => {
      setIsLoadingData(true);
      if (cuenta.id_cuentas > 0) {
        let response = esJuridica ? await getCuentasById(cuenta.id_cuentas) : await getCuentasFisicasById(cuenta.id_cuentas);
        if (response.relations) {
          if (esJuridica) {
            response.relations.cuentas_personas_juridicas
              ? setCuentaJuridica(response.relations.cuentas_personas_juridicas)
              : setCuentaJuridica([]);
          } else {
            response.relations.cuentas_personas_fisicas
              ? setCuentaFisica(response.relations.cuentas_personas_fisicas)
              : setCuentaFisica([]);
          }

          response.relations.cuentas_documentos_inscripciones
            ? setDocumentos(response.relations.cuentas_documentos_inscripciones)
            : setDocumentos([]);

          response.relations.cuentas_categoria_cuentas
            ? setCategoriaCuentas(response.relations.cuentas_categoria_cuentas)
            : setCategoriaCuentas([]);

          response.relations.cuentas_info_adicional
            ? setInfoAdicional(response.relations.cuentas_info_adicional)
            : setInfoAdicional([]);
          response.relations.cuentas_tipo_contenidos
            ? setCuentasTipoContenidos(
              response.relations.cuentas_tipo_contenidos
            )
            : setCuentasTipoContenidos([]);

          if (response.relations.cuentas_localizaciones) {

            response.relations.cuentas_localizaciones = esArray(response.relations.cuentas_localizaciones)
            response.relations.localidad = esArray(response.relations.localidad)
            response.relations.departamento = esArray(response.relations.departamento)
            response.relations.provincia = esArray(response.relations.provincia)
            response.relations.pais = esArray(response.relations.pais)

            const localidadMap = new Map(response.relations.localidad.map(data => [data.id, data.id_departamento]));
            const localidadNombreMap = new Map(response.relations.localidad.map(data => [data.id, data.nombre]));
            const departamentoMap = new Map(response.relations.departamento.map(data => [data.id, data.id_provincia]));
            const provinciaMap = new Map(response.relations.provincia.map(data => [data.id, data.id_pais]));

            const cuentasLocalizaciones = response.relations.cuentas_localizaciones.map(cuentaLocalizacion => {
              const id_localidad = cuentaLocalizacion.id_localidad;
              const localidadNombre = localidadNombreMap.get(id_localidad);
              const id_departamento = localidadMap.get(id_localidad);
              const id_provincia = departamentoMap.get(id_departamento);
              const id_pais = provinciaMap.get(id_provincia);

              return {
                ...cuentaLocalizacion,
                id_departamento,
                id_provincia,
                id_pais,
                nombre: localidadNombre
              };
            });

            setLocalizaciones(cuentasLocalizaciones);
          } else {
            setLocalizaciones([]);
          }


          response.relations.cuentas_localizaciones_tipo_contenidos
            ? Array.isArray(response.relations.cuentas_localizaciones_tipo_contenidos)
              ? setTipoContenidos(response.relations.cuentas_localizaciones_tipo_contenidos)
              : setTipoContenidos([response.relations.cuentas_localizaciones_tipo_contenidos])
            : setTipoContenidos([]);

          response.relations.cuentas_servicios_internet
            ? Array.isArray(response.relations.cuentas_servicios_internet)
              ? setServiciosInternet(
                response.relations.cuentas_servicios_internet
              )
              : setServiciosInternet([
                response.relations.cuentas_servicios_internet,
              ])
            : setServiciosInternet([]);
          response.relations.cuentas_telefonos
            ? Array.isArray(response.relations.cuentas_telefonos)
              ? setTelefonos(response.relations.cuentas_telefonos)
              : setTelefonos([response.relations.cuentas_telefonos])
            : setTelefonos([]);

          response.relations.cuentas_parametros
            ? Array.isArray(response.relations.cuentas_parametros)
              ? setParametros(response.relations.cuentas_parametros)
              : setParametros([response.relations.cuentas_parametros])
            : setParametros([]);

          response.relations.cuentas_contactos
            ? Array.isArray(response.relations.cuentas_contactos)
              ? setContactos(response.relations.cuentas_contactos)
              : setContactos([response.relations.cuentas_contactos])
            : setContactos([]);

          response.relations.cuentas_impuestos_situaciones
            ? setImpuestoSituacion(
              response.relations.cuentas_impuestos_situaciones
            )
            : setImpuestoSituacion([]);
        }
      }
      setIsLoadingData(false);
    };
    getDataCuentas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuenta]);

  // const getLocalidades = async () => {
  //   if (!(localidades.length > 0)) {
  //     let aux_storage = JSON.parse(localStorage.getItem("siam-localidad"));
  //     if (aux_storage) setLocalidades(aux_storage.localidad);
  //     else {
  //       const response = await getLocalidad();
  //       if (response) {
  //         setLocalidades(response);
  //       }
  //     }
  //   }
  // };

  const getTiposTelefonos = async () => {
    if (!(tipoTelefonos.length > 0)) {
      let aux_storage = JSON.parse(localStorage.getItem("siam-tipo_telefonos"));
      if (aux_storage) setTipoTelefonos(aux_storage.tipo_telefonos);
      else {
        const response = await getTipoTelefonos();
        if (response) {
          setTipoTelefonos(response);
        }
      }
    }
  };
  const getTiposServiciosInternet = async () => {
    if (!(tiposServiciosInternet.length > 0)) {
      let aux_storage = JSON.parse(
        localStorage.getItem("siam-tipo_servicios_internet")
      );
      if (aux_storage)
        setTiposServiciosInternet(aux_storage.tipo_servicios_internet);
      else {
        const response = await getTipoServiciosInternet();
        if (response) {
          setTiposServiciosInternet(response);
        }
      }
    }
  };

  const getParametros = async () => {
    if (!(tipoParametros.length > 0)) {
      let aux_storage = JSON.parse(localStorage.getItem("siam-tipo_parametro"));
      if (aux_storage) setTipoParametros(aux_storage.tipo_parametro);
      else {
        const response = await getTipoParametros();
        if (response) {
          setTipoParametros(response);
        }
      }
    }
  };

  const getParametrosCuentas = async () => {
    if (!(tipoParametros.length > 0)) {
      let aux_storage = JSON.parse(
        localStorage.getItem("siam-parametros_cuentas")
      );
      if (aux_storage) setParametrosCuentas(aux_storage.parametros_cuentas);
      else {
        const response = await getTipoParametros();
        if (response) {
          setParametrosCuentas(response);
        }
      }
    }
  };

  useEffect(() => {
    // getLocalidades();
    getTiposTelefonos();
    getTiposServiciosInternet();
    getParametros();
    getParametrosCuentas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* Se setean las opciones del listado de Localizaciones */
  /* Definicion como estados de las opcions de las listas de Localizaciones, Servicios de Internet
  y telefonos */
  /* Se setean las opciones del listado de Telefonos */

  useEffect(() => {
    let opciones = { ...optionsTelefonos };
    let telsConTipos = telefonos.map(function (x) {
      let result = { ...x };
      result.nombre = tipoTelefonos.filter(
        (y) => y.id === x.id_tipo_telefonos
      )[0].nombre;
      return result;
    });
    opciones.res = [...telsConTipos];
    setOptionsTelefonos(opciones);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telefonos]);

  /* La siguiente funcion permite la carga de Localizaciones en el arreglo contenedor
  que luego guardará los datos en la DB */

  // const loadLocalizaciones = (loc) => {
  //   if (loc) {

  //     const localidadNombreMap = new Map(localidades.map(data => [data.id, data.nombre]));
  //     const localidadNombre = localidadNombreMap.get(loc.id_localidad);

  //     loc.nombre = localidadNombre;
  //     //asigno el nombre de la localidad para que se muestre en el listado de Localizaciones
  //     const loc_aux = [...localizaciones];
  //     if (loc.principal) {
  //       let indexPrincipal = loc_aux.findIndex(
  //         (loc_aux) => loc_aux.principal === "1"
  //       );

  //       indexPrincipal > 0 && (loc_aux[indexPrincipal].principal = "0");
  //       /*
  //       Esta linea se asegura que no haya otros domicilios principales seteados 
  //       y de encontrarlo, lo pone en 0, para reemplazarlo por el actual 
  //       */
  //     }
  //     if (loc.id >= "0") {
  //       // este caso es una modificacion basado en el id
  //       const indice = loc_aux.findIndex((loc_aux) => loc_aux.id === loc.id);
  //       loc_aux[indice] = loc;
  //     } else {
  //       if (loc_aux.length > 0) {
  //         //en este caso, si agregas, se asegura que le pongas un id mayor al ultimo existente en el listado
  //         loc_aux.sort(function (a, b) {
  //           return a.id - b.id;
  //         });
  //         loc.id = '0' + (parseInt(loc_aux[loc_aux.length - 1].id) + 1).toString();
  //       } else {
  //         // si es el primero de la lista, debo agregarlo con el 0
  //         loc.id = "0";
  //       }
  //       loc_aux.push(loc);
  //     }
  //     setLocalizaciones(loc_aux);
  //     const options_aux = { ...optionsLocalizaciones };
  //     options_aux.res = loc_aux;
  //     options_aux.listName = "Localizaciones";
  //     setOptionsLocalizaciones(options_aux);
  //   }
  // };

  /* La siguiente funcion permite la carga de Servicios de Internet en el arreglo contenedor
  que luego guardará los datos en la DB */

  const loadServiciosInternet = (serv) => {
    if (serv) {
      const serv_aux = [...serviciosInternet];
      if (serv.id >= "0") {
        // este caso es una modificacion basado en el id
        const indice = serv_aux.findIndex(
          (serv_aux) => serv_aux.id === serv.id
        );
        serv_aux[indice] = serv;
      } else {
        if (serv_aux.length > 0) {
          //en este caso, si agregas, se asegura que le pongas un id mayor al ultimo existente en el listado
          serv_aux.sort(function (a, b) {
            return a.id - b.id;
          });
          serv.id = '0' + (parseInt(serv_aux[serv_aux.length - 1].id) + 1).toString();
        } else {
          // si es el primero de la lista, debo agregarlo con el 0
          serv.id = "0";
        }
        serv_aux.push(serv);
      }
      setServiciosInternet(serv_aux);
      const options_aux = { ...optionsServiciosInternet };
      options_aux.res = serv_aux;
      options_aux.listName = "Servicios Internet";
      setOptionsServiciosInternet(options_aux);
    }
  };

  /* La siguiente funcion permite la carga de Telefonos en el arreglo contenedor
  que luego guardará los datos en la DB */

  const loadTelefonos = (tel) => {
    if (tel) {
      //filtro los telefonos que son de esta localizacion
      const tel_aux = [...telefonos];
      if (tel.id >= "0") {
        // este caso es una modificacion basado en el id
        const indice = tel_aux.findIndex((tel_aux) => tel_aux.id === tel.id);
        tel_aux[indice] = tel;
      } else {
        tel.id_cuentas_localizaciones = selectedLocalizacion;
        if (tel_aux.length > 0) {
          //en este caso, si agregas, se asegura que le pongas un id mayor al ultimo existente en el listado
          tel_aux.sort(function (a, b) {
            return a.id - b.id;
          });
          tel.id = (parseInt(tel_aux[tel_aux.length - 1].id) + 1).toString();
        } else {
          // si es el primero de la lista, debo agregarlo con el 0
          tel.id = "0";
        }
        tel_aux.push(tel);
      }
      setTelefonos(tel_aux);
    }
  };

  /* La siguiente funcion permite la carga de Tipo de Contenidos en el arreglo contenedor
  que luego guardará los datos en la DB */

  const loadTipoContenidos = (tContenido) => {

    const tContenidoSelected = tContenido?.selectedTipoContenidos;

    if (tContenidoSelected) {

      localizaciones.map(cuentaLocalizacion => {
        if (cuentaLocalizacion.id === tContenido.id_cuentas_localizaciones) {

          delete cuentaLocalizacion.tipo_contenidos;
          cuentaLocalizacion.tipo_contenidos = tContenidoSelected

          return { ...cuentaLocalizacion };
        } else {
          // Devuelve el elemento sin cambios si la condición no se cumple
          return cuentaLocalizacion;
        }

      });

    }

  };

  const loadParametros = (param) => {
    if (param) {
      const param_aux = [...parametros];
      if (param.id >= "0") {
        // este caso es una modificacion basado en el id
        const indice = param_aux.findIndex(
          (param_aux) => param_aux.id === param.id
        );
        param_aux[indice] = param;
      } else {
        if (param_aux.length > 0) {
          //en este caso, si agregas, se asegura que le pongas un id mayor al ultimo existente en el listado
          param_aux.sort(function (a, b) {
            return a.id - b.id;
          });
          param.id = (
            parseInt(param_aux[param_aux.length - 1].id) + 1
          ).toString();
        } else {
          // si es el primero de la lista, debo agregarlo con el 0
          param.id = "0";
        }
        param_aux.push(param);
      }
      setParametros(param_aux);
    }
  };

  const loadContactos = (param) => {
    if (param) {
      const param_aux = [...contactos];
      if (param.id >= "0") {
        // este caso es una modificacion basado en el id
        const indice = param_aux.findIndex(
          (param_aux) => param_aux.id === param.id
        );
        param_aux[indice] = param;
      } else {
        if (param_aux.length > 0) {
          //en este caso, si agregas, se asegura que le pongas un id mayor al ultimo existente en el listado
          param_aux.sort(function (a, b) {
            return a.id - b.id;
          });
          param.id = '0' + (
            parseInt(param_aux[param_aux.length - 1].id) + 1
          ).toString();
        } else {
          // si es el primero de la lista, debo agregarlo con el 0
          param.id = "0";
        }
        param_aux.push(param);
      }

      setContactos(param_aux);
    }
  };
  /* funciones para cargar en context los datos a editar */
  const setEditData = (params) => {
    switch (params.nodoPadre) {
      case "juridicas": {
        setCuentaJuridicaEdit({
          id: cuentaJuridica?.id || "",
          id_cuentas: cuentaJuridica?.id_cuentas || "",
          razon_social: cuentaJuridica?.razon_social || "",
          nombre_fantasia: cuentaJuridica?.nombre_fantasia || "",
        });
        break;
      }
      case "fisicas": {
        setCuentaFisicaEdit({
          id: cuentaFisica?.id || "",
          id_cuentas: cuentaFisica?.id_cuentas || "",
          nombre: cuentaFisica?.nombre || "",
          apellido: cuentaFisica?.apellido || "",
          fecha_nacimiento: cuentaFisica?.fecha_nacimiento || "",
          id_genero: cuentaFisica?.id_genero || "",
          id_estado_civil: cuentaFisica?.id_estado_civil || "",
        });
        break;
      }
      case "localizaciones": {
        setLocalizacionesEdit({
          id: params?.id || "",
          id_cuentas: params?.id_cuentas || "",
          id_tipo_localizacion: params?.id_tipo_localizacion || "",
          domicilio: params?.domicilio || "",
          id_localidad: params?.id_localidad || "",
          descripcion: params?.descripcion || "",
          principal: params?.principal === 1 ? true : false,
          id_pais: params?.id_pais || "",
          id_provincia: params?.id_provincia || "",
          id_departamento: params?.id_departamento || "",
        });
        break;
      }
      case "serviciosInternet": {
        setServiciosInternetEdit({
          id: params?.id || "",
          id_cuentas: params?.id_cuentas || "",
          id_tipo_servicios_internet: params?.id_tipo_servicios_internet || "",
          valor: params?.valor || "",
        });
        break;
      }
      case "telefonos": {
        setTelefonosEdit({
          id: params?.id || "",
          id_cuentas: params?.id_cuentas || "",
          id_cuentas_localizaciones:
            params?.id_cuentas_localizaciones || selectedLocalizacion.id || "",
          id_tipo_telefonos: params?.id_tipo_telefonos || "",
          numero: params?.numero || "",
        });
        break;
      }
      case "parametros": {
        setParametrosEdit({
          id: params?.id || "",
          id_cuentas: params?.id_cuentas || "",
          id_parametros_cuentas: params?.id_parametros_cuentas || "",
          valor: params?.valor || "",
        });

        break;
      }
      case "infoAdicional": {
        setInfoAdicionalEdit({
          id: params?.id || "",
          id_cuentas: params?.id_cuentas || "",
          descripcion: params?.descripcion || "",
        });
        break;
      }
      case "cuentasTipoContenidos": {
        setCuentasTipoContenidosEdit({
          id: params?.id || "",
          id_cuentas_localizaciones: params?.id_cuentas_localizaciones || "",
          id_tipo_contenidos: params?.id_tipo_contenidos || "",
        });
        break;
      }
      case "contactos": {
        setContactosEdit({
          id: params?.id || "",
          id_cuentas: params?.id_cuentas || "",
          descripcion: params?.descripcion || "",
        });

        break;
      }
      default: {
        break;
      }
    }
  };

  /* Se setea la Localizacion actual seleccionada */

  const setLocation = (idLocalizacion) => {
    setSelectedLocalizacion(idLocalizacion);
  };

  /* Funcion de eliminacion del arreglo de Localizaciones. Al borrar una localizacion
  tambien se eliminan los telefonos pertenecientes a dicha localizacion */

  /* ToDo: alert de consulta de eliminacion de los datos */

  const deleteLocalizacion = (id) => {
    /* remuevo primero los elementos del arreglo de telefonos pertenecientes a esa localizacion */
    const telefonosToSave = telefonos.filter(
      (x) => x.id_cuentas_localizaciones !== id
    );
    setTelefonos(telefonosToSave);
    const localizacionesToSave = localizaciones.filter((x) => x.id !== id);

    setLocalizaciones(localizacionesToSave);
  };

  /* Funcion de eliminacion del arreglo de Servicios de Internet.  */

  const deleteServiciosInternet = (id) => {
    const serviciosToSave = serviciosInternet.filter((x) => x.id !== id);
    setServiciosInternet(serviciosToSave);
  };

  /* Funcion de eliminacion del arreglo de Telefonos.  */

  /* ToDo: alert de consulta de eliminacion de los datos */

  const deleteTelefonos = async (id) => {

    let telefonosToSave = telefonos;
    const preId = id.startsWith("0");
    if (!preId) {

      const servicioTelefono = new datos('cuentas_telefonos');
      let data = {
        id: id
      }
      const response = await servicioTelefono.delete(data);
      const severidad = response.status !== 'true' ? 'warning' : 'success';
      const mensaje = response.status !== 'true' ? response.message : 'El registro se elimino correctamente.';

      if (response.status === 'true') {
        telefonosToSave = telefonos.filter((x) => x.id !== id);
      }

      setSeverity(severidad)
      setMessage(mensaje)
      setSnackbarVisible(true);

    } else {
      telefonosToSave = telefonos.filter((x) => x.id !== id);
    }
    setTelefonos(telefonosToSave);
    setUpdateTelefonos(true);
  };

  const deleteParametros = (id) => {
    const parametrosToSave = parametros.filter((x) => x.id !== id);
    setParametros(parametrosToSave);
  };

  const deleteContactos = (id) => {
    const contactosToSave = contactos.filter((x) => x.id !== id);
    setContactos(contactosToSave);
  };

  const setter = (type, id) => {
    switch (type) {
      case "location": {
        setLocation(id);
        break;
      }

      default: {
        break;
      }
    }
  };

  const [optionsLocalizaciones, setOptionsLocalizaciones] = useState({
    res: { ...localizaciones },
    cols: [
      { field: "id", headerName: "ID", flex: 0.2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
      { field: "domicilio", headerName: "Domicilio", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
      { field: "id_localidad", headerName: "Localidad", flex: 1 }, //
    ],
    listName: "Localizaciones",
    create: CuentasLocalizacionesForm,
  });

  const [optionsServiciosInternet, setOptionsServiciosInternet] = useState({
    res: { ...serviciosInternet },
    cols: [
      { field: "id", headerName: "ID", flex: 0.2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
      { field: "nombre", headerName: "Tipo", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
      { field: "valor", headerName: "Valor", flex: 1 },
    ],
    listName: "Servicios de Internet",
    redirectURLForm: "/generales/cuentas-servicios-internet-form",
    create: CuentasServiciosInternetForm,
  });

  const [optionsTelefonos, setOptionsTelefonos] = useState({
    res: { ...telefonos },
    cols: [
      { field: "id", headerName: "ID", flex: 0.2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
      { field: "nombre", headerName: "Tipo", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
      { field: "numero", headerName: "Numero", flex: 1 },
    ],
    listName: "Telefonos",
    redirectURLForm: "/generales/cuentas-juridicas-form",
    create: CuentasTelefonosForm,
    delete: deleteTelefonos,
    setter: "location",
  });

  /* Funcion de limpieza de datos del context, para asegurarse que se vacia el mismo al realizar una edicion
      o una creacion */

  function clearData() {
    setCuenta({ id_cuentas: "0" });
    setCuentaJuridica([]);
    setCategoriaCuentas([]);
    setDocumentos([]);
    setCuentaJuridicaEdit(undefined);
    setCuentaFisicaEdit(undefined);
    setCategoriaCuentasEdit(undefined);
    setDocumentosEdit(undefined);

    setLocalizacionesEdit(undefined);
    //setear la localizacion para usarla en los tlefonos
    setServiciosInternet([]);
    setServiciosInternetEdit(undefined);

    setTelefonosEdit(undefined);
    setParametros([]);
    setParametrosEdit(undefined);
    setInfoAdicional([]);
    setInfoAdicionalEdit(undefined);
    setCuentasTipoContenidosEdit(undefined);
    setCuentasTipoContenidos([]);
    setOptionsTelefonos({});
    setOptionsLocalizaciones({});
    setOptionsServiciosInternet({});
    setAddCuenta(false);
    setEditCuenta(false);
    setEndAsyncGetData(false);
    setImpuestoSituacion(undefined);
    setImpuestoSituacionEdit(undefined);
    setUpdateServiciosInternet(false);
    setUpdateParametros(false);
    setLocalizaciones([]);
    setContactosEdit(undefined);
    setContactos([]);
    setUpdateContactos(false);
  }

  /* Funcion que envia los datos almacenados en el Context a traves del metodo POST
    del servicio de Cuentas */

  const saveData = async () => {
    let postResponse = false;
    let dataToSave = {
      cuentas: cuenta.id_cuentas > 0 ? { id: cuenta.id_cuentas } : { id: null },
      cuentas_personas_juridicas:
        cuentaJuridicaEdit !== undefined ? cuentaJuridicaEdit : cuentaJuridica,
      cuentas_personas_fisicas:
        cuentaFisicaEdit !== undefined ? cuentaFisicaEdit : cuentaFisica,
      cuentas_localizaciones: localizaciones,
      cuentas_servicios_internet: serviciosInternet,
      cuentas_telefonos: telefonos,
      cuentas_info_adicional:
        infoAdicionalEdit !== undefined ? infoAdicionalEdit : infoAdicional,
      cuentas_parametros: parametros,
      cuentas_documentos_inscripciones:
        documentosEdit !== undefined ? documentosEdit : documentos,
      cuentas_categoria_cuentas:
        categoriaCuentasEdit !== undefined
          ? categoriaCuentasEdit
          : categoriaCuentas,
      cuentas_impuesto_situacion:
        impuestoSituacionEdit !== undefined ? impuestoSituacionEdit : "",
      cuentas_contactos:
        contactos !== undefined ? contactos : "",
    };


    if (esJuridica) {
      await postCuentas(dataToSave)
        .then(response => {
          if (response.status === "false") {
            setSeverity("error");
            setMessage(response.message);
            throw new Error(response.message);
          }
        })
        .then(() => {
          postResponse = true;
          setSavedData(true);
          setCloseModals(true);
          setSeverity("success");
          setSnackbarVisible(true);
          setMessage('Datos almacenados correctamente.');
        })
        .catch((error) => {
          postResponse = false;
        });
    } else {
      await postCuentasFisicas(dataToSave)
        .then(response => {
          if (response.status === "false") {
            setSeverity("error");
            setMessage(response.message);
            throw new Error(response.message);
          }
        })
        .then(() => {
          postResponse = true;
          setSavedData(true);
          setCloseModals(true);
          setSeverity("success");
          setSnackbarVisible(true);
          setMessage('Datos almacenados correctamente.');
        })
        .catch((error) => {
          postResponse = false;
        });
    }

    if (postResponse) clearData();
    return postResponse;
  };

  /* se exportan todas las variables y funciones de estado que se utilizan a traves del cuerpo del proceso de CUENTAS */

  const value = {
    addCuenta,
    setAddCuenta,
    editCuenta,
    setEditCuenta,
    cuenta,
    setCuenta,
    categoriaCuentas,
    setCategoriaCuentas,
    cuentaJuridica,
    setCuentaJuridica,
    documentos,
    setDocumentos,
    categoriaCuentasEdit,
    setCategoriaCuentasEdit,
    cuentaJuridicaEdit,
    setCuentaJuridicaEdit,
    documentosEdit,
    setDocumentosEdit,
    localizaciones,
    serviciosInternet,
    telefonos,
    infoAdicional,
    setInfoAdicional,
    parametros,
    setLocalizaciones,
    setServiciosInternet,
    setTelefonos,
    // loadLocalizaciones,
    loadServiciosInternet,
    loadTelefonos,
    loadTipoContenidos,
    loadParametros,
    optionsLocalizaciones,
    optionsServiciosInternet,
    optionsTelefonos,
    setLocation,
    setter,
    // localidades,
    tipoTelefonos,
    tiposServiciosInternet,
    tipoParametros,
    clearData,
    endAsyncGetData,
    deleteLocalizacion,
    deleteTelefonos,
    deleteServiciosInternet,
    deleteParametros,
    saveData,
    isLoadingData,
    localizacionesEdit,
    setLocalizacionesEdit,
    setEditData,
    telefonosEdit,
    parametrosEdit,
    infoAdicionalEdit,
    setInfoAdicionalEdit,
    serviciosInternetEdit,
    closeModal,
    setCloseModal,
    setSelectedLocalizacion,
    selectedLocalizacion,
    savedData,
    setSavedData,
    impuestoSituacion,
    setImpuestoSituacion,
    impuestoSituacionEdit,
    setImpuestoSituacionEdit,
    message,
    setMessage,
    severity,
    setSeverity,
    snackbarVisible,
    setSnackbarVisible,
    updateLocalizaciones,
    setUpdateLocalizaciones,
    setTelefonosEdit,
    updateTelefonos,
    setUpdateTelefonos,
    setServiciosInternetEdit,
    updateServiciosInternet,
    setUpdateServiciosInternet,
    closeModals,
    setCloseModals,
    setParametrosEdit,
    setParametros,
    updateParametros,
    setUpdateParametros,
    parametrosCuentas,
    setParametrosCuentas,
    cuentasTipoContenidos,
    setCuentasTipoContenidos,
    setCuentasTipoContenidosEdit,
    cuentasTipoContenidosEdit,
    tipoContenidos,
    setTipoContenidos,
    criteriosSearchCuentasJuridicas,
    setCriteriosSearchCuentasJuridicas,
    busquedaLocalidad,
    setBusquedaLocalidad,
    contactosCuentas, setContactosCuentas,
    contactos, setContactos, esJuridica, setEsJuridica, cuentaFisicaEdit, setCuentaFisicaEdit, cuentaFisica, setCuentaFisica,
    contactosEdit, setContactosEdit, deleteContactos, updateContactos, setUpdateContactos, loadContactos
  };

  return (
    <CuentasContext.Provider value={value}>{children}</CuentasContext.Provider>
  );
}
export default CuentasProvider;

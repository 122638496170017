import MenuBookIcon from "@mui/icons-material/MenuBook";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { ListTablePaginated } from "src/general_components/ListTablePaginated";
import {
  deleteTipoContenidos,
  getTipoContenidosPaginated,
  getTiposContenidos,
  postTipoContenidos,
} from "../helpers/TipoContenidosData";
import { FormTiposContenidos } from "../components/FormTiposContenidos";
import { MetadatosList } from "../components/MetadatosList";
import { Grid, Tooltip } from "@mui/material";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import BuscarFormTipoContenidos from "../components/BuscarFormTipoContenidos";
import Button from '@mui/material/Button';
import SearchIcon from "@mui/icons-material/Search";

export const TipoContenidosList = () => {
  const [createButton, setCreateButton] = useState(false);
  const [buttonMetadatos, setButtonMetadatos] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [labelModal, setLabelModal] = useState("Metadatos del tipo de contenido: ");
  const [modalStateSearch, setModalStateSearch] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [labelModalSearch, setLabelModalSearch] = useState("Buscar");
  const [filtros, setFiltros] = useState(null);

  const [tipoContenidos, setTipoContenidos] = useState([]);
  const listName = "Tipos de Contenidos";
  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    { field: "detalle", headerName: "Detalle", flex: 1 }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    {
      field: "Metadatos",
      headerName: "Ver Metadatos",
      type: "actions",
      flex: 0.3,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Ver Metadatos">
              <span>
                <MenuBookIcon color="primary" />
              </span>
            </Tooltip>
          }
          onClick={() => {
            verMetadatos(params);
            setModalState(true);
          }}
          label="MenuBookIcon"
        />,
      ],
    },
  ];

  const toggleModalMetadatos = () => {
    setModalState(!modalState);
  };

  const toggleModalSearch = () => {
    setModalStateSearch(!modalStateSearch);
  };

  const verMetadatos = async (data) => {
    setTipoContenidos(data.row);
    setButtonMetadatos(true);

  };

  useEffect(() => {
    setLabelModal("Metadatos del tipo de contenido: " + tipoContenidos.detalle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoContenidos]);

  useEffect(() => {
    setOptions({ ...options, refresh: refreshList, idElementData: filtros })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshList, filtros]);

  const [options, setOptions] = useState({
    cols: columns,
    get: getTiposContenidos,
    getPaginated: getTipoContenidosPaginated,
    //pageSize: [5, 10, 25],
    edit: true,
    delete: deleteTipoContenidos,
    limit: 50,
    filter: true,
    exportData: true,
    server: true,
    idElementData: filtros,

    //DATOS PARA EL FORMULARIO
    createButton: createButton,
    setCreateButton: setCreateButton,
    setDataEdit: setTipoContenidos,
  });

  //CONTROLA CUANDO SE SALE DE UN EDITAR PARA LIMPIAR LA VARIABLE, GUARDA CON EL ID, PUEDE SER QUE EN ALGUNOS NO FUNCIONE
  //TODOS LOS EDITAR DEBERIAN DE TENER UN ID, PERO POR LAS DUDAS COMPROBAR.
  //EL BUTTONMETADATOS SOLO ES PARA ESTE CASO, VERIFICAR CADA OCASION.
  if (
    createButton === false &&
    tipoContenidos.id !== undefined &&
    buttonMetadatos === false
  ) {
    setTipoContenidos([]);
  }
  //setLabelModal("Metadatos del tipo de contenido: " + tipoContenidos.detalle)
  return (



    <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          {listName}
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <CajasProvider>
        <>
          <Grid container justifyContent="flex-end" mb={2} mt={2}>
            <Button
              endIcon={<SearchIcon />}
              onClick={() => {
                setModalStateSearch(true);
              }}
              variant="contained"
              style={{
                color: '#FFFFFF',
                fontFamily: 'Roboto, sans-serif',
                minWidth: '120px',
                width: '120px',
                fontSize: '11px',
                height: '30px',
              }}
            >
              Buscar
            </Button>
          </Grid>

          <SimpleDialogComponent
            open={modalState}
            modalName={labelModal}
            child={
              <MetadatosList
                tipoContenido={tipoContenidos}
                setButtonMetadatos={setButtonMetadatos}
                setRefreshList={setRefreshList}
              />}
            handleClose={toggleModalMetadatos}
            buttonEnviar={false}
            buttonCancelar={true}
          />

          <SimpleDialogComponent
            open={modalStateSearch}
            modalName={labelModalSearch}
            child={
              <BuscarFormTipoContenidos
                setModalState={setModalStateSearch}
                setFiltros={setFiltros}
              />}
            handleClose={toggleModalSearch}
            buttonEnviar={false}
            buttonCancelar={true}
          />

          <>
            <ListTablePaginated options={options} />
            <FormTiposContenidos
              data={tipoContenidos}
              createButton={createButton}
              listName={listName}
              setCreateButton={setCreateButton}
              componentList={"/tipo_contenidos/admin"}
              postData={postTipoContenidos}
            />
          </>
        </>
      </CajasProvider>
    </Grid>
  );
};
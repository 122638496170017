import React from 'react';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { Typography } from '@mui/material';

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
  width: '90%', // Ancho del modal
  height: '90%', // Altura del modal
  maxWidth: '500px', // Ancho máximo del modal
  maxHeight: '80vh', // Altura máxima del modal
  [theme.breakpoints.down('sm')]: {
    width: '100%', // Ancho del modal en pantallas pequeñas
    height: '100%', // Altura del modal en pantallas pequeñas
  },
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: '#3c4b64', // Color de fondo del encabezado
}));

const CloseButton = styled(Button)(({ theme }) => ({
  color: '#FFFFFF',
}));

const Content = styled('div')(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  maxHeight: 'calc(80vh - 100px)', // Establece la altura máxima del contenido
  overflow: 'auto',
}));

const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
}));

const AcceptButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.success.main,
  '&:hover': {
    backgroundColor: theme.palette.success.dark,
  },
  marginLeft: theme.spacing(1), // Agregar un margen izquierdo al botón aceptar
}));

const CerrarButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
  marginRight: theme.spacing(1), // Agregar un margen derecho al botón cerrar
}));


const Title = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF', // Color blanco para el título
  fontWeight: 'bold', // Puedes ajustar el peso de la fuente según tus preferencias
  fontSize: '1.5rem', // Tamaño de la fuente del título
  marginBottom: theme.spacing(1), // Espacio inferior para separar del contenido
}));

const MyModal = ({ open, onClose, content, title }) => {
  return (
    <StyledModal open={open} onClose={onClose}>
      <ModalContent>
        <Header>
          <div>
            <Title variant="h6">{title}</Title>
          </div>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Divider />
        <Content>{content}</Content>
        <Divider />
        <Footer>
          <CerrarButton onClick={onClose}>Cerrar</CerrarButton>
          <AcceptButton onClick={onClose}>Aceptar</AcceptButton>
        </Footer>
      </ModalContent>
    </StyledModal>
  );
};

export default MyModal;
import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { getDepartamento } from 'src/general_helpers/DepartamentoData';
import { getLocalidadesWhereDepartamento, getLocalidadesEditByIdLocalidad } from 'src/general_helpers/LocalidadesData';
import { SimpleDialogComponent } from './SimpleDialogComponentRC';
import { Grid } from "@mui/material";
import { Buttons } from './Buttons';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

export const LocalidadSelector = ({ onSeleccionar, fieldName, valueEdit }) => {

    const [provincia, setProvincia] = useState(null);
    const [departamento, setDepartamento] = useState(null);
    const [localidad, setLocalidad] = useState(null);
    const [provincias, setProvincias] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [actualizar, setAcualizar] = useState(true);

    const filtrarPropiedades = (objeto) => ({
        id: objeto.id,
        nombre: objeto.nombre
    });

    useEffect(() => {
        const obtenerProvinciaDepartamentoLocalidad = async (id) => {
            const response = await getLocalidadesEditByIdLocalidad(id);

            if (response) {
                const provincia = filtrarPropiedades(response.relations?.provincia) || null;
                const departamento = filtrarPropiedades(response.relations?.departamento) || null;
                const localidad = filtrarPropiedades(response) || null;
                await handleProvinciaChange("", provincia)
                await handleDepartamentoChange("", departamento)
                await handleLocalidadChange("", localidad)
            }
        }
        if (actualizar) {
            const provinciasLocalStorage = JSON.parse(localStorage.getItem('siam-provincia'))?.provincia || [];
            setProvincias(provinciasLocalStorage);
        }


        if (valueEdit && actualizar) {
            obtenerProvinciaDepartamentoLocalidad(valueEdit.id || valueEdit)
        }

    }, [valueEdit, actualizar]);

    const handleProvinciaChange = async (event, newValue) => {
        setProvincia(newValue);
        setDepartamentos([]);
        setLocalidades([]);
        setDepartamento(null); // Vaciar el selector de departamento
        setLocalidad(null); // Vaciar el selector de localidad

        if (newValue) {
            await getDepartamento(newValue.id).then((data) => {
                setDepartamentos(data);
            });
        }
    };

    const handleDepartamentoChange = async (event, newValue) => {
        setDepartamento(newValue);
        setLocalidades([]);
        setLocalidad(null); // Vaciar el selector de localidad
        if (newValue) {
            await getLocalidadesWhereDepartamento(newValue.id).then((data) => {
                setLocalidades(data);
            });
        }
    };

    const handleLocalidadChange = (event, newValue) => {
        setLocalidad(newValue);
    };

    const handleSeleccionarClick = () => {
        if (localidad) {
            setAcualizar(false);
            if (onSeleccionar) {
                onSeleccionar(fieldName, localidad);
            }
            setOpenModal(false);
        }
    };

    const handleCancelarClick = () => {
        setAcualizar(false);
        setOpenModal(false);
    };

    const dialogChild = (
        <>
            <div style={{ marginBottom: '10px' }}>
                <Autocomplete
                    size="small"
                    options={provincias}
                    getOptionLabel={(option) => option.nombre}
                    value={provincia} // Asignar el valor de la provincia seleccionada
                    onChange={handleProvinciaChange}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // Personaliza la comparación
                    renderInput={(params) => <TextField {...params} label="Provincia" size="small" />}
                />
            </div>
            <div style={{ marginBottom: '10px' }}>
                <Autocomplete
                    size="small"
                    options={departamentos}
                    getOptionLabel={(option) => option.nombre}
                    value={departamento} // Asignar el valor del departamento seleccionado
                    onChange={handleDepartamentoChange}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // Personaliza la comparación
                    renderInput={(params) => <TextField {...params} label="Departamento" size="small" disabled={!provincia} />}
                />
            </div>
            <div style={{ marginBottom: '10px' }}>
                <Autocomplete
                    size="small"
                    options={localidades || []}
                    getOptionLabel={(option) => option.nombre}
                    value={localidad || null} // Asignar el valor de la localidad seleccionada
                    onChange={handleLocalidadChange}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // Personaliza la comparación
                    renderInput={(params) => <TextField {...params} label="Localidad" size="small" disabled={!departamento} />}
                />
            </div>
            <Grid sx={{ mt: 1, marginTop: 5 }}>
                <Buttons
                    disableButton={!localidad}
                    onClickSubmit={handleSeleccionarClick}
                    labelButtonSave={"Seleccionar"}
                    buttonCerrar={handleCancelarClick}
                    labelButtonClose={"Cancelar"}
                />
            </Grid>
        </>
    );
    return (
        <>
            <Grid container spacing={1} alignItems="center">
                <Grid item sx={{ width: '80%' }}>
                    <TextField label="Localidad" value={localidad ? localidad.nombre : ''} onChange={handleLocalidadChange} size="small" disabled fullWidth />
                </Grid>
                <Grid item sx={{ width: '20%' }}>
                    <Tooltip title="Buscar">
                        <Button variant="contained" onClick={() => setOpenModal(true)}>
                            <SearchIcon /> {/* Icono de la lupa */}
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>

            <SimpleDialogComponent
                open={openModal}
                modalName={"Selector de Localidad"}
                child={dialogChild}
                handleClose={handleCancelarClick}
                buttonEnviar={false}
                buttonCancelar={false}
                maxWidth="xs"
            />
        </>
    );
};

export default LocalidadSelector;
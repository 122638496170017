import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getTipoCaja } from "../../projects/gestion_documental/helpers/CajasTipos";
import { Autocomplete, TextField } from "@mui/material";

const SelectorTipoCajas = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.id_tipo_cajas;

  const [tipoDeCajaSeleccionado, setTipoDeCaja] = useState("");



  useState(() => {
    const tiposDeCajas = async () => {

      let tipoCajas = JSON.parse(localStorage.getItem("siam-tipo_cajas"))?.tipo_cajas;
      
      if(!tipoCajas){
        tipoCajas = await getTipoCaja()
      }
      
        setDataDefaults(tipoCajas);
      
    }

    tiposDeCajas()
  
  }, []);


  useEffect(() => {
    setTipoDeCaja(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setTipoDeCaja(newValue);
      props.handleChange(props.path, newValue.id ); // Obtén el ID del objeto seleccionado
    }else{
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
        value={tipoDeCajaSeleccionado ? tipoDeCajaSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.detalle || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Tipo de Cajas"
          disabled={disabled} />
        )}
      />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorTipoCajasTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("number"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorTipoCajas";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorTipoCajasComponent = {
  renderer: withJsonFormsControlProps(SelectorTipoCajas),
  tester: SelectorTipoCajasTester,
};

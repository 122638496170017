// ThemeContext.js
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import getCustomTheme from 'src/general_components/theme';

export const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
    const storedThemeMode = localStorage.getItem('themeMode') || 'light';
    const storedCustomColors = JSON.parse(localStorage.getItem('customColors')) || {};

    const [themeMode, setThemeMode] = useState(storedThemeMode);
    const [customColors, setCustomColors] = useState(storedCustomColors);

    useEffect(() => {
        localStorage.setItem('themeMode', themeMode);
    }, [themeMode]);

    useEffect(() => {
        localStorage.setItem('customColors', JSON.stringify(customColors));
    }, [customColors]);

    const theme = useMemo(() => getCustomTheme(themeMode, customColors), [themeMode, customColors]);

    const handleThemeChange = (mode) => {
        setThemeMode(mode);
    };

    const handleColorChange = (type, color) => {
        setCustomColors((prevColors) => ({
            ...prevColors,
            [type]: color,
        }));
    };

    return (
        <ThemeContext.Provider value={{ themeMode, handleThemeChange, handleColorChange }}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

import datos from "src/services/datos";


export const getArticulosCategorias = async (auth) => {
  const articulos_categorias = new datos('categorias')
  articulos_categorias.addCondition("categorias", "id", 0, ">")
  const response = await articulos_categorias.get()

  const res = response.wsResponse.categorias;
  // Si es un array se mantiene como estaba
  if(Array.isArray(res)){
      var arr = res; 
  // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  }else if(typeof res === 'undefined'){ // se pasa un array vacio
      arr = [];
  }else { // si trae un solo elemento , es un objeto
      arr = [res]; // se lo convierte a array
  }
  return arr;
};

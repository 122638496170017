import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getLocalidad } from "src/general_helpers/LocalidadData";

import { esArray } from "src/general_components/functionEsArray";
import { useCuentasContext } from "src/context/CuentasContext";
import { Autocomplete, TextField } from "@mui/material";

const SelectorLocalidad = (props) => {
  const {busquedaLocalidad} = useCuentasContext();
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  //const disabled = jsonForm.core.data.disabled;
  const id_localidad = jsonForm.core.data.id_localidad;
  const id_departamento=jsonForm.core.data.id_departamento;
  const id_provincia=jsonForm.core.data.id_provincia;
  const id_pais=jsonForm.core.data.id_pais;

  //const [paises, setPaises] = useState([])
  var localidadesLocalStorage=JSON.parse(localStorage.getItem("siam-localidad"));
  useEffect(() => {
    const getLocalidades = async () => {
      
      if(!busquedaLocalidad){
        if(localidadesLocalStorage !== null){
                  
            const arrlocalidades=esArray(localidadesLocalStorage.localidad);
            const arrayFiltrado = arrlocalidades.filter(objeto => objeto.id_departamento === id_departamento);
            setDataDefaults(esArray(arrayFiltrado));
        }else{
            const localidadesObtenidos = await getLocalidad(id_departamento);
            if (localidadesObtenidos) {
              setDataDefaults([...localidadesObtenidos]);
            }
        }

        if(id_pais === "" || id_provincia === "" || id_departamento === ""){
          setDataDefaults([]);
          setLocalidadSeleccionado("");
          props.handleChange(props.path, "");
        }else{
          setLocalidadSeleccionado("");
          props.handleChange(props.path, "");

        }

        // if (id_localidad) {
        //   setLocalidadSeleccionado(props.data);
        // }
      }else{
        if(localidadesLocalStorage !== null){
                  
          const arrlocalidades=esArray(localidadesLocalStorage.localidad);
        //  const arrayFiltrado = arrlocalidades.filter(objeto => objeto.id_departamento === id_departamento);
          setDataDefaults(esArray(arrlocalidades));
      }else{
          const localidadesObtenidos = await getLocalidad();
          if (localidadesObtenidos) {
            setDataDefaults([...localidadesObtenidos]);
          }
      }
      }
    };
    getLocalidades();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_pais,id_provincia,id_departamento]);


  useEffect(() => {

    if (dataDefaults) {

      const localidadEncontrado = dataDefaults.find((localidad) => localidad.id === id_localidad);
      setLocalidadSeleccionado(localidadEncontrado);
    }
    
}, [dataDefaults,id_localidad]);


  const [localidadSeleccionado, setLocalidadSeleccionado] = useState("");
  //funcion para devolver al formulario la data seleccionada y para setearla en el selector
  const handleChange = (event,newValue) => {

    if (newValue && newValue !== "") {
      setLocalidadSeleccionado(newValue);
      props.handleChange(props.path, newValue.id);
    }else{
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
         <Autocomplete
        value={localidadSeleccionado  ? localidadSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.nombre || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Localidad" />
        )}
      />
      </FormControl>
    </div>
  );
};

  
 /*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorLocalidadTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorLocalidad";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorLocalidadComponent = {
  renderer: withJsonFormsControlProps(SelectorLocalidad),
  tester: SelectorLocalidadTester,
};








// import {
//   and,
//   rankWith,
//   schemaMatches,
//   schemaTypeIs,
//   uiTypeIs,
// } from "@jsonforms/core";
// import Grid from "@mui/material/Grid";
// import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import { useEffect, useState } from "react";
// import { getLocalidad } from "src/general_helpers/LocalidadData";
// import { getDepartamento } from "src/general_helpers/DepartamentoData";
// import { getProvincia } from "src/general_helpers/ProvinciaData";
// import { SelectorLocalidad } from "../../general_components/Localizacion";

// const SelectorLocalidad = (props) => {
//   const estilosSelect = { fontSize: "1em", color: "#646464" };

//   const MenuProps = {
//     PaperProps: {
//       style: {
//         //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//         //width: 100,
//         maxHeight: 250,
//       },
//     },
//   };
//   //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
//   const jsonForm = useJsonForms();
//   const [dataDefaults, setDataDefaults] = useState([]);
//   const disabled = jsonForm.core.data.disabled;
//   const tipo = jsonForm.core.data.selectedLocalidad;
//   const [selectedLocalidad, setSelectedLocalidad] = useState("");
//   const [localidades, setLocalidades] = useState([]);
//   const [provincias, setProvincias] = useState([])
//   const [departamentos, setDepartamentos] = useState([])

//   const getLocalidades = async () => {
//     if (!(localidades.length > 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-localidad"));
//       if (aux_storage) { setLocalidades(aux_storage.localidad) }
//       else {
//         const response = await getLocalidad()
//         if (response) {
//           setLocalidades(response)
//         }
//       }
//     }
//   }

//   const getDepartamentos = async () => {
//     if (!(departamentos.length > 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-departamento"));
//       if (aux_storage) { setDepartamentos(aux_storage.departamento) }
//       else {
//         const response = await getDepartamento()
//         if (response) {
//           setDepartamentos(response)
//         }
//       }
//     }
//   }

//   const getProvincias = async () => {
//     if (!(provincias.length > 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-provincia"));
//       if (aux_storage) { setProvincias(aux_storage.provincia) }
//       else {
//         const response = await getProvincia()
//         if (response) {
//           setProvincias(response)
//         }
//       }
//     }
//   }

//   /* El siguiente codigo crea el arreglo que me da el select de las localidades */
//   const superLocalidadesSetter = () => {
//     if ((dataDefaults.length === 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-superLocalidades"));
//       if (aux_storage) { setDataDefaults(aux_storage) }
//       else {
//         if (localidades.length > 0 && departamentos.length > 0 && provincias.length > 0) {
//           const localidades_aux = []
//           localidades.forEach(function (loc) {
//             const depto = departamentos.filter(d => d.id === loc.id_departamento)
//             const prov = provincias.filter(p => p.id === depto[0].id_provincia)
//             const localidad_aux = {
//               id: loc.id,
//               nombre: loc.nombre,
//               id_departamento: depto[0].id,
//               departamento: depto[0].nombre,
//               id_provincia: prov[0].id,
//               provincia: prov[0].nombre
//             }
//             localidades_aux.push(localidad_aux)
//           })
//           setDataDefaults(localidades_aux)
//           localStorage.setItem('siam-superLocalidades', JSON.stringify(localidades_aux))
//         }
//       }
//     }
//   }


//   useEffect(() => {
//     getProvincias()
//     getDepartamentos()
//     getLocalidades()
//     superLocalidadesSetter()
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [localidades, departamentos, provincias])

//   useEffect(() => {import {
//   and,
//   rankWith,
//   schemaMatches,
//   schemaTypeIs,
//   uiTypeIs,
// } from "@jsonforms/core";
// import Grid from "@mui/material/Grid";
// import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import { useEffect, useState } from "react";
// import { getLocalidad } from "src/general_helpers/LocalidadData";
// import { getDepartamento } from "src/general_helpers/DepartamentoData";
// import { getProvincia } from "src/general_helpers/ProvinciaData";
// import { SelectorLocalizacion } from "../../general_components/Localizacion";

// const SelectorLocalidad = (props) => {
//   const estilosSelect = { fontSize: "1em", color: "#646464" };

//   const MenuProps = {
//     PaperProps: {
//       style: {
//         //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//         //width: 100,
//         maxHeight: 250,
//       },
//     },
//   };
//   //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
//   const jsonForm = useJsonForms();
//   const [dataDefaults, setDataDefaults] = useState([]);
//   const disabled = jsonForm.core.data.disabled;
//   const tipo = jsonForm.core.data.selectedLocalidad;
//   const [selectedLocalidad, setSelectedLocalidad] = useState("");
//   const [localidades, setLocalidades] = useState([]);
//   const [provincias, setProvincias] = useState([])
//   const [departamentos, setDepartamentos] = useState([])

//   const getLocalidades = async () => {
//     if (!(localidades.length > 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-localidad"));
//       if (aux_storage) { setLocalidades(aux_storage.localidad) }
//       else {
//         const response = await getLocalidad()
//         if (response) {
//           setLocalidades(response)
//         }
//       }
//     }
//   }

//   const getDepartamentos = async () => {
//     if (!(departamentos.length > 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-departamento"));
//       if (aux_storage) { setDepartamentos(aux_storage.departamento) }
//       else {
//         const response = await getDepartamento()
//         if (response) {
//           setDepartamentos(response)
//         }
//       }
//     }
//   }

//   const getProvincias = async () => {
//     if (!(provincias.length > 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-provincia"));
//       if (aux_storage) { setProvincias(aux_storage.provincia) }
//       else {
//         const response = await getProvincia()
//         if (response) {
//           setProvincias(response)
//         }
//       }
//     }
//   }

//   /* El siguiente codigo crea el arreglo que me da el select de las localidades */
//   const superLocalidadesSetter = () => {
//     if ((dataDefaults.length === 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-superLocalidades"));
//       if (aux_storage) { setDataDefaults(aux_storage) }
//       else {
//         if (localidades.length > 0 && departamentos.length > 0 && provincias.length > 0) {
//           const localidades_aux = []
//           localidades.forEach(function (loc) {
//             const depto = departamentos.filter(d => d.id === loc.id_departamento)
//             const prov = provincias.filter(p => p.id === depto[0].id_provincia)
//             const localidad_aux = {
//               id: loc.id,
//               nombre: loc.nombre,
//               id_departamento: depto[0].id,
//               departamento: depto[0].nombre,
//               id_provincia: prov[0].id,
//               provincia: prov[0].nombre
//             }
//             localidades_aux.push(localidad_aux)
//           })
//           setDataDefaults(localidades_aux)
//           localStorage.setItem('siam-superLocalidades', JSON.stringify(localidades_aux))
//         }
//       }
//     }
//   }


//   useEffect(() => {
//     getProvincias()
//     getDepartamentos()
//     getLocalidades()
//     superLocalidadesSetter()
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [localidades, departamentos, provincias])

//   useEffect(() => {
//     setSelectedLocalidad(tipo);
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   //funcion para devolver al formulario la data seleccionada y para setearla en el selector
//   const handleChange = (event) => {
//     setSelectedLocalidad(event.target.value);
//     props.handleChange(props.path, event.target.value);
//   };

//   return (
//     <div>
//       <FormControl
//         variant="standard"
//         sx={{ width: "100%", paddingBottom: "10px" }}
//       >
//         <InputLabel>Localidad</InputLabel>
//         <Select
//           style={estilosSelect}
//           MenuProps={MenuProps}
//           inputProps={{ disabled: disabled }}
//           value={selectedLocalidad}
//           onChange={handleChange}
//           label="Localidad"
//         >
//           <MenuItem style={estilosSelect} value="">
//             Ninguno
//           </MenuItem>
//           {dataDefaults && dataDefaults.length > 0 ? (
//             dataDefaults.map((localidad) => (
//               <MenuItem
//                 style={estilosSelect}
//                 key={localidad.id}
//                 value={localidad.id}
//               >
//                 {localidad.id + " - " + localidad.nombre + " ( " + localidad.departamento + " ) " + localidad.provincia}
//               </MenuItem>
//             ))
//           ) : (
//             <MenuItem key={0} value={0} style={estilosSelect}>
//               {"No hay Localidades disponibles"}
//             </MenuItem>
//           )}
//         </Select>
//       </FormControl>
//     </div>
//     // <Grid >
//     //   <SelectorLocalizacion  />
//     // </Grid>
//     // <div>
//     //     <Button
//     //           variant="contained"
//     //           size="small"
//     //           onClick={() => {
//     //             toggleModal();
//     //           }}
//     //           style={{
//     //             marginTop: "0px",
//     //             marginLeft: "88%",
//     //             width: "110px",
//     //             height: "30px",
//     //             marginBottom: "0px",
//     //           }}
//     //         >
//     //           <AddCircle />
//     //           <div>Localizaciones {/* BOTON AGREGAR */}</div>
//     //   </Button>
//     //   <SimpleDialogComponent
//     //         open={modalState}
//     //         modalName={"Cuentas"}
//     //         child={dialogChild}
//     //         setPushData={saveData}
//     //         handleClose={toggleModal}
//     //         buttonEnviar={false}
//     //         buttonCancelar={true}
//     //       />
//     // </div>
//   );
// };



// /*
// TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
// que se encargue de verificar si el componente debe ser renderizado o no.
// */
// const SelectorLocalidadTester = rankWith(
//   100,
//   and(
//     uiTypeIs("Control"),
//     schemaTypeIs("string"),
//     schemaMatches((schema) => {
//       if (schema.hasOwnProperty("customRender")) {
//         let cellschema = schema;
//         return cellschema["customRender"] === "SelectorLocalidad";
//       }
//       return false;
//     })
//   )
// );

// // se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
// export const SelectorLocalidadComponent = {
//   renderer: withJsonFormsControlProps(SelectorLocalidad),
//   tester: SelectorLocalidadTester,
// };


//   //funcion para devolver al formulario la data seleccionada y para setearla en el selector
//   const handleChange = (event) => {
//     setSelectedLocalidad(event.target.value);
//     props.handleChange(props.path, event.target.value);
//   };

//   return (
//     <div>
//       <FormControl
//         variant="standard"
//         sx={{ width: "100%", paddingBottom: "10px" }}
//       >
//         <InputLabel>Localidad</InputLabel>
//         <Select
//           style={estilosSelect}
//           MenuProps={MenuProps}
//           inputProps={{ disabled: disabled }}
//           value={selectedLocalidad}
//           onChange={handleChange}
//           label="Localidad"
//         >
//           <MenuItem style={estilosSelect} value="">
//             Ninguno
//           </MenuItem>
//           {dataDefaults && dataDefaults.length > 0 ? (
//             dataDefaults.map((localidad) => (
//               <MenuItem
//                 style={estilosSelect}
//                 key={localidad.id}
//                 value={localidad.id}
//               >
//                 {localidad.id + " - " + localidad.nombre + " ( " + localidad.departamento + " ) " + localidad.provincia}
//               </MenuItem>
//             ))
//           ) : (
//             <MenuItem key={0} value={0} style={estilosSelect}>
//               {"No hay Localidades disponibles"}
//             </MenuItem>
//           )}
//         </Select>
//       </FormControl>
//     </div>
//     // <Grid >
//     //   <SelectorLocalizacion  />
//     // </Grid>
//     // <div>
//     //     <Button
//     //           variant="contained"
//     //           size="small"
//     //           onClick={() => {
//     //             toggleModal();
//     //           }}
//     //           style={{
//     //             marginTop: "0px",
//     //             marginLeft: "88%",
//     //             width: "110px",
//     //             height: "30px",
//     //             marginBottom: "0px",
//     //           }}
//     //         >
//     //           <AddCircle />
//     //           <div>Localizaciones {/* BOTON AGREGAR */}</div>
//     //   </Button>
//     //   <SimpleDialogComponent
//     //         open={modalState}
//     //         modalName={"Cuentas"}
//     //         child={dialogChild}
//     //         setPushData={saveData}
//     //         handleClose={toggleModal}
//     //         buttonEnviar={false}
//     //         buttonCancelar={true}
//     //       />
//     // </div>
//   );
// };



// /*
// TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
// que se encargue de verificar si el componente debe ser renderizado o no.
// */
// const SelectorLocalidadTester = rankWith(
//   100,
//   and(
//     uiTypeIs("Control"),
//     schemaTypeIs("string"),
//     schemaMatches((schema) => {
//       if (schema.hasOwnProperty("customRender")) {
//         let cellschema = schema;
//         return cellschema["customRender"] === "SelectorLocalidad";
//       }
//       return false;
//     })
//   )
// );

// // se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
// export const SelectorLocalidadComponent = {
//   renderer: withJsonFormsControlProps(SelectorLocalidad),
//   tester: SelectorLocalidadTester,
// };


// import {
//   rankWith,
//   and,
//   uiTypeIs,
//   schemaMatches,
//   schemaTypeIs,
// } from "@jsonforms/core";
// import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
// import React, { useState, useEffect } from "react";
// import FormControl from "@mui/material/FormControl";
// import { Autocomplete, CircularProgress, TextField } from "@mui/material";
// import { getLocalidad } from "src/general_helpers/LocalidadData";
// import { getDepartamento } from "src/general_helpers/DepartamentoData";
// import { getProvincia } from "src/general_helpers/ProvinciaData";

// const SelectorLocalidad = (props) => {
//   const jsonForm = useJsonForms();
//   const dataDefaultsAssigned = jsonForm.core.data.selectedLocalidad;
//   const [localidades, setLocalidades] = useState([]);
//   const [superLocalidades, setSuperLocalidades] = useState([])
//   const [provincias, setProvincias] = useState([])
//   const [departamentos, setDepartamentos] = useState([])

//   const [selectedLoc, setSelectedLoc] = useState(
//     dataDefaultsAssigned || []
//   );

//   const getLocalidades = async () => {
//     if (!(localidades.length > 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-localidad"));
//       if (aux_storage) { setLocalidades(aux_storage.localidad) }
//       else {
//         const response = await getLocalidad()
//         if (response) {
//           setLocalidades(response)
//         }
//       }
//     }
//   }

//   const getDepartamentos = async () => {
//     if (!(departamentos.length > 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-departamento"));
//       if (aux_storage) { setDepartamentos(aux_storage.departamento) }
//       else {
//         const response = await getDepartamento()
//         if (response) {
//           setDepartamentos(response)
//         }
//       }
//     }
//   }

//   const getProvincias = async () => {
//     if (!(provincias.length > 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-provincia"));
//       if (aux_storage) { setProvincias(aux_storage.provincia) }
//       else {
//         const response = await getProvincia()
//         if (response) {
//           setProvincias(response)
//         }
//       }
//     }
//   }

//   /* El siguiente codigo crea el arreglo que me da el select de las localidades */
//   const superLocalidadesSetter = () => {
//     if ((superLocalidades.length === 0)) {
//       let aux_storage = JSON.parse(localStorage.getItem("siam-superLocalidades"));
//       if (aux_storage) { setSuperLocalidades(aux_storage) }
//       else {
//         if (localidades.length > 0 && departamentos.length > 0 && provincias.length > 0) {
//           const localidades_aux = []
//           localidades.forEach(function (loc) {
//             const depto = departamentos.filter(d => d.id === loc.id_departamento)
//             const prov = provincias.filter(p => p.id === depto[0].id_provincia)
//             const localidad_aux = {
//               id: loc.id,
//               nombre: loc.nombre,
//               id_departamento: depto[0].id,
//               departamento: depto[0].nombre,
//               id_provincia: prov[0].id,
//               provincia: prov[0].nombre
//             }
//             localidades_aux.push(localidad_aux)
//           })
//           setSuperLocalidades(localidades_aux)
//           localStorage.setItem('siam-superLocalidades', JSON.stringify(localidades_aux))
//         }
//       }
//     }
//   }

//   useEffect(() => {
//     getProvincias()
//     getDepartamentos()
//     getLocalidades()
//     superLocalidadesSetter()
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [localidades, departamentos, provincias])

//   const handleChangeLocalidad = (selectedOption) => {
//     setSelectedLoc({
//       ...selectedOption
//     })
//     handleChanges(selectedOption)
//   }

//   const handleChanges = (selectedOption) => {
//     props.handleChange(props.path, selectedOption);
//   };

//   return (
//     <>
//       <FormControl
//         sx={{ width: "100%", paddingBottom: "10px" }}
//       >
//         <Autocomplete
//           options={superLocalidades && superLocalidades}
//           value={(selectedLoc?.id && superLocalidades.length > 0) && superLocalidades.filter(x => x.id === selectedLoc.id)[0]}
//           getOptionLabel={(e) => e.id + " - " + e.nombre + " ( " + e.departamento + " ) " + e.provincia}
//           getOptionValue={(e) => e.id}
//           renderInput={(params) => superLocalidades.length > 0 ? <TextField {...params} label="Localidad" size="medium" /> : <CircularProgress {...params} size={30} />}
//           onChange={((e, newValue) => handleChangeLocalidad(newValue))}
//           fullWidth
//           size="medium"
//         />

//       </FormControl>
//     </>
//   );
// };

// const SelectorLocalidadTester = rankWith(
//   100,
//   and(
//     uiTypeIs("Control"),
//     schemaTypeIs("object"),
//     schemaMatches((schema) => {
//       if (schema.hasOwnProperty("customRender")) {
//         let cellschema = schema;
//         return cellschema["customRender"] === "SelectorLocalidad";
//       }
//       return false;
//     })
//   )
// );

// // hand this over together with the provided `renderers` to the `JsonForms` component
// export const SelectorLocalidadComponent = {
//   renderer: withJsonFormsControlProps(SelectorLocalidad),
//   tester: SelectorLocalidadTester,
// };
import datos from "src/services/datos";

const objectTipoLocalizacion = new datos("tipo_localizacion")

export const getTipoLocalizacion = async() => {    
    
  objectTipoLocalizacion.addCondition("tipo_localizacion","id",0,">")
   
    const response = await objectTipoLocalizacion.get()
    
    if(response){
      return response.wsResponse.tipo_localizacion
    }
    return response
}

export const postTipoLocalizacion = async(data) => {
  
  const response = objectTipoLocalizacion.insert(data)

  if (response){
    return response.wsResponse.tipo_localizacion
  }
  return response
  
}

export const deleteTipoTelefonos = async(data) => {
}
import React, { useContext, useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { schemaSearchUbicacion } from '../../../forms/schemaSearchUbicacion'
import { uiSchemaSearchUbicacion } from '../../../forms/uiSchemaSearchUbicacion'
import { SelectorDepositoComponent } from "../../../forms/customRenders/selectorDeposito";
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { uiSchemaCajasUbicacionSelectDeposito } from "../../../forms/uiSchemaCajasUbicacionSelectDeposito";
import { SelectorTipoUbicacionesComponent } from "src/forms/customRenders/selectorTipoUbicaciones";
const localize = require("ajv-i18n");

export const FormUbicaciones = ({ data, setData, valorUbicacionTemporal }) => {

  const {
    setListDatosFormUbicaciones, formUbicacionesDisabled, setFormUbicacionesDisabled, setDataSearchFormUbicaciones,
    setGrupoBusquedaUbicaciones
  } = useContext(CajasContext);



  useEffect(() => {
    let datos = {}
    setListDatosFormUbicaciones({});
    if (data.idDep) {
      datos["owner_localizaciones"] = { id_owner_localizaciones: data.idDep };
      if (data.sector) {
        datos["sector"] = { sector: `${data.sector}` };
      }
      if (data.modulo) {
        datos["modulo"] = { modulo: `${data.modulo}` };
      }
      if (data.estante) {
        datos["estante"] = { estante: `${data.estante}` };
      }
      if (data.posicion) {
        datos["posicion"] = { posicion: `${data.posicion}` };
      }
      if (data.estiba) {
        datos["estibe"] = { estibe: `${data.estiba}` };
      }
      if (data.profundidad) {
        datos["profundidad"] = { profundidad: `${data.profundidad}` };
      }

      setDataSearchFormUbicaciones(datos);
      setGrupoBusquedaUbicaciones(true)
    }

    if (data.ubicacionTemporal && !data.idDep) {
      datos["ubicacionTemporal"] = { ubicacionTemporal: `${data.ubicacionTemporal}` };

      setDataSearchFormUbicaciones(datos);
    }

    setListDatosFormUbicaciones(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const limpiar = (e) => {
    setData({ idDep: "", disabled: false });
    setListDatosFormUbicaciones({});
    setFormUbicacionesDisabled(false);
    setGrupoBusquedaUbicaciones(false)

  };


  // eslint-disable-next-line no-unused-vars
  const [withErrors, setWithErrors] = useState(false);

  const onChange = (errors, data) => {
    // console.log("data",data)
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);
    let valorSector = data.sector ? (data.sector <= 0 ? undefined : data.sector) : undefined;
    let valorModulo = data.modulo ? (data.modulo <= 0 ? undefined : data.modulo) : undefined;
    let valorEstante = data.estante ? (data.estante <= 0 ? undefined : data.estante) : undefined;
    let valorPosicion = data.posicion ? (data.posicion <= 0 ? undefined : data.posicion) : undefined;
    let valorEstiba = data.estiba ? (data.estiba <= 0 ? undefined : data.estiba) : undefined;
    let valorProfundidad = data.profundidad ? (data.profundidad <= 0 ? undefined : data.profundidad) : undefined;
    let dataFinal = {
      "idDep": data.idDep,
      "sector": valorSector,
      "modulo": valorModulo,
      "estante": valorEstante,
      "estiba": valorEstiba,
      "posicion": valorPosicion,
      "profundidad": valorProfundidad
    }

    var esPositivo =
      data.sector <= 0 ||
        data.modulo <= 0 ||
        data.estante <= 0 ||
        data.estiba <= 0 ||
        data.posicion <= 0 ||
        data.profundidad <= 0
        ? false
        : true;

    if (!esPositivo) {
      setData(dataFinal);
      return { errors, dataFinal };
    } else {
      setData(data);
      return { errors, data };
    }
  };

  return (
    <div>

      <Grid container justifyContent="end" style={{ marginBottom: '3px' }}>



        <Button
          variant="contained"
          onClick={limpiar}
          color="secondary"

          sx={{
            padding: '5px',
            minWidth: 'auto',

          }}
        >
          <ReplayIcon style={{
            padding: '0px',      // Ajusta el relleno (padding) para hacer el botón más pequeño
            position: 'center',

          }} />
        </Button>


      </Grid>
      <JsonForms
        schema={schemaSearchUbicacion}
        uischema={{
          ...(valorUbicacionTemporal !== "1"
            ? uiSchemaSearchUbicacion
            : uiSchemaCajasUbicacionSelectDeposito),
        }}
        data={data}
        renderers={[
          ...materialRenderers,
          SelectorTipoUbicacionesComponent,
          SelectorDepositoComponent,
        ]}
        cells={materialCells}
        // onChange={({ data, _errors }) => setData(data)}
        onChange={({ errors, data }) => onChange && onChange(errors, data)}
        {...(formUbicacionesDisabled ? { readonly: true } : {})}
      />

    </div>);
};

import datos from "src/services/datos";

function reemplazarObjetosPorId(data) {
    // Verificar si el dato es un objeto
    if (typeof data === 'object' && data !== null) {
        // Recorrer cada propiedad del objeto
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                // Verificar si la propiedad es un objeto y tiene una propiedad "id"
                if (typeof data[key] === 'object' && data[key] !== null && 'id' in data[key]) {
                    // Reemplazar el objeto por su propiedad "id"
                    data[key] = data[key].id;
                } else {
                    // Si la propiedad no es un objeto con "id", llamar recursivamente a la función
                    reemplazarObjetosPorId(data[key]);
                }
            }
        }
    }
    // Devolver el objeto modificado
    return data;
}

export const postDinamico = async (modelo, datosForm) => {
    const data = reemplazarObjetosPorId(datosForm);

    const objeto = new datos(modelo);
    if (data.fecha) {
        const fechaParseada = new Date(data.fecha);

        // Formatear la fecha según el formato deseado (por ejemplo, 'YYYY-MM-DD HH:mm:ss')
        const fechaFormateada = `${fechaParseada.getFullYear()}-${(fechaParseada.getMonth() + 1).toString().padStart(2, '0')}-${fechaParseada.getDate().toString().padStart(2, '0')} ${fechaParseada.getHours().toString().padStart(2, '0')}:${fechaParseada.getMinutes().toString().padStart(2, '0')}:${fechaParseada.getSeconds().toString().padStart(2, '0')}`;

        const objetoFormateado = {
            ...data,
            fecha: fechaFormateada
        };
        const response = await objeto.insert(objetoFormateado);
        return response;
    }
    const response = await objeto.insert(data);
    return response;

};
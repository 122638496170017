import { createTheme } from '@mui/material/styles';
import { id_proyecto } from 'src/configs/config';
import fondo2 from "src/assets/img/fondo2.jpeg";
import playa from "src/assets/img/playa.jpg";
import fondo1 from "src/assets/img/fondo1.jpg";

const getCustomTheme = (mode = 'light', customColors = {}) => {
    const isDarkMode = mode === 'dark';

    return createTheme({
        palette: {
            mode,
            background: isDarkMode ? { login: id_proyecto !== '27' ? '#e23139' : "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(10,57,95,1) 100%)", } : {
                default: customColors.background || '#fff',
                paper: customColors.paper || '#fff',
                list: id_proyecto !== '27' && id_proyecto !== '24' ? '#e23139' : id_proyecto !== '24' ? "linear-gradient(69deg, #000000 0%, #44787D 100%)" : "#1A8036",
                login: id_proyecto !== '27' && id_proyecto !== '24' ? '#e23139' : id_proyecto !== '24' ?
                    "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(10,57,95,1) 100%)" : "#1A8036",
            },
            primary: {
                main: customColors.menu || (id_proyecto !== '27' && id_proyecto !== '24' ? '#e23139' : id_proyecto !== '24' ? "#0A395F" : "#1A8036"),
            },
            secondary: {
                main: customColors.button || '#0A395F',
            },
        },
        typography: {
            fontFamily: 'Roboto, sans-serif',
            h1: {
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 500,
                fontSize: 13,
                lineHeight: 1.5,
            },
            h4: {
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 500,
                fontSize: 13,
                lineHeight: 1.5,
                color: isDarkMode ? '#FFFFF' : customColors.button || '#0A395F',
            },
            h3: {
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 500,
                fontSize: 20,
                lineHeight: 1.5,
                color: isDarkMode ? '#FFFFF' : customColors.button || '#0A395F',
            },
        },

        backgroundImage: {
            default: id_proyecto !== '27' && id_proyecto !== '24' ? `url(${playa})` : id_proyecto !== '24' ? `url(${fondo2})` : `url(${fondo1})`,
        },
        backgroundDrawer: {
            default: customColors.menu || (id_proyecto !== '27' && id_proyecto !== '24' ? '#e23139' : id_proyecto !== '24' ? "#0A395F" : "#1A8036"),
        },
        backgroundMenu: {
            default: customColors.menu || (id_proyecto !== '27' && id_proyecto !== '24' ? '#e23139' : id_proyecto !== '24' ? "linear-gradient(0deg, #44787D 20%, #000000 80%)" : "#1A8036"),
        },
        backgroundMenuItem: {
            default: customColors.menu || (id_proyecto !== '27' && id_proyecto !== '24' ? '#e23139' : id_proyecto !== '24' ? "linear-gradient(0deg, #44787D 20%, #000000 80%)" : "#1A8036"),
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        background: customColors.menu || (id_proyecto !== '27' && id_proyecto !== '24' ? '#e23139' : id_proyecto !== '24' ? "#0A395F" : "#1A8036"),
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {},
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    columnHeaders: {
                        background: !isDarkMode ? "rgb(244, 246, 248)" : "#212121",
                        color: "#637381",
                        fontSize: 14,
                        fontWeight: 1200,
                        borderRadius: 0,
                        verticalAlign: 'inherit',
                        border: !isDarkMode ? '1px dashed rgb(241, 243, 244)' : "none",
                    },
                    row: {
                        fontSize: 13,
                        fontFamily: 'Roboto, sans-serif',
                    },
                    columnHeaderTitle: {
                        fontWeight: "bold !important", overflow: "visible !important",
                    }
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        background: customColors.menu || (id_proyecto !== '27' && id_proyecto !== '24' ? '#e23139' : id_proyecto !== '24' ? "#0A395F" : "#1A8036"),
                        color: 'white',
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        background: "rgb(244, 246, 248)",
                        '& .MuiTableCell-head': {
                            color: "#637381",
                            fontSize: 14,
                            fontWeight: 1200,
                            borderRadius: 0,
                            verticalAlign: 'inherit',
                            border: '1px dashed rgb(241, 243, 244)',
                        },
                    },
                },
            },
        }

    });
};

export default getCustomTheme;

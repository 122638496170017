import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import { schemaBasicIdDetalle } from "src/forms/schemaBasicIdDetalle";
import { uischemaBasicIdDetalle, uischemaBasicIdDetalleEdit } from "src/forms/uischemaBasicIdDetalle";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponentRC";
import { Buttons } from "src/general_components/Buttons";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";

const localize = require("ajv-i18n");
export const FormTiposContenidos = (props) => {
  const auth = useContext(AuthContext);
  const {
    data,
    listName,
    componentList,
    createButton,
    setCreateButton,
    postData,
  } = props;

  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const [dataInfo, setDataInfo] = useState({
    id: data.id || "",
    detalle: data.nombre || data.detalle || "",
  });

  //CONTROLA CUANDO SE SALE DE UN EDIT Y QUEDA CON BASURA LOS SELECTORES, COMPROBAR CUALQUIERA DE LOS DOS SELECTORES SI ES DISTINTO DE VACIO
  if (data.length === 0 && dataInfo.id !== "") {
    setDataInfo({
      id: "",
      detalle: "",
    });
  }

  //PARA QUE SE ACTUALICE EL SELECTOR CUANDO SE EDITA
  if (data.id && dataInfo.id === "") {
    setDataInfo({
      id: data.id || "",
      detalle: data.nombre || data.detalle || "",
    });
    setChecked(data.digitalizado === "1" ? true : false);

  }
  // eslint-disable-next-line no-unused-vars
  const [withErrors, setWithErrors] = useState(true)
  const [redirect, setRedirect] = useState(false);


  if (redirect) {
    return <Redirect to={componentList} />; //Redirecciona a la pagina del listado dependiend del componente que se este usando
  }

  const handleSubmit = async () => {
    console.log("dataInfo",dataInfo)
    if(!dataInfo["detalle"] || dataInfo["detalle"] === ""){
      setMessage("El campo detalle no puede estar vacio");
      setSeverity("warning")
      setSnackbarVisible(true);
      return;
    }
    dataInfo["digitalizado"] = checked ? "1" : "0";
    const response = await postData(dataInfo, auth);
    if (response.status === "true") {
      setRedirect(true);
    }
  };


  const onChange = (errors, data) => {
    localize.es(errors);
    let err = errors && errors.length > 0;
    setWithErrors(err);

    setDataInfo(data);

    return { errors, data };
  };


  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const toggleModal = () => {
    setChecked(false);
    setCreateButton(!createButton);

  };

  const dialogChild = <Grid style={{ background: "white", padding: "30px", }}>
    <JsonForms
      schema={schemaBasicIdDetalle}
      uischema={data.id ? uischemaBasicIdDetalleEdit : uischemaBasicIdDetalle}
      renderers={materialRenderers}
      data={dataInfo}
      cells={materialCells}
      onChange={({ errors, data }) =>
        onChange && onChange(errors, data)
      }
    />
    <FormControlLabel
      value="end"
      control={<Switch
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />}
      label="Digitalizado"
      labelPlacement="end"
    />
    <Buttons
      disableButton={false}
      onClickSubmit={handleSubmit}
      labelButtonSave={"Confirmar"}
      buttonCerrar={toggleModal}
    />
  </Grid>



  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <>


      <SimpleDialogComponent
        open={createButton}
        modalName={listName}
        child={dialogChild}
        handleClose={toggleModal}
        buttonEnviar={false}
        buttonCancelar={false}
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </>
  );
};

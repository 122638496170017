import esArray from "src/general_components/functionEsArray";
import datos from "src/services/datos";
import Swal from 'sweetalert2'
import { id_proyecto } from 'src/configs/config';

const initDataService = async (serviceObj, attributesModel = null) => {
    let response = false;
    const init = new datos(`${serviceObj.model}`);
    const service = serviceObj.servicio;
    let arrayModelObjetos = {};
    let arraySubobjetos = [];
    let dataArray = [];
    if (service === "getCuentasGenericas") {
        response = await init[service]();
        if (response.status === "false") {
            alert(`Problemas para obtener informacion del servicio: '${serviceObj.service}'`)
        } else {
            localStorage.setItem(`siam-service-${service}`, JSON.stringify(response.wsResponse));
        }
    } else {

        const tipoContenidos = new datos('cajas')
        const response = await tipoContenidos.getLoadData()
        const data = esArray(response.wsResponse)

        for (const clave in data) {

            for (const model in data[clave]) {
                dataArray = esArray(data[clave][model])


                dataArray.forEach((subObjeto, index) => {
                    arraySubobjetos.push(Object.values(subObjeto)['0']);
                });

                arrayModelObjetos = Object.values(arraySubobjetos);

                localStorage.setItem(`siam-${model}`, JSON.stringify({ 'total_records': arrayModelObjetos.length.toString(), [model]: arrayModelObjetos }));

                arraySubobjetos.splice(0, arraySubobjetos.length);
                arrayModelObjetos.splice(0, arrayModelObjetos.length);

            }

        }
    }
    //console.log(localStorage)
    return;
};

export const populateServiceData = async () => {
    let serviceObj = [
        { modelo: "get", servicio: "getLoadData" },
    ];
    
    if (id_proyecto !== '24') {
        serviceObj.push(
            { modelo: "cuentas", servicio: "getCuentasGenericas" } // Servicio para obtener las cuentas genéricas
        );
    }

    const promises = serviceObj.map(async (serviceObj) => {
        await initDataService(serviceObj);
    });

    Promise.all(promises)
        .finally(() => {
            //console.log('Fin Carga Datos Populares de Models ')
            localStorage.setItem(`estadoPeticionModelos`, 'true');
            Swal.close();
        })
};

import React, { useEffect, useContext, useState } from "react";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { CajasContext } from "src/context/CajasContext/CajasContext";
import { deleteContenido } from "../helpers/ContenidosData";
import { getContenidos, getContenidosPaginated, getDigitalizacionesContenidos, getImages } from "../helpers/ContenidosData";
import { TipoContenidosSelect } from "./TipoContenidosSelectMUI";
import { ListTablePaginated } from "../../../general_components/ListTablePaginated";
import { SimpleDialogComponent } from "../../../general_components/SimpleDialogComponent";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteForever, Edit } from "@mui/icons-material";
import { Tooltip, MenuItem } from "@mui/material";
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import esArray from "src/general_components/functionEsArray";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ContenidosMetadatosCell from "./TablaMetadatosContenidos"
import { SnackbarMessenger } from "../../../general_components/SnackbarMessenger";
import jsPDF from 'jspdf';
import HistoryIcon from "@mui/icons-material/History";
import { getContenidosSearchHistorico } from "../helpers/HistoricoCajasData";
import { MovimientosTimelineMUI } from "./MovimientosTimelineMUI";
import { TheLayoutContext } from "src/context/TheLayoutContext";
import LoadingOverlay from "src/general_components/LoadingOverlay";
import { estadoColoresContenidos, estadoIconosContenidos } from "./estadosCajas";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import { Grid } from "@mui/material";
// import { getLoteMaximo } from "../helpers/ContenidosData";

export const ContenidosListM = (data) => {
  const { eliminado, setEliminado, obtenerMetadato,
    clienteName, setSelectionModel, setOpenDialogImage, setTipoContenidoSeleccionado,
    actualizarListadoContenidos, setActualizarListadoContenidos, setContenidoSeleccionado } = useContext(CajasContext);
  const {idsContenidos} = useContext(TheLayoutContext)

  const filtersPaginated = { id_caja: data.id, clienteName: clienteName };
  const notas = data.notas;
  const [updateList, setUpdateList] = useState(false);
  const [params, setParams] = useState(false);
  const [contenidoEdit, setContenidoEdit] = useState(false);
  const [labelModal, setLabelModal] = useState("Alta de Contenido");
  const [actualizarSelected, setActualizarSelected] = useState(false);
  const [modalStateCont, setModalStateCont] = useState(false);
  const [cajaId, setCajaId] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [modalStateHistorico, setMOdalStateHistorico] = useState(false);
  const [historicoCaja, setHistoricoCaja] = useState(false);
  const [loading, setLoading] = useState(false);
  var columns = []
  // const [loteMaximo, setLoteMaximo] = useState(false);

  /*Almacena los detalles del dispositivo del usuario en una variable*/
  let details = navigator.userAgent;

  /*Creamos una expresión regular que contiene algunas palabras clave del dispositivo móvil
  para buscarlo en la cadena details*/
  let regexp = /android|iphone|kindle|ipad/i;

  /*Usamos el método test() para buscar expresiones regulares en details
  devolviendo un valor booleano*/
  let isMobileDevice = regexp.test(details);

  if (isMobileDevice) {
    columns = [
      {
        field: "contenidos_metadatos_ordenados",
        headerName: "Contenidosx",
        width: 100, minWidth: 515, maxWidth: 575,
        headerAlign: "center",
        renderCell: (params) => {
          // You need to add the condition of your loop here
          return (<strong>{params.row.contenidos_metadatos_ordenados}</strong>);
        }
      },
      {
        field: "estado",
        headerName: "Estado",
        width: 100, minWidth: 100, maxWidth: 150
      },
    ];
  } else {
    columns = [
      {
        field: 'lote', headerName: 'Lote', width: 50, headerAlign: "center",
        renderCell: (params) => {
          const value = params.row.lote;
          const id_contenido = params.row.id;
          const descripcion_tipo_contenido = params.row.tipo_contenidos;
          const id_tipo_contenidosR = params.row.id_tipo_contenidos;
          console.log("params.row", params.row)
          return (
            <Tooltip title={
              <>
                <div>id: {id_contenido}</div>
                <div>Tipo de Contenido: {id_tipo_contenidosR+' - '+descripcion_tipo_contenido}</div>
              </>
            }>
              <div>{value}</div>
            </Tooltip>
          );
        }
      },
      {
        field: "contenidos_metadatos_ordenados",
        headerName: "Contenidos",
        flex: 4,
        headerAlign: "center",
        renderCell: (params) => <ContenidosMetadatosCell row={params.row} idsContenidos={idsContenidos} />
      },
      {
        field: "estado",
        headerName: "Estado",
        headerAlign: "center",
        flex: 0.5,
        renderCell: (params) => {
          if (params.value) {
            const color = estadoColoresContenidos[params.value] || "defaultColor"; // Color predeterminado si el estado no tiene un color definido
            const icono = estadoIconosContenidos[params.value] || <ErrorOutlineIcon />; // Icono predeterminado si el estado no tiene un icono definido
            return (
              <Grid style={{ alignItems: 'center', textAlign: 'center', margin: 'auto' }}>

                <Tooltip title={params.value}>
                  <IconButton style={{ color: color }}>
                    {icono}
                  </IconButton>

                </Tooltip>
              </Grid>
            );
          } else {
            return null;
          }
        },
      },
    ];
  }

  if (data.digitalizacionesOn) {
    columns.push({
      //Boton Imagenes
      field: "image",
      headerName: "Administrar Imagenes",
      headerAlign: "center",
      type: "actions",
      width: 100,
      minWidth: 100,
      maxWidth: 125,
      getActions: (params) => [
        <GridActionsCellItem
          hidden={params.row.hiddenImage ? true : false}
          icon={
            <Tooltip title="Administrar Imagenes">
              <span>
                <AddPhotoAlternateIcon />
              </span>
            </Tooltip>
          }
          onClick={() => {
            handleDigitalizaciones(params);
          }}
          label="Image"
        />,
        <GridActionsCellItem
          hidden={params.row.hiddenImage ? true : false}
          icon={
            <Tooltip title="Exportar Imagenes">
              <span>
                <SimCardDownloadIcon />
              </span>
            </Tooltip>
          }
          onClick={() => {
            generarPdfContenidosImagenes(params);
          }}
          label="Image"
        />,
      ],
    },)
  }

  const toggleModalHistorico = async (id) => {
    const response = await getContenidosSearchHistorico(id, "contenido")

    if (response) {
      setHistoricoCaja(esArray(response.wsResponse.movimientos?.historico));
      toggleModalMovimientos();
    } else {

    }

  };

  const toggleModalMovimientos = async () => {
    setMOdalStateHistorico(!modalStateHistorico);

  };


  const obtenerImagenesParaDigitalizacionesMetadatos = async (objetoOriginal, idContenidos, soloCaratua = true) => {
    let resultado = { digitalizaciones: [] };

    const concatIds = (data) => {
      return data.map(item => item.id).join(', ');
    };

    //En esta funcion se toma dos arrays de objetos, array1 y array2, y realiza una operación de fusión (merge) 
    // entre ellos. El objetivo de la función es encontrar los objetos en array2 que tienen un 
    // valor de propiedad id_modelo_padre que coincide con el valor de propiedad id en los objetos de array1, 
    // y luego combinar los objetos correspondientes en un nuevo array resultante.
    const fusionarArrays = (array1, array2) => {
      const mergedArray = array1.map((obj1) => {
        const idToFind = obj1.id;
        const matchingObj2 = array2.find((obj2) => obj2.id_modelo_padre === idToFind);

        if (!matchingObj2) return obj1;

        const mergedObj = {
          ...obj1,
          imagenes: matchingObj2,
        };

        return mergedObj;
      });

      return mergedArray;
    };

    //  Esta funcion toma un array de objetos como entrada y devuelve un nuevo array con una 
    //  estructura de objetos transformada. La idea detrás de esta función es reorganizar y 
    //  seleccionar ciertas propiedades de los objetos en el array original para formar 
    //  un nuevo objeto con una estructura específica.
    const transformArrayStructure = (array) => {
      return array.map((obj) => {
        const { relations, imagenes } = obj;

        return {
          digitalizaciones_metadatos: {
            id: relations?.digitalizaciones_metadatos?.id,
            id_digitalizaciones: obj?.id,
            id_metadatos: relations?.digitalizaciones_metadatos?.id_metadatos,
            id_tipo_contenidos: relations?.metadatos?.id_tipo_contenidos,
            folio: obj?.folio,
            label_form: relations?.metadatos?.label_form,
            valor: relations?.digitalizaciones_metadatos?.valor,
          },
          imagenes: {
            id: imagenes?.id,
            link: imagenes?.link,
            modelo_padre: imagenes?.modelo_padre,
            id_modelo_padre: imagenes?.id_modelo_padre,
          },
        };
      });
    };

    // Obtiene todas las imágenes cuyo modelo padre sea igual a "contenidos"
    const getImagesWithModelPadre = (array) => {
      return array.filter((obj) => obj.modelo_padre === "contenidos");
    };

    const idDigitalizaciones = concatIds(objetoOriginal);

    let paramsImages = [
      { modelo_padre: "contenidos", id_modelo_padre: idContenidos },
      idDigitalizaciones ? { modelo_padre: "digitalizaciones", id_modelo_padre: idDigitalizaciones } : null
    ].filter(Boolean);

    const responseImagenes = await getImages(paramsImages);

    const imagenesCaratula = getImagesWithModelPadre(responseImagenes);

    const mergedArray = fusionarArrays(objetoOriginal, responseImagenes);
    const nuevoArray = transformArrayStructure(mergedArray);

    resultado = { digitalizaciones: nuevoArray, imagenes: soloCaratua ? imagenesCaratula : responseImagenes };

    return resultado;
  }

  const handleDigitalizaciones = async (params) => {
    const responseDigitalizaciones = await getDigitalizacionesContenidos(params.id);

    const responseDigitalizacionesConImagenes = await obtenerImagenesParaDigitalizacionesMetadatos(responseDigitalizaciones, params.id);
    const row = params.row;
    const contenidoSeleccionadoFinal = {
      ...row,
      ...responseDigitalizacionesConImagenes,
    };
    setContenidoSeleccionado(contenidoSeleccionadoFinal);
    console.log("contenidoSeleccionadoFinal", params.row)
    obtenerMetadato(params.row.contenidos_metadatos);
    setOpenDialogImage(true);
  };

  const isLocalhost = () => {
    const hostname = window.location.hostname;
    return hostname === 'localhost' || hostname === '127.0.0.1';
  };

  const generarPdfContenidosImagenes = async (params) => {
    const responseDigitalizaciones = await getDigitalizacionesContenidos(params.id);
    const responseDigitalizacionesConImagenes = await obtenerImagenesParaDigitalizacionesMetadatos(responseDigitalizaciones, params.id, false);

    if (responseDigitalizacionesConImagenes.imagenes.length) {
      // Suponiendo que tu array de imágenes se llama 'imagenes'
      const imagenesOrdenadas = responseDigitalizacionesConImagenes.imagenes.sort((a, b) => {
        // Si a es 'contenidos' y b no lo es, colocamos 'a' primero
        if (a.modelo_padre === 'contenidos' && b.modelo_padre !== 'contenidos') {
          return -1;
        }
        // Si b es 'contenidos' y a no lo es, colocamos 'b' primero
        else if (b.modelo_padre === 'contenidos' && a.modelo_padre !== 'contenidos') {
          return 1;
        }
        // En cualquier otro caso, mantenemos el orden actual
        else {
          return 0;
        }
      });
      const imagenesUrl = imagenesOrdenadas.map((contenidoSeleccionado) => {
        return {
          src: contenidoSeleccionado.link,
          width: 80,
          height: 60,
        };
      });

      const doc = new jsPDF();

      async function loadImage(image) {
        return new Promise((resolve, reject) => {
          let imageUrl = image.src;
          const esLocalHost = isLocalhost();
          if (image.src.startsWith('http://www.digitaldlys')) {
            imageUrl = image.src.replace('http://', 'https://');
          }
          fetch(!esLocalHost ? imageUrl : `http://localhost:3001/image?url=${(imageUrl)}`)
            .then(response => {
              if (!response.ok) {
                throw new Error('Error al cargar la imagen: ' + response.statusText);
              }
              // Si la respuesta es exitosa, puedes procesarla aquí
              // Por ejemplo, convertirla a un blob o mostrarla en una imagen
              return response.blob();
            })
            .then(blob => {
              const url = URL.createObjectURL(blob);
              const imgElement = new Image();
              imgElement.src = url;
              imgElement.onload = () => {
                URL.revokeObjectURL(url);
                resolve(imgElement);
              };
              imgElement.onerror = reject;
            })
            .catch(error => {
              reject(error);
            });
        });
      }

      try {
        setLoading(true);
        const loadedImages = await Promise.all(imagenesUrl.map((image) => loadImage(image)));

        if (loadedImages.some((image) => !image)) {
          throw new Error('Error al cargar una imagen.');
        }
        const esLocalHost = isLocalhost();
        imagenesUrl.forEach((image, index) => {
          if (index !== 0) {
            doc.addPage();
          }
          let imageUrl = image.src;
          if (image.src.startsWith('http://www.digitaldlys')) {
            imageUrl = image.src.replace('http://', 'https://');
          }

          //NOTE SE USA 7 PARA LA X, 7 PARA LA Y, 200 PARA LA IMAGEN WHIDT Y 287 PARA LA IMAGEN HEIGHT
          //210 whidt y 297 height son los bases para una imagen A4, se utiliza como estandar.
          doc.addImage(!esLocalHost ? imageUrl : `http://localhost:3001/image?url=${(imageUrl)}`, 'JPEG', 7, 7, 200, 287);
         });

        doc.save('mi_documento.pdf');
        setLoading(false);
        setSnackbarVisible(true);
        setMessage('Se genero correctamente el PDF.');
        setSeverity('success');
      } catch (error) {
        setLoading(false);
        //Se crear el catch para manejar posibles errores en el momento de agregar imagenes en el PDF
        setSnackbarVisible(true);
        setMessage('ERROR al generar el PDF.');
        setSeverity('error');
      }
    } else {
      setSnackbarVisible(true);
      setMessage('El contenido no contiene imagenes.');
      setSeverity('warning');
    }
  }

  const handleDelete = async (id) => {
    let eliminar = await deleteContenido(id);
    if (eliminar) {
      setOptions({
        ...options,
        idElementData: filtersPaginated,
        sinFilas: false,
      });
      setEliminado(!eliminado);
      setUpdateList(!updateList);
    }
  };


  const onClickUpdateList = () => {
    setUpdateList(!updateList);
  }

  /* Se arma la columna "Acciones que va a tener editar y borrar " */
  const actionColumn = {
    field: "actions",
    headerName: "Acciones",
    type: "actions",
    flex: 0.5,
    width: 100,
    minWidth: 100,
    maxWidth: 125,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Tooltip title="Acciones">
            <span>
              <MoreVertIcon color="info" />
            </span>
          </Tooltip>
        }
        onClick={(e) => {
          handleOpenMenu(e);
          setParams(params)
        }}
        label="Acciones"
      />
    ],
  };

  useEffect(() => {
    if (data.id) {
      setOptions({
        ...options,
        sinFilas: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateList]);

  const toggleModal = () => {
    setModalStateCont(!modalStateCont);
    if (modalStateCont) {
      setContenidoEdit(false);
      setTipoContenidoSeleccionado(null);
      setLabelModal("Alta de Contenido");
    }
  };

  /// Funcion que concatena el valor de los contenidos de cada caja
  const concatenarContenidos = (datos) => {
    var arrMetaDatos = [];
    arrMetaDatos = esArray(datos);

    var contenidosConcatenados = "";
    for (let i4 = 0; i4 < arrMetaDatos.length; i4++) {
      if (contenidosConcatenados === "") {
        contenidosConcatenados =
          contenidosConcatenados +
          arrMetaDatos[i4].metadato_label_form +
          ": " +
          arrMetaDatos[i4].metadato_valor;
      } else {
        contenidosConcatenados =
          contenidosConcatenados +
          " | " +
          arrMetaDatos[i4].metadato_label_form +
          ": " +
          arrMetaDatos[i4].metadato_valor;
      }
    }
    return contenidosConcatenados;
  };

  const getContenidosList = () => {
    if (data.contenidosList) {
      let listContenidos = esArray(data.contenidosList);
      for (let i = 0; i < listContenidos.length; i++) {
        let arrMetaDatos = esArray(listContenidos[i].contenidos_metadatos);
        listContenidos[i].isRowSelectable = true;
        listContenidos[i].arrayMetaDatos = arrMetaDatos;
        listContenidos[i].contenidos_metadatos_ordenados = concatenarContenidos(arrMetaDatos)
      }

      return listContenidos;
    }
  };

  console.log("data.abmContenidos",data.abmContenidos)
  const [options, setOptions] = useState({
    cols: columns,
    get: data.contenidosList ? getContenidosList : getContenidos,
    getPaginated: getContenidosPaginated,
    pageSize: [5, 10, 25],
    limit: 50,
    filter: true,
    idElementData: filtersPaginated,
    exportData: true,
    server: true,
    sinFilas: false,
    actionColumnCustom: actionColumn,
    selectCheckbox: data.checkboxContenidos ? true : false,
    valuesSelected: setSelectionModel,
    delete: data.abmContenidos  ? "deleteMetadatos" : false,
    edit: data.abmContenidos  ? true : false,
    createButton: data.abmContenidos  ? modalStateCont : undefined,
    setCreateButton: data.abmContenidos ? setModalStateCont : false,
    setDataEdit: data.abmContenidos  ? setContenidoEdit : false,
    paginar: "true",
    viewCustomToolbar: "false",
    desabilitarSeleccion: data.desabilitarSeleccionContenidos ? true : false,
    notas: notas,
    setElementId: setCajaId,
    modelo: "contenidos",
  });

  // Actualiza el listtable
  useEffect(() => {
    if (actualizarListadoContenidos) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      setOptions({
        ...options,
        sinFilas: false,
        notas: true,
      });
      setActualizarListadoContenidos(false);
      setActualizarSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualizarListadoContenidos
  ]);

  // Se utiliza para actualizar el array de id seleccionados en el listtable ya que el selectionModel 
  // al limpiar sigue trayendo los elementos seleccionados anteriores
  useEffect(() => {
    if (actualizarSelected) {
      //NOTE MODIFICO EL OPTION DEL LISTADO PARA QUE MUESTRE LOS DATOS
      setOptions({
        ...options,
        sinFilas: false,
        actualizarSelected: actualizarSelected,
        setActualizarSelected: setActualizarSelected
      });
    } else {
      setOptions({
        ...options,
        sinFilas: false,
        actualizarSelected: actualizarSelected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualizarSelected
  ]);

  // Parametros Popover
  const [open, setOpen] = useState(null); // variable popover
  // eslint-disable-next-line no-unused-vars
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEdit = () => {
    setContenidoEdit(params.row);
    setLabelModal("Modificacion de Contenido");
    handleCloseMenu()
    toggleModal();
  };

  const handleDeleteButton = () => {
    handleDelete(params.id)
    handleCloseMenu()
  };

  const handleHistoricoButton = () => {
    toggleModalHistorico(params.id);
    handleCloseMenu()
  };


  return (

    <>
      {modalStateHistorico && (
        <SimpleDialogComponent
          open={modalStateHistorico}
          modalName={"Historico"}
          child={<MovimientosTimelineMUI data={historicoCaja} />}
          setPushData={toggleModalMovimientos}
          handleClose={toggleModalMovimientos}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}
      <ListTablePaginated options={options} />
      {
        loading && <LoadingOverlay open={loading} />
      }
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem onClick={handleEdit}>
          <Edit color="info" sx={{ mr: 2 }} />
          Editar
        </MenuItem>

        <MenuItem onClick={handleDeleteButton}>
          <DeleteForever color="warning" sx={{ mr: 2 }} />
          Eliminar
        </MenuItem>
        <MenuItem onClick={handleHistoricoButton}>
          <HistoryIcon color="info" sx={{ mr: 2 }} />
          Historico
        </MenuItem>
      </Popover>
      <SimpleDialogComponent
        isMobileDevice={data.isMobileDevice}
        open={modalStateCont}
        modalName={labelModal}
        child={<TipoContenidosSelect
          data={contenidoEdit}
          setData={setContenidoEdit}
          isMobileDevice={data.isMobileDevice}
          updateList={onClickUpdateList}
          setLabelModal={setLabelModal}
          modalStateContList={modalStateCont}
          setModalStateContList={setModalStateCont}
          cajaId={cajaId}
          id_cuentas_localizaciones={data.id_cuentas_localizaciones}
          setActualizarListadoContenidos={setActualizarListadoContenidos}
          cuenta_localizaciones={data.cuentas_localizaciones}
          habilitarLote={true} />}
        setPushData={toggleModal}
        handleClose={toggleModal}
        buttonEnviar={false}
        buttonCancelar={true}
      />
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </>)
};

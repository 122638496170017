/* eslint-disable react-hooks/exhaustive-deps */
import { AuthContext } from "src/context/AuthContext";
import React, { useContext, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import DashboardMaterial from "./DashboardMaterial";
import { TheFooter } from "./index";
import { TheLayoutProvider } from "src/context/TheLayoutContext";
import LogoutModal from "./LogoutModal"; // Importa el componente del modal de cierre de sesión
import { types } from "src/context/types";
import Cookies from 'js-cookie';

const TheLayout = () => {
  const { dispatch } = useContext(AuthContext);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [cerrarSesion, setCerrarSesion] = useState(false);

  const handleLogout = () => {
    // Realizar acciones para cerrar la sesión del usuario
    // setShowLogoutModal(false);
    // Elimina la cookie de sesión al cerrar sesión
    Cookies.remove('session');
    dispatch({
      type: types.logout,
      payload: {}
    });
    localStorage.clear();

  };

  useEffect(() => {
    let inactivityTimer;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        setShowLogoutModal(true);
      }, 360 * 60 * 1000); // 6 horas en milisegundos
    };

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      resetInactivityTimer();
    };

    const handleActivity = () => {
      resetTimer();
      resetCountdown();
    };

    resetInactivityTimer();

    document.addEventListener('click', handleActivity);
    document.addEventListener('keydown', handleActivity);

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener('click', handleActivity);
      document.removeEventListener('keydown', handleActivity);
    };
  }, []);

  const resetCountdown = () => {
    setShowLogoutModal(false);
    setCountdown(30);
  };

  useEffect(() => {
    let timer;

    if (showLogoutModal) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            // handleLogout();
            setCerrarSesion(true);
            return 0; // Restablecer el contador a 0 después de ejecutar handleLogout
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [showLogoutModal]);

  useEffect(() => {
    if (cerrarSesion ) {
      handleLogout();
    }
  }, [cerrarSesion]);

  useEffect(() => {
    // Verificar si la cookie de sesión existe al cargar la aplicación
    const sessionCookie = Cookies.get('session');
    const esLogin = sessionStorage.getItem("sessionLogin");
    if (!esLogin && !sessionCookie) {
      sessionStorage.removeItem("sessionLogin");
      handleLogout();
    }
  }, []);


  return (
    <>
      <TheLayoutProvider>
        <Grid container flexDirection={"column"} style={{ textAlign: "center", color: "white" }}>
          <Grid item>
            <DashboardMaterial />
          </Grid>
          <TheFooter />
        </Grid>
      </TheLayoutProvider>
      {showLogoutModal && <LogoutModal handleLogout={handleLogout} setShowLogoutModal={setShowLogoutModal} resetCountdown={resetCountdown} countdown={countdown} />}
    </>
  );
};

export default TheLayout;
import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getDepartamento } from "src/general_helpers/DepartamentoData";

import { esArray } from "src/general_components/functionEsArray";
import { Autocomplete, TextField } from "@mui/material";

const SelectorDepartamento = (props) => {
  
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const [dataDefaults, setDataDefaults] = useState([]);
  //const disabled = jsonForm.core.data.disabled;
  const id_departamento = jsonForm.core.data.id_departamento;  
  const id_provincia=jsonForm.core.data.id_provincia;
  const id_pais = jsonForm.core.data.id_pais;



  //const [paises, setPaises] = useState([])
  var departamentosLocalStorage=JSON.parse(localStorage.getItem("siam-departamento"));

  useEffect(() => {
    const getDepartamentos = async () => {
      if(departamentosLocalStorage !== null){
          const arrDepartamentos=esArray(departamentosLocalStorage.departamento);
          const arrayFiltrado = arrDepartamentos.filter(objeto => objeto.id_provincia === id_provincia);
          setDataDefaults(esArray(arrayFiltrado));
      }else{
          const departamentosObtenidos = await getDepartamento();
          const arrayFiltrado = departamentosObtenidos.filter(objeto => objeto.id_provincia === id_provincia);
          setDataDefaults(esArray(arrayFiltrado));
          if (departamentosObtenidos) {
            setDataDefaults([...departamentosObtenidos]);
          }
      }
      
      if(id_pais === "" || id_provincia === ""){
        setDataDefaults([]);
        setDepartamentoSeleccionado("");
        props.handleChange(props.path, "");
      }else{
        setDepartamentoSeleccionado("");
        props.handleChange(props.path, "");

      }

    //   if (id_departamento) {
    //     setDepartamentoSeleccionado(props.data);
    // }
    };
    getDepartamentos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_provincia,id_pais]);



  useEffect(() => {

    if (dataDefaults) {

      const departamentoEncontrado = dataDefaults.find((departamento) => departamento.id === id_departamento);
      setDepartamentoSeleccionado(departamentoEncontrado);
    }
    
}, [dataDefaults,id_departamento]);


  const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState("");
  //funcion para devolver al formulario la data seleccionada y para setearla en el selector
  const handleChange = (event,newValue) => {
    if (newValue && newValue !== "") {
      setDepartamentoSeleccionado(newValue);
      props.handleChange(props.path, newValue.id);
    }else{
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%", paddingBottom: "10px" }}
      >
        <Autocomplete
        value={departamentoSeleccionado  ? departamentoSeleccionado : null}
        onChange={handleChange}
        options={dataDefaults || []}
        getOptionLabel={(option) => option.id+' - '+option.nombre || ""}
        renderInput={(params) => (
          <TextField {...params} 
          label="Departamentos" />
        )}
      />
      </FormControl>
    </div>
  );
};

  
  /*
  TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
  que se encargue de verificar si el componente debe ser renderizado o no.
  */
  const SelectorDepartamentoTester = rankWith(
    100,
    and(
      uiTypeIs("Control"),
      schemaTypeIs("string"),
      schemaMatches((schema) => {
        if (schema.hasOwnProperty("customRender")) {
          let cellschema = schema;
          return cellschema["customRender"] === "SelectorDepartamento";
        }
        return false;
      })
    )
  );
  
  // se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
  export const SelectorDepartamentoComponent = {
    renderer: withJsonFormsControlProps(SelectorDepartamento),
    tester: SelectorDepartamentoTester,
  }; 








import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";

const tipo_ubicaciones = new datos("tipo_ubicaciones");
export const getTipoUbicaciones = async () => {
  tipo_ubicaciones.addCondition("tipo_ubicaciones", "id", 0, ">");
  const response = await tipo_ubicaciones.get();
  let arrResponse = esArray(response.wsResponse?.tipo_ubicaciones)
  return arrResponse;
};

import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { CajasProvider } from "src/context/CajasContext/CajasContext";
import { CajasEtiquetasList } from '../components/CajasEtiquetasList';
import React from 'react';
import CustomBreadcrumbs from 'src/general_components/BreadCrumbsCustom';

export const CajasEtiquetas = () => {
    let parametrosUrl = new URLSearchParams(window.location.search);
    var mudanza = parametrosUrl.get('mudanza');
    var ubicacion_temporal = parametrosUrl.get('ubicacion_temporal');
    var retiro_cajas = parametrosUrl.get('retiro_cajas');
    var devolucion_cajas = parametrosUrl.get('devolucion_caja');
    let tituloModulo = "";
    if (mudanza === '1') {
        devolucion_cajas = '0'
        ubicacion_temporal = '0'
        retiro_cajas = '0'
        tituloModulo = "Mudanza de Cajas"
    } else {
        mudanza = '0';
        if (ubicacion_temporal === '1') {
            devolucion_cajas = '0'
            retiro_cajas = '0';
            tituloModulo = "Ubicacion Temporal de Cajas";
        } else if (retiro_cajas === '1') {
            devolucion_cajas = '0'
            ubicacion_temporal = '0';
            tituloModulo = "Retiro de Cajas";
        } else if (devolucion_cajas === '1') {
            retiro_cajas = '0';
            ubicacion_temporal = '0';
            tituloModulo = "Devolucion de Cajas"
        }
        else {
            devolucion_cajas = '0'
            retiro_cajas = '0'
            ubicacion_temporal = '0';
            tituloModulo = "Etiquetas"
        }

    }

    const datos = {
        valorMudanza: 1,
        valorUbicacionTemporal: ubicacion_temporal,
        valorRetiroCaja: retiro_cajas,
        valorDevolucionCaja: devolucion_cajas,
    };

    return (
        <Grid style={{ padding: " 0px 0px 0px 0px", background: "white", marginBottom: "50px", paddingTop: 10 }}>
            <Grid
                style={{
                    background: "white",
                    display: 'flex',
                    justifyContent: 'space-between', // Para alinear a los extremos
                    alignItems: 'center', // Para alinear verticalmente
                    backgroundColor: 'white',
                    fontFamily: 'Roboto, sans-serif',
                    borderRadius: 10,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    marginBottom: 20,
                    height: 50
                }}
            >
                <Typography
                    align="left"
                    variant="h2"
                    component="div"
                    sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
                >
                    {tituloModulo}
                </Typography>
                <CustomBreadcrumbs></CustomBreadcrumbs>
            </Grid>
            <CajasProvider>
                {/* <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <ClienteSector />
                    </Grid>
                </Grid> */}
                <CajasEtiquetasList datos={datos} />
            </CajasProvider>
        </Grid>
    );
}
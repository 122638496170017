import { useState, useEffect } from "react";
import { AddCircle } from "@mui/icons-material";
import { Button, Container, Grid } from "@mui/material";
import { useCuentasContext } from "src/context/CuentasContext";
import { SimpleDialogComponent } from "src/general_components/SimpleDialogComponent";
import {
  getCuentasJuridicas,
  getCuentasJuridicasPaginated,
} from "../helpers/CuentasJuridicasData";
import { getCuentasFisicas, getCuentasFisicasPaginated } from "../helpers/CuentasFisicasData";
import { CuentasJuridicasForm } from "./CuentasJuridicasForm";
import { ListTableCuentas } from "./ListTableCuentas";
import { SnackbarMessenger } from "src/general_components/SnackbarMessenger";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { FormConsultaCuentas } from "./FormConsultaCuentas";
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";

/* Listado de Cuentas */

export const CuentasList = (data) => {

  const titulo = data.esJuridica ? "Cuentas Personas Juridicas" : "Cuentas Personas Fisicas";

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);

  const { clearData, saveData, message,
    severity,
    snackbarVisible, setSnackbarVisible,
    criteriosSearchCuentasJuridicas, setBusquedaLocalidad, busquedaLocalidad } = useCuentasContext();
  const columns = data.esJuridica ? [
    {
      field: "id_cuentas",
      headerName: "ID",
      maxWidth: isMobileDevice ? 50 : 50,
      minWidth: isMobileDevice ? 40 : 40,
      flex: 1,
    }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    {
      field: "razon_social",
      headerName: "Razon Social",
      // maxWidth: isMobileDevice ? 250 : 500,
      // minWidth: isMobileDevice ? 250 : 500,
      flex: 1,
    }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    {
      field: "nombre_fantasia",
      headerName: "Nombre de Fantasia",
      // maxWidth: isMobileDevice ? 200 : 400,
      // minWidth: isMobileDevice ? 200 : 400,
      flex: 1,
    },
  ] : [
    {
      field: "id_cuentas",
      headerName: "ID",
      maxWidth: isMobileDevice ? 50 : 50,
      minWidth: isMobileDevice ? 40 : 40,
      flex: 1,
    }, // la propiedad flex permite setear responsivamente el tamaño de la columna
    {
      field: "apellido",
      headerName: "Apellido",
      flex: 1,
    }, // la misma se divide entre el total y el porcentaje que le asiganas con un numero
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "genero",
      headerName: "Genero",
      flex: 1,
    },
    {
      field: "fecha_nacimiento",
      headerName: "Fecha de Nacimiento",
      flex: 1,
    },
    {
      field: "estado_civil",
      headerName: "Estado Civil",
      flex: 1,
    },

  ];

  const options = {
    cols: columns,
    get: data.esJuridica ? getCuentasJuridicas : getCuentasFisicas,
    getPaginated: data.esJuridica ? getCuentasJuridicasPaginated : getCuentasFisicasPaginated,
    pageSize: 5,
    limit: 50,
    criterios: criteriosSearchCuentasJuridicas,
    esJuridica: data.esJuridica
  };

  const [abrirBusqueda, setAbrirBusqueda] = useState(false);
  const [modalState, setModalState] = useState(false);
  const toggleModal = () => {
    setModalState(!modalState);
  };
  const toggleSearchModal = () => {
    setAbrirBusqueda(!abrirBusqueda);
    setBusquedaLocalidad(!busquedaLocalidad)
  };
  const dialogChild = <CuentasJuridicasForm handleClose={toggleModal} esJuridica={data.esJuridica} />;


  /// Despliega el buscador cuando se presiona F2
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F2') {
        setAbrirBusqueda(!abrirBusqueda);
        setBusquedaLocalidad(!busquedaLocalidad)
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid style={{ marginBottom: "50px", backgroundc: "rgb(241, 243, 244)", paddingTop: 10 }}>
      {abrirBusqueda && (
        //Aca va el componente de busqueda
        <SimpleDialogComponent
          open={abrirBusqueda}
          modalName={"Busqueda de Cuentas"}
          child={
            <FormConsultaCuentas
              toggleModal={toggleModal}
              cerrarModal={setAbrirBusqueda}
            />
          }
          handleClose={toggleSearchModal}
          buttonEnviar={false}
          buttonCancelar={true}
        />
      )}
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          sx={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 3, color: "#0A395F", }}
        >
          {titulo}
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <Container style={{ padding: "0px 0px 0px 0px" }} maxWidth="xxl">
        <Grid item xs={12}>
          <Grid container spacing={2} marginTop={'10px'} justifyContent="flex-end">
            <Grid item >
              <Button
                variant="contained"
                size="small"
                endIcon={<AddCircle />}
                onClick={() => {
                  clearData();
                  toggleModal();
                }}
              >

                <div>Agregar {/* BOTON AGREGAR */}</div>
              </Button>
            </Grid>
            <Grid item >

              <Button
                size="small"
                endIcon={<SearchIcon />}
                onClick={() => {
                  setAbrirBusqueda(!abrirBusqueda);
                  setBusquedaLocalidad(!busquedaLocalidad)
                  // setOmitirQuerySearch(true);
                }}
                variant="contained"
              >
                Buscar
              </Button>

            </Grid>
          </Grid>
          <ListTableCuentas options={options} />
          <SimpleDialogComponent
            open={modalState}
            modalName={"Cuentas"}
            child={dialogChild}
            setPushData={saveData}
            handleClose={toggleModal}
            buttonEnviar={false}
            buttonCancelar={true}
          />
        </Grid>
      </Container>
      <SnackbarMessenger
        open={snackbarVisible}
        message={message}
        severity={severity}
        hide={4000}
        close={() => {
          setSnackbarVisible(false);
        }}
      />
    </Grid>
  );
};

import React from 'react';
import { useState } from "react";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Buttons } from './Buttons';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';


export const FormularioDinamico = (data) => {

  const {
    setFormValuesPost,
    formData,
    handleSubmit,
    toggleModalForm,
    inputsOblicatorios,
    dataEdit
  } = data

  let valueEdit = dataEdit;
  if (dataEdit) {
    const mergeObjectsWithOptions = (propertyDefinitions, propertyValues) => {
      const mergedObject = {};

      for (const propertyName in propertyDefinitions) {
        const propertyDefinition = propertyDefinitions[propertyName];
        const propertyValue = propertyValues[propertyName];

        if (propertyDefinition.options) {
          // Si la propiedad tiene opciones, busca el valor correspondiente en las opciones
          const option = propertyDefinition.options.find((opt) => opt.id === propertyValue);

          // Usa el nombre de la propiedad para la nueva propiedad del objeto combinado
          mergedObject[propertyName] = option ? option : propertyValues[propertyName];;
        } else {
          // Si la propiedad no tiene opciones, simplemente asigna el valor
          mergedObject[propertyName] = propertyValue;
        }
      }

      return mergedObject;
    };

    const mergedResult = mergeObjectsWithOptions(formData, dataEdit);
    valueEdit = mergedResult;

  }

  const [formValues, setFormValues] = useState(valueEdit || {});


  const handleChange = (fieldName, value, check) => {

    // Verificar si el campo es un campo de opciones (select o autocomplete)
    const isOptionField = formData[fieldName]?.options;
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));

    if (check) {
      if (value === 1) {
        value = "1";
      } else {
        value = "0";
      }

    }
    // Actualizar el estado solo si es un campo de opciones o un campo diferente
    if (isOptionField) {
      setFormValuesPost((prevValues) => ({ ...prevValues, [fieldName]: value.id }));
    } else {
      if (typeof value === 'object' && value !== null && 'id' in value) {
        // Si es así, asignar value.id al campo fieldName
        setFormValuesPost((prevValues) => ({ ...prevValues, [fieldName]: value.id }));
      } else {
        // De lo contrario, convertir value a cadena y asignarlo al campo fieldName
        setFormValuesPost((prevValues) => ({ ...prevValues, [fieldName]: String(value) }));
      }
    }
  };

  const cumpleRequisitos = (objeto, propiedadesRequeridas) => {
    for (const propiedad of propiedadesRequeridas) {
      if (!objeto.hasOwnProperty(propiedad) || !objeto[propiedad] || Object.keys(objeto[propiedad]).length === 0) {
        return false;
      }
    }
    return true;
  };


  function transformarPalabra(palabra) {
    // Eliminar "id_" y dividir por "_"
    const partes = palabra.replace(/^id_/, '').split('_');

    // Capitalizar la primera letra de cada parte
    const resultado = partes.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');

    return resultado;
  }

  const totalFields = Object.keys(formData).length;
  const isTotalFieldsOdd = totalFields % 2 !== 0;
  // Función para obtener el tipo de base de datos sin paréntesis
  const getDbTypeWithoutParentheses = (dbType) => {
    return dbType.replace(/\([^)]*\)/, '');
  };

  return (
    <Grid>
      <Grid container spacing={2}>
        {Object.keys(formData).map((fieldName, index, array) => {
          const field = formData[fieldName];
          const isLastField = index === array.length - 1 && isTotalFieldsOdd;
          const dbTypeWithoutParentheses = getDbTypeWithoutParentheses(field.dbType);
          if (formData[fieldName].component) {
            return (
              <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                {React.createElement(formData[fieldName].component, { onSeleccionar: handleChange, fieldName: fieldName,valueEdit: formValues[fieldName] })}
              </Grid>
            );
          } else {
            switch (dbTypeWithoutParentheses) {
              case 'int':
                if (field.options) {
                  return (
                    <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                      <Autocomplete
                        size="small"
                        fullWidth
                        value={formValues[fieldName] || null}
                        onChange={(event, newValue) => handleChange(fieldName, newValue)}
                        options={field.options || []}
                        getOptionLabel={(option) => option.id + ' - ' + option.nombre}
                        renderInput={(params) => (
                          <TextField {...params} label={transformarPalabra(typeof (field.label) === "object" ? fieldName : field.label)} />
                        )}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                      <TextField
                        label={transformarPalabra(typeof (field.label) === "object" ? fieldName : field.label)}
                        type="number"
                        value={formValues[fieldName] || ''}
                        onChange={(e) => handleChange(fieldName, parseInt(e.target.value, 10))}
                        size='small'
                        style={{ width: '100%' }}
                      />
                    </Grid>
                  );
                }
              case 'integer':
                return (
                  <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                    <TextField
                      label={transformarPalabra(typeof (field.label) === "object" ? fieldName : field.label)}
                      type="number"
                      value={formValues[fieldName] || ''}
                      onChange={(e) => handleChange(fieldName, parseInt(e.target.value, 10))}
                      size='small'
                      style={{ width: '100%' }}
                    />
                  </Grid>
                );
              case 'string':
                return (
                  <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                    <TextField
                      label={transformarPalabra(typeof (field.label) === "object" ? fieldName : field.label)}
                      value={typeof formValues[fieldName] === 'object' ? '' : formValues[fieldName] ?? ''}
                      onChange={(e) => handleChange(fieldName, e.target.value)}
                      size='small'
                      style={{ width: '100%' }}
                    />
                  </Grid>
                );
              case 'longtext':
                return (
                  <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                    <TextField
                      label={transformarPalabra(typeof (field.label) === "object" ? fieldName : field.label)}
                      value={typeof formValues[fieldName] === 'object' ? '' : formValues[fieldName] ?? ''}
                      onChange={(e) => handleChange(fieldName, e.target.value)}
                      size='small'
                      style={{ width: '100%' }}
                    />
                  </Grid>
                );
              case 'varchar':
                return (
                  <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                    <TextField
                      label={transformarPalabra(typeof (field.label) === "object" ? fieldName : field.label)}
                      value={typeof formValues[fieldName] === 'object' ? '' : formValues[fieldName] ?? ''}
                      onChange={(e) => handleChange(fieldName, e.target.value)}
                      size='small'
                      style={{ width: '100%' }}
                    />
                  </Grid>
                );
              case 'char':
                return (
                  <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                    <TextField
                      label={transformarPalabra(typeof (field.label) === "object" ? fieldName : field.label)}
                      value={typeof formValues[fieldName] === 'object' ? '' : formValues[fieldName] ?? ''}
                      onChange={(e) => handleChange(fieldName, e.target.value)}
                      size='small'
                      style={{ width: '100%' }}
                    />
                  </Grid>
                );
              case 'decimal':
                return (
                  <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                    <TextField
                      label={transformarPalabra(typeof (field.label) === "object" ? fieldName : field.label)}
                      type="number"
                      value={formValues[fieldName] || ''}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue <= 9999999) {
                          handleChange(fieldName, parseFloat(e.target.value));
                        }
                      }}
                      size='small'
                      style={{ width: '100%' }}
                    />
                  </Grid>
                );
              case 'tinyint':
                return (
                  <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={parseInt(formValues[fieldName]) === 1}
                          onChange={(e) => handleChange(fieldName, e.target.checked ? 1 : 0, true)}
                          color="primary"
                        />
                      }
                      label={field.label}
                      size='small'
                      style={{ width: '100%' }}
                    />
                  </Grid>
                );
              case 'date':
                return (
                  <Grid item xs={isLastField ? 12 : 6} key={fieldName}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={field.label}
                        value={formValues[fieldName] || null}
                        onChange={(value) => handleChange(fieldName, value)}
                        renderInput={(params) => <TextField {...params} />}
                        size='small'
                        style={{ width: '100%' }}
                      />
                    </LocalizationProvider>
                  </Grid>
                );

              default:
                return null;
            }
          }

        })}
      </Grid>
      <Grid sx={{ mt: 1, marginTop: 5 }}>
        <Buttons
          disableButton={cumpleRequisitos(formValues, inputsOblicatorios) ? false : true}
          onClickSubmit={handleSubmit}
          labelButtonSave={"Confirmar"}
          buttonCerrar={toggleModalForm}
        />
      </Grid>
    </Grid>
  );
};

export default FormularioDinamico;
import { ABMGenerico } from "src/general_components/ABMGenerico";

export const TiposDetenciones = () => {
    const modelo = "prd_tipo_detenciones";


    return (
        <ABMGenerico modelo={modelo} titulo={"Tipos de Detenciones"} />
    );
};

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CajasProvider } from "../../../context/CajasContext/CajasContext";
import { ImportFile } from "../components/ImportFile"
import CustomBreadcrumbs from "src/general_components/BreadCrumbsCustom";

export const ImportacionContenidos = () => {
  const tituloModulo = "Importacion de Contenidos";

  return (
    <Grid style={{ padding: " 0px 0px 0px 0px", background: "white", marginBottom: "50px", paddingTop: 10 }}>
      <Grid
        style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          height: 50
        }}
      >
        <Typography
          align="left"
          variant="h2"
          component="div"
          style={{ fontSize: 18, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingLeft: 30, color: "#0A395F", }}
        >
          {tituloModulo}
        </Typography>
        <CustomBreadcrumbs></CustomBreadcrumbs>
      </Grid>
      <Grid style={{
          background: "white",
          display: 'flex',
          justifyContent: 'space-between', // Para alinear a los extremos
          alignItems: 'center', // Para alinear verticalmente
          backgroundColor: 'white',
          fontFamily: 'Roboto, sans-serif',
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          padding: 50

        }}>
        <CajasProvider>
          <ImportFile />
        </CajasProvider>
      </Grid>
    </Grid>
  );
}

import { esArray } from "src/general_components/functionEsArray";
import datos from "src/services/datos";

export const getComprobantesTipos = async () => {
  
  const objectComprobantesTipos = new datos("comprobantes_tipo");
  objectComprobantesTipos.addCondition("comprobantes_tipo","id",0,">");
  const response = await objectComprobantesTipos.get();
  const comprobantesTipos = response.wsResponse.comprobantes_tipo;
  const arrComprobantesTipos = esArray(comprobantesTipos);

  return arrComprobantesTipos;
};


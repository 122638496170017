import { createContext, useState, useEffect } from "react";
import {
  updateArticulos,
  getArticulosByIdArticulo,
  deleteArticulos,
} from "../projects/articulos/helpers/ArticulosData";
import Swal from "sweetalert2";
import "src/services/styles/sweetAlert.css";
export const ArticulosContext = createContext();

export function ArticulosProvider({ children }) {
  const [refresh, setRefresh] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [articulosFormData, setArticulosFormData] = useState({});
  const [tipoArticulos, setTipoArticulos] = useState([]);
  const [categoriasArticulos, setCategoriasArticulos] = useState([]);
  const [tipoMoneda, setTipoMoneda] = useState([]);
  const [alicuotasIVA, setAlicuotasIVA] = useState([]);
  const [paramsRow, setParamsRow] = useState("");
  const [modEdit, setModEdit] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [articulo, setArticulo] = useState({ id_articulo: "0" });
  const [articulosList, setArticulosList] = useState([]);
  const [articulosEspecificacion, setArticulosEspecificacion] = useState({});
  const [modelSave, setModelSave] = useState("");
  const [articulosEspecificacionFormData, setArticulosEspecificacionFormData] =
    useState("");
  const [openModal, setOpenModal] = useState(false);
  const [articulosPrecio, setArticulosPrecio] = useState({});
  const [articulosPrecioFormData, setArticulosPrecioFormData] =
  useState({});
  let parametros_proyecto = JSON.parse(localStorage.getItem("parametros_proyecto"));
  const titulo_config = parametros_proyecto?.titulo_articulos;
  const delete_articulo_text = titulo_config ? "esta Propiedad" : "este Articulo";

  /*
  El siguiente useEffect se utiliza para traer todos los datos de las cuentas al Context
  */

  useEffect(() => {
    const getDataArticulos = async () => {
      if (articulo.id_articulo > 0) {
        let response = await getArticulosByIdArticulo(articulo.id_articulo);
        if (response.status === "true") {
          response.wsResponse.articulos.articulos
            ? setArticulosList(response.wsResponse.articulos.articulos)
            : setArticulosList([]);
          response.wsResponse.articulos.articulos_especificacion
            ? setArticulosEspecificacion(
              response.wsResponse.articulos.articulos_especificacion
            )
            : setArticulosEspecificacion({});
          typeof (response.wsResponse.articulos.articulos_calculo_precios.id) !== 'object'
            ? setArticulosPrecio({
              costo: response.wsResponse.articulos.articulos_calculo_precios.costo,
              detalle: response.wsResponse.articulos.articulos_calculo_precios.detalle,
              selectedpropositoCalculoPrecio: response.wsResponse.articulos.articulos_calculo_precios.id_proposito_calculo_precios,
              impuestoInterno: response.wsResponse.articulos.articulos_calculo_precios.impuesto_interno,
            })
            : setArticulosPrecio({});
        }
      }
    };
    getDataArticulos();
  }, [articulo, modelSave, modEdit]);

  /* Funcion que envia los datos almacenados en el Context a traves del metodo POST
    del servicio de Articulos */

  const saveData = async () => {
    let dataToSave = {
      articulos: {
        id: articulosFormData.id ? `${articulosFormData.id}` : "",
        id_articulos_tipo: `${articulosFormData.selectedArticulosTipo}`,
        alias: `${articulosFormData.alias}`,
        id_categorias: `${articulosFormData.selectedCategoria}`,
        nombre: `${articulosFormData.nombre}`,
        nombre_reducido: `${articulosFormData.nombre_reducido}`,
        id_alicuotas_iva: `${articulosFormData.selectedAlicuotasIVA}`,
        id_tipo_moneda: `${articulosFormData.selectedTipoMoneda}`,
      },
      articulos_servicios: [
        {
          insert: true,
        },
      ],
    };

    switch (modelSave) {
      case "articulos_especificacion":
        dataToSave.articulos_especificacion = [articulosEspecificacionFormData];
        break;
      case "articulosUpdate":
        let idArticuloEspecificacion = articulosEspecificacion.id;
        if (typeof idArticuloEspecificacion !== "object") {
          setArticulosEspecificacionFormData({ articulosEspecificacion });
          dataToSave.articulos_especificacion = [articulosEspecificacion];
        } else {
          setArticulosEspecificacionFormData({
            detalle: "",
          });
        }
        let idArticuloPrecio = articulosPrecio.id;
        if (typeof idArticuloPrecio !== "object") {
          setArticulosPrecioFormData(articulosPrecio);
          dataToSave.articulos_calculo_precios = [articulosPrecio];
        } else {
          setArticulosPrecioFormData({
            costo: "",
            detalle: "",
            id_proposito_calculo_precios: "",
            impuesto_interno: "",
          });
        }
        break;
      case "articulos_calculo_precios":
        let articuloPrecio = {
          costo: `${articulosPrecioFormData.costo}`,
          detalle: `${articulosPrecioFormData.detalle}`,
          id_proposito_calculo_precios: `${articulosPrecioFormData.selectedpropositoCalculoPrecio}`,
          impuesto_interno: `${articulosPrecioFormData.impuestoInterno}`,
        }
        dataToSave.articulos_calculo_precios = [articuloPrecio];
        break;
      default:
    }
    var response = await updateArticulos(dataToSave);
    if (response.status === "true") {
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage("Articulo modificado con exito!!!");
      setRefresh(true);
      setOpenModal(!openModal);
      setModEdit(false);
    } else {
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage(response.message);
    }
  };

  /* Funcion que pregunta y consume el servicio articulosDelete */

  const deleteData = async (row) => {
    var dataDelete = {};
    dataDelete.articulos = [{ id: row.id }];

    Swal.fire({
      title: "¿Esta seguro que desea eliminar "+delete_articulo_text+ "?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminalo!",
      cancelButtonText: "Cancelar",
      customClass: {
        container: 'my-swal-delete'
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteArticuloss(dataDelete);
      }
    });
  };

  // Funcion que consume el servicio deleteArticulos y muestra un mensaje.
  const deleteArticuloss = async (dataDelete) => {
    var response = await deleteArticulos(dataDelete);
    if (response.status === "true") {
      setSnackbarVisible(true);
      setSeverity("success");
      setMessage("Articulo eliminado con exito!!!");
      setRefresh(true);
    } else {
      setSnackbarVisible(true);
      setSeverity("warning");
      setMessage(response.message);
    }
    return response;
  };

  return (
    <ArticulosContext.Provider
      value={{
        modalState,
        setModalState,
        tipoArticulos,
        setTipoArticulos,
        categoriasArticulos,
        setCategoriasArticulos,
        tipoMoneda,
        setTipoMoneda,
        alicuotasIVA,
        setAlicuotasIVA,
        paramsRow,
        setParamsRow,
        modEdit,
        setModEdit,
        articulosFormData,
        setArticulosFormData,
        saveData,
        message,
        setMessage,
        severity,
        setSeverity,
        snackbarVisible,
        setSnackbarVisible,
        articulo,
        setArticulo,
        articulosList,
        setArticulosList,
        articulosEspecificacion,
        setArticulosEspecificacion,
        modelSave,
        setModelSave,
        articulosEspecificacionFormData,
        setArticulosEspecificacionFormData,
        refresh,
        setRefresh,
        openModal,
        setOpenModal,
        deleteData,
        articulosPrecio,
        setArticulosPrecio,
        articulosPrecioFormData, setArticulosPrecioFormData,
      }}
    >
      {children}
    </ArticulosContext.Provider>
  );
}

export default ArticulosProvider;

/* eslint-disable no-unused-vars */
import { Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import * as React from "react";
import Divider from "@mui/material/Divider";
import { Buttons } from "src/general_components/Buttons";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PrintIcon from '@mui/icons-material/Print';
import "src/scss/stylesSuccess.scss"

export const ModalImpresionEtiquetasComprobantes = (props) => {
  const {
    modalName,
    open,
    mensaje,
    handleConfirmImpresionEtiquetas,
    handleConfirmImpresionComprobantes,
    handleClose,
    response_procesar,
    maxWidth = "md",
  } = props;

  let isMobile = props.isMobileDevice ? false : true;

  const handleOnClose = (event, reason) => {
    console.log("handleOnClose", reason);
    if (reason === "backdropClick") {
    } else if (reason === "escapeKeyDown") handleClose();
    else handleClose();
  };

  const Title = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF', // Color blanco para el título
    fontWeight: 'bold', // Puedes ajustar el peso de la fuente según tus preferencias
    fontSize: '1rem', // Tamaño de la fuente del título
    marginBottom: theme.spacing(0), // Espacio inferior para separar del contenido
  }));

  const CloseButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
  }));

  const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0),

  }));

  const estilosChild = {
    justifyContent: 'center', textAlign: 'center', marginBottom: 30, fontSize: 16,
    fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', color: "#0A395F"
  }

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      fullWidth={isMobile}
      maxWidth={maxWidth}
    >
      <DialogTitle style={{ background: "linear-gradient(69deg, #000000 0%, #44787D 100%)", }}>
        <Header>
          <div>
            <Title>{modalName}</Title>
          </div>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
      </DialogTitle>
      <Divider />
      <DialogContent style={estilosChild}>
        <div className="dummy-positioning d-flex">

          <div className="success-icon">
            <div className="success-icon__tip"></div>
            <div className="success-icon__long"></div>
          </div>

        </div>
        <Grid style={{marginTop: 40}}>{mensaje}</Grid>
      </DialogContent>

      <Divider />

      <DialogActions style={{ justifyContent: 'center' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              onClick={handleClose}
              variant="contained"
              color="error"
              fullWidth
              startIcon={<CloseIcon />}
              style={{ color: 'white', fontSize: '13px', fontFamily: 'Roboto, sans-serif', }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => handleConfirmImpresionEtiquetas()}
              variant="contained"
              color="success"
              fullWidth
              startIcon={<PrintIcon />}
              style={{ backgroundColor: 'grey', color: 'white', fontSize: '13px', fontFamily: 'Roboto, sans-serif', }}
            >
              Etiquetas
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => handleConfirmImpresionComprobantes()}
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<PrintIcon />}
              disabled={response_procesar?.wsResponse?.id_comprobantes === "false" ? true : false}
              style={{ color: 'white', fontSize: '13px', fontFamily: 'Roboto, sans-serif', }}
            >
              Comprobante
            </Button>
          </Grid>
        </Grid>

      </DialogActions>
    </Dialog>
  );
};

import {
  and,
  rankWith,
  schemaMatches,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { getGenero } from "src/general_helpers/GeneroData";
import { esArray } from "src/general_components/functionEsArray";
import { getFromLS } from "src/general_helpers/getFromLS";
import { Autocomplete, TextField } from "@mui/material";

const SelectorGenero = (props) => {
  //jsonForm se utiliza para traer la data que se le haya pasado al componente, en este caso los perfiles que puede ver el usuario
  const jsonForm = useJsonForms();
  const disabled = jsonForm.core.data.disabled;
  const tipo = jsonForm.core.data.id_genero;
  const [selectedGenero, setSelectedGenero] = useState("");

  const [dataDefaults, setDataDefaults] = useState([]);

  const genero = getFromLS("siam-genero").genero;

  useState(() => {
    const getGeneros = async () => {
      if (genero) {
        setDataDefaults(esArray(genero));
      } else {
        const response = await getGenero()
        if (response) {
          setDataDefaults(response);
        }
      }
    }
    getGeneros()

  }, []);


  useEffect(() => {
    setSelectedGenero(dataDefaults.find((item) => item.id === tipo));
  }, [dataDefaults, tipo]);


  const handleChange = (event, newValue) => {
    if (newValue && newValue !== "") {
      setSelectedGenero(newValue);
      props.handleChange(props.path, newValue.id); // Obtén el ID del objeto seleccionado
    } else {
      props.handleChange(props.path, "");
    }
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ width: "100%",paddingTop: "20px" }}
      >
        <Autocomplete
          size="small"
          sx={{mt:-1, minWidth:"100%" }}
          value={selectedGenero ? selectedGenero : null}
          onChange={handleChange}
          options={dataDefaults || []}
          getOptionLabel={(option) => option.id + ' - ' + option.nombre || ""}
          renderInput={(params) => (
            <TextField {...params}
              label="Genero"
              disabled={disabled}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

/*
TODOS los renders personalizados que se quieran agregar al JSONForms se le debe agregar una funcion tester 
que se encargue de verificar si el componente debe ser renderizado o no.
*/
const SelectorGeneroTester = rankWith(
  100,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    schemaMatches((schema) => {
      if (schema.hasOwnProperty("customRender")) {
        let cellschema = schema;
        return cellschema["customRender"] === "SelectorGenero";
      }
      return false;
    })
  )
);

// se exporta para esta funcion ser llamada en los renders de donde se declare el jsonform
export const SelectorGeneroComponent = {
  renderer: withJsonFormsControlProps(SelectorGenero),
  tester: SelectorGeneroTester,
};

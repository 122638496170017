
import datos from "src/services/datos";


export const getCategoriaCuentas = async() => {    
    const objectCategoriaCuentas = new datos("categoria_cuentas")
    
    objectCategoriaCuentas.addCondition("categoria_cuentas","id",0,">")
   
    const response = await objectCategoriaCuentas.get()
    
    if(response){
            return response.wsResponse.categoria_cuentas
    }
    return response
}

export const postCategoriaCuentas = async(data) => {
  const objectCategoriaCuentas = new datos("categoria_cuentas")
  
  const response = objectCategoriaCuentas.insert(data)

  if (response){
    return response.wsResponse.categoria_cuentas
  }
  return response
  
}

export const deleteCategoriaCuentas = async(data) => {
}
import datos from "src/services/datos";


//Para el selector de tipo contenidos
export const getTipoContenidos = async (auth) => {
  const tipo_contenidos = new datos("tipo_contenidos");
  tipo_contenidos.addCondition("tipo_contenidos", "id", 0, ">");
  const response = await tipo_contenidos.get();
  const res = response.wsResponse.tipo_contenidos;

  // Si es un array se mantiene como estaba
  if (Array.isArray(res)) {
    var arr = res;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (typeof res === "undefined") {
    // se pasa un array vacio
    arr = [];
  } else {
    // si trae un solo elemento , es un objeto
    arr = [res]; // se lo convierte a array
  }
  return arr;
};



export const getTipoContenidosLocalizaciones = async (auth) => {
  const tipo_contenidos_localicazaciones = new datos("cuentas_localizaciones_tipo_contenidos");
  tipo_contenidos_localicazaciones.addCondition("cuentas_localizaciones_tipo_contenidos", "id", 0, ">");
  const response = await tipo_contenidos_localicazaciones.get();

  const res = response.wsResponse.cuentas_localizaciones_tipo_contenidos;

  // Si es un array se mantiene como estaba
  if (Array.isArray(res)) {
    var arr = res;
    // Si no es un array... primero se verifica que tenga valores ... si no los tiene
  } else if (typeof res === "undefined") {
    // se pasa un array vacio
    arr = [];
  } else {
    // si trae un solo elemento , es un objeto
    arr = [res]; // se lo convierte a array
  }
  return arr;
};

export const postTipoContenidosLocalizaciones = async (dato) => {
  const objetoTipoContenidoSector = new datos("cuentas_localizaciones_tipo_contenidos")

  let data = {
    id_cuentas_localizaciones: dato.id_cuentas_localizaciones
  }
  await objetoTipoContenidoSector.delete(data);

  let response = '';

  for (const item of dato.selectedTipoContenidos) {
    const objetoTipoContenidoSectorAdd = new datos("cuentas_localizaciones_tipo_contenidos")
    let data = {};
    data.id_cuentas_localizaciones = dato.id_cuentas_localizaciones;
    data.id_tipo_contenidos = item.id_tipo_contenidos || item.id;
    
    response = await objetoTipoContenidoSectorAdd.insert(data)
  }

  return response

}

export const deleteCuentasTelefonos = async (data) => {
}
import datos from "src/services/datos";
import esArray from "src/general_components/functionEsArray";


export const getCajasGenericasDisponibles = async (limit, offset, data) => {
  const cajasDisponibles = new datos("cajas");
  const response = await cajasDisponibles.getCajasDisponiblesGenericas(data, limit, offset);
  const datosObtenidos = response.wsResponse.caja;
  var datosObtenidosArr = esArray(datosObtenidos);


  if (datosObtenidosArr.length > 0) {
    for (let i = 0; i < datosObtenidosArr.length; i++) {
      datosObtenidosArr[i].tieneContenido = JSON.stringify(datosObtenidosArr[i].contenidos) === "{}" ? "No" : "Si";
      let referencia = typeof datosObtenidosArr[i].referencia === "object" ? "" : " | REF: " + datosObtenidosArr[i].referencia;
      datosObtenidosArr[i].concatCaja = "ID: " + datosObtenidosArr[i].id + referencia;
      datosObtenidosArr[i].hierarchy = ['cliente0', 'sector1', datosObtenidosArr[i].id];
      datosObtenidosArr[i].isRowSelectable = true;
      datosObtenidosArr[i].contenidos = false;
      datosObtenidosArr[i].estado = getDetalleById(datosObtenidosArr[i].id_estados_cajas);
    }
    datosObtenidosArr.push({ id: 'cliente0', clienteName: data.clienteName, hierarchy: ['cliente0'] });
    datosObtenidosArr.push({ id: 'sector1', sectorName: datosObtenidosArr[0]?.sector, hierarchy: ['cliente0', 'sector1'] });
  }


  let result = {
    data: datosObtenidosArr,
    total_records: response.wsResponse.total_records,
  };

  return result;

};

export const postCajasGenericasAlojadas = async (data) => {

  const ubicacionesCajas = new datos('cajas_ubicaciones')
  const response = await ubicacionesCajas.cajasUbicacionesSetGenerica(data)


  return response;
};

// Funcion para obtener el estado de la caja pasandole el id de la caja
const getDetalleById = (id) => {

  let estadosCajas = [];
  let responseStorage = JSON.parse(
    localStorage.getItem("siam-estados_cajas")
  );
  if (responseStorage.estados_cajas) {
    estadosCajas = esArray(responseStorage.estados_cajas);
  }

  const detalleObj = estadosCajas.find((obj) => obj.id === id);

  return detalleObj ? detalleObj.detalle : '';
};


